class BrandingColourSelectionController {
  /*@ngInject*/
  constructor(brandingService) {
    this.name = 'brandingColourSelection'
    this.brandingService = brandingService
  }

  get prompt() {
    return `Brand colour selection${this.required ? '*' : ''}`
  }

  get brands() {
    return this.brandingService.getAllProductBrandColours()
  }

  setStyle(hue) {
    return {'background-color': hue.colour}
  }

  getBrandingIdentifier(brand, hue) {
    return this.brandingService.makeBrandingIdentifier(brand.brand, hue.hue)
  }
}


export default BrandingColourSelectionController
