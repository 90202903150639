class MediaController {
  /*@ngInject*/
  constructor(contentful, mediaService, loggerService) {
    this.name = 'media'
    this.media = []
    const logger = loggerService.makeLogger()

    this.$onInit = () => {
      contentful.entries(`content_type=page&fields.name=${this.name}&include=10`).then((entry) => {
        this.page = entry.data && entry.data.items.length && entry.data.items[0].fields
      }, (error) => {
        logger.serviceFault()
          .serviceId('marketingCMS')
          .notification(`We're unable to display that page just now,. Please try again later.`)
          .logMessage(() => {
            const messages = ['Marketing pages contentful entries promise rejection.']
            messages.push(`this.name: ${JSON.stringify(this.name)}`)
            messages.push(`Reason: ${JSON.stringify(error)}`)
            return messages.join('\n')
          })
          .report()
      })

      contentful.entries('content_type=blurb&include=1').then((entry) => {
        this.media = mediaService.mapToViewOrdered(entry.data.items.map((item) => item.fields))
      })
    }
  }
}

export default MediaController
