import {
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  isTrailBookLoanTarget,
  isReferrerNotes,
  loanAmount,
  loanPurpose,
  referrerNotes,
  isBorrowerDone,
  isGuarantorDone,
  conjunction,
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  and,
  or,
  string,
  isModelDefined, fromModel
} from '../../../dynamicForm/helpers/dynamicFormHelpers'


const hasProperty = or(
  '(eq (fromModel "borrowerOwnsProperty") "yes")',
  '(eq (fromModel "borrowerOwnsProperty") "another")',
  '(eq (fromModel "guarantorOwnsProperty") "yes")',
  '(eq (fromModel "guarantorOwnsProperty") "another")',
  '(eq (fromModel "moreGuarantors") "yes")'
)

const hasNoProperty = '(or ' +
  '(and ' +
    '(eq (fromModel "borrowerOwnsProperty") "no") ' +
    '(eq (fromModel "hasGuarantor") "no")' +
  ') ' +
  '(and ' +
    '(eq (fromModel "borrowerOwnsProperty") "no") ' +
    '(eq (fromModel "guarantorOwnsProperty") "no") ' +
    '(eq (fromModel "moreGuarantors") "no") ' +
  ') ' +
')'

const cancelLabel = '(and ' + hasNoProperty + ' "Ok")'
const actionLabel = '(and ' + hasNoProperty + ' "Cannot submit")'
const showCancel = hasNoProperty
const showAction = '(not ' + hasNoProperty + ' )'


const trailBookBrokerEnquiryForm = {
  title: 'Broker trail book loan',
  model: 'trail-book-enquiry-application',
  actionLabel: 'Submit enquiry',
  cancelLabel: 'Cancel',
  // showCancel: false,
  // showAction: true,
  widgets: [
    container()
      // .prompt('Trail book enquiry')
      // .classes('border')
      .widgets(
        string().model('showCancel').required(true).hidden().rules(ruleSet(showCancel)).build(),
        string().model('cancelLabel').required(true).hidden().rules(ruleSet(cancelLabel)).build(),
        string().model('showAction').required(true).hidden().rules(ruleSet(showAction)).build(),
        string().model('actionLabel').required(true).hidden().rules(ruleSet(actionLabel)).build(),
        loanPurpose()
          .required(true)
          .display('none')
          .rules(ruleSet('"trailBook"'))
          .build(),
        contactType().candidatePartner().build(),
        helpContent()
          .contentId('enquiry-form-trail-book-application-main')
          .build(),
        container()
          .boxed(5)
          .prompt('Your details')
          .widgets(
            ...borrowerFullComposite('you'),
          ).build(),
        container()
          .boxed(5)
          .prompt('Note:')
          .rules(ruleRender('(eq (fromModel "borrowerOwnsProperty") "no")'))
          .widgets(
            helpContent().contentId('enquiry-form-broker-no-property-note').build(),
            radioButtons()
              .model('propertyNote')
              .button('Ok', 'ok')
              .build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Trail book details')
          .rules(ruleRender(and(isBorrowerDone, or(conjunction('eq')(fromModel('propertyNote'), '"ok"'), conjunction('ne')(fromModel('borrowerOwnsProperty'), '"no"')))))
          .widgets(
            loanAmount()
              .prompt('Preferred loan amount?')
              .min(30_000)
              .max(400_000)
              .build(),
            radioButtons()
              .required(true)
              .model('trailBookLoanTarget')
              .prompt('Type of trail book?')
              .button('Existing', 'existing')
              .button('Acquisition', 'acquisition')
              .build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isTrailBookLoanTarget))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .required(true)
              .prompt('Describe your business background and history, your aggregator/franchisor, and the reason for the loan')
              .rules(ruleRender(isTrailBookLoanTarget))
              .build()
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .rules(ruleRender(and(isBorrowerDone, isReferrerNotes)))
          .prompt('Is there an additional guarantor for this loan?')
          .button('Another guarantor', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-broker-representatives-info')
          .rules(ruleRender(and(isBorrowerDone, isReferrerNotes)))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender('(eq (fromModel "hasGuarantor") "yes")'))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        radioButtons()
          .model('moreGuarantors')
          .rules(ruleRender(isGuarantorDone))
          .prompt('Are there any further guarantors for this loan?')
          .button('Additional guarantor(s)', 'yes')
          .button('Not required', 'no')
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-broker-further-guarantors')
          .rules(ruleRender('(eq (fromModel "moreGuarantors") "yes")'))
          .build(),
        container()
          .boxed(5)
          .prompt('Apologies!')
          .rules(ruleRender(hasNoProperty))
          .widgets(
            helpContent().contentId('enquiry-form-broker-no-property-warning').build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isBorrowerDone, isModelDefined('hasGuarantor'), hasProperty)))
          .widgets(
            fileUploadBuilder()
              .fileGroup('atoICA', 'ATO portal report (Integrated Client Account)')
              .fileGroup('currentTrailIncomeSummary', 'Current trail income summary reports (last 3 months)')
              .fileGroup('contractOfSale', 'Contract of Sale (if applicable)')
              .fileGroup('aggregatorFranchisorAgreement', 'Aggregator / franchisor agreement')
              .fileGroup('councilRates', 'Rates Notice (optional at this stage)')
              .fileGroup('mortgageStatement', 'Most recent home loan mortgage statement')
              .fileGroup('purchasingTrailIncomeSummary', 'Intended purchasing trail income summary reports last 3 months (if applicable)')
              .fileGroup('otherDocuments', 'Any other relevant documents (optional)')
              .includeComments(false)
              .build())
          .build())
      .build()
  ]
}

export default trailBookBrokerEnquiryForm
