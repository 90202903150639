import compiler from './apiForm.compile'

export default ['$q', '$mdToast', '$window', ($q, $mdToast, $window) => {
  return {
    require: 'form',
    restrict: 'A',
    controller: () => {
    },
    compile: compiler($q, $mdToast, $window),
  }
}]
