import completeAll from '../../../utils/completeAll'
import {uploadStatus, createUploadManager, uploadProgressDescription, performUploads, deleteUpload, defaultingMap} from '../../../utils/uploadHelpers'


class FileUploadDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, attachmentsRepository, uploadService, loggerService, widget, linkToUri, existingUploads) {
    this.name = 'file-upload-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.attachmentsRepository = attachmentsRepository
    this.uploadService = uploadService

    this.nrLogger = loggerService.makeLogger().enableNewRelic(true).enableConsole(false)

    this.uploads = defaultingMap()
    this.uploadsInProgress = false

    this.dialogHeading = widget.prompt
    this.fileUploadDefinitions = widget.fileUploadConfig.fileUploads
    this.commentDefinition = widget.fileUploadConfig.comments
    this.linkToUri = linkToUri

    this._populateModelFromExistingUploads(existingUploads)
  }

  actionClose() {
    return completeAll(this.$q, ...(this.uploads.entries().map((upload) => this.deleteUpload(upload))))
      .then(() => this.$mdDialog.cancel())
  }

  actionContinue() {
    const fileUploads = this.uploads.entries().filter(entry => entry.status === uploadStatus.ATTACHMENT_CREATED).reduce((accumulator, currentValue) => {
      let fileGroup = accumulator.find((entry) => entry.fileGroup === currentValue.fileGroup)
      if (!fileGroup) {
        fileGroup = {prompt: this.fileUploadDefinitions.find((def) => def.fileGroup === currentValue.fileGroup).prompt, fileGroup: currentValue.fileGroup, uploads: []}
        accumulator.push(fileGroup)
      }
      fileGroup.uploads.push({attachmentId: currentValue.attachmentId, fileName: currentValue.metadata.name, fileSize: currentValue.metadata.size, fileType: currentValue.metadata.type})
      return accumulator
    }, [])
    this.$mdDialog.hide({fileUploads: fileUploads, comments: this.commentsValue})
  }

  selectFiles(files, fileGroup) {
    this.uploadsInProgress = true
    const uploadPromise = performUploads(this.$q, files, fileGroup, this.uploads, this.uploadService, this.attachmentsRepository, this.linkToUri, 'attachments', this.nrLogger)
    uploadPromise.then(() => this.uploadsInProgress = false)
    return uploadPromise
  }

  deleteUpload(uploadManager) {
    return deleteUpload(uploadManager, this.attachementsRepository)
  }

  uploadProgressDescription(item) {
    return uploadProgressDescription(item)
  }

  uploadsActiveFor(fileGroup) {
    return this.uploads.get(fileGroup).filter(upload => ![uploadStatus.ABORTING, uploadStatus.DELETED].includes(upload.status))
  }

  uploadsAvailable() {
    return !this.uploadsInProgress && (this.uploads.entries().some(upload => upload.status === uploadStatus.ATTACHMENT_CREATED) || (this.commentsValue && this.commentsValue.trim().length > 0))
  }

  // ****** private.

  _populateModelFromExistingUploads(existingUploads) {
    if (existingUploads) {
      if (existingUploads.fileUploads) {
        existingUploads.fileUploads.forEach((fileUpload) => {
          fileUpload.uploads.forEach((upload) => {
            const file = {name: upload.fileName, size: upload.fileSize, type: upload.fileType}
            const uploadManager = createUploadManager(fileUpload.fileGroup, file, uploadStatus.ATTACHMENT_CREATED, upload.attachmentId, 100)
            this.uploads.push(fileUpload.fileGroup, uploadManager)
          })
        })
      }
      if (existingUploads.comments) {
        this.commentsValue = existingUploads.comments
      }
    }
  }
}

export default FileUploadDialogController
