/*

  The generated dialog markup is created to hang off the #tp element in the page.

  There is styling in the app.scss file for the contents of the confirmation dialog
  which takes advantage of the location of the dialog within the markup. The idea
  is to provide standard styles within the app.scss file for content authors to
  simply nominate the classes to use when entering definitions into Contentful.

  Just look for '.confirmation-dialog' definition and where it's used, and then add
  whatever you need so that the Contentful markup can access it.
 */

import isDefined from '../../utils/isDefined'

class ConfirmationDialogService {
  /*@ngInject*/
  constructor(contentful, loggerService, $mdDialog, $q) {
    this.contentful = contentful
    this.$mdDialog = $mdDialog
    this.$q = $q

    this.logger = loggerService.makeLogger().enableNewRelic(true).enableConsole(false)
  }

  simpleConfig(contentfulId) {
    if (!this._cachedData) {
      this._cachedData = this._loadConfirmationDialogs()
    }
    return this._cachedData.then((data) => {
      const definition = data.find(record => record.id === contentfulId)
      if (isDefined(definition)) {
        return this.$mdDialog.confirm()
          .textContent(definition.content)
          .title(definition.title)
          .ariaLabel(definition.title || definition.content)
          .clickOutsideToClose(true)
          .ok('OK')
      } else {
        throw(new Error(`Confirmation dialog: content for '${contentfulId}' not found.`))
      }
    })
  }

  confirmAction($event, contentfulId, model = {}) {
    if (!this._cachedData) {
      this._cachedData = this._loadConfirmationDialogs()
    }
    return this._cachedData.then((data) => {
      const definition = data.find(record => record.id === contentfulId)
      if (isDefined(definition)) {
        return this.$mdDialog.show({
          template: definition.content,
          targetEvent: $event,
          locals: {model},
          clickOutsideToClose: true,
          escapeToClose: true,
          controller: DialogController,
          controllerAs: 'vm'
        })
      } else {
        const message = `Confirmation dialog: content for '${contentfulId}' not found.`
        this.logger
          .notify('There is a temporary problem with the system. Please try again later.')
          .error(message)
        const deferred = this.$q.defer()
        deferred.reject(message)
        return deferred.promise
      }
    })
  }

  // private

  _loadConfirmationDialogs() {
    return this.contentful.entries('content_type=confirmationDialog&limit=200&include=10').then((entry) => {
      return entry.data.items.map((item) => ({
        id: item.fields.name,
        content: item.fields.content,
        title: item.fields.title
      }))
    }).catch((e) => {
      throw new Error('There appears to be a problem with Contentful.')
    })
  }
}

class DialogController {
  /*@ngInject*/
  constructor($scope, $mdDialog, model) {
    this.model = model
    this.confirmDialog = () => $mdDialog.hide()
    this.closeDialog = () => $mdDialog.cancel()
  }
}

export default ConfirmationDialogService
