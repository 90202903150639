export default ['$timeout', function($timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attributes) {
      $timeout(() => {
        angular.element(element[0].querySelector('input.md-input')).bind('blur', function() {
          $timeout(function() {
            scope.$eval(attributes.mdBlur)
          }, 100)
        })
      }, 0)
    }
  }
}]
