import template from './combinedRating.pug'
import controller from './combinedRating.controller'
import './combinedRating.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      riskRating: '=',
      securityRating: '=',
      selected: '=',
      disabled: '=?'
    }
  }
}
