class DebitsProcessingController {
  /*@ngInject*/
  constructor($mdDialog, directDebitsRepository, dateService, abaViewerService) {
    this.name = 'debits-processing'
    this.$mdDialog = $mdDialog
    this.directDebitsRepository = directDebitsRepository
    this.dateService = dateService
    this.abaViewerService = abaViewerService

    this.order = ['-processedDate', -'dueDate']
    this.selectedDebitIds = []

    this.$onInit = () => {
      this.reload()
    }
  }

  reload() {
    const today = this.dateService.now().format('YYYY-MM-DD')
    this.loading = this.directDebitsRepository.where({scope: 'all', status: 'processing'}).then(data => {
      this.directDebits = data.directDebits.filter(directDebit => directDebit.clearedDate > today)
    })
  }

  viewABA($event, abaId, selectedDebitId) {
    this.abaViewerService.show($event, abaId, selectedDebitId)
  }

  reject(debit) {
    return this._confirmRejection(debit).then(() => this._performRejection(debit))
  }

  // private

  _confirmRejection(debit) {
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(`Are you sure you want to mark this $${debit.amount}0 debit as rejected?`)
        .clickOutsideToClose(true)
        .title(`Rejected debit?`)
        .ariaLabel('Reject debit?')
        .ok('Rejected')
        .cancel('No')
    )
  }

  _performRejection(debit) {
    return debit.performAction('reject').then(() => this.reload())
  }
}

export default DebitsProcessingController
