import template from './tenantAnchor.pug'
import controller from './tenantAnchor.controller'
import './tenantAnchor.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      anchorId: '@'
    }
  }
}
