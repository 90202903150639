import template from './yearSelect.pug'
import controller from './yearSelect.controller'
import './yearSelect.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    require: ['^?formRepeatSectionTransclusion'],
    bindToController: {
      model: '=',
      name: '@',
      label: '@'
    },
    link: (scope, ele, attrs, controls) => {
      let [formRepeatSectionTransclusion] = controls
      scope.vm.formRepeatSectionTransclusion = formRepeatSectionTransclusion
    }
  }
}
