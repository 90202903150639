class HelpBoxController {
  /*@ngInject*/
  constructor(localStorageService, $location) {
    this.name = 'helpBox'
    this.localStorageService = localStorageService
    this.$location = $location
  }

  hidden(heading) {
    return heading && this.localStorageService.get(this._toKey(heading))
  }

  close(heading) {
    if (heading) {
      this.localStorageService.set(this._toKey(heading), true)
    }
  }

  open(heading) {
    if (heading) {
      this.localStorageService.remove(this._toKey(heading))
    }
  }

  // private

  _toKey(heading) {
    const path = (this.$location && this.$location.$$path) || '/'
    return `help.${path.replace(/^\//, '').replace(/\/$/, '').replace(/\//g, '-')}.${this._simpleHash(heading)}`
  }

  _simpleHash(s) {
    return Array.from(s).reduce((hash, character) => ((hash << 5) - hash + character.charCodeAt(0)) << 0, 0)
  }
}

export default HelpBoxController
