import angular from 'angular'
import 'angular-ui-router'

import marketplaceHomeComponent from './marketplaceHome.component'

export default angular.module('app.pages.marketplace.marketplaceHome', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('marketplace', {
        url: '/marketplace',
        template: '<marketplace-home/>',
        data: {
          resumeOnLogin: true,
          hideOnMaintenance: true,
          appClasses: ['hero']
        },
        tabName: 'Marketplace'
      })
  }])
  .directive('marketplaceHome', marketplaceHomeComponent)
