import angular from 'angular'

function mergeSource(target, source) {
  for (let key in source) {
    let sourceValue = source[key]
    if (angular.isObject(sourceValue) && !angular.isArray(sourceValue)) {
      target[key] = angular.isObject(target[key]) ? target[key] : {}
      mergeSource(target[key], sourceValue)
    } else {
      target[key] = source[key]
    }
  }
  return target
}
export default function deepMerge(target, ...sources) {
  sources.forEach((source) => mergeSource(target, source))
  return target
}
