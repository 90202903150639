var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {


















pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};
































pug_mixins["ng-messages"] = pug_interp = function(formField){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cng-messages" + (pug.attr("for", formField+'.$error', true, true)+" md-auto-hide=\"false\" role=\"alert\" ng-messages-multiple=\"\"") + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fng-messages\u003E";
};
pug_mixins["inputs"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Ctransclude-replace\u003E\u003C\u002Ftransclude-replace\u003E";
pug_mixins["ng-messages"].call({
block: function(){
pug_html = pug_html + "\u003Cng-message ng-if=\"vm.formField.$touched\" when=\"required\"\u003ERequired\u003C\u002Fng-message\u003E\u003Cdiv ng-if=\"alwaysShowErrors ? vm.formField.$error : vm.formField.$dirty\"\u003E\u003Ctransclude-replace slot=\"extraMessages\"\u003E\u003C\u002Ftransclude-replace\u003E\u003C\u002Fdiv\u003E";
}
}, "vm.formField");
};
pug_html = pug_html + "\u003Cspan ng-if=\"!label\"\u003E\u003Cmd-input-container class=\"md-block\" flex md-no-float\u003E";
pug_mixins["inputs"]();
pug_html = pug_html + "\u003C\u002Fmd-input-container\u003E\u003C\u002Fspan\u003E\u003Cspan ng-if=\"label\"\u003E\u003Cmd-input-container class=\"md-block\" flex\u003E\u003Clabel\u003E{{label}}\u003C\u002Flabel\u003E";
pug_mixins["inputs"]();
pug_html = pug_html + "\u003C\u002Fmd-input-container\u003E\u003C\u002Fspan\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;