import moment from 'moment'
import Big from 'big.js'

class FinalLoanRepaymentController {
  /*@ngInject*/
  constructor($filter, loansRepository) {
    this.name = 'final-loan-repayment'
    this.$filter = $filter
    this.loansRepository = loansRepository

    this.$onInit = () => {
      this.resetStage()
      this.repaymentDate = moment().startOf('day').toDate()

      if (this.debit) {
        this.amount = new Big(this.debit.amount).toString()
        this.dueDate = moment(this.debit.dueDate).startOf('day').toDate()
        this.repaymentDate = moment(this.debit.processedDate).startOf('day').toDate()
      } else {
        this.today = moment().startOf('day').toDate()
        this.dueDate = moment().startOf('day').toDate()
        this.amount = new Big(this.loan.principalOutstanding)
          .plus(new Big(this.loan.interestOutstanding))
          .plus(new Big(this.loan.standardFeeOutstanding))
          .plus(new Big(this.loan.additionalFeeOutstanding))
          .plus(new Big(this.loan.interestAccruedSinceLastPayment))
          .toString()
      }
    }
  }

  resetStage() {
    this.stage = 'initial'
    this.loadingPreview = false
  }

  showPreview() {
    if (!this.loadingPreview) {
      this.loadingPreview = true
      this.loansRepository.getById(this.loan.id + '/final-repayment?date=' + this._toBackendDate(this.repaymentDate) + '&amount=' + this.amount)
        .then((finalRepaymentPreview) => {
          this.finalRepaymentPreview = finalRepaymentPreview
          this.loadingPreview = false
          this.stage = 'preview'
        })
        .catch((error) => {
          this.error = error.data
          this.stage = 'error'
        })
    }
  }

  confirmAction() {
    this._setProcessing()
    const payload = {
      dueDate: this._toBackendDate(this.dueDate),
      repaymentDate: this._toBackendDate(this.repaymentDate),
      amount: this.amount,
      comment: this.comment
    }
    return this.loan.performAction('final-repayment', payload)
      .then((loan) => {
        this.stage = 'complete'
        this.completion.resolve(loan)
        this._unsetProcessing()
      })
      .catch((error) => {
        this.error = error.data
        this.stage = 'error'
        this._unsetProcessing()
      })
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  // private

  _toBackendDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  _setProcessing() {
    this.processing = true
  }

  _unsetProcessing() {
    this.processing = false
  }
}

export default FinalLoanRepaymentController
