class ContactRequestFormController {
  /*@ngInject*/
  constructor(contactRequestsRepository, thirdPartyService) {
    this.name = 'contactRequestForm'

    this.contactRequestsRepository = contactRequestsRepository
    this.thirdPartyService = thirdPartyService
    this.requestFormStatus = 'pre-request'

    this.details = {}
  }

  set contactType(type) {
    this._contactType = type
  }

  get contactType() {
    return this._contactType || 'public'
  }

  submit() {
    const dto = {
      email: this.details.email,
      contactType: this.contactType
    }
    dto.payload = Object.assign({}, this.details)
    const result = this._save(dto)
    this.thirdPartyService.trackLead()
    return result
  }

  requestPreRequest() {
    return this.requestFormStatus === 'pre-request'
  }

  requestInProgress() {
    return this.requestFormStatus === 'in-progress'
  }

  requestSuccessful() {
    return this.requestFormStatus === 'successful'
  }

  // private

  _save(dto) {
    this.requestFormStatus = 'in-progress'
    return this.contactRequestsRepository.create(dto).then((contactRequest) => {
      this.contactRequest = contactRequest
      this.requestFormStatus = 'successful'
    })
  }
}

export default ContactRequestFormController
