import angular from 'angular'
import uploadedFilesModule from './uploadedFiles/uploadedFiles'
import linkedBusinessesModule from './linkedBusinesses/linkedBusinesses'
import representativesModule from '../representatives/representatives'
import bankStatementsModule from './bankStatements/bankStatements'

import summaryComponent from './summary.component.js'

export default angular.module('app.pages.admin.loanEnquiryDetails.summary', [uploadedFilesModule.name, linkedBusinessesModule.name, representativesModule.name, bankStatementsModule.name])
  .directive('adminLoanEnquiryDetailsSummary', summaryComponent)
