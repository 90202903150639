import moment from 'moment'

const dateFormatRFC7231 = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'
const dateFormatRFC850 = 'dddd, DD-MMM-YYYY HH:mm:ss [GMT]'
const dateFormatAnsiC = 'ddd MMM D HH:mm:ss YYYY'

class DateService {
  /*@ngInject*/
  constructor($http, environment) {
    this._$http = $http
    this._environment = environment
    this._offset = 0
    this._updateOffset()
  }

  getSecondsTill(date) {
    const now = this._getCurrentUTCDate().add(this._offset, 'seconds')
    const to = moment.utc(date)
    return now.isSameOrAfter(to) ? 0 : to.diff(now, 'seconds')
  }

  getSecondsSince(date) {
    const now = this._getCurrentUTCDate().add(this._offset, 'seconds')
    const from = moment.utc(date)
    return from.isSameOrAfter(now) ? 0 : now.diff(from, 'seconds')
  }

  isBeforeNow(date) {
    return this._getNowWithOffset().isSameOrAfter(moment(date))
  }

  isAfterNow(date) {
    return this._getNowWithOffset().isSameOrBefore(moment(date))
  }

  now() {
    return moment()
  }

  // private

  _updateOffset() {
    this._$http.head(`${this._environment.TP_API_URL}/`).then((response) => {
      const clientDate = this._getCurrentUTCDate()

      const responseDate = response.headers('date')
      if (!responseDate) {
        return
      }

      const whitespaceNormalisedString = responseDate.trim().replace(/ {2}/, ' ')
      let serverDate = moment(whitespaceNormalisedString, [dateFormatRFC7231, dateFormatRFC850, dateFormatAnsiC], true)
      if (!serverDate.isValid()) {
        // Replicates previous behaviour, but what should happen if this fails too?
        serverDate = moment(new Date(response.headers('date')))
      }

      this._offset = serverDate.diff(clientDate, 'seconds')
    })
  }

  _getNowWithOffset() {
    return this._getCurrentUTCDate().add(this._offset, 'seconds')
  }

  _getCurrentUTCDate() {
    return moment().utc().milliseconds(0)
  }

  // This (private) method is to expose the implementation of moment in the console
  // of the user interface for debugging purposes. You can get hold of it in the
  // user interface console by right-clicking to "Inspect" an element in the user
  // interface that is inside any Angular directive, and then accessing this service
  // with the following command:
  //
  //    const m = angular.element($0).injector().get('dateService')._moment()
  //
  // Now you can do things like: m('2018-05-09T04:36:49.548Z', m.ISO_8601).locale('en').format('D MMM H:mm')
  //
  _moment() {
    return moment
  }

}

export default DateService
