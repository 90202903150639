import jsonpatch from 'fast-json-patch'

class ChangeTracker {

  constructor(model) {
    this.model = model
    this._snapshotOriginal()
  }

  changes() {
    let patches = jsonpatch.compare(this.original, this.model)
    this._snapshotAtChangePoint = this._snapshotModel()
    for(let patch of patches) {
      if(patch.value === undefined) {
        patch.value = null
      }
    }
    return patches
  }

  commit() {
    if(this._snapshotAtChangePoint) {
      this.original = this._snapshotAtChangePoint
    }
  }

  _snapshotOriginal() {
    this.original = this._snapshotModel()
  }

  _snapshotModel() {
    return JSON.parse(JSON.stringify(this.model))
  }
}

export default ChangeTracker
