import angular from 'angular'
import 'angular-ui-router'

import ContentfulDocumentsService from './contentfulDocuments.service'
import ContentfulAssetsService from './contentfulAssets.service'
import contentfulAssetComponent from './contentfulAsset.component'

export default angular.module('app.common.contentfulAsset', [])
  .directive('contentfulAsset', contentfulAssetComponent)
  .service('contentfulDocumentsService', ContentfulDocumentsService)
  .service('contentfulAssetsService', ContentfulAssetsService)
