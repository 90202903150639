import CheckDigitCalculator from '../../../../utils/checkDigitCalculator'

const INVESTOR_ENTITY_TYPES = ['trust', 'company', 'smsf']

class InvestmentEntityFormController {
  /*@ngInject*/
  constructor(userService) {
    this.name = 'investment-entity-form'
    this.userService = userService
  }

  get investmentEntity() {
    if (this.formTracker) {
      if (!this.formTracker.property.investmentEntity) {
        this.formTracker.property.investmentEntity = this._newInvestmentEntity()
      }
      return this.formTracker.property.investmentEntity
    } else {
      return null
    }
  }

  uploadChanged() {
    if (this.formTracker) {
      this.formTracker.update()
    }
  }

  showJobTitle() {
    return this.applicant && this.applicant.hasActiveRole === 'Yes'
  }

  representativeFilter(filterObject) {
    return filterObject !== this.applicant
  }

  get representatives() {
    if (this.formTracker) {
      if (!this.formTracker.property.representatives) {
        this.formTracker.property.representatives = {}
        INVESTOR_ENTITY_TYPES.forEach((type) => {
          this.formTracker.property.representatives[type] = {}
        })
      }
      return this.formTracker.property.representatives
    } else {
      return null
    }
  }

  get applicant() {
    let type = this.investmentEntity.type
    if (this.representatives) {
      if (!this.representatives[type].applicant) {
        this._fillApplicantDetails(type)
      }
      return this.representatives[type].applicant
    }
    return null
  }

  validateTFN() {
    this.investmentEntityForm.taxFileNumber.$setValidity('invalid-tfn')

    if (this.investmentEntity.taxFileNumber.number) {
      let valid = new CheckDigitCalculator(this.investmentEntity.taxFileNumber.number).isTfn()
      this.investmentEntityForm.taxFileNumber.$setValidity('invalid-tfn', valid)
    }
  }

  // private

  _fillApplicantDetails(entityType) {
    let user = this.userService.currentUser
    if (user) {
      INVESTOR_ENTITY_TYPES.forEach((type) => {
        if (type === entityType) {
          this.representatives[type].applicant = {}
          let applicant = this.representatives[type].applicant
          applicant.email = user.email
          applicant.firstName = user.firstName
          applicant.middleName = user.middleName
          applicant.lastName = user.lastName
          if (type === 'company') applicant.ownershipPercent = 0
        } else {
          delete this.representatives[type].applicant
        }
      })
    }
  }

  _newInvestmentEntity() {
    return {
      taxFileNumber: {
        willProvideTFN: 'isProvided'
      }
    }
  }
}
export default InvestmentEntityFormController
