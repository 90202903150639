const filteredPanels = (contentful, prefix, resultFn) => {
  contentful.entries(`content_type=container&limit=200&include=10&query=${prefix}`)
    .then((entries) => {
      const panelMap = entries.data.items
        .filter((item) => item.fields && item.fields.name && item.fields.name.startsWith(prefix))
        .reduce((mapping, item) => {
          mapping.set(item.fields.name, {
            name: item.fields.name,
            panel: item
          })
          return mapping
        }, new Map())
      resultFn(panelMap)
    })
    .catch((error) => {}) // FIXME : Do something???
}

export default filteredPanels
