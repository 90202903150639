import angular from 'angular'
import repaymentsDueModule from './repaymentsDue/repaymentsDue'
import feesDueModule from './feesDue/feesDue'
import debitsProcessingModule from './debitsProcessing/debitsProcessing'
import debitsClearedModule from './debitsCleared/debitsCleared'

import directDebitsComponent from './directDebits.component.js'

export default angular.module('app.pages.admin.adminDashboard.directDebits', [
  repaymentsDueModule.name,
  feesDueModule.name,
  debitsProcessingModule.name,
  debitsClearedModule.name,
])
  .directive('adminDirectDebits', directDebitsComponent)
