class AdminDashboardController {
  /*@ngInject*/
  constructor($scope, router, userService, tenantInformationService) {
    this.name = 'admin-dashboard'
    this.router = router
    this.userService = userService

    this.rolesForState = {
      'adminDashboard.enquiries': ['creditAssessor', 'bdm'],
      'adminDashboard.borrowers': 'creditAssessor',
      'adminDashboard.loanRequests': 'creditAssessor',
      'adminDashboard.loans': 'creditAssessor',
      'adminDashboard.directDebits': 'creditAssessor',
      'adminDashboard.investors': 'creditAssessor',
      'adminDashboard.investorApplications': 'creditAssessor',
      'adminDashboard.investmentProducts': 'creditAssessor',
      'adminDashboard.financials': 'creditAssessor',
      'adminDashboard.withdrawals': 'creditAssessor',
      'adminDashboard.reports': 'creditAssessor'
    }

    let landingState
    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.name.indexOf('adminDashboard') === 0) {
        landingState = toState
        if (this.tabs && this.tabs.length > 0) {
          this.navigateToTab(landingState.name)
        }
      }
    })

    this.tabs = []
    tenantInformationService.getElement('adminTabs').then((tabs) => {
      this.tabs = Object.entries(tabs).map((k) => ({'label': k[0], 'state': k[1]}))
        .filter(tab => this.userCanVisit(tab.state))

      if (landingState) {
        this.navigateToTab(landingState.name)
        landingState = null
      }
    })
  }

  navigateToTab(stateName) {
    let indexOfState = this.tabs.findIndex(tab => stateName.startsWith(tab.state))
    if (indexOfState >= 0) {
      this.tabs[indexOfState].preservedState = stateName
      this.selectedIndex = indexOfState
    } else if (this.userService.hasRole('creditAssessor')) {
      this.selectedIndex = 3
    }
  }

  userCanVisit(state) {
    const role = this.rolesForState[state]
    return Array.isArray(role) ? this.userService.hasRole(...role) : this.userService.hasRole(role)
  }

  onSelectTab(tab) {
    if (tab.preservedState) {
      this.router.go(tab.preservedState)
      delete tab.preservedState
    } else {
      this.router.go(tab.state)
    }
  }
}

export default AdminDashboardController
