import Repository from './repository'

class ApiService {
  /*@ngInject*/
  constructor($http, $q, resourceFactory, environment, loggerService) {
    this._$http = $http
    this._$q = $q
    this._resourceFactory = resourceFactory
    this._environment = environment
    this.logger = loggerService.makeLogger()
    this._cachedLinks = null
    this._repositories = {}
  }

  repository(resource) {
    if (!this._repositories[resource]) {
      this._repositories[resource] = this._createRepository(resource)
    }
    return this._repositories[resource]
  }

  linkFor(resource) {
    return this._links.then((response) => {
      if (!response || !response.data) { this.logger.log('BAD RESPONSE', response) }
      return response && response.data && response.data._links[resource]
    })
  }

  // private

  _createRepository(resource) {
    return new Repository(this._$http, this._$q, this._resourceFactory, this.linkFor(resource))
  }

  get _links() {
    if (this._cachedLinks === null) {
      this._cachedLinks = this._$http.get(`${this._environment.TP_API_URL}/`)
    }
    return this._cachedLinks
  }
}

export default ApiService
