import cuid from 'cuid'
import businessEntityMapping from '../../common/businessEntityMapping'
import valueOrDefault from '../../utils/valueOrDefault'
import isDefined from '../../utils/isDefined'
import {isABNCandidate, isACNCandidate} from '../../utils/isOrganisationIdentifierCandidate'

const normaliseAbn = (candidateAbn) => candidateAbn ? candidateAbn.replace(/[^0-9]+/g, '') : candidateAbn

const formatEntityType = (entityType) => `${valueOrDefault(businessEntityMapping[entityType], {label: '*Unknown entity type*'}).label} (${entityType})`

class AbnLookupController {
  /*@ngInject*/
  constructor($timeout, australianBusinessesRepository, australianCompaniesRepository) {
    this.name = 'abnLookup'
    this.$timeout = $timeout
    this.australianBusinessesRepository = australianBusinessesRepository
    this.australianCompaniesRepository = australianCompaniesRepository

    this.listValidEntityTypes = undefined
    this.localModelName = `_ausBizId_${cuid()}`
    this.localModel = {}

    this.label = valueOrDefault(this.label, 'Please provide your ABN/ACN')

    this.mappings = this.mappings || {
      entityType: {
        CUT: 'Trust',
        DIT: 'Trust',
        DTT: 'Trust',
        FPT: 'Partnership',
        FUT: 'Trust',
        FXT: 'Trust',
        HYT: 'Trust',
        IND: 'Sole trader',
        LPT: 'Partnership',
        OIE: 'Pty. Ltd.',
        PRV: 'Pty. Ltd.',
        PTR: 'Partnership',
        PUB: 'Pty. Ltd.',
        TRT: 'Trust'
      }
    }

    this.$timeout(() => {
      this._resetValidity()
      if (this.hasOwnProperty('abn') || (this.hasOwnProperty('abnModel') && this.hasOwnProperty('acnModel'))) {
        this.abn = (this.localModel[this.localModelName] = this.abn || this.abnModel || this.acnModel)
        this.type = 'ABN/ACN'
      } else if (this.hasOwnProperty('abnModel')) {
        this.abn = (this.localModel[this.localModelName] = this.abnModel)
        this.type = 'ABN'
      } else if (this.hasOwnProperty('acnModel')) {
        this.abn = (this.localModel[this.localModelName] = this.acnModel)
        this.type = 'ACN'
      }
      if (this.localModel[this.localModelName] && this.localModel[this.localModelName].length > 0) {
        this.form[this.localModelName].$setDirty(true)
        this.lookup()
      }
    }, 0)
  }

  lookup() {
    this.response = {}
    if (isDefined(this.validatingNumber) && this.validatingNumber) {
      this.localModel[this.localModelName] = this.validatingNumber
    } else {
      this._setValidity('parsingError', true)
      const candidateNumber = normaliseAbn(this.localModel[this.localModelName])
      if (candidateNumber && this.hasOwnProperty('abnModel') && isABNCandidate(candidateNumber) && ['ABN/ACN', 'ABN'].includes(this.type)) {
        this._lookupABN(candidateNumber)
      } else if (candidateNumber && this.hasOwnProperty('acnModel') && isACNCandidate(candidateNumber) && ['ABN/ACN', 'ACN'].includes(this.type)) {
        this._lookupACN(candidateNumber)
      } else if (!this.required && (!isDefined(candidateNumber) || candidateNumber.length === 0)) {
        this._resetValidity()
      } else {
        this.response = {}
        this._setValidity('parsingError', false)
      }
    }
  }

  get isRequiredClass() {
    return this.required ? 'required' : ''
  }

  // private

  _setValidity(tag, status) {
    this.form[this.localModelName].$setValidity(tag, status)
  }

  _getValidEntityTypes() {
    if (!isDefined(this.listValidEntityTypes)) {
      if (isDefined(this.validEntityTypes)) {
        this.listValidEntityTypes = Array.isArray(this.validEntityTypes) ? this.validEntityTypes : [this.validEntityTypes]
        const listMappedValidEntityTypes = this.listValidEntityTypes.map(validEntityType => formatEntityType(validEntityType))
        this.entityTypeErrorMessageDetail = this.listValidEntityTypes.length === 1 ?
          listMappedValidEntityTypes[0] :
          [
            listMappedValidEntityTypes.slice(0, listMappedValidEntityTypes.length - 1).join(', '),
            ', or ',
            listMappedValidEntityTypes[listMappedValidEntityTypes.length - 1]
          ].join('')
      } else {
        this.listValidEntityTypes = []
      }
    }
    return this.listValidEntityTypes
  }

  _lookupCandidate(candidate, service) {
    this.validatingNumber = candidate
    service.getById(candidate)
      .then((response) => {
        this._handleResponse(response)
      })
      .catch((response) => {
        if (response.status === 404) {
          this._setValidity('businessFound', false)
        } else {
          this._setServiceUnavailable()
        }
      })
      .finally(() => this.validatingNumber = false)
  }

  _lookupABN(candidate) {
    this._lookupCandidate(candidate, this.australianBusinessesRepository)
  }

  _lookupACN(candidate) {
    this._lookupCandidate(candidate, this.australianCompaniesRepository)
  }

  _setServiceUnavailable() {
    this._setValidity('serviceAvailable', false)
  }

  _resetValidity() {
    ['parsingError', 'businessFound', 'serviceAvailable', 'validEntityType', 'registrationValid'].forEach((tag) => this._setValidity(tag, true))
  }

  _handleResponse(response) {
    this._resetValidity()

    const validEntityTypes = this._getValidEntityTypes()
    if (validEntityTypes.length > 0) {
      if (!validEntityTypes.includes(response.entityType)) {
        this._setValidity('validEntityType', false)
        const joiner = validEntityTypes.length > 1 ? 'one of' : 'a'
        this.validEntityTypeErrorMessage = `Invalid entity type, ${formatEntityType(response.entityType)}. Must be ${joiner} ${this.entityTypeErrorMessageDetail}.`
      } else {
        this._setValidity('validEntityType', true)
      }
    } else {
      this._setValidity('validEntityType', true)
    }

    if (isDefined(this.validStatusCodes) && !this.validStatusCodes.toLowerCase().includes(response.statusCode?.toLowerCase())) {
      this._setValidity('registrationValid', false)
      this.form[this.localModelName].$setDirty(true)
    }

    const mapResponse = (key, response) => {
      const field = response && response[key] && response[key]
      return (field && this.mappings[key] && this.mappings[key][field.trim()]) || field
    }

    const VM_MAPPING = [
      {vmPropertyName: 'abnModel', responseKey: 'abn', responseSource: response},
      {vmPropertyName: 'acnModel', responseKey: 'acn', responseSource: response},
      {vmPropertyName: 'nameModel', responseKey: 'name', responseSource: response},
      {vmPropertyName: 'tradingNameModel', responseKey: 'tradingName', responseSource: response},
      {vmPropertyName: 'gstRegisteredSinceModel', responseKey: 'gstRegisteredSince', responseSource: response},
      {vmPropertyName: 'tradingSinceModel', responseKey: 'tradingSince', responseSource: response},
      {vmPropertyName: 'entityTypeModel', responseKey: 'entityType', responseSource: response},
      {vmPropertyName: 'entityDescriptionModel', responseKey: 'entityDescription', responseSource: response},
      {vmPropertyName: 'stateModel', responseKey: 'state', responseSource: response.address},
      {vmPropertyName: 'postcodeModel', responseKey: 'postcode', responseSource: response.address},
      {vmPropertyName: 'statusCodeModel', responseKey: 'statusCode', responseSource: response}
    ]

    this.response = {}
    VM_MAPPING.forEach(({vmPropertyName, responseKey, responseSource}) => {
      this.response[responseKey] = mapResponse(responseKey, responseSource)
      if (this.hasOwnProperty(vmPropertyName)) {
        this[vmPropertyName] = this.response[responseKey]
      }
    })
  }
}

export default AbnLookupController
