import controller from './documents.controller'

export default () => {
  return {
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
