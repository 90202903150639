import template from './keyInfo.pug'
import controller from './keyInfo.controller'
import './keyInfo.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loanRequest: '=loanRequest',
    },
    bindToController: true
  }
}
