import template from './reconciliation.pug'
import controller from './reconciliation.controller'
import './reconciliation.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
