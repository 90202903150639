class DirectDebitsController {
  /*@ngInject*/
  constructor($scope, router) {
    this.name = 'directDebits'
    this.router = router

    this.tabs = [
      {label: 'Repayments', state: 'adminDashboard.directDebits.repayments'},
      {label: 'Fees', state: 'adminDashboard.directDebits.fees'},
      {label: 'Processing', state: 'adminDashboard.directDebits.processing'},
      {label: 'Cleared', state: 'adminDashboard.directDebits.cleared'},
    ]
    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.name.indexOf('adminDashboard.directDebits') === 0) {
        let indexOfState = this.tabs.findIndex(tab => toState.name.startsWith(tab.state))
        if(indexOfState >= 0) {
          this.selectedIndex = indexOfState
        }
      }
    })
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }
}

export default DirectDebitsController
