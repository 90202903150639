import template from './addRepresentative.pug'
import controller from './addRepresentative.controller'
import './addRepresentative.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      entity: '=',
      entityType: '@?',
      completion: '=',
      defaultValues: '=?'
    },
    bindToController: true
  }
}
