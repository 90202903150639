const valueOrDefault = (candidateValue, defaultValue, options = {replaceEmpty: false, replaceBlank: false}) => {
  if (candidateValue === undefined || candidateValue === null) {
    return defaultValue
  } else if (options.replaceEmpty && candidateValue.toString() === '') {
    return defaultValue
  } else if (options.replaceBlank && candidateValue.toString().trim() === '') {
    return defaultValue
  }
  return candidateValue
}

export default valueOrDefault
