import 'firebase'
import notificationTemplate from './notificationTemplate.pug'

class NotificationsService {
  /*@ngInject*/
  constructor($mdToast) {
    this.$mdToast = $mdToast
    this.hideDelay = 60 * 1000 // 1 minute
    this._snapshots = []
    this._toastPosition = 'top left'
  }

  set user(user) {
    if (this._user) {
      this._teardown()
    }
    this._user = user
    if (this._user) {
      this._setup()
    }
  }

  get user() {
    return this._user
  }

  // private

  _teardown() {
    if (this._handler) {
      this._query.off('child_added', this._handler)
    }
    if (this._currentToast) {
      this.$mdToast.hide(this._currentToast)
      this._currentToast = null
    }
    this._snapshots = []
  }

  _setup() {
    this._query = this._createQuery()
    this._handler = this._createHandler()
  }

  _createQuery() {
    return this._createNotificationsRef().orderByChild('read').endAt(null)
  }

  _createNotificationsRef() {
    return new Firebase(this._user._links.notifications.href)
  }

  _createHandler() {
    return this._query.on('child_added', (snapshot) => {
      this._addSnapshot(snapshot)
    })
  }

  _addSnapshot(snapshot) {
    if (this._currentToast) {
      this._snapshots.push(snapshot)
    } else {
      this._toastSnapshot(snapshot)
    }
  }

  _toastSnapshot(snapshot) {
    this._currentToast = this.$mdToast.show(this._toastFor(snapshot))

    this._currentToast.then((response) => {
      this._currentToast = null
      if (response === 'ok') {
        this._markAsRead([snapshot])
      }
      if (response === 'clearAll') {
        this._markAsRead([snapshot])
        this._markAsRead(this._snapshots)
        this._snapshots.length = 0
      }
      let nextSnapshot = this._snapshots.shift()
      if (nextSnapshot) {
        this._toastSnapshot(nextSnapshot)
      }
    })
  }

  _markAsRead(snapshots) {
    for (let snapshot of snapshots) {
      snapshot.ref().child('read').set(Firebase.ServerValue.TIMESTAMP)
    }
  }

  _toastFor(snapshot) {
    let notification = snapshot.val()
    return {
      hideDelay: this.hideDelay,
      position: this._toastPosition,
      controllerAs: 'vm',
      controller: () => {
        return {
          message: notification.message,
          closeToast: () => {
            this.$mdToast.hide('ok')
          },
          clearToasts: () => {
            this.$mdToast.hide('clearAll')
          }
        }
      },
      template: notificationTemplate({})
    }
  }
}

export default NotificationsService
