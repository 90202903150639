import dynamicDialogTemplate from './dynamicDialog.pug'
import DynamicDialogController from './dynamicDialog.controller'
import './dynamicDialog.scss'

class DynamicDialogService {
  /*@ngInject*/
  constructor($mdDialog) {
    this.$mdDialog = $mdDialog
  }

  processDialog(event, config, model) {
    return this.$mdDialog.show({
      targetEvent: event,
      template: dynamicDialogTemplate({}),
      controllerAs: 'vmd',
      locals: {config, model},
      controller: DynamicDialogController
    })
  }

  buildConfig() {
    const config = {
      title: '',
      definition: {},
      actionLabel: 'submit',
      submitUntouched: false,
      rulesContext: {}
    }
    const builder = {
      title: (title) => {
        config.title = title
        return builder
      },
      definition: (definition) => {
        config.definition = definition
        return builder
      },
      actionLabel: (actionLabel) => {
        config.actionLabel = actionLabel
        return builder
      },
      submitUntouched: (submitUntouched) => {
        config.submitUntouched = submitUntouched
        return builder
      },
      rulesContext: (rulesContext) => {
        config.rulesContext = rulesContext
        return builder
      },
      build: () => config
    }
    return builder
  }
}

export default DynamicDialogService
