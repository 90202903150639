import ClipboardJS from 'clipboard'
import resolveProperty from '../../../utils/resolveProperty'

class CreditorWatchAsicExtractDisplayController {
  /*@ngInject*/
  constructor() {
    this.name = 'creditor-watch-asic-extract-display'
    this.clipboard = undefined

    this.$onInit = () => {
      // console.log(`**** EXTRACT **** this.report:`, JSON.stringify(this.report))
      this.clipboard = new ClipboardJS('.clippable')
      this.clipboard.on('success', (event) => {
        event.clearSelection()
      })
    }
    this.$onDestroy = () => {
      this.clipboard.destroy()
    }
  }

  get statusValue() {
    const status = resolveProperty(this.report, 'asicOrgDetails', 'status')
    return status ? status.toString().toLowerCase() : 'unknown'
  }

  concatenator(base, prefix, separator, ...parts) {
    const prefixParts = prefix ? prefix.split('.') : []
    const filteredParts = parts.map(part => resolveProperty(base, ...Array.of(...prefixParts, part))).filter(part => part)
    return filteredParts.join(separator)
  }

}


export default CreditorWatchAsicExtractDisplayController
