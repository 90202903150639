import {LoanNoteRegisterType, LoanNoteRegister} from './loanNoteRegister'
import {MemberRegisterType, MemberRegister} from './memberRegister'
import {LoanSummaryRegisterType, LoanSummaryRegister} from './loanSummaryRegister'

class RegisterService {
  /*@ngInject*/
  constructor(pdfService, csvService) {
    this.pdfService = pdfService
    this.csvService = csvService
  }

  processor(reportType) {
    switch (reportType) {
      case LoanNoteRegisterType:
        return new LoanNoteRegister(this.pdfService, this.csvService)
      case MemberRegisterType:
        return new MemberRegister(this.pdfService, this.csvService)
      case LoanSummaryRegisterType:
        return new LoanSummaryRegister(this.pdfService, this.csvService)
      default:
        throw new Error(`Registers Reports: Unknown report type: '${reportType}'.`)
    }
  }
}
export default RegisterService
