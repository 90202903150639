import {loanEnquiryDetailLinkedBusinesses} from '../../../../../utils/loanEnquiryDetailHelper'
import addBusinessTemplate from './addBusinessDialog/addBusinessDialog.pug'
import AddBusinessController from './addBusinessDialog/addBusinessDialog.controller'
import {isLoanEnquiryMutable} from '../../../../../utils/loanEnquiryStatusHelper'

class LinkedBusinessesController {
  /*@ngInject*/
  constructor($scope, $mdDialog) {
    this.name = 'enquiry-details-linked-businesses'
    this.$mdDialog = $mdDialog
    this.businesses = []

    this.$onInit = () => {
      this.loanEnquiry.promise('loanEnquiryDetails').then(() => {
        $scope.$watch(() => this.loanEnquiry.loanEnquiryDetails, (loanEnquiryDetails) => {
          this.businesses = loanEnquiryDetailLinkedBusinesses(this.loanEnquiry)
        })
      })
    }
  }

  addBusiness($event) {
    return this.$mdDialog.show({
      escapeToClose: true,
      targetEvent: $event,
      template: addBusinessTemplate({}),
      controllerAs: 'vmd',
      locals: {
        loanEnquiry: this.loanEnquiry
      },
      controller: AddBusinessController
    }).then(() => this.loanEnquiry.reloadResource('loanEnquiryDetails'))
  }

  get isMutable() {
    return isLoanEnquiryMutable(this.loanEnquiry)
  }
}

export default LinkedBusinessesController
