import isDefined from './isDefined'

const PHONE_TRANSFORMERS = [
  {regExp: '^0([2-9])(.*)$', format: (matched) => `+61${matched[1]}${matched[2]}`},
  {regExp: '^(1[38]00)(.*)$', format: (matched) => `+${matched[1]}${matched[2]}`},
]

const asURLPhone = (candidate) => {
  if (isDefined(candidate)) {
    const baseCandidate = candidate.toString().replaceAll(/[^0-9a-zA-Z]*/g, '')
    for (const transformer of PHONE_TRANSFORMERS) {
      const matched = baseCandidate.match(transformer.regExp)
      if (Array.isArray(matched)) {
        return `tel:${transformer.format(matched)}`
      }
    }
    return `tel:${candidate.toString().replaceAll(/\s*/g, '')}`
  } else {
    return ''
  }
}

const asURLEmail = (candidate) => {
  return isDefined(candidate) ? `mailto:${candidate.toString().trim()}` : ''
}

export {asURLPhone, asURLEmail}
