import moment from 'moment'
const ISO_FORMAT='YYYY-MM-DD'

class EditDebitsScheduleController {
  /*@ngInject*/
  constructor($scope, $q, directDebitsRepository) {
    this.name = 'edit-debits-schedule'
    this.$q = $q
    this.directDebitsRepository = directDebitsRepository
    this.todayDateISO = moment().format(ISO_FORMAT)
    this.scheduledDebits = []
    this.debitOrder = this.editing ? [] : ['_original.dueDate || dueDate']
    this.debitTypes = ['repayment', 'final']

    this.$onInit = () => {
      this.loadScheduledDebits()
    }
  }

  classFor(debit, fieldName) {
    return (debit._original && debit._original[fieldName] !== debit[fieldName]) ? 'edited' : ''
  }

  addDebit() {
    if (!this.directDebitsForm.$invalid) {
      this.scheduledDebits.map((scheduledDebit) => scheduledDebit._editing = false)
      const debit = this._newDebit()
      debit._editing = true
      this.scheduledDebits.push(debit)
      this.directDebitsForm.$setDirty()
      return debit
    }
    return null
  }

  deleteDebit(debit) {
    debit._deleted = true
    debit._editing = false
    this.directDebitsForm.$setDirty()
  }

  editDebit(debit) {
    if (!this.directDebitsForm.$invalid) {
      this.scheduledDebits.map((scheduledDebit) => scheduledDebit._editing = false)
      debit._editing = true
      debit._original ||= Object.assign({}, debit)
    }
  }

  resetDebit(debit) {
    Object.assign(debit, debit._original)
    debit._editing = false

    if (this.directDebitsForm.$invalid) {
      this.deleteDebit(debit)
    }
  }

  keepDebit(debit) {
    debit._editing = false
  }

  isFormInvalid() {
    if (this.directDebitsForm?.dueDate?.$modelValue) {
      this._validateRepaymentDates(this.directDebitsForm.dueDate)
    }

    return !this?.directDebitsForm.$valid
  }

  loadScheduledDebits() {
    return this.loadingPromise = this.directDebitsRepository.where({scope: 'all', loanId: this.loan.id}).then(data => {
      this.scheduledDebits = data.directDebits
        .filter(directDebit => directDebit.status === 'scheduled')
      this.scheduledDebits.forEach(directDebit => directDebit.dueDate = new Date(directDebit.dueDate))

      this?.directDebitsForm?.$setPristine()
      return this.scheduledDebits
    })
  }

  confirmAction() {
    this.scheduledDebits.map(debit => debit._editing = false)
    let results = this.scheduledDebits.map(debit => {
      if (debit.id) {
        return debit._deleted ? debit.delete() : debit.save()
      } else {
        const newDebit = {
          loanId: debit.loan.id,
          dueDate: debit.dueDate,
          amount: debit.amount,
          description: debit.description,
          status: 'scheduled',
          type: debit.type
        }
        return this.directDebitsRepository.create(newDebit)
      }
    })
    return this.loadingPromise = this.$q.all(results).then((values) => this.completion.resolve(values))
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  maxDate(d1, d2) {
    if (!d1) { return d2 }
    if (!d2) { return d1 }
    return d1 > d2 ? d1 : d2
  }

  // private

  _newDebit() {
    return {
      description: this.scheduledDebits.length === 0 ? 'manual' : '',
      dueDate: this.maxDate(this.todayDateISO, this.loan.nextRepaymentSummary?.dueDate),
      amount: this.loan?.nextRepaymentSummary?.amount || '1000.0',
      loan: this.loan,
      type: 'repayment',
    }
  }

  _validateRepaymentDates(formField) {
    const repaymentDatesMap = this.scheduledDebits.reduce((map, debit) => {
      if (debit.type === 'repayment') {
        const dueDate = moment(debit.dueDate).format(ISO_FORMAT)
        map[dueDate] = (map[dueDate] || 0) + 1
      }
      return map
    }, {})

    const key = moment(formField.$modelValue).format(ISO_FORMAT)
    formField.$setValidity('uniqueDate', (repaymentDatesMap[key] || 0) < 2)
  }
}

export default EditDebitsScheduleController
