class RepresentativeController {
  /*@ngInject*/
  constructor() {
    this.name = 'representative'
  }

  set representative(value) {
    this._representative = value
    if(!this._representative.ownershipPercent) {
      this._representative.ownershipPercent = 0
    }
    if(!this._representative.type) {
      this._representative.type='partner'
    }
  }

  get representative() {
    return this._representative
  }

  get percentagePrompt() {
    if (!this.representative) {return}
    switch (this.businessType) {
      case 'partnership':
        return 'What percentage of the partnership does he/she hold?'
      case 'company':
        return 'What percentage of the company does he/she own?'
      case 'trustee':
        return 'What percentage of the trustee company does he/she own?'
      default:
        return 'What percentage of the company does he/she own?'
    }
  }
}

export default RepresentativeController
