class ThirdPartyService {
  /*@ngInject*/
  constructor(userService, $window) {
    this.userService = userService
    this.$window = $window
  }

  init(scope) {
    scope.$on('$stateChangeSuccess', (event, toState) => {
      // pageviews to google analytics
      if (this.$window && this.$window.ga && typeof this.$window.ga === 'function') {
        this.$window.ga('send', 'pageview')
      }

      // tag in lucky orange
      this.$window._loq = this.$window._loq || []
      this.$window._loq.push(['tag', toState.name])

      // tracking events for facebook pixel
      if (this._hasFacebookQuery()) {
        if (this.userService.isLoggedIn()) {
          delete this.$window.fbq
        } else {
          this.$window.fbq('track', 'ViewContent', {content_name: toState.name})
        }
      }
    })
  }

  trackLead() {
    if (this._hasFacebookQuery()) {
      this.$window.fbq('track', 'Lead')
    }
  }

  // private

  _hasFacebookQuery() {
    return this.$window && this.$window.fbq && typeof this.$window.fbq === 'function'
  }
}

export default ThirdPartyService
