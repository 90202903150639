import angular from 'angular'
import reportsLoanStatsModule from './loanStats/loanStats'
import reportsInvestorRegistryModule from './investorRegistry/investorRegistry'
import reportsJournalEntriesModule from './journalEntries/journalEntries'
import reportsReconciliationModule from './reconciliation/reconciliation'
import reportsRegistersModule from './registers/registers'

import reportsComponent from './reports.component.js'

export default angular.module('app.pages.admin.adminDashboard.reports', [
  reportsLoanStatsModule.name,
  reportsInvestorRegistryModule.name,
  reportsJournalEntriesModule.name,
  reportsReconciliationModule.name,
  reportsRegistersModule.name
])
  .directive('adminReports', reportsComponent)
