class DynamicDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, config, model) {
    this.name = 'dynamic-dialog'
    this.$mdDialog = $mdDialog

    this.dynamicFormConfig = {
      model: model,
      title: config.title,
      definition: config.definition,
      showSubmit: true,
      saveAction: () => $q.resolve(),
      submitAction: (model) => $mdDialog.hide(model),
      actionLabel: config.actionLabel,
      submitUntouched: config.submitUntouched,
      rulesContext: config.rulesContext
    }
  }

  actionClose() {
    return this.$mdDialog.cancel()
  }
}

export default DynamicDialogController
