class IdentityController {
  /*@ngInject*/
  constructor($rootScope, $q, $mdDialog, $mdToast, representativeInvitationsRepository, greenidVerifyService) {
    this.name = 'identity'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.representativeInvitationsRepository = representativeInvitationsRepository
    this.greenidVerifyService = greenidVerifyService
    this.representativeLoadingStati = {}
  }

  get borrower() {
    return this.loanRequest && this.loanRequest.borrower
  }

  get representatives() {
    return this.borrower && this.borrower.representatives
  }

  get items() {
    if (!this._itemsPromise) {
      if (this.representatives) {
        this._itemsPromise = this.$q.all(this.representatives.map((representative) => this._representRepresentative(representative)))
        this._itemsPromise.then((items) => this._items = items)
      }
    }
    return this._items
  }

  addRepresentative($event) {
    const template = `
      <md-dialog flex="50">
        <md-dialog-content layout-padding>
          <add-representative entity='borrower' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template)
  }

  canSendEmail(user) {
    return user && user.email && (!user.identityCheck || user.identityCheck.status !== 'verified')
  }

  canUpdateStatus(item) {
    return item.details.identityEmailSent && item.user && (!item.user.identityCheck || item.user.identityCheck.status !== 'verified')
  }

  canPerformIdentityCheck(item) {
    return item.details.authToken && this.canUpdateStatus(item)
  }

  canCreateAuth0User(item) {
    return item.user && item.user.id && !item.user.id.startsWith('auth0|')
  }

  sendIdentityForm(details) {
    this._startLoading(details)
    this.representativeInvitationsRepository.create({type: 'borrower', id: details.id}).then((response) => {
      details.identityEmailSent = response.representative.identityEmailSent
      this._reload()
    }).catch(() => {
      this._showMessage('There was an error emailing the identity form.')
    }).finally(() => {
      this._finishLoading(details)
    })
  }

  updateRepresentativeIdentityCheckStatus(item) {
    if (item.user.identityCheck) {
      this._startLoading(item.details)
      this.greenidVerifyService.updateStatus(item.user.identityCheck.id).then(() => {
        this._reload()
      }).catch(() => {
        this._showMessage('There was an error updating the identity check status.')
      }).finally(() => {
        this._finishLoading(item.details)
      })
    } else {
      this._reload()
    }
  }

  createAuth0User(item) {
    this._startLoading(item.details)
    this.borrower.performAction('representative-user', {representativeId: item.details.id}).then(() => {
      this._reload()
    }).catch(() => {
      this._showMessage('There was an error creating user.')
    }).finally(() => {
      this._finishLoading(item.details)
    })
  }

  loadingStatus(representative) {
    return this.representativeLoadingStati[representative.id]
  }

  // ****** Private

  _startLoading(representative) {
    this.representativeLoadingStati[representative.id] = true
  }

  _finishLoading(representative) {
    this.representativeLoadingStati[representative.id] = false
  }

  _representRepresentative(representative) {
    return representative.promise('user').then(() => {
      let details = this._cleanRepresentativeJson(representative.toJson())
      let user = this._cleanUserJson(details.user)
      delete details.user
      return {details: details, user: user}
    })
  }

  _cleanRepresentativeJson(details) {
    delete details.name
    delete details.details
    delete details.isBorrower
    delete details.isInvestor
    return details
  }

  _cleanUserJson(json) {
    delete json.firebaseToken
    delete json.investors
    delete json.borrowers
    if (json.canonicalAddress) {
      json.address = json.canonicalAddress.fullAddress
    }
    delete json.canonicalAddress
    delete json.roles
    return json
  }

  _showDialog($event, template) {
    this.loanRequest.promise('borrower').then((borrower) => {
      const scope = this.$rootScope.$new(false)
      scope.borrower = borrower
      scope.completion = this.$q.defer()
      scope.completion.promise
        .then(() => this._reload())
        .finally(() => this.$mdDialog.hide())
      this.$mdDialog.show({
        parent: angular.element(document.body),
        targetEvent: $event,
        template: template,
        scope
      })
    })
  }

  _reload() {
    return this.borrower.reload().then(() => delete this._itemsPromise)
  }

  _showMessage(message) {
    this.$mdToast.show(this.$mdToast.simple().position('top left').hideDelay(5000).textContent(message))
  }
}

export default IdentityController
