var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {pug_mixins["centered-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "centered-content","layout": "row"},attributes]), true)) + "\u003E\u003Cdiv class=\"content\" layout=\"column\" layout-align=\"start stretch\" flex flex-md=\"90\" flex-lg=\"80\" flex-gt-lg=\"70\" flex-offset-md=\"5\" flex-offset-lg=\"10\" flex-offset-gt-lg=\"15\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["contentful-component"] = pug_interp = function(component){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-if", "" + component + ".fields.type === 'wide'", true, true)) + "\u003E\u003Cicon-panel" + (" class=\"wide-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel" + (" class=\"standard-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E";
},
attributes: {"ng-if": pug.escape("" + component + ".fields.type !== 'wide'")}
});
};





























pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};



















































pug_mixins["label-with-help"] = pug_interp = function(text, topic){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"label-with-help\" layout=\"row\"\u003E\u003Clabel\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003Chelp-mate" + (pug.attr("topic", topic, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Flabel\u003E\u003C\u002Fdiv\u003E";
};



















pug_mixins["rateDisplay"] = pug_interp = function(rate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + (pug.escape(null == (pug_interp = rate) ? "" : pug_interp)) + "\u003Cimg src=\"\u002Ficons\u002Ficon-percent-pa.svg\" alt=\"percentage pa\"\u003E";
};












pug_html = pug_html + "\u003Cdiv class=\"auction\" layout=\"column\" ng-if=\"!vm.displayBidWidget\"\u003E\u003Ch2 class=\"tp-layout-padding\"\u003EAuction\u003C\u002Fh2\u003E\u003Cdiv class=\"auction-details\" layout=\"column\" layout-align=\"space-around\" flex layout-padding\u003E\u003Cdiv class=\"interest-rate\"\u003E";
pug_mixins["label-with-help"]("Investor interest rate", "bid-interest-range");
pug_html = pug_html + "\u003Cdiv class=\"rates\" layout=\"row\" flex\u003E\u003Cdiv class=\"text\" ng-if=\"(vm.auction.entryRate - vm.auction.minRate) &gt; 0\"\u003E{{ vm.auction.minRate * 1.0 - vm.auction.investorMarginRate * 1.0 }} \u003Cspan\u003E-\u003C\u002Fspan\u003E {{ vm.auction.entryRate * 1.0 - vm.auction.investorMarginRate * 1.0 }}\u003C\u002Fdiv\u003E\u003Cdiv class=\"text\" ng-if=\"(vm.auction.entryRate - vm.auction.minRate) &lt;= 0\"\u003E{{ vm.auction.minRate * 1.0 - vm.auction.investorMarginRate * 1.0 }}\u003C\u002Fdiv\u003E\u003Csup\u003E% p.a.\u003C\u002Fsup\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bottom-panel\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bottom-panel\"\u003E\u003Cdiv class=\"time-details\"\u003E\u003Cdiv ng-if=\"!vm.isStarted\" layout=\"row\"\u003E\u003Clabel\u003ETime to start:\u003C\u002Flabel\u003E\u003Ccountdown-timer class=\"time-period\" end-date=\"vm.auction.startDate\"\u003E\u003C\u002Fcountdown-timer\u003E\u003Chelp-mate topic=\"time-to-start\" always-show=\"true\"\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"vm.isRunning\" layout=\"row\"\u003E\u003Clabel\u003ETime left:\u003C\u002Flabel\u003E\u003Ccountdown-timer class=\"time-period\" end-date=\"vm.auction.endDate\"\u003E\u003C\u002Fcountdown-timer\u003E\u003Chelp-mate topic=\"time-to-end\" always-show=\"true\"\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"vm.isEnded\" layout=\"row\"\u003E\u003Clabel\u003EDate completed:\u003C\u002Flabel\u003E\u003Cdiv class=\"time-period\"\u003E{{ vm.auction.endDate | date }}\u003C\u002Fdiv\u003E\u003Chelp-mate topic=\"time-completed\" always-show=\"true\"\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"percent-funded\" ng-if=\"vm.isRunning &amp;&amp; vm.percentageFunded &lt; 100\"\u003E";
pug_mixins["label-with-help"]("{{ vm.percentageFunded | number:1 }}% funded", "percentage-funded");
pug_html = pug_html + "\u003Cmd-progress-linear md-mode=\"determinate\" value=\"{{ vm.percentageFunded }}\"\u003E\u003C\u002Fmd-progress-linear\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"actions\" ng-switch=\"vm.bidStatusMessage\"\u003E\u003Cdiv ng-switch-when=\"Enter bid\" layout=\"row\" layout-align=\"center center\" layout-margin\u003E\u003Cmd-button class=\"md-raised md-accent\" id=\"bid-button\" ng-click=\"vm.showBidWidget()\"\u003EEnter bid\u003C\u002Fmd-button\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-switch-when=\"Verify email to bid\" layout=\"row\" layout-align=\"center center\" layout-margin\u003E\u003Cmd-button class=\"md-raised md-warn\" ng-click=\"vm.resendVerificationEmailDialog($event)\"\u003EVerify email to bid\u003C\u002Fmd-button\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-switch-when=\"Add funds to bid\" layout=\"row\" layout-align=\"center center\" layout-margin\u003E\u003Cmd-button class=\"md-raised md-warn\" ui-sref=\"{{ vm.addFundsRef }}\" ui-sref-opts=\"{reload: true}\" aria-label=\"Add funds\"\u003EAdd funds to bid\u003C\u002Fmd-button\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-switch-default layout=\"row\" layout-align=\"center center\" layout-margin\u003E\u003Cmd-button class=\"md-raised\" ng-disabled=\"true\"\u003E{{ vm.bidStatusMessage }}\u003C\u002Fmd-button\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"bid-box\" ng-if=\"vm.displayBidWidget\"\u003E\u003Cbid-widget investor=\"vm.investor\" auction=\"vm.auction\" complete=\"vm.hideWidget\"\u003E\u003C\u002Fbid-widget\u003E\u003C\u002Fdiv\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;