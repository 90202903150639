import template from './editInvestmentProductPartMaturityInstruction.pug'
import controller from './editInvestmentProductPartMaturityInstruction.controller'
import './editInvestmentProductPartMaturityInstruction.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      investmentProductPart: '=',
      investmentProducts: '='
    },
    bindToController: true
  }
}
