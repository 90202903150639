import Resource from './resource'
import LiveResource from './liveResource'

class ResourceFactory {
  /*@ngInject*/
  constructor($http, $q, $firebaseObject, $rootScope) {
    this._$http = $http
    this._$q = $q
    this._$firebaseObject = $firebaseObject
    this._$rootScope = $rootScope
  }

  getResource(data) {
    if (data._links && data._links.live) {
      return LiveResource.create(this._$http, this._$q, this, data, this._$firebaseObject, this._$rootScope)
    } else {
      return Resource.create(this._$http, this._$q, this, data)
    }
  }
}

export default ResourceFactory
