import Chartist from 'chartist'
import RiskBar from './riskBar'

// FIXME pollutes the global namespace
const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
const RISK_LEVELS = {
  'MINIMAL': [1508, 1999],
  'VERY LOW': [1474, 1507],
  'LOW': [1424, 1473],
  'AVERAGE': [1359, 1423],
  'MODERATE': [1323, 1358],
  'HIGH': [1298, 1322],
  'VERY HIGH': [1232, 1297],
  'SEVERE': [1001, 1231],
}

class CreditScoreController {
  /*@ngInject*/
  constructor(entityContextService, $window) {
    this.name = 'credit-score'
    this.entityContextService = entityContextService
    this.$window = $window
    this._clearCache()

    this.loading = true

    this.$onInit = () => {
      entityContextService.current().then((context) => {
        this.entityContextService = context
        this.loading = false
      })
    }
  }

  get reportType() {
    return this.creditReport && this.creditReport.type
  }

  get isFinancial() {
    return this.entityContextService && this.entityContextService.isFinancial()
  }

  get drsChartOptions() {
    return {
      axisX: {
        position: 'start'
      },
      axisY: {
        labelOffset: {x: -20, y: 5},
        offset: 120,
        low: 0,
        high: 8,
        labelInterpolationFnc: (value) => Object.keys(RISK_LEVELS)[value],
      },
      chartPadding: {
        bottom: 25,
      },
      lineSmooth: false,
    }
  }

  get sbrsChartOptions() {
    return {
      axisY: {
        labelOffset: {x: -20, y: 5},
        offset: 120,
        low: 0,
        high: 8,
        labelInterpolationFnc: (value) => Object.keys(RISK_LEVELS)[value],
        showGrid: false
      },
      height: 300,
    }
  }

  get drsChartEvents() {
    if (!this._drsChartEvents) {
      if (!this.creditReport) { return null }

      const series = this._getSeries(this.creditReport.data)
      this._drsChartEvents = {
        created: (data) => {
          this._addAxisTitle(data)
          this._addGradientToGraph(data, series[series.length-1])
        }
      }
    }
    return this._drsChartEvents
  }

  get sbrsChartEvents() {
    if (!this._sbrsChartEvents) {
      if (!this.creditReport) { return null }

      this._sbrsChartEvents = {
        created: (data) => {
          this._addGradientToGraph(data, this._mapRiskScoreToIndex(this.creditReport.data))
        }
      }
    }
    return this._sbrsChartEvents
  }

  get drsChartData() {
    if (!this._drsChartData) {
      if (!this.creditReport) { return null }

      this._drsChartData = {
        labels: this._getLabels(this.creditReport.data),
        series: [this._getSeries(this.creditReport.data)]
      }
    }
    return this._drsChartData
  }

  get drsSmallChartData() {
    if (!this._drsChartData) {
      if (!this.creditReport) { return null }

      this._drsChartData = {
        labels: this._getSmallLabels(this.creditReport.data),
        series: [this._getSeries(this.creditReport.data)]
      }
    }
    return this._drsChartData
  }

  get sbrsChartData() {
    if(!this._sbrsChartData) {
      this._sbrsChartData = {
        labels: [],
        series: []
      }
    }
    return this._sbrsChartData
  }

  get creditReport() {
    if (!this._creditReport) {
      if (this.isFinancial) {
        this._creditReport = this.loanRequest && this.loanRequest.borrowerDetails && this.loanRequest.borrowerDetails.creditReport && this.loanRequest.borrowerDetails.creditReport.report
      } else {
        this._creditReport = {
          'type': 'sample',
          'data': [
            {'month': '4', 'year': '2015', 'score': '1500', 'industry_score': '1400'},
            {'month': '5', 'year': '2015', 'score': '1452', 'industry_score': '1401'},
            {'month': '6', 'year': '2015', 'score': '1500', 'industry_score': '1401'},
            {'month': '7', 'year': '2015', 'score': '1500', 'industry_score': '1402'},
            {'month': '8', 'year': '2015', 'score': '1457', 'industry_score': '1402'},
            {'month': '9', 'year': '2015', 'score': '1452', 'industry_score': '1402'},
            {'month': '10', 'year': '2015', 'score': '1500', 'industry_score': '1402'},
            {'month': '11', 'year': '2015', 'score': '1500', 'industry_score': '1402'},
            {'month': '12', 'year': '2015', 'score': '1500', 'industry_score': '1402'},
            {'month': '1', 'year': '2016', 'score': '1460', 'industry_score': '1402'},
            {'month': '2', 'year': '2016', 'score': '1500', 'industry_score': '1402'},
            {'month': '3', 'year': '2016', 'score': '1500', 'industry_score': '1402'}
          ]
        }
      }
    }
    return this._creditReport
  }

  // private

  _clearCache() {
    this._creditReport = null
    this._drsChartEvents = null
    this._sbrsChartEvents = null
    this._drsChartData = null
    this._sbrsChartData = null
  }

  _addGradientToGraph(data, mostRecentRiskRating) {
    const chartHeight = data.chartRect.height()
    const tickHeight = chartHeight / Object.keys(RISK_LEVELS).length

    new RiskBar().addGradient(
      {
        svg: data.svg,
        height: chartHeight + tickHeight/2,
        width: 10,
        x: 0,
        y: data.chartRect.y2 + tickHeight/4,
        tickHeight: tickHeight,
        mostRecentRiskRating: mostRecentRiskRating,
        window: this.$window,
        type: this.reportType
      }
    )
  }

  _addAxisTitle(data) {
    // TODO: shouldn't be constants
    const xPos = 100
    const yPos = 37

    let title = new Chartist.Svg('text')
    title.addClass('ct-axis-title sample')
    let reportData = this.creditReport.data
    title.text(`${reportData[0].year} - ${reportData[reportData.length-1].year}`)
    title.attr( {x: xPos, y: yPos})
    data.svg.append(title, true)
  }

  _getLabels(drsData) {
    return drsData.map( (period) => MONTHS[period.month-1])
  }

  _getSmallLabels(drsData) {
    return drsData.map( (period) => (period.month % 2 === 0) ? MONTHS[period.month-1] : '' )
  }

  _getSeries(drsData) {
    return drsData.map( (period) => this._mapRiskScoreToIndex(period.score))
  }

  _mapRiskScoreToIndex(riskScore) {
    let index = null
    Object.keys(RISK_LEVELS).forEach( (level, i) => {
      let range = RISK_LEVELS[level]
      if (riskScore >= range[0] && riskScore <= range[1]) { index = i }
    })
    return index
  }
}

export default CreditScoreController
