class BigfootController {
  /*@ngInject*/
  constructor(contentful) {
    this.name = 'footer'

    this.$onInit = () => {
      contentful.entries(`content_type=page&fields.name=${this.name}&include=10`).then((entry) => {
        this.page = entry.data && entry.data.items.length && entry.data.items[0].fields
      })
    }
  }
}

export default BigfootController
