var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {


















pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};
































pug_mixins["ng-messages"] = pug_interp = function(formField){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cng-messages" + (pug.attr("for", formField+'.$error', true, true)+" md-auto-hide=\"false\" role=\"alert\" ng-messages-multiple=\"\"") + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fng-messages\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"dynamic\" ng-if=\"vm.isDynamic\"\u003E\u003Cmd-autocomplete ng-required=\"vm.required\" md-selected-item=\"vm.address\" md-selected-item-change=\"vm.itemChanged(item)\" md-search-text=\"searchText\" md-items=\"item in vm.querySearch(searchText)\" md-item-text=\"item.fullAddress\" md-input-name=\"{{vm.nameForControl()}}\" md-min-length=\"1\" md-delay=\"600\" autocomplete=\"off\" md-blur=\"vm.clearUnselected()\" md-dropdown-items=\"5\" md-clear-button=\"false\" flex\u003E";
pug_mixins["ng-messages"].call({
block: function(){
pug_html = pug_html + "\u003Cng-message when=\"required\"\u003ERequired\u003C\u002Fng-message\u003E\u003Cng-message when=\"noMatch\"\u003ENo matching address found\u003C\u002Fng-message\u003E";
}
}, "vm.form[vm.nameForControl()]");
pug_html = pug_html + "\u003Cmd-item-template\u003E\u003Cspan class=\"address-option\" md-highlight-text=\"searchText\" md-highlight-flags=\"^i\"\u003E{{item.fullAddress}}\u003C\u002Fspan\u003E\u003C\u002Fmd-item-template\u003E\u003Cmd-not-found\u003E\u003Cspan\u003ENo address matches found.\u003C\u002Fspan\u003E\u003C\u002Fmd-not-found\u003E\u003C\u002Fmd-autocomplete\u003E\u003Cinput ng-required=\"vm.required\" name=\"address_hidden_field_{{vm.name}}\" ng-model=\"vm.address\" aria-label=\"Address\" ng-show=\"false\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"static\" ng-if=\"!vm.isDynamic\"\u003E\u003Cmd-autocomplete ng-required=\"vm.required\" md-selected-item=\"vm.address\" md-selected-item-change=\"vm.itemChanged(item)\" md-search-text=\"searchText\" md-items=\"item in vm.querySearch(searchText)\" md-item-text=\"item.fullAddress\" md-input-name=\"{{vm.nameForControl()}}\" md-floating-label=\"{{vm.label}}\" md-min-length=\"1\" md-delay=\"600\" autocomplete=\"off\" md-blur=\"vm.clearUnselected()\" md-dropdown-items=\"5\" flex\u003E\u003Cmd-item-template\u003E\u003Cspan class=\"address-option\" md-highlight-text=\"searchText\" md-highlight-flags=\"^i\"\u003E{{item.fullAddress}}\u003C\u002Fspan\u003E\u003C\u002Fmd-item-template\u003E";
pug_mixins["ng-messages"].call({
block: function(){
pug_html = pug_html + "\u003Cng-message when=\"required\"\u003ERequired\u003C\u002Fng-message\u003E\u003Cng-message when=\"noMatch\"\u003ENo matching address found\u003C\u002Fng-message\u003E";
}
}, "vm.form[vm.nameForControl()]");
pug_html = pug_html + "\u003C\u002Fmd-autocomplete\u003E\u003Cinput ng-required=\"{{vm.required}}\" name=\"address_hidden_field_{{vm.name}}\" ng-model=\"vm.address\" aria-label=\"require\" ng-show=\"false\"\u003E\u003C\u002Fdiv\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;