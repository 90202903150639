import template from './investorStatusChange.pug'
import controller from './investorStatusChange.controller'
import './investorStatusChange.scss'
import './transitionStatusDialog/transitionStatusDialog.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      investor: '=', // The investor to whom the status change is to be applied.
      transitions: '=', // Included possible transitions, as an array of actions (verbs) example ['accept', 'reject'].
    }
  }
}
