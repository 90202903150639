const brokenImageUrl = '/icons/icon_brokenImage.svg'

class CdnAssetController {
  /*@ngInject*/
  constructor(iconsService) {
    this.name = 'cdn-asset'

    const inOrderDefinitionResolver = [
      {predicate: () => (!this.assetId && !this.assetName) || (this.assetId && this.assetName), definition: () => ({status: 'complete', url: brokenImageUrl})},
      {predicate: () => this.assetId, definition: () => iconsService.getByIdentifier(this.assetId)},
      {predicate: () => this.assetName, definition: () => iconsService.getByName(this.assetName)},
    ]

    this.$onInit = () => {
      this.assetDefinition = inOrderDefinitionResolver.find((resolver) => resolver.predicate()).definition()
    }
  }

  get assetStatus() {
    return this.assetDefinition.status
  }

  get assetComputedStyle() {
    const styling = this.assetStyle ?
      Object.entries(this.assetStyle).reduce((obj, pair) => {obj[pair[0]] = pair[1]; return obj}, {}) :
      {}
    styling['background'] = `url(${this.assetDefinition.status === 'complete'? this.assetDefinition.url : brokenImageUrl}) no-repeat`
    return styling
  }
}

export default CdnAssetController
