import countries from './countries'

class CountrySelectController {
  /*@ngInject*/
  constructor($timeout) {
    this.name = 'countrySelect'
    this.$timeout = $timeout
  }

  get countries() {
    return countries
  }

  querySearch(query) {
    return query ? this.countries.filter(this._filter(query)) : this.countries
  }

  selectedItemChange() {
    if(this.patchForm) {
      this.$timeout(() => {
        this.patchForm.update()
      })
    }
  }

  nameForControl() {
    if(this.formRepeatSectionTransclusion) {
      return `${this.formRepeatSectionTransclusion.transcludedName}-${this.name}`
    } else {
      return `${this.name}`
    }
  }

  _filter(query) {
    return (item) => {
      return (item.toLowerCase().indexOf(query.toLowerCase()) === 0)
    }
  }
}

export default CountrySelectController
