import template from './attachmentUpload.pug'
import controller from './attachmentUpload.controller'
import './attachmentUpload.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      attachTo: '=',
      maxAttachments: '@',
      attachmentLinks: '=',
      required: '@',
      ngRequired: '=',
      onChange: '&',
      name: '@'
    },
  }
}
