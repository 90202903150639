import assert from '../../utils/assert'
import Big from 'big.js'

const EXPECTED_RISKS = ['', '5', '4', '3', '2', '1']
const EXPECTED_SECURITIES = ['A', 'B', 'C', 'D', 'E']
const EXPECTED_HEADERS = ['', ...EXPECTED_SECURITIES, '', '', ...EXPECTED_SECURITIES]

const MAP_RISK_RATINGS = {
  '5': 'A',
  '4': 'B',
  '3': 'C',
  '2': 'D',
  '1': 'E',
}

const INDEX_RISK_MIN = 1
const INDEX_RISK_MAX = INDEX_RISK_MIN + EXPECTED_SECURITIES.length + 2

const transformRawRatingsMinMax = (rawData, makeKey) => {

  assert(typeof rawData === 'string', 'Should be transforming a string.')

  const rawDataLines = rawData.split('\n')

  const header = rawDataLines[0]
  assert(header === EXPECTED_HEADERS.join('\t'), `Unexpected headers (actual <-> expected): ${header} <-> ${EXPECTED_HEADERS}.`)

  return rawDataLines.reduce((minMax, rawLine, rowIndex) => {
    if (rowIndex !== 0) {
      const values = rawLine.split('\t')
      EXPECTED_SECURITIES.forEach((security, colIndex) => {
        const mappedRiskRating = MAP_RISK_RATINGS[EXPECTED_RISKS[rowIndex]]
        minMax[makeKey(mappedRiskRating, security)] = {
          risk: mappedRiskRating,
          security: security,
          rateMin: new Big(values[INDEX_RISK_MIN + colIndex]),
          rateMax: new Big(values[INDEX_RISK_MAX + colIndex])
        }
      })
    }
    return minMax
  }, {})
}

export default transformRawRatingsMinMax
