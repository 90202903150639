import ChangeTracker from './changeTracker'

class FormTracker {

  constructor(resource, property) {
    this.resource = resource
    this.property = property
    this.changeTracker = new ChangeTracker(property)
  }

  update() {
    if(this.hasNoOutstandingRequests()) {
      let changes = this.changeTracker.changes()
      if(changes.length > 0) {
        this.makeRequest(changes)
      }
    } else {
      this.pendingUpdates = true
    }
  }

  makeRequest(changes) {
    this.updateInProgress = true
    this.resource.patch({'changeset': changes}).then(() => {
      this.changeTracker.commit()
    }).finally(() => {
      this.updateInProgress = false
      if(this.pendingUpdates) {
        this.pendingUpdates = false
        this.update()
      }
    })
  }

  hasNoOutstandingRequests() {
    return !this.updateInProgress
  }

  setFormValid(form, valid) {
    this.stateFor(form).valid = valid
  }

  isFormValid(form) {
    return this.validStates[form] && this.validStates[form].valid
  }

  setValidateInitial(form) {
    this.stateFor(form).validateInitial = true
  }

  setFormVisited(form) {
    this.stateFor(form).visited = true
  }

  formVisited(form) {
    return this.validStates[form] && this.validStates[form].visited
  }

  shouldValidate(form) {
    let state = this.stateFor(form)
    return state && (state.visited || state.validateInitial)
  }

  get validStates() {
    if(!this._validStates) {
      this._validStates = {}
    }
    return this._validStates
  }

  stateFor(form) {
    if(!this.validStates[form]) {
      this.validStates[form] = {}
    }
    return this.validStates[form]
  }

  valid() {
    for (const formState of Object.getOwnPropertyNames(this.validStates)) {
      if(!this.validStates[formState].valid) {
        return false
      }
    }
    return this.hasNoOutstandingRequests()
  }
}

export default FormTracker
