import angular from 'angular'

import investorSignupModule from './investorSignup/investorSignup'
import investorApplicationModule from './investorApplication/investorApplication'
import investorQualifyingModule from './investorQualifying/investorQualifying'
import investorDashboardModule from './investorDashboard/investorDashboard'

export default angular.module('app.pages.investors', [
  investorSignupModule.name,
  investorApplicationModule.name,
  investorQualifyingModule.name,
  investorDashboardModule.name
])
