import moment from 'moment'

class CreateLoanRepaymentController {
  /*@ngInject*/
  constructor($filter, $mdDialog, loanRepaymentsRepository) {
    this.name = 'create-loan-repayment'
    this.$filter = $filter
    this.$mdDialog = $mdDialog
    this.loanRepaymentsRepository = loanRepaymentsRepository

    this.$onInit = () => {
      this.disbursementDate = moment().startOf('day').toDate()
      this.skipFees = false
      this.skipPrincipal = false

      if (this.debit) {
        this.amount = parseFloat(this.debit.amount)
        this.dueDate = moment(this.loan.nextRepaymentSummary.dueDate).startOf('day').toDate()
        this.repaymentDate = moment(this.debit.processedDate).startOf('day').toDate()
      }

      if (this.type === 'additional') {
        this.skipInterest = false
      } else {
        if (!this.debit && this.loan.nextRepaymentSummary) {
          this.dueDate = moment(this.loan.nextRepaymentSummary.dueDate).startOf('day').toDate()
          this.expectedDate = moment(this.loan.nextRepaymentSummary.expectedDate).startOf('day').toDate()
          if (moment().startOf('day').isSameOrBefore(moment(this.loan.nextRepaymentSummary.expectedDate).startOf('day'))) {
            this.repaymentDate = moment(this.loan.nextRepaymentSummary.dueDate).startOf('day').toDate()
          }
          this.amount = parseFloat(this.loan.nextRepaymentSummary.amount)
        }

        this.skipInterest = this.loan.repaymentType === 'principalOnly' || this.loan.status === 'defaulted'
        this.skipFees = this.loan.status === 'defaulted'
      }
    }
  }

  confirmAction() {
    let dialogBody
    if (this.type === 'additional') {
      dialogBody = `<div class="disbursement-confirmation">Are you sure that you want to disburse the repayment?
        <table>
          <tr><th>Disbursement amount:</th><td>${this._toDisplayAmount(this.amount)}</td></tr>
          <tr><th>Due date:</th><td>${this._toDisplayDate(this.dueDate)}</td></tr>
          <tr><th>Repayment date:</th><td>${this._toDisplayDate(this.repaymentDate)}</td></tr>
          <tr><th>Comment:</th><td>${this.comment || ''}</td></tr>
        </table>
      </div>`
    } else {
      dialogBody = `<div class="disbursement-confirmation">Are you sure that you want to disburse the repayment?
        <table>
          <tr><th>Disbursement amount:</th><td>${this._toDisplayAmount(this.amount)}</td></tr>
          <tr><th>Due date:</th><td>${this._toDisplayDate(this.dueDate)}</td></tr>
          <tr><th>Repayment date:</th><td>${this._toDisplayDate(this.repaymentDate)}</td></tr>
          <tr><th>Disbursement date:</th><td>${this._toDisplayDate(this.disbursementDate)}</td></tr>
          <tr><th>Comment:</th><td>${this.comment || ''}</td></tr>
          <tr><th>Skip fees:</th><td>${this.skipFees || 'false'}</td></tr>
          <tr><th>Skip principal:</th><td>${this.skipPrincipal || 'false'}</td></tr>
          <tr><th>Skip interest:</th><td>${this.skipInterest || 'false'}</td></tr>
        </table>
      </div>`
    }
    const options = this.$mdDialog.confirm({
      onComplete: (scope, element, options) => {
        const actionsSection = element.find('md-dialog-actions')
        const cancelButton = actionsSection.children()[0]
        const confirmButton = actionsSection.children()[1]
        angular.element(confirmButton).removeClass('md-focused')
        angular.element(cancelButton).addClass('md-focused')
        cancelButton.focus()
      }
    })
      .htmlContent(dialogBody)
      .clickOutsideToClose(true)
      .title('Confirm?')
      .ariaLabel('Confirm?')
      .multiple(true)
      .ok('Disburse')
        .cancel('Cancel')
    return this.$mdDialog.show(options)
        .then(() => this._create(), () => this.cancelAction())
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  // private

  _create() {
    this.$mdDialog.show({
      template: `
        <md-dialog flex="50">
          <md-dialog-content class="disbursement-processing" layout-padding layout="column" layout-align="center center">
            <h2>Processing loan repayment</h2> 
            <md-progress-circular md-mode="indeterminate" md-diameter="200"></md-progress-circular>
          </md-dialog-content>
        </md-dialog>
      `,
      escapeToClose: false,
      clickOutsideToClose: false
    })
    const payload = {
      loanId: this.loan.id,
      type: this.type || 'standard',
      dueDate: this._toBackendDate(this.dueDate),
      repaymentDate: this._toBackendDate(this.repaymentDate),
      disbursementDate: this._toBackendDate(this.disbursementDate),
      amount: this.amount,
      comment: this.comment,
      skipFees: this.skipFees,
      skipPrincipal: this.skipPrincipal,
      skipInterest: this.skipInterest
    }
    if (this.debit) {
      payload.directDebitId = this.debit.id
    }

    return this.loanRepaymentsRepository.create(payload)
      .then((loanRepayment) => this.completion.resolve(loanRepayment))
      .finally(() => this.$mdDialog.hide())
  }

  _toBackendDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  _toDisplayDate(date) {
    return this.$filter('asMoment')(moment(date), 'ddd D MMM YYYY')
  }

  _toDisplayAmount(amount) {
    return this.$filter('asCurrency')(amount)
  }
}

export default CreateLoanRepaymentController
