const makeContentItemResponse = (name, content, status) => ({name, content, status})

class ContentItemsService {
  /*@ngInject*/
  constructor(contentful, $filter, loggerService) {
    this.contentful = contentful
    this.logger = loggerService.makeLogger()
    this.$filter = $filter
    this._cachedData = undefined
  }

  getContentItemByName(name) {
    if (!this._cachedData) {
      this._cachedData = this._loadContentItems()
    }
    return this._cachedData.then((data) => {
      const content = data.get(name)
      if (!content) {
        this.logger.warn(`Requested content item with name '${name}' does NOT exist.`)
        return makeContentItemResponse(name, `[🪲 - missing contentItem: ${name} - 🪲]`, 404)
      }
      return content
    })
  }

  // private

  _loadContentItems() {
    return this.contentful.entries('content_type=contentItem&limit=200&include=10').then((entry) => {
      return entry.data.items.reduce((cache, item) => {
        cache.set(item.fields.name, makeContentItemResponse(item.fields.name, this.$filter('fromMarkdown')(item.fields.content), 200))
        return cache
      }, new Map())
    })
  }
}

export default ContentItemsService
