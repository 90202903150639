import createEnum from '../../utils/createEnum'
import resolveProperty from '../../utils/resolveProperty'
import valueOrDefault from '../../utils/valueOrDefault'
import isDefined from '../../utils/isDefined'

const NAVIGATION_STATUS = createEnum('PENDING', 'LOADED', 'FAILED')

const optionalConcat = (name, optional, separator = '-') => [name, optional].filter(part => part).join(separator)

const resolveAction = (item, actions, logger) => {
  const action = actions.get(item.action)
  if (action) {
    return action
  }
  const message = `NavBar: Missing action '${item.action}' for item '${item.name}'.`
  logger.notify(message)
  logger.error(message)
  throw new Error(message)
}

const resolveRenderExpression = (item, actions, logger) => {
  const expressionLocal = resolveProperty(item, 'presentation', 'render')
  return expressionLocal ? expressionLocal : resolveAction(item, actions, logger).render
}

const resolveActionDefinition = (item) => resolveProperty(item, 'actionDefinition')
const resolveActionOpts = (item) => valueOrDefault(resolveProperty(resolveActionDefinition(item), 'opts'), '').split(/[, ]+/)
const resolveActionGaClick = (item, actions, logger) => valueOrDefault(resolveProperty(resolveAction(item, actions, logger), 'gaClick'), '')

class NavbarController {
  /*@ngInject*/
  constructor(loginRouter, navigation,
              userService, entityContextService,
              featureTogglesService, applicationSettingsService, rulesService,
              loggerService,
              $scope, $timeout, $mdDialog, $state) {
    this.name = 'navbar'
    this.loginRouter = loginRouter
    this.navigation = navigation
    this.userService = userService
    this.entityContextService = entityContextService
    this.featureTogglesService = featureTogglesService
    this.applicationSettingsService = applicationSettingsService
    this.rulesService = rulesService
    this.$mdDialog = $mdDialog
    this.$timeout = $timeout

    this.logger = loggerService.makeLogger()

    this.definitionActions = new Map()
    this.definitionDesktop = []
    this.definitionMobile = []
    this.definitionAuth = {}

    this.status = NAVIGATION_STATUS.PENDING

    this.state = $state.$current

    $scope.$on('$stateChangeSuccess', (event, toState) => {
      $timeout(() => {
        this.state = $state.$current
      }, 0)
    })

    $scope.$watch(() => navigation.isLoaded && this.status === NAVIGATION_STATUS.PENDING, () => {
      if (navigation.isLoaded) {
        this.definitionActions = navigation.definitionActions().reduce((mappedActions, action) => {
          mappedActions.set(action.name, action)
          return mappedActions
        }, new Map())
        this.definitionDesktop = navigation.definitionDesktop()
        this.definitionMobile = navigation.definitionMobile()
        this.definitionAuth = navigation.definitionAuth()
        this.status = NAVIGATION_STATUS.LOADED
      }
    })
  }

  get navigationLoaded() {
    return this.status === NAVIGATION_STATUS.LOADED
  }

  get actionDefinitions() {
    return this.definitionActions
  }

  get navItemsDesktop() {
    return this.definitionDesktop.filter((item) => this.isRendered(item))
  }

  get navItemsMobile() {
    return this.definitionMobile.filter((group) => group.menuItems.some(item => this.isRendered(item)))
  }

  get authNavItems() {
    return this.definitionAuth
  }

  tabHighlight(item) {
    const parentTab = resolveProperty(item, 'presentation', 'parentTab')
    const parentTabName = valueOrDefault(resolveProperty(this.state, 'parentTabName'), '')
    const tabName = valueOrDefault(resolveProperty(this.state, 'tabName'), '')
    return parentTab ? {'ink-bar': parentTabName === parentTab || tabName === parentTab} : []
  }

  ariaLabel(item, suffix = undefined) {
    const ariaLabel = valueOrDefault(resolveProperty(item, 'content', 'ariaLabel'), this.label(item).toLowerCase())
    return ariaLabel ? optionalConcat(ariaLabel, suffix, ' ') : ''
  }

  label(item) {
    const label = resolveProperty(item, 'content', 'label')
    return label ? label : ''
  }

  addNameClass(item, suffix = '') {
    return optionalConcat(resolveProperty(item, 'name'), suffix)
  }

  addAuxiliaryClasses(item) {
    const additionalClasses = {}
    const classes = resolveProperty(item, 'presentation', 'classes')
    if (classes) {
      const arrayOfClasses = classes.split(/[ ,]+/)
      arrayOfClasses.map((className) => {
        additionalClasses[className] = true
      })
    }
    return additionalClasses
  }

  isRendered(item) {
    if (!isDefined(item)) {
      return false
    }
    // FIXME : The following values are now part of the rules' appContext.
    //         The model can be removed from options once the dependent rule definitions (in Contentful) have
    //         been updated to use the appContext rather than the model for these values.
    //         This can ONLY be done after this code has been deployed to production.
    const options = {
      model: {
        applicationInProgress: this.applicationInProgress,
        investorApplicationInProgress: this.investorApplicationInProgress,
        isAdmin: this.isAdmin,
        isInvestor: this.isInvestor,
        isLoggedIn: this.isLoggedIn,
      },
      toggleResolver: (toggleName) => this.featureTogglesService.can(toggleName),
      applicationSettingResolver: (settingName) => this.applicationSettingsService.setting(settingName)
    }
    const expression = resolveRenderExpression(item, this.actionDefinitions, this.logger)
    return expression ? this.rulesService.parse(expression, options) : true
  }

  menuOffset(item) {
    const offset = resolveProperty(item, 'presentation', 'offset')
    return offset ? offset : '20 0'
  }

  actionType(expectedType, item) {
    return resolveAction(item, this.actionDefinitions, this.logger).type === expectedType
  }

  navRefAction(item) {
    const ref = resolveAction(item, this.actionDefinitions, this.logger).action
    if (ref) {
      return ref
    }
    const refMessage = `NavBar: Unknown ref action for navigation item named '${item.name}'.`
    this.logger.notify(refMessage)
    this.logger.error(refMessage)
    throw new Error(refMessage)
  }

  navClickAction(item) {
    const clickAction = resolveAction(item, this.actionDefinitions, this.logger).action
    switch (clickAction) {
      case 'dashboard':
        return this.gotoDashboard()
      case 'login':
        return this.login()
      default:
        const refMessage = `NavBar: Unknown action '${clickAction}' for navigation item named '${item.name}'.`
        this.logger.notify(refMessage)
        this.logger.error(refMessage)
        throw new Error(refMessage)
    }
  }

  navActionOpts(item) {
    const actionOpts = resolveActionOpts(item)
    const opts = {}
    actionOpts.forEach((opt) => {
      switch (opt) {
        case '':
          break
        case 'reload':
          opts.reload = true
          break
        default:
          const optMessage = `NavBar: Unknown ui-ref-opt of '${opt}', for item named '${item.name}'.`
          this.logger.notify(optMessage)
          this.logger.error(optMessage)
          throw new Error(optMessage)
      }
    })
    return opts
  }

  navGaClick(item) {
    const gaClick = resolveActionGaClick(item, this.actionDefinitions, this.logger)
    return gaClick ? gaClick : ''
  }

  isAvailable(toggleName) {
    return this.featureTogglesService.can(toggleName)
  }

  login() {
    this.userService.login().then(() => {
      this.loginRouter.onLogin()
    })
  }

  logout() {
    this.userService.logout().then(() => {
      this.entityContextService.logout()
      if (this.loginRouter.currentRouteData().requireAuth) {
        this.loginRouter.gotoHome()
      } else {
        this.loginRouter.currentRouteReload()
      }
    })
  }

  get isAdmin() {
    return this.userService && this.userService.isAdmin()
  }

  get title() {
    if (this.state && this.state.data && this.state.data.title) {
      this._title = this.state.data.title
    }
    return this._title
  }

  gotoDashboard() {
    this.loginRouter.gotoDashboard({reload: true})
  }

  openSidenav() {
    this.navigation.showSideNav()
  }

  closeSidenav() {
    return this.navigation.hideSideNav()
  }

  selectEntity(index, andClose) {
    this.entityContextService.selected = index
    this.loginRouter.gotoDashboard({reload: true})
    if (andClose) {
      this.closeSidenav()
    }
  }

  get entities() {
    return this.entityContextService.entities()
  }

  get selected() {
    return this.entityContextService.selected
  }

  set selected(index) {
    this.selectEntity(index, false)
  }

  get selectedEntity() {
    return this.entityContextService.selectedEntity()
  }

  get isLoggedIn() {
    return !!this.userService.isLoggedIn()
  }

  get isInvestor() {
    return !!this.entityContextService.investor
  }

  get investorApplicationInProgress() {
    return !!this.entityContextService.investorApplication
  }

  get applicationInProgress() {
    return this.investorApplicationInProgress
  }

  get preferredName() {
    return this.userService.preferredName
  }

  get currentUser() {
    return this.userService.currentUser
  }

  toggleMenu($mdMenu, $mdMenuIsOpen, $event) {
    if (this.$mdMenu && this.$mdMenu._isOpen) {
      this.$mdMenu.close()
      delete this.$mdMenu._isOpen
    }

    if ($mdMenuIsOpen) {
      $mdMenu.close()
      delete $mdMenu._isOpen
    } else {
      $mdMenu.open($event)
      $mdMenu._isOpen = true
    }
    this.$mdMenu = $mdMenu
  }

  showChangePreferredNamePrompt($event) {
    const confirm = this.$mdDialog.prompt()
      .title(this.userService.fullName())
      .textContent('Choose your preferred name.')
      .placeholder('Preferred name')
      .ariaLabel('Preferred name')
      .initialValue(this.userService.preferredName)
      .targetEvent($event)
      .ok('Okay!')
      .cancel('Cancel')

    this.$mdDialog.show(confirm).then((preferredName) => {
      this.userService.preferredName = preferredName
    })
  }
}

export default NavbarController
