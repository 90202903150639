export default {
  mainRoute: 'investorApplication',
  applicationFinished: 'investorDashboard.summary',
  registrationIndex: 0,
  consentIndex: 2,
  steps: [
    {
      name: 'myDetails',
      state: 'investorApplication.myDetails',
      title: 'About you',
      subtitle: '2 mins',
    },
    {
      name: 'investmentEntity',
      state: 'investorApplication.investmentEntity',
      title: 'Investment entity',
      validationProperty: 'entity',
      subtitle: '1-4 mins',
      admin: true
    },
    {
      name: 'consentForm',
      state: 'investorApplication.consentForm',
      title: 'Consent & submit',
      validationProperty: 'consents',
      subtitle: '2 mins',
    }
  ]
}
