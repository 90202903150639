export default function() {
  return {
    restrict: 'E',
    template: `<svg viewBox="0 0 100 10" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <rect x="0" y="2" width="0" height="8" fill="black"></rect>
              </svg>`,
    link: function link(scope, element, attributes, controller) {

      const targetNode = element[0]
      const rectNode = targetNode.querySelector('rect')

      rectNode.setAttribute('fill', controller.colour)
      rectNode.setAttribute('width', controller.quantity)
    },
    controller: function() {},
    scope: {},
    bindToController: {
      quantity: '=',
      colour: '='
    }
  }
}
