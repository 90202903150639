export default () => {
  return {
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      ngModel.$parsers.push((value) => {
        return (value === null || value === undefined) ? '' : ('' + value).split('%')[0]
      })
      ngModel.$formatters.push((value) => {
        return parseFloat(value) + '%'
      })
    }
  }
}
