import angular from 'angular'
import 'angular-ui-router'
import reviewInvestorApplicationModule from './reviewInvestorApplication/reviewInvestorApplication'
import investorRepresentativesModule from './investorRepresentatives/investorRepresentatives'
import investorTransactionsModule from './investorTransactions/investorTransactions'
import investorManageModule from './investorManage/investorManage'

import investorApplicationDetailsComponent from './investorApplicationDetails.component'

export default angular.module('app.pages.admin.investorApplicationDetails',
  ['ui.router', reviewInvestorApplicationModule.name, investorRepresentativesModule.name, investorTransactionsModule.name, investorManageModule.name])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('adminInvestorApplication', {
        url: '/admin/investor-applications/:id',
        template: '<admin-investor-application-details/>',
        replace: true,
        data: {
          requireAuth: true,
          title: 'Investor application details'
        }
      })
      .state('adminInvestorApplication.application', {
        url: '/application',
        template: '<admin-review-investor-application investor-application="vm.investorApplication"/>',
      })
      .state('adminInvestorApplication.representative', {
        url: '/representative',
        template: '<admin-investor-representatives investor="vm.investorApplication.investor"/>',
      })
      .state('adminInvestorApplication.transactions', {
        url: '/transactions',
        template: '<admin-investor-transactions investor="vm.investorApplication.investor"/>',
      })
      .state('adminInvestorApplication.manage', {
        url: '/manage',
        template: '<admin-investor-manage investor="vm.investorApplication.investor" applicant="vm.investorApplication.applicant"/>',
      })
  }])
  .directive('adminInvestorApplicationDetails', investorApplicationDetailsComponent)
