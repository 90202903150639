import Big from 'big.js'

import businessEntityMapping from '../../../../common/businessEntityMapping'
import resolveProperty from '../../../../utils/resolveProperty'
import valueOrDefault from '../../../../utils/valueOrDefault'
import createEnum from '../../../../utils/createEnum'
import concatNames from '../../../../utils/concatNames'
import {loanEnquiryDetailAnswerByCondition} from '../../../../utils/loanEnquiryDetailHelper'
import {isRepresentativeVerifiedAndConsenting} from '../../../../utils/representativeHelper'
import reportDialogTemplate from './reportDialog/reportDialog.pug'
import ReportDialogController from './reportDialog/reportDialog.controller'
import overrideDialogTemplate from './overrideDialog/overrideDialog.pug'
import OverrideDialogController from './overrideDialog/overrideDialog.controller'
import {isLoanEnquiryMutable} from '../../../../utils/loanEnquiryStatusHelper'

const IN_ORDER_ENTITY_TYPE_DEFINITIONS = [
  {type: 'borrower', abn: ['abn-search', 'creditor-watch-credit-report'], acn: ['creditor-watch-extract-report']},
  {type: 'trustee', abn: ['creditor-watch-credit-report'], acn: ['creditor-watch-extract-report']},
  {type: 'vendor', abn: ['creditor-watch-credit-report'], acn: ['creditor-watch-extract-report']},
  {type: 'acquired business', abn: ['creditor-watch-credit-report'], acn: ['creditor-watch-extract-report']},
  {type: 'representative', abn: ['equifax-commercial-credit-report']},
  {type: 'cross directorship', abn: ['creditor-watch-credit-report'], acn: ['creditor-watch-extract-report']},
  {type: 'other business', abn: ['creditor-watch-credit-report'], acn: ['creditor-watch-extract-report']},
]
const ASSESSMENT_ACTION = createEnum('NONE', 'PENDING', 'ERROR')

const createEntity = (entityTypeDefinition, resource) => {
  let _results = []
  let _completedReportIds = new Set()
  let _currentReportId = undefined
  const resolverFor = (kind) => [
    {
      types: ['representative'],
      keyResolver: () => resource.id,
      nameResolver: () => concatNames(resource.user.firstName, resource.user.lastName),
      structureResolver: () => 'IND'
    },
    {
      types: ['vendor', 'acquired business', 'cross directorship', 'other business'],
      keyResolver: () => loanEnquiryDetailAnswerByCondition(resource, (qanda) => qanda.name === 'businessABNACN' || qanda.name === 'businessABN'),
      nameResolver: () => loanEnquiryDetailAnswerByCondition(resource, (qanda) => qanda.name === 'businessName'),
      structureResolver: () => resource.businessStructure || loanEnquiryDetailAnswerByCondition(resource, (qanda) => qanda.name === 'businessStructure'),
    },
    {
      types: [],
      keyResolver: () => resource.abnAcn,
      nameResolver: () => resource.businessName,
      structureResolver: () => resource.businessStructure || 'PRV'
    }
  ].filter((keyDefinition) => keyDefinition.types.length === 0 || keyDefinition.types.includes(entityTypeDefinition.type))
    .map((keyDefinition) => keyDefinition[`${kind}Resolver`]())[0]
  return {
    assessment: {
      errorMessage: '',
      errors: [],
      action: ASSESSMENT_ACTION.NONE,
      get hasError() {
        return this.action === ASSESSMENT_ACTION.ERROR
      },
      get results() {
        return _results
      },
      set results(results) {
        _completedReportIds = new Set(results.map((result) => result.ruleId))
        _results = results
      }
    },
    get type() {
      return entityTypeDefinition.type
    },
    get reports() {
      return [...entityTypeDefinition.abn, ...(this.isCompany ? entityTypeDefinition.acn : [])]
    },
    get pendingReports() {
      return this.reports.filter(reportId => !_completedReportIds.has(reportId))
    },
    get key() {
      return resolverFor('key')
    },
    get name() {
      return resolverFor('name')
    },
    get structure() {
      return resolverFor('structure')
    },
    get resource() {
      return resource
    },
    get hasPendingAssessments() {
      return this.pendingReports.length > 0
    },
    get isCompany() {
      return !!businessEntityMapping[this.structure]?.label?.match(/company/i)
    },
    get currentReportId() {
      return _currentReportId
    },
    set currentReportId(reportId) {
      _currentReportId = reportId
    }
  }
}

const findOrCreateEntity = (results, entityTypeDefinition, resource, matchingAttribute = 'id') => {
  const candidateEntity = results.find(result => result.type === entityTypeDefinition.type && result.resource[matchingAttribute] === resource[matchingAttribute])
  if (candidateEntity) {
    return candidateEntity
  }
  const newEntity = createEntity(entityTypeDefinition, resource)
  results.push(newEntity)
  return newEntity
}

const processAssessmentResults = (entity, results) => {
  if (results && Array.isArray(results)) {
    entity.assessment.results = entity.type === 'representative' ?
      results.filter(result => result.individual && result.individual.representativeId === entity.key) :
      results.filter(result => result.business && (result.business.abn === entity.key || result.business.acn === entity.key))
  }
}

const evaluateStageStatus = (results) => {
  const summary = results.reduce((intermediate, result) => {
    intermediate[result.status] = (intermediate[result.status] || 0) + 1
    return intermediate
  }, {})
  return summary['fail'] > 0 ? 'fail' : (summary['override'] > 0 ? 'override' : (summary['pass'] > 0 ? 'pass' : 'unknown'))
}

const filterIncludeCompleteLinkedBusinesses = (loanEnquiryDetail) => loanEnquiryDetail.type === 'linked-business' && loanEnquiryDetail.status === 'complete'
const filterIncludeCurrentType = (type) => (loanEnquiryDetail) => loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'type') === type

const processAssessableEntities = (loanEnquiry) => {
  const results = []
  const summary = valueOrDefault(resolveProperty(loanEnquiry, 'loanEnquirySummary'), {})
  IN_ORDER_ENTITY_TYPE_DEFINITIONS
    .forEach((entityTypeDefinition) => {
      switch (entityTypeDefinition.type) {
        case 'representative':
          loanEnquiry.promise('representatives').then(representatives => {
            if (representatives && Array.isArray(representatives) && representatives.length > 0) {
              representatives.forEach((candidateRepresentative) => {
                candidateRepresentative.promise('user').then(() => {
                  if (isRepresentativeVerifiedAndConsenting(candidateRepresentative)) {
                    processAssessmentResults(findOrCreateEntity(results, entityTypeDefinition, candidateRepresentative), loanEnquiry.assessmentResults)
                  }
                })
              })
            }
          })
          break
        case 'vendor':
        case 'acquired business':
        case 'cross directorship':
        case 'other business':
          loanEnquiry.promise('loanEnquiryDetails').then(loanEnquiryDetails => {
            if (loanEnquiryDetails && Array.isArray(loanEnquiryDetails) && loanEnquiryDetails.length > 0) {
              loanEnquiryDetails
                .filter(filterIncludeCompleteLinkedBusinesses)
                .filter(filterIncludeCurrentType(entityTypeDefinition.type))
                .forEach((loanEnquiryDetail) => processAssessmentResults(findOrCreateEntity(results, entityTypeDefinition, loanEnquiryDetail), loanEnquiry.assessmentResults))
            }
          })
          break
        default:
          if (summary.hasOwnProperty(entityTypeDefinition.type)) {
            processAssessmentResults(findOrCreateEntity(results, entityTypeDefinition, summary[entityTypeDefinition.type], 'abnAcn'), loanEnquiry.assessmentResults)
          }
          break
      }
    })
  return results
}

class AssessmentController {
  /*@ngInject*/
  constructor($scope, $mdDialog, $rootScope, $q, router, usersRepository) {
    this.name = 'enquiry-details-assessment'
    this.$mdDialog = $mdDialog
    this.$rootScope = $rootScope
    this.$q = $q
    this.router = router
    this.usersRepository = usersRepository

    this.rules = {
      'abn-search': {
        title: 'ABN search',
        submit: (event, assessable) => this._processRule(event, assessable, {'ruleId': 'abn-search', 'ruleParams': {'abnOrAcn': assessable.key}})
      },
      'creditor-watch-credit-report': {
        title: 'Creditor watch report',
        submit: (event, assessable) => this._processRule(event, assessable, {'ruleId': 'creditor-watch-credit-report', 'ruleParams': {'abnOrAcn': assessable.key}})
      },
      'creditor-watch-extract-report': {
        title: 'Creditor watch ASIC extract report',
        submit: (event, assessable) => this._processRule(event, assessable, {'ruleId': 'creditor-watch-extract-report', 'ruleParams': {'abnOrAcn': assessable.key}})
      },
      'equifax-commercial-credit-report': {
        title: 'Equifax commercial credit report',
        submit: (event, assessable) => this._processRule(event, assessable, {'ruleId': 'equifax-commercial-credit-report', 'ruleParams': {'representativeId': assessable.key}})
      }
    }

    this.processLoanEnquiry = (loanEnquiry) => {
      this._updateUserCache(loanEnquiry)
      this.assessableEntities = processAssessableEntities(loanEnquiry)
      return loanEnquiry
    }

    this.$onInit = () => {
      this.processLoanEnquiry(this.loanEnquiry)
      this.loanEnquiry.promise('representatives').then(() => {
        $scope.$watch(() => this.loanEnquiry.representatives, () => this.processLoanEnquiry(this.loanEnquiry))
      })
      this.loanEnquiry.promise('loanEnquiryDetails').then(() => {
        $scope.$watch(() => this.loanEnquiry.loanEnquiryDetails, () => this.processLoanEnquiry(this.loanEnquiry))
      })
    }
  }

  get isMutable() {
    return isLoanEnquiryMutable(this.loanEnquiry)
  }

  compoundStatus(status) {
    const classes = this.isMutable ? ['active'] : []
    classes.push(status)
    return classes.join(' ')
  }

  getUserNameById(userId) {
    if (this.resourceCache.has('user-' + userId)) {
      const user = this.resourceCache.get('user-' + userId)
      return user.status === 'pending' ? `pending … ${userId}` : [user.user.firstName, user.user.lastName].filter((name) => name && name.toString().trim().length > 0).join(' ')
    }
  }

  viewReport($event, report, formatter= undefined) {
    $event.stopPropagation()
    return this.$mdDialog.show({
      targetEvent: $event,
      template: reportDialogTemplate({}),
      controllerAs: 'vmd',
      locals: {
        report,
        formatter
      },
      controller: ReportDialogController
    })
  }

  isExtractReport(report) {
    return resolveProperty(report, 'ruleId') === 'creditor-watch-extract-report'
  }

  overrideAction($event, ruleUuid, resultLine) {
    $event.preventDefault()
    if (!this.isMutable || resultLine.status !== 'fail') {
      return 'skip'
    }
    return this.$mdDialog.show({
      targetEvent: $event,
      template: overrideDialogTemplate({}),
      controllerAs: 'vmd',
      locals: {
        loanEnquiry: this.loanEnquiry,
        ruleUuid, resultLine
      },
      controller: OverrideDialogController
    }).then((response) => this.processLoanEnquiry(response))
  }

  stageStatus(results) {
    return evaluateStageStatus(results)
  }

  hasFailed(status) {
    return status && ['fail', 'override'].includes(status.toString().trim().toLowerCase())
  }

  hasBeenOverridden(status) {
    return status && status.toString().trim().toLowerCase() === 'override'
  }

  description(value) {
    return value ? value : 'This value is invalid.'
  }

  reportRepresentativeDirectors(result) {
    return this._hasRepresentatives(result, 'directors')
  }

  reportRepresentativeShareholders(result) {
    return this._hasRepresentatives(result, 'shareholders')
  }

  hasReportRepresentativeDirectors(result) {
    return this.reportRepresentativeDirectors(result).length > 0
  }

  hasReportRepresentativeShareholders(result) {
    return this.reportRepresentativeShareholders(result).length > 0
  }

  hasReportRepresentatives(result) {
    return this.hasReportRepresentativeDirectors(result) || this.hasReportRepresentativeShareholders(result)
  }

  directorName(director) {
    return ['firstName', 'middleName', 'lastName'].reduce((names, nameKey) => {
      if (director.hasOwnProperty(nameKey) && director[nameKey] && director[nameKey].toString().trim().length > 0) {
        names.push(director[nameKey])
      }
      return names
    }, []).join(' ')
  }

  asShareholderPercentage(percent) {
    if (percent && typeof percent === 'string' && percent.trim().match(/\d+([.]?\d+)/)) {
      const value = new Big(percent)
      return `${value.toFixed(0)}%`
    }
    return percent
  }

  // ******* private

  _hasRepresentatives(result, representativeType) {
    return (result && result.representatives &&
      result.representatives[representativeType] && Array.isArray(result.representatives[representativeType]) && result.representatives[representativeType]) || []
  }

  _updateUserCache(enquiry) {
    if (enquiry.assessmentResults && Array.isArray(enquiry.assessmentResults)) {
      enquiry.assessmentResults.reduce((collectedUserIds, result) => {
        collectedUserIds.add(result.userId)
        result.results.forEach(resultLine => {
          if (resultLine.hasOwnProperty('override')) {
            collectedUserIds.add(resultLine.override.userId)
          }
        })
        return collectedUserIds
      }, new Set()).forEach(userId => {
        if (!this.resourceCache.has('user-' + userId)) {
          this.resourceCache.set('user-' + userId, {id: userId, status: 'pending'})
          this.usersRepository.getById(userId).then(user => {
            this.resourceCache.set('user-' + userId, {id: userId, status: 'ok', user: user})
          })
        }
      })
    }
  }

  _processRule(event, assessable, parameters) {
    assessable.assessment.action = ASSESSMENT_ACTION.PENDING
    event.preventDefault()
    this.loanEnquiry.performAction('assess', parameters).then(result => {
      this.processLoanEnquiry(result)
      assessable.assessment.action = ASSESSMENT_ACTION.NONE
    }, errorResult => {
      assessable.assessment.action = ASSESSMENT_ACTION.ERROR
      assessable.assessment.errorMessage = errorResult.data.message
      assessable.assessment.errors = errorResult.data.errors
    })
  }
}

export default AssessmentController
