var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {pug_mixins["centered-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "centered-content","layout": "row"},attributes]), true)) + "\u003E\u003Cdiv class=\"content\" layout=\"column\" layout-align=\"start stretch\" flex flex-md=\"90\" flex-lg=\"80\" flex-gt-lg=\"70\" flex-offset-md=\"5\" flex-offset-lg=\"10\" flex-offset-gt-lg=\"15\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["contentful-component"] = pug_interp = function(component){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-if", "" + component + ".fields.type === 'wide'", true, true)) + "\u003E\u003Cicon-panel" + (" class=\"wide-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel" + (" class=\"standard-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E";
},
attributes: {"ng-if": pug.escape("" + component + ".fields.type !== 'wide'")}
});
};





























pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};




































































pug_mixins["named-panel"] = pug_interp = function(caption){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "named-panel","layout": "column","layout-align": "start stretch"},attributes]), true)) + "\u003E\u003Cdiv layout=\"column\" layout-align=\"start center\"\u003E\u003Cdiv class=\"caption\"\u003E" + (pug.escape(null == (pug_interp = caption) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_mixins["rateDisplay"] = pug_interp = function(rate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + (pug.escape(null == (pug_interp = rate) ? "" : pug_interp)) + "\u003Cimg src=\"\u002Ficons\u002Ficon-percent-pa.svg\" alt=\"percentage pa\"\u003E";
};












pug_html = pug_html + "\u003Cdiv class=\"contact-request-form\"\u003E\u003Cform layout=\"column\" name=\"vm.contactRequestForm\" api-form=\"vm.submit()\"\u003E\u003Cfieldset\u003E\u003Cdiv class=\"referrer-details\"\u003E";
pug_mixins["named-panel"].call({
block: function(){
pug_html = pug_html + "\u003Cinput-container label=\"What is your name?\"\u003E\u003Cinput ng-disabled=\"vm.requestSuccessful()\" ng-required=\"true\" name=\"name\" ng-model=\"vm.details.name\"\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"What is your email address?\"\u003E\u003Cinput ng-disabled=\"vm.requestSuccessful()\" ng-required=\"true\" name=\"email\" type=\"email\" ng-model=\"vm.details.email\" pattern=\".*@.*\\..*\"\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"What is your phone number?\"\u003E\u003Cinput ng-disabled=\"vm.requestSuccessful()\" ng-required=\"true\" type=\"tel\" name=\"phone\" ng-model=\"vm.details.phone\" ng-model-options=\"{ debounce: 500 }\" pattern=\"^ *[()-]*(\\d[() -]*){8,10}\"\u003E\u003Cng-message when=\"pattern\"\u003Ee.g. 04XX-XXX-XXX, 0X XXXX XXXX\u003C\u002Fng-message\u003E\u003C\u002Finput-container\u003E\u003Cdiv class=\"submit-details\"\u003E\u003Cmd-button class=\"submit-request md-raised md-accent ios-rendering-fix\" ng-if=\"vm.requestPreRequest()\" type=\"submit\" ga-click=\"enquiry.broker-submit\"\u003ESubmit enquiry\u003C\u002Fmd-button\u003E\u003Cmd-progress-circular class=\"md-accent\" ng-if=\"vm.requestInProgress()\" md-mode=\"indeterminate\" md-size=\"40\"\u003E\u003C\u002Fmd-progress-circular\u003E\u003Cdiv class=\"success\" ng-if=\"vm.requestSuccessful()\"\u003E\u003Ch3\u003EThanks for your enquiry, we'll be in touch soon!\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
}, "We'll contact you");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Ffieldset\u003E\u003C\u002Fform\u003E\u003C\u002Fdiv\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;