class YearSelectController {
  /*@ngInject*/
  constructor() {
    this.name = 'yearSelect'
  }

  get years() {
    if(!this._years) {
      this._years = []
      for(let i = this._thisYear; i >= 1900; i--) {
        this._years.push(`${i}`)
      }
    }
    return this._years
  }

  nameForControl() {
    if(this.formRepeatSectionTransclusion) {
      return `${this.formRepeatSectionTransclusion.transcludedName}-${this.name}`
    } else {
      return `${this.name}`
    }
  }

  get _thisYear() {
    return new Date().getFullYear()
  }
}

export default YearSelectController
