class FormRepeatSectionController {
  /*@ngInject*/
  constructor() {
    this.name = 'formRepeat'
    this._ensureModelValue()
  }

  set collection(value) {
    this._collection = value
    this._ensureModelValue()
  }

  get collection() {
    return this._collection
  }

  addFormObject() {
    if(!this.collection) {
      this.collection = {}
    }
    let id = new Date().getTime()
    this.collection[id] = {}
  }

  removeFormObject(id) {
    delete this.collection[id]
    this.formTracker.update()
  }

  showRemove(index) {
    if(this.required && index === 0) {
      return false
    }
    return true
  }

  filterObject(formObject) {
    if(this.filter) {
      if(angular.isFunction(this.filter)) {
        return this.filter({formObject: formObject})
      }
    }
    return true
  }

  _ensureModelValue() {
    if(this.required && (!this.collection || Object.keys(this.collection).length === 0)) {
      this.addFormObject()
    }
  }
}

export default FormRepeatSectionController
