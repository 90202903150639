import {loanEnquiryDetailsByStatus, loanEnquiryDetailsSortByUpdatedAt, extractEntity} from '../../../../utils/loanEnquiryDetailHelper'
import resolveProperty from '../../../../utils/resolveProperty'
import makeAddRepresentative from '../representatives/addRepresentative'
import nameSplitter from '../../../../utils/nameSplitter'

const FIELD_NAMES_BORROWER = ['borrowerName', 'borrowerFirstName', 'borrowerMiddleNames', 'borrowerLastName',
  'borrowerPhone', 'borrowerEmail', 'borrowerAddress', 'borrowerOwnsProperty', 'borrowerOwnedAddress']
const FIELD_NAMES_GUARANTOR = ['guarantorName', 'guarantorFirstName', 'guarantorMiddleNames', 'guarantorLastName',
  'guarantorPhone', 'guarantorEmail', 'guarantorAddress', 'guarantorOwnsProperty', 'guarantorOwnedAddress']
const FIELD_NAMES_COLLATERAL = ['propertyOwner']
const FIELD_NAMES_ASSET_VENDOR_SOURCE = ['vendorSource']
const FIELD_NAMES_ASSET_PROPOSED_DEPOSIT = ['proposedDeposit']
const FIELD_NAMES_TRAIL_BOOK_TARGET = ['trailBookLoanTarget']
const FIELD_NAMES_ASSET_PRICE = ['assetPrice']

class SummaryController {
  /*@ngInject*/
  constructor($scope, $q, $mdDialog, $state) {
    this.name = 'enquiry-details-summary'
    this.$scope = $scope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$state = $state

    this.representativeUsers = []
  }

  $onInit() {
    this.$scope.$on('loanEnquiryEvent', (event, ...args) => {
      if (args[0] === 'reloadRepresentatives') {
        event.preventDefault()
        event.stopPropagation()
        this.$state.reload()
      }
    })

    this.loanEnquiry.promise('loanEnquiryDetails').then(loanEnquiryDetails => {
      const mostRecentCompletedLoanEnquiryDetails = loanEnquiryDetailsSortByUpdatedAt(loanEnquiryDetailsByStatus(loanEnquiryDetails, 'complete'))

      this.referrerDetails = (() => {
        const mostRecentLoanEnquiryDetailsWithReferrer = mostRecentCompletedLoanEnquiryDetails
          .find(loanEnquiryDetails => loanEnquiryDetails.response && loanEnquiryDetails.response.referrerDetails)
        return mostRecentLoanEnquiryDetailsWithReferrer ? mostRecentLoanEnquiryDetailsWithReferrer.response.referrerDetails : undefined
      })()

      this.borrowerDetails = extractEntity(loanEnquiryDetails, FIELD_NAMES_BORROWER)
      if (this.borrowerDetails) {
        const nameParts = nameSplitter(resolveProperty(this.borrowerDetails, 'borrowerName'))
        this.addBorrowerRepresentative = makeAddRepresentative(this.$scope, this.$q, this.$mdDialog, this.loanEnquiry, () => ({
          firstName: this.borrowerDetails.borrowerFirstName ||= nameParts.firstName,
          middleName: this.borrowerDetails.borrowerMiddleNames ||= nameParts.middleName,
          lastName: this.borrowerDetails.borrowerLastName ||= nameParts.lastName,
          email: this.borrowerDetails.borrowerEmail,
          phone: this.borrowerDetails.borrowerPhone
        }))
      }

      this.guarantorDetails = extractEntity(loanEnquiryDetails, FIELD_NAMES_GUARANTOR)
      if (this.guarantorDetails) {
        const nameParts = nameSplitter(resolveProperty(this.guarantorDetails, 'guarantorName'))
        this.addGuarantorRepresentative = makeAddRepresentative(this.$scope, this.$q, this.$mdDialog, this.loanEnquiry, () => ({
          firstName: this.guarantorDetails.guarantorFirstName ||= nameParts.firstName,
          middleName: this.guarantorDetails.guarantorMiddleNames ||= nameParts.middleName,
          lastName: this.guarantorDetails.guarantorLastName ||= nameParts.lastName,
          email: this.guarantorDetails.guarantorEmail,
          phone: this.guarantorDetails.guarantorPhone
        }))
      }

      this.assetDetails = {}
      extractEntity(loanEnquiryDetails, FIELD_NAMES_ASSET_VENDOR_SOURCE, this.assetDetails)
      extractEntity(loanEnquiryDetails, FIELD_NAMES_ASSET_PROPOSED_DEPOSIT, this.assetDetails)
      extractEntity(loanEnquiryDetails, FIELD_NAMES_ASSET_PRICE, this.assetDetails)

      this.trailBook = extractEntity(loanEnquiryDetails, FIELD_NAMES_TRAIL_BOOK_TARGET)
      this.collateralDetails = extractEntity(loanEnquiryDetails, FIELD_NAMES_COLLATERAL)
      this.nCinoLeadId = extractEntity(loanEnquiryDetails, ['leadId'])
    })

    this.loanEnquiry.promise('representatives').then((representatives) => {
      representatives.forEach((representative) => {
        representative.promise('user').then((user) => {
          this.representativeUsers.push(user)
        })
      })
    })
  }

  isBorrowerRepresentative(details) {
    return this.representativeUsers.find((representativeUser) =>
       (details['borrowerEmail'] === representativeUser.email || details['borrowerPhone'] === representativeUser.phone) &&
         (details['borrowerName'] === representativeUser.name ||
         (details['borrowerFirstName'] === representativeUser.firstName && details['borrowerLastName'] === representativeUser.lastName))
    )
  }

  isGuarantorRepresentative(details) {
    return this.representativeUsers.find((representativeUser) =>
      (details['guarantorEmail'] === representativeUser.email || details['guarantorPhone'] === representativeUser.phone) &&
        (details['guarantorName'] === representativeUser.name ||
        (details['guarantorFirstName'] === representativeUser.firstName && details['guarantorLastName'] === representativeUser.lastName))
    )
  }

  ownedProperty(details, prefix) {
    prefix ||= 'borrower'

    // snapforms
    if (details[prefix + 'OwnedAddress']) {
      return details[prefix + 'OwnedAddress']?.fullAddress || details[prefix + 'OwnedAddress']
    }

    // tp
    switch(details[prefix + 'OwnsProperty']?.toLowerCase()) {
      case 'another':
        return details[prefix + 'OwnedAddress']?.fullAddress || details[prefix + 'OwnedAddress']
      case 'yes':
        return details[prefix + 'Address']?.fullAddress || details[prefix + 'Address']
      default:
        return 'none'
    }
  }
}

export default SummaryController
