import {resolveInvestorBankDetails, hasInvestorBankDetails} from '../../../../../common/investorBankDetails/investorBankDetails'

const DEFAULT_MINIMUM_WITHDRAWAL = 50

class WithdrawalRequestController {
  /*@ngInject*/
  constructor(entityContextService, withdrawalRequestsRepository, emailVerificationService, $mdDialog) {
    this.name = 'withdrawal-request'
    this.withdrawalRequestsRepository = withdrawalRequestsRepository
    this.emailVerificationService = emailVerificationService
    this.$mdDialog = $mdDialog
    this.requestFormStatus = 'pre-request'

    this.$onInit = () => {
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        this._instrument(this.investor)

        this.details = {}
        resolveInvestorBankDetails(investor, this.details)
      })
    }
  }

  showRequestForm() {
    this.requestFormStatus = 'form-entry'
  }

  requestFundWithdrawal() {
    return this._confirm(() => {
      this.requestFormStatus = 'in-progress'
      return this.withdrawalRequestsRepository.create(this._toCreateDto())
        .then((response) => {
          this.requestFormStatus = 'successful'
          this.investor.reload()
        })
        .catch((response) => {
          this.requestFormStatus = 'failed'
          this.investor.reload()
        })
    })
  }

  get requestPreRequest() {
    return this.requestFormStatus === 'pre-request'
  }

  get requestFormEntry() {
    return this.requestFormStatus === 'form-entry'
  }

  get requestInProgress() {
    return this.requestFormStatus === 'in-progress'
  }

  get requestSuccessful() {
    return this.requestFormStatus === 'successful'
  }

  get requestFailed() {
    return this.requestFormStatus === 'failed'
  }

  get emailVerified() {
    return this.emailVerificationService.emailVerified()
  }

  resendVerificationEmail() {
    this.emailVerificationService.resendVerificationEmail()
  }

  hasBankDetails() {
    return hasInvestorBankDetails(this.details)
  }

  // private

  _instrument(investor) {
    investor.minimumWithdrawal = DEFAULT_MINIMUM_WITHDRAWAL
    investor.canWithdraw = investor.availableFunds >= investor.minimumWithdrawal

    return investor.promise('investorSetting').then((settings) => {
      if (settings.permissions && settings.permissions.smallWithdrawals) {
        investor.minimumWithdrawal = 0.01
        investor.canWithdraw = investor.availableFunds >= investor.minimumWithdrawal
      }
      return investor
    })
  }

  _confirm(onFulfilled) {
    let options = this.$mdDialog.confirm()
      .textContent('Are you sure you want to make the withdrawal?')
      .clickOutsideToClose(true)
      .title('Confirm withdrawal')
      .ariaLabel('Confirm?')
      .ok('Confirm')
      .cancel('Cancel')
    let onRejected = () => {
      return false
    }
    return this.$mdDialog.show(options).then(onFulfilled, onRejected)
  }

  _toCreateDto() {
    return {
      investorId: this.investor.id,
      accountName: this.details.accountName,
      bsb: this.details.bsb,
      accountNumber: this.details.accountNumber,
      amount: this.details.amount,
      ownBankAccount: this.details.ownBankAccount,
      authorised: this.details.authorised
    }
  }

  _getValue(value, defaultIfUndefinedOrNull) {
    return angular.isUndefined(value) || value === null ? defaultIfUndefinedOrNull : value
  }

}

export default WithdrawalRequestController
