class InvestorTransactionsController {
  /*@ngInject*/
  constructor($scope) {
    this.name = 'investor-transactions'
    this.includeBetweenReservedAndAvailable = false

    $scope.$watch(() => this.investor, () => {
      this.changeQuery()
    })
  }

  changeQuery() {
    if (this.investor) {
      this.conditions = {scope: 'for_investor', id: this.investor.id, includeBetweenReservedAndAvailable: this.includeBetweenReservedAndAvailable}
    }
  }

}

export default InvestorTransactionsController
