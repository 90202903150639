import {loanEnquiryDetailAnswerByCondition, loanEnquiryDetailsSortByUpdatedAt} from '../../../../../utils/loanEnquiryDetailHelper'
import sendBankStatementsRequestTemplate from './sendBankStatementsRequestDialog/sendBankStatementsRequestDialog.pug'
import SendBankStatementsRequestDialogController from './sendBankStatementsRequestDialog/sendBankStatementsRequestDialog.controller'
import {isLoanEnquiryMutable} from '../../../../../utils/loanEnquiryStatusHelper'

class BankStatementsController {
  /*@ngInject*/
  constructor($scope, $mdDialog) {
    this.name = 'enquiry-details-bank-statements'
    this.$mdDialog = $mdDialog
    this.bankStatementRequests = []

    this.$onInit = () => {
      this.loanEnquiry.promise('loanEnquiryDetails').then(() => {
        $scope.$watch(() => this.loanEnquiry.loanEnquiryDetails, (loanEnquiryDetails) => {
          this.bankStatementRequests = []

          loanEnquiryDetailsSortByUpdatedAt(loanEnquiryDetails, false)
            .filter((loanEnquiryDetail) => loanEnquiryDetail.type === 'electronic-bank-statements')
            .forEach((loanEnquiryDetail) => {
              this.bankStatementRequests.push({
                id: loanEnquiryDetail.id,
                email: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'electronicBankStatements.clientEmail'),
                firstName: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'electronicBankStatements.clientFirstName'),
                lastName: loanEnquiryDetailAnswerByCondition(loanEnquiryDetail, (qanda) => qanda.name === 'electronicBankStatements.clientLastName'),
                status: loanEnquiryDetail.status
              })
            })
        })
      })
    }
  }

  get isMutable() {
    return isLoanEnquiryMutable(this.loanEnquiry)
  }

  reloadLoanEnquiryDetails() {
    return this.loanEnquiry.reloadResource('loanEnquiryDetails')
  }

  sendBankStatementsRequest($event) {
    return this.$mdDialog.show({
      escapeToClose: true,
      targetEvent: $event,
      template: sendBankStatementsRequestTemplate({}),
      controllerAs: 'vmd',
      locals: {
        loanEnquiry: this.loanEnquiry
      },
      controller: SendBankStatementsRequestDialogController
    }).then(() => this.loanEnquiry.reloadResource('loanEnquiryDetails'))
  }

  sendEmail(event, id) {
    event.stopPropagation()
    const led = this.loanEnquiry.loanEnquiryDetails.find((loanEnquiryDetail) => loanEnquiryDetail.id === id)
    return led.performAction('response', {response: led.response})
  }
}

export default BankStatementsController
