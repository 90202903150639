class InvestorQualifyingController {
  /*@ngInject*/
  constructor(userService, entityContextService, emailVerificationsRepository) {
    this.name = 'investor-qualifying'
    this.userService = userService
    this.entityContextService = entityContextService
    this.emailVerificationsRepository = emailVerificationsRepository

    this.$onInit = () => {
      entityContextService.current().then((context) => {
        this.entityContextService = context
        this.investor = context.investor
        if (!userService.isEmailVerified()) {
          this.checkIfEmailVerified()
        }
      })
    }
  }

  get numberOfSteps() {
    let count = 0
    if (!this.identityCheckVerified()) count++
    if (!this.fundsTransferred()) count++
    if (!this.investorApprovalStatusAccepted()) count++
    return count
  }

  identityCheckVerified() {
    return this.userService.isIdVerified()
  }

  fundsTransferred() {
    return this.investor && this.investor.moneyAdded
  }

  investorStatus() {
    return this.investor && this.investor.status
  }

  investorApprovalStatusPending() {
    return this.investorStatus() === 'pending'
  }

  investorApprovalStatusAccepted() {
    return this.investorStatus() === 'accepted' || this.investorStatus() === 'verified'
  }

  investorApprovalStatusRejected() {
    return this.investorStatus() === 'rejected'
  }

  emailVerified() {
    return this.userService.isEmailVerified()
  }

  checkIfEmailVerified() {
    this.emailVerificationsRepository.create().then((result) => {
      this.userService.setEmailVerified(result.user.emailVerified)
    })
  }
}

export default InvestorQualifyingController
