import template from './loanPartDetails.pug'
import controller from './loanPartDetails.controller'
import './loanPartDetails.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      compositeLoanPart: '='
    },
    bindToController: true
  }
}
