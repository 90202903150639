import resolveProperty from '../../../utils/resolveProperty'
import valueOrDefault from '../../../utils/valueOrDefault'
import isDefined from '../../../utils/isDefined'

class MarketingContentController {
  accreditedBrokerPresent = false

  /*@ngInject*/
  constructor($rootScope, $scope, $stateParams, $state, $timeout, contentful, loginRouter, loggerService, navigation, contentPanelToggleService) {
    this.name = 'marketing-content'
    this.$rootScope = $rootScope
    this.$scope = $scope
    this.contentPanelToggleService = contentPanelToggleService

    const resolveRedirects = (requestedContentId) => {
      const redirect = navigation.redirectsFor('marketing').get(requestedContentId)
      return isDefined(redirect) ? redirect : requestedContentId
    }

    const defaultContentPrefix = 'marketing-content'
    const logger = loggerService.makeLogger()

    this.$onInit = () => {
      navigation.whenLoaded.then(() => {
        const contentPrefix = valueOrDefault(resolveProperty($state, '$current', 'data', 'contentPrefix'), defaultContentPrefix)
        const contentId = valueOrDefault(resolveProperty($stateParams, 'id'), 'home', {replaceEmpty: true, replaceBlank: true})
        const candidateCmsContentName = `${contentPrefix}-${contentId}`
        const cmsContentName = resolveRedirects(candidateCmsContentName)

        return contentful.entries(`content_type=page&fields.name=${cmsContentName}&include=10`).then((entry) => {
          this.page = resolveProperty(entry, 'data', 'items', 0, 'fields')
          if (isDefined(this.page)) {
            const parentTabName = valueOrDefault(resolveProperty(this.page, 'parentTabName'), '').trim()
            if (parentTabName.length > 0) {
              $state.current.parentTabName = parentTabName
            }
          } else {
            logger.notify('Sorry, but your requested page cannot be found. We will redirect you to the home page.')
            $timeout(() => {
              loginRouter.gotoHome()
            }, 5000)
          }
        }, (error) => {
          logger.serviceFault()
            .serviceId('marketingCMS')
            .notification(`We're unable to display that page just now,. Please try again later.`)
            .logMessage(() => {
              const messages = ['Marketing pages contentful entries promise rejection.']
              messages.push(`defaultContentPrefix: ${JSON.stringify(defaultContentPrefix)}`)
              messages.push(`$stateParams.id: ${JSON.stringify(contentId)}`)
              messages.push(`Reason: ${JSON.stringify(error)}`)
              return messages.join('\n')
            })
            .report()
        })
      })
    }
  }
}

export default MarketingContentController
