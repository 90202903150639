import template from './loanParts.pug'
import controller from './loanParts.controller'
import './loanParts.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      investor: '='
    },
    bindToController: true
  }
}
