import isDefined from '../../utils/isDefined'
import filteredPanels from '../../utils/filteredPanels'
import noopPromiseBasedActionGenerator from '../../common/dynamicForm/helpers/noopPromiseBasedActionGenerator'
import {defaultingMap, performUploads, uploadStatus} from '../../utils/uploadHelpers'

import collectBiometricIdentityDataForm from './collectBiometricIdentityDataForm'
import createEnum from '../../utils/createEnum'
import resolveProperty from '../../utils/resolveProperty'

const CONTENT_PREFIX = 'biometric-identity-check-form-'
const CONTENT_PREFIX_RETRY = 'retry-'
const BIOMETRIC_ATTACHMENTS_DIRECTORY = 'biometrics'

const PAGE_STATUS = createEnum('PENDING', 'ACTIVE', 'INACTIVE')

class CollectBiometricIdentityDataController {
  /*@ngInject*/
  constructor(biometricIdentityChecksRepository, attachmentsRepository,
              userService, uploadService, loggerService,
              contentful,
              $q, $stateParams) {
    this.name = 'check-biometric-identity'

    this.nrLogger = loggerService.makeLogger().enableConsole(false).enableNewRelic(true)

    const token = $stateParams.token
    this.pageStatus = PAGE_STATUS.PENDING
    this.loaded = false
    this.panels = new Map()

    this.allPhotos = []
    this.attemptedSubmit = false
    const uploads = defaultingMap()

    const noopAction = noopPromiseBasedActionGenerator($q)

    this.theForm = {
      definition: collectBiometricIdentityDataForm,
      model: {},
      showSubmit: true,
      saveAction: noopAction,
      submitAction: (model, compiledWidgets) => {
        this.attemptedSubmit = true
        if (this.isSubmitAttemptWithNoPhotos()) {
          this.attemptedSubmit = false
          return noopAction()
        }
        // 1. Disable the submit button.
        // 2. Put up a spinner (or progress bar, based on uploading everything?)
        this.uploadInProgress = true
        // 3. Upload the pictures.
        const uponCompletion = () => {
          this.pageStatus = PAGE_STATUS.INACTIVE
          this.uploadInProgress = false
        }
        // 4. Create attachments (in a biometrics directory)
        const uploadPromise = performUploads($q, this.allPhotos, 'photos', uploads, uploadService, attachmentsRepository, undefined, BIOMETRIC_ATTACHMENTS_DIRECTORY, this.nrLogger)
        // 5. Resolve all picture upload promises.
        uploadPromise.then((response) => {
          // 6. Build a payload JSON for the BIC, including the comment and the attachments.
          const payload = {
            comment: model['comment'],
            biometrics: {
              photos: [...uploads.entries()]
                .filter((upload) => upload.status === uploadStatus.ATTACHMENT_CREATED)
                .map((upload) => ({
                  fileName: upload.metadata.name,
                  fileType: upload.metadata.type,
                  fileSize: upload.metadata.size,
                  attachmentId: upload.attachmentId
                }))
            }
          }
          // 7. Transition the BIC to review by submitting the payload through the transition endpoint.
          const submitResultPromise = this.bic.performAction('transition/review-required', {payload})
          // 8. Change the user interface to display the outcome.
          submitResultPromise.then(uponCompletion)
          return submitResultPromise
        })
        return uploadPromise
      }
    }

    const isInExpectedState = (status) => ['retry', 'requested'].includes(status)

    this.$onInit = () => {
      filteredPanels(contentful, CONTENT_PREFIX, (resultMap) => this.panels = resultMap)

      if (isDefined(token) && token.toString().trim().length > 0) {
        userService.logout()
          .then(() => {
            return biometricIdentityChecksRepository.getById(token)
          })
          .then((bic) => {
            this.bic = bic
            this.pageStatus = isInExpectedState(bic.status) ? PAGE_STATUS.ACTIVE : PAGE_STATUS.INACTIVE
          })
          .catch((error) => {
            this.pageStatus = PAGE_STATUS.INACTIVE
            this.nrLogger.log(`Problem getting the biometric identity check row for id '${token}'. See next message.`)
            this.nrLogger.log(error)
          }).finally(() => this.loaded = true)
      } else {
        this.pageStatus = PAGE_STATUS.INACTIVE
      }
    }
  }

  getPanelBySuffix(suffix) {
    const contentKey = `${CONTENT_PREFIX}${suffix}`
    const panelEntry = this.panels.get(contentKey)
    if (!panelEntry) {
      this.nrLogger.log(`Unable to find panel content for key '${contentKey}'.`)
    }
    return panelEntry ? panelEntry.panel : undefined
  }

  getRetryPanel() {
    const retryKey = resolveProperty(this.bic, 'retryReason')
    return this.getPanelBySuffix(`${CONTENT_PREFIX_RETRY}${retryKey}`)
  }

  isSubmitAttemptWithNoPhotos() {
    return this.attemptedSubmit && this.allPhotos.length === 0
  }

  get isActive() {
    return this.pageStatus === PAGE_STATUS.ACTIVE
  }

  get isInactive() {
    return this.pageStatus === PAGE_STATUS.INACTIVE
  }

  get isExpectedRetry() {
    return resolveProperty(this.bic, 'status') === 'retry' && this.getRetryPanel() !== undefined
  }
}

export default CollectBiometricIdentityDataController
