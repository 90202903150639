import template from './consent.pug'
import controller from './consent.controller'
import './consent.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      formTracker: '=',
      validationProperty: '@'
    }
  }
}
