import {
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  isLoanAmount,
  isReferrerNotes,
  isReferrer,
  loanAmount,
  loanPurposeAdmin,
  referrerFirstName,
  referrerLastName,
  referrerPhone,
  referrerEmail,
  referrerNotes,
  isBorrowerDone,
  conjunction,
  reeceAccountNumber,
  isReferrerEmail,
  preferredCommission,
  originationFee,
  isReeceAccountNumber
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  and,
  or,
  isModelDefined, fromModel
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const isLaddrLoan = '(eq (fromModel "loanOrigination") "laddr")'
const isTruePillarsLoan = '(eq (fromModel "loanOrigination") "truepillars")'

const cashFlowAdminEnquiryForm = {
  title: 'Admin business loan',
  model: 'business-loan-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Business loan enquiry')
      .classes('border')
      .widgets(
        loanPurposeAdmin()
          .required(true)
          .display('none')
          .rules(ruleSet('"cashflow"'))
          .build(),
        contactType().admin().build(),
        helpContent().contentId('enquiry-form-business-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            radioButtons()
              .required(true)
              .model('loanOrigination')
              .prompt('Loan origination?')
              .button('Truepillars', 'truepillars')
              .button('Laddr', 'laddr')
              .build(),
            reeceAccountNumber().prompt('Reece trade account number')
              .addRules(ruleRender(isLaddrLoan))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Accredited referrer details')
          .rules(ruleRender(isTruePillarsLoan))
          .widgets(
            referrerFirstName().prompt('Referrer first name').build(),
            referrerLastName().prompt('Referrer last name').build(),
            referrerPhone().prompt('Referrer mobile number').build(),
            referrerEmail().prompt('Referrer email address').build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Loan details')
          .rules(ruleRender(or(and(isReeceAccountNumber, isLaddrLoan), and(isReferrerEmail, isTruePillarsLoan))))
          .widgets(
            loanAmount().prompt('Business loan amount?').min(50_000).max(300_000).build(),
            preferredCommission()
              .prompt('What is the preferred brokerage?')
              .min(0)
              .max(5)
              .step(0.5)
              .required(true)
              .build(),
            originationFee().build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isLoanAmount))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background, and the reason for the loan')
              .required(true)
              .rules(ruleRender(isLoanAmount))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            ...borrowerFullComposite()
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Yes, another', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(isBorrowerDone))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(isBorrowerDone))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('homeLoan', 'Most recent home loan statement')
              .fileGroup('councilRates', 'Copy of council rates notices')
              .fileGroup('creditReports', 'Equifax credit report for individuals')
              .fileGroup('propertyValuation', '<6 month old sworn property valuation report (optional)')
              .fileGroup('driversLicence', 'Copy of Drivers License (optional but preferred)')
              .fileGroup('bankStatements', '6 months of electronic bank statements (provided by broker or link sent to customer from TruePillars)')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .build())
          .build())
      .build()
  ]
}

export default cashFlowAdminEnquiryForm
