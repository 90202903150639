import template from './loanRequestDetails.pug'
import controller from './loanRequestDetails.controller'
import './loanRequestDetails.scss'

let loanRequestDetailsComponent = () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}

export default loanRequestDetailsComponent
