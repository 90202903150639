import template from './helpBox.pug'
import controller from './helpBox.controller'
import './helpBox.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    transclude: true,
    scope: {
      heading: '@',
      text: '@'
    }
  }
}
