class ReportsController {
  /*@ngInject*/
  constructor($scope, router) {
    this.name = 'reports'
    this.router = router

    this.tabs = [
      {label: 'Loan Stats', state: 'adminDashboard.reports.loanStats'},
      {label: 'Reconciliation', state: 'adminDashboard.reports.reconciliation'},
      {label: 'Investor Registry', state: 'adminDashboard.reports.investorRegistry'},
      {label: 'Journal Entries', state: 'adminDashboard.reports.journalEntries'},
      {label: 'Registers', state: 'adminDashboard.reports.registers'},
    ]

    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.name.indexOf('adminDashboard.reports') === 0) {
        let indexOfState = this.tabs.findIndex(tab => toState.name.startsWith(tab.state))
        if(indexOfState >= 0) {
          this.selectedIndex = indexOfState
        }
      }
    })
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }
}

export default ReportsController
