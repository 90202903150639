import angular from 'angular'
import showdown from 'showdown'
import markdownEditorConfig from './markdownEditorConfig'

export default angular.module('app.common.compileMarkdown', [])
  .directive('compileMarkdown', ['$compile', ($compile) => {
    return (scope, element, attrs) => {
      scope.$watch(
        (scope) => {
          // watch the 'compile' expression for changes
          return scope.$eval(attrs.compileMarkdown)
        },
        (value) => {
          element.html(typeof value === 'undefined' || value === null ? '' : new showdown.Converter(markdownEditorConfig).makeHtml(value.toString().trim()))

          // compile the new DOM and link it to the current
          $compile(element.contents())(scope)
        }
      )
    }
  }])
