import template from './bidWidget.pug'
import controller from './bidWidget.controller'
import './bidWidget.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      investor: '=',
      auction: '=',
      complete: '='
    }
  }
}
