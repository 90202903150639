import resolveProperty from '../../../../../../utils/resolveProperty'
import valueOrDefault from '../../../../../../utils/valueOrDefault'
import sendBankStatementsRequestForm from './sendBankStatementsRequestForm'
import {createPayload} from '../../../../../../utils/loanEnquiryDetailHelper'

class SendBankStatementsRequestDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, loanEnquiryDetailsRepository, loanEnquiry) {
    this.name = 'send-bank-statements-request-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.loanEnquiryDetailsRepository = loanEnquiryDetailsRepository
    this.loanEnquiry = loanEnquiry
    this.referrerEmail = this.loanEnquiry.loanEnquirySummary?.referrer?.email

    const assetRideShareElectricVehicle = valueOrDefault(resolveProperty(this.loanEnquiry, 'loanEnquirySummary', 'loan', 'assetRideShareElectricVehicle'), 'no')
    const enquiryDetails = Array.isArray(this.loanEnquiry.loanEnquiryDetails) ? this.loanEnquiry.loanEnquiryDetails[0]?.response?.enquiryDetails : {}
    const theFormModel = {
      'electronicBankStatements.loanPurpose': resolveProperty(this.loanEnquiry, 'loanEnquirySummary', 'loan', 'loanPurpose'),
      'electronicBankStatements.assetRideShareElectricVehicle': assetRideShareElectricVehicle,
      'electronicBankStatements.copyToReferrer': this.referrerEmail ? assetRideShareElectricVehicle : 'no',
      'electronicBankStatements.referrerEmail': this.referrerEmail,
      'electronicBankStatements.clientFirstName': enquiryDetails?.find((tuple) => tuple.name === 'borrowerName')?.answer?.split(' ').slice(0, -1).join(' '),
      'electronicBankStatements.clientLastName': enquiryDetails?.find((tuple) => tuple.name === 'borrowerName')?.answer?.split(' ').slice(-1).join(' '),
      'electronicBankStatements.clientEmail': enquiryDetails?.find((tuple) => tuple.name === 'borrowerEmail')?.answer
    }

    this.config = {
      model: theFormModel,
      title: 'Send bank statements request',
      definition: sendBankStatementsRequestForm,
      showSubmit: true,
      canSubmitUntouched: true,
      saveAction: () => this.$q.resolve(),
      submitAction: (model, compiledWidgets) => {
        const body = {
          loanEnquiryId: this.loanEnquiry.id,
          loanEnquiryStage: this.loanEnquiry.stage,
          type: 'electronic-bank-statements',
          request: Object.assign({
            loanEnquirySummary: {
              name: this.loanEnquiry.loanEnquirySummary.borrower.businessName,
              amount: this.loanEnquiry.loanEnquirySummary.loan.loanAmount,
              purpose: this.loanEnquiry.loanEnquirySummary.loan.loanPurpose,
              assetRideShareElectricVehicle: assetRideShareElectricVehicle
            },
            referrerDetails: this._referrerDetails(),
          }, sendBankStatementsRequestForm),
          response: {enquiryDetails: createPayload(model, compiledWidgets)}
        }
        return this.loanEnquiryDetailsRepository.create(body).then(() => this.$mdDialog.hide(this.config.loanEnquiry))
      }
    }
  }

  actionClose() {
    return this.$mdDialog.cancel()
  }

  // private methods

  _referrerDetails() {
    if (this.referrerEmail) {
      return {
        email: this.loanEnquiry.loanEnquirySummary.referrer.email,
        name: this.loanEnquiry.loanEnquirySummary.referrer.name,
        phone: this.loanEnquiry.loanEnquirySummary.referrer.phone,
        company: this.loanEnquiry.loanEnquirySummary.referrer.company
      }
    }
  }
}

export default SendBankStatementsRequestDialogController
