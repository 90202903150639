class CustomPropertiesService {
  /*@ngInject*/
  constructor($window, $document) {
    this.rootStyleElement = $window.window.getComputedStyle($document[0].documentElement)
  }

  getProperty(propertyName) {
    return this.rootStyleElement.getPropertyValue(propertyName)
  }
}

export default CustomPropertiesService
