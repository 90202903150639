export default ['userService', '$compile', (userService, $compile) => {
  return {
    restrict: 'A',
    priority: 600,
    scope: true,
    terminal: true,
    link: function link(scope, element, attrs) {
      const ngIfValue = attrs.ngIf ? `${attrs.ngIf} && hasRole` : 'hasRole'

      element.attr('ng-if', ngIfValue)
      attrs.ngIf = ngIfValue
      scope.hasRole = userService.hasRole(...attrs.requireRole.split(' '))
      $compile(element)(scope)
    }
  }
}]
