const NO_VALUE = '<span class="no-data">- no value -</span>'
const EXTERNAL_REL_LIST = 'external nofollow noreferrer noopener'

const booleanRepresentation = (value, trueValue, falseValue, unknownValue) => value ? trueValue : (value === undefined || value === null ? unknownValue : falseValue)

class DynamicFieldDisplayController {
  /*@ngInject*/
  constructor($filter, $sce) {
    this.name = 'dynamic-field-display'
    this.$filter = $filter
    this.$sce = $sce

    // this.type
    // this.value
    // this.format

    // $filter('asCurrency')(this.value, 2)

  }

  get abnFormattedValue() {
    return this.$sce.trustAsHtml(this.value ? this.$filter('asABN')(this.value) : NO_VALUE)
  }

  get booleanValueClass() {
    return booleanRepresentation(this.value, 'boolean-value-true', 'boolean-value-false', 'boolean-value-unknown')
  }

  get booleanAriaLabel() {
    return booleanRepresentation(this.value, 'True', 'False', 'Unknown')
  }

  get businessEntityValue() {
    return this.$sce.trustAsHtml(this.value ?
      (this.$filter('asBusinessEntity')(this.value)) :
      NO_VALUE)
  }

  get currencyFormattedValue() {
    return this.$sce.trustAsHtml(this.value ?
      (this.format && Number.isInteger(this.format) ? this.$filter('asCurrency')(this.value, this.format) : this.$filter('asCurrency')(this.value, 0)) :
      NO_VALUE)
  }

  get dateFormattedValue() {
    return this.$sce.trustAsHtml(this.value ?
      (this.format ? this.$filter('asLocaltime')(this.value, this.format) : this.$filter('asLocaltime')(this.value)) :
      NO_VALUE)
  }

  get labelValue() {
    return this.$sce.trustAsHtml(this.label ? this.label : NO_VALUE)
  }

  get postcodeFormattedValue() {
    return this.$sce.trustAsHtml(this.value ?
      (this.value.toString().match(/^\d{4}$/) ? `<a target="_blank" href="https://auspost.com.au/postcode/${this.value}" rel="${EXTERNAL_REL_LIST}">${this.value}</a>` : this.value) :
      NO_VALUE)
  }

  get textFormattedValue() {
    return this.value ?
      (this.$filter('fromMarkdown')(this.value)) :
      this.$sce.trustAsHtml(`<p>${NO_VALUE}</p>`)
  }

  get defaultValue() {
    return this.$sce.trustAsHtml(this.value ? this.value : NO_VALUE)
  }
}

export default DynamicFieldDisplayController
