class ApiFormManager {
  constructor(scope, form, submitter, $q, $mdToast, $window) {
    this.scope = scope
    this.form = form
    this.submitter = submitter
    this.$q = $q
    this.$mdToast = $mdToast
    this.$window = $window
  }

  submittable() {
    return this.form.$valid && !this.form.$currentlySubmitting
  }

  submit(event) {
    this._reportToLuckyOrange()

    this.scope.$apply(() => {
      this.form.$commitViewValue()
      this.form.$setSubmitted()

      const promise = this.scope.$eval(this.submitter, {submitEvent: event})
      if (promise && promise.then) {
        this.form.$currentlySubmitting = true
        return promise.catch(
          response => {return this._catchResponse(response)}
        )
        .finally(() => {
          this.form.$currentlySubmitting = false
        })
      } else {
        throw new Error(`API form submitter ${this.submitter} does not return a promise`)
      }
    })
  }

  clearError(name) {
    if (this._isInputError(name)) {
      const target = this.form[name]
      target.$setValidity('api', true)
      target.apiError = ''
    }
  }

  // private

  _catchResponse(response) {
    if (response.status === 400) {
      this._handleErrors(response.data.errors)
    } else {
      return this.$q.reject(response)
    }
  }

  _handleErrors(errors) {
    let inputErrors = {}
    let formErrors = []
    for (let error of errors) {
      if (this._isInputError(error.source)) {
        if (!inputErrors[error.source]) {
          inputErrors[error.source] = []
        }
        inputErrors[error.source].push(error)
      } else {
        formErrors = formErrors.concat(error)
      }
    }
    for (let name in inputErrors) {
      this._showInputErrors(name, inputErrors[name])
    }
    if (formErrors.length) this._showFormErrors(formErrors)
  }

  _showInputErrors(name, errors) {
    const input = this.form[name]
    input.$setValidity('api', false)
    input.apiError = this._concatenatedMessages(errors)
  }

  _showFormErrors(errors) {
    this.$mdToast.show(this.$mdToast.simple().textContent(this._concatenatedMessages(errors)).position('top left right'))
  }

  _isInputError(name) {
    return !!this.form[name]
  }

  _concatenatedMessages(errors) {
    return errors.map(error => error.message).join(', ')
  }

  _reportToLuckyOrange() {
    if (this.$window && this.$window._loq) {
      this.$window._loq.push(['tag', this.form.$name])
    }
  }
}

export default ApiFormManager
