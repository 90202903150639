class VersionService {
  /*@ngInject*/
  constructor(api, $firebaseObject) {
    const FIREBASE_URL_PATTERN = /^https:\/\/.*\.firebaseio\.com/i

    this.versionChanged = false

    api.linkFor('versions').then((link) => {
      if (link && link.href && !link.href.match(FIREBASE_URL_PATTERN)) {
        throw new Error('Current Firebase libraries require: https://<YOUR FIREBASE>.firebaseio.com')
      }

      this._firebaseObject = $firebaseObject(new Firebase(link.href))
      this._firebaseObject.$loaded().then(() => {
        const unwatch = this._firebaseObject.$watch(() => {
          this.versionChanged = true
          unwatch()
        })
      })
    })
  }

  getVersions() {
    return this._firebaseObject
  }

  hasVersionChanged() {
    return this.versionChanged
  }
}

export default VersionService
