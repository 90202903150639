class AbaViewerService {
  /*@ngInject*/
  constructor($mdDialog, $q) {
    this.$mdDialog = $mdDialog
    this.$q = $q
    this.name = 'aba-viewer-service'
  }

  show($event, abaId, selectedDebitId) {
    const completion = this.$q.defer()
    completion.promise.finally(() => {
      this.$mdDialog.hide()
    })

    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      locals: {
        abaId: abaId,
        selectedDebitId: selectedDebitId,
        completion: completion
      },
      controllerAs: 'vm',
      bindToController: true,
      template:
        `<md-dialog>
          <md-dialog-content>
            <admin-view-aba aba-id="vm.abaId" selected-debit-id="vm.selectedDebitId" completion="vm.completion"/>
          </md-dialog-content>
        </md-dialog>`,
      controller: function() {},
    })
  }
}

export default AbaViewerService
