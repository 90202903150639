class WithdrawalsVerifiedController {
  /*@ngInject*/
  constructor($scope, $mdDialog, $mdToast, withdrawalService, localStorageService) {
    this.name = 'withdrawals-verified'
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.withdrawalService = withdrawalService

    this.search = ''
    this.limitOptions = [5, 10, 20, 50, 100]
    this.order = '-UpdatedAt'
    this.page = 1
    localStorageService.bind($scope, 'vm.limit', 50, 'admin-withdrawal-requests.limit')

    this.mdReload = () => this.reload() // this is required for md-reorder-on to work

    this.total = 0
    this.withdrawals = []
    this.processing = false

    this.$onInit = () => {
      this.reload()
    }
  }

  get hasVerified() {
    return this.withdrawals.length > 0
  }

  get hasIdsToProcess() {
    return this.idsToProcess().length > 0
  }

  idsToProcess() {
    return this.withdrawals
      .filter((request) => request.process !== undefined && request.process !== null && request.process)
      .map((request) => ({id: request.id, reason: 'processing'}))
  }

  reload() {
    const conditions = {
      search: this.search,
      order: this.order,
      page: this.page,
      size: this.limit
    }
    return this.withdrawalService.verifiedWithdrawalsPromise(conditions).then((data) => {
      this.withdrawals = data.withdrawalRequests
      this.total = data.total
      return true
    })
  }

  performSearch() {
    this.page = 1
    this.reload()
  }

  compare(a, b) {
    if (!a || !b) return 'initial'
    if (a !== b) return 'differ'
    return 'same'
  }

  selectAllForProcessing() {
    this.withdrawals.forEach((withdrawal) => withdrawal.process = true)
  }

  unselectAllForProcessing() {
    this.withdrawals.forEach((withdrawal) => withdrawal.process = false)
  }

  sendForProcessing() {
    const ids = this.idsToProcess()
    if (ids.length > 0) {
      this.processing = true
      this.unselectAllForProcessing()
      this.withdrawalService.update('process', ids)
        .catch((error) => {
          this._showToast(error)
        })
        .finally(() => {
          this.processing = false
          return this.reload()
        })
    }
    return true
  }

  withdrawalDeclined(id) {
    this._reasonToDecline((reason) => this.withdrawalService.updateSingle('decline', id, reason).then(() => this.reload()))
  }

  // private

  _reasonToDecline(onFulfilled) {
    const options = this.$mdDialog.prompt()
      .textContent('What is the reason for declining?')
      .required(true)
      .clickOutsideToClose(true)
      .title('Decline message for requester')
      .ariaLabel('Confirm?')
      .ok('Decline')
      .cancel('Cancel')
    const onRejected = () => false
    return this.$mdDialog.show(options).then(onFulfilled, onRejected)
  }

  _showToast(error) {
    const msg = (error?.data) ? 'Error: ' + error.data.message + ' - ' + error.data.errors[0].source + ': ' + error.data.errors[0].message : 'Error: bad response from server'
    this.$mdToast.show(this.$mdToast.simple().textContent(msg).hideDelay(5000).position('top left'))
  }
}

export default WithdrawalsVerifiedController
