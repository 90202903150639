class BorrowersController {
  /*@ngInject*/
  constructor($scope, localStorageService, borrowersRepository) {
    this.name = 'admin-borrowers'
    this.borrowersRepository = borrowersRepository
    this.order = '-ID'
    this.status = 'all'
    this.limitOptions = [5, 10, 20, 50, 100]
    this.total = 0

    localStorageService.bind($scope, 'vm.limit', 50, 'admin-borrowers.limit')

    this.reload = () => {
      this.loading = true
      const conditions = {
        search: this.search,
        order: this.order,
        size: this.limit,
        page: this.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.borrowersRepository.where(conditions)
        .then(data => {
          this.borrowers = data.borrowers
          this.total = data.total
        })
        .finally(() => this.loading = false)
    }
    this.$onInit = () => {
      this.performSearch()
    }
  }

  performSearch() {
    this.page = 1
    this.reload()
  }
}

export default BorrowersController
