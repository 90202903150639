import Big from 'big.js'

class FeesDueController {
  /*@ngInject*/
  constructor($q, $mdDialog, directDebitsRepository, dateService, abaViewerService, userService) {
    this.name = 'fees-due'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.directDebitsRepository = directDebitsRepository
    this.dateService = dateService
    this.abaViewerService = abaViewerService
    this.userService = userService

    this.order = '-dueDate'
    this.hiddenOrder = '-dueDate'
    this.selectedDebitIds = []

    this.$onInit = () => {
      this.reload()
    }
  }

  reload() {
    const today = this.dateService.now().format('YYYY-MM-DD')
    this.selectedDebitIds = []
    this.loading = this.directDebitsRepository.where({scope: 'due', type: 'fee'}).then(data => {
      this.dueDebits = data.directDebits
        .filter(directDebit => directDebit.dueDate <= today)
        .filter(directDebit => directDebit.status !== 'processing')

      this.selectedDebitIds = []
      this.dueDebits.forEach(debit => {
        debit.promise('loan').then(loan => {
          if (debit.dueDate === today && loan.hasBankDetail && !loan.disableDirectDebit) {
            this.selectedDebitIds.push(debit.id)
          }
        })
      })
    })

    this.loadingHidden = this.directDebitsRepository.where({scope: 'hidden', type: 'fee'}).then(data => {
      this.hiddenDebits = data.directDebits
        .filter(directDebit => directDebit.dueDate <= today)
    })
  }

  viewABA($event, abaId, selectedDebitId) {
    this.abaViewerService.show($event, abaId, selectedDebitId)
  }

  createABA($event) {
    const completion = this.$q.defer()
    completion.promise.finally(() => {
      this.$mdDialog.hide()
      this.reload()
    })

    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      locals: {
        loanIds: [],
        debitIds: this.selectedDebitIds,
        total: this._sumDebitAmounts(this.selectedDebitIds),
        completion: completion
      },
      controllerAs: 'vm',
      bindToController: true,
      template:
        `<md-dialog>
          <md-dialog-content>
            <admin-create-aba debit-ids="vm.debitIds" total="vm.total" completion="vm.completion"/>
          </md-dialog-content>
        </md-dialog>`,
      controller: function() {},
    })
  }

  createFeeRepayment($event, debit) {
    const completion = this.$q.defer()
    completion.promise
      .finally(() => {
        this.$mdDialog.hide()
        this.reload()
      })

    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        loan: debit.loan,
        debit: debit,
        completion: completion
      },
      controllerAs: 'vm',
      bindToController: true,
      template:
        `<md-dialog flex="70">
          <md-dialog-content layout-padding>
            <admin-create-loan-repayment loan="vm.loan" debit="vm.debit" type="additional" completion="vm.completion"/>
          </md-dialog-content>
        </md-dialog>`,
      controller: function() {},
    })
  }

  cancelDebit(debit) {
    return this._confirmAction(debit, 'cancel').then(() => debit.performAction('cancel')).then(() => this.reload())
  }

  hideDebit(debit) {
    return this._confirmAction(debit, 'hide').then(() => debit.performAction('hide')).then(() => this.reload())
  }

  restoreDebit(debit) {
    return this._confirmAction(debit, 'restore').then(() => debit.performAction('restore')).then(() => this.reload())
  }

  cannotBeProcessed(debit) {
    return debit.loan && (debit.loan.disableDirectDebit || !debit.loan.hasBankDetail)
  }

  canBeCancelled(debit) {
    return debit.status === 'scheduled' && this.userService.isSupervisor()
  }

  canBeHidden(debit) {
    return debit.status === 'rejected' && debit.statusCount >= 2
  }

  canBeRestored(debit) {
    return debit.status === 'hidden'
  }

  hasExpired(debit) {
    return debit.status === 'rejected' && debit.loan?.status === 'completed'
  }

  // private

  _confirmAction(debit, action='cancel') {
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(`Are you sure you want to ${action} this $${debit.amount}0 direct debit for loan ${debit.loanId}?`)
        .clickOutsideToClose(true)
        .title('Are you sure?')
        .ariaLabel('Are you sure?')
        .ok(`Yes, ${action} debit`)
        .cancel('No')
    )
  }

  _sumDebitAmounts(debitIds) {
    const debitsMap = this.dueDebits.reduce((map, debit) => map.set(debit.id, debit), new Map())
    return debitIds.reduce((total, id) => total.plus(debitsMap.get(id).amount), new Big(0)).toNumber()
  }
}

export default FeesDueController
