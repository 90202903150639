const businessEntityMapping = {
  'ADF': {source: 'ABN', label: 'Approved Deposit Fund'},
  'ARF': {source: 'ABN', label: 'APRA Regulated Fund (Fund Type Unknown)'},
  'CCB': {source: 'ABN', label: 'Commonwealth Government Public Company'},
  'CCC': {source: 'ABN', label: 'Commonwealth Government Co-operative'},
  'CCL': {source: 'ABN', label: 'Commonwealth Government Limited Partnership'},
  'CCN': {source: 'ABN', label: 'Commonwealth Government Other Unincorporated Entity'},
  'CCO': {source: 'ABN', label: 'Commonwealth Government Other Incorporated Entity'},
  'CCP': {source: 'ABN', label: 'Commonwealth Government Pooled Development Fund'},
  'CCR': {source: 'ABN', label: 'Commonwealth Government Private Company'},
  'CCS': {source: 'ABN', label: 'Commonwealth Government Strata Title'},
  'CCT': {source: 'ABN', label: 'Commonwealth Government Public Trading Trust'},
  'CCU': {source: 'ABN', label: 'Commonwealth Government Corporate Unit Trust'},
  'CGA': {source: 'ABN', label: 'Commonwealth Government Statutory Authority'},
  'CGC': {source: 'ABN', label: 'Commonwealth Government Company'},
  'CGE': {source: 'ABN', label: 'Commonwealth Government Entity'},
  'CGP': {source: 'ABN', label: 'Commonwealth Government Partnership'},
  'CGS': {source: 'ABN', label: 'Commonwealth Government Super Fund'},
  'CGT': {source: 'ABN', label: 'Commonwealth Government Trust'},
  'CMT': {source: 'ABN', label: 'Cash Management Trust'},
  'COP': {source: 'ABN', label: 'Co-operative'},
  'CSA': {source: 'ABN', label: 'Commonwealth Government APRA Regulated Public Sector Fund'},
  'CSP': {source: 'ABN', label: 'Commonwealth Government APRA Regulated Public Sector Scheme'},
  'CSS': {source: 'ABN', label: 'Commonwealth Government Non-Regulated Super Fund'},
  'CTC': {source: 'ABN', label: 'Commonwealth Government Cash Management Trust'},
  'CTD': {source: 'ABN', label: 'Commonwealth Government Discretionary Services Management Trust'},
  'CTF': {source: 'ABN', label: 'Commonwealth Government Fixed Trust'},
  'CTH': {source: 'ABN', label: 'Commonwealth Government Hybrid Trust'},
  'CTI': {source: 'ABN', label: 'Commonwealth Government Discretionary Investment Trust'},
  'CTL': {source: 'ABN', label: 'Commonwealth Government Listed Public Unit Trust'},
  'CTQ': {source: 'ABN', label: 'Commonwealth Government Unlisted Public Unit Trust'},
  'CTT': {source: 'ABN', label: 'Commonwealth Government Discretionary Trading Trust'},
  'CTU': {source: 'ABN', label: 'Commonwealth Government Fixed Unit Trust'},
  'CUT': {source: 'ABN', label: 'Corporate Unit Trust'},
  'DES': {source: 'ABN', label: 'Deceased Estate'},
  'DIP': {source: 'ABN', label: 'Diplomatic/Consulate Body or High Commissioner'},
  'DIT': {source: 'ABN', label: 'Discretionary Investment Trust'},
  'DST': {source: 'ABN', label: 'Discretionary Services Management Trust'},
  'DTT': {source: 'ABN', label: 'Discretionary Trading Trust'},
  'FHS': {source: 'ABN', label: 'First Home Saver Accounts Trust'},
  'FPT': {source: 'ABN', label: 'Family Partnership'},
  'FUT': {source: 'ABN', label: 'Fixed Unit Trust'},
  'FXT': {source: 'ABN', label: 'Fixed Trust'},
  'HYT': {source: 'ABN', label: 'Hybrid Trust'},
  'IND': {source: 'ABN', label: 'Individual/Sole Trader'},
  'LCB': {source: 'ABN', label: 'Local Government Public Company'},
  'LCC': {source: 'ABN', label: 'Local Government Co-operative'},
  'LCL': {source: 'ABN', label: 'Local Government Limited Partnership'},
  'LCN': {source: 'ABN', label: 'Local Government Other Unincorporated Entity'},
  'LCO': {source: 'ABN', label: 'Local Government Other Incorporated Entity'},
  'LCP': {source: 'ABN', label: 'Local Government Pooled Development Fund'},
  'LCR': {source: 'ABN', label: 'Local Government Private Company'},
  'LCS': {source: 'ABN', label: 'Local Government Strata Title'},
  'LCT': {source: 'ABN', label: 'Local Government Public Trading Trust'},
  'LCU': {source: 'ABN', label: 'Local Government Corporate Unit Trust'},
  'LGA': {source: 'ABN', label: 'Local Government Statutory Authority'},
  'LGC': {source: 'ABN', label: 'Local Government Company'},
  'LGE': {source: 'ABN', label: 'Local Government Entity'},
  'LGP': {source: 'ABN', label: 'Local Government Partnership'},
  'LGT': {source: 'ABN', label: 'Local Government Trust'},
  'LPT': {source: 'ABN', label: 'Limited Partnership'},
  'LSA': {source: 'ABN', label: 'Local Government APRA Regulated Public Sector Fund'},
  'LSP': {source: 'ABN', label: 'Local Government APRA Regulated Public Sector Scheme'},
  'LSS': {source: 'ABN', label: 'Local Government Non-Regulated Super Fund'},
  'LTC': {source: 'ABN', label: 'Local Government Cash Management Trust'},
  'LTD': {source: 'ABN', label: 'Local Government Discretionary Services Management Trust'},
  'LTF': {source: 'ABN', label: 'Local Government Fixed Trust'},
  'LTH': {source: 'ABN', label: 'Local Government Hybrid Trust'},
  'LTI': {source: 'ABN', label: 'Local Government Discretionary Investment Trust'},
  'LTL': {source: 'ABN', label: 'Local Government Listed Public Unit Trust'},
  'LTQ': {source: 'ABN', label: 'Local Government Unlisted Public Unit Trust'},
  'LTT': {source: 'ABN', label: 'Local Government Discretionary Trading Trust'},
  'LTU': {source: 'ABN', label: 'Local Government Fixed Unit Trust'},
  'NPF': {source: 'ABN', label: 'APRA Regulated Non-Public Offer Fund'},
  'NRF': {source: 'ABN', label: 'Non-Regulated Superannuation Fund'},
  'OIE': {source: 'ABN', label: 'Other Incorporated Entity'},
  'PDF': {source: 'ABN', label: 'Pooled Development Fund'},
  'POF': {source: 'ABN', label: 'APRA Regulated Public Offer Fund'},
  'PQT': {source: 'ABN', label: 'Unlisted Public Unit Trust'},
  'PRV': {source: 'ABN', label: 'Australian Private Company'},
  'PST': {source: 'ABN', label: 'Pooled Superannuation Trust'},
  'PTR': {source: 'ABN', label: 'Other Partnership'},
  'PTT': {source: 'ABN', label: 'Public Trading trust'},
  'PUB': {source: 'ABN', label: 'Australian Public Company'},
  'PUT': {source: 'ABN', label: 'Listed Public Unit Trust'},
  'SAF': {source: 'ABN', label: 'Small APRA Regulated Fund'},
  'SCB': {source: 'ABN', label: 'State Government Public Company'},
  'SCC': {source: 'ABN', label: 'State Government Co-operative'},
  'SCL': {source: 'ABN', label: 'State Government Limited Partnership'},
  'SCN': {source: 'ABN', label: 'State Government Other Unincorporated Entity'},
  'SCO': {source: 'ABN', label: 'State Government Other Incorporated Entity'},
  'SCP': {source: 'ABN', label: 'State Government Pooled Development Fund'},
  'SCR': {source: 'ABN', label: 'State Government Private Company'},
  'SCS': {source: 'ABN', label: 'State Government Strata Title'},
  'SCT': {source: 'ABN', label: 'State Government Public Trading Trust'},
  'SCU': {source: 'ABN', label: 'State Government Corporate Unit Trust'},
  'SGA': {source: 'ABN', label: 'State Government Statutory Authority'},
  'SGC': {source: 'ABN', label: 'State Government Company'},
  'SGE': {source: 'ABN', label: 'State Government Entity'},
  'SGP': {source: 'ABN', label: 'State Government Partnership'},
  'SGT': {source: 'ABN', label: 'State Government Trust'},
  'SMF': {source: 'ABN', label: 'ATO Regulated Self-Managed Superannuation Fund'},
  'SSA': {source: 'ABN', label: 'State Government APRA Regulated Public Sector Fund'},
  'SSP': {source: 'ABN', label: 'State Government APRA Regulated Public Sector Scheme'},
  'SSS': {source: 'ABN', label: 'State Government Non-Regulated Super Fund'},
  'STC': {source: 'ABN', label: 'State Government Cash Management Trust'},
  'STD': {source: 'ABN', label: 'State Government Discretionary Services Management Trust'},
  'STF': {source: 'ABN', label: 'State Government Fixed Trust'},
  'STH': {source: 'ABN', label: 'State Government Hybrid Trust'},
  'STI': {source: 'ABN', label: 'State Government Discretionary Investment Trust'},
  'STL': {source: 'ABN', label: 'State Government Listed Public Unit Trust'},
  'STQ': {source: 'ABN', label: 'State Government Unlisted Public Unit Trust'},
  'STR': {source: 'ABN', label: 'Strata-title'},
  'STT': {source: 'ABN', label: 'State Government Discretionary Trading Trust'},
  'STU': {source: 'ABN', label: 'State Government Fixed Unit Trust'},
  'SUP': {source: 'ABN', label: 'Super Fund'},
  'TCB': {source: 'ABN', label: 'Territory Government Public Company'},
  'TCC': {source: 'ABN', label: 'Territory Government Co-operative'},
  'TCL': {source: 'ABN', label: 'Territory Government Limited Partnership'},
  'TCN': {source: 'ABN', label: 'Territory Government Other Unincorporated Entity'},
  'TCO': {source: 'ABN', label: 'Territory Government Other Incorporated Entity'},
  'TCP': {source: 'ABN', label: 'Territory Government Pooled Development Fund'},
  'TCR': {source: 'ABN', label: 'Territory Government Private Company'},
  'TCS': {source: 'ABN', label: 'Territory Government Strata Title'},
  'TCT': {source: 'ABN', label: 'Territory Government Public Trading Trust'},
  'TCU': {source: 'ABN', label: 'Territory Government Corporate Unit Trust'},
  'TGA': {source: 'ABN', label: 'Territory Government Statutory Authority'},
  'TGE': {source: 'ABN', label: 'Territory Government Entity'},
  'TGP': {source: 'ABN', label: 'Territory Government Partnership'},
  'TGT': {source: 'ABN', label: 'Territory Government Trust'},
  'TRT': {source: 'ABN', label: 'Other trust'},
  'TSA': {source: 'ABN', label: 'Territory Government APRA Regulated Public Sector Fund'},
  'TSP': {source: 'ABN', label: 'Territory Government APRA Regulated Public Sector Scheme'},
  'TSS': {source: 'ABN', label: 'Territory Government Non-Regulated Super Fund'},
  'TTC': {source: 'ABN', label: 'Territory Government Cash Management Trust'},
  'TTD': {source: 'ABN', label: 'Territory Government Discretionary Services Management Trust'},
  'TTF': {source: 'ABN', label: 'Territory Government Fixed Trust'},
  'TTH': {source: 'ABN', label: 'Territory Government Hybrid Trust'},
  'TTI': {source: 'ABN', label: 'Territory Government Discretionary Investment Trust'},
  'TTL': {source: 'ABN', label: 'Territory Government Listed Public Unit Trust'},
  'TTQ': {source: 'ABN', label: 'Territory Government Unlisted Public Unit Trust'},
  'TTT': {source: 'ABN', label: 'Territory Government Discretionary Trading Trust'},
  'TTU': {source: 'ABN', label: 'Territory Government Fixed Unit Trust'},
  'UIE': {source: 'ABN', label: 'Other Unincorporated Entity'},
  'PARTNERSHIP': {source: 'TP', label: 'Partnership*'},
  'PTY. LTD.': {source: 'TP', label: 'Proprietary Limited Company*'},
  'SOLE TRADER': {source: 'TP', label: 'Sole Trader*'},
  'TRUST': {source: 'TP', label: 'Trust*'},
  'APTY': {source: 'ACN', label: 'Australian Proprietary Company'},
  'APUB': {source: 'ACN', label: 'Australian Public Company'},
  'ASSN': {source: 'ACN', label: 'Association'},
  'BUSN': {source: 'ACN', label: 'Business Name'},
  'CHAR': {source: 'ACN', label: 'Charity'},
  'COMP': {source: 'ACN', label: 'Community Purpose'},
  'COOP': {source: 'ACN', label: 'Co-Operative Society'},
  'FNOS': {source: 'ACN', label: 'Foreign Company (Overseas)'},
  'LTDP': {source: 'ACN', label: 'Limited Partnership'},
  'MISM': {source: 'ACN', label: 'Managed Investment Scheme'},
  'NONC': {source: 'ACN', label: 'Non Company'},
  'NRET': {source: 'ACN', label: 'Non Registered Entity'},
  'RACN': {source: 'ACN', label: 'Registered Australian Body'},
  'REBD': {source: 'ACN', label: 'Religious Body'},
  'RSVN': {source: 'ACN', label: 'Name Reservation'},
  'SOLS': {source: 'ACN', label: 'Solicitor Corporation'},
  'TRST': {source: 'ACN', label: 'Trust'}
}

export default businessEntityMapping


