export default class MarkdownEditorController {
  /*@ngInject*/
  constructor() {
    this.name = 'markdownEditor'
    this.editingMarkdown = false
  }

  hasMarkdown() {
    return this.model && this.model.trim().length > 0
  }

  isEditingMarkdown() {
    return this.editingMarkdown
  }

  editMarkdown() {
    this.originalMarkdown = this.model
    this.editingMarkdown = true
  }

  cancelMarkdown() {
    this.model = this.originalMarkdown
    this.editingMarkdown = false
    delete this.originalMarkdown
  }

  saveMarkdown() {
    this.editingMarkdown = false
    return this.saveHook && typeof this.saveHook === 'function' && this.saveHook()
  }
}
