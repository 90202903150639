class EditableDisplayController {
  /*@ngInject*/
  constructor($q, $scope) {
    this.name = 'editableDisplay'

    this.$q = $q
    this.scope = $scope

    this.stateDisplay = 'display'
    this.stateEditing = 'editing'

    this.state = this.stateDisplay
    this.successfulSave = false
  }

  actionEdit() {
    this.state = this.stateEditing
    this.successfulSave = false
  }

  actionCancel($event) {
    this.state = this.stateDisplay
    this.successfulSave = false
    if (this.cancelAction && typeof this.cancelAction === 'function') {
      this.cancelAction($event).then(result => {
        if (result.status === 'cancelled') {
          this.state = this.stateDisplay
        }
      })
    }
  }

  actionSave($event) {
    if (!this.saveAction || typeof this.saveAction !== 'function') {
      this.state = this.stateDisplay
      this.successfulSave = false
    } else {
      if (this.saveAction && typeof this.saveAction === 'function') {
        return this.saveAction($event).then(result => {
          if (result.status === 'saved') {
            this.state = this.stateDisplay
            this.successfulSave = true
          }
        })
      } else {
        this.state = this.stateEditing
        return this.$q.resolve('simple')
      }
    }
  }

  get isEditable() {
    return this.editable && this.isDisplay
  }

  get isDisplay() {
    // The following setting is used to communicate whether the form is enabled.
    // It is for use with the non-standards-compliant Angular Material controls.
    // These controls include md-checkbox Grrrr.
    this.scope.$parent.isFormDisabled = this.state === this.stateDisplay
    return this.scope.$parent.isFormDisabled
  }

  get isEdit() {
    return this.state === this.stateEditing
  }

  get saveButtonLabel() {
    return this.saveLabel || 'Save'
  }
}

export default EditableDisplayController
