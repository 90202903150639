class ViewABAController {
  /*@ngInject*/
  constructor(abasRepository) {
    this.name = 'view-aba'
    this.abasRepository = abasRepository
    this.$onInit = () => {
      this.reload()
    }
  }

  reload() {
    this.abasRepository.getById(this.abaId).then((aba) => {
      this.aba = aba
    })
  }

  close() {
    this.completion.resolve(this.aba)
  }
}

export default ViewABAController
