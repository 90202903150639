import fileUploadDialogTemplate from './fileUploadDialog/fileUploadDialog.pug'
import FileUploadDialogController from './fileUploadDialog/fileUploadDialog.controller'

class FileUploadController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.name = 'fileUpload'
    this.$mdDialog = $mdDialog
  }

  uploadFiles($event) {
    return this.$mdDialog.show({
      targetEvent: $event,
      template: fileUploadDialogTemplate({}),
      controllerAs: 'vmd',
      locals: {
        widget: this.widget,
        linkToUri: this.linkToUri,
        existingUploads: this.model
      },
      controller: FileUploadDialogController
    }).then((response) => {
      this.model = response
      this.form[this.widget.modelFieldName].$setDirty()
    }).catch((response) => {
      this.model = response
      this.form[this.widget.modelFieldName].$setPristine()
    })
  }

  fileUploadFor(fileGroup) {
    return this.model && this.model.fileUploads && this.model.fileUploads.find(fileUpload => fileUpload.fileGroup === fileGroup)
  }
}


export default FileUploadController
