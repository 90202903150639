class CountdownTimerController {
  /*@ngInject*/
  constructor($scope, $interval, dateService) {
    this.name = 'countdownTimer'

    const tick = () => {
      this.countdown = this.endDate && dateService.getSecondsTill(this.endDate)
    }

    $scope._ticker = $interval(() => {
      tick()
    }, 1000)

    $scope.$on('$destroy', () => {
      // Make sure that the interval is destroyed too
      $interval.cancel($scope._ticker)
    })

    this.$onInit = () => {
      tick()
    }
  }
}

export default CountdownTimerController
