import angular from 'angular'
import linker from './apiForm.link'

function messagesElement(name) {
  return `<ng-message when="api">{{${name}.apiError}}</ng-message>`
}

function disableButtonsUnlessSubmittable(buttons, formName) {
  // Disable buttons unless form is submittable
  Array.from(buttons)
    .map(button => angular.element(button))
    .filter(button => !button.attr('ng-click') && !button.attr('ng-disabled'))
    .forEach(button => button.attr('ng-disabled', `!${formName}.$submittable`))
}

function addMessageElements(inputs, formName) {
  Array.from(inputs)
    .map(input => angular.element(input))
    .forEach(input => input.after(messagesElement(`${formName}.${input.attr('name')}`)))
}

export default ($q, $mdToast, $window) => {
  return function compile(tElement, tAttrs) {
    const formName = tElement.attr('name')
    if (tAttrs.ngSubmit) {
      tAttrs.apiForm = tAttrs.ngSubmit
      tElement.attr('api-form', tAttrs.ngSubmit)
      tElement.removeAttr('ng-submit')
    }

    const children = tElement.children().detach()
    tElement.append(`<fieldset ng-disabled="${formName}.$currentlySubmitting"/>`).children().append(children)

    addMessageElements(tElement.find('input'), formName)

    disableButtonsUnlessSubmittable(tElement.find('md-button'), formName)

    return linker($q, $mdToast, $window)
  }
}
