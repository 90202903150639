import template from './numberedStep.pug'
import controller from './numberedStep.controller'
import './numberedStep.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      step: '@',
      state: '@',
      complete: '=',
      disabled: '=',
      title: '@',
      subtitle: '@',
      incompleteMessage: '@',
      visited: '=',
      noHandle: '='
    }
  }
}
