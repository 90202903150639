import overrideDialogForm from './overrideDialogForm'

class OverrideDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, loanEnquiry, ruleUuid, resultLine) {
    this.name = 'override-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog

    this.resultLine = resultLine

    this.override = {
      model: {},
      title: 'Override 🤔',
      definition: overrideDialogForm,
      showSubmit: true,
      saveAction: () => this.$q.resolve(),
      submitAction: (model) => {
        return loanEnquiry.performAction('assess-override', {'resultId': resultLine.id, 'comment': model.overrideReason})
          .then(result => $mdDialog.hide(result))
      }
    }
  }

  get heading() {
    return this.override.title
  }

  actionClose() {
    return this.$mdDialog.cancel()
  }

  get actionLabel() {
    return this.override.definition.actionLabel
  }
}

export default OverrideDialogController
