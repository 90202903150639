import moment from 'moment'

class InvestorRegistryController {
  /*@ngInject*/
  constructor($mdToast, csvService, investorsRepository) {
    this.name = 'investor-registry'
    this.$mdToast = $mdToast
    this.csvService = csvService
    this.investorsRepository = investorsRepository

    this.reportDateMin = new Date('2016-01-01')
    this.reportDateMax = moment().startOf('day').toDate()
    this.reportDate = moment().startOf('day').toDate()
  }

  startCsvDownload() {
    if (!this.loading) {
      this.loading = true
      this._loadInvestors([], 5000, 1)
    }
  }

  _loadInvestors(investors, size, page) {
    this.investorsRepository
      .where({status: 'all', includeFunds: true, atDate: moment(this.reportDate).format('YYYY-MM-DD'), size: size, page: page})
      .then((data) => {
        investors = investors.concat(data.investors)
        if (data.investors.length >= size) {
          this._loadInvestors(investors, size, page + 1)
        } else {
          this.loading = false
          this.csvService.createAndDownload('investors-' + moment(this.reportDate).format('YYYY-MM-DD') + '.csv', this._createCsv(investors))
        }
      })
      .catch(() => {
        this._toast('Error: request failed, please contact TruePillars.')
      })
  }

  _createCsv(investors) {
    return investors.map(investor => {
      return {
        'Name': investor.name,
        'Owner': investor.ownerSummary.firstName + ' ' + investor.ownerSummary.lastName,
        'Email': investor.ownerSummary.email,
        'Phone': investor.ownerSummary.phone,
        'BPAY CRN': investor.bpay.crn,
        'Qualified': investor.moneyAdded,
        'Available': investor.availableFunds,
        'Reserved': investor.reservedFunds,
        'P2P Invested': investor.p2pInvestedFunds,
        'Total': investor.totalFunds,
      }
    })
  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }
}

export default InvestorRegistryController
