import moment from 'moment'

class CreateABAController {
  /*@ngInject*/
  constructor($mdToast, $window, abasRepository, loanRepaymentAbasRepository, dateService) {
    this.$mdToast = $mdToast
    this.$window = $window
    this.abasRepository = abasRepository
    this.loanRepaymentAbasRepository = loanRepaymentAbasRepository
    this.name = 'create-aba'

    this.today = dateService.now().startOf('day').toDate()
    this.processedDate = this.today
    this.sequence = 1
    this.minSequence = 1

    this.$onInit = () => {
      this.abasRepository.where({processedDate: moment(this.processedDate).format('YYYY-MM-DD')})
        .then((list) => {
          this.sequence = list.abas.length + 1
          this.minSequence = this.sequence
        })
    }
  }

  createAndDownload() {
    this._create().then(abaFiles => abaFiles.map(aba => this._download(aba.filename, aba.url)))
  }

  close() {
    this.completion.resolve(this.abaFiles)
  }

  cancel() {
    this.completion.reject('cancel')
  }

  // private

  _showToast(error) {
    const msg = 'Error: ' + error.data.message + ' - ' + error.data.errors[0].source + ': ' + error.data.errors[0].message
    this.$mdToast.show(this.$mdToast.simple().textContent(msg).hideDelay(5000).position('top left'))
  }

  _create() {
    return this.loanRepaymentAbasRepository
      .create({loanIds: this.loanIds, debitIds: this.debitIds, processedDate: moment(this.processedDate).format('YYYY-MM-DD'), sequence: this.sequence})
      .then(result => {
        return this.abaFiles = result.abaFiles
      })
      .catch(error => {
        this._showToast(error)
        this.completion.reject(error)
      })
  }

  _download(filename, url) {
    const downloadLink = this.$window.document.createElement('a')
    this.$window.document.body.appendChild(downloadLink)
    downloadLink.href = url
    downloadLink.target = '_blank'
    downloadLink.download = filename
    downloadLink.click()
    this.$window.document.body.removeChild(downloadLink)
  }
}

export default CreateABAController
