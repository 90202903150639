import resolveProperty from './resolveProperty'

const representativeAccountStatus = (representative) => resolveProperty(representative, 'user', 'identityCheck', 'status') ?
    resolveProperty(representative, 'user', 'identityCheck', 'status') === 'verified' ? 'verified' : 'in-progress' :
    'unverified'

const representativeConsentStatus = (representative) => resolveProperty(representative, 'consent', 'hasAuthorisedCreditCheck') ?
  'consent-given' :
  'no-consent'

const isRepresentativeVerifiedAndConsenting = (representative) => representativeAccountStatus(representative) === 'verified' &&
  representativeConsentStatus(representative) === 'consent-given'

export {representativeAccountStatus, representativeConsentStatus, isRepresentativeVerifiedAndConsenting}
