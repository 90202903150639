import assert from '../../utils/assert'
import isDefined from '../../utils/isDefined'
import {extractWidgetsWithRules, parseWidget} from './widgets/formWidget/widget'
import noopPromiseBasedActionGenerator from './helpers/noopPromiseBasedActionGenerator'
import resolveProperty from '../../utils/resolveProperty'
import valueOrDefault from '../../utils/valueOrDefault'

const containsAll = (array1, array2) => array1.every(element => array2.indexOf(element) !== -1)

class DynamicFormController {
  /*@ngInject*/
  constructor(confirmationDialogService, rulesService, loggerService, sharedState, $scope, $q, $mdToast) {
    this.name = 'dynamic-form'
    this.rulesService = rulesService
    this.loggerService = loggerService
    this.$q = $q
    this.$mdToast = $mdToast

    this.submitTrigger = () => this.submit()

    const makeFormContext = () => ({
      dirty: valueOrDefault(resolveProperty(this, 'dynamicForm', '$dirty'), []),
      error: valueOrDefault(resolveProperty(this, 'dynamicForm', '$error'), []),
      invalid: valueOrDefault(resolveProperty(this, 'dynamicForm', '$invalid'), false),
      pristine: valueOrDefault(resolveProperty(this, 'dynamicForm', '$pristine'), false),
      touched: valueOrDefault(resolveProperty(this, 'dynamicForm', '$touched'), false),
      untouched: valueOrDefault(resolveProperty(this, 'dynamicForm', '$untouched'), false),
      valid: valueOrDefault(resolveProperty(this, 'dynamicForm', '$valid'), true),
      untouchedForm: this.untouchedForm,
      canSubmitUntouched: this.canSubmitUntouched,
      submitInProgress: this.submitInProgress,
      cancelInProgress: this.cancelInProgress,
      processing: this.processing
    })

    const processRules = (widgetsWithRules, model, rulesContext) => {
      const MAX_RULE_ITERATIONS = 10
      for (let iter = MAX_RULE_ITERATIONS, anyWidgetChange = true; anyWidgetChange; iter -= 1) {
        if (iter <= 0) {
          const failureMessage = `ERROR: Exceeded the maximum number of form rule iterations. (${MAX_RULE_ITERATIONS})`
          throw typeof Error !== 'undefined' ? new Error(failureMessage) : failureMessage
        }
        anyWidgetChange = widgetsWithRules.reduce((widgetChanged, widget) => {
          const result = widget.processRules({model, rulesContext, formContext: makeFormContext()}, model)
          return widgetChanged || result
        }, false)
      }
    }

    const modelKey = (modelName = 'no-name') => modelName

    const updateSharedState = (modelKeyValue, newVal) => {
      sharedState.set(modelKeyValue, newVal)
      sharedState.notify()
    }

    this.processing = false
    this.submitInProgress = false
    this.cancelInProgress = false
    this.untouchedForm = true
    this.showFormControls = false

    this.$onInit = () => {
      assert(this.model && typeof this.model === 'object' && !Array.isArray(this.model), 'You must provided a model object to receive the form responses.')

      // Initialise the submit button area for the form.
      if (isDefined(this.showSubmit) && typeof this.showSubmit === 'boolean') {
        this.showFormControls = this.showSubmit
        this.processFormControlsRules = () => {
        }
      } else {
        const ruleDefinition = this.showSubmit
        this.processFormControlsRules = (model, rulesContext) => {
          const formContext = makeFormContext()
          this.showFormControls = this.rulesService.parse(ruleDefinition, {model, rulesContext, formContext})
        }
      }

      // Initialise the cancel button, if it's defined.
      this.showCancel = valueOrDefault(this.showCancel, false)
      if (!isDefined(this.cancelFn) || typeof this.cancelFn !== 'function') {
        this.cancelFn = noopPromiseBasedActionGenerator($q, '-cancel-')
      }

      // Initialise the ability to submit an unchanged (untouched) form.
      this.canSubmitUntouched = !!this.submitUntouched

      // Initialise the confirmation dialog processing, if it's defined
      if (typeof this.requiresConfirmation === 'object' &&
        containsAll(['dialogName', 'dialogModelProvider'], Object.getOwnPropertyNames(this.requiresConfirmation))) {
        this.confirmationDialog = (submitEvent) => confirmationDialogService.confirmAction(
          submitEvent,
          this.requiresConfirmation.dialogName,
          this.requiresConfirmation.dialogModelProvider())
      } else {
        this.confirmationDialog = () => {
          const deferred = $q.defer()
          deferred.resolve('-no confirmation required-')
          return deferred.promise
        }
      }

      // Initialise the form cache.
      const formCache = new Map()
      this.compiledWidgets = this.formDefinition.widgets.map(widget => parseWidget(widget, formCache, (...args) => this.rulesService.parse(...args)))

      const extractedWidgetsWithRules = extractWidgetsWithRules(this.compiledWidgets)

      // Watch for changes to the model and then trigger the rule processing.
      $scope.$watch('vm.model', (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.untouchedForm = false
        }
        const candidateModelKey = modelKey(this.formDefinition.model)
        if (!sharedState.has(candidateModelKey) || newVal !== oldVal) {
          updateSharedState(candidateModelKey, newVal)
          processRules(extractedWidgetsWithRules, this.model, this.rulesContext)
        }
        this.processFormControlsRules(this.model, this.rulesContext)
      }, true)

      // Initialise the continuous save function.
      $scope.saveForm = () => {
        return this.saveFn(this.model, this.compiledWidgets)
      }

      // Setup for the cleanup of the form upon completion/destruction.
      $scope.$on('$destroy', () => {
        sharedState.delete(modelKey(modelKey(this.formDefinition.model)))
      })
    }
  }

  formName() {
    return this.formDefinition && this.formDefinition.name
  }

  get title() {
    return this.formDefinition && this.formDefinition.title
  }

  get widgets() {
    return this.compiledWidgets ? this.compiledWidgets.filter(widget => widget.display !== 'none') : []
  }

  // TODO: Why didn't we call this "submitButton" and "submitButtonLabel"? I have no confidence in changing it.
  get actionButtonLabel() {
    const initActionLabel = () => {
      if (this.actionLabel) {
        return this.actionLabel
      } else if (this.formDefinition && this.formDefinition.actionLabel) {
        return this.formDefinition.actionLabel
      }
      return 'Continue'
    }
    return this.submitInProgress ? `${initActionLabel()} - in progress` : initActionLabel()
  }

  get cancelButtonLabel() {
    const initCancelLabel = () => {
    if (this.cancelLabel) {
      return this.cancelLabel
      } else if (this.formDefinition && this.formDefinition.cancelLabel) {
        return this.formDefinition.cancelLabel
      }
      return 'Cancel'
    }
    return this.submitInProgress ? `${initCancelLabel()} - in progress` : initCancelLabel()
  }

  get hasClickTracking() {
    return this.clickTracking && typeof this.clickTracking === 'string' && this.clickTracking.trim().length > 0
  }

  submit(submitEvent) {
    this.processing = true
    this.submitInProgress = true
    if (this.dynamicForm.$valid && !this.dynamicForm.$invalid) {
      return this.confirmationDialog(submitEvent)
        .then(() => this.submitFn(this.model, this.compiledWidgets)
          .then(() => {
            this.untouchedForm = true
            this.submitInProgress = false
            this.processFormControlsRules(this.model, this.rulesContext)
            this.processing = false
            return this.dynamicForm && this.dynamicForm.$setPristine()
          })
        )
        .catch((e) => {
          this.submitInProgress = false
          this.processing = false
          this._showError(e)
          throw e
        })
    } else {
      // If you're here then there's something wrong with the form logic that let this submit method be called.
      const rejectionReasons = [
        (!this.dynamicForm.$valid) ? 'not valid' : undefined,
        (this.dynamicForm.$invalid) ? 'invalid' : undefined
      ].filter(isTrue => isTrue).join(' and ')
      const rejectionMessage = `The form is ${rejectionReasons}.`
      this.submitInProgress = false
      this.processing = false
      const result = this.$q.defer()
      result.promise.reject(rejectionMessage)
      return result.promise
    }
  }

  disableSubmit() {
    return (this.untouchedForm && !this.canSubmitUntouched) ||
      this.processing ||
      (isDefined(this.dynamicForm.$invalid) && this.dynamicForm.$invalid) ||
      (isDefined(this.dynamicForm.$valid) && !this.dynamicForm.$valid) ||
      this.showAction !== undefined && !this.showAction
  }

  notSubmitting() {
    return !this.submitInProgress && !this.processing
  }

  performCancelAction() {
    this.processing = true
    this.cancelInProgress = true
    return this.cancelFn(this.model, this.compiledWidgets).then(() => {
      this.cancelInProgress = false
      this.untouchedForm = true
      this.processFormControlsRules()
      this.processing = false
      return this.dynamicForm && this.dynamicForm.$setPristine()
    })
  }

  currentFormErrors() {
    const createOrGetField = (name, element, mapper) => {
      const field = mapper.get(name)
      if (field) {
        return field
      } else {
        const newField = {
          name: name,
          element: element,
          errors: new Set()
        }
        mapper.set(name, newField)
        return newField
      }
    }
    return Object.getOwnPropertyNames(this.dynamicForm.$error).reduce((mapper, errorKey) => {
      this.dynamicForm.$error[errorKey].map(error => {
        if (isDefined(error.$name)) {
          const field = createOrGetField(error.$name, error.$$element, mapper)
          field.errors.add(errorKey)
        }
      })
      return mapper
    }, new Map())
  }

  completionAssistance($event) {
    $event.preventDefault()
    const formErrors = this.currentFormErrors()
    if (formErrors.size > 0) {
      formErrors.get([...formErrors.keys()][0]).element.focus()
    }
  }

  /* ****** private */

  _createFormDiagnostic(formErrors) {
    const transformFormErrors = (formErrors) => {
      return [...formErrors.keys()].reduce((obj, key) => {
        obj[key] = [...formErrors.get(key).errors.values()].join(',')
        return obj
      }, {})
    }
    return {
      formTitle: this.formDefinition.title,
      formModel: this.formDefinition.model,
      untouchedForm: this.untouchedForm,
      canSubmitUntouched: this.canSubmitUntouched,
      submitInProgress: this.submitInProgress,
      processing: this.processing,
      cancelInProgress: this.cancelInProgress,
      dynamicForm$invalid: this.dynamicForm.$invalid,
      dynamicForm$valid: this.dynamicForm.$valid,
      formErrors: JSON.stringify(transformFormErrors(formErrors))
    }
  }

  _showError(e) {
    const message = e?.message || e
    this.$mdToast.show(this.$mdToast.simple().position('top left').hideDelay(5000).textContent('Error: ' + message))
  }
}

export default DynamicFormController
