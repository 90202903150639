import Big from 'big.js'

const makeObservableAmount = (initialAmount) => {
  let baseAmount = initialAmount ? new Big(initialAmount) : new Big('0')
  const allObservables = []
  const observableAmount = {
    set amount(newAmount) {
      baseAmount = new Big(newAmount)
    },
    get amount() {
      return allObservables.reduce((interimAmount, observable) => {
        return interimAmount.plus(observable.amount)
      }, baseAmount)
    },
    plus(addedAmount) {
      baseAmount = baseAmount.plus(new Big(addedAmount))
      return observableAmount
    },
    minus(subtractedAmount) {
      baseAmount = baseAmount.minus(new Big(subtractedAmount))
      return observableAmount
    },
    addObservables(...observables) {
      observables.forEach(observable => allObservables.push(observable))
      return observableAmount
    },
  }
  return observableAmount
}

export default makeObservableAmount
