import angular from 'angular'
import 'angular-ui-router'

import investorQualifyingComponent from './investorQualifying.component'

export default angular.module('app.pages.investors.investorQualifying', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('investorQualifying', {
        url: '/investor-qualifying',
        template: '<investor-qualifying />',
        data: {
          requireAuth: true
        },
        tabName: 'Dashboard'
      })
  }])
  .directive('investorQualifying', investorQualifyingComponent)
