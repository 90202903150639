import {
  assetNewOrUsed,
  assetPrice,
  assetRideShareElectricVehicleFixed,
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  immigrationVisaType,
  isReferrerNotes,
  isResidencyStatus,
  isResidencyStatusVisaHolder,
  isYearOfManufacture,
  isBorrowerDone,
  isReferrer,
  loanAmount,
  loanPurpose,
  preferredCommission,
  proposedDeposit,
  referrerEmail,
  referrerFirstName,
  referrerLastName,
  referrerNotes,
  referrerPhone,
  residencyStatus,
  vehicleModel,
  vendorSource,
  yearOfManufacture,
  isReferrerEmail,
  isBorrowerAddress,
  isOriginationFee,
  originationFee,
  isVendorSource,
} from '../dynamicEnquiryFormHelpers'
import {
  and,
  conjunction,
  container,
  fromModel,
  helpContent, info,
  isModelDefined,
  radioButtons,
  ruleRender,
  ruleSet
} from '../../../dynamicForm/helpers/dynamicFormHelpers'
import {calculateLoanAmount} from '../sharedUtilities'

const isPrivateVendorAssetWarning = and('(gt (fromModel "assetPrice") 40000)', '(eq (fromModel "vendorSource") "private")')

const rideshareBrokerEnquiryForm = {
  title: 'Broker rideshare finance',
  model: 'rideshare-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      // .prompt('Rideshare Vehicle Finance application')
      // .classes('border')
      .widgets(
        loanPurpose()
          .required(true)
          .display('none')
          .rules(ruleSet('"asset"'))
          .build(),
        contactType().candidatePartner().build(),
        helpContent().contentId('enquiry-form-rideshare-application-main').build(),
        container()
          .boxed(5)
          .prompt('Accredited broker details')
          .widgets(
            referrerFirstName().build(),
            referrerLastName().build(),
            referrerPhone().build(),
            referrerEmail().build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Asset details')
          .rules(ruleRender(isReferrerEmail))
          .widgets(
            assetPrice()
              .prompt('Asset purchase price')
              .build(),
            vehicleModel()
              .build(),
            assetRideShareElectricVehicleFixed()
              .yes()
              .build(),
            vendorSource()
              .rules(ruleRender(isReferrerEmail))
              .build(),
            container()
              .boxed(5)
              .prompt('Warning!')
              .rules(ruleRender(isPrivateVendorAssetWarning))
              .widgets(
                helpContent().contentId('enquiry-form-private-vendor-asset-warning').build(),
              ).build(),
            assetNewOrUsed()
              .rules(ruleRender(isVendorSource))
              .build(),
            yearOfManufacture()
              .required(true)
              .prompt('Vehicle year')
              .addRules(ruleRender(isVendorSource))
              .build(),
          ).build(),
        proposedDeposit()
          .prompt('Deposit amount (if applicable)')
          .rules(ruleRender(isYearOfManufacture))
          .build(),
        loanAmount()
          .prompt('Financed amount (calculated)')
          .min(15_000)
          .max(200_000)
          .required(false)
          .rules(ruleRender(isYearOfManufacture), ruleSet(calculateLoanAmount))
          .display('read-only')
          .build(),
        preferredCommission()
          .prompt('What is your preferred brokerage?')
          .min(0)
          .max(7)
          .step(0.5)
          .required(true)
          .rules(ruleRender(isYearOfManufacture))
          .build(),
        originationFee()
          .rules(ruleRender(isYearOfManufacture))
          .build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isOriginationFee))
          .widgets(
            info().content('By completing these details you are confirming that you hold the guarantor\'s authority to provide them, ' +
              'including (where applicable) copies of documents containing personal identifying information.').build(),
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background and reason for the loan')
              .required(true)
              .rules(ruleRender(isOriginationFee))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            ...borrowerFullComposite(),
            residencyStatus()
              .rules(ruleRender(isBorrowerAddress))
              .build(),
            immigrationVisaType()
              .addRules(ruleRender(isResidencyStatusVisaHolder))
              .build(),
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Yes, another', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(and(isResidencyStatus, isBorrowerDone)))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(and(isResidencyStatus, isBorrowerDone)))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('driverProfile', 'Rideshare or food delivery profile screen shot')
              .fileGroup('driversLicence', 'Copy of Drivers License')
              .fileGroup('councilRates', 'Rates Notice if property owner (optional at this stage)')
              .fileGroup('idDocuments', 'Medicare Card (Citizen/PR) or Visa Grant notice')
              .fileGroup('assetDetails', 'Invoice for the asset')
              .fileGroup('assetDetails', 'Certificate of Currency for insurance (optional at this stage)')
              .fileGroup('assetDetails', 'Receipts for any deposits (if applicable)')
              .fileGroup('creditReports', 'Equifax credit report for individuals (optional but preferred)')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .includeComments(false)
              .build())
          .build())
      .build()
  ]
}

export default rideshareBrokerEnquiryForm
