import Chartist from 'chartist'

class RiskBar {

  addGradient(options) {
    const POINTER_HEIGHT = 15
    const POINTER_WIDTH = 15

    this._addPointer(
      jQuery.extend(options, {pointerHeight: POINTER_HEIGHT, pointerWidth: POINTER_WIDTH})
    )

    let defs = new Chartist.Svg('defs')
    let gradient = defs.elem('linearGradient', {id: 'risk-gradient', x2: '0%', y2: '100%'})

    if (options.type === 'sample') {
      gradient.elem('stop', {'offset': '0%', 'stop-color': 'lightgrey'})
      gradient.elem('stop', {'offset': '50%', 'stop-color': 'darkgrey'})
      gradient.elem('stop', {'offset': '100%', 'stop-color': 'grey'})
    } else {
      gradient.elem('stop', {'offset': '0%', 'stop-color': 'red'})
      gradient.elem('stop', {'offset': '50%', 'stop-color': 'gold'})
      gradient.elem('stop', {'offset': '100%', 'stop-color': 'green'})
    }

    let rect = new Chartist.Svg(
      'rect',
      {
        fill: 'url(' + options.window.location + '#risk-gradient)',
        x: options.x,
        y: options.y,
        width: options.width + POINTER_WIDTH,
        height: options.height,
      })

    options.svg.append(rect, true)
    options.svg.append(defs, true)
  }

  _addPointer(options) {
    let pointer = new Chartist.Svg('path', {
      'd': this._framePath(options) + this._cutoutPath(options),
      'fill-rule': 'evenodd',
      'stroke-width': 0,
    }).addClass('ct-pointer-template')
    options.svg.append(pointer, true)
  }

  _framePath(options) {
    return [
      'M', options.x + options.width, options.y-1,
      'v', options.height+2,
      'h', options.pointerWidth,
      'v', -options.height-2,
      'z'
    ].join(' ')
  }

  _cutoutPath(options) {
    let p1 = {
      x: options.x+options.width,
      y: options.y+options.height -
        options.tickHeight*(options.mostRecentRiskRating+0.75) -
        options.pointerHeight/2,
    }
    let p2 = {
      x: p1.x + options.pointerWidth,
      y: p1.y + options.pointerHeight/2
    }
    let p3 = {
      x: p1.x,
      y: p1.y + options.pointerHeight
    }

    return [
      'M', p1.x, p1.y,
      'L', p2.x, p2.y,
      'L', p3.x, p3.y,
      'z'
    ].join(' ')
  }

}

export default RiskBar
