class GreenidService {
  /*@ngInject*/
  constructor(environment, identityChecksRepository, greenidLoader, $q, $rootScope) {
    this.$rootScope = $rootScope
    this.environment = environment
    this.identityChecksRepository = identityChecksRepository
    this._loadPromise = greenidLoader.load()
    this.$q = $q
    this.recommendAssistance = false
  }

  tokenForUser(userIdentity) {
    return this.$q((resolve, reject) => {
      if(userIdentity.identityCheckAttempted()) {
        if(userIdentity.identityCheckInProgress()) {
          this.identityChecksRepository.getById(userIdentity.identityCheck.id).then((identityCheckWithToken) => {
            userIdentity.identityCheck = identityCheckWithToken
            resolve(identityCheckWithToken)
          }).catch((error) => {
            reject(error)
          })
        } else {
          reject('Identity check already completed!')
        }
      } else {
        this.identityChecksRepository.create({userId: userIdentity.resource.id}).then((identityCheck) => {
          userIdentity.identityCheck = identityCheck
          resolve(identityCheck)
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  show(elementId, identityCheck) {
    let verificationAttempts = 0
    this.recommendAssistance = false
    return this.$q((resolve, reject) => {
      this._loadPromise.then(() => {
        window.greenidUI.setup({
          environment: this.environment.GREENID_ENVIRONMENT,
          frameId: elementId,
          errorCallback: (veritificationToken, errorName) => {
            reject(errorName)
          },
          sourceAttemptCallback: (veritificationToken, sourceName, checkState, overallState) => {
            verificationAttempts += 1
            if (verificationAttempts > 1) {
              this.$rootScope.$apply(() => {
                this.recommendAssistance = true
              })
            }
          },
          submitCallback: (verificationToken, overallState) => {
            identityCheck.checkStatus = overallState
            identityCheck.save().then((idCheck) => {
              resolve(idCheck)
            }).catch(() => {
              reject('Greenid result could not be confirmed!')
            })
          }
        })
        window.greenidUI.show(this.environment.GREENID_ACCOUNT_ID, this.environment.GREENID_API_CODE, identityCheck.token)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default GreenidService
