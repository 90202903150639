class ContentfulAssetsService {
  /*@ngInject*/
  constructor($q, contentful) {
    this.name = 'contentful-assets-service'

    this.assets = new Map()
    this.loadedMetadata = $q.defer()

    const mapAsset = (item) => ({
      id: item.sys.id,
      revision: item.sys.revision,
      asset: {
        title: item.fields.title,
        file: item.fields.file
      }
    })

    const assetLoader = (skip, count) => {
      const query = `skip=${skip}&limit=100&mimetype_group=image`
      return contentful.assets(query).then(results => {
        results.data.items.map(mapAsset).forEach(item => {
          this.assets.set(item.asset.title, item)
        })
        const loadedCount = count + results.data.items.length
        if (loadedCount < results.data.total) {
          assetLoader(skip + 100, loadedCount)
        } else {
          this.loadedMetadata.resolve()
        }
      })
    }

    this.init = () => this.assetsPromise = assetLoader(0, 0)
  }

  findAsset(title) {
    return this.loadedMetadata.promise.then(() => {
      const metadata = this.assets.get(title)
      return metadata
    })
  }
}

export default ContentfulAssetsService
