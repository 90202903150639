import template from './creditorWatchAsicExtractDisplay.pug'
import controller from './creditorWatchAsicExtractDisplay.controller'
import './creditorWatchAsicExtractDisplay.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      report: '<',
    }
  }
}
