export default ['$log', '$interval', function($log, $interval) {
  return {
    restrict: 'A',
    require: ['^form'],
    link: function(scope, element, attrs, controllers) {

      const $formCtrl = controllers[0]
      const autoSaveExpression = attrs.autoSaveForm
      if (!autoSaveExpression) {
        $log.error('autoSaveForm missing parameter')
        return
      }

      let savePromise = null
      let formModified = undefined

      scope.$on('$destroy', function() {
        $interval.cancel(savePromise)
      })

      scope.$watch(() => {
        // note: formCtrl.$valid is undefined when this first runs, so we use !$formCtrl.$invalid instead
        return !!(!$formCtrl.$invalid && $formCtrl.$dirty)
      }, (newValue, oldVaue, scope) => {

        if (!newValue) {
          // ignore, it's not "valid and dirty"
          return
        }

        // Mark pristine here - so we get notified again if the form is further changed, which would make it dirty again
        $formCtrl.$setPristine()

        if (savePromise) {
          // yikes, note we've had more activity - which we interpret as ongoing changes to the form.
          formModified = true
          return
        }

        // initialize - for the new interval timer we're about to create, we haven't yet re-dirtied the form
        formModified = false

        savePromise = $interval(() => {

          if (formModified) {
            // darn - we've got to wait another period for things to quiet down before we can save
            formModified = false
            return
          }

          $interval.cancel(savePromise)
          savePromise = null

          // Still valid?

          if ($formCtrl.$valid) {

            $formCtrl.$saving = true

            const autoSavePromise = scope.$eval(autoSaveExpression)
            if (!autoSavePromise || !autoSavePromise.finally) {
              $log.error('autoSaveForm not returning a promise')
            }

            autoSavePromise
              .finally(function() {
                $formCtrl.$saving = undefined
              })
          }
        }, 500)

      })
      return 'done'
    }
  }
}]
