class AmendInvestmentProductRedemptionRequestController {
  /*@ngInject*/
  constructor() {
    this.disbursement = false

    this.$onInit = () => {
      this.amount = this.investmentProductRedemptionRequest.amount
      this.fee = this.investmentProductRedemptionRequest.estimatedFee
    }
  }

  confirmAction() {
    return this._update()
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  _update() {
    const payload = {
      amount: this.amount,
      fee: this.fee,
    }
    return this.investmentProductRedemptionRequest.performAction('approve', payload)
      .then((investmentProductRedemptionRequest) => this.completion.resolve(investmentProductRedemptionRequest))
  }
}

export default AmendInvestmentProductRedemptionRequestController
