class Bidder {
  constructor(investor, auction) {
    this.investor = investor
    this.auction = auction
    this.borrowerMinRate = Number(this.auction.minRate)
    this.borrowerMaxRate = Number(this.auction.entryRate)
    this.amount = Number(this.auction.bidAmountMin)
  }

  get rateStep() {
    return Number(this.auction.rateStep)
  }

  get bidStep() {
    return Number(this.auction.bidAmountStep)
  }

  get minRate() {
    return Number(this.auction.minRate)
  }

  get maxRate() {
    return Number(this.auction.entryRate)
  }

  get minimumBid() {
    return Number(this.auction.bidAmountMin)
  }

  get maximumBid() {
    const numericBidStep = Number(this.auction.bidAmountStep)
    const quantisedAvailableFunds = numericBidStep !== 0 ? Math.floor(Number(this.investor.availableFunds) / numericBidStep) * numericBidStep : 0
    return Math.min(quantisedAvailableFunds, Number(this.auction.bidAmountMax))
  }

  get investorMarginRate() {
    return Number(this.auction.investorMarginRate)
  }

  get investorMinRate() {
    return this.borrowerMinRate && this.investorMarginRate ? this.borrowerMinRate - this.investorMarginRate : null
  }

  get investorMaxRate() {
    return this.borrowerMaxRate && this.investorMarginRate ? this.borrowerMaxRate - this.investorMarginRate : null
  }
}
export default Bidder
