import Big from '../../../../../../../../node_modules/big.js/big'
import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/x-pseudo'

const LoanSummaryRegisterType = Symbol.for('loan summary')

class LoanSummaryRegister {

  constructor(pdfService, csvService) {
    this.pdfService = pdfService
    this.csvService = csvService
  }

  processPdf(reportDate, loanSummaries) {
    const processedLoanSummaries = this._processLoanSummaries(loanSummaries)
    const totalAmount = processedLoanSummaries.reduce((sum, current) => sum.plus(current.amount), new Big('0.0'))
    const totalOutstanding = processedLoanSummaries.reduce((sum, current) => sum.plus(current.outstanding), new Big('0.0'))

    const fontSize = 6

    // -- Generate the body of the document table, with headings
    const tableBody = (dataRows) => {
      const body = [
        [
          this.pdfService.thl('\nUnderlying Borrower', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thr('Loan Notes', -1, {colSpan: 2, fontSize: fontSize}),
          this.pdfService.thr(' '),
          this.pdfService.thr('Original\nPrincipal', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thr('Current\nBalance', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thr('Borrower\nInterest Rate', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thr('Term\n(Months)', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thr('Drawdown\nDate', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thr('Payments\nMade', -1, {rowSpan: 2, fontSize: fontSize}),
          this.pdfService.thl('\nStatus', -1, {rowSpan: 2, fontSize: fontSize})
        ],
        [
          this.pdfService.thl(' '),
          this.pdfService.thr('first', -1, {fontSize: fontSize}),
          this.pdfService.thr('last', -1, {fontSize: fontSize}),
          this.pdfService.thr(' '),
          this.pdfService.thr(' '),
          this.pdfService.thr(' '),
          this.pdfService.thr(' '),
          this.pdfService.thr(' '),
          this.pdfService.thr(' '),
          this.pdfService.thr(' '),
        ]
      ]

      dataRows.forEach((row, index) => {
        const tableRow = []
        tableRow.push(this.pdfService.tdl(row.borrowerName, index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(row.loanNotesFirst, index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(row.loanNotesLast, index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(this.pdfService.asMoney(row.amount), index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(this.pdfService.asMoney(row.outstanding), index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(row.rate.round(2, 1).toFixed(2).toString(), index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(row.term, index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(row.startDate.format('D MMM YYYY'), index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdr(row.repayments, index, {fontSize: fontSize}))
        tableRow.push(this.pdfService.tdl(row.status, index, {fontSize: fontSize}))
        body.push(tableRow)
      })
      return body
    }

    const tableColumnWidths = ['*', 22, 22, 38, 38, 40, 25, 38, 28, 29]

    // -- The main report table, with the table body.
    const tableData = {
      table: {
        headerRows: 2,
        widths: tableColumnWidths,

        body: tableBody(processedLoanSummaries),
      }
    }

    const tableSummaryData = {
      table: {
        headerRows: 0,
        widths: tableColumnWidths,

        body: [
          [
            this.pdfService.thr(`Total number of loans: ${processedLoanSummaries.length}`, -1, {colSpan: 3, fontSize: fontSize, color: 'white', fillColor: 'black'}),
            this.pdfService.thr(''),
            this.pdfService.thr(''),
            this.pdfService.thr(this.pdfService.asMoney(totalAmount), -1, {fontSize: fontSize, color: 'white', fillColor: 'black'}),
            this.pdfService.thr(this.pdfService.asMoney(totalOutstanding), -1, {fontSize: fontSize, color: 'white', fillColor: 'black'}),
            this.pdfService.thr('', -1, {colSpan: 5, color: 'white', fillColor: 'black'}),
            this.pdfService.thr(''),
            this.pdfService.thr(''),
            this.pdfService.thr(''),
            this.pdfService.thr('')
          ]
        ],
      }
    }

    const reportDateMoment = moment(reportDate)
    const docDefinition = this.pdfService.createDocumentDefinition(reportDateMoment.locale('en').format('D MMMM YYYY'),
      'TruePillars Investment Trust', 'Underlying Loan Summary', tableSummaryData, ' ', tableData)
    this.pdfService.createAndDownload(this._reportName(reportDateMoment, 'pdf'), docDefinition)
    return {reportDate: reportDateMoment, count: processedLoanSummaries.length, amount: totalAmount, outstanding: totalOutstanding}
  }

  processCsv(reportDate, loanSummaries) {
    const processedLoanSummaries = this._processLoanSummaries(loanSummaries)
    const totalAmount = processedLoanSummaries.reduce((sum, current) => sum.plus(current.amount), new Big('0.0'))
    const totalOutstanding = processedLoanSummaries.reduce((sum, current) => sum.plus(current.outstanding), new Big('0.0'))

    const reportDateMoment = moment(reportDate)
    const formattedReportDate = reportDateMoment.locale('en').format('YYYY-MM-DD')

    const csvLoanSummaries = processedLoanSummaries.map((summary) => {
      return {
        'Report Date': formattedReportDate,
        'Borrower Name': summary.borrowerName,
        'Loan Notes First': summary.loanNotesFirst,
        'Loan Notes Last': summary.loanNotesLast,
        'Original Principal': summary.amount.round(2, 1).toFixed(2),
        'Current Balance': summary.outstanding.round(4, 1).toFixed(4),
        'Borrower Rate': summary.rate.round(2, 1).toFixed(2),
        'Term': summary.term,
        'Drawdown': summary.startDate.format('YYYY-MM-DD'),
        'Payments': summary.repayments,
        'Status': summary.status
      }
    })
    this.csvService.createAndDownload(this._reportName(reportDateMoment, 'csv'), csvLoanSummaries)
    return {reportDate: reportDateMoment, count: csvLoanSummaries.length, amount: totalAmount, outstanding: totalOutstanding}
  }

  // -- private

  _reportName(reportDateMoment, suffix) {
    return `loanSummaryRegister-${reportDateMoment.format('YYYY-MM-DD')}.${suffix}`
  }

  _processLoanSummaries(loanSummaries) {
    return loanSummaries.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0).map((summary) => {
      return {
        borrowerName: summary.borrowerName,
        loanNotesFirst: summary.loanNotesMin,
        loanNotesLast: summary.loanNotesMax,
        amount: new Big(summary.amount),
        outstanding: new Big(summary.principalOutstanding),
        rate: new Big(summary.rate),
        term: summary.termInMonths,
        startDate: moment(summary.startDate).locale('en'),
        repayments: summary.repayments,
        status: summary.status
      }
    })
  }
}

export {LoanSummaryRegisterType, LoanSummaryRegister}
