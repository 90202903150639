import angular from 'angular'

import Big from 'big.js'
import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/x-pseudo'
import showdown from 'showdown'
import valueOrDefault from '../utils/valueOrDefault'
import isDefined from '../utils/isDefined'

import markdownEditorConfig from './compileMarkdown/markdownEditorConfig'

import businessEntityMapping from './businessEntityMapping'

import {loanEnquiryStage, loanEnquiryStatus, loanEnquiryStageDisplay, loanEnquiryStatusDisplay} from '../utils/loanEnquiryStatusHelper'

const BIG_ZERO = new Big(0)
const BIG_ONE = new Big(1)


const baseNumericValue = (amount, decimals = 2, rounding = 'down') => {
  if (!angular.isDefined(amount) || amount === null) {
    return null
  }
  const roundingModes = new Map([['up', 3], ['down', 0], ['half-up', 1]])
  const roundingMode = roundingModes.has(rounding) ? roundingModes.get(rounding) : 1
  const value = typeof amount === 'object' && amount instanceof Big ? amount : new Big(amount)
  return value.round(decimals, roundingMode).toString()
}

export default angular.module('app.filters', [])
  .filter('asTFN', () => {
    return (candidateTfn) => {
      if (candidateTfn === null || candidateTfn === undefined) {
        return '- no tax file number -'
      }
      const tfnAsString = candidateTfn.toString().trim()
      return tfnAsString.length === 9 ? [tfnAsString.slice(0, 3), tfnAsString.slice(3, 6), tfnAsString.slice(6, 9)].join(' ') : tfnAsString
    }
  })
  .filter('monthYear', () => {
    return (units, frequency = 'monthly') => {
      const frequencyMatcher = (targetFrequency) => (freq) => freq.toString().trim().toLowerCase() === targetFrequency
      const mapping = [
        {predicate: frequencyMatcher('daily'), action: () => ({years: Math.floor(units / 264), months: Math.round((units % 264) / 21)})},
        {predicate: frequencyMatcher('weekly'), action: () => ({years: Math.floor(units / 52), months: Math.round((units % 52) / 4)})},
        {predicate: frequencyMatcher('fortnightly'), action: () => ({years: Math.floor(units / 26), months: Math.round((units % 26) / 2)})},
        {predicate: frequencyMatcher('monthly'), action: () => ({years: Math.floor(units / 12), months: units % 12})},
        {predicate: frequencyMatcher('yearly'), action: () => ({years: units, months: 0})},
        {predicate: () => true, action: () => ({years: 0, months: 0})},
      ]
      const {years, months} = mapping.find((pair) => pair.predicate(frequency)).action()
      if (years === 0 && months === 0) {
        return 'days'
      }
      if (months === 0) {
        return (years === 1) ? '1 year' : ((years === 0) ? '' : `${years} years`)
      }
      return (years > 0) ? `${years}y ${months}m` : ((months === 1) ? `${months} month` : `${months} months`)
    }
  })
  .filter('personalise', () => {
    return (input, pov = 2) => {
      if (pov === 3) {
        input = input.replace(/#([Dd])o\b/g, '$1oes')
        // input = input.replace(/#do\b/g, 'does\b')
        input = input.replace(/#your /g, 'the borrower\'s ')
        input = input.replace(/#you /g, 'the borrower ')
        input = input.replace(/#Your /g, 'Borrower ')
        input = input.replace(/#are /g, 'is ')
      } else {
        input = input.replace(/#Does /g, 'Do ')
        input = input.replace(/#does /g, 'do ')
        input = input.replace(/#their /g, 'your ')
        input = input.replace(/#them /g, 'you ')
        input = input.replace(/#they /g, 'you ')
        input = input.replace(/#the /g, 'your ')
        input = input.replace(/#Their /g, 'Your ')
        input = input.replace(/#is /g, 'are ')
      }
      input = input.replace(/#/g, '')
      return input
    }
  })
  .filter('mediaSelect', ['$mdMedia', ($mdMedia) => {
    return (input, layout = 'sm') => {
      return $mdMedia(layout) ? input[0] : input[1]
    }
  }])
  .filter('accounting', ['$filter', ($filter) => {
    return (input) => {
      if (input < 0) {
        return '(' + $filter('number')(-input, '0') + ')'
      } else if (input === 0) {
        return '\u2014\u00a0'
      } else {
        return $filter('number')(input, '0') + '\u00a0'
      }
    }
  }])
  .filter('asPeriod', () => {
    return (input, format = '') => {
      if (format === 'long') {
        if (input > 0) {
          return Math.floor(input / 86400) + 'd ' +
            Math.floor((input % 86400) / 3600) + 'h ' +
            Math.floor((input % 3600) / 60) + 'm ' +
            Math.floor(input % 60) + 's'
        } else {
          return '-'
        }
      }

      let days = Math.floor(input / 86400)
      let hours = Math.floor((input % 86400) / 3600)
      let mins = Math.floor((input % 3600) / 60)
      let secs = Math.floor(input % 60)

      if (input >= 604800) {
        return `${days} days`
      } else if (input >= 172800) {
        return (hours === 0) ? `${days} days` : `${days}d ${hours}h`
      } else if (input >= 86400) {
        return (hours === 0) ? `${days} day` : `${days}d ${hours}h`
      } else if (input >= 3600) {
        return `${hours}h ${mins}m`
      } else if (input >= 60) {
        return `${mins}m ${secs}s`
      } else if (input > 0) {
        return `${secs}s`
      } else {
        return 'completed'
      }
    }
  })
  .filter('onlyDigits', () => {
    return (input, index) => {
      const matches = input.match(/(\d+)\D+(\d*)\D*/)
      return matches && matches[index]
    }
  })
  .filter('onlyText', () => {
    return (input, index) => {
      const matches = input.match(/\d+(\D+)\d*(\D*)/)
      return matches && matches[index]
    }
  })
  .filter('asStars', () => {
    return (input) => {
      if (input === 'A') {
        return 'star star star star star'
      } else if (input === 'B') {
        return 'star star star star star_border'
      } else if (input === 'C') {
        return 'star star star star_border star_border'
      } else if (input === 'D') {
        return 'star star star_border star_border star_border'

      } else if (input === 'E') {
        return 'star star_border star_border star_border star_border'
      } else {
        return ''
      }
    }
  })
  .filter('asRiskRating', () => {
    return (input) => valueOrDefault(new Map([
      ['A', '5'],
      ['B', '4'],
      ['C', '3'],
      ['D', '2'],
      ['E', '1']
      ]).get(input), '?')
  })
  .filter('asLocaltime', () => {
    return (timestamp, format = 'LLL') => {
      return moment(timestamp, moment.ISO_8601).local().locale('en').format(format)
    }
  })
  .filter('asUTCTime', () => {
    return (timestamp, format = 'dd D MMM YYYY h:mm a') => {
      return moment(timestamp, moment.ISO_8601).locale('en').format(format)
    }
  })
  .filter('asMoment', () => {
    return (momentValue, format = 'LL', locale = 'en-au') => {
      return momentValue.locale(locale).format(format)
    }
  })
  .filter('asDiff', () => {
    return (timestamp, unitOfTime = 'days') => {
      return moment().startOf('day').diff(moment(timestamp, moment.ISO_8601), unitOfTime)
    }
  })
  .filter('asQuantity', () => (value, singular, plural) => {
    const numValue = new Big(value)
    if (BIG_ZERO.cmp(numValue) === 0 || BIG_ONE.cmp(numValue.abs()) !== 0) {
      return `${value} ${plural}`
    } else {
      return `${value} ${singular}`
    }
  })
  .filter('asNumber', ['$filter', ($filter) => {
    return (amount, decimals = 2, rounding = 'down') => {
      if (amount === null || amount === undefined || isNaN(amount) || (angular.isString(amount) && amount.trim() === '')) {
        return amount
      }
      return $filter('number')(baseNumericValue(amount, decimals, rounding), decimals)
    }
  }])
  .filter('asBoolean', () => (value, isTrue = 'Yes', isFalse = 'No', isUndefined = '- undefined -', isNull = '- unknown -') => {
    if (value === undefined) {
      return isUndefined
    }
    if (value === null) {
      return isNull
    }
    return value ? isTrue : isFalse
  })
  .filter('asCurrency', ['$filter', ($filter) => {
    return (amount, decimals = 2, rounding = 'down') => {
      if (amount === null || amount === undefined || isNaN(amount) || (angular.isString(amount) && amount.trim() === '')) {
        return amount
      }
      return $filter('currency')(baseNumericValue(amount, decimals, rounding), '$', decimals)
    }
  }])
  .filter('asPercentage', ['$filter', ($filter) => {
    return (input, decimals = 2) => {
      return `${$filter('number')(baseNumericValue(input, decimals), decimals)}%`
    }
  }])
  .filter('asUserFullName', () => {
    return (user) => {
      if (typeof user === 'undefined' || user === null) {
        return ''
      }
      let nameParts = []
      if (typeof user.firstName !== 'undefined' && user.firstName !== null) {
        nameParts.push(user.firstName)
      }
      if (typeof user.middleName !== 'undefined' && user.middleName !== null) {
        nameParts.push(user.middleName)
      }
      if (typeof user.lastName !== 'undefined' && user.lastName !== null) {
        nameParts.push(user.lastName)
      }
      return nameParts.join(' ')
    }
  })
  .filter('asPhoneNumber', () => {
    return (phoneNumber) => {
      if (typeof phoneNumber === 'undefined' || phoneNumber === null) {
        return ''
      }
      if (typeof phoneNumber === 'string') {
        let baseNumber = phoneNumber.split(' ').join('')
        if (baseNumber.length === 10 && baseNumber.startsWith('04')) {
          return [baseNumber.slice(0, 4), baseNumber.slice(4, 7), baseNumber.slice(7, 10)].join(' ')
        }
      }
      return phoneNumber
    }
  })
  .filter('asLeadingCaps', () => {
    return (s) => {
      if (typeof s === 'string') {
        let parts = s.split('-')
        return parts.map(part => part[0].toUpperCase() + part.substring(1)).join('-')
      }
    }
  })
  .filter('fromMarkdown', ['$sce', ($sce) => {
    return (markdown) => {
      if (typeof markdown === 'undefined' || markdown === null) {
        return ''
      }
      return typeof markdown === 'string' ? $sce.trustAsHtml(new showdown.Converter(markdownEditorConfig).makeHtml(markdown)) : markdown
    }
  }])
  .filter('asSuffix', () => (text, length = 3, prefix = '…') => text.trim().length === 0 ? '' : prefix + text.trim().slice(-1 * length))
  .filter('asMaskedNumeric', () => (text, length = 3, mask = 'x') => Array.from(text.trim()).reverse().map((c, i) => i < length || c < '0' || c > '9' ? c : mask).reverse().join(''))
  .filter('strReplace', () => (input, from, to) => {
    input = input || ''
    from = from || ''
    to = to || ''
    return input.replace(new RegExp(from, 'g'), to)
  })
  .filter('asSimpleLoanEnquiryStage', () => (stage) => loanEnquiryStageDisplay(stage))
  .filter('asLoanEnquiryStage', () => (enquiry) => {
    const stage = loanEnquiryStage(enquiry)
    return loanEnquiryStageDisplay(stage)
  })
  .filter('asSimpleLoanEnquiryStatus', () => (status) => loanEnquiryStatusDisplay(status))
  .filter('asLoanEnquiryStatus', () => (enquiry) => {
    const status = loanEnquiryStatus(enquiry)
    const updated = enquiry && enquiry.hasOwnProperty('createdAt') && enquiry.hasOwnProperty('updatedAt') && enquiry.createdAt !== enquiry.updatedAt
    && status && status.endsWith('in_progress') ? '*' : ''
    return loanEnquiryStatusDisplay(status, updated)
  })
  .filter('asLoanEnquiryPayloadAnswer', () => (enquiry, payloadField) => {
    let answer
    if (enquiry && enquiry.payload) {
      answer = enquiry.payload[payloadField]
      if (!answer) {
        const match = Array.isArray(enquiry.payload.enquiryDetails) && enquiry.payload.enquiryDetails.find(detail => detail.name === payloadField)
        answer = match && match.hasOwnProperty('answer') && match.answer
      }
    }
    return answer || '-UNKNOWN-'
  })
  .filter('asFormFieldType', ['$filter', ($filter) => (fieldValue, type) => {
    switch (type) {
      case 'currency':
        return $filter('currency')(baseNumericValue(fieldValue, 2, 'down'), '$', 2)
      case 'upload':
        return Array.isArray(fieldValue) && fieldValue.length > 0 ? 'PROVIDED' : '- not provided -'
      default:
        return fieldValue
    }
  }])
  .filter('asFileSize', () => (fileSize) => {
    const size = parseInt(fileSize, 10)
    if (size <= 0) {
      return '(empty)'
    }
    const scale = Math.floor(Math.log10(size))
    const nonBreakingSpace = '\xa0'
    const formatSize = (size, decimalPlaces, baseScale, units) => {
      return `${(size / Math.pow(10, baseScale)).toFixed(decimalPlaces)}${nonBreakingSpace}${units}`
    }
    switch (scale) {
      case 3:
        return formatSize(size, 1, 3, 'KB')
      case 4:
        // Fall through.
      case 5:
        return formatSize(size, 0, 3, 'KB')
      case 6:
        return formatSize(size, 1, 6, 'MB')
      case 7:
      // Fall through.
      case 8:
        return formatSize(size, 0, 6, 'MB')
      case 9:
        return formatSize(size, 1, 9, 'GB')
      case 10:
      // Fall through.
      case 11:
        return formatSize(size, 0, 9, 'GB')
      default:
        // VF big, or less then one kilobyte.
        const plural = size > 1 ? 's' : ''
        return `${size}${nonBreakingSpace}Byte${plural}`
    }

  })
  .filter('asABN', () => (abn) => {
    if (abn === undefined || abn === null) {
      return ''
    }
    const candidateABN = abn.toString()
    const baseABN = candidateABN.replace(/(\s|-)/g, '')
    const matchedABN = /^(\d\d)(\d{3})(\d{3})(\d{3})$/.exec(baseABN)
    return matchedABN ? matchedABN.slice(1).join(' ') : candidateABN
  })
  .filter('asTerm', () => {
    return (term, format = 'months') => {
      const formatMonths = (parts, value) => {
        if (value > 0) {
          if (parts.length > 1) {
            parts.push('and')
          }
          parts.push(value)
          parts.push(value > 1 ? 'months' : 'month')
        } else if (value === 0 && parts <= 1) {
          parts.push('0 months')
        }
      }
      const parts = []
      if (term < 0) {
        parts.push('minus')
      }
      const absTerm = Math.abs(term)
      switch (format.toLowerCase()) {
        case 'year':
        case 'years':
          const years = Math.floor(absTerm / 12)
          const months = absTerm % 12
          if (years >= 1 || (years === 0 && months === 0)) {
            parts.push(years.toFixed(0))
            parts.push(years === 0 || years > 1 ? 'years' : 'year')
          }
          formatMonths(parts, months)
          break
        default:
          formatMonths(parts, absTerm)
      }
      return parts.join(' ')
    }
  })
  .filter('asBusinessEntity', () => {
    return (businessEntityAbbreviation) => {
      const key = businessEntityAbbreviation ? businessEntityAbbreviation.toString().toUpperCase().trim() : ''
      const translatedValue = businessEntityMapping[key]
      const displayValue = isDefined(translatedValue) ? translatedValue.label : '-Unknown entity type-'
      return `${displayValue} (${businessEntityAbbreviation})`
    }
  })
  .filter('asLoanPurpose', () => {
    const loanPurposeMapping = {
      'ACQUISITION': 'Business acquisition',
      'ASSET': 'Asset finance',
      'LOC': 'Line of credit',
      'BUSINESS': 'Cashflow',
      'CASHFLOW': 'Cashflow',
      'TRAILBOOK': 'Trail book',
      'TOOLSFINANCE': 'Tools Finance',
    }
    return (loanPurposeValue) => {
      const key = loanPurposeValue ? loanPurposeValue.toString().toUpperCase().trim() : ''
      const translatedValue = loanPurposeMapping[key]
      return translatedValue ? translatedValue : loanPurposeValue
    }
  })
  .filter('asPropertyOwner', () => {
    const propertyOwnershipTranslation = {
      'OWNSPROPERTY': 'Property offered',
      'NOPROPERTY': 'No property offered',
      'UNKNOWN': 'Property unknown',
      'notSpecified': '- not specified -'
    }
    return (propertyOwnership) => {
      const key = propertyOwnership ? propertyOwnership.toString().trim().toUpperCase() : 'notSpecified'
      const translatedValue = propertyOwnershipTranslation[key]
      return translatedValue ? translatedValue : propertyOwnership
    }
  })
  .filter('orNull', () => {
    return (value, rep) => value ? value : rep
  })
  .filter('asAssetSource', () => {
    return (assetSource) => {
      switch (assetSource) {
        case 'private':
          return 'Private sale'
        case 'dealer':
          return 'Dealer'
        case 'reece':
          return 'Reece'
        default:
          return `- unknown : '${assetSource}' -`
      }
    }
  })
  .filter('asAssetRideShareElectricVehicle', () => {
    return (assetRideShareElectricVehicle) => {
      switch (assetRideShareElectricVehicle) {
        case 'yes':
          return 'Yes : ride-share electric vehicle'
        case 'no':
          return 'No : other'
        default:
          return `- unknown : '${assetRideShareElectricVehicle}' -`
      }
    }
  })
  .filter('asTrailBookTarget', () => {
    return (trailBookTarget) => {
      switch (trailBookTarget) {
        case 'existing':
          return 'Existing trail book'
        case 'acquisition':
          return 'Acquiring trail book'
        default:
          return `- unknown : '${trailBookTarget}' -`
      }
    }
  })
  .filter('asLoanFeeCategory', () => {
    return (input) => valueOrDefault(new Map([
      ['1001', 'Drawdown'],
      ['1002', 'Establishment'],
      ['1003', 'Valuation'],
      ['1004', 'Brokerage'],
      ['1000', 'Other'],
      ['2001', 'Late'],
      ['2002', 'Dishonour'],
      ['2003', 'Early repayment'],
      ['2004', 'Past due notice'],
      ['2005', 'Mortgage/Caveat'],
      ['2000', 'Other']
    ]).get(input), '?')
  })
