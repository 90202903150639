class InvestorApplicationsController {
  /*@ngInject*/
  constructor($scope, localStorageService, investorApplicationsRepository) {
    this.name = 'investor-applications'
    this.investorApplicationsRepository = investorApplicationsRepository
    this.applicationStatus = 'submitted'
    this.entityType = 'all'
    this.investorStatus = 'all'
    this.order = '-UpdatedAt'
    this.limitOptions = [5, 10, 20, 50, 100]
    this.total = 0

    localStorageService.bind($scope, 'vm.limit', 50, 'admin-investor-applications.limit')

    this.applicationStatuses = [
      {key: 'all', label: 'All'},
      {key: 'pending', label: 'Pending'},
      {key: 'submitted', label: 'Submitted'},
      {key: 'accepted', label: 'Qualified'}
    ]
    this.entityTypes = [
      {key: 'all', label: 'All'},
      {key: 'individual', label: 'Individual'},
      {key: 'company', label: 'Company'},
      {key: 'trust', label: 'Trust'}
    ]
    this.investorStatuses = [
      {key: 'all', label: 'All'},
      {key: 'pending', label: 'Pending'},
      {key: 'accepted', label: 'Accepted'},
      {key: 'verified', label: 'Verified'},
      {key: 'rejected', label: 'Rejected'}
    ]

    this.reload = () => {
      this.loading = true
      const conditions = {
        search: this.search,
        status: this.applicationStatus,
        entityType: this.entityType,
        investorStatus: this.investorStatus,
        order: this.order,
        size: this.limit,
        page: this.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.investorApplicationsRepository.where(conditions)
        .then(data => {
          this.investorApplications = data.investorApplications
          this.total = data.total
        })
        .finally(() => this.loading = false)
    }
    this.$onInit = () => {
      this.performSearch()
    }
  }

  performSearch() {
    this.page = 1
    this.reload()
  }
}

export default InvestorApplicationsController
