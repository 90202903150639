import template from './iconPanel.pug'
import controller from './iconPanel.controller'
import './iconPanel.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: true,
    bindToController: {
      panel: '=?',
      panelId: '@'
    }
  }
}
