class TenantInlineController {
  /*@ngInject*/
  constructor(tenantInformationService) {
    this.name = 'tenantAnchor'

    this.$onInit = () => {
      tenantInformationService.getElement(this.elementId)
        .then((element) => {
          this.elementValue = element
        })
    }
  }

  get value() {
    return this.elementValue
  }
}

export default TenantInlineController
