import moment from 'moment/moment'
import reportRequestParameters from './reportRequestParameters'
import isDefined from '../../utils/isDefined'

const _reportTypes = new Map()

const reportTypes = {
  get: (reportType) => {
    const reportDefinition = _reportTypes.get(Symbol.for(reportType))
    if (!isDefined(reportDefinition)) {
      throw Error(`Unable to get report definition for type '${reportType}'.`)
    }
    return reportDefinition
  },
  get size() {
    return _reportTypes.size
  }
}

const _addReportEntry = (name, queryDefinition) => {
  const _key = Symbol.for(name)
  _reportTypes.set(_key, {
    get id() {
      return _key
    },
    get name() {
      return name
    },
    apiQuery: (model) => {
      const parameters = queryDefinition.parameters.reduce((pairs, parameter) => {
        if (!isDefined(model) || !model.hasOwnProperty(parameter.name)) {
          if (parameter.required) {
            throw Error(`Report parameter '${parameter.name}' is required. model: ${JSON.stringify(pairs)}`)
          }
        } else {
          if (parameter.required && !isDefined(model[parameter.name])) {
            throw Error(`Report parameter '${parameter.name}' is required, and must have a value. model: ${JSON.stringify(pairs)}`)
          }
          pairs[parameter.name] = parameter.format(model[parameter.name])
        }
        return pairs
      }, {})
      return reportRequestParameters({type: queryDefinition.apiKey, parameters})
    }
  })
}

const _makeQueryDefinition = (apiKey) => {
  const parameters = []
  const maker = {
    parameter: (name, required, format = (value) => value) => {
      parameters.push({name, required, format})
      return maker
    },
    build: () => {
      return {apiKey, parameters}
    }
  }
  return maker
}

const _asYYYYMMDD = (value) => moment(value).locale('en').format('YYYY-MM-DD')


/* ****** Define the report, and its parameters here by adding a report entry. */

_addReportEntry(
  'loanStatement',
  _makeQueryDefinition('loan-statement')
    .parameter('loanId', true)
    .parameter('date', false, _asYYYYMMDD)
    .build()
)

export default reportTypes
