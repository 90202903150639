import helpPanelTemplate from './helpPanelTemplate.pug'
import InvestorTotals from './../../../services/investor/investorTotals.model'

class MarketplaceHomeController {
  /*@ngInject*/
  constructor(userService, entityContextService, loanRequestNavigatorService, contentful, helpPanelsService, $mdDialog, $state, currencyFilter) {
    this.name = 'marketplace'
    this.userService = userService
    this.entityContextService = entityContextService
    this.loanRequestNavigatorService = loanRequestNavigatorService
    this.helpPanelsService = helpPanelsService
    this.$mdDialog = $mdDialog
    this.$state = $state
    this.currencyFilter = currencyFilter

    this.status = 'loading'

    this.$onInit = () => {
       this.loanRequestNavigatorService.loanRequestNavigator()
        .then((loanRequestNavigator) => {
          this.loanRequestNavigator = loanRequestNavigator
          if (this.loanRequestNavigator.hasLoanRequests() && this.loanRequestNavigator.hasAuctions()) {
            if (this.userService.isAdmin()) {
              this.activeRequests = this.loanRequestNavigator.loanRequestsWithActiveAuction()
              this.listedRequests = this.loanRequestNavigator.loanRequestsWithListedLoan()
              this.completedRequests = this.loanRequestNavigator.loanRequestsWithCompletedAuctionAndUnlistedLoans()
            } else {
              this.activeRequests = this.loanRequestNavigator.loanRequestsWithInProgressAuction()
              this.listedRequests = this.loanRequestNavigator.loanRequestsWithListedActiveLoan()
              this.completedRequests = this.loanRequestNavigator.loanRequestsWithUnlistedActiveLoans()
            }
            this.status = 'loaded'
          } else {
            this.status = 'noneFound'
          }
        })
      .catch((e) => {
        this.status = 'unableToLoad'
      })

      this.entityContextService.currentInvestor().then(investor => {
        this.investor = investor
        this.investorTotals = new InvestorTotals(investor)
        this.investor.promise('bids').then(bids => this.bids = bids)
      }).catch(angular.noop)

      contentful.entries(`content_type=page&fields.name=${this.name}&include=10`).then((entry) => {
        this.page = entry.data && entry.data.items.length && entry.data.items[0].fields
      })
    }
  }

  stopper(event) {
    event.stopPropagation()
    return false
  }

  loanPurpose(loanRequest) {
    return this.isPortfolioLoan(loanRequest) ? 'Portfolio purchase' : loanRequest.purpose
  }

  imageStyle(image, position) {
    const overlayColour = 'rgba(60, 61, 64, 0.6)'
    const backgroundImage = `linear-gradient(${overlayColour}, ${overlayColour}), url('${image}')`
    return {
      'background-image': backgroundImage,
      'background-position': position
    }
  }

  isPortfolioLoan(loanRequest) {
    return loanRequest.origination !== 'truepillars'
  }

  typeOf(loanRequest) {
    return this.loanRequestNavigator.loanRequestType(loanRequest)
  }

  badge(loanRequest) {
    if (loanRequest && this.investor) {
      if (this._myListing(loanRequest)) return 'assignment'
      if (this._myPart(loanRequest)) return 'account_balance'
      if (this._myBid(loanRequest)) return 'gavel'
    }
    return null
  }

  tooltip(loanRequest) {
    if (loanRequest && this.investor) {
      if (this._myListing(loanRequest)) return 'Active listings: ' + this.currencyFilter(this._totalListings(loanRequest), '$', 0)
      if (this._myPart(loanRequest)) return 'Current holdings: ' + this.currencyFilter(this._totalParts(loanRequest), '$', 0)
      if (this._myBid(loanRequest)) return 'Active bids: ' + this.currencyFilter(this._totalBids(loanRequest), '$', 0)
    }
    return null
  }

  navigateToCorrectTab(loanRequest) {
    if (this.typeOf(loanRequest) === 'listed' && this.userService.isLoggedIn()) {
      this.$state.transitionTo('loanRequestDetails.loanHistory', {id: loanRequest.id})
    } else {
      this.$state.transitionTo('loanRequestDetails', {id: loanRequest.id})
    }
  }

  contentHelp(event, identifier) {
    const helpContent = this.helpPanelsService.getByIdentifier(identifier)
    this.$mdDialog.show({
      template: helpPanelTemplate({}),
      controller: DialogController,
      controllerAs: 'vm',
      bindToController: true,
      locals: {
        help: helpContent
      },
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true})
  }

  auctionHelp(ev) {
    this.$mdDialog.show({template: auctionHelpTemplate({}), controller: DialogController, parent: angular.element(document.body), targetEvent: ev, clickOutsideToClose: true})
  }

  listingHelp(ev) {
    this.$mdDialog.show({template: listingHelpTemplate({}), controller: DialogController, parent: angular.element(document.body), targetEvent: ev, clickOutsideToClose: true})
  }

  loanHelp(ev) {
    this.$mdDialog.show({template: loanHelpTemplate({}), controller: DialogController, parent: angular.element(document.body), targetEvent: ev, clickOutsideToClose: true})
  }

  toggleExpanded() {
    this._expanded = !this._expanded
  }

  get expanded() {
    return this._expanded
  }

  shouldShowToggle() {
    if (this.status === 'loading') {
      return true
    }
    return (this.activeRequests && this.activeRequests.length > 0) || (this.listedRequests && this.listedRequests.length > 0)
  }

  isLoggedIn() {
    return this.userService.isLoggedIn()
  }

  // private

  _myBid(loanRequest) {
    return loanRequest.auctionId && !loanRequest.loanId && this.bids && this.bids.some(bid => bid.activeAmount > 0 && bid.auctionId === loanRequest.auctionId)
  }

  _myPart(loanRequest) {
    return loanRequest.loanId && this.investor.loanParts && this.investor.loanParts.some(loanPart => loanPart.loanId === loanRequest.loanId)
  }

  _myListing(loanRequest) {
    return loanRequest.loanId && this.loanRequestNavigator.hasListingsByLoanIdAndInvestorId(loanRequest.loanId, this.investor.id)
  }

  _totalBids(loanRequest) {
    return this.investorTotals && this.investorTotals.totalBids(loanRequest)
  }

  _totalParts(loanRequest) {
    return this.investorTotals && this.investorTotals.totalParts(loanRequest)
  }

  _totalListings(loanRequest) {
    return this.investorTotals && this.investorTotals.totalListings(loanRequest)
  }
}

class DialogController {
  /*@ngInject*/
  constructor($scope, $mdDialog) {
    $scope.cancel = function() {
      $mdDialog.hide()
    }
  }
}

export default MarketplaceHomeController
