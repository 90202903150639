class LoanRequestDetailsController {
  /*@ngInject*/
  constructor($state, $stateParams, $scope, router, loanRequestsRepository) {
    this.name = 'loan-request-details'
    this.$state = $state
    this.router = router
    this.tabs = [
      {label: 'Loan Request', state: 'adminLoanRequest.edit'},
      {label: 'Identity checks', state: 'adminLoanRequest.identity'},
      {label: 'Credit Reports', state: 'adminLoanRequest.reports'},
      {label: 'Business Profile', state: 'adminLoanRequest.profile'},
      {label: 'Actions', state: 'adminLoanRequest.auction'},
    ]
    this.id = $stateParams.id
    this.loanRequestsRepository = loanRequestsRepository

    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.name.indexOf('adminLoanRequest') === 0) {
        let indexOfState = this.tabs.findIndex(tab => tab.state === toState.name)
        if(indexOfState >= 0) {
          this.selectedIndex = indexOfState
        }
      }
    })
    this.$onInit = () => {
      this.loanRequestsRepository.getById(this.id).then((loanRequest) => {
        this.loanRequest = loanRequest
      })
    }
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }
}

export default LoanRequestDetailsController
