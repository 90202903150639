import angular from 'angular'

export default angular.module('app.services.environment', [])
  .provider('environment', () => {
    return {
      env: Environment,
      $get() {
        return Environment
      }
    }
  })
