/*
  This implementation relies on the target element having a calculated width. The easiest way
  to make sure this happens is for the target element to have a style of 'display: inline-block'

  The implementation listens for resize events, however, it will only make the target smaller and
  will NOT reset to the original size if the parent happens to get bigger. Given that this code is to
  deal with mobile rendering, this is a reasonable compromise, but doesn't take into account any
  rotation of the device, per se.
 */

export default ['$window', '$timeout', function($window, $timeout) {
  return {
    restrict: 'A',
    link: function link(scope, element) {

      const targetNode = element[0]
      const parentNode = targetNode.parentNode

      const targetComputedStyle = $window.getComputedStyle(targetNode, null)
      const parentComputedStyle = $window.getComputedStyle(parentNode, null)

      const computedWidth = (nodeStyle) => Number.parseFloat(nodeStyle.getPropertyValue('width'))

      const doResize = () => $timeout(() => {
        targetNode.style.transform = 'scale(1)'

        $timeout(() => {
          const targetWidth = computedWidth(targetComputedStyle)
          const parentWidth = computedWidth(parentComputedStyle)
          if (targetWidth > parentWidth) {
            targetNode.style.transform = `scale(${(parentWidth/targetWidth).toFixed(2)})`
          }
        }, 0)
      }, 0)

      $window.addEventListener('resize', (event) => doResize())
      doResize()
    }
  }
}]
