import Big from 'big.js'
import InvestorStatusHelper from '../../../../utils/investorStatusHelper'

class ListingsPaneController {
  /*@ngInject*/
  constructor($state, $mdDialog, userService, entityContextService, emailVerificationService, listingsRepository,
              listingPurchaseByCriteriaRepository, featureTogglesService, applicationSettingsService) {
    this.name = 'listings-pane'
    this.$state = $state
    this.$mdDialog = $mdDialog
    this.userService = userService
    this.entityContextService = entityContextService
    this.emailVerificationService = emailVerificationService
    this.listingsRepository = listingsRepository
    this.listingPurchaseByCriteriaRepository = listingPurchaseByCriteriaRepository
    this.featureTogglesService = featureTogglesService
    this.applicationSettingsService = applicationSettingsService

    this.$onInit = () => {
      this.entityContextService.currentInvestor()
        .then((investor) => this.investor = investor)
        .catch(angular.noop) /* Do nothing - prolly not logged in as an INVESTOR. */
        .finally(() => this.goToSelectionView())
    }
  }

  get minRate() {
    return this.listings && this.listings.length > 0 && this.listings
      .map(listing => new Big(listing.rate).minus(listing.marginRate))
      .reduce((memo, investorRate) => memo.gt(investorRate) ? investorRate : memo, new Big(this.listings[0].rate).minus(this.listings[0].marginRate))
      .toString()
  }

  get maxRate() {
    return this.listings && this.listings.length > 0 && this.listings
      .map(listing => new Big(listing.rate).minus(listing.marginRate))
      .reduce((memo, investorRate) => memo.lt(investorRate) ? investorRate : memo, new Big(this.listings[0].rate).minus(this.listings[0].marginRate))
      .toString()
  }

  get selectedListingId() {
    return this._selectedListingId
  }

  set selectedListingId(selectedListingId) {
    this._selectedListingId = selectedListingId
    this.selectedListing = this._selectedListing(selectedListingId)
  }

  selectListing(listing) {
    if (this.investor && !listing.owner) {
      this.selectedListingId = listing.id
    }
  }

  goToSelectionView() {
    delete this.purchaseStatus
    delete this.purchasedListing
    delete this.purchaseErrorCode
    delete this.confirmationListing
    delete this.isAcknowledged

    this._loadListings().then(() => {
      this.selectedListingId = 0
      this.currentView = 'selection'
    })
  }

  goToNextView() {
    if (!this.featureTogglesService.can('disableListingPurchaseAcknowledgement')) {
      this.goToAcknowledgementView()
    } else {
      this.goToConfirmationView()
    }
  }

  goToAcknowledgementView() {
    this.currentView = 'acknowledgement'
  }

  goToConfirmationView() {
    this.confirmationListing = {
      amount: this.selectedListing.amount,
      rate: this.selectedListing.rate,
      marginRate: this.selectedListing.marginRate
    }

    this.currentView = 'confirmation'
  }

  goToNotificationView() {
    delete this.confirmationListing
    this.currentView = 'notification'
  }

  canSelectListing() {
    return this.investor && this.selectedListing && this.selectStatusMessage === 'Select listing'
  }

  get selectStatusMessage() {
    if (this.currentView !== 'selection') return ' '
    let amount = this.selectedListing ? this.selectedListing.amount : 0
    return new InvestorStatusHelper(this.userService, this.entityContextService).selectStatusMessage(amount)
  }

  resendVerificationEmailDialog($event) {
    const confirm = this.$mdDialog.confirm()
      .title('You must verify your email address to be able to invest')
      .htmlContent(
        '<p>When you registered as an investor, an email verification request was sent to: <b>' + this.userService.currentUser.email + '</b></p>' +
        '<p>If you would like us to send you another verification email, please click below.</p>'
      )
      .clickOutsideToClose(true)
      .targetEvent($event)
      .ok('Re-send email')
      .cancel('Cancel')

    this.$mdDialog.show(confirm).then(() => {
      this.emailVerificationService.resendVerificationEmail()
    })
  }

  purchaseSelectedListing() {
    this.purchaseStatus = 'in-progress'
    const selected = this.selectedListing
    return this.listingPurchaseByCriteriaRepository.create({investorId: this.investor.id, loanId: this.loanRequest.loanId, rate: selected.rate, amount: selected.amount})
      .then((listing) => {
        this.purchasedListing = listing
        this.purchaseStatus = 'successful'
        return listing
      }, (response) => {
        this.purchaseStatus = 'failed'
        if (response.status === 404) {
          this.purchaseErrorCode = 'NoMatchFound'
        } else if (response.data.errors && response.data.errors.length > 0) {
          this.purchaseErrorCode = response.data.errors[0].code
        }
      }).finally(() => {
        this.selectedListingId = 0
        this.entityContextService.currentInvestor()
          .then((investor) => this.investor = investor)
          .catch(angular.noop) /* Do nothing - prolly not logged in as an INVESTOR. */
          .finally(() => this.goToNotificationView())
      })
  }

  get purchaseInProgress() {
    return this.purchaseStatus === 'in-progress'
  }

  get purchaseSuccessful() {
    return this.purchaseStatus === 'successful'
  }

  get purchaseFailed() {
    return this.purchaseStatus === 'failed'
  }

  _loadListings() {
    this.currentView = 'loading'
    this.listings = []

    if (this.applicationSettingsService.setting('enableP2p')) {
      return this.listingsRepository.where({'scope': 'loan', 'id': this.loanRequest.loanId, 'state': 'active'}).then((data) => {
        data ||= {listings: []}

        this.listings = data.listings.reduce((results, listing) => {
          const owner = this.investor && listing.investorId === this.investor.id
          const rate = listing.rate
          const amount = new Big(listing.amount).round(2, 3).toString()
          const match = results.find((e) => e.owner === owner && e.rate === rate && e.amount === amount)
          if (match) {
            match.count = match.count + 1
          } else {
            results.push({id: results.length + 1, owner, rate: rate, marginRate: listing.marginRate, amount: amount, count: 1})
          }
          return results
        }, [])
      })
    } else {
      return Promise.resolve([])
    }
  }

  _selectedListing(listingId) {
    if (this.listings) {
      for (let i = 0; i < this.listings.length; i++) {
        if (this.listings[i].id === listingId) {
          return this.listings[i]
        }
      }
    }
  }
}

export default ListingsPaneController
