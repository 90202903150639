const sortComparator = (...comparators) => {
  return (a, b) => {
    let comparison = 0
    return comparators.findIndex(comparator => {
      comparison = comparator(a, b)
      return comparison !== 0
    }) === -1 ? 0 : comparison
  }
}

export default sortComparator
