import LoanRequestNavigator from './loanRequestNavigator.model'

class LoanRequestNavigatorService {
  /*@ngInject*/
  constructor($q, dateService, loanRequestsRepository, auctionsRepository, listingsRepository, applicationSettingsService) {
    this.$q = $q
    this.dateService = dateService
    this.loanRequestsRepository = loanRequestsRepository
    this.auctionsRepository = auctionsRepository
    this.listingsRepository = listingsRepository
    this.applicationSettingsService = applicationSettingsService
  }

  loanRequestNavigator() {
    const defer = this.$q.defer()

    if (this.applicationSettingsService.hasSetting('enableP2p')) {
      this._loanRequestNavigator(this.applicationSettingsService.setting('enableP2p'), defer)
    } else {
      this.applicationSettingsService.addCallBack('enableP2p', () => {
        this._loanRequestNavigator(this.applicationSettingsService.setting('enableP2p'), defer)
      })
    }

    return defer.promise
  }

  // private methods

  _loanRequestNavigator(enableP2p, defer) {
    this.$q.all([
      this.loanRequestsRepository.where({scope: 'active', size: 500}),
      enableP2p && this.auctionsRepository.where({state: 'all'}),
      enableP2p && this.listingsRepository.where({scope: 'all', state: 'active'})
    ])
    .then(([loanRequestsData, auctionsData, listingsData]) => {
      auctionsData ||= {auctions: []}
      listingsData ||= {listings: []}
      defer.resolve(LoanRequestNavigator.create(this.dateService, loanRequestsData.loanRequests, auctionsData.auctions, listingsData.listings))
    })
    .catch((e) => {
      defer.reject(e)
    })
  }
}

export default LoanRequestNavigatorService
