import FormTracker from '../../../common/patchForm/formTracker'

class InvestorApplicationController {
  /*@ngInject*/
  constructor(investorApplicationsRepository, investorApplicationRouter, userService, $stateParams, userIdentityService, $q, $scope) {
    this.name = 'investor-application'
    this.id = $stateParams.id
    this.investorApplicationRouter = investorApplicationRouter
    this.user = userService.currentUser

    this.$onInit = () => {
      this.registrationStep = this.investorApplicationRouter.registrationStep
      this.consentStep = this.investorApplicationRouter.consentStep
      this.applicationForms = this.investorApplicationRouter.applicationSteps

      this.userIdentityPromise = userIdentityService.getCurrentIdentity().then((userIdentity) => {
        return this.userIdentity = userIdentity
      })

      investorApplicationsRepository.getById(this.id).then((investorApplication) => {
        this.investorApplication = investorApplication
        this.formTracker = new FormTracker(investorApplication, investorApplication.application)
        for (let form of this.applicationForms) {
          this.formTracker.setFormValid(form.validationProperty, false)
        }

        this.investorApplicationRouter.goToDefaultRoute()
        this._setRegistrationCompletionHandler($q)
      })
    }

    $scope.$on('$stateChangeSuccess', (event, toState) => {
      this.investorApplicationRouter.currentRoute = toState.name
    })
  }

  validForSubmit() {
    return this.formTracker && this.formTracker.valid() && this.identityCheckAttempted() && this._isApplicant()
  }

  isRegistered() {
    return this.userIdentity && this.userIdentity.identityCheckProgressed()
  }

  identityCheckAttempted() {
    return this.userIdentity && this.userIdentity.identityCheckAttempted()
  }

  isFormComplete(form) {
    if(form && this.formTracker) {
      return this.formTracker.isFormValid(form.validationProperty)
    } else {
      return false
    }
  }

  formVisited(form) {
    if(form && this.formTracker) {
      return this.formTracker.formVisited(form.validationProperty)
    } else {
      return false
    }
  }

  submit() {
    this.investorApplication.statusEvent = 'submit'
    this.submitting = true
    return this.investorApplication.save()
      .then(() => {
        this.investorApplicationRouter.applicationFinished()
      }).finally(() => {
        this.submitting = false
      })
  }

  _isApplicant() {
    return this.user && this.investorApplication && this.investorApplication.applicantSummary.id === this.user.id
  }

  _setRegistrationCompletionHandler($q) {
    this.registrationCompletionHandler = $q.defer()
    this.registrationCompletionHandler.promise
      .then(() => this.investorApplicationRouter.goToFirstApplicationForm())
      // promise can be resolved only once, so to perform registration completion handler multiple times (when navigation back and forth between tabs) promise has to be reinitialized
      .finally(() => this._setRegistrationCompletionHandler($q))
  }
}

export default InvestorApplicationController
