import electronicBankStatementsDialogTemplate from './electronicBankStatementsDialog/electronicBankStatementsDialog.pug'
import ElectronicBankStatementsDialogController from './electronicBankStatementsDialog/electronicBankStatementsDialog.controller'

import assert from '../../utils/assert'

class ElectronicBankStatementsController {
  /*@ngInject*/
  constructor($mdDialog) {
    this.name = 'electronicBankStatements'
    this.$mdDialog = $mdDialog
    this.$onInit = () => {
      assert(this.widget && this.widget.preamble && typeof this.widget.preamble === 'string' && this.widget.preamble.trim().length > 0,
        'You must provide a preamble for the electronic bank statements.')
    }
  }

  get hasModel() {
    return this.model && this.model.clientEmail && typeof this.model.clientEmail === 'string' && this.model.clientEmail.trim().length > 0
  }

  get preamble() {
    return this.widget.preamble
  }

  get buttonLabel() {
    return this.hasModel ? 'Resend bank statements request…' : 'Send bank statements request…'
  }

  manageElectronicBankStatements($event) {
    return this.$mdDialog.show({
      targetEvent: $event,
      template: electronicBankStatementsDialogTemplate({}),
      controllerAs: 'vmd',
      locals: {
        widget: this.widget,
        existingData: this.model
      },
      controller: ElectronicBankStatementsDialogController
    }).then((response) => {
      this.model = response
      this.form[this.widget.modelFieldName].$setDirty()
      return response
    })
  }
}


export default ElectronicBankStatementsController
