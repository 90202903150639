import Big from 'big.js'

const LISTING_FEE_FACTOR = new Big('5').div('1000')
const LISTING_START_MAX = new Big(5000)

class AddLoanPartListingController {

  /*@ngInject*/
  constructor(loanPartListingsService, featureTogglesService, $scope, $filter) {
    this.name = 'add-loan-part-listing'
    this.featureTogglesService = featureTogglesService

    let listingState = 'closed'

    const calculateListingUnitsMax = () => this.compositeLoanPart.loanPart.units - this.compositeLoanPart.listingsContext.offeredLoanPartUnits

    const calculateListingUnitsStart = (max) => {
      return Math.min(Math.floor(LISTING_START_MAX.div(this.loanPartPrice) / 50) * 50, max)
    }

    $scope.$watch(() => {
      return this.compositeLoanPart.listingsContext.offeredLoanPartUnits
    }, () => {
      this.units.max = calculateListingUnitsMax()
      this.units.listing = Math.min(this.units.listing, this.units.max)
    })

    this.processingResult = (() => {
      const instance = {
        processing: false,
        heading: '',
        presentation: '',
        errors: [],
        content: ''
      }
      instance.processingSuccessful = (amount) => {
        instance.heading = 'Listing now live'
        instance.presentation = ''
        instance.errors = []
        instance.content = `Your have listed capital of ${$filter('asCurrency', 2)(amount)}. You will be notified if your listing finds an investor.`
      }
      instance.processingFailed = (amount, errorMessage, errors) => {
        instance.heading = 'Listing failed'
        instance.presentation = 'error'
        const handledErrors = new Map([
          ['CannotListMoreThanOutstanding', 'You cannot list more than the cumulative outstanding balance of your loan part.'],
          ['CannotListNon50Units', 'Your listing must be in multiples of 50 loan units.'],
          ['CannotListNonActiveLoanPart', 'You cannot list an defaulted/irregular loan part'],
          ['CannotListOverdueLoanPart', 'A repayment is now due on this loan. You will be able to list your investment once the repayment from the borrower has cleared.'],
          ['CannotListExpiredLoanPart', 'You cannot list an expired loan part.'],
          ['CannotListLateLastRepaymentLoanPart', 'You cannot list a loan with an overdue repayment.'],
          ['CannotListNoRepaymentsLoanPart', 'You cannot list a loan before it has received a repayment.']
        ])
        instance.errors = errors ? errors.map(error => {
          if (handledErrors.has(error.code)) {
            return handledErrors.get(error.code)
          } else {
            return `An unknown error, '${error.code}', has occurred.`
          }
        }) : [`Request error: '${errorMessage}'.`]
        instance.content = `Your listing request of ${$filter('asCurrency', 2)(amount)} has NOT been processed.`
      }
      return instance
    })()

    this.units = {
      min: 50,
      max: 0,
      step: 50,
      listing: 0,
    }

    this.listingState = () => listingState

    this.closePanel = () => {
      listingState = 'closed'
    }

    this.enterLoanPartListing = () => {
      listingState = 'enter'
    }

    this.confirmLoanPartListing = () => {
      listingState = 'confirm'
    }

    this.placeLoanPartListing = () => {
      this.processingResult.inProgress = true
      this.processing = loanPartListingsService.addListing(this.compositeLoanPart.loanPart.id, this.listingAmount).then(result => {
        this.processingResult.processingSuccessful(this.listingAmount)
        loanPartListingsService.loadActiveListings(this.compositeLoanPart)
      }, errorResult => {
        this.processingResult.processingFailed(this.listingAmount, errorResult.data.message, errorResult.data.errors)
      }).finally(() => {
        listingState = 'inform'
        this.processingResult.inProgress = false
      })
    }

    this.$onInit = () => {
      loanPartListingsService.loadActiveListings(this.compositeLoanPart).then(() => {
        this.units.max = calculateListingUnitsMax()
        this.units.listing = calculateListingUnitsStart(this.units.max)
      })
    }
  }

  get requestInProgress() {
    return this.processingResult.inProgress
  }

  get listingAmount() {
    if (this.units.max <= 0 || !this.compositeLoanPart.loanPart) {
      return new Big('0.0')
    } else if (this.units.listing === this.units.max) {
      return new Big(this.compositeLoanPart.loanPart.capitalOutstanding).minus(this.compositeLoanPart.listingsContext.offeredLoanPartAmount)
    } else {
      return new Big(this.compositeLoanPart.loanPart.capitalOutstanding).div(this.compositeLoanPart.loanPart.units).times(this.units.listing).round(4, 0)
    }
  }

  get loanExchangeFee() {
    return this.units.listing > 0 && this.listingAmount ? this.listingAmount.times(LISTING_FEE_FACTOR).round(2, 0) : new Big('0.0')
  }

  get loanPartAmountAvailable() {
    return this.compositeLoanPart.loanPart ? this.loanPartPrice.times(this.units.max) : new Big('0.0')
  }

  get loanPartPrice() {
    return new Big(this.compositeLoanPart.loanPart.capitalOutstanding).div(this.compositeLoanPart.loanPart.units)
  }

  get loanPartsAvailableForListing() {
    return this.units.max > 0
  }

  get allListingsDisabled() {
    return this.featureTogglesService.can('disableSecondaryMarketCreate') && !this.listingDisabled
  }

  get listingDisabled() {
    return this.compositeLoanPart.loanPartDisableSecondaryMarket === true
  }

  get listingAllowed() {
    return this.compositeLoanPart.loanPartStatus === 'active'
      && !this.compositeLoanPart.loanPartDisableSecondaryMarket
      && !this.featureTogglesService.can('disableSecondaryMarketCreate')
      && (!this.compositeLoanPart.loanPart.lastRepaymentSummary || this.compositeLoanPart.loanPart.lastRepaymentSummary.daysLate === 0)
      && (!this.compositeLoanPart.loanPart.hasBalloonPayment || this.compositeLoanPart.loanPart.paymentsRemaining > 3)
  }
}

export default AddLoanPartListingController
