import Big from 'big.js'

class CreateInvestmentProductPledgeRequestController {
  /*@ngInject*/
  constructor(investmentProductPledgeRequestsRepository) {
    this.name = 'create-investment-product-pledge-request'
    this.investmentProductPledgeRequestsRepository = investmentProductPledgeRequestsRepository
    this.status = 'pre-confirmation'
    this.currentScreen = 'loading'

    this.$onInit = () => {
      this.currentInvestment = this.investmentProductParts.reduce((sum, current) => sum.plus(current.currentValue), new Big('0.0'))
      this.minInvestmentAmount = this.currentInvestment.gt('0') ? this.investmentProduct.minimumAdditionalInvestmentAmount : this.investmentProduct.minimumInitialInvestmentAmount

      this.gotoEnterScreen()
      this.amount = this.minInvestmentAmount
      this.autoReinvest = true
    }
  }

  gotoEnterScreen() {
    this.currentScreen = 'enter'
  }

  gotoConfirmScreen() {
    this.currentScreen = 'confirm'
  }

  gotoResultScreen() {
    this.currentScreen = 'result'
  }

  confirmAction() {
    this.status = 'in-progress'
    const payload = {
      investorId: this.investor.id,
      investmentProductId: this.investmentProduct.id,
      amount: this.amount,
      autoReinvest: this.autoReinvest
    }
    return this.investmentProductPledgeRequestsRepository.create(payload)
      .then((investmentProductPledgeRequest) => {
        this.createdInvestmentProductPledgeRequest = investmentProductPledgeRequest
        this.resultCode = 'success'
        this.investor.reload()
      }).catch((response) => {
        this.resultCode = this._getErrorCode(response)
      }).finally(() => {
        this.status = 'complete'
        this.gotoResultScreen()
      })
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  completeAction() {
    this.createdInvestmentProductPledgeRequest ? this.completion.resolve(this.createdInvestmentProductPledgeRequest) : this.completion.reject('cancel')
    return false
  }

  _getErrorCode(response) {
    if (response && response.data && response.data.errors) {
      for (let check of [
        {code: 'InvestmentProductNotActive', result: 'error-investment-product-not-active'},
        {code: 'InvestmentAmount', result: 'error-amount-range'},
        {code: 'InsufficientFunds', result: 'error-insufficient-funds'},
      ]) {
        if (response.data.errors.find(error => error.code === check.code)) {
          return check.result
        }
      }
    }
    return 'error-unknown'
  }
}

export default CreateInvestmentProductPledgeRequestController
