import angular from 'angular'
import 'angular-ui-router'
import creatLoanFeeModule from './createLoanFee/createLoanFee'
import createLoanRepaymentModule from './createLoanRepayment/createLoanRepayment'
import finalLoanRepaymentModule from './finalLoanRepayment/finalLoanRepayment'
import rescheduleLoanModule from './rescheduleLoan/rescheduleLoan'
import creatLoanLossModule from './createLoanLoss/createLoanLoss'
import editDebitsScheduleModule from './editDebitsSchedule/editDebitsSchedule'
import createFinalDebitModule from './createFinalDebit/createFinalDebit'

import loanDetailsComponent from './loanDetails.component'

export default angular.module('app.pages.admin.loanDetails', [
    'ui.router',
    creatLoanFeeModule.name,
    createLoanRepaymentModule.name,
    finalLoanRepaymentModule.name,
    rescheduleLoanModule.name,
    creatLoanLossModule.name,
    editDebitsScheduleModule.name,
    createFinalDebitModule.name
  ])
  .config(['$stateProvider', $stateProvider=> {
    $stateProvider
      .state('adminLoanDetails', {
        url: '/admin/loans/:id?:scrollTo',
        template: '<admin-loan-details/>',
        data: {
          requireAuth: true,
          title: 'Loan details'
        },
        onEnter: ($location, $stateParams, $anchorScroll, $timeout) => {
          $timeout(() => {
            $location.hash($stateParams.scrollTo)
            $anchorScroll()
          }, 100)
        }
      })
  }])
  .directive('adminLoanDetails', loanDetailsComponent)
