import blobToFile from '../../utils/blobToFile'

const MAX_PHOTOS = 4

class PhotoBoothController {
  /*@ngInject*/
  constructor($element, $window, audioService, loggerService) {
    this.name = 'photoBooth'
    this.$element = $element

    this.logger = loggerService.makeLogger().enableNewRelic(true).enableConsole(false)

    this.imageDataUrls = []
    this.allPhotos = []
    this.blobCounter = 0

    this.$onInit = () => {
      this.video = this.$element.find('video.player')[0]
      this.canvas = this.$element.find('canvas.photo')[0]
      this.audioSnap = audioService.makeAudio('./sounds/snap.mp3')

      // See https://leemartin.dev/hello-webrtc-on-safari-11-e8bcb5335295
      this.video.setAttribute('autoplay', '')
      this.video.setAttribute('muted', '')
      this.video.setAttribute('playsinline', '')

      const constraints = {
        audio: false,
        video: {
          facingMode: 'user',
          width: {
            min: 1024,
            ideal: 1920,
            max: 1920
          },
          height: {
            min: 576,
            ideal: 1080,
            max: 1080
          }
        }
      }

      $window.navigator.mediaDevices.getUserMedia(constraints)
        .then((localMediaStream => {
          if ('srcObject' in this.video) {
            this.video.srcObject = localMediaStream
          } else {
            this.video.src = URL.createObjectURL(localMediaStream)
          }
          this.video.play()
        }))
        .catch(err => {
          const message = 'There appears to be a problem accessing the camera on your device.'
          this.logger.notification(message).error(err)
        })
    }
  }

  takePhoto() {
    // played the photograph shutter sound.
    this.audioSnap.currentTime = 0
    const playPromise = this.audioSnap.play()
    if (playPromise) {
      playPromise.catch((error) => {
        this.logger.error('Audio failed to play.', error)
      })
    }

    this.canvas.width = this.video.videoWidth
    this.canvas.height = this.video.videoHeight
    this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)

    const imageType = 'png'
    const mimeType = `image/${imageType}`

    // Create a Blob/File - for potential upload by the parent controller.
    const callback = (blob) => {
      this.blobCounter += 1
      this.allPhotos.push(blobToFile(blob, `biometricPhoto-${this.blobCounter}.${imageType}`))
    }
    this.canvas.toBlob(callback, mimeType)

    // Create a dataUrl out of the canvas, for display as the thumbnail.
    this.imageDataUrls.push(this.canvas.toDataURL(mimeType))
  }

  disableTakePhotoButton() {
    return this.imageDataUrls.length >= MAX_PHOTOS
  }

  get quantityOfPhotos() {
    if (this.imageDataUrls.length === 0) {
      return 'No'
    } else if (this.imageDataUrls.length >= MAX_PHOTOS) {
      return `Maximum number (${MAX_PHOTOS}) of`
    } else {
      return `${this.imageDataUrls.length} of up to ${MAX_PHOTOS}`
    }
  }

  deleteImage(photoIndex) {
    const deleteElement = (arr, index) => [...arr.slice(0, index), ...arr.slice(index +1)]
    this.imageDataUrls = deleteElement(this.imageDataUrls, photoIndex)
    this.allPhotos = deleteElement(this.allPhotos, photoIndex)
  }
}

export default PhotoBoothController
