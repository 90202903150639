export default ['$window', function($window) {
  return {
    restrict: 'A',
    scope: true,
    link: function(scope, elem, attr) {
      elem.on('click', function(event) {
        if ($window && $window.ga) {
          const gaClick = attr.gaClick
          if (gaClick && gaClick.trim().length > 0) {
            const [category, label] = gaClick.split('.')
            $window.ga('send', 'event', category, 'click', label, 0)
          }
        }
        return true
      })
    }
  }
}]
