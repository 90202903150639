const NO_RULE = -1

const formatRuleId = (candidateRuleId) => {
  if (candidateRuleId === undefined) {
    return 'undefined'
  } else if (candidateRuleId === null) {
    return null
  } else {
    return `'${candidateRuleId}'`
  }
}

const WITHDRAWAL_ORIGIN_SETTINGS = {
  withRule: {symbol: 'miscellaneous_services', classes: 'by-rule', title: (ruleId) => `rule id: ${ruleId}`},
  manual: {symbol: 'face', classes: 'manual', title: () => 'manual'},
  error: {symbol: 'error', classes: 'error', title: (ruleId) => `Unknown rule id: ${formatRuleId(ruleId)}`}
}

class WithdrawalRequestOriginController {
  /*@ngInject*/
  constructor() {
    this.name = 'withdrawalRequestOrigin'

    this.$onInit = () => {
      if (this.ruleId && Number.isInteger(this.ruleId)) {
        this.key = this.ruleId > NO_RULE ? 'withRule' : 'manual'
      } else {
        this.key = 'error'
      }
      this.description = this.hasRule ? `rule id: ${this.ruleId}` : `manual`
    }
  }

  get iconName() {
    return WITHDRAWAL_ORIGIN_SETTINGS[this.key].symbol
  }

  get iconStyle() {
    return WITHDRAWAL_ORIGIN_SETTINGS[this.key].classes
  }

  get originDescription() {
    return WITHDRAWAL_ORIGIN_SETTINGS[this.key].title(this.ruleId)
  }
}

export default WithdrawalRequestOriginController
