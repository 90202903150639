import ChartData from './chartData'
import resolveProperty from '../../../../utils/resolveProperty'
import valueOrDefault from '../../../../utils/valueOrDefault'

import Big from 'big.js'

class InvestorP2pController {
  /*@ngInject*/
  constructor($filter, router, entityContextService, emailVerificationService, dateService, auctionsRepository,
              applicationSettingsService) {
    this.router = router
    this.emailVerificationService = emailVerificationService
    this.applicationSettingsService = applicationSettingsService
    this.$filter = $filter

    this.pendingBids = 0
    this.liveBids = 0
    this.auctionsResolved = !this.p2p
    this.emailVerificationService.checkIfEmailVerified()

    this.$onInit = () => {
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor

        this.pending = 0
        this._drawChart()

        if (this.p2p) {
          this.investor.promise('bids')
            .then(bids => {
              bids ||= []
              if (bids.length > 0) {
                auctionsRepository.where({state: 'active'})
                  .then(auctionsData => {
                    bids.forEach((bid) => {
                      bid.setResource('auction', auctionsData.auctions.find(auction => auction.id === bid.auctionId))
                    })
                    this.pendingBids = bids.filter(bid => bid.auction && dateService.isBeforeNow(bid.auction.endDate)).reduce((memo, bid) => memo + bid.activeAmount, 0)
                    this.liveBids = bids.filter(bid => bid.auction && !dateService.isBeforeNow(bid.auction.endDate)).reduce((memo, bid) => memo + bid.activeAmount, 0)
                    this._drawChart()
                  })
                  .finally(() => {
                    this.auctionsResolved = true
                  })
              }
            })
            .finally(() => this.auctionsResolved = true)
        }
      })
    }
  }

  get invested() {
    return this._investorValueOrZero('p2pInvestedFunds')
  }

  get available() {
    return this._investorValueOrZero('availableFunds')
  }

  get interest() {
    return this._investorValueOrZero('investmentEarningSummary', 'p2p', 'interestReceived')
  }

  get total() {
    const total = new Big(0)
    return this.auctionsResolved ? total.add(this.liveBids).add(this.pendingBids).add(this.invested) : total
  }

  get p2pYield() {
    return this._investorValueOrZero('investmentYield', 'p2pYield')
  }

  get emailVerified() {
    return this.emailVerificationService.emailVerified()
  }

  get p2p() {
    return this.applicationSettingsService.setting('enableP2p')
  }

  resendVerificationEmail() {
    this.emailVerificationService.resendVerificationEmail()
  }

  // private

  _investorValueOrZero(...investorFields) {
    return new Big(valueOrDefault(resolveProperty(this.investor, ...investorFields), 0))
  }

  _drawChart() {
    this.data = new ChartData([this.invested, this.pendingBids, this.liveBids, this.available]).normalised
    this.chartData = this.investor.moneyAdded ? {
      labels: ['review', 'invested', 'pending', 'live', 'available'],
      series: [
        {value: 0, name: 'duringInvestorReviewPeriodOnly'},
        {value: this.data[0]},
        {value: this.data[1]},
        {value: this.data[2]},
        {value: this.data[3], name: 'marketplace'},
      ]
    } : {
      labels: ['review'],
      series: [1]
    }
    this.chartOptions = {
      donut: true,
      donutWidth: '50%',
      showLabel: false,
    }
    this.chartEvents = {
      draw: (context) => {
        context.element._node.onclick = (event) => {
          const seriesName = event.target.parentNode.getAttribute('ct:series-name')
          if (seriesName) {
            this.router.go(seriesName)
          }
        }
      }
    }
  }
}

export default InvestorP2pController
