const MOBILE_SCREEN_SIZE = 600
const DEFAULT_LINE_HEIGHT = 24

export default ['$timeout', '$window', function($timeout, $window) {
  return {
    restrict: 'A',
    link: function(scope, element, attributes) {
      if (attributes.ngShow) {
        scope.$watch(attributes.ngShow, (newValue) => {
          if (newValue && element && !$window._scrolling) {
            $timeout(() => {
              let content = angular.element($window.document.querySelector('#main-content'))
              let offset = $window.innerHeight <= MOBILE_SCREEN_SIZE ? DEFAULT_LINE_HEIGHT : $window.innerHeight / 4
              if (content && element[0] && !$window._scrollingToShow) {
                $window._scrollingToShow = true
                content.scrollToElementAnimated(element[0], offset, 500).then(() => {
                  delete $window._scrollingToShow
                })
              }
            }, 100)
          }
        })
      }
    }
  }
}]
