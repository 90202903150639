class ConfirmBankDetailsDialogController {
  /*@ngInject*/
  constructor($mdDialog, authenticationsRepository, investor, phone, bankDetails) {
    this.name = 'confirm-bank-details'
    this.investor = investor
    this.bankDetails = bankDetails
    this.phone = phone

    this.$onInit = () => {
      const createResponse = (status = 'saved', ...errorMessages) => {
        return {
          status: status,
          errorMessages: errorMessages
        }
      }

      this.closeAction = () => {
        $mdDialog.hide(createResponse('cancelled'))
      }

      const authenticationPromise = authenticationsRepository.create()

      let challengeStatus = 'initial'
      let savedCode = ''
      this.sendChallengeCode = () => {
        challengeStatus = 'sending'
        authenticationPromise.then((authentication) => {
          const challengeCodeSentSuccess = () => {
            challengeStatus = 'sent'
            this.errorMessage = undefined
          }
          const challengeCodeSentFailure = () => {
            challengeStatus = 'sendFailure'
            this.errorMessage = 'Unable to send challenge code. Please try again later.'
          }
          authentication.performAction('send-code').then(challengeCodeSentSuccess, challengeCodeSentFailure)
        })
      }

      this.challengeCodeSent = () => challengeStatus === 'sent'

      this.awaitingResponse = () => ['sending', 'saving'].includes(challengeStatus)

      this.hasChallengeCodeError = () => this.errorMessage !== undefined

      const validChallengeCodeCandidate = candidateCode => candidateCode.match(/^\s*\d{6}\d*\s*$/) !== null

      this.disableSave = () => {
        return !this.challengeCode ||
          (this.errorMessage && this.challengeCode === savedCode) ||
          (!validChallengeCodeCandidate(this.challengeCode) ||
            this.challengeCode === savedCode) ||
          ['saving', 'failed', 'sendFailure'].includes(challengeStatus)
      }

      this.saveAction = () => {
        challengeStatus = 'saving'
        const verificationProcess = authentication => {
          if (validChallengeCodeCandidate(this.challengeCode)) {
            savedCode = this.challengeCode

            const verifySuccess = successResult => {
              const saveSuccess = () => {
                $mdDialog.hide(createResponse())
              }
              const saveFailure = badSaveResult => {
                this.errorMessage = badSaveResult.status === 401 ?
                  'The save was not successful because you don\'t appear to be authorised for this challenge.' :
                  'There has been a problem with the save. Your changes have not been saved.'
                challengeStatus = 'failed'
              }
              this.investor.investorSetting.authenticationId = authentication.id
              this.investor.investorSetting.save().then(saveSuccess, saveFailure)
            }
            const verifyFailure = rejectResult => {
              this.errorMessage = rejectResult.data.errors.map(error => error.message).join('. ')
              challengeStatus = 'invalid'
            }
            authentication.performAction('verify-code', {code: this.challengeCode.trim()}).then(verifySuccess, verifyFailure)
          } else {
            this.errorMessage = 'Incomplete challenge code.'
            challengeStatus = 'waiting'
          }
        }
        return authenticationPromise.then(verificationProcess)
      }
    }
  }
}

export default ConfirmBankDetailsDialogController
