import template from './investorApplication.pug'
import controller from './investorApplication.controller'
import './investorApplication.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
