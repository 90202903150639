import template from './withdrawalRequestOrigin.pug'
import controller from './withdrawalRequestOrigin.controller'
import './withdrawalRequestOrigin.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      ruleId: '='
    }
  }
}
