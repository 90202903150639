import template from './mediaCarousel.pug'
import controller from './mediaCarousel.controller'
import './mediaCarousel.scss'

export default () => ({
  restrict: 'E',
  scope: {},
  controllerAs: 'vm',
  bindToController: true,
  controller,
  template: template({}),
  transclude: true
})

