import FormTracker from '../../common/patchForm/formTracker'

class CheckIdentityController {

  /*@ngInject*/
  constructor(representativesRepository, userIdentityService, volatileStore, userService, $scope, $q, $timeout, $stateParams, $state) {
    this.name = 'check-identity'

    this.representativesRepository = representativesRepository
    this.userService = userService
    this.$scope = $scope
    this.$timeout = $timeout
    this.volatileStore = volatileStore

    this.verificationStartedHandler = $q.defer()
    this.verificationStartedHandler.promise.then(() => this._verificationStarted())

    this.registrationCompletionHandler = $q.defer()
    this.registrationCompletionHandler.promise.then(() => this._registrationComplete())

    this.$onInit = () => {
      if ($stateParams.token) {
        this.volatileStore.tpAuthToken = $stateParams.token
        this.userIdentityPromise = this._load(
          this.userService.logout()
            .then(() => this.representativesRepository.getById($stateParams.token))
            .then((owner) => this._setOwner(owner))
            .then(() => this.owner.promise('user'))
            .then((user) => this.user = user)
            .then(() => this._checkAlreadyRegistered())
            .then(() => this.user)
            .then((user) => userIdentityService.augment(user))
            .catch((...errors) => {
              // TODO : Report the error.
            })
        )
      } else {
        this.volatileStore.tpAuthToken = this._generateToken()
        this.userIdentityPromise = this._load(
          this.userService.logout()
            .then(() => this._setOwner({user: {_authToken: this.volatileStore.tpAuthToken}, details: {}, save: () => $q.resolve(true), performAction: () => $q.resolve(true)}))
            .then(() => this.user = this.owner.user)
            .then((user) => userIdentityService.augment(user))
        )
      }
    }

    $scope.$on('$destroy', () => {
      this._clearToken()
    })

    $state['$current'] ||= {}
    $state['$current']['data'] ||= {landingPage: true}
  }

  submit() {
    this._load(this.owner.performAction('clear-token', {})).then(() => {
      this._clearToken()
      this.submitted = true
    })
  }

  validForSubmit() {
    return this.loaded &&
      this.formTracker &&
      this.formTracker.valid() &&
      this._isRegistered()
  }

  get loanOrigination() {
    return this.owner?.metadata?.loanEnquiry?.origination
  }

  get loanPurpose() {
    return this.owner?.metadata?.loanEnquiry?.purpose
  }

  // private

  _verificationStarted() {
    if (this.owner.isBorrower || this.owner.isLoanEnquiry) {
      this.owner.save()
    }
  }

  _registrationComplete() {
    this.submit()
  }

  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript (RFC4122)
  _generateToken() {
    let d = new Date().getTime()
    // use high-precision timer if available
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now()
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  }

  _clearToken() {
    delete this.volatileStore.tpAuthToken
  }

  _isRegistered() {
    return this.user && this.userService.isRegistered(this.user)
  }

  _setOwner(owner) {
    this.owner = owner
    this.formTracker = new FormTracker(owner, owner.details)
    this.details = owner.details
    this.formTracker.setValidateInitial(this.validationProperty)
    this.addressSearchText = owner.details.residentialAddress
  }

  _load(promise) {
    this.loaded = false
    return promise.then((result) => {
      this._loaded()
      return result
    })
      .catch(() => {
        this._loaded()
      })
  }

  _loaded() {
    // Combining es6 promises and $q means that a promise chain might not
    // trigger a digest on completion. Hence we use a timeout
    // See card #421
    this.$timeout(() => this.loaded = true)
  }

  _checkAlreadyRegistered() {
    if (this._isRegistered()) {
      this._registrationComplete()
    }
  }
}

export default CheckIdentityController
