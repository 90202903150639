import Big from 'big.js'

class ShowLoanPartListingsController {
  /*@ngInject*/
  constructor(loanPartListingsService, $mdDialog, $filter) {
    this.name = 'show-loan-part-listings'

    this.loanPartListingsOrderConversion = (listing) => {
      return Number.parseFloat(listing.amount)
    }

    this.loanPartListingDelete = (listing) => {
      listing.delete().catch(arg => {
        let message = ''
        if (arg.data && arg.data.errors && arg.data.errors.length > 0) {
          const handledErrors = new Map([
            ['CannotDeleteCompletedListing', 'the listing has already been purchased']
          ])
          message = 'The deletion of the listing failed because'
          let separator = ' '
          let optionalAnd = ''
          arg.data.errors.forEach((error, index, allErrors) => {
            let errorText = `unknown error [${error.code}]`
            if (handledErrors.has(error.code)) {
              errorText = handledErrors.get(error.code)
            } else {
              errorText = `an unknown error (${error.code}) occurred`
            }
            optionalAnd = allErrors.length > 1 && index === allErrors.length - 1 ? 'and ' : ''
            message += separator + optionalAnd + errorText
            separator = ', '
          })
          message += '.'
        } else {
          message = 'The deletion of your listing was unsuccessful.'
        }
        const options = $mdDialog.alert()
          .clickOutsideToClose(true)
          .title('Delete')
          .textContent(message)
          .ariaLabel('Delete Listing Failure Dialog')
          .ok('Close')
        $mdDialog.show(options)
        return false
      }).finally(() => {
        loanPartListingsService.loadActiveListings(this.compositeLoanPart)
      })
      return true
    }

    this.confirmRemovingListing = (listing) => {
      const options = $mdDialog.confirm()
        .textContent(`Are you sure you want to remove this listing of ${$filter('asCurrency')(listing.amount)}?`)
        .clickOutsideToClose(true)
        .title('Unlist investment?')
        .ariaLabel('Unlist investment?')
        .ok('Unlist')
        .cancel('Retain')

      const onCancel = () => {
        return false
      }

      const onDelete = () => {
        return this.loanPartListingDelete(listing)
      }

      return $mdDialog.show(options).then(onDelete, onCancel)
    }

    this.confirmRemovingAllListings = () => {
      const options = $mdDialog.confirm()
        .textContent(`Are you sure you want to remove all listings?`)
        .clickOutsideToClose(true)
        .title('Unlist investments?')
        .ariaLabel('Unlist investments?')
        .ok('Unlist all')
        .cancel('Retain all')

      const onCancel = () => {
        return false
      }

      const onDelete = () => {
        this.compositeLoanPart.listingsContext.listings.forEach((listing) => this.loanPartListingDelete(listing))
      }

      return $mdDialog.show(options).then(onDelete, onCancel)
    }
  }

  get hasListings() {
    return this.compositeLoanPart.listingsContext.listings.length > 0
  }

  get listingSum() {
    return this.compositeLoanPart.listingsContext.listings.reduce((sum, listing) => {
      return sum.plus(listing.amount)
    }, new Big('0.0'))
  }
}

export default ShowLoanPartListingsController
