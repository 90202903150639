class RouterService {
  /*@ngInject*/
  constructor($state) {
    const defaultOptions = () => ({location: $state.current.replace ? 'replace' : true})

    this.currentRouteData = () => {
      return $state.current.data || {}
    }

    this.go = (to, params = {}, options = {}) => {
      $state.go(to, params, Object.assign(defaultOptions(), options))
    }

    this.reload = () => {
      $state.reload()
    }
  }
}

export default RouterService
