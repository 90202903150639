import {
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  isLoanAmount,
  isReferrerNotes,
  isReferrer,
  loanAmount,
  loanPurpose,
  referrerFirstName,
  referrerLastName,
  referrerPhone,
  referrerEmail,
  referrerNotes,
  isBorrowerDone,
  conjunction,
  isReferrerEmail,
  preferredCommission,
  originationFee
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  and,
  isModelDefined,
  fromModel,
  info
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const cashFlowBrokerEnquiryForm = {
  title: 'Broker business loan',
  model: 'business-loan-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      // .prompt('Secured business loan enquiry')
      // .classes('border')
      .widgets(
        loanPurpose()
          .required(true)
          .display('none')
          .rules(ruleSet('"cashflow"'))
          .build(),
        contactType().candidatePartner().build(),
        helpContent().contentId('enquiry-form-business-application-main').build(),
        container()
          .boxed(5)
          .prompt('Accredited broker details')
          .widgets(
            referrerFirstName().build(),
            referrerLastName().build(),
            referrerPhone().build(),
            referrerEmail().build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Loan details')
          .rules(ruleRender(isReferrerEmail))
          .widgets(
            loanAmount().prompt('Business loan amount?').min(50_000).max(300_000).build(),
            preferredCommission()
              .prompt('What is your preferred brokerage? (This will be included in the loan amount as an establishment fee)')
              .min(0)
              .max(5)
              .step(0.5)
              .required(true)
              .build(),
            originationFee().build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isLoanAmount))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background, and the reason for the loan')
              .required(true)
              .rules(ruleRender(isLoanAmount))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            info().content('By completing these details you are confirming that you hold the guarantor\'s authority to provide them, ' +
              'including (where applicable) copies of documents containing personal identifying information.').build(),
            ...borrowerFullComposite(),
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Yes, another', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(isBorrowerDone))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(isBorrowerDone))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('homeLoan', 'Most recent home loan statement')
              .fileGroup('councilRates', 'Copy of council rates notices')
              .fileGroup('creditReports', 'Equifax credit report for individuals')
              .fileGroup('propertyValuation', '<6 month old sworn property valuation report (optional)')
              .fileGroup('driversLicence', 'Copy of Drivers License (optional but preferred)')
              .fileGroup('bankStatements', '6 months of electronic bank statements (provided by broker or link sent to customer from TruePillars)')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .build())
          .build())
      .build()
  ]
}

export default cashFlowBrokerEnquiryForm
