import 'angular-scroll'

export default function addScrollHandler($rootScope, $document, $timeout) {
  $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
    $timeout(() => {
      let content = angular.element($document.find('#main-content'))
      let selector = toParams && toParams.scrollTo
      let node = selector && $document.find(selector)

      if (node) {
        content.scrollToElementAnimated(angular.element(node), 0, 500)
      } else {
        content.scrollTo(0, 0)
      }
    }, 0)
  })
}
