import ApplicationRouter from './applicationRouter'
import investorApplicationConfig from './investorApplicationConfig'

class InvestorApplicationRouterService extends ApplicationRouter {
  /*@ngInject*/
  constructor(router, $state, $stateParams) {
    super(router, $state, $stateParams, investorApplicationConfig)
  }
}

export default InvestorApplicationRouterService
