import template from './addressSelect.pug'
import controller from './addressSelect.controller'
import './addressSelect.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    require: ['^form', '^?patchForm', '^?formRepeatSectionTransclusion'],
    bindToController: {
      label: '@?',
      name: '@',
      address: '=',
      selectedItemChange: '&',
      required: '=?',
      dynamic: '=?'
    },
    link: (scope, ele, attrs, ctrls) => {
      const [form, patchForm, formRepeatSectionTransclusion] = ctrls
      scope.vm.form = form
      scope.vm.patchForm = patchForm
      scope.vm.formRepeatSectionTransclusion = formRepeatSectionTransclusion
    }
  }
}
