class ContentItemController {
  /*@ngInject*/
  constructor(contentItemsService) {
    this.name = 'contentItem'
    this.contentItem = undefined

    this.$onInit = () => {
      if (this.contentItemName) {
        contentItemsService.getContentItemByName(this.contentItemName)
          .then((contentItem) => {
            this.contentItem = contentItem
          })
      } else {
        throw new Error('ContentItemController: Coding error. Missing argument: contentItemName.')
      }
    }
  }

  get content() {
    return this.contentItem ? this.contentItem.content : ''
  }

}

export default ContentItemController
