import angular from 'angular'
import 'angular-ui-router'

import checkIdentityComponent from './checkIdentity.component'

export default angular.module('app.pages.checkIdentity', ['ui.router'])
  .directive('checkIdentity', checkIdentityComponent)
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('checkIdentity', {
        url: '/identity-check/:token',
        template: '<check-identity />',
      })
      .state('createIdentity', {
        url: '/identity-check',
        template: '<check-identity />',
      })
  }])
