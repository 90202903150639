class InvestorApplicationDetailsController {
  /*@ngInject*/
  constructor($state, $stateParams, $scope, router, investorApplicationsRepository) {
    this.name = 'investor-application-details'
    this.router = router
    this.$state = $state
    this.tabs = [
      {label: 'Investor Application', state: 'adminInvestorApplication.application'},
      {label: 'Representatives', state: 'adminInvestorApplication.representative'},
      {label: 'Transactions', state: 'adminInvestorApplication.transactions'},
      {label: 'Manage', state: 'adminInvestorApplication.manage'},
    ]
    this.id = $stateParams.id
    this.investorApplicationsRepository = investorApplicationsRepository

    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.name.indexOf('adminInvestorApplication') === 0) {
        let indexOfState = this.tabs.findIndex(tab => tab.state === toState.name)
        if (indexOfState >= 0) {
          this.selectedIndex = indexOfState
        }
      }
    })

    this.$onInit = () => {
      this.investorApplicationsRepository.getById(this.id).then((investorApplication) => {
        this.investorApplication = investorApplication
      })
    }
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }
}

export default InvestorApplicationDetailsController
