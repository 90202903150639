import {container, radioButtons, ruleRender} from '../../../../../../common/dynamicForm/helpers/dynamicFormHelpers'

const formSendBankStatementsRequest = {
  title: 'Electronic bank statements',
  model: 'electronicBankStatements',
  actionLabel: 'Send request email',
  widgets: [
    container()
      .boxed(5)
      .widgets(
        {
          type: 'String',
          definition: {
            model: 'electronicBankStatements.clientFirstName',
            prompt: 'Client\'s first name',
            required: true
          }
        },
        {
          type: 'String',
          definition: {
            model: 'electronicBankStatements.clientLastName',
            prompt: 'Client\'s last name',
            required: true
          }
        },
        {
          type: 'Email',
          definition: {
            model: 'electronicBankStatements.clientEmail',
            prompt: 'Client\'s email address',
            required: true
          }
        }
        ).build(),
      container()
        .boxed(5)
        .rules(ruleRender('(isDefined (fromModel "electronicBankStatements.referrerEmail"))'))
        .widgets(
          radioButtons()
            .model('electronicBankStatements.copyToReferrer')
            .prompt('Send copy to the referrer?')
            .button('CC via email', 'yes')
            .button('Not required', 'no')
            .required(false)
            .build()
        ).build()
    ]
}

export default formSendBankStatementsRequest
