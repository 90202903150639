import angular from 'angular'
import 'angular-ui-router'

import mediaComponent from './media.component'

export default angular.module('app.pages.marketing.media', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('media', {
        url: '/media',
        template: '<media/>',
        tabName: 'More',
        data: {
          appClasses: ['hero']
        }
      })
  }])
  .directive('media', mediaComponent)
