import template from './linkedBusinesses.pug'
import controller from './linkedBusinesses.controller.js'
import './linkedBusinesses.scss'
import './addBusinessDialog/addBusinessDialog.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '='
    }
  }
}
