import template from './referrerAssistance.pug'
import controller from './referrerAssistance.controller'
import './referrerAssistance.scss'

export default () => {
  return {
    transclude: true,
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      metadata: '='
    }
  }
}
