import angular from 'angular'

import countrySelectModule from './countrySelect/countrySelect'
import inputContainerModule from './inputContainer/inputContainer'
import formRepeatSectionModule from './formRepeatSection/formRepeatSection'
import addressSelectModule from './addressSelect/addressSelect'
import yearSelectModule from './yearSelect/yearSelect'
import dateSelectModule from './dateSelect/dateSelect'
import stringToNumberModule from './stringToNumber/stringToNumber'
import stringToPercentageModule from './stringToPercentage/stringToPercentage'
import stringToDateModule from './stringToDate/stringToDate'
import onBlurModule from './onBlur/onBlur'
import autoSaveFormModule from './autoSaveForm/autoSaveForm'
import scrollToShowModule from './scrollToShow/scrollToShow'

export default angular.module('app.common.forms', [
  countrySelectModule.name,
  inputContainerModule.name,
  formRepeatSectionModule.name,
  addressSelectModule.name,
  yearSelectModule.name,
  dateSelectModule.name,
  stringToNumberModule.name,
  stringToDateModule.name,
  stringToPercentageModule.name,
  onBlurModule.name,
  autoSaveFormModule.name,
  scrollToShowModule.name
])
