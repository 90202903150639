import filteredPanels from '../../../../utils/filteredPanels'

class ConsentController {
  /*@ngInject*/
  constructor(contentful) {
    this.name = 'consent'

    this.howHeardOptions = [
      {key: 'google', label: 'Online search'},
      {key: 'nestegg', label: 'Nestegg'},
      {key: 'online ad', label: 'Online ad'},
      {key: 'facebook', label: 'Facebook'},
      {key: 'instagram', label: 'Instagram'},
      {key: 'linkedin', label: 'LinkedIn'},
      {key: 'twitter', label: 'Twitter'},
      {key: 'media', label: 'In the media'},
      {key: 'word', label: 'Family/friend'},
      {key: 'other', label: 'Other'}
    ]

    this.panels = new Map()

    this.$onInit = () => {
      filteredPanels(contentful, 'consent-form-', (resultMap) => this.panels = resultMap)
    }
  }

  get consent() {
    if (!this.formTracker) { return null }
    if (!this.formTracker.property.consent) { this.formTracker.property.consent = {} }
    return this.formTracker.property.consent
  }

  getPanel(name) {
    const panelEntry = this.panels.get(name)
    return panelEntry ? panelEntry.panel : undefined
  }
}

export default ConsentController
