class LoginRouterService {
  /*@ngInject*/
  constructor(router, entityContextService, $window) {
    this.router = router
    this.entityContextService = entityContextService
    this.$window = $window
  }

  currentRouteData() {
    return this.router.currentRouteData()
  }

  currentRouteReload() {
    this.router.reload()
  }

  gotoDashboard(options = {}) {
    this._basicRoute(this.entityContextService, options)
  }

  gotoHome() {
    this.router.go('home')
  }

  onLogin(options = {}) {
    this.entityContextService.current().then((context) => {
      this._basicRoute(context, options, () => { this.currentRouteData().resumeOnLogin ? this.currentRouteReload() : this._gotoInvestorDashboard(options) })
    }).catch(() => {
      this.gotoHome()
    })
  }

  resumeSession(options = {}) {
    this.entityContextService.current().then((context) => {
      if (context.investorApplication) {
          this.router.go('investorApplication', {id: context.investorApplication.id}, options)
      } else {
        this.currentRouteReload()
      }
    }).catch(() => {
      this.gotoHome()
    })
  }

  // private

  _basicRoute(context, options, investorAction = () => { this._gotoInvestorDashboard(options) }) {
    const investorApplication = {predicate: () => context.investorApplication, action: () => { this.router.go('investorApplication', {id: context.investorApplication.id}, options) }}
    const investor = {predicate: () => context.investor, action: investorAction}
    const admin = {predicate: () => context.admin, action: () => { this.router.go('adminDashboard', {}, options) }}
    const otherwise = {predicate: () => true, action: () => { this.gotoHome() }}

    const orderedRoutesByContext = [investorApplication, investor, admin, otherwise]

    orderedRoutesByContext.find(e => e.predicate()).action()
  }

  _gotoInvestorDashboard(options) {
    this.router.go('investorDashboard', {}, options)
  }
}

export default LoginRouterService
