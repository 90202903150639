import {parse} from './dsl/formDsl.pegjs'
import valueOrDefault from '../../utils/valueOrDefault'
import merge from 'lodash/merge'

class RulesService {
  /*@ngInject*/
  constructor(userService, entityContextService, featureTogglesService, applicationSettingsService) {
    const createDefaultAppContext = () => {
      const investorApplicationInProgress = valueOrDefault(entityContextService, {investorApplication: false}).investorApplication
      return {
        applicationInProgress: investorApplicationInProgress,
        investorApplicationInProgress: investorApplicationInProgress,
        isAdmin: valueOrDefault(userService, {isAdmin: () => false}).isAdmin(),
        isInvestor: valueOrDefault(entityContextService, {investor: false}).investor,
        isLoggedIn: valueOrDefault(userService, {isLoggedIn: () => false}).isLoggedIn(),
      }
    }

    this.createDefaultOptions = () => ({
      appContext: createDefaultAppContext(),
      toggleResolver: (toggleName) => featureTogglesService.can(toggleName),
      applicationSettingResolver: (settingName) => applicationSettingsService.setting(settingName)
    })
  }

  parse(expression, options = {}) {
    const mergedOptions = merge({}, this.createDefaultOptions())
    merge(mergedOptions, options)
    // If your having a problem with a rule expression, then uncomment the next line and check the browser console for diagnostic information.
    // console.log(`******** expression:`, expression)
    return parse(expression, mergedOptions)
  }
}

export default RulesService
