export default class AbTestVariantService {
  /*@ngInject*/
  constructor(localStorageService) {
    this.localStorageService = localStorageService
  }

  setVariant(variants, random, variantPrefix, localStorageName) {
    const sum = Array.from(variants.entries()).reduce((total, variant) => total + variant[1], 0)
    const selectedIndex = Math.trunc(random * sum)
    const predictablyOrderedVariantKeys = Array.from(variants.keys()).sort()

    let offset = 0
    for (let variantKey of predictablyOrderedVariantKeys) {
      if (offset <= selectedIndex && selectedIndex < offset + variants.get(variantKey)) {
        this.localStorageService.set(localStorageName, variantKey.substring(variantKey.indexOf(variantPrefix) + variantPrefix.length))
        break
      }
      offset += variants.get(variantKey)
    }
  }
}
