import template from './uploadedFiles.pug'
import controller from './uploadedFiles.controller.js'
import './uploadedFiles.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '=',
      resourceCache: '='
    }
  }
}
