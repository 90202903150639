import angular from 'angular'
import 'angular-ui-router'

import borrowerDetailsComponent from './borrowerDetails.component'

export default angular.module('app.pages.admin.borrowerDetails', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('adminBorrower', {
        url: '/admin/borrowers/:id',
        template: '<admin-borrower-details/>',
        replace: true,
        data: {
          requireAuth: true
        }
      })
  }])
  .directive('adminBorrowerDetails', borrowerDetailsComponent)
