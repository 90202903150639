import Big from 'big.js'
import moment from 'moment'

const dateAsString = (date) => moment(date).format('YYYY-MM-DD')

const monthlyDate = (d) => {
  const date = moment(d)
  return date.date() > 28 ? date.date(28) : date
}

class CreateLoanController {
  /*@ngInject*/
  constructor($scope, $mdDialog, loansRepository, loanSchedulesRepository) {
    this.name = 'create-loan'
    this.$scope = $scope
    this.$mdDialog = $mdDialog
    this.loansRepository = loansRepository
    this.loanSchedulesRepository = loanSchedulesRepository

    this.interestMethod = 'daily'
    this.establishmentFee = '0'
    this.drawdownFee = '0'
    this.feesRebate = '0'

    this.$onInit = () => {
      this._startDate = moment().startOf('day').toDate()
      this._firstRepaymentDate = (monthlyDate(this.startDate).add(1, 'month')).toDate()
      this._endDate = (monthlyDate(this.startDate).add(this.loanRequest.termInMonths, 'month')).toDate()
      if (this.loanRequest.fundingSource === 'auction') {
        this.borrowersRate = this.loanRequest.auction.borrowersRate
        this.repaymentAmount = this.loanRequest.auction.borrowersMonthlyPayment
      } else {
        this.borrowersRate = this.loanRequest.maxRate
        this.repaymentAmount = new Big(this.loanRequest.amount)
          .minus(this.loanRequest.residual)
          .plus(new Big(this.loanRequest.amount).times(new Big(this.borrowersRate).div(100)).times(new Big(this.loanRequest.termInMonths).div(12)))
          .div(new Big(this.loanRequest.termInMonths))
          .round(2, 0)
          .toString()
      }

      this._loadSchedule()

      $scope.$watch(() => this._startDate, () => this._loadSchedule())
      $scope.$watch(() => this._firstRepaymentDate, () => this._loadSchedule())
      $scope.$watch(() => this._endDate, () => this._loadSchedule())
      $scope.$watch(() => this.interestMethod, () => this._loadSchedule())
      $scope.$watch(() => this.borrowersRate, () => this._loadSchedule())
      $scope.$watch(() => this.repaymentAmount, () => this._loadSchedule())
    }
  }

  get startDate() { return this._startDate }

  get firstRepaymentDate() { return this._firstRepaymentDate }

  get endDate() { return this._endDate }

  set startDate(date) {
    this._startDate = date
    this._validateDates()
  }

  set firstRepaymentDate(date) {
    this._firstRepaymentDate = date
    this._validateDates()
  }

  set endDate(date) {
    this._endDate = date
    this._validateDates()
  }

  confirmAction() {
    const options = this.$mdDialog.confirm({
      onComplete: (scope, element, options) => {
        const actionsSection = element.find('md-dialog-actions')
        const cancelButton = actionsSection.children()[0]
        const confirmButton = actionsSection.children()[1]
        angular.element(confirmButton).removeClass('md-focused md-primary')
        angular.element(confirmButton).addClass('md-raised md-secondary')
        angular.element(cancelButton).addClass('md-focused md-raised md-primary')
        cancelButton.focus()
      }
    })
      .htmlContent(`
        <div class="loan-confirmation">
          <h3>Confirm you want to create the loan</h3>
          <p>Click CREATE! to create the loan, or CANCEL to return to the create screen.</p>
        </div>
      `)
      .clickOutsideToClose(true)
      .title('Confirm?')
      .ariaLabel('Confirm?')
      .multiple(true)
      .ok('Create!')
      .cancel('Cancel')
    return this.$mdDialog.show(options)
      .then(() => this._create(), () => this._cancelConfirmationAction())
  }

  cancel() {
    this.completion.reject()
  }

  numberOfRepayments() {
    return moment(this._endDate).diff(moment(this._firstRepaymentDate), 'months', false) + 1
  }

  // private

  _create() {
    const payload = {
      loanRequestId: this.loanRequest.id,
      startDate: dateAsString(this._startDate),
      firstRepaymentDate: dateAsString(this._firstRepaymentDate),
      endDate: dateAsString(this._endDate),
      borrowersRate: this.borrowersRate,
      interestMethod: this.interestMethod,
      repaymentAmount: this.repaymentAmount,
      establishmentFee: this.establishmentFee,
      drawdownFee: this.drawdownFee,
      feesRebate: this.feesRebate
    }
    return this.loansRepository.create(payload).then(() => this.completion.resolve())
  }

  _cancelConfirmationAction() {
    return false
  }

  _validateDates() {
    this._validateStartDate()
    this._validateEndDate()
    this._validateFirstRepaymentDate()
  }

  _validateStartDate() {
    if (this.createForm) {
      this.createForm['startDate'].$setValidity('range', !(this.startDate && moment(this.startDate).isValid()) || !moment(this.startDate).isBefore(moment().startOf('day').toDate()))
    }
  }

  _validateEndDate() {
    if (this.createForm) {
      if (this.endDate && moment(this.endDate).isValid()) {
        this.createForm['endDate'].$setValidity('range', moment(this.startDate).isBefore(moment(this.endDate)))
        this.createForm['endDate'].$setValidity('sameAsFirstRepaymentDay', moment(this.firstRepaymentDate).date() === moment(this.endDate).date())
      } else {
        this.createForm['endDate'].$setValidity('range', true)
        this.createForm['endDate'].$setValidity('sameAsFirstRepaymentDay', true)
      }
    }
  }

  _validateFirstRepaymentDate() {
    if (this.createForm) {
      if (this.firstRepaymentDate && moment(this.firstRepaymentDate).isValid()) {
        if (moment(this.firstRepaymentDate).isBefore(this._startDate)) {
          this.createForm['firstRepaymentDate'].$setValidity('range', false)
        } else if (moment(this.firstRepaymentDate).isAfter(this._endDate)) {
          this.createForm['firstRepaymentDate'].$setValidity('range', false)
        } else {
          this.createForm['firstRepaymentDate'].$setValidity('range', true)
        }
        this.createForm['firstRepaymentDate'].$setValidity('sameAsEndDay', moment(this.firstRepaymentDate).date() === moment(this.endDate).date())
      } else {
        this.createForm['firstRepaymentDate'].$setValidity('range', true)
        this.createForm['firstRepaymentDate'].$setValidity('sameAsEndDay', true)
      }
    }
  }

  _loadSchedule() {
    if (this.createForm) {
      if (this.createForm.$valid) {
        this.loadingPromise = this.loanSchedulesRepository.where({
          type: this.loanRequest.type,
          amount: this.loanRequest.amount,
          residual: this.loanRequest.residual,
          standardFee: '0',
          startDate: dateAsString(this._startDate),
          firstRepaymentDate: dateAsString(this._firstRepaymentDate),
          endDate: dateAsString(this._endDate),
          interestMethod: this.interestMethod,
          rate: this.borrowersRate,
          repaymentType: 'principalAndInterest',
          repaymentFrequency: 'monthly',
          repaymentAmount: this.repaymentAmount
        }).then((schedule) => this.schedule = schedule)
      } else {
        delete this.schedule
      }
    }
  }
}

export default CreateLoanController
