var pug = require("!../../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {


















pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};






































pug_html = pug_html + "\u003Ch2\u003EAdd representative\u003C\u002Fh2\u003E\u003Cform layout=\"column\" layout-padding flex name=\"vm.createForm\" api-form=\"vm.confirmAction()\"\u003E\u003Cinput-container label=\"Title\"\u003E\u003Cmd-select class=\"honorifics small-list\" ng-model=\"vm.model.honorific\" placeholder=\"Title\" aria-label=\"Title\"\u003E\u003Cmd-option ng-repeat=\"honorific in vm.honorifics\" ng-value=\"honorific.value\"\u003E{{ honorific.label || honorific.value }}\u003C\u002Fmd-option\u003E\u003C\u002Fmd-select\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"First name\"\u003E\u003Cinput required name=\"firstName\" ng-model=\"vm.model.firstName\"\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"Middle name\"\u003E\u003Cinput name=\"middleName\" ng-model=\"vm.model.middleName\"\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"Last name\"\u003E\u003Cinput required name=\"lastName\" ng-model=\"vm.model.lastName\"\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"Phone number\"\u003E\u003Cinput name=\"phone\" ng-model=\"vm.model.phone\" type=\"tel\" pattern=\"^04(\\d *){8}\"\u003E\u003Cng-message when=\"pattern\"\u003EInvalid mobile phone number (e.g. 0412 345 678) \u003C\u002Fng-message\u003E\u003C\u002Finput-container\u003E\u003Cinput-container label=\"Email address\"\u003E\u003Cinput required name=\"email\" ng-model=\"vm.model.email\" type=\"email\"\u003E\u003C\u002Finput-container\u003E\u003Cdiv class=\"corporate-representative\" ng-if=\"vm.isBorrower || vm.isCorporateInvestor\"\u003E\u003Cdiv layout=\"row\" layout-align=\"start center\"\u003E\u003Cp class=\"label required\" flex=\"60\"\u003EIs a beneficial owner?\u003C\u002Fp\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-radio-button ng-value=\"true\"\u003EYes\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button ng-value=\"false\"\u003ENo &nbsp;&nbsp;\u003C\u002Fmd-radio-button\u003E";
}
}, "isBeneficialOwner", "vm.model.isBeneficialOwner");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv layout=\"row\" layout-align=\"start center\"\u003E\u003Cp class=\"label required\" flex=\"60\"\u003EIs a director?\u003C\u002Fp\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-radio-button ng-value=\"true\"\u003EYes\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button ng-value=\"false\"\u003ENo &nbsp;&nbsp;\u003C\u002Fmd-radio-button\u003E";
}
}, "isDirector", "vm.model.isDirector");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv layout=\"row\" layout-align=\"start top\" ng-if=\"vm.canHavePercentage\"\u003E\u003Cinput-container label=\"Percentage of ownership\" flex=\"60\"\u003E\u003Cinput required name=\"ownershipPercent\" ng-model=\"vm.model.ownershipPercent\" type=\"number\" min=\"0\" max=\"100\"\u003E\u003C\u002Finput-container\u003E\u003Cp\u003E%\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv layout=\"row\" layout-align=\"start center\"\u003E\u003Cp flex=\"60\"\u003EDoes the representative have a day-to-day role in the business?\u003C\u002Fp\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-radio-button ng-value=\"true\"\u003EYes\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button ng-value=\"false\"\u003ENo &nbsp;&nbsp;\u003C\u002Fmd-radio-button\u003E";
}
}, "hasActiveRole", "vm.model.hasActiveRole");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"vm.model.hasActiveRole\"\u003E\u003Cinput-container label=\"Job title \u002F role\"\u003E\u003Cinput name=\"jobTitle\" ng-model=\"vm.model.jobTitle\"\u003E\u003C\u002Finput-container\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"actions\" layout=\"row\"\u003E\u003Cmd-button class=\"md-raised md-secondary cancel\" type=\"button\" ng-click=\"vm.cancelAction()\"\u003ECancel\u003C\u002Fmd-button\u003E\u003Cmd-button class=\"md-raised md-accent create\" type=\"submit\"\u003ECreate\u003C\u002Fmd-button\u003E\u003C\u002Fdiv\u003E\u003C\u002Fform\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;