import angular from 'angular'
import withdrawalVerifiedModule from './withdrawalVerified/withdrawalsVerified'
import withdrawalDeclinedModule from './withdrawalDeclined/withdrawalsDeclined'
import withdrawalCompletedModule from './withdrawalCompleted/withdrawalsCompleted'
import withdrawalProcessingModule from './withdrawalProcessing/withdrawalsProcessing'
import withdrawalRejectedModule from './withdrawalRejected/withdrawalsRejected'

import withdrawalsComponent from './withdrawals.component.js'

export default angular.module('app.pages.admin.adminDashboard.withdrawals', [
  withdrawalVerifiedModule.name,
  withdrawalDeclinedModule.name,
  withdrawalCompletedModule.name,
  withdrawalProcessingModule.name,
  withdrawalRejectedModule.name
])
  .directive('adminWithdrawals', withdrawalsComponent)
