class CancelLoanRequestController {
  /*@ngInject*/
  constructor() {
  }

  confirm() {
    this.loanRequest.performAction('cancel', {}).then(() => this.completion.resolve())
  }

  cancel() {
    this.completion.reject()
  }
}

export default CancelLoanRequestController
