import isDefined from './isDefined'


const isGenericCandidate = (genericCandidate, validLength, validPattern, noisePattern, validChecksum) => {
  if (isDefined(genericCandidate)) {
    const trimmedCandidate = genericCandidate.toString().trim()
    const parsedCandidate = trimmedCandidate.match(validPattern)
    if (parsedCandidate) {
      const compressedCandidate = parsedCandidate[0].replace(noisePattern, '')
      if (compressedCandidate.length !== validLength) {
        return false
      } else {
        return validChecksum(compressedCandidate)
      }
    } else {
      return false
    }
  } else {
    return false
  }
}


/*
  ABN : Australian Business Number

  Reference: https://abr.business.gov.au/Help/AbnFormat
 */

const ABN_LENGTH = 11
const ABN_PATTERN = /^[0-9][-0-9 ]+$/
const ABN_NOISE = /[- ]/g
const ABN_CHECKSUM_MULTIPLIERS = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
const ABN_CHECKSUM_DIVISOR = 89
const ABN_VALID_CHECKSUM = (candidate) => ((Array.from(candidate).reduce((acc, num, index) => {
  acc += (Number.parseInt(num, 10) - (index === 0 ? 1 : 0)) * ABN_CHECKSUM_MULTIPLIERS[index]
  return acc
}, 0)) % ABN_CHECKSUM_DIVISOR) === 0

const isABNCandidate = (candidateABN) => isGenericCandidate(candidateABN, ABN_LENGTH, ABN_PATTERN, ABN_NOISE, ABN_VALID_CHECKSUM)


/*
  ACN : Australian Company Number

  Reference: https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/
 */

const ACN_LENGTH = 9
const ACN_PATTERN = /^[0-9][-0-9 ]+$/
const ACN_NOISE = /[- ]/g
const ACN_CHECKSUM_MULTIPLIERS = [8, 7, 6, 5, 4, 3, 2, 1]
const ACN_CHECKSUM_DIVISOR = 10
const ACN_VALID_CHECKSUM = (candidate) => {
  const explodedCandidate = Array.from(candidate.toString())
  const sum = explodedCandidate.reduce((acc, num, index) => {
    acc += (index !== ACN_LENGTH - 1) ? Number.parseInt(num, 10) * ACN_CHECKSUM_MULTIPLIERS[index] : 0
    return acc
  }, 0)
  const remainder = sum % ACN_CHECKSUM_DIVISOR
  return (remainder === 0 ? 0 : ACN_CHECKSUM_DIVISOR - remainder) === Number.parseInt(explodedCandidate[ACN_LENGTH - 1], 10)
}

const isACNCandidate = (candidateACN) => isGenericCandidate(candidateACN, ACN_LENGTH, ACN_PATTERN, ACN_NOISE, ACN_VALID_CHECKSUM)


export {isABNCandidate, isACNCandidate}

