class HelpPanelsService {
  /*@ngInject*/
  constructor(contentful, $filter) {
    this.contentful = contentful
    this.$filter = $filter

    this.helpPanels = new Map()
  }

  getByIdentifier(identifier) {
    const content = this.helpPanels.get(identifier)
    if (content) {
      return content
    }
    const asyncContent = {
      identifier,
      status: 'loading',
      title: 'Loading…',
      content: '',
    }
    this.helpPanels.set(identifier, asyncContent)
    this.contentful.entries(`content_type=helpPanel&fields.name=${identifier}&limit=10&include=10`).then((entries) => {
      const fields = entries.data.items[0].fields
      asyncContent.title = fields.title
      asyncContent.content = this.$filter('fromMarkdown')(fields.content)
      asyncContent.status = 'complete'
    })
      .catch((error) => {
        asyncContent.status = 'failed'
        asyncContent.title = 'Oops!'
        asyncContent.content = '<div class="help-panel-content-failure">' +
          '<h2>Content not available</h2>' +
          '<p>Please try again later, sorry.</p>' +
          `<p class="identifier">${identifier}</p>` +
          '</div>'
        this.helpPanels.delete(identifier)
      })
    return asyncContent
  }
}

export default HelpPanelsService
