
const fsmBeforeToken = Symbol('beforeToken')
const processBeforeToken = (theChar, charIndex, theResult, tokenCache) => {
  if (theChar.value !== '{') {
    return [fsmBeforeToken, theResult + theChar.value, tokenCache]
  } else {
    return [fsmTokenStart, theResult, tokenCache + theChar.value]
  }
}

const fsmTokenStart = Symbol('tokenStart')
const processTokenStart = (theChar, charIndex, theResult, tokenCache) => {
  if (theChar.value === '{') {
    return [fsmInExpression, theResult, '']
  } else {
    return [fsmBeforeToken, theResult + tokenCache + theChar.value, '']
  }
}

const fsmInExpression = Symbol('inExpression')
const processInExpression = (theChar, charIndex, theResult, tokenCache, tokenProcessor, logger) => {
  if (theChar.value === '}') {
    return [fsmTokenEnding, theResult, tokenCache]
  } else {
    return [fsmInExpression, theResult, tokenCache + theChar.value]
  }
}

const fsmTokenEnding = Symbol('tokenEnding')
const processTokenEnding = (theChar, charIndex, theResult, tokenCache, tokenProcessor, logger) => {
  if (theChar.value === '}') {
    const [success, updatedResult] = tokenProcessor(tokenCache, theResult, logger)
    return [success ? fsmBeforeToken : fsmError, updatedResult, '']
  } else {
    logger.error(`Unexpected character '${theChar.value}' at index ${charIndex}.`)
    return [fsmError, theResult, tokenCache]
  }
}

const fsmError = Symbol('error')

const finiteStateMachine = new Map([
  [fsmBeforeToken, processBeforeToken],
  [fsmTokenStart, processTokenStart],
  [fsmInExpression, processInExpression],
  [fsmTokenEnding, processTokenEnding],
])


const textParser = (text, tokenProcessor, logger) => {
  let status = fsmBeforeToken
  const textIterator = text[Symbol.iterator]()
  let theChar = textIterator.next()

  let theResult = ''
  let tokenCache = ''
  let charIndex = 1
  while (!theChar.done && status !== fsmError) {
    [status, theResult, tokenCache] = finiteStateMachine.get(status)(theChar, charIndex, theResult, tokenCache, tokenProcessor, logger)
    if (status !== fsmError) {
      theChar = textIterator.next()
      charIndex += 1
    }
  }
  return [theResult, status !== fsmError]
}

export default textParser
