class ContentfulDocumentsService {
  /*@ngInject*/
  constructor(contentful, router, $window) {
    this.name = 'contentful-asset'
    this.router = router
    this.$window = $window

    this.assetsPromise = contentful.assets('mimetype_group=pdfdocument').then((result) =>
      this.assets = result && result.data && result.data.items
    )
  }

  findDocument(title) {
    return this.assetsPromise.then(() => {
      return title && this.assets && this.assets.find((asset) => asset.fields && asset.fields.title === title)
    })
  }
}

export default ContentfulDocumentsService
