import template from './createInvestmentProductPledgeRequest.pug'
import controller from './createInvestmentProductPledgeRequest.controller'
import './createInvestmentProductPledgeRequest.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      investor: '=',
      investmentProduct: '=',
      investmentProductParts: '=',
      completion: '=',
    },
    bindToController: true
  }
}
