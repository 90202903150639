import Big from '../../../../../../../../node_modules/big.js/big'
import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/x-pseudo'

const LoanNoteRegisterType = Symbol.for('loan note register')

class LoanNoteRegister {

  constructor(pdfService, csvService) {
    this.pdfService = pdfService
    this.csvService = csvService
  }

  processPdf(reportDate, loanNotes) {
    const processedLoanNotes = this._processLoanNotes(loanNotes)
    const totalAmount = processedLoanNotes.reduce((sum, current) => sum.plus(current.amount), new Big('0.0'))
    const totalOutstanding = processedLoanNotes.reduce((sum, current) => sum.plus(current.outstanding), new Big('0.0'))

    // -- Table summary
    const tableSummary = {
      table: {
        widths: ['*', 70],
        body: [
          [this.pdfService.thr('Total Current Balance:'), this.pdfService.thr(this.pdfService.asMoney(totalOutstanding))]
        ]
      }
    }

    // -- Generate the body of the document table, with headings
    const tableBody = (dataRows) => {
      const body = [
        [
          this.pdfService.thl('Loan\nNote'),
          this.pdfService.thl('\nReference'),
          this.pdfService.thr('Interest\nRate'),
          this.pdfService.thr('Initial\nPrincipal'),
          this.pdfService.thr('Current\nBalance')
        ]
      ]
      dataRows.forEach((row, index) => {
        body.push([
          this.pdfService.tdl(row.id, index),
          this.pdfService.tdl(row.reference, index),
          this.pdfService.tdr(row.rate.round(1, 1).toFixed(1).toString(), index),
          this.pdfService.tdr(this.pdfService.asMoney(row.amount), index),
          this.pdfService.tdr(this.pdfService.asMoney(row.outstanding), index),
        ])
      })
      return body
    }

    // -- The main report table, with the table body.
    const tableData = {
      table: {
        headerRows: 1,
        widths: [50, '*', 70, 70, 70],

        body: tableBody(processedLoanNotes),
      }
    }

    const reportDateMoment = moment(reportDate)
    const docDefinition = this.pdfService.createDocumentDefinition(reportDateMoment.locale('en').format('D MMMM YYYY'),
      'TruePillars Investment Trust', 'Loan Note Register', tableSummary, tableData)
    this.pdfService.createAndDownload(this._reportName(reportDate, 'pdf'), docDefinition)
    return {reportDate: reportDateMoment, count: processedLoanNotes.length, amount: totalAmount, outstanding: totalOutstanding}
  }

  processCsv(reportDate, loanNotes) {
    const processedLoanNotes = this._processLoanNotes(loanNotes)
    const totalAmount = processedLoanNotes.reduce((sum, current) => sum.plus(current.amount), new Big('0.0'))
    const totalOutstanding = processedLoanNotes.reduce((sum, current) => sum.plus(current.outstanding), new Big('0.0'))

    const reportDateMoment = moment(reportDate)
    const formattedReportDate = reportDateMoment.locale('en').format('YYYY-MM-DD')

    const csvLoanNotes = processedLoanNotes.map((loanNote) => {
      const csvLoanNote = {}
      csvLoanNote['Report Date'] = formattedReportDate
      csvLoanNote['Loan Note'] = loanNote.id
      csvLoanNote['Reference'] = loanNote.reference
      csvLoanNote['Interest Rate'] = loanNote.rate.round(2, 1).toFixed(2)
      csvLoanNote['Initial Principal'] = loanNote.amount.round(0, 1).toFixed(0)
      csvLoanNote['Current Balance'] = loanNote.outstanding.round(4, 1).toFixed(4)
      return csvLoanNote
    })
    this.csvService.createAndDownload(this._reportName(reportDate, 'csv'), csvLoanNotes)
    return {reportDate: moment(reportDate), count: csvLoanNotes.length, amount: totalAmount, outstanding: totalOutstanding}
  }

  // -- private

  _reportName(reportDate, suffix) {
    return `loanNoteRegister-${moment(reportDate).format('YYYY-MM-DD')}.${suffix}`
  }

  _processLoanNotes(loanNotes) {
    return loanNotes.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0).map((loanNote) => {
      return {
        id: loanNote.id,
        reference: loanNote.loanReference + '.' + loanNote.id,
        rate: new Big(loanNote.loanNoteRate),
        amount: new Big(loanNote.loanNoteAmount),
        outstanding: new Big(loanNote.loanNoteOutstanding)
      }
    })
  }

}

export {LoanNoteRegisterType, LoanNoteRegister}
