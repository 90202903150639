import createAsyncCounter from '../../utils/asyncCounts'
import debounce from 'lodash-es/debounce'
import phoneNormaliser from '../../utils/phoneNormaliser'

class BrokerAuthController {
  /*@ngInject*/
  constructor(
    $q,
    $window,
    $scope,
    $rootScope,
    $sce,
    $filter,
    contentful,
    volatileStore,
    userIdentityService,
    greenidService,
    biometricIdentitiesService,
    cognitoService,
    featureTogglesService,
    loggerService
  ) {
    this.name = 'brokerAuth'
    this.$q = $q
    this.$window = $window
    this.$scope = $scope
    this.$rootScope = $rootScope
    this.$sce = $sce
    this.$filter = $filter
    this.contentful = contentful
    this.volatileStore = volatileStore
    this.userIdentityService = userIdentityService
    this.greenidService = greenidService
    this.biometricIdentitiesService = biometricIdentitiesService
    this.cognitoService = cognitoService
    this.featureTogglesService = featureTogglesService

    this.nrLogger = loggerService.makeLogger().enableNewRelic(true).enableConsole(false)

    this._processResourcesSequentially = this._processResourcesSequentially.bind(this)
    this._processRepresentative = this._processRepresentative.bind(this)
    this.asyncCounter = createAsyncCounter()
    this.idChecksToPerform = 0
    this.completionHandler = $q.defer()
    this.completionHandler.promise.then((loanEnquiry) => this._processLoanEnquiry(loanEnquiry))

    this.$onInit = () => {
      // TODO: Ideally this should be a single call to the API, not a series of calls.
      this._contentfulLoad('contentItem', this.textContentId).then((content) => this.textContent = content)
      this._contentfulLoad('contentItem', this.titleContentId).then((content) => this.titleContent = content)
      this._contentfulLoad('contentItem', this.kycTitleContentId).then((content) => this.kycTitleContent = content)
      this._contentfulLoad('contentItem', this.kycTextContentId).then((content) => this.kycTextContent = content)

      // Sign in if the feature toggle is enabled.
      this.featureTogglesService.initialised.then(() => {
        $scope.$watch(
          () => this.featureTogglesService.can('enableRideshareCalculator'),
          async(newValue) => {
            if (newValue) {
              await this._signIn()
            }
          }
        )
      })
    }
  }

  get isComplete() {
    return this.idChecksToPerform > 0 && (this.idChecksFinished === this.idChecksToPerform)
  }

  get isSuccessful() {
    return this.idChecksToPerform > 0 && (this.idChecksSucceeded === this.idChecksToPerform)
  }

  async _signIn() {
    const user = await this.cognitoService.isSignedIn()

    if (user) {
      await this.cognitoService.verifyAccreditationStatus()
      return
    }

    const debouncedSignIn = debounce(async(username, password) => {
      try {
        await this.cognitoService.signIn(username, password)
      } catch (error) {
        this.nrLogger.error(error)
      }
    }, 1000)

    // Listen for changes to the enquiry form.
    const deregister = this.$scope.$on('enquiry-form-updated', async(event, model) => {
      if (!model || !model.referrerEmail || !model.referrerPhone) {
        return
      }

      const username = model.referrerEmail
      const password = phoneNormaliser(model.referrerPhone)

      debouncedSignIn(username, password)
    })

    this.$scope.$on('$destroy', deregister)
  }

  _processLoanEnquiry(loanEnquiry) {
    loanEnquiry.promise('representatives').then(representatives => {
      if (representatives && Array.isArray(representatives) && representatives.length > 0) {
        this.idChecksToPerform = representatives.length
        this.idChecksStarted = 0
        this.idChecksFinished = 0
        this.idChecksSucceeded = 0
        return this._processResourcesSequentially(representatives, this._processRepresentative)
      }
      return this.$q.defer([])
    })
  }

  async _processResourcesSequentially(resources, processResource) {
    const results = []
    for (const resource of resources) {
      const result = await processResource(resource)
      results.push(result)
    }
    return results
  }

  async _processRepresentative(representative) {
    this.idChecksStarted++
    this.saveAuthToken = this.volatileStore.tpAuthToken
    this.volatileStore.tpAuthToken = representative.authToken
    return representative.promise('user')
      .then((user) => {
        const userIdentity = this.userIdentityService.augment(user)
        return userIdentity.save()
          .then(() => this._createIdCheck(userIdentity))
          .then((idCheck) => {
            return this._createBiometricIdCheck(userIdentity).then(() => this._showGreenId(userIdentity, idCheck))
          })
      })
      .catch((error) => {
        this._alertNewRelic('Error processing representative: ' + error?.message)
      })
      .finally(() => {
        this.idChecksFinished++
        this.volatileStore.tpAuthToken = this.saveAuthToken
      })
  }

  _createIdCheck(userIdentity) {
    const getGreenidToken = 'getGreenIdToken'
    this.asyncCounter.start(getGreenidToken)
    return this.greenidService.createOrUpdateIdentityCheck(userIdentity)
      .catch((error) => {
        this._alertNewRelic('Error checking identity: ' + error?.message)
      }).finally(() => {
        this.asyncCounter.end(getGreenidToken)
      })
  }

  _createBiometricIdCheck(userIdentity, idCheck) {
    this.asyncCounter.start('biometrics')
    return this.biometricIdentitiesService.createOrUpdateBiometricIdCheck(userIdentity, 'Created via loan enquiry.')
      .catch((error) => {
        this._alertNewRelic('Error checking biometric identity requirements: ' + error?.message)
      }).finally(() => {
        this.asyncCounter.end('biometrics')
      })
  }

  _showGreenId(userIdentity, idCheck) {
    const greenidShowToken = 'greenidShow'
    this.asyncCounter.start(greenidShowToken)
    return this.greenidService.showAndSaveResult('greenid', idCheck, this.kycTitleContent, this.kycTextContent).then((idCheckResult) => {
      if (idCheckResult.status === 'verified') {
        this.idChecksSucceeded++
      }
    }).finally(() => this.asyncCounter.end(greenidShowToken))
  }

  _contentfulLoad(type, identifier) {
    if (!identifier) {
      return this.$q.resolve()
    }

    return this.contentful.entries(`content_type=${type}&fields.name=${identifier}&limit=10&include=10`)
      .then((entries) => {
        let content = entries.data.items[0].fields.content
        return content && this.$sce.getTrustedHtml(this.$filter('fromMarkdown')(content))
      })
  }

  _alertNewRelic(message) {
    if (this.$window.NREUM) {
      this.$window.NREUM.noticeError(new Error(message))
    }
  }
}

export default BrokerAuthController
