import template from './createLoanRepayment.pug'
import controller from './createLoanRepayment.controller'
import './createLoanRepayment.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loan: '=',
      type: '@',
      debit: '=?',
      completion: '=',
    },
    bindToController: true
  }
}
