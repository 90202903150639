class BusinessProfileController {
  /*@ngInject*/
  constructor() {
    this.name = 'business-profile'
  }

  get isTruePillarsLoan() {
    return this.loanRequest && this.loanRequest.origination === 'truepillars'
  }

  get loanHeading() {
    return this.isTruePillarsLoan ? 'Purpose of the loan' : 'Portfolio information'
  }
}

export default BusinessProfileController
