import resolveProperty from './../../utils/resolveProperty'
import valueOrDefault from './../../utils/valueOrDefault'
import isDefined from './../../utils/isDefined'
import {resolveChoices} from '../dynamicForm/helpers/dynamicFormHelpers'
import 'viewerjs/dist/viewer.css'

import transitionStageFormDefinition from './transitionStageFormDefinition'
import {isLoanEnquiryMutable} from '../../utils/loanEnquiryStatusHelper'

const TRANSITIONS = [
  {toStage: 'requested', name: 'request', label: 'request biometrics', fromStage: ['legacy', 'pending']},
  {toStage: 'review', name: 'review_required', label: 'review required', fromStage: ['requested', 'retry']},
  {toStage: 'retry', name: 'request_again', label: 'request biometrics again', fromStage: ['review', 'verified', 'rejected']},
  {toStage: 'legacy', name: 'preexisting', label: 'mark as preexisting', fromStage: ['pending']},
  {toStage: 'rejected', name: 'reject', label: 'reject', fromStage: ['pending', 'requested', 'retry', 'review', 'verified'], class: 'md-warn'},
  {toStage: 'verified', name: 'accept', label: 'accept', fromStage: ['pending', 'requested', 'retry', 'review', 'rejected'], class: 'md-accent'},
]

const TRANSITIONS_FOR_STAGE = TRANSITIONS.reduce((mappings, transition) => {
  transition.fromStage.forEach((stage) => {
    const availableTransitions = valueOrDefault(mappings.get(stage), [])
    availableTransitions.push(transition)
    mappings.set(stage, availableTransitions)
  })
  return mappings
}, new Map())


class UserIdentityCheckManagementController {
  /*@ngInject*/
  constructor($element, $scope, $q, $timeout, $location, $mdToast, usersRepository, attachmentsRepository, dynamicDialogService, viewerFactory, contentful, clipboardFactory, userService) {
    this.name = 'userIdentityCheckManagement'
    this.uniqueId = this.name + '-' + $scope.$id
    this.dynamicDialogService = dynamicDialogService
    this.$location = $location
    this.$mdToast = $mdToast

    this.clipboard = undefined
    this.biometricIdentityCheck = undefined
    this.payload = undefined
    this.biometrics = undefined
    this.photos = []

    const attachmentCache = new Map()
    this.statusChangeOrder = '-updatedAt'
    this._biometricIdentityCheckStatusChanges = []

    const retryReasonChoices = resolveChoices(contentful, {contentId: ''})

    this.isBDM = () => valueOrDefault(userService, {isBusinessDevelopmentManager: () => false}).isBusinessDevelopmentManager()

    // Used in .pug
    // noinspection JSUnusedGlobalSymbols
    this.changesReorder = () => {
      const key = this.statusChangeOrder.replace('-', '')
      const [lessThan, greaterThan] = this.statusChangeOrder.startsWith('-') ? [1, -1] : [-1, 1]
      this._biometricIdentityCheckStatusChanges.sort((a, b) => {
        const [aValue, bValue] = [a[key], b[key]]
        if (aValue < bValue) {
          return lessThan
        } else if (aValue > bValue) {
          return greaterThan
        } else {
          return a['id'] < b['id'] ? lessThan : (a['id'] > b ['id'] ? greaterThan : 0)
        }
      })
    }

    const DOM_REFRESH_DELAY_MILLISECONDS = 100
    const makeAttachImageViewerAfterDomSettles = (target) => () => {
      $timeout(() => {
        const photoParent = $element.find(target)[0]
        this.viewer = viewerFactory.createViewer(photoParent)
      }, DOM_REFRESH_DELAY_MILLISECONDS)
    }

    const resolvePhotoAttachments = (sourcePhotos, attachmentPhotos, viewerElementSelector) => {
      const deferred = $q.defer()
      deferred.promise.then(makeAttachImageViewerAfterDomSettles(viewerElementSelector))
      const processAttachment = (attachment) => {
        attachmentPhotos.push(attachment)
        if (attachmentPhotos.length === sourcePhotos.length) {
          deferred.resolve('- all attachments resolved -')
        }
      }
      sourcePhotos.forEach((photo) => {
        const attachment = attachmentCache.get(photo.attachmentId)
        if (isDefined(attachment)) {
          processAttachment(attachment)
        } else {
          attachmentsRepository.getById(photo.attachmentId)
            .then((attachment) => {
              attachmentCache.set(photo.attachmentId, attachment)
              processAttachment(attachment)
            })
        }
      })
    }

    this.resolveBiometricIdentityCheck = (biometricIdentityCheck) => {
      this.biometricIdentityCheck = biometricIdentityCheck
      const payload = biometricIdentityCheck.payload
      this.biometrics = isDefined(resolveProperty(payload, 'biometrics')) ? payload.biometrics : undefined
      if (isDefined(resolveProperty(this.biometrics, 'photos'))) {
        resolvePhotoAttachments(this.biometrics.photos, this.photos, '.main-photos')
      } else {
        this.photos = []
      }

      return biometricIdentityCheck.promise('biometricIdentityCheckStages')
        .then((biometricIdentityCheckStages) => {
          this.biometricIdentityCheckStages = biometricIdentityCheckStages.map((stage, index) => {
            const stageWrapper = {
              stage: stage,
              photos: [],
              get isRetry() {
                return stage.status === 'retry'
              },
              get retryReason() {
                const reasonKey = resolveProperty(stage, 'payload', 'retryReason')
                if (reasonKey === undefined) {
                  return 'No reason provided.'
                }
                const reasonLabel = retryReasonChoices.lookup(reasonKey)
                return reasonLabel ? reasonLabel : `code: ${reasonKey}`
              },
              get lookupInProgress() {
                return retryReasonChoices.isLookupInProgress
              },
              get lookupResolved() {
                return retryReasonChoices.isLookupSuccessful
              }
            }
            if (isDefined(resolveProperty(stage, 'payload', 'biometrics', 'photos'))) {
              const parentElementQuery = `table.history tbody:nth-child(${index + 2}) tr.details td.details`
              resolvePhotoAttachments(stage.payload.biometrics.photos, stageWrapper.photos, parentElementQuery)
            }
            return stageWrapper
          })
        })
    }

    this.$onInit = () => {
      this.clipboard = clipboardFactory.createClipboard(`.${this.uniqueId} .clippable`)
      this.clipboard.on('success', (event) => {
        $mdToast.show($mdToast.simple().position('top left').hideDelay(2000).textContent(`Copied: ${event.text}`))
        event.clearSelection()
      })

      if (this.targetUser) {
        const biometricIdentityCheckResolver = (user) => {
          if ('promise' in user) {
            const promise = user.promise('biometricIdentityCheck')
            if (promise) {
              promise.then(this.resolveBiometricIdentityCheck)
            }
          } else if (user.biometricIdentityCheck) {
            this.resolveBiometricIdentityCheck(user.biometricIdentityCheck)
          }
        }

        if (this.targetUser.hasOwnProperty('promise')) {
          this.userResource = this.targetUser
          biometricIdentityCheckResolver(this.userResource)
        } else {
          usersRepository.getById(this.targetUser.id)
            .then((user) => {
              this.userResource = user
              biometricIdentityCheckResolver(this.userResource)
            })
        }
      }
    }

    this.$onDestroy = () => {
      this.clipboard && this.clipboard.destroy()
    }
  }

  get baseURL() {
    return this.$location.protocol() + '://' +
      this.$location.host() +
      ((this.$location.port() === 80 || this.$location.port() === 443) ? '' : (':' + this.$location.port()))
  }

  get identityCheckStatus() {
    return valueOrDefault(resolveProperty(this.userResource, 'identityCheck', 'status'), 'not submitted')
  }

  get biometricIdentityCheckStatus() {
    if (this.targetUser && this.targetUser.phone && this.biometricIdentityCheck) {
      return valueOrDefault(resolveProperty(this.biometricIdentityCheck, 'status'), 'not yet created')
    }
    return 'not yet created'
  }

  get helpMateTopicStatus() {
    const defaultStatus = 'unresolved'
    const checkKnownValue = (candidate) => ['legacy', 'pending', 'verified', 'rejected', 'requested', 'review', 'retry', defaultStatus].includes(candidate) ? candidate : 'unknown'
    const resolvedStatus = (this.biometricIdentityCheck) ?
      checkKnownValue(valueOrDefault(resolveProperty(this.biometricIdentityCheck, 'status'), defaultStatus)) :
      defaultStatus
    return `biometric-identity-check-status-${resolvedStatus}`
  }

  get biometricIdentityCheckStatusChanges() {
    return this.biometricIdentityCheckStages
  }

  get hasBiometricData() {
    return this.biometrics
  }

  get hasPhotos() {
    return this.photos.length > 0
  }

  get hasHistory() {
    return this.biometricIdentityCheckStatusChanges && this.biometricIdentityCheckStatusChanges.length > 0
  }

  get canSendIdEmail() {
    if (!isLoanEnquiryMutable(this.loanEnquiry)) {
      return false
    }
    return resolveProperty(this.targetUser, 'identityCheck', 'status') !== 'verified'
  }

  get canSendConsentEmail() {
    if (!isLoanEnquiryMutable(this.loanEnquiry)) {
      return false
    }
    return resolveProperty(this.targetUser, 'identityCheck', 'status') === 'verified' && !resolveProperty(this.targetUser, 'consent', 'hasAuthorisedCreditCheck')
  }

  stageHasDetails(stage) {
    return valueOrDefault(resolveProperty(stage, 'payload', 'biometrics'), false) ||
      this.stageIsRequesting(stage)
  }

  stageHasPhotos(photos) {
    return photos.length > 0
  }

  stageIsRequesting(stage) {
    return ['requested', 'retry'].includes(stage.status)
  }

  get possibleTransitions() {
    if (this.targetUser && this.targetUser.phone && this.biometricIdentityCheck) {
      const candidateTransitions = TRANSITIONS_FOR_STAGE.get(this.biometricIdentityCheck.status)
      if (!candidateTransitions) {
        return []
      }
      return candidateTransitions.filter((transition) => !this.isBDM() || transition.name === 'request_again')
    }
    return []
  }

  doAction($event, transition) {
    $event.preventDefault()
    $event.stopPropagation()

    const name = transition.name
    const config = this.dynamicDialogService.buildConfig()
      .title(`Biometric identity check transition: ${transition.label}`)
      .definition(transitionStageFormDefinition)
      .actionLabel(transition.label)
      .rulesContext({name})
      .build()
    const model = {
      comment: ''
    }

    return this.dynamicDialogService.processDialog($event, config, model)
      .then((model) => {
        const payload = {
          comment: model.comment
        }
        if (model.retryReason) {
          payload.retryReason = model.retryReason
        }
        return this.biometricIdentityCheck.performAction(`transition/${transition.name}`, {payload})
      })
      .then(this.resolveBiometricIdentityCheck)
  }
}

export default UserIdentityCheckManagementController
