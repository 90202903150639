import linker from './pageMeta.linker'

export default ['$state', '$window', '$timeout', 'tenantInformationService', function($state, $window, $timeout, tenantInformationService) {
  return {
    restrict: 'E',
    link: linker($state, $window, $timeout, tenantInformationService),
    scope: {
      image: '@',
      description: '@',
      title: '@',
      state: '@',
      document: '@',
      page: '=?'
    },
  }
}]

