import template from './sectionAccordion.pug'
import controller from './sectionAccordion.controller'
import './sectionAccordion.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    transclude: {
      'header': 'saHeader',
      'body': 'saBody'
    },
    controllerAs: 'vm',
    scope: {},
    link: (scope, element, attr, ctrls) => ctrls.directiveElement = element,
    bindToController: {
      initialState: '@'
    }
  }
}
