class HelpMatesService {
  /*@ngInject*/
  constructor(contentful, $filter) {
    this.contentful = contentful
    this.$filter = $filter
  }

  getHelpByTopic(topic) {
    if (!this._cachedData) {
      this._cachedData = this._loadHelpMates()
    }
    return this._cachedData.then((data) => {
      return data.find(record => record.id === topic)
    })
  }

  // private

  _loadHelpMates() {
    return this.contentful.entries('content_type=helpmate&limit=200&include=10').then((entry) => {
      return entry.data.items.map((item) => ({
        id: item.fields.name,
        content: this.$filter('fromMarkdown')(item.fields.content),
        placement: item.fields.placement,
        xOffset: item.fields.xOffset,
        yOffset: item.fields.yOffset,
      }))
    })
  }
}

export default HelpMatesService
