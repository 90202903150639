import confirmBankDetailsDialogTemplate from './confirmBankDetailsDialog.pug'
import confirmBankDetailsDialogController from './confirmBankDetailsDialog.controller'

class InvestorSettingsController {
  /*@ngInject*/
  constructor(userService, entityContextService, $mdDialog, $q, $scope) {
    this.name = 'investor-settings'
    this.user = userService.currentUser
    this.scope = $scope
    this.noMobilePhone = !(this.user.phone && this.user.phone.replace(/ /g, '').match(/^(04|[+]614)/))
    this.wasSaved = false

    this.$onInit = () => {
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        if (this.investor) {
          this.investor.promise('users').then((users) => {
            this.isOwner = this.user && users.investorUsers.find((investorUser) => investorUser.role === 'owner' && investorUser.userId === this.user.id)
          })
        }
      })

      const createResponse = (status = 'saved', ...errorMessages) => {
        return {
          status: status,
          errorMessages: errorMessages
        }
      }

      // ***** Definition as lambda to make sure 'this' is bound correctly.
      this.resetInvestorSettings = () => {
        if (this.investor && this.investor.investorSetting) {
          this.investor.investorSetting.reload()
        }
        return $q.resolve(createResponse('cancelled'))
      }

      this.formatBankDetails = (bankDetails) => {
        if (bankDetails) {
          if (bankDetails.bsb) {
            bankDetails.bsb = bankDetails.bsb.replace(/^([0-9][0-9][0-9])([0-9][0-9][0-9])$/, '$1-$2')
          }
          if (bankDetails.accountNumber) {
            bankDetails.accountNumber = bankDetails.accountNumber.replace(/[^0-9]/g, '')
          }
        }
        return bankDetails
      }

      // ***** Definition as lambda to make sure 'this' is bound correctly.
      this.saveInvestorSettings = ($event) => {
        if (this.investor && this.investor.investorSetting) {
          if (this.investor.investorSetting.bankDetails) {
            this.formatBankDetails(this.investor.investorSetting.bankDetails)
          }

          return $mdDialog.show({
            targetEvent: $event,
            template: confirmBankDetailsDialogTemplate({}),
            controllerAs: 'vm',
            locals: {
              investor: this.investor,
              phone: this.user.phone,
              bankDetails: this.investor.investorSetting.bankDetails
            },
            controller: confirmBankDetailsDialogController
          })
        }
        return $q.reject(createResponse('error', 'No investor settings'))
      }
    }
  }

  get isEditable() {
    return this.isOwner && !this.noMobilePhone
  }

  isFormDisabled() {
    return this.scope.isFormDisabled
  }
}

export default InvestorSettingsController
