import controller from './patchForm.controller'

export default () => {
  return {
    controller,
    require: ['form', 'patchForm'],
    restrict: 'A',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      formTracker: '=patchForm',
      form: '=name',
      validationProperty: '='
    }
  }
}
