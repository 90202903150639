import angular from 'angular'

class LoanCalculatorController {
  calculator
  brand
  mode
  version
  calculatorUrl

  cancelOnBrokerChange

  /*@ngInject*/
  constructor($rootScope, $sce, $window, $document, $timeout, featureTogglesService, api, calculatorService, cognitoService) {
    this.$rootScope = $rootScope
    this.$sce = $sce
    this.$window = $window
    this.$document = $document
    this.$timeout = $timeout
    this.featureTogglesService = featureTogglesService
    this.api = api
    this.calculatorService = calculatorService
    this.cognitoService = cognitoService
  }

  $onDestroy() {
    if (this.cancelOnBrokerChange) {
      this.cancelOnBrokerChange()
    }
  }

  async $onInit() {
    await this.featureTogglesService.initialised

    const calculatorUi = await this.api.linkFor('calculatorUi')
    const iframe = angular.element(this.$document.find('#calculator-iframe'))[0]
    if (!iframe || !iframe.contentWindow) {
      return
    }
    iframe.style.display = 'none'

    const calculatorUiURL = new URL(calculatorUi.href)

    this.$window.addEventListener('message', (event) => {
      if (event.origin !== calculatorUiURL.origin) {
        return
      }
      switch(event.data.type) {
        case 'calculatorHeight':
          if (typeof event.data.height === 'number') {
            iframe.style.height = `${event.data.height}px`
          }
          break
        case 'calculatorAuthReady':
          this.cancelOnBrokerChange = this.calculatorService.onBrokerChange((session) => {
            if (!iframe || !iframe.contentWindow) {
              return
            }
            iframe.style.display = 'block'
            iframe.contentWindow.postMessage({
              type: 'brokerAuth',
              tokens: {
                accessToken: session.tokens.accessToken.toString(),
                expiry: session.tokens.accessToken.payload.exp
              }
            }, calculatorUi.href)
          })
          break
        case 'refreshAccessToken':
          this.cognitoService.verifyAccreditationStatus()
          break
        default:
          break
      }
    })

    this.$timeout(() => {
      this.setCalculatorUrl(calculatorUi)
    })
  }

  setCalculatorUrl(calculatorUi) {
    if (!this.calculator || !this.brand) {
      return
    }

    const parameters = new URLSearchParams()
    // Borrower is the default mode so this prevents exposing the mode parameter in public locations.
    if (this.mode && this.mode !== 'borrower') {
      parameters.set('mode', this.mode)
    }
    if (this.version) {
      parameters.set('version', this.version)
    }

    const queryString = parameters.toString()

    this.calculatorUrl = this.$sce.trustAsResourceUrl(
      `${calculatorUi.href}/${this.calculator}/${this.brand}${queryString ? `?${queryString}` : ''}`
    )
  }

  isEnabled() {
    return this.featureTogglesService.can('enableRideshareCalculator')
  }
}

export default LoanCalculatorController
