import moment from 'moment'
import Big from 'big.js'

class EditLoanRequestController {
  /*@ngInject*/
  constructor(ratingsService, fundingSourcesRepository, $filter) {
    this.name = 'edit-loan-request'
    this.ratingsService = ratingsService

    this.fundingSources = []
    fundingSourcesRepository.all().then(data => {
      this.fundingSources = data.fundingSources.map(source => ({label: $filter('asLeadingCaps')(source.name), name: source.name, value: source.id}))
    })

    this.sectors = [
      'Agriculture, Forestry and Fishing',
      'Mining',
      'Manufacturing',
      'Electricity, Gas, Water and Waste Services',
      'Construction',
      'Wholesale Trade',
      'Retail Trade',
      'Accommodation and Food Services',
      'Transport, Postal and Warehousing',
      'Information Media and Telecommunications',
      'Financial and Insurance Services',
      'Rental, Hiring and Real Estate Services',
      'Professional, Scientific and Technical Services',
      'Administrative and Support Services',
      'Public Administration and Safety',
      'Education and Training',
      'Health Care and Social Assistance',
      'Arts and Recreation Services',
      'Other Services'
    ]

    this.categories = [
      'Company', 'Trust', 'Sole Trader', 'Partnership'
    ]

    this.regions = [
      'ACT', 'QLD', 'NSW', 'NT', 'SA', 'TAS', 'VIC', 'WA'
    ]

    this.types = [
      {label: 'Cash flow', value: 'CF'},
      {label: 'Chattel mortgage', value: 'CM'},
      {label: 'Finance lease', value: 'FL'},
      {label: 'Acquisition', value: 'AQ'},
      {label: 'Rent roll', value: 'RR'},
      {label: 'Trail book', value: 'TB'},
      {label: 'Line of Credit', value: 'LC'},
      {label: 'Tools finance', value: 'TF'},
      {label: 'Other', value: 'OT'}
    ]

    this.securityTypes = [
      {label: 'Asset', value: 'AS'},
      {label: 'Second Mortgage', value: 'SM'},
      {label: 'Trail Book', value: 'TB'},
      {label: 'Caveat', value: 'CV'},
      {label: 'Rent Roll', value: 'RR'},
      {label: 'Unsecured', value: 'US'},
    ]

    this.securityRatings = [
      {label: 'E', value: 'E'},
      {label: 'D', value: 'D'},
      {label: 'C', value: 'C'},
      {label: 'B', value: 'B'},
      {label: 'A', value: 'A'},
    ]

    this.candidateOriginations = [
      {label: 'TruePillars', value: 'truepillars'},
      {label: 'Laddr', value: 'laddr'},
      {label: 'RedZed', value: 'redzed'},
      {label: 'Other', value: 'other'}
    ]

    this.$onInit = () => {
      this._loadBorrower()
      this.loanRequest.promise('fundingSource').then((fundingSource) => {
        this.fundingSource = fundingSource.id
      })
    }
  }

  get rateSteps() {
    return this.ratingsService.rateSteps()
  }

  get riskRatings() {
    return this.ratingsService.ratings()
  }

  get riskRating() {
    return this.loanRequest.riskRating
  }

  set riskRating(riskRating) {
    this.loanRequest.riskRating = riskRating
    this.loanRequest.minRate = this.ratingsService.rating(riskRating).minRate
    this.loanRequest.maxRate = this.ratingsService.rating(riskRating).maxRate
    if (!this.loanRequest.rateStep) {
      this.loanRequest.rateStep = this.ratingsService.rating(riskRating).rateStep
    }
  }

  get fundingSource() {
    return this.loanRequest.fundingSourceId
  }

  set fundingSource(fundingSourceId) {
    if (fundingSourceId) { this.loanRequest.fundingSourceId = fundingSourceId }

    if (this.isAuction(this.loanRequest)) {
      this.loanRequest.investorMarginRate = 2.0
    } else {
      this.loanRequest.investorMarginRate = 0.0
    }
  }

  get rate() {
    return this.loanRequest.minRate
  }

  set rate(rate) {
    this.loanRequest.minRate = rate
    this.loanRequest.maxRate = rate
  }

  get rateStep() {
    return this.loanRequest.rateStep
  }

  get tradingSince() {
    return this.borrower ? moment(this.borrower.tradingSince).year() : null
  }

  set tradingSince(value) {
    this.borrower.tradingSince = value + '-01-01'
  }

  get isValid() {
    return this._validRates(this.loanRequest.minRate, this.loanRequest.maxRate)
      && this._validStep(this.loanRequest.minRate, this.loanRequest.maxRate, this.loanRequest.rateStep)
  }

  isAuction(loanRequest) {
    const match = this.fundingSources?.filter(e => e.value === +(loanRequest?.fundingSourceId))
    return Array.isArray(match) && match[0]?.name === 'auction'
  }

  save() {
    return this.loanRequest.save().then((loanRequest) => {
      this.borrower.save()
    })
  }

  approve() {
    return this.save().then(() => this.loanRequest.performAction('approve', {}))
  }

  // private

  _loadBorrower() {
    this.loanRequest.promise('borrower').then((borrower) => this.borrower = borrower)
  }

  _validRates(minRate, maxRate) {
    return minRate && maxRate && (new Big(minRate).lte(new Big(maxRate)))
  }

  _validStep(minRate, maxRate, step) {
    return minRate && maxRate && step && ((new Big(maxRate).minus(new Big(minRate))).mod(new Big(step)).round(1).eq(new Big(0)))
  }
}

export default EditLoanRequestController
