import angular from 'angular'

export default ($state, $window, $timeout, tenantInformationService) => {
  const _resolveBrand = (action) => {
    tenantInformationService.getElement('name.short').then((response) => {
      action(response)
    })
  }

  const _setStateTitle = (title) => {
    if (typeof $state.$current.data === 'undefined') {
      $state.$current.data = {}
    }
    $state.$current.data.title = title
  }

  const _addSiteNameForBookmarks = (brand, title) => (title && title.indexOf(brand) < 0) ? `${brand} - ${title}` : title

  const _setDocumentTitle = (title) => {
    _resolveBrand((brand) => $window.document.title = _addSiteNameForBookmarks(brand, title))
  }

  const _setMetaValue = (key, value) => {
    const meta = $window.document.querySelector(key)
    if (meta) {
      angular.element(meta).attr('content', value)
    }
  }

  const _setMetaTitle = (title) => {
    tenantInformationService.getElement('name.short').then((shortName) => {
      const fullTitle = (title && title.indexOf(shortName) < 0) ? `${shortName} - ${title}` : title
      _setMetaValue(`meta[name='title']`, fullTitle)
      _setMetaValue(`meta[property='og:title']`, fullTitle)
      _setMetaValue(`meta[itemprop='name']`, fullTitle)
    })
  }

  const _setMetaImage = (image) => {
    if (image.indexOf('//') === 0) {
      _setMetaValue(`meta[property='og:image']`, 'http:' + image)
      _setMetaValue(`meta[property='og:image:secure_url']`, 'https:' + image)
      return
    }

    let imageUrl
    if (image.indexOf('http') === 0) {
      imageUrl = image
    } else if (image.indexOf('/') === 0) {
      imageUrl = _rootUrl() + image
    } else {
      imageUrl = _rootUrl() + '/' + image
    }
    _setMetaValue(imageUrl.indexOf('https') === 0 ? `meta[property='og:image:secure_url']` : `meta[property='og:image']`, imageUrl)
  }

  const _setMetaDescription = (description) => {
    const descriptionText = angular.element('<div>' + description + '</div>').text()
    _setMetaValue(`meta[name='description']`, descriptionText)
    _setMetaValue(`meta[property='og:description']`, descriptionText)
  }

  const _setMetaUrl = (url) => {
    _setMetaValue(`meta[property='og:url']`, url)
  }

  const _rootUrl = () => {
    return $window.location.protocol + '//' + $window.location.host
  }

  return function link(scope, elem) {
    _setMetaTitle('')
    _setMetaDescription('')

    const text = (elem.text() || '').trim()
    if (text) {
      _setDocumentTitle(text)
      _setStateTitle(text)
    }

    if (scope.page) {
      _setDocumentTitle(scope.page.title)
      _setStateTitle(scope.page.title)
      _setMetaTitle(scope.page.title)
      _setMetaDescription(scope.page.description)

      if (scope.page.unfurlImage) {
        try {
          _setMetaImage(scope.page.unfurlImage.fields.file.url)
        } catch (error) {
          error.message = 'missing unfurl image: ' + error.message
          throw error
        }
      }
    }

    if (scope.title) {
      _setMetaTitle(scope.title)
    }

    if (scope.description) {
      _setMetaDescription(scope.description)
    }

    if (scope.image) {
      _setMetaImage(scope.image)
    }

    if (scope.state) {
      _setStateTitle(scope.state)
    }

    if (scope.document) {
      _setDocumentTitle(scope.document)
    }

    _setMetaUrl($window.location.href)
    elem.text('')
  }
}
