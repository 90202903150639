import angular from 'angular'

import GreenidLoader from './greenidLoader'
import GreenidService from './greenid.service'
import GreenidVerifyService from './greenidVerify.service'

export default angular.module('app.services.greenid', [])
  .service('greenidLoader', GreenidLoader)
  .service('greenidService', GreenidService)
  .service('greenidVerifyService', GreenidVerifyService)
