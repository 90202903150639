import resolveProperty from '../../../../../utils/resolveProperty'

const resolveAmitReportContent = (financialYear, contentful, logger, makeReport) => {
  const reportContentLayout = `investor-report-AMIT-${financialYear}`
  const errorContext = () => `AMIT report definition in CMS with name '${reportContentLayout}'`
  const notificationMessage = (kind) => `Report not available. (${kind})`
  contentful.entries(`content_type=pdfMakerReport&fields.name=${reportContentLayout}&limit=200&include=10`)
    .then((layoutDefinitionContent) => {
      const layoutDefinition = resolveProperty(layoutDefinitionContent, 'data', 'items', 0)
      if (layoutDefinition === undefined) {
        logger.error(`Can't find ${errorContext()}.`)
        logger.notify(notificationMessage('no-definition'))
      } else {
        const parts = resolveProperty(layoutDefinition, 'fields', 'parts')
        if (parts === undefined) {
          logger.error(`Report parts are undefined for ${errorContext()}.`)
          logger.notify(notificationMessage('missing-parts-definition'))
        } else if (!Array.isArray(parts)) {
          logger.error(`Report parts are missing for ${errorContext()}.`)
          logger.notify(notificationMessage('no-parts-defined'))
        } else {
          const reportParts = parts.map((part) => part.fields)
          makeReport(reportParts)
        }
      }
    }, (error) => {
      logger.error(`ERROR: Failed to retrieve AMIT report layout:`, error)
      logger.notify('Report not available. (cms-layout-reject)')
    })
}

export default resolveAmitReportContent
