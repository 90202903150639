import angular from 'angular'
import 'angular-ui-router'

import emailVerifiedComponent from './emailVerified.component'

export default angular.module('app.pages.emailVerified', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('emailVerified', {
        url: '/email-verified',
        template: '<email-verified />',
        data: {
          requireAuth: true
        }
      })
  }])
  .directive('emailVerified', emailVerifiedComponent)
