import {asURLEmail, asURLPhone} from '../../utils/asURL'
import isDefined from '../../utils/isDefined'
import resolveProperty from '../../utils/resolveProperty'

const isPresent = (candidate) => isDefined(candidate) && candidate.toString().trim().length > 0

class ReferrerAssistanceController {
  /*@ngInject*/
  constructor() {
    this.name = 'referrerAssistance'
    this.$onInit = () => {
    }
  }

  get referrer() {
    const {display, name, company, phone, email} = this._processMetadata(this.metadata)
    return {
      name: name,
      company: company,
      phone: {
        URL: asURLPhone(phone),
        label: phone
      },
      email: {
        URL: asURLEmail(email),
        label: email
      },
      hasCompany: () => isPresent(company),
      hasPhone: () => isPresent(phone),
      hasEmail: () => isPresent(email),
      display: () => display
    }
  }

  // private

  _processMetadata(metadata) {
    return resolveProperty(metadata, 'loanEnquiry', 'purpose') === 'asset' &&
    resolveProperty(metadata, 'loanEnquiry', 'assetRideShareElectricVehicle') === 'yes' ?
      {
        display: true,
        name: resolveProperty(metadata, 'loanEnquiry', 'referrer', 'name'),
        company: resolveProperty(metadata, 'loanEnquiry', 'referrer', 'company'),
        phone: resolveProperty(metadata, 'loanEnquiry', 'referrer', 'phone'),
        email: resolveProperty(metadata, 'loanEnquiry', 'referrer', 'email')
      } :
      {
        display: false
      }
  }

}

export default ReferrerAssistanceController
