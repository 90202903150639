import template from './cancelLoanRequest.pug'
import controller from './cancelLoanRequest.controller'
import './cancelLoanRequest.scss'

export default () => {
  return {
    template: template({}),
    controller: controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loanRequest: '=',
      completion: '=',
    },
    bindToController: true
  }
}
