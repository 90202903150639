import angular from 'angular'
import 'angular-ui-router'
import identityModule from './identity/identity'
import creditReportsModule from './creditReports/creditReports'
import editLoanRequestModule from './editLoanRequest/editLoanRequest'
import businessProfileModule from './businessProfile/businessProfile'
import auctionDetailsModule from './auctionDetails/auctionDetails'

import loanRequestDetailsComponent from './loanRequestDetails.component'

export default angular.module('app.pages.admin.loanRequestDetails', [
  'ui.router',
  editLoanRequestModule.name,
  identityModule.name,
  creditReportsModule.name,
  businessProfileModule.name,
  auctionDetailsModule.name
])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('adminLoanRequest', {
        url: '/admin/loan-requests/:id',
        template: '<admin-loan-request-details/>',
        replace: true,
        data: {
          requireAuth: true
        }
      })
      .state('adminLoanRequest.edit', {
        url: '/edit',
        template: '<admin-edit-loan-request loan-request="vm.loanRequest"/>',
      })
      .state('adminLoanRequest.identity', {
        url: '/identity',
        template: '<admin-loan-request-identity loan-request="vm.loanRequest"/>',
      })
      .state('adminLoanRequest.reports', {
        url: '/reports',
        template: '<admin-loan-request-credit-reports loan-request="vm.loanRequest"/>',
      })
      .state('adminLoanRequest.profile', {
        url: '/profile',
        template: '<admin-loan-request-business-profile loan-request="vm.loanRequest"/>',
      })
      .state('adminLoanRequest.auction', {
        url: '/auction',
        template: '<admin-auction-details loan-request="vm.loanRequest"/>',
      })
  }])
  .directive('adminLoanRequestDetails', loanRequestDetailsComponent)
