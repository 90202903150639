class PolicyService {
  /*@ngInject*/
  constructor(router, userService, entityContextService) {
    this.router = router
    this.userService = userService
    this.entityContextService = entityContextService
  }

  init(scope) {
    scope.$on('$stateChangeSuccess', (e, to, toParams) => {
      delete this.userReloaded
    })

    scope.$on('$stateChangeStart', (e, to, toParams, from, fromParams, options) => {
      if (this.userService.isLoggedIn()) {
        if (!this.userReloaded) {
          e.preventDefault()
          this.userService.renewToken()
            .then(() => this.userService.currentUserPromise.then(() => this._navigate(to, toParams, options)))
            .catch(() => this._logout(to, toParams, options))
        }
      } else {
        if ((to.data && to.data.requireAuth) || this.userService.hasValidToken()) {
          e.preventDefault()
          this.userService.login()
            .then(() => this._navigate(to, toParams, options))
            .catch(() => this._logout(to, toParams, options))
        }
      }
    })
  }

  _navigate(to, toParams, options) {
    this.userReloaded = true
    this.router.go(to, toParams, options)
  }

  _logout(to, toParams, options) {
    this.userService.logout().then(() => {
      this.entityContextService.logout()
      if (to.data && to.data.requireAuth) {
        this.router.go('home')
      } else {
        this._navigate(to, toParams, options)
      }
    })
  }
}

export default PolicyService
