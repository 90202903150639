class GreenidLoader {
  /*@ngInject*/
  constructor($q, $document, environment) {
    this.$q = $q
    this.$document = $document
    this.environment = environment
  }

  load() {
    const defer = this.$q.defer()
    this._loadCss(this._greenidCssUrl())
    this._loadScript(this._greenidConfigUrl(), () => this._loadScript(this._greenidUiUrl(), () => defer.resolve()))
    return defer.promise
  }

  _greenidUiUrl() {
    return `${this.environment.GREENID_URL}/df/javascripts/greenidui.min.js`
  }

  _greenidConfigUrl() {
    return `${this.environment.GREENID_URL}/df/javascripts/greenidConfig.js`
  }

  _greenidCssUrl() {
    return `${this.environment.GREENID_URL}/df/assets/stylesheets/greenid.css`
  }

  _loadCss(src, onload) {
    const link = this.$document[0].createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = src
    link.onload = onload
    this.$document[0].head.appendChild(link)
  }

  _loadScript(src, onload) {
    const script = this.$document[0].createElement('script')
    script.src = src
    script.onload = onload
    this.$document[0].head.appendChild(script)
  }
}

export default GreenidLoader
