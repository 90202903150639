import template from './summary.pug'
import controller from './summary.controller.js'
import './summary.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '=',
      resourceCache: '='
    }
  }
}
