import angular from 'angular'
import 'angular-ui-router'

import keyInfoModule from './keyInfo/keyInfo'
import businessProfileModule from './businessProfile/businessProfile'
import financialSummaryDisplayModule from './financialSummaryDisplay/financialSummaryDisplay'
import creditScoreModule from './creditScore/creditScore'
import loanHistoryModule from './loanHistory/loanHistory'
import auctionPaneModule from './auctionPane/auctionPane'
import listingsPaneModule from './listingsPane/listingsPane'
import positionPaneModule from './positionPane/positionPane'

import loanRequestDetailsComponent from './loanRequestDetails.component'

export default angular.module('app.pages.marketplace.loanRequestDetails', [
  'ui.router',
  keyInfoModule.name,
  businessProfileModule.name,
  financialSummaryDisplayModule.name,
  creditScoreModule.name,
  loanHistoryModule.name,
  auctionPaneModule.name,
  listingsPaneModule.name,
  positionPaneModule.name
])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('loanRequestDetails', {
        url: '/loan-requests/:id',
        template: '<loan-request-details show-financials="showFinancials"></loan-request-details>',
        replace: true,
        data: {
          // to be filled in by the controller
          title: null,
          resumeOnLogin: true
        }
      })
      .state('loanRequestDetails.keyInfo', {
        url: '/key-information',
        template: '<key-info loan-request="vm.loanRequest"/>',
        tabName: 'Marketplace'
      })
      .state('loanRequestDetails.businessProfile', {
        url: '/business-profile',
        template: '<business-profile loan-request="vm.loanRequest" />',
        tabName: 'Marketplace'
      })
      .state('loanRequestDetails.creditScore', {
        url: '/credit-score',
        template: '<credit-score loan-request="vm.loanRequest" />',
        tabName: 'Marketplace'
      })
      .state('loanRequestDetails.financialSummary', {
        url: '/financial-summary',
        template: '<financial-summary-display loan-request="vm.loanRequest" />',
        tabName: 'Marketplace'
      })
      .state('loanRequestDetails.auction', {
        url: '/auction',
        template: '<auction-pane class="md-whiteframe-3dp" auction="vm.auction" />' +
        '<position-pane loan-request="vm.loanRequest" loan-request-navigator="vm.loanRequestNavigator"/>',
        tabName: 'Marketplace'
      })
      .state('loanRequestDetails.listings', {
        url: '/exchange',
        template: '<listings-pane class="md-whiteframe-3dp" loan-request="vm.loanRequest" />' +
        '<position-pane loan-request="vm.loanRequest" loan-request-navigator="vm.loanRequestNavigator"/>',
        tabName: 'Marketplace'
      })
      .state('loanRequestDetails.loanHistory', {
        url: '/history',
        template: '<loan-history loan-request="vm.loanRequest" />',
        tabName: 'Marketplace'
      })
  }])
  .directive('loanRequestDetails', loanRequestDetailsComponent)
