import template from './navbar.pug'
import controller from './navbar.controller'
import './navbar.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: true
  }
}
