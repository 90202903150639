import resolveProperty from '../../utils/resolveProperty'
import isDefined from '../../utils/isDefined'

const makeNavigationFieldName = (name) => `navigation-definition-${name}`
const NAVIGATION_DEFINITION_FIELD_ACTIONS = makeNavigationFieldName('actions')
const NAVIGATION_DEFINITION_FIELD_DESKTOP = makeNavigationFieldName('desktop')
const NAVIGATION_DEFINITION_FIELD_AUTH = makeNavigationFieldName('auth')
const NAVIGATION_DEFINITION_FIELD_MOBILE = makeNavigationFieldName('mobile')
const NAVIGATION_REDIRECTS = 'navigation-redirects'

const NAVIGATION_DEFINITION_FIELD_NAMES = [
  NAVIGATION_DEFINITION_FIELD_ACTIONS,
  NAVIGATION_DEFINITION_FIELD_DESKTOP,
  NAVIGATION_DEFINITION_FIELD_MOBILE,
  NAVIGATION_DEFINITION_FIELD_AUTH,
  NAVIGATION_REDIRECTS
]
const NAVIGATION_DEFINITION_QUERY = NAVIGATION_DEFINITION_FIELD_NAMES.join(',')


const resolveDefinitions = (validations, result, logger) => {
  const definitions = resolveProperty(result, 'data', 'items')
  return (validations.every(({predicate, message}) => {
    const {isValid, metadata} = predicate(definitions)
    if (!isValid) {
      const errorMessage = `Bad navigation definition (${message(metadata)}) - please try again later.`
      logger.error(errorMessage)
    }
    return isValid
  }))
}


const resolvePartitionedRedirects = (partitionName, map) => {
  const partition = map.get(partitionName)
  if (isDefined(partition)) {
    return partition
  }
  const newPartition = new Map()
  map.set(partitionName, newPartition)
  return newPartition
}


const indexNotFound = (candidateIndex) => candidateIndex !== -1


class NavigationService {
  /*@ngInject*/
  constructor($mdSidenav, router, contentful, loggerService, $q) {
    this.$mdSidenav = $mdSidenav
    this.router = router
    this.contentful = contentful
    this.$q = $q

    this.navigationDefinitions = new Map()
    this.navigationLoadedPromise = this.$q.defer().promise
    this.navigationLoaded = false
    this.navigationRedirects = new Map()

    this.navigationValidationsAndTransformations = [
      {
        // Check that the definitions have been returned from the CMS.
        predicate: (definitions) => ({isValid: isDefined(definitions)}),
        message: () => 'missing'
      },
      {
        // Check that the returned definitions are all arrays.
        predicate: (definitions) => ({isValid: Array.isArray(definitions)}),
        message: () => 'not an array'
      },
      {
        // Extract and assign each of the navigation structures to a map entry for later retrieval, checking for missing structures.
        predicate: (definitions) => {
          definitions.forEach((definition) => {
            const name = resolveProperty(definition, 'fields', 'name')
            const payload = resolveProperty(definition, 'fields', 'data')
            const index = NAVIGATION_DEFINITION_FIELD_NAMES.findIndex((candidateName) => candidateName === name)
            if (indexNotFound(index)) {
              this.navigationDefinitions.set(name, payload)
              // Process the redirects into a map structure for later retrieval.
              if (name === NAVIGATION_REDIRECTS) {
                if (isDefined(payload)) {
                  Object.getOwnPropertyNames(payload).forEach((redirectPartitionName) => {
                    const partitionedRedirects = resolvePartitionedRedirects(redirectPartitionName, this.navigationRedirects)
                    payload[redirectPartitionName].forEach((definition) => {
                      partitionedRedirects.set(definition.deprecated, definition.current)
                    })
                  })
                }
              }
            }
          })
          if (this.navigationDefinitions.size !== NAVIGATION_DEFINITION_FIELD_NAMES.length) {
            const missingDefinitions = new Set(NAVIGATION_DEFINITION_FIELD_NAMES)
            Array.from(this.navigationDefinitions.keys()).forEach((key) => {
              missingDefinitions.delete(key)
            })
            return {isValid: false, metadata: Array.from(missingDefinitions.keys())}
          }
          return {isValid: true, metadata: []}
        },
        message: (metadata) => `Missing definition, by key [${metadata.join(', ')}]`
      }
    ]

    this.logger = loggerService.makeLogger().enableNewRelic(true)
  }


  init() {
    this.navigationLoadedPromise = this.contentful.entries(`content_type=genericJson&fields.name[in]=${NAVIGATION_DEFINITION_QUERY}&limit=200&include=10`)
      .then(
        (result) => {
          const resolution = resolveDefinitions(this.navigationValidationsAndTransformations, result, this.logger)
          this.navigationLoaded = true
          return resolution
        },
        (error) => {
          this.logger.error(error)
          return this.navigationLoaded
        })
    return this.navigationLoadedPromise
  }

  definitionActions() {
    return this.navigationDefinitions.get(NAVIGATION_DEFINITION_FIELD_ACTIONS)
  }

  definitionDesktop() {
    return this.navigationDefinitions.get(NAVIGATION_DEFINITION_FIELD_DESKTOP)
  }

  definitionMobile() {
    return this.navigationDefinitions.get(NAVIGATION_DEFINITION_FIELD_MOBILE)
  }

  definitionAuth() {
    return this.navigationDefinitions.get(NAVIGATION_DEFINITION_FIELD_AUTH)
  }

  redirectsFor(redirectPartitionName) {
    const redirects = this.navigationRedirects.get(redirectPartitionName)
    return isDefined(redirects) ? redirects : new Map()
  }

  get isLoaded() {
    return this.navigationLoaded
  }

  get whenLoaded() {
    return this.navigationLoadedPromise
  }

  showSideNav() {
    return this.$mdSidenav('left').open()
  }

  hideSideNav() {
    return this.$mdSidenav('left').close()
  }
}

export default NavigationService
