import controller from './investorSignup.controller.js'

export default () => {
  return {
    template: '',
    controller,
    restrict: 'E',
    scope: {},
  }
}
