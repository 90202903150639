export default ($state) => {
  return function link(scope, elem) {
    scope.$watch((elem.attr('value'), () => {
        if (typeof $state.$current.data === 'undefined') {
          $state.$current.data = {}
        }
        $state.$current.data.entityName = elem.attr('value') || ''
      })
    )
  }
}
