export default () => {
  return {
    restrict: 'E',
    require: ['^?form', '?^inputContainer', '?^formRepeatSectionTransclusion'],
    link: function(scope, el, attr, ctrls) {
      let [form, inputContainer, formRepeatSectionTransclusion] = ctrls
      if(form && inputContainer) {
        if(formRepeatSectionTransclusion) {
          attr.$set('name', `${formRepeatSectionTransclusion.transcludedName}-${attr.name}`)
        }
        inputContainer.formField = form[attr.name]
        inputContainer.max = attr.max
        inputContainer.min = attr.min
      }
    }
  }
}
