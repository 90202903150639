import template from './videoDialog.pug'
import controller from './videoDialog.controller'
import './videoDialog.scss'

export default ['$mdDialog', ($mdDialog) => {
  return {
    restrict: 'A',
    scope: true,
    link: function link(scope, element, attrs) {
      element.on('click', () => {
        $mdDialog.show({
          template: template({}),
          controller,
          controllerAs: 'vm',
          bindToController: true,
          locals: {
            videoName: attrs.videoDialog
          },
          clickOutsideToClose: true
        })
      })
    }
  }
}]
