import angular from 'angular'

import createInvestmentProductModule from './createInvestmentProduct/createInvestmentProduct'
import updateInvestmentProductModule from './updateInvestmentProduct/updateInvestmentProduct'
import amendInvestmentProductRedemptionRequestModule from './amendInvestmentProductRedemptionRequest/amendInvestmentProductRedemptionRequest'
import investmentProductsComponent from './investmentProducts.component'

export default angular.module('app.pages.admin.adminDashboard.investmentProducts', [
  createInvestmentProductModule.name,
  updateInvestmentProductModule.name,
  amendInvestmentProductRedemptionRequestModule.name
])
  .directive('adminInvestmentProducts', investmentProductsComponent)
