// Heavily influenced by Szymon Kosno's implementation
// https://medium.com/@skosno/server-error-handing-in-angularjs-forms-c5fd38ccf0fc
import angular from 'angular'

import apiFormComponent from './apiForm.component'
import inputComponent from './input.component'

export default angular.module('app.common.apiForm', [])
  .directive('apiForm', apiFormComponent)
  .directive('input', inputComponent)
