import template from './userIdentityCheckManagement.pug'
import controller from './userIdentityCheckManagement.controller'
import './userIdentityCheckManagement.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '=',
      targetUser: '=',
      authToken: '='
    }
  }
}
