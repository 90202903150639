import template from './updateAuction.pug'
import controller from './updateAuction.controller'
import './updateAuction.scss'

export default () => {
  return {
    template: template({}),
    controller: controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      auction: '=',
      completion: '=',
    },
    bindToController: true
  }
}
