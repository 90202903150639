import template from './brokerAuth.pug'
import controller from './brokerAuth.controller'
import './brokerAuth.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    transclude: true,
    controllerAs: 'vm',
    scope: {
      completionHandler: '='
    },
    bindToController: {
      caption: '@',
      titleContentId: '@?',
      textContentId: '@?',
      kycTitleContentId: '@?',
      kycTextContentId: '@?',
    }
  }
}
