import moment from 'moment'

class CreateFinalDebitController {
  /*@ngInject*/
  constructor(directDebitsRepository) {
    this.name = 'create-final-debit'
    this.directDebitsRepository = directDebitsRepository

    this.today = moment().startOf('day').toDate()

    this.$onInit = () => {
      this.repaymentDate = this.debit.dueDate
    }
  }

  confirmAction() {
    return this._create()
      .then((fee) => this.completion.resolve(fee))
      .catch((error) => this.completion.reject(error))
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  // private

  _create() {
    const debit = Object.assign({}, this.debit)
    debit.dueDate = moment(this.debit.dueDate).format('YYYY-MM-DD')
    return this.directDebitsRepository.create(debit)
  }
}

export default CreateFinalDebitController
