export default ['$rootScope', ($rootScope) => {
  const scope = $rootScope.$new(false)
  scope.update = new Date().valueOf()
  const state = new Map()
  return {
    notify: () => scope.update = new Date().valueOf(),
    onNotify: (func) => scope.$watch('update', func),
    set: (key, data) => state.set(key, data),
    get: (key) => state.get(key),
    has: (key) => state.has(key),
    delete: (key) => state.delete(key)
  }
}]
