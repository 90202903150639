export default ($provide) => {
  $provide.decorator('$exceptionHandler', ['$delegate', '$window', ($delegate, $window) => {
    return (exception, cause) => {
      $delegate(exception, cause)

      if ($window.NREUM) {
        $window.NREUM.noticeError(exception)
      }
    }
  }])
}
