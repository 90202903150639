import moment from 'moment/moment'

class ReconciliationController {
  /*@ngInject*/
  constructor($mdToast, csvService, reportsRepository) {
    this.name = 'reconciliation'
    this.$mdToast = $mdToast
    this.csvService = csvService
    this.reportsRepository = reportsRepository
    this.reportPeriods = [
      {key: 'CURRENT_MONTH', label: 'Current month'},
      {key: 'LAST_MONTH', label: 'Last month'},
      {key: 'CURRENT_FINANCIAL_YEAR', label: 'Current financial year'},
      {key: 'LAST_FINANCIAL_YEAR', label: 'Last financial year'},
      {key: 'CUSTOM', label: 'Custom'}
    ]
    this.reportPeriod = 'CURRENT_FINANCIAL_YEAR'
    this._calculateReportFromAndToDate()
  }

  downloadCsv() {
    if (!this.prepareCsv) {
      this.prepareCsv = true
      this._calculateReportFromAndToDate()
      this.reportsRepository.getById(`reconciliation?fromDate=${this._formatDate(this.reportPeriodFromDate)}&toDate=${this._formatDate(this.reportPeriodToDate)}`).then((data) => {
        this._createAndDownloadCsv(this.reportPeriodFromDate, this.reportPeriodToDate, data.records)
      }).catch(() => {
        this._toast('Error: loan notes request failed, please contact TruePillars.')
      }).finally(() => this.prepareCsv = false)
    }
  }

  _createAndDownloadCsv(fromDate, toDate, records) {
    const rows = records.map(entry => {
      let row = {}
      row['Date'] = entry.date
      row['Investor Registered'] = entry.investors.registered
      row['Investor Qualified'] = entry.investors.qualified
      row['Investor Available Funds'] = entry.investors.availableFunds

      row['Pooled Invested Funds'] = entry.investors.ip.investedFunds
      row['Pooled Interest Received'] = entry.investors.ip.interestReceived
      row['Pooled Interest Reinvested'] = entry.investors.ip.interestReinvested
      row['Pooled Tax Withholding'] = entry.investors.ip.taxWithholding
      row['Pooled Fees'] = entry.investors.ip.fees

      row['P2P Invested Funds'] = entry.investors.p2p.investedFunds
      row['P2P Interest Received'] = entry.investors.p2p.interestReceived
      row['P2P Tax Withholding'] = entry.investors.p2p.taxWithholding
      row['P2P Fees'] = entry.investors.p2p.fees

      row['Bpay Payment'] = entry.cash.bpayPayment
      row['Withdrawal Outstanding'] = entry.cash.withdrawalOutstanding
      row['Withdrawal Requested'] = entry.cash.withdrawalRequested
      row['Withdrawal Completed'] = entry.cash.withdrawalCompleted

      row['Auction Loans Principal Issued'] = entry.loans.auction.principalIssued
      row['Auction Loans Principal Returned'] = entry.loans.auction.principalReturned
      row['Auction Loans Principal Lost'] = entry.loans.auction.principalLost
      row['Auction Loans Interest Paid'] = entry.loans.auction.interestPaid
      row['Auction Loans Margin Collected'] = entry.loans.auction.marginCollected
      row['Auction Loans Standard Fees Collected'] = entry.loans.auction.standardFeesCollected
      row['Auction Loans Additional Fees Collected'] = entry.loans.auction.additionalFeesCollected
      row['Auction Loans Gst Collected'] = entry.loans.auction.gstCollected

      row['Pooled Loans Principal Issued'] = entry.loans.pooled.principalIssued
      row['Pooled Loans Principal Returned'] = entry.loans.pooled.principalReturned
      row['Pooled Loans Principal Lost'] = entry.loans.pooled.principalLost
      row['Pooled Loans Interest Paid'] = entry.loans.pooled.interestPaid
      row['Pooled Loans Margin Collected'] = entry.loans.pooled.marginCollected
      row['Pooled Loans Standard Fees Collected'] = entry.loans.pooled.standardFeesCollected
      row['Pooled Loans Additional Fees Collected'] = entry.loans.pooled.additionalFeesCollected
      row['Pooled Loans Gst Collected'] = entry.loans.pooled.gstCollected

      row['Pool A Loans Principal Issued'] = entry.loans.poolA.principalIssued
      row['Pool A Loans Principal Returned'] = entry.loans.poolA.principalReturned
      row['Pool A Loans Principal Lost'] = entry.loans.poolA.principalLost
      row['Pool A Loans Interest Paid'] = entry.loans.poolA.interestPaid
      row['Pool A Loans Margin Collected'] = entry.loans.poolA.marginCollected
      row['Pool A Loans Standard Fees Collected'] = entry.loans.poolA.standardFeesCollected
      row['Pool A Loans Additional Fees Collected'] = entry.loans.poolA.additionalFeesCollected
      row['Pool A Loans Gst Collected'] = entry.loans.poolA.gstCollected

      row['Pool B Loans Principal Issued'] = entry.loans.poolB.principalIssued
      row['Pool B Loans Principal Returned'] = entry.loans.poolB.principalReturned
      row['Pool B Loans Principal Lost'] = entry.loans.poolB.principalLost
      row['Pool B Loans Interest Paid'] = entry.loans.poolB.interestPaid
      row['Pool B Loans Margin Collected'] = entry.loans.poolB.marginCollected
      row['Pool B Loans Standard Fees Collected'] = entry.loans.poolB.standardFeesCollected
      row['Pool B Loans Additional Fees Collected'] = entry.loans.poolB.additionalFeesCollected
      row['Pool B Loans Gst Collected'] = entry.loans.poolB.gstCollected

      row['Pool C Loans Principal Issued'] = entry.loans.poolC.principalIssued
      row['Pool C Loans Principal Returned'] = entry.loans.poolC.principalReturned
      row['Pool C Loans Principal Lost'] = entry.loans.poolC.principalLost
      row['Pool C Loans Interest Paid'] = entry.loans.poolC.interestPaid
      row['Pool C Loans Margin Collected'] = entry.loans.poolC.marginCollected
      row['Pool C Loans Standard Fees Collected'] = entry.loans.poolC.standardFeesCollected
      row['Pool C Loans Additional Fees Collected'] = entry.loans.poolC.additionalFeesCollected
      row['Pool C Loans Gst Collected'] = entry.loans.poolC.gstCollected

      row['Pool LF Loans Principal Issued'] = entry.loans.poolLF.principalIssued
      row['Pool LF Loans Principal Returned'] = entry.loans.poolLF.principalReturned
      row['Pool LF Loans Principal Lost'] = entry.loans.poolLF.principalLost
      row['Pool LF Loans Interest Paid'] = entry.loans.poolLF.interestPaid
      row['Pool LF Loans Margin Collected'] = entry.loans.poolLF.marginCollected
      row['Pool LF Loans Standard Fees Collected'] = entry.loans.poolLF.standardFeesCollected
      row['Pool LF Loans Additional Fees Collected'] = entry.loans.poolLF.additionalFeesCollected
      row['Pool LF Loans Gst Collected'] = entry.loans.poolLF.gstCollected

      row['Pool TPL Loans Principal Issued'] = entry.loans.poolTPL.principalIssued
      row['Pool TPL Loans Principal Returned'] = entry.loans.poolTPL.principalReturned
      row['Pool TPL Loans Principal Lost'] = entry.loans.poolTPL.principalLost
      row['Pool TPL Loans Interest Paid'] = entry.loans.poolTPL.interestPaid
      row['Pool TPL Loans Margin Collected'] = entry.loans.poolTPL.marginCollected
      row['Pool TPL Loans Standard Fees Collected'] = entry.loans.poolTPL.standardFeesCollected
      row['Pool TPL Loans Additional Fees Collected'] = entry.loans.poolTPL.additionalFeesCollected
      row['Pool TPL Loans Gst Collected'] = entry.loans.poolTPL.gstCollected

      row['Carnbrea Loans Principal Issued'] = entry.loans.carnbrea.principalIssued
      row['Carnbrea Loans Principal Returned'] = entry.loans.carnbrea.principalReturned
      row['Carnbrea Loans Principal Lost'] = entry.loans.carnbrea.principalLost
      row['Carnbrea Loans Interest Paid'] = entry.loans.carnbrea.interestPaid
      row['Carnbrea Loans Margin Collected'] = entry.loans.carnbrea.marginCollected
      row['Carnbrea Loans Standard Fees Collected'] = entry.loans.carnbrea.standardFeesCollected
      row['Carnbrea Loans Additional Fees Collected'] = entry.loans.carnbrea.additionalFeesCollected
      row['Carnbrea Loans Gst Collected'] = entry.loans.carnbrea.gstCollected

      row['Other Loans Principal Issued'] = entry.loans.other.principalIssued
      row['Other Loans Principal Returned'] = entry.loans.other.principalReturned
      row['Other Loans Principal Lost'] = entry.loans.other.principalLost
      row['Other Loans Interest Paid'] = entry.loans.other.interestPaid
      row['Other Loans Margin Collected'] = entry.loans.other.marginCollected
      row['Other Loans Standard Fees Collected'] = entry.loans.other.standardFeesCollected
      row['Other Loans Additional Fees Collected'] = entry.loans.other.additionalFeesCollected
      row['Other Loans Gst Collected'] = entry.loans.other.gstCollected
      return row
    })
    this.csvService.createAndDownload('Reconciliation-' + this._formatDate(fromDate, 'YYYYMMDD') + '-' + this._formatDate(toDate, 'YYYYMMDD') + '.csv', rows)
  }

  _calculateReportFromAndToDate() {
    if (this.reportPeriod === 'CURRENT_MONTH') {
      this.reportPeriodFromDate = moment().startOf('day').startOf('month').toDate()
      this.reportPeriodToDate = moment().startOf('day').endOf('month').startOf('day').toDate()
    } else if (this.reportPeriod === 'LAST_MONTH') {
      this.reportPeriodFromDate = moment().startOf('day').startOf('month').subtract(1, 'months').toDate()
      this.reportPeriodToDate = moment().startOf('day').startOf('month').subtract(1, 'days').toDate()
    } else if (this.reportPeriod === 'CURRENT_FINANCIAL_YEAR') {
      if (moment().startOf('day').quarter() >= 3) {
        this.reportPeriodFromDate = moment().startOf('day').month(6).startOf('month').toDate()
        this.reportPeriodToDate = moment().startOf('day').month(6).startOf('month').add(1, 'year').subtract(1, 'days').toDate()
      } else {
        this.reportPeriodFromDate = moment().startOf('day').subtract(1, 'year').month(6).startOf('month').toDate()
        this.reportPeriodToDate = moment().startOf('day').month(6).startOf('month').subtract(1, 'days').toDate()
      }
    } else if (this.reportPeriod === 'LAST_FINANCIAL_YEAR') {
      if (moment().startOf('day').quarter() >= 3) {
        this.reportPeriodFromDate = moment().startOf('day').month(6).startOf('month').subtract(1, 'year').toDate()
        this.reportPeriodToDate = moment().startOf('day').month(6).startOf('month').subtract(1, 'days').toDate()
      } else {
        this.reportPeriodFromDate = moment().startOf('day').subtract(2, 'year').month(6).startOf('month').toDate()
        this.reportPeriodToDate = moment().startOf('day').subtract(1, 'year').month(6).startOf('month').subtract(1, 'days').toDate()
      }
    }
  }

  _formatDate(fromDate, format) {
    return moment(fromDate).locale('en').format(format || 'YYYY-MM-DD')
  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }
}


export default ReconciliationController
