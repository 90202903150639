import moment from 'moment'

class ScheduleAuctionController {
  /*@ngInject*/
  constructor() {
    this.startDate = this._getDefaultStartDate()
    this.endDate = this._getDefaultEndDate()
  }

  schedule() {
    return this.loanRequest.performAction('schedule', {startDate: this.startDate, endDate: this.endDate}).then(() => this.completion.resolve())
  }

  cancel() {
    this.completion.reject()
  }

  _getDefaultStartDate() {
    return moment().startOf('day').add(1, 'days').add(9, 'hours').toDate()
  }

  _getDefaultEndDate() {
    return moment().startOf('day').add(15, 'days').add(9, 'hours').toDate()
  }
}

export default ScheduleAuctionController
