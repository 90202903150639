class ManagePledgeRequestsController {
  /*@ngInject*/
  constructor($rootScope, $mdToast, investmentProductPledgeRequestsRepository) {
    this.name = 'manage-pledge-requests'
    this.$mdToast = $mdToast
    this.investmentProductPledgeRequestsRepository = investmentProductPledgeRequestsRepository

    this.page = 1
    this.limit = 5
    this.total = 0

    this.reload = () => {
      this.loading = true
      const conditions = {
        scope: 'for_investor',
        status: 'pending',
        id: this.investor.id,
        size: this.limit,
        page: this.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.investmentProductPledgeRequestsRepository.where(conditions)
        .then(data => {
          this.investmentProductPledgeRequests = data.investmentProductPledgeRequests
          this.total = data.total
        })
        .finally(() => this.loading = false)
    }
    this.$onInit = () => {
      this.reload()
      $rootScope.$on('InvestmentProductPledgeRequestCreated', () => this.reload())
    }

  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }
}

export default ManagePledgeRequestsController
