export default ['$mdMedia', '$compile', function($mdMedia, $compile) {
  return {
    restrict: 'A',
    priority: 600,
    scope: true,
    terminal: true,
    link: function link(scope, element, attrs, form) {
      const mediaSizes = attrs.ifMediaSize.split(' ')
      if (!scope._checkMediaSize) {
        const ngIfValue = attrs.ngIf ? `${attrs.ngIf} && _checkMediaSize()` : '_checkMediaSize()'

        element.attr('ng-if', ngIfValue)
        attrs.ngIf = ngIfValue

        scope._checkMediaSize = () => mediaSizes.some((mediaSize) => $mdMedia(mediaSize))

        $compile(element)(scope)
      }
    }
  }
}]
