import createEnum from '../../../../utils/createEnum'
import {resolveChoices} from '../../helpers/dynamicFormHelpers'
import isDefined from '../../../../utils/isDefined'

const uninitialised = value => value === undefined || value === null

const createHelpContentKey = value => `HELP-CONTENT-KEY:${value}`

const RADIO_BUTTON_DEFINITIONS_KEY = 'radio-button-definitions'
const SELECT_CHOICE_DEFINITIONS_KEY = 'select-choice-definitions'

const DEFAULT_TERM_IN_MONTHS = 36

const LOOKUP_STATUS_VALUES = createEnum('INIT', 'IN_PROGRESS', 'DONE', 'FAILED')

class FormWidgetController {
  /*@ngInject*/
  constructor(contactsRepository, helpPanelsService, contentful) {
    this.name = 'formWidget'
    this.contactsRepository = contactsRepository
    this.helpPanelsService = helpPanelsService

    this.loanTypes = [
      {label: 'Unsecured', value: 'unsecured'},
      {label: 'Secured', value: 'secured'},
      {label: 'Acquisition', value: 'acquisition'},
    ]

    this.loanTypeAdmins = [
      {label: 'Cashflow', value: 'cashflow'},
      {label: 'Asset finance', value: 'asset'},
      {label: 'Line of credit', value: 'loc'},
      {label: 'Acquisition finance', value: 'acquisition'},
      {label: 'Trail book', value: 'trailBook'},
      {label: 'Tools finance', value: 'toolsFinance'}
    ]

    this.borrowerTypes = [
      {label: 'Company', value: 'company'},
      {label: 'Trust, with corporate trustee', value: 'trustCorporate'},
      {label: 'Trust, other', value: 'trust'},
      {label: 'Sole Trader', value: 'soleTrader'},
      {label: 'Other', value: 'other'},
    ]

    this.pipedriveLookupStatus = LOOKUP_STATUS_VALUES.INIT

    this.phonePattern = /^\s*[+]?(\d|\s|[(]\d+[)])+\s*$/
    this.phonePatternAusMobile = /^\s*(([+]\s*61)|0)?\s*4(\s*\d){8}\s*$/
    /* eslint-disable max-len */
    this.emailPattern = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/
    /* eslint-enable max-len */

    this.tfnPattern = /^\s*(\d *){9}\s*$/

    this.$onInit = () => {
      if (this.widget.type === 'LoanTerm' && uninitialised(this.model[this.widget.modelFieldName])) {
        this.model[this.widget.modelFieldName] = DEFAULT_TERM_IN_MONTHS
      }
      if (this.widget.type === 'HelpContent' && uninitialised(this._helpContentModel)) {
        this._helpContentModel = helpPanelsService.getByIdentifier(this.widget.contentId)
      }
      if (this.widget.type === 'RadioButtons' && uninitialised(this._radioButtonDefinitions)) {
        this._radioButtonDefinitions = resolveChoices(contentful, this.widget.radioButtons)
      }
      if (this.widget.type === 'Select' && uninitialised(this._selectChoiceDefinitions)) {
        this._selectChoiceDefinitions = resolveChoices(contentful, this.widget.choices)
      }
    }
  }

  get widgetType() {
    return this.widget.type
  }

  get isRequiredClass() {
    return this.widget.required ? 'required' : ''
  }

  get abnResponse() {
    return this._abnResponse
  }

  set abnResponse(value) {
    this._abnResponse = value
    this._populateOptionalModels(value)
  }

  getValidation(validationType) {
    const finder = validation => validation.type === validationType
    return this.widget.validations && Array.isArray(this.widget.validations) && this.widget.validations.find(finder) ? this.widget.validations.find(finder).value : null
  }

  get isInteractive() {
    return this.widget.display !== 'read-only'
  }

  get isReadOnly() {
    return this.widget.display === 'read-only'
  }

  get displayedSubWidgets() {
    return this.widget.widgets.filter((widget) => widget.display !== 'none')
  }

  // Currency

  get decimalPlaces() {
    return isDefined(this.widget.decimals) ? this.widget.decimals : 0
  }

  // Pipedrive widget

  get pipedriveLookupInProgress() {
    return this.pipedriveLookupStatus === LOOKUP_STATUS_VALUES.IN_PROGRESS
  }

  pipedriveEmailLookup(model) {
    if (model) {
      this.pipedriveLookupStatus = LOOKUP_STATUS_VALUES.IN_PROGRESS
      this.contactsRepository.where({search: model})
        .then((response) => {
          if (response.contacts && (response.contacts.length > 0)) {
            this._populateOptionalModels(response.contacts[0])
          }
        })
        .catch(() => { /* noop */ })
        .finally(() => {
          this.pipedriveLookupStatus = LOOKUP_STATUS_VALUES.DONE
        })
    }
  }

  // Dynamic help content

  get helpContentLookupInProgress() {
    return this._checkHelpContentStatus('loading')
  }

  get helpContentLookupDone() {
    return this._checkHelpContentStatus('complete')
  }

  get helpContent() {
    return this._helpContentModel
  }

  // Radio button setup

  get radioButtonLookupInProgress() {
    return this._radioButtonDefinitions.isLookupInProgress
  }

  get radioButtonLookupFailed() {
    return this._radioButtonDefinitions.isLookupFailed
  }

  get radioButtonLookupSuccessful() {
    return this._radioButtonDefinitions.isLookupSuccessful
  }

  get radioButtonDefinitions() {
    return this._radioButtonDefinitions.choices
  }

  get radioButtonErrorMessage() {
    return this._radioButtonDefinitions.errorMessage
  }

  // Select setup

  get selectChoiceLookupInProgress() {
    return this._selectChoiceDefinitions.isLookupInProgress
  }

  get selectChoiceLookupFailed() {
    return this._selectChoiceDefinitions.isLookupFailed
  }

  get selectChoiceLookupSuccessful() {
    return this._selectChoiceDefinitions.isLookupSuccessful
  }

  get selectChoiceDefinitions() {
    return this._selectChoiceDefinitions.choices
  }

  get selectChoiceErrorMessage() {
    return this._selectChoiceDefinitions.errorMessage
  }

  isPromptDefined(prompt) {
    return prompt !== undefined && prompt !== null && typeof prompt === 'string' && prompt.trim().length > 0
  }

  // ****** private

  get _helpContentModel() {
    return this.widget.formCache.get(createHelpContentKey(this.widget.contentId))
  }


  set _helpContentModel(value) {
    this.widget.formCache.set(createHelpContentKey(this.widget.contentId), value)
  }

  _checkHelpContentStatus(expectedStatus) {
    return !uninitialised(this._helpContentModel) &&
      this._helpContentModel.status === expectedStatus
  }

  get _radioButtonDefinitions() {
    return this.widget.formCache.get(RADIO_BUTTON_DEFINITIONS_KEY)
  }

  set _radioButtonDefinitions(value) {
    return this.widget.formCache.set(RADIO_BUTTON_DEFINITIONS_KEY, value)
  }

  get _selectChoiceDefinitions() {
    return this.widget.formCache.get(SELECT_CHOICE_DEFINITIONS_KEY)
  }

  set _selectChoiceDefinitions(value) {
    return this.widget.formCache.set(SELECT_CHOICE_DEFINITIONS_KEY, value)
  }

  _populateOptionalModels(values) {
    this.widget.optionalModels && Object.entries(this.widget.optionalModels).forEach(([key, reference]) => {
      const referenceList = Array.isArray(reference) ? reference : [reference]
      referenceList.forEach(modelReference => {
        this.model[modelReference] = values && values[key]
      })
    })
  }
}

export default FormWidgetController
