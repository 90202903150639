const defaultOrValue = (defaultValue, value = defaultValue()) => value

class SectionAccordionController {
  /*@ngInject*/
  constructor(anime, $timeout) {
    this.name = 'sectionAccordion'
    this.anime = anime

    this.open = true
    this.inFlight = false

    this.$onInit = () => {
      if (this.initialState && this.initialState === 'CLOSED') {
        $timeout(() => {
          this._doAnimation(true)
        }, 0)
      }
    }
  }

  animateVisibility(event) {
    event.preventDefault()
    this._doAnimation(false)
  }

  _doAnimation(initial) {
    this.toggleIcon = defaultOrValue(() => this.directiveElement.find('.the-arrow').get(0), this.toggleIcon)
    this.toggleSection = defaultOrValue(() => this.directiveElement.find('section.supplied').get(0), this.toggleSection)

    if (this.inFlight) {
      return
    }

    const iconAnimation = (dValue) => ({
      targets: this.toggleIcon,
      d: dValue,
      complete: () => {
        this.inFlight = false
      }
    })

    const timeline = this.anime.timeline({
      easing: 'linear',
      duration: initial ? 100 : 200
    })

    this.inFlight = true
    if (this.open) {
      // Collapse the expanded section.
      timeline
        .add(iconAnimation('M10 10L30 30 50 10'))
        .add({
          targets: this.toggleSection,
          opacity: 0,
          height: ['50%', '0%'],
          begin: () => {
            this.toggleSection.style.overflow = 'hidden'
          },
          complete: () => {
            this.toggleSection.style.display = 'none'
          }
        }, 0)
    } else {
      // Expand the collapsed section.
      timeline
        .add(iconAnimation('M10 30L30 10 50 30'))
        .add({
          targets: this.toggleSection,
          opacity: 1,
          height: ['50%', '100%'],
          begin: () => {
            this.toggleSection.style.display = 'block'
            this.toggleSection.style.overflow = 'hidden'
          },
          complete: () => {
            this.toggleSection.style.overflow = 'visible'
          }
        }, 0)
    }
    this.open = !this.open
  }
}

export default SectionAccordionController
