const ADDRESS_FIELDS = [
  'fullAddress', 'buildingName', 'locality', 'postcode', 'state',
  'streetName', 'streetNumber', 'streetType', 'unitNumber'
]

class AddressSelectController {
  /*@ngInject*/
  constructor(streetAddressesRepository, $mdToast, $timeout) {
    this.name = 'addressSelect'
    this.streetAddressesRepository = streetAddressesRepository
    this.$mdToast = $mdToast
    this.$timeout = $timeout
  }

  get isDynamic() {
    return this.dynamic === undefined ? false : this.dynamic
  }

  querySearch(query) {
    return this.streetAddressesRepository.where({search: query})
      .then((response) => {
        this._setValidity(response.streetAddresses && (response.streetAddresses.length > 0))
        return response.streetAddresses
      })
      .catch(() => {
        this._setValidity(false)
        this._toast('Failed to connect to address service. Please try again in a minute.')
      })
  }

  itemChanged(item) {
    this.form[this.name].$setValidity('api', true)
    if (this._fieldError) {
      delete this._fieldError.required
    }
    if (this.patchForm) {
      this.patchForm.update()
    }
    this.selectedItemChange({item: item})
  }

  nameForControl() {
    if (this.formRepeatSectionTransclusion) {
      return `${this.formRepeatSectionTransclusion.transcludedName}-${this.name}`
    } else {
      return `${this.name}`
    }
  }

  _setValidity(ok) {
    this.form[this.name].$setValidity('api', false)
    if (ok) {
      if (this._fieldError) {
        this._fieldError.required = true
        delete this._fieldError.noMatch
      }
    } else {
      this._fieldError.noMatch = true
      delete this._fieldError.required
    }
  }

  clearUnselected() {
    this.$timeout(() => {
      if (!this._address || this._address === '') {
        this.form[this.name].$setViewValue('')
        this.form[this.name].$render()
      }
      if (this._fieldError.required) {
        delete this._fieldError.noMatch
      }
    }, 100)
  }

  set address(value) {
    if (value) {
      const address = {}
      ADDRESS_FIELDS.forEach((key) => address[key] = value[key])
      this._address = address
    } else {
      this._address = value
    }
  }

  get address() {
    return this._address
  }

  get _fieldError() {
    return this.form[this.name].$error
  }

  _toast(message) {
    this.$mdToast.show(
      this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right')
    )
  }
}

export default AddressSelectController
