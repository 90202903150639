import merge from 'lodash/merge'
import textParser from './textParser'

import resolveProperty from '../../../../../utils/resolveProperty'

const reportError = (logger, key, message) => {
  logger
    .notify(`Report generation was unsuccessful. (${key})`)
    .error(`jsonToPdfMakeTable error (${key}): ${message}`)
}

const jsonToPdfMakeTable = (tableSpecification, tokenProcessor, logger) => {
  const template = resolveProperty(tableSpecification, 'template')
  if (template === undefined) {
    reportError(logger, 'missing-template', 'Table specification is missing the template property.')
    return {}
  }
  const pmTableDefinition = merge({}, tableSpecification.template)

  const layoutMap = new Map()

  const bodyContentDefinitions = resolveProperty(tableSpecification, 'options', 'bodyContent')
  if (bodyContentDefinitions) {
    bodyContentDefinitions.forEach((rowDefinition, index) => {
      if (rowDefinition.layout) {
        layoutMap.set(index, rowDefinition.layout)
      }
      if (rowDefinition.rowContent && Array.isArray(rowDefinition.rowContent)) {
        const row = []
        rowDefinition.rowContent.forEach((cellContent) => {
          switch (typeof cellContent) {
            case 'number':
            case 'boolean':
            case 'bigint':
              row.push(cellContent)
              break
            case 'string':
              const [value, status] = textParser(cellContent, tokenProcessor, logger)
              row.push(value)
              if (!status) {
                reportError(logger, 'string-parse', `Failed to parse '${cellContent}'.`)
              }
              break
            case 'object':
              const cell = merge({}, cellContent)
              const cellTextContent = resolveProperty(cell, 'text')
              if (cellTextContent) {
                const [value, status] = textParser(cellTextContent, tokenProcessor, logger)
                cell.text = value
                row.push(cell)
                if (!status) {
                  reportError(logger, 'object-string-parse', `Failed to parse '${cellTextContent}'.`)
                }
              } else {
                row.push(cell)
              }
              break
            default:
              // Report that it is an unexpected value type.
              logger.error(`Unknown table cell type: '${typeof cellContent}'.`)
              break
          }
        })
        if (row.length > 0) {
          if (resolveProperty(pmTableDefinition, 'table') === undefined) {}
          pmTableDefinition.table.body.push(row)
        }
      }
    })
  }

  const defaultLayoutDefinition = resolveProperty(tableSpecification, 'options', 'defaultLayout')
  if (defaultLayoutDefinition || layoutMap.size > 0) {
    const layoutResolver = (propertyName, i, node) => {
      const rowValue = layoutMap.get(i)
      if (rowValue && rowValue.hasOwnProperty(propertyName)) {
        return rowValue[propertyName]
      }
      const candidateLayoutPropertyValue = resolveProperty(defaultLayoutDefinition, propertyName)
      return candidateLayoutPropertyValue !== undefined ? candidateLayoutPropertyValue : null
    }
    pmTableDefinition.layout = {
      hLineWidth: (i, node) => layoutResolver('hLineWidth', i, node),
      vLineWidth: (i, node) => layoutResolver('vLineWidth', i, node),
      hLineColor: (i, node) => layoutResolver('hLineColor', i, node),
      vLineColor: (i, node) => layoutResolver('vLineColor', i, node),
      paddingLeft: (i, node) => layoutResolver('paddingLeft', i, node),
      paddingRight: (i, node) => layoutResolver('paddingRight', i, node),
      paddingTop: (i, node) => layoutResolver('paddingTop', i, node),
      paddingBottom: (i, node) => layoutResolver('paddingBottom', i, node),
      fillColor: (i, node) => layoutResolver('fillColor', i, node)
    }
  }
  return pmTableDefinition
}

export default jsonToPdfMakeTable
