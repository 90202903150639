import template from './cdnAsset.pug'
import controller from './cdnAsset.controller'
import './cdnAsset.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: {
      assetStyle: '=?',
      assetId: '@?',
      assetName: '@?'
    },
  }
}
