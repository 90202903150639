import template from './investorManage.pug'
import controller from './investorManage.controller.js'
import './investorManage.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      investor: '=',
      applicant: '='
    }
  }
}
