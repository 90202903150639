class ElectronicBankStatementsDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, widget, existingData) {
    this.name = 'electronic-bank-statements-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog

    this.widget = widget

    this.hasExistingData = existingData && Object.entries(existingData).length > 0

    this.electronicBankStatements = {
      model: Object.assign({}, existingData),
      title: 'Electronic Bank Statements Message',
      definition: {widgets: widget.sourceWidgets},
      showSubmit: true,
      saveAction: () => this.$q.resolve(),
      submitAction: () => {
        return this.$mdDialog.hide(this.electronicBankStatements.model)
      }
    }
  }

  get heading() {
    return this.widget.dialogHeading
  }

  get instructions() {
    return this.widget.dialogInstructions
  }

  actionClose() {
    return this.$mdDialog.cancel()
  }

  get actionLabel() {
    return this.hasExistingData ? 'Resend email' : 'Send email'
  }
}

export default ElectronicBankStatementsDialogController
