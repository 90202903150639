const RENEW_TOKEN_FREQUENCY = 60

import isDefined from '../../utils/isDefined'

class AuthService {
  /*@ngInject*/
  constructor($q, $timeout, jwtHelper, authLockService, localStorageService, loggerService, tenantInformationService) {
    this.$q = $q
    this.$timeout = $timeout
    this.jwtHelper = jwtHelper
    this.authLockService = authLockService
    this.localStorageService = localStorageService
    this.logger = loggerService.makeLogger()
    this.tenantInformationService = tenantInformationService

    this.tenantName = 'We'
    tenantInformationService.getElement('name.short').then((value) => {
      this.tenantName = value
    })
  }

  login(providedTitle, username) {
    const mastTitle = providedTitle ? providedTitle : 'Log in'
    this._disableEmailInput = isDefined(username) && username.toString().trim().length > 0
    this._lock = this.authLockService.createLockWidget(username, mastTitle)
    this._handleAuthenticationEvents(this._lock)
    this._lock.show({
      allowSignUp: false,
      allowLogin: true,
      allowForgotPassword: true,
      languageDictionary: {
        title: mastTitle,
        error: {
          login: {
            'invalid_grant': 'Wrong email or password.'
          }
        }
      }
    })

    this.authPromise = this.$q.defer()
    return this.authPromise.promise
  }

  signup(providedEmail) {
    this._disableEmailInput = isDefined(providedEmail) && providedEmail.toString().trim().length > 0
    this._lock = this.authLockService.createLockWidget(providedEmail)
    this._handleAuthenticationEvents(this._lock)
    this._lock.show({
      allowSignUp: true,
      allowLogin: false,
      languageDictionary: {
        signupTitle: 'Sign up',
        signUpTerms: `${this.tenantName} respects your privacy. We will never share your email address with any external person or company without your permission.`
      }
    })

    this.authPromise = this.$q.defer()
    return this.authPromise.promise.then(() => this.getLoggedInUserId())
  }

  renewToken() {
    const resultPromise = this.$q.defer()
    if (this.hasValidToken()) {
      if (this._getDecodedToken().iat < Math.round(new Date().getTime() / 1000) - RENEW_TOKEN_FREQUENCY) {
        this.authLockService.createLockWidget().checkSession({}, (err, authResult) => {
          if (err) {
            this._clearToken()
            resultPromise.reject()
          } else {
            this._setToken(authResult)
            resultPromise.resolve('renewal')
          }
        })
      } else {
        resultPromise.resolve('renewal')
      }
    } else {
      resultPromise.reject()
    }
    return resultPromise.promise
  }

  logout() {
    delete this._lock
    this._clearToken()
    const resultPromise = this.$q.defer()
    resultPromise.resolve('signed out')
    return resultPromise.promise
  }

  isLoggedIn() {
    const idToken = this.localStorageService.get('token')
    return idToken ? !this.jwtHelper.isTokenExpired(idToken) : false
  }

  hasValidToken() {
    return this.isLoggedIn()
  }

  getLoggedInUserId() {
    return this.isLoggedIn() ? this._getDecodedToken().sub : undefined
  }

  getLoggedInUserEmail() {
    return this.isLoggedIn() ? this._getDecodedToken().email : undefined
  }

  // private

  _handleAuthenticationEvents(lock) {
    this._submitting = false

    const disableSuppliedEmailAndFocusPassword = () => {
      if (this._disableEmailInput) {
        angular.element('input.auth0-lock-input[name="email"]').attr('disabled', 'disabled')
        this.$timeout(() => { angular.element('input.auth0-lock-input[name="password"]').focus() }, 750)
      }
    }
    lock.on('signup ready', disableSuppliedEmailAndFocusPassword)
    lock.on('signin ready', disableSuppliedEmailAndFocusPassword)

    const currentlySubmitting = () => this._submitting = true
    lock.on('signin submit', currentlySubmitting)
    lock.on('signup submit', currentlySubmitting)

    lock.on('authenticated', (authResult) => {
      if (authResult && authResult.expiresIn && authResult.idToken) {
        this._setToken(authResult)
        this.authPromise.resolve('authenticated')
      } else {
        this.logger.log('authenticated event: failed', authResult)
      }
      this._submitting = false
    })

    const reportErrorGenerator = (kind) => (err) => {
      this.logger.error(`Auth0: ${kind} Error`)
      this.logger.error(err)
    }
    lock.on('authorization_error', reportErrorGenerator('authorization'))
    lock.on('unrecoverable_error', reportErrorGenerator('Unrecoverable'))

    lock.on('hide', () => {
      if (!this._submitting) {
        this.authPromise.reject()
      } else {
        this.$timeout(() => {
          if (this._submitting) {
            this.authPromise.reject()
          }
        }, 2000)
      }
    })
  }

  _getDecodedToken() {
    const idToken = this.localStorageService.get('token')
    return idToken ? this.jwtHelper.decodeToken(idToken) : {}
  }

  _setToken(authResult) {
    this.localStorageService.set('token', authResult.idToken)
  }

  _clearToken() {
    this.localStorageService.remove('token')
  }
}

export default AuthService
