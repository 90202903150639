import template from './editDebitsSchedule.pug'
import controller from './editDebitsSchedule.controller'
import './editDebitsSchedule.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loan: '=',
      completion: '=',
    },
    bindToController: true
  }
}
