// Utilities to assist with the uploading of files.

import Big from 'big.js'
import createEnum from './createEnum'
import completeAll from './completeAll'


const uploadStatus = createEnum('PENDING', 'UPLOADING', 'UPLOADED', 'ATTACHMENT_CREATED', 'DELETED', 'ABORTING', 'FAILED')


const createUploadManager = (fileGroup, file, status, attachmentId, uploadProgressValue) => ({
    fileGroup,
    metadata: file,
    status: status,
    upload: undefined,
    attachmentId: attachmentId,
    attachment: undefined,
    uploadProgressValue: uploadProgressValue
  })


const asPercentage = (numerator, denominator) => {
  const bigNumerator = new Big(numerator)
  const bigDenominator = new Big(denominator)
  return bigNumerator.eq(bigDenominator) ? 100 : bigNumerator.div(bigDenominator).times(100.0).round(0).toNumber()
}

const createUploadProgressLambda = (upload) => (event) => upload.uploadProgressValue = asPercentage(event.loaded, event.total)


const uploadProgressDescription = (item) => {
  if (item.status === uploadStatus.FAILED) {
    return 'failed'
  } else if (item.uploadProgressValue <= 0) {
    return 'not started'
  } else if (item.uploadProgressValue === 100) {
    return 'uploaded'
  } else {
    return `${item.uploadProgressValue}%`
  }
}


const performUploads = ($q, files, fileGroup, uploads, uploadService, attachmentsRepository, linkToUri, toDirectory = 'attachments', logger = () => {}) => {
  if (files && Array.isArray(files) && files.length > 0) {
    const promises = []
    files.forEach(file => {
      const perAttachmentDeferred = $q.defer()
      promises.push(perAttachmentDeferred.promise)

      const uploadManager = createUploadManager(fileGroup, file, uploadStatus.PENDING, undefined, 0)

      const {uploadPromise, upload} = uploadService.exposedUpload(file, undefined, createUploadProgressLambda(uploadManager))
      uploadManager.status = uploadStatus.UPLOADING
      uploadManager.upload = upload

      uploads.push(fileGroup, uploadManager)

      uploadPromise
        .then((metadata) => {
          if (![uploadStatus.ABORTING, uploadStatus.DELETED].includes(uploadManager.status)) {
            promises.push(attachmentsRepository.create({
              attachedTo: linkToUri ? linkToUri : 'none',
              toDirectory: toDirectory,
              metadata: metadata,
            }).then(attachment => {
              uploadManager.status = uploadStatus.ATTACHMENT_CREATED
              uploadManager.attachmentId = attachment.id
              uploadManager.attachment = attachment
              perAttachmentDeferred.resolve()
              return attachment
            }))
          } else {
            perAttachmentDeferred.resolve()
          }
        })
        .catch(err => {
          uploadManager.status = uploadStatus.FAILED
          logger.log(`File upload failed for ${file.name}'. err: ${JSON.stringify(err)}`)
        })
    })
    return completeAll($q, ...promises)
  }
  const deferred = $q.defer()
  deferred.resolve('no uploads')
  return deferred.promise
}


const deleteAttachment = (uploadManager, attachmentsRepository) => {
  if (uploadManager.attachment) {
    return uploadManager.attachment.delete().then(() => uploadManager.status = uploadStatus.DELETED)
  } else {
    return attachmentsRepository.getById(uploadManager.attachmentId).then((attachment) => {
      uploadManager.attachment = attachment
      return deleteAttachment(uploadManager, attachmentsRepository)
    })
  }
}

const deleteUpload = (uploadManager, attachmentsRepository) => {
  const originalStatus = uploadManager.status
  uploadManager.status = uploadStatus.ABORTING
  if (originalStatus === uploadStatus.ATTACHMENT_CREATED && uploadManager.attachmentId) {
    return deleteAttachment(uploadManager, attachmentsRepository)
  } else {
    const abortPromise = uploadManager.upload.abort()
    abortPromise.then(() => {
      if (originalStatus === uploadStatus.ATTACHMENT_CREATED && uploadManager.attachmentId) {
        return deleteAttachment(uploadManager, attachmentsRepository)
      } else {
        uploadManager.status = uploadStatus.DELETED
      }
    })
    return abortPromise
  }
}


const defaultingMap = () => {
  const map = new Map()
  const impl = {
    push: (key, value) => {
      if (!map.has(key)) {
        map.set(key, [])
      }
      map.get(key).push(value)
      return impl
    },
    entries: () => Array.from(map.keys()).reduce((container, key) => {
      container.push(...map.get(key))
      return container
    }, []),
    get: key => map.has(key) ? map.get(key) : []
  }
  return impl
}

export {uploadStatus, createUploadManager, createUploadProgressLambda, uploadProgressDescription, asPercentage, performUploads, deleteUpload, defaultingMap}
