class IconsService {
  /*@ngInject*/
  constructor(contentful, contentfulAssetsService) {
    this.contentful = contentful
    this.contentfulAssetsService = contentfulAssetsService
    this.icons = new Map()
  }

  getByIdentifier(assetId) {
   return this._findOrLookupIcon(assetId, () => this.contentful.asset(assetId), (entries) => entries.data.fields.file.url)
  }

  getByName(assetName) {
    return this._findOrLookupIcon(assetName, () => this.contentfulAssetsService.findAsset(assetName), (entries) => entries.asset.file.url)
  }

  // ****** Private.

  _findOrLookupIcon(assetKey, resolver, mapUrlAttribute) {
    const icon = this.icons.get(assetKey)
    return icon ? icon : this._resolveIcon(assetKey, resolver, mapUrlAttribute)
  }

  _resolveIcon(assetKey, resolver, mapUrlAttribute) {
    const asyncContent = {
      assetKey: assetKey,
      status: 'loading',
      url: '',
    }
    this.icons.set(assetKey, asyncContent)
    resolver().then((entries) => {
      asyncContent.url = mapUrlAttribute(entries)
      asyncContent.status = 'complete'
    })
      .catch((error) => {
        asyncContent.status = 'failed'
        asyncContent.url = 'icons/icon_brokenImage.svg'
        this.icons.delete(assetKey)
      })
    return asyncContent
  }
}

export default IconsService
