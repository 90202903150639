import {isVersion1LoanEnquiryDetailResponse, isVersion2LoanEnquiryDetailResponse, loanEnquiryDetailsByStatus, loanEnquiryDetailsSortByUpdatedAt} from '../../../../utils/loanEnquiryDetailHelper'

// ******* Loan enquiry detail display type translation.

const LED_DISPLAY_TYPES = {
  'initial': 'Initial creation',
  'file-upload': 'Uploaded files',
  'electronic-bank-statements': 'Bank statements request',
  'linked-business': 'Add linked business',
}

// ****** Key to find resolved uploads.

const uploadLookupKey = (...keys) => keys.join('/')

class HistoryController {
  /*@ngInject*/
  constructor($scope, attachmentsRepository, loggerService) {
    this.name = 'enquiry-details-history'
    const logger = loggerService.makeLogger()
    this._uploadLookup = {}
    this._responseCopy = {}

    const addFileReference = (loanEnquiryDetail, name, uploads, amendResponseFn) => {
      const addToLookup = (uploadIndex, attachment) => {
        this._uploadLookup[uploadLookupKey(loanEnquiryDetail.id, name, uploadIndex)] = {
          loanEnquiryDetail: loanEnquiryDetail,
          name: attachment.name,
          mime: attachment.mime,
          url: attachment.url
        }
        if (amendResponseFn) {
          amendResponseFn(this._uploadLookup[uploadLookupKey(loanEnquiryDetail.id, name, uploadIndex)], uploadIndex)
        }
      }
      uploads.forEach((upload, uploadIndex) => {
        const attachmentId = upload.attachmentId ? upload.attachmentId : upload.replace(/^.*[/](\d+)$/, '$1')
        if (this.resourceCache.has('attachment-' + attachmentId)) {
          addToLookup(uploadIndex, this.resourceCache.get('attachment-' + attachmentId))
        } else {
          attachmentsRepository.getById(attachmentId).then(attachment => {
            this.resourceCache.set('attachment-' + attachmentId, attachment)
            addToLookup(uploadIndex, attachment)
          })
        }
      })
    }

    this.$onInit = () => {
      this.loanEnquiry.promise('loanEnquiryDetails').then(() => {
        $scope.$watch(() => this.loanEnquiry.loanEnquiryDetails, (loanEnquiryDetails) => {
          this.loanEnquiryDetails = loanEnquiryDetailsByStatus(loanEnquiryDetailsSortByUpdatedAt(loanEnquiryDetails), 'active', 'complete')
          this.loanEnquiryDetails.forEach((loanEnquiryDetail) => {
            if (isVersion1LoanEnquiryDetailResponse(loanEnquiryDetail.response)) {
              this._responseCopy[loanEnquiryDetail.id] = JSON.parse(JSON.stringify(loanEnquiryDetail.response))
              if (loanEnquiryDetail.response.hasOwnProperty('uploads')) {
                Object.getOwnPropertyNames(loanEnquiryDetail.response.uploads).forEach((propertyName) => {
                  if (loanEnquiryDetail.response.uploads[propertyName].length > 0) {
                    addFileReference(loanEnquiryDetail, propertyName, loanEnquiryDetail.response.uploads[propertyName], (attachmentReference, uploadIndex) => {
                      this._responseCopy[loanEnquiryDetail.id].uploads[propertyName][uploadIndex] = `${attachmentReference.name} (SEE FILES)`
                    })
                  }
                })
              }
            } else if (isVersion2LoanEnquiryDetailResponse(loanEnquiryDetail.response)) {
              loanEnquiryDetail.response.enquiryDetails.filter((qanda) => qanda.type === 'upload' || qanda.type === 'FileUpload').forEach((qanda) => {
                if (Array.isArray(qanda.answer) && qanda.answer.length > 0) {
                  addFileReference(loanEnquiryDetail, qanda.name, qanda.answer)
                } else if (qanda.answer.uploads && Array.isArray(qanda.answer.uploads) && qanda.answer.uploads.length > 0) {
                  addFileReference(loanEnquiryDetail, qanda.name, qanda.answer.uploads)
                }
              })
            } else {
              logger.log('UNKNOWN loan enquiry details response format')
            }
          })
        })
      })
    }
  }

  ledDisplayType(ledType) {
    const candidateLabel = LED_DISPLAY_TYPES[ledType]
    return candidateLabel ? candidateLabel : 'Questions and Answers'
  }

  isVersion1LoanEnquiryDetail(loanEnquiryDetail) {
    return isVersion1LoanEnquiryDetailResponse(loanEnquiryDetail.response)
  }

  isVersion2LoanEnquiryDetail(loanEnquiryDetail) {
    return isVersion2LoanEnquiryDetailResponse(loanEnquiryDetail.response)
  }

  loanEnquiryJson(loanEnquiryDetailId) {
    return this._responseCopy[loanEnquiryDetailId]
  }

  getUpload(loanEnquiryDetailId, fieldName, fieldIndex) {
    return this._uploadLookup[uploadLookupKey(loanEnquiryDetailId, fieldName, fieldIndex)]
  }
}

export default HistoryController
