class CheckDigitCalculator {
  constructor(numbers) {
    this._numbers = (typeof numbers === 'string') && numbers.replace(/[^0-9]+/g, '')
  }

  // http://www.clearwater.com.au/code/acn
  isAcn() {
    if (this._numbers && this._numbers.length === 9) {
      let checksum = this._checksum(this._numbers, [8, 7, 6, 5, 4, 3, 2, 1])
      return parseInt(this._numbers.charAt(8), 10) === (10 - checksum % 10) % 10
    }

    return false
  }

  // http://www.clearwater.com.au/code/tfn, https://github.com/sidorares/tfn
  isTfn() {
    if (this._numbers) {
      if (this._numbers.length === 9) {
        let checksum = this._checksum(this._numbers, [1, 4, 3, 7, 5, 8, 6, 9, 10])
        return (checksum % 11) === 0
      } else if (this._numbers.length === 8) {
        let checksum = this._checksum(this._numbers, [10, 7, 8, 4, 6, 3, 5, 1])
        return (checksum % 11) === 0
      }
    }

    return false
  }

  // private

  _checksum(number, multipliers) {
    let total = 0
    let i = 0
    for (let m of multipliers) {
      total = total + (number.charAt(i) - '0') * m
      ++i
    }

    return total
  }
}

export default CheckDigitCalculator
