class StylingService {
  /*@ngInject*/
  constructor($window, $document) {
    this.rootElement = $window.getComputedStyle($document[0].documentElement)
  }

  getPropertyValue(propertyName) {
    return this.rootElement.getPropertyValue(propertyName).trim()
  }
}

export default StylingService
