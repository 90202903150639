import {
  assetDescription,
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  assetPrice,
  isVendorSource,
  isReferrer,
  loanAmount,
  loanPurposeAdmin,
  referrerFirstName,
  referrerLastName,
  referrerPhone,
  referrerEmail,
  referrerNotes,
  preferredCommission,
  conjunction,
  isReferrerEmail,
  isLoanOrigination,
  vendorSource,
  proposedDeposit,
  originationFee,
  isOriginationFee,
  isReferrerNotes, isBorrowerDone
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  and,
  isModelDefined,
  fromModel
} from '../../../dynamicForm/helpers/dynamicFormHelpers'
import {calculateLoanAmount} from '../sharedUtilities'


const equipmentFinanceAdminEnquiryForm = {
  title: 'Admin equipment finance',
  model: 'equipment-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Equipment finance application')
      .classes('border')
      .widgets(
        loanPurposeAdmin()
          .required(true)
          .display('none')
          .rules(ruleSet('"asset"'))
          .build(),
        contactType().admin().build(),
        helpContent().contentId('enquiry-form-asset-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            radioButtons()
              .required(true)
              .model('loanOrigination')
              .prompt('Loan origination?')
              .button('Truepillars', 'truepillars')
              .button('Laddr', 'laddr')
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Accredited referrer details')
          .rules(ruleRender(isLoanOrigination))
          .widgets(
            referrerFirstName().prompt('Referrer first name').build(),
            referrerLastName().prompt('Referrer last name').build(),
            referrerPhone().prompt('Referrer mobile number').build(),
            referrerEmail().prompt('Referrer email address').build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Asset details')
          .rules(ruleRender(isReferrerEmail))
          .widgets(
            assetPrice()
              .prompt('Asset purchase price')
              .build(),
            assetDescription()
              .required(true)
              .build(),
            vendorSource()
              .build(),
          ).build(),
        proposedDeposit()
          .prompt('Deposit amount (if applicable)')
          .rules(ruleRender(isVendorSource))
          .build(),
        loanAmount()
          .prompt('Financed amount (calculated)')
          .min(15_000)
          .max(300_000)
          .required(false)
          .rules(ruleRender(isVendorSource), ruleSet(calculateLoanAmount))
          .display('read-only')
          .build(),
        preferredCommission()
          .prompt('What is the preferred brokerage?')
          .min(0)
          .max(8)
          .step(0.5)
          .required(true)
          .rules(ruleRender(isVendorSource))
          .build(),
        originationFee()
          .rules(ruleRender(isVendorSource))
          .build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isOriginationFee))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background and reason for the loan')
              .required(true)
              .rules(ruleRender(isOriginationFee))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            ...borrowerFullComposite()
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Yes, another', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(isBorrowerDone))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(isBorrowerDone))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('assetInvoices', 'Invoice for the asset (optional at this stage)')
              .fileGroup('councilRates', 'Rates notice if property owner (optional at this stage)')
              .fileGroup('creditReports', 'Equifax credit report for individuals (optional but preferred)')
              .fileGroup('bankStatements', 'Illion electronic bank statement link in HTML format (optional)')
              .fileGroup('identityDocuments', 'Copy of Drivers License (optional but preferred)')
              .fileGroup('otherDocuments', 'Any other relevant documents (optional)')
              .includeComments(false)
              .build())
          .build())
      .build()
  ]
}

export default equipmentFinanceAdminEnquiryForm
