import moment from 'moment'

class AlertListController {
  /*@ngInject*/
  constructor(contentful, entityContextService) {
    this.name = 'alertList'
    this.contentful = contentful
    this.entityContextService = entityContextService
  }

  $onInit() {
    const today = moment().format('YYYY-MM-DD')
    this.entityContextService.currentInvestor().then((investor) => {
      this.contentful.entries(`content_type=alert&fields.type=${this.type}&fields.starting[lte]=${today}&fields.ending[gte]=${today}&order=-fields.starting`)
        .then((entry) => this.alerts = entry.data.items.map((item) => item.fields)
          .filter((alert) => !alert.targetIDs || alert.targetIDs.includes(`${investor.id}`)))
    })
      .catch(angular.noop) /* Do nothing - prolly not logged in as an INVESTOR. */
  }
}

export default AlertListController
