class UnfurlCardController {
  /*@ngInject*/
  constructor(contentful) {
    this.name = 'unfurl-card'
    this.contentful = contentful

    this.$onInit = () => {
      this.contentful.entries(`content_type=page&fields.name=${this.name}&include=10`).then((entry) => {
        this.page = entry.data && entry.data.items.length && entry.data.items[0].fields
      })
    }
  }
}

export default UnfurlCardController
