import isEmpty from '../../../../utils/isEmpty'
import isDefined from '../../../../utils/isDefined'
import moment from 'moment'

class InvestorRepresentativesController {
  /*@ngInject*/
  constructor($q, $mdToast, $rootScope, $mdDialog, representativeInvitationsRepository, greenidVerifyService) {
    this.name = 'investor-representatives'
    this.$q = $q
    this.$mdToast = $mdToast
    this.$rootScope = $rootScope
    this.$mdDialog = $mdDialog
    this.representativeInvitationsRepository = representativeInvitationsRepository
    this.greenidVerifyService = greenidVerifyService
    this.representativeLoading = {}
    this.representativesState = 'pending-representatives'
  }

  get representatives() {
    return this.investor && this.investor.representatives
  }

  get representativesJsons() {
    if (!this._representativesJsonsPromise) {
      if (this.representatives) {
        this._representativesJsonsPromise = this.$q.all(this.representatives.map((representative) => this._representRepresentative(representative)))
        this._representativesJsonsPromise.then((representativesJsons) => this._representativesJsons = representativesJsons)
      }
    }
    return this._representativesJsons
  }

  get numberOfRepresentatives() {
    if (!this.representatives || this.representatives.length < 1) {
      return 'No representatives'
    }
    const count = this.representatives.length
    return `${count} Representative${count > 1 ? 's' : ''}`
  }

  get representativesStatus() {
    if (this.representatives) {
      this.representativesState = this.representatives.length > 0 ? 'has-representatives' : 'no-representatives'
    }
    return this.representativesState
  }

  get isActive() {
    return this.investor && !['deleted', 'rejected'].includes(this.investor.status)
  }

  get canAddRepresentative() {
    return !!(this.investor && this.investor.type !== 'individual' && this.isActive)
  }

  sendRepresentativeInvitation(representative) {
    this._startLoading(representative)
    this.representativeInvitationsRepository.create({type: 'investor', id: representative.id}).then((response) => {
      representative.authToken = response.representative.authToken
      representative.identityEmailSent = response.representative.identityEmailSent
    }).catch(() => {
      this._showMessage('There was an error emailing the representative.')
    }).finally(() => {
      this._finishLoading(representative)
    })
  }

  updateRepresentativeIdentityCheckStatus(representative) {
    if (representative.user.identityCheck) {
      this._startLoading(representative)
      this.greenidVerifyService.updateStatus(representative.user.identityCheck.id).then(() => {
        this.representatives.find((r) => r.id === representative.id).user.reload().then(() => {
          delete this._representativesJsonsPromise
        })
      }).catch(() => {
        this._showMessage('There was an error updating representative identity check status.')
      }).finally(() => {
        this._finishLoading(representative)
      })
    }
  }

  loadingStatus(representative) {
    return this.representativeLoading[representative.id]
  }

  addRepresentative($event) {
    const template = `
      <md-dialog flex="50">
        <md-dialog-content layout-padding>
          <add-representative entity="investor" entity-type="investor" completion="completion"/>
        </md-dialog-content>
      </md-dialog>
    `
    return this._showDialog($event, template)
  }

  formatUserName(user) {
    return user ?
      [user.firstName, user.middleName, user.lastName].filter((name) => isDefined(name) && !isEmpty(name)).join(' ') : 'no name provided'
  }

  formatUserDateOfBirth(user) {
    return user.dob ? moment(user.dob, moment.ISO_8601).local().locale('en').format('LL') : 'no date of birth provided'
  }

  formatUserEmail(user) {
    return user.email ? user.email : 'no email provided'
  }

  // ****** private.

  _startLoading(rep) {
    this.representativeLoading[rep.id] = true
  }

  _finishLoading(rep) {
    this.representativeLoading[rep.id] = false
  }

  _representRepresentative(representative) {
    return representative.promise('user').then(() => {
      const json = representative.toJson()
      delete json.user.firebaseToken
      delete json.user.investors
      delete json.user.borrowers
      if (json.user.canonicalAddress) {
        json.user.address = json.user.canonicalAddress.fullAddress
      }
      delete json.user.canonicalAddress
      delete json.user.roles
      return json
    })
  }

  _showDialog($event, template) {
    const scope = this.$rootScope.$new(false)
    scope.investor = this.investor
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this._reload())
      .catch((wot) => wot) // Nothing to do on CANCEL, except keep AngularJS happy.
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      template: template,
      scope,
    })
  }

  _reload() {
    return this.investor.reload().then(() => {
      this.representativesState = 'pending-representatives'
      delete this._representativesJsonsPromise
    })
  }

  _showMessage(message) {
    this.$mdToast.show(this.$mdToast.simple().position('top left').hideDelay(5000).textContent(message))
  }
}

export default InvestorRepresentativesController
