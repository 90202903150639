import isDefined from '../../../utils/isDefined'
import resolveProperty from '../../../utils/resolveProperty'
import reportTypes from '../reportTypes'

const STATUS_INITIAL = 'initial'
const STATUS_PROCESSING = 'processing'
const STATUS_COMPLETE = 'complete'
const STATUS_ERROR = 'error'


class ReportInteractionController {
  /*@ngInject*/
  constructor(reportsRepository, loggerService) {
    this.name = 'reportInteraction'
    this.reportsRepository = reportsRepository

    this.logger = loggerService.makeLogger().enableNewRelic(true)

    this.recentError = undefined
    this.liveResponse = undefined

    this.actionLabel = 'Create'

    this.$onInit = () => {
      this._status = STATUS_INITIAL
      this.reportDefinition = reportTypes.get(this.reportType)
    }
  }

  get interactionLabel() {
    return isDefined(this.label) && this.label.toString().trim().length > 0 ? this.label : 'Report'
  }

  get errorMessage() {
    return this.recentError
  }
  
  get status() {
    if (isDefined(this.liveResponse)) {
      switch (this.liveResponse.status) {
        case 'COMPLETED':
          this._status = STATUS_COMPLETE
          break
        case 'DOWNLOADED':
          this._status = STATUS_INITIAL
          this.actionLabel = 'Re-create'
          break
        case 'ERROR':
          this._status = STATUS_ERROR
          break
        default:
          break
      }
    }
    return this._status
  }

  set status(value) {
    this._status = value
  }

  get downloadUrl() {
    const downloadUrl = resolveProperty(this.liveResponse, 'downloadUrl')
    if (isDefined(downloadUrl)) {
      return downloadUrl
    }
    throw new Error('The download URL is not defined. Programming error?')
  }

  initiateReport() {
    this._status = STATUS_PROCESSING
    const apiQuery = this.reportDefinition.apiQuery(this.payloadResolver())
    this.reportsRepository.getById(apiQuery).then((data) => {
      this.liveResponse = data
    }).catch((e) => {
      this._status = STATUS_ERROR
      this.logger.error(`ReportInteraction: reportRepository.getById FAILED for query '${apiQuery}'.`, e)
    })
  }
}

export default ReportInteractionController
