import addBusinessForm from './addBusinessForm'
import {createPayload} from '../../../../../../utils/loanEnquiryDetailHelper'

class AddBusinessDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, loanEnquiryDetailsRepository, loanEnquiry) {
    this.name = 'add-business-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.loanEnquiryDetailsRepository = loanEnquiryDetailsRepository
    this.loanEnquiry = loanEnquiry

    this.config = {
      model: {},
      title: 'Add Business',
      definition: addBusinessForm,
      showSubmit: true,
      saveAction: () => this.$q.resolve(),
      submitAction: (model, compiledWidgets) => {
        const body = {
          loanEnquiryId: this.loanEnquiry.id,
          loanEnquiryStage: this.loanEnquiry.stage,
          type: 'linked-business',
          request: addBusinessForm,
          response: {enquiryDetails: createPayload(model, compiledWidgets)}
        }
        return this.loanEnquiryDetailsRepository.create(body).then(() => this.$mdDialog.hide(this.config.loanEnquiry))
      }
    }
  }

  actionClose() {
    return this.$mdDialog.cancel()
  }
}

export default AddBusinessDialogController
