class WithdrawalService {
  /*@ngInject*/
  constructor(withdrawalRequestsRepository, withdrawalRequestsProcessingRepository) {
    this.withdrawalRequestsRepository = withdrawalRequestsRepository
    this.withdrawalRequestsProcessingRepository = withdrawalRequestsProcessingRepository
  }

  verifiedWithdrawalsPromise(conditions) {
    return this._selectWithdrawalsPromise('verified', conditions)
  }

  declinedWithdrawalsPromise(conditions) {
    return this._selectWithdrawalsPromise('declined', conditions)
  }

  completedWithdrawalsPromise(conditions) {
    return this._selectWithdrawalsPromise('completed', conditions)
  }

  processingWithdrawalsPromise(conditions) {
    return this._selectWithdrawalsPromise('processing', conditions)
  }

  rejectedWithdrawalsPromise(conditions) {
    return this._selectWithdrawalsPromise('rejected', conditions)
  }

  batchWithdrawalsPromise(batchId, conditions) {
    const parameters = Object.assign({scope: 'by_batch_id', batchId: batchId}, conditions)
    return this.withdrawalRequestsRepository.where(parameters)
  }


  updateSingle(event, id, reason) {
    return this._sendProcessingRequest(WithdrawalService._createProcessingPayloadFromSingle(event, id, reason))
  }

  update(event, idsToProcess) {
    return this._sendProcessingRequest(WithdrawalService._createProcessingPayload(event, idsToProcess))
  }

  // private

  _selectWithdrawalsPromise(status, conditions) {
    const parameters = Object.assign({scope: 'by_status', status: status}, conditions)
    Object.keys(parameters).forEach((key) => !parameters[key] && delete parameters[key])
    return this.withdrawalRequestsRepository.where(parameters)
  }

  static _createProcessingPayload(event, entities) {
    return {
      event: event,
      entities: entities.map((entity) => {
        return {id: entity.id, reason: entity.reason}
      })
    }
  }

  static _createProcessingPayloadFromSingle(event, id, reason) {
    return WithdrawalService._createProcessingPayload(event, [{id: id, reason: reason}])
  }

  _sendProcessingRequest(processingPayload) {
    return this.withdrawalRequestsProcessingRepository.create(processingPayload)
  }

}

export default WithdrawalService
