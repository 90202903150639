var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngCurrency, ngModel, ngRequired, required) {pug_mixins["centered-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "centered-content","layout": "row"},attributes]), true)) + "\u003E\u003Cdiv class=\"content\" layout=\"column\" layout-align=\"start stretch\" flex flex-md=\"90\" flex-lg=\"80\" flex-gt-lg=\"70\" flex-offset-md=\"5\" flex-offset-lg=\"10\" flex-offset-gt-lg=\"15\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["contentful-component"] = pug_interp = function(component){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-if", "" + component + ".fields.type === 'wide'", true, true)) + "\u003E\u003Cicon-panel" + (" class=\"wide-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel" + (" class=\"standard-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E";
},
attributes: {"ng-if": pug.escape("" + component + ".fields.type !== 'wide'")}
});
};



















pug_mixins["section"] = pug_interp = function(title, cls){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(attributes, true)) + "\u003E";
if (title) {
pug_html = pug_html + "\u003Cdiv class=\"section-title\" layout-padding\u003E\u003Cdiv" + (pug.attr("class", pug.classes([cls], [true]), false, true)) + "\u003E\u003Ch2\u003E" + (pug.escape(null == (pug_interp = title) ? "" : pug_interp)) + "\u003C\u002Fh2\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"content\" layout-padding\u003E\u003Cdiv\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};





















pug_mixins["form-input"] = pug_interp = function(name, label, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
type = type || 'text'
ngModel = attributes['ng-model'] || ("vm." + name)
ngCurrency = ('currency' === type)
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cinput" + (pug.attrs(pug.merge([{"flex": pug.escape(true),"ng-currency": pug.escape(ngCurrency),"name": pug.escape(name),"ng-model": pug.escape(ngModel),"type": pug.escape(type)},attributes]), true)) + "\u003E\u003C\u002Finput-container\u003E";
};














































pug_mixins["rateDisplay"] = pug_interp = function(rate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + (pug.escape(null == (pug_interp = rate) ? "" : pug_interp)) + "\u003Cimg src=\"\u002Ficons\u002Ficon-percent-pa.svg\" alt=\"percentage pa\"\u003E";
};












pug_html = pug_html + "\u003Cmd-progress-linear ng-if=\"!vm.formTracker\" md-mode=\"indeterminate\"\u003E\u003C\u002Fmd-progress-linear\u003E\u003Cform layout=\"column\" flex name=\"vm.investmentEntityForm\" patch-form=\"vm.formTracker\" validation-property=\"vm.validationProperty\"\u003E\u003Cfieldset ng-disabled=\"!vm.formTracker\"\u003E";
pug_mixins["section"].call({
block: function(){
pug_mixins["required-select"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-option value=\"individual\"\u003EIndividual\u003C\u002Fmd-option\u003E\u003Cmd-option value=\"company\"\u003ECompany\u003C\u002Fmd-option\u003E\u003Cmd-option value=\"smsf\"\u003ESelf-managed Super\u003C\u002Fmd-option\u003E\u003Cmd-option value=\"trust\"\u003ETrust\u003C\u002Fmd-option\u003E";
}
}, 'type', 'vm.investmentEntity.type', 'What type of investor are you?');
pug_html = pug_html + "\u003Cdiv ng-switch on=\"vm.investmentEntity.type\"\u003E\u003Cfieldset ng-switch-when=\"company\"\u003E\u003Cabn-lookup label=\"Please provide your ABN\u002FACN\" abn-model=\"vm.investmentEntity.company.abn\" acn-model=\"vm.investmentEntity.company.acn\" name-model=\"vm.investmentEntity.company.name\" required=\"true\"\u003E\u003C\u002Fabn-lookup\u003E";
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true)}
}, 'investmentEntity.company.name', "Legal name of company");
pug_html = pug_html + "\u003Caddress-select name=\"registeredAddress\" label=\"Registered address of company\" address=\"vm.investmentEntity.company.registeredAddress\" required\u003E\u003C\u002Faddress-select\u003E\u003Caddress-select name=\"principalPlaceOfBiz\" label=\"Principal place of business (if different to above)\" address=\"vm.investmentEntity.company.principalPlaceOfBiz\"\u003E\u003C\u002Faddress-select\u003E\u003C\u002Ffieldset\u003E\u003Cfieldset ng-switch-when=\"smsf\"\u003E\u003Cabn-lookup label=\"Please provide your ABN\u002FACN\" abn-model=\"vm.investmentEntity.smsf.abn\" acn-model=\"vm.investmentEntity.smsf.acn\" name-model=\"vm.investmentEntity.smsf.name\"\u003E\u003C\u002Fabn-lookup\u003E";
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true)}
}, 'investmentEntity.smsf.name', "Name of trust");
pug_html = pug_html + "\u003Chelp-box\u003E\u003Ccontent-item content-item-name=\"investmentEntity-info-trust-deed-upload\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fhelp-box\u003E\u003Cattachment-upload flex ng-required=\"true\" name=\"trustDeed\" attach-to=\"vm.formTracker.resource\" attachment-links=\"vm.investmentEntity.smsf.deed\" max-attachments=\"10\" on-change=\"vm.uploadChanged()\"\u003E\u003C\u002Fattachment-upload\u003E\u003C\u002Ffieldset\u003E\u003Cfieldset ng-switch-when=\"trust\"\u003E\u003Cabn-lookup label=\"Please provide your ABN\u002FACN\" abn-model=\"vm.investmentEntity.trust.abn\" acn-model=\"vm.investmentEntity.trust.acn\" name-model=\"vm.investmentEntity.trust.name\"\u003E\u003C\u002Fabn-lookup\u003E";
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true)}
}, 'investmentEntity.trust.name', "Name of trust");
pug_mixins["required-select"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-option value=\"discretionary\"\u003EDiscretionary\u002FFamily\u003C\u002Fmd-option\u003E\u003Cmd-option value=\"unit\"\u003EUnit\u003C\u002Fmd-option\u003E";
}
}, 'type', 'vm.investmentEntity.trust.type', 'Type of trust');
pug_html = pug_html + "\u003Chelp-box\u003E\u003Ccontent-item content-item-name=\"investmentEntity-info-trust-deed-requirement\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fhelp-box\u003E\u003Cattachment-upload flex ng-required=\"true\" name=\"trustDeed\" attach-to=\"vm.formTracker.resource\" attachment-links=\"vm.investmentEntity.trust.deed\" max-attachments=\"10\" on-change=\"vm.uploadChanged()\"\u003E\u003C\u002Fattachment-upload\u003E\u003C\u002Ffieldset\u003E\u003C\u002Fdiv\u003E";
}
}, "Investment entity");
pug_html = pug_html + "\u003Cdiv ng-if=\"vm.investmentEntity.type\"\u003E";
pug_mixins["section"].call({
block: function(){
pug_html = pug_html + "\u003Chelp-box\u003E\u003Ccontent-item content-item-name=\"investmentEntity-info-tfnDisclosure\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fhelp-box\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-radio-button value=\"isProvided\"\u003EI will provide a tax file number\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button value=\"notProvided\" ng-click=\"vm.investmentEntity.taxFileNumber.number = null\"\u003EI do not wish to provide a tax file number\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button value=\"exemptionClaimed\" ng-click=\"vm.investmentEntity.taxFileNumber.number = null\"\u003EI wish to claim a tax file number exemption\u003C\u002Fmd-radio-button\u003E";
},
attributes: {"ng-required": "!vm.investmentEntity.taxFileNumber.number"}
}, "willProvideTFN", "vm.investmentEntity.taxFileNumber.willProvideTFN");
pug_html = pug_html + "\u003Cdiv ng-if=\"'isProvided' == vm.investmentEntity.taxFileNumber.willProvideTFN\"\u003E\u003Cinput-container label=\"TFN (of {{ vm.investmentEntity.type }})\"\u003E\u003Cinput required name=\"taxFileNumber\" ng-model=\"vm.investmentEntity.taxFileNumber.number\" ng-change=\"vm.validateTFN()\" pattern=\"[0-9 -]*\"\u003E\u003Cng-message class=\"invalid-tfn\" when=\"invalid-tfn\"\u003EThis is not a valid TFN\u003C\u002Fng-message\u003E\u003Cng-message class=\"invalid-chars\" when=\"pattern\"\u003EInvalid characters in TFN\u003C\u002Fng-message\u003E\u003C\u002Finput-container\u003E\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"'exemptionClaimed' == vm.investmentEntity.taxFileNumber.willProvideTFN\"\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-radio-button value=\"pensioner\"\u003EPensioner\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button value=\"other\" flex\u003EOther\u003C\u002Fmd-radio-button\u003E\u003Cinput-container label=\"Please provide details\" ng-if=\"'other' == vm.investmentEntity.taxFileNumber.exemptionReason\"\u003E\u003Cinput class=\"reason-details\" required name=\"exemptionReasonDetails\" ng-model=\"vm.investmentEntity.taxFileNumber.exemptionReasonDetails\"\u003E\u003C\u002Finput-container\u003E";
}
}, "exemptionReason", "vm.investmentEntity.taxFileNumber.exemptionReason", "column");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-if=\"'notProvided' == vm.investmentEntity.taxFileNumber.willProvideTFN\" ng-cloak\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-checkbox class=\"accept-withholding\" name=\"acceptWithholding\" ng-model=\"vm.investmentEntity.taxFileNumber.acceptWithholding\" aria-label=\"Accept withholding tax\"\u003E\u003Ccontent-item content-item-name=\"investmentEntity-form-acceptWithholding\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fmd-checkbox\u003E";
},
attributes: {"ng-required": "!vm.investmentEntity.taxFileNumber.acceptWithholding"}
}, "notProvided", "vm.investmentEntity.taxFileNumber.notProvided", "column");
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
}, "Tax file number ({{ vm.investmentEntity.type }})");
pug_html = pug_html + "\u003Cdiv ng-switch on=\"vm.investmentEntity.type\"\u003E\u003Cdiv ng-switch-when=\"company\"\u003E";
pug_mixins["section"].call({
block: function(){
pug_html = pug_html + "\u003Chelp-box\u003E\u003Ccontent-item content-item-name=\"investmentEntity-info-beneficial-owner-or-director\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fhelp-box\u003E\u003Ch3\u003EAre you a beneficial owner or director?\u003C\u002Fh3\u003E";
pug_mixins["required-radio-container"].call({
block: function(){
pug_html = pug_html + "\u003Cmd-radio-button value=\"beneficialOwner\"\u003EI am a beneficial owner\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button value=\"director\"\u003EI am a director\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button value=\"beneficialOwnerAndDirector\"\u003EI am both a beneficial owner and a director\u003C\u002Fmd-radio-button\u003E\u003Cmd-radio-button value=\"neither\"\u003EI am neither a beneficial owner nor a director\u003C\u002Fmd-radio-button\u003E";
}
}, "beneficialOwnerOrDirector", "vm.applicant.type", "column");
pug_html = pug_html + "\u003Cbr\u003E\u003Cdiv ng-if=\"vm.applicant.type !== 'neither'\" layout=\"row\" layout-align=\"none top\"\u003E\u003Cinput-container label=\"What percentage of the company do you (directly or indirectly) own?\" flex\u003E\u003Cinput required name=\"ownershipPercent\" ng-model=\"vm.applicant.ownershipPercent\" type=\"number\" min=\"0\" max=\"100\"\u003E\u003C\u002Finput-container\u003E\u003Cp\u003E%\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Ch3\u003EPlease provide details of all other beneficial owners and directors\u003C\u002Fh3\u003E\u003Cform-repeat-section section-name=\"representatives.company\" title=\"Person\" collection=\"vm.representatives.company\" ng-if=\"vm.formTracker\" filter=\"vm.representativeFilter(formObject)\"\u003E\u003Crepresentative representative=\"$formObject\"\u003E\u003C\u002Frepresentative\u003E\u003C\u002Fform-repeat-section\u003E";
}
}, "Beneficial owner and director details");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-switch-when=\"trust\"\u003E";
pug_mixins["section"].call({
block: function(){
pug_html = pug_html + "\u003Chelp-box\u003E\u003Ccontent-item content-item-name=\"investmentEntity-info-trust-beneficiaries\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fhelp-box\u003E\u003Cform-repeat-section section-name=\"representatives.trust\" title=\"Person\" collection=\"vm.representatives.trust\" ng-if=\"vm.formTracker\" filter=\"vm.representativeFilter(formObject)\"\u003E";
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true),"ng-model": "$formObject.firstName"}
}, 'firstName', "First name");
pug_mixins["form-input"].call({
attributes: {"ng-model": "$formObject.middleName"}
}, 'middleName', "Middle name");
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true),"ng-model": "$formObject.lastName"}
}, 'lastName', "Last name");
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true),"ng-model": "$formObject.email"}
}, 'email', "Email");
pug_html = pug_html + "\u003C\u002Fform-repeat-section\u003E";
}
}, "Trust beneficiaries");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv ng-switch-when=\"smsf\"\u003E";
pug_mixins["section"].call({
block: function(){
pug_html = pug_html + "\u003Chelp-box\u003E\u003Ccontent-item content-item-name=\"investmentEntity-info-smsf-beneficiaries\"\u003E\u003C\u002Fcontent-item\u003E\u003C\u002Fhelp-box\u003E\u003Cform-repeat-section section-name=\"representatives.smsf\" title=\"Person\" collection=\"vm.representatives.smsf\" ng-if=\"vm.formTracker\" filter=\"vm.representativeFilter(formObject)\"\u003E";
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true),"ng-model": "$formObject.firstName"}
}, 'firstName', "First name");
pug_mixins["form-input"].call({
attributes: {"ng-model": "$formObject.middleName"}
}, 'middleName', "Middle name");
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true),"ng-model": "$formObject.lastName"}
}, 'lastName', "Last name");
pug_mixins["form-input"].call({
attributes: {"required": pug.escape(true),"ng-model": "$formObject.email"}
}, 'email', "Email");
pug_html = pug_html + "\u003C\u002Fform-repeat-section\u003E";
}
}, "SMSF beneficiaries");
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ffieldset\u003E\u003C\u002Fform\u003E";}.call(this,"ngCurrency" in locals_for_with?locals_for_with.ngCurrency:typeof ngCurrency!=="undefined"?ngCurrency:undefined,"ngModel" in locals_for_with?locals_for_with.ngModel:typeof ngModel!=="undefined"?ngModel:undefined,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;