import transitionStatusForm from './transitionStatusForm'

class TransitionStatusDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, investor, model) {
    this.name = 'transition-status-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog

    this.statusChange = {
      model: model,
      title: `Investor status change: ${model.transition}`,
      definition: transitionStatusForm,
      showSubmit: true,
      saveAction: () => $q.resolve(),
      submitAction: (model) => $mdDialog.hide(model),
      actionLabel: model.transition,
      submitUntouched: true
    }

  }

  actionClose() {
    return this.$mdDialog.cancel()
  }
}

export default TransitionStatusDialogController
