import angular from 'angular'
import 'angular-ui-router'

import homeComponent from './home.component'

export default angular.module('app.pages.marketing.home', ['ui.router'])
  .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
    $urlRouterProvider.otherwise('/')

    $urlRouterProvider.when('/', ['$state', ($state) => {
      $state.transitionTo('home')
    }])

    $stateProvider
      .state('home', {
        url: '/',
        template: '<home></home>',
        data: {
          appClasses: ['home', 'hero']
        },
        tabName: 'Home'
      })
  }])
  .directive('home', homeComponent)
