import template from './fileUpload.pug'
import controller from './fileUpload.controller'
import './fileUpload.scss'
import './fileUploadDialog/fileUploadDialog.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    require: ['^form'],
    controllerAs: 'vm',
    scope: {},
    link: (scope, el, attr, ctrls) => {
      let [form] = ctrls
      scope.vm.form = form
    },
    bindToController: {
      model: '=',
      linkToUri: '=',
      widget: '='
    }
  }
}
