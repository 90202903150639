import template from './feesDue.pug'
import controller from './feesDue.controller'
import '../directDebits.scss'
import './feesDue.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
