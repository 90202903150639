import moment from 'moment'
import completeAll from '../../../utils/completeAll'
import toastTemplate from './toastTemplate.pug'

class LoanDetailsController {
  /*@ngInject*/
  constructor($rootScope, $q, $state, $stateParams, $mdDialog, $mdToast, $scope, $sce, dateService, loansRepository,
              directDebitsRepository, loanReportService, fundingSourcesRepository, $filter) {
    this.name = 'loan-details'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$state = $state
    this.$stateParams = $stateParams
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.$sce = $sce
    this.dateService = dateService
    this.loansRepository = loansRepository
    this.directDebitsRepository = directDebitsRepository
    this.loanReportService = loanReportService

    this.expectedReturnMin = 0.1
    this.expectedReturnMax = 50.0
    this.discountMin = 10.0
    this.discountMax = 90.0
    this.todayDate = new Date()
    this.scheduledDebits = []
    this.debitOrder = ['dueDate']
    this.accordianState = $stateParams.scrollTo ? 'OPEN' : 'CLOSED'

    this.securityRatings = [
      {label: 'E', value: 'E'},
      {label: 'D', value: 'D'},
      {label: 'C', value: 'C'},
      {label: 'B', value: 'B'},
      {label: 'A', value: 'A'},
    ]

    this.loanFundingSources = [{label: 'All', value: 'all'}]
    fundingSourcesRepository.all().then(data => {
      this.loanFundingSources = data.fundingSources.map(source => ({label: $filter('asLeadingCaps')(source.name), value: source.id}))
    })

    this.loanPartsOrder = ['-rate', '-amount']
    this.showNextPaymentDetails = true
    this.showInvestorDetails = false

    this.submitLoan = this.submitLoan.bind(this)

    this.isResolvedReportService = () => featureTogglesService.isResolved('enableReportServiceInitiateWebui')
    this.isEnabledReportService = () => featureTogglesService.can('enableReportServiceInitiateWebui')

    this.reportLoanStatement = {
      type: 'loanStatement',
      label: 'Loan Statement',
      payloadResolver: () => {
        return {
          loanId: this.loan.id
        }
      }
    }

    this.$onInit = () => {
      this.load()
    }

    $scope.sortDate = function(scheduledDebit) {
      return new Date(scheduledDebit.dueDate)
    }
  }

  load() {
    if (!this.loading) {
      this.loading = true

      this.loadingPromise = this.loansRepository.getById(this.$stateParams.id).then((loan) => {
        this.loan = loan
        if (this.loan) {
          this._saveInitialValues()
          this._initializeLoanStatusForm()
          this._initializeLoanFundingSourceForm()
          this._initializeLoanRescheduleForm()
          this.irregular = this.loan.status === 'irregular'
          this.loan.promise('bankDetail').then((details) => {
            this.bankDetails = details
          }).then(() => this.reloadDebits())
          this.loan.promise('borrower').then((borrower) => {
            this.borrower = borrower
          })
          this.loan.promise('loanRequest').then((loanRequest) => {
            this.loanRequest = loanRequest
          })
          this.loan.promise('fundingSource').then((fundingSource) => {
            this.fundingSource = fundingSource.id
            this.originalFundingSource = fundingSource.id
          })
          this.recalculateTermination()
        }
        this.loading = false
      })
    }
  }

  resetDiscountAndReload() {
    this.discountPercentage = null
    this.load()
  }

  populateTerminationArgs() {
    let args = ''
    if (this.expectedReturnRate) {
      args += (args.length === 0) ? '?' : '&'
      args += ('expectedReturnRate=' + this.expectedReturnRate)
    }
    if (this.discountPercentage) {
      args += (args.length === 0) ? '?' : '&'
      args += ('discountPercentage=' + this.discountPercentage)
    }
    if (this.terminationDate) {
      args += (args.length === 0) ? '?' : '&'
      args += ('terminationDate=' + this._formatDate(this.terminationDate))
    }
    return args
  }

  recalculateTermination() {
    this.loansRepository.getById(this.$stateParams.id + '/termination-repayment' + this.populateTerminationArgs()).then((terminationRepayment) => {
      this.terminationDate ||= terminationRepayment.repaymentDate ? new Date(terminationRepayment.repaymentDate) : new Date()
      this.discountPercentage ||= terminationRepayment.discountPercentage
      this.expectedReturnRate ||= terminationRepayment.expectedReturnRate
      this.terminationRepayment = terminationRepayment
    })
  }

  get firstRepaymentDate() { return this._firstRepaymentDate }

  set firstRepaymentDate(date) {
    this._firstRepaymentDate = date

    if (this.loanRescheduleForm) {
      this._validateFirstRepaymentDate()
    }
  }

  get endDate() { return this._endDate }

  set endDate(date) {
    this._endDate = date

    if (this.loanRescheduleForm) {
      this._validateFirstRepaymentDate()
      this._validateEndDate()
    }
  }

  get loanStatus() {
    return this._loanStatus
  }

  set loanStatus(status) {
    this._loanStatus = status
  }

  get rate() {
    return this._rate
  }

  set rate(rate) {
    this._rate = rate
  }

  get repaymentType() {
    return this._repaymentType
  }

  set repaymentType(repaymentType) {
    this._repaymentType = repaymentType
  }

  get repaymentFrequency() {
    return this._repaymentFrequency
  }

  set repaymentFrequency(repaymentFrequency) {
    this._repaymentFrequency = repaymentFrequency
  }

  get repaymentAmount() {
    return this._repaymentAmount
  }

  set repaymentAmount(repaymentAmount) {
    this._repaymentAmount = repaymentAmount
  }

  get showActiveNextPayment() {
    return this.loanActive && this.showNextPaymentDetails
  }

  get loanActive() {
    return this.loan && (this.loan.status === 'active' || this.loan.status === 'deferred')
  }

  get loanComplete() {
    return this.loan && this.loan.status === 'completed'
  }

  get loanDefaulted() {
    return this.loan && this.loan.status === 'defaulted'
  }

  get hasLosses() {
    return this.loan.loanLosses && this.loan.loanLosses.length > 0
  }

  submitLoan() {
    return this.loan && this.loan.save()
      .catch((error) => this._showMessage('Error: ' + error.data.message))
  }

  downloadLoanStatement() {
    completeAll(this.$q, this.loan.promise('loanRepayments'), this.loan.promise('loanFees'), this.loan.promise('loanLosses')).then(() => {
      return this.loanReportService.createAndDownloadLoanStatement(this.loan, this.loan.loanRepayments, this.loan.loanFees, this.loan.loanLosses, this.borrower)
    })
  }

  submitSecurityBacking() {
    if (this.loanRequest) {
      return this.loanRequest.save()
        .then(() => this.securityBackingForm.$setPristine())
        .catch((error) => this.$mdToast.show(this.$mdToast.simple().textContent('Error: ' + error.data.message).hideDelay(5000).position('top left right')))
    }
  }

  submitContactDetails() {
    if (this.borrower) {
      return this.borrower.save()
        .then(() => this.contactDetailsForm.$setPristine())
        .catch((error) => this.$mdToast.show(this.$mdToast.simple().textContent('Error: ' + error.data.message).hideDelay(5000).position('top left right')))
    }
  }

  submitBankDetails() {
    if (this.bankDetails) {
      if (this.bankDetails.accountNumber) {
        this.bankDetails.accountNumber = this.bankDetails.accountNumber.replace(/[^0-9]/g, '')
      }

      return this.bankDetails.save()
        .then(() => this.bankDetailsForm.$setPristine())
        .catch((error) => this.$mdToast.show(this.$mdToast.simple().textContent('Error: ' + error.data.message).hideDelay(5000).position('top left right')))
    }
  }

  get isReschedule() {
    return this.loanActive && (
        this.repaymentType !== this.originalRepaymentType ||
        this.rate !== this.originalRate ||
        this.repaymentFrequency !== this.originalRepaymentFrequency ||
        this.firstRepaymentDate !== this.originalFirstRepaymentDate ||
        this.endDate !== this.originalEndDate ||
        this.repaymentAmount !== this.originalRepaymentAmount
    )
  }

  showRescheduleLoanDialog($event) {
    let scope = this.$rootScope.$new()
    scope.loan = this._proposeNewLoan(this.loan)
    scope.completion = this.$q.defer()
    scope.completion.promise
        .then(() => {
          this._saveInitialValues()
          this._initializeLoanRescheduleForm()
        })
      .catch(() => this.cancelRescheduleLoan())
      .finally(() => {
        this.$mdDialog.hide()
        this.loanRescheduleForm.$setPristine()
      })
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      onRemoving: () => this.loan.reload(),
      template: `
        <md-dialog flex="70">
          <md-dialog-content layout-padding>
            <admin-reschedule-loan loan='loan' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  cancelRescheduleLoan() {
    this._initializeLoanRescheduleForm()
  }

  updateLoanStatus() {
    if (this.loanStatus) {
      return this.loan.performAction(this.loanStatus).then(() => this.$state.reload())
    }
  }

  cancelUpdateLoanStatus() {
    this.loanStatus = this.originalStatus
    this.loanStatusForm.$setPristine()
  }

  updateLoanFundingSource() {
    const confirm = this.$mdDialog.confirm()
      .title('Update Funding Source')
      .textContent(`Are you sure you want to change funding source for this loan?`)
      .ok('Yes')
      .cancel('No')
    this.$mdDialog.show(confirm).then(() => {
      this.loan.fundingSourceId = this.fundingSource
      this.loan.save().then(() => this.loanFundingSourceForm.$setPristine())
        .catch((error) => this._showMessage('Error: ' + error.data.message))
    }, () => {
      this.cancelUpdateLoanFundingSource()
    })
  }

  cancelUpdateLoanFundingSource() {
    this._initializeLoanFundingSourceForm()
    this.loanFundingSourceForm.$setPristine()
  }

  createLoanFee($event) {
    const template = `
      <md-dialog flex="50">
        <md-dialog-content layout-padding>
          <admin-create-loan-fee loan='loan' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template)
      .then(() => this.reloadDebits())
      .then(() => this.recalculateTermination())
  }

  createLoanRepayment($event, type) {
    const template = `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-create-loan-repayment loan='loan' type='${type}' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template)
  }

  finalLoanRepayment($event) {
    const template = `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-final-loan-repayment loan='loan' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template)
  }

  createLoanLoss($event) {
    const template = `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-create-loan-loss loan='loan' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template)
  }

  editDebitsSchedule($event) {
    const template = `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-edit-debits-schedule loan='loan' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template).then(() => this.reloadDebits())
  }

  createFinalDebit($event) {
    const debit = {
      loanId: this.loan.id,
      type: 'final',
      dueDate: moment(this.terminationRepayment.repaymentDate).startOf('day').toDate(),
      amount: this.terminationRepayment.totalAmount,
      bsbNumber: this.bankDetails.bsbNumber,
      accountNumber: this.bankDetails.accountNumber,
      accountName: this.bankDetails.accountName
    }
    const template = `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-create-final-debit debit='debit' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template, debit).then(() => this.reloadDebits())
  }

  isBeforeNow(date) {
    return this.dateService.isBeforeNow(date)
  }

  allowListingChanges() {
    return this.loan && this.loan.status === 'active'
  }

  toggleDisallowSecondaryMarketListing() {
    const action = this.loan.disableSecondaryMarket ? 'DISALLOW' : 'ALLOW'
    const instructions = this.loan.disableSecondaryMarket ? ' NOTE: Disallowing secondary market listings will REMOVE ALL CURRENT INVESTOR LISTINGS for this loan from the Secondary Market.' : ''
    const confirm = this.$mdDialog.confirm()
      .title('Disallow Secondary Market Listings')
      .textContent(`Are you sure you want to ${action} Secondary Market listings for this loan?${instructions}`)
      .ok(action)
      .cancel('CANCEL')
    this.$mdDialog.show(confirm).then(() => {
      this.loan.save().catch(error => {
        this.$mdToast.show(this.$mdToast.simple().textContent('Error: ' + error.data.message).hideDelay(5000).position('top left right'))
      })
    }, () => {
      this.loan.disableSecondaryMarket = !this.loan.disableSecondaryMarket
    })
  }

  reloadDebits() {
    return this.loadingPromise = this.directDebitsRepository.where({scope: 'all', loanId: this.loan.id}).then(data => {
      this.activeDebits = data.directDebits.filter(directDebit => directDebit.status === 'scheduled' || directDebit.status === 'processing')
      this.scheduledDebits = data.directDebits.filter(directDebit => directDebit.status === 'scheduled')
      return this.scheduledDebits
    })
  }

  _formatDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  _saveInitialValues() {
    this.originalStatus = this.loan.status
    this.originalRate = this.loan.rate
    this.originalRepaymentType = this.loan.repaymentType
    this.originalRepaymentFrequency = this.loan.repaymentFrequency
    this.originalFirstRepaymentDate = moment(this.loan.nextRepaymentSummary ? this.loan.nextRepaymentSummary.dueDate : this.loan.firstRepaymentDate).toDate()
    this.originalEndDate = moment(this.loan.endDate).toDate()
    this.originalRepaymentAmount = this.loan.repaymentAmount
  }

  _initializeLoanStatusForm() {
    this.loanStatus = this.originalStatus
  }

  _initializeLoanFundingSourceForm() {
    this.fundingSource = this.originalFundingSource
  }

  _initializeLoanRescheduleForm() {
    this.rate = this.originalRate
    this.repaymentType = this.originalRepaymentType
    this.repaymentFrequency = this.originalRepaymentFrequency
    this.repaymentAmount = this.originalRepaymentAmount
    this.firstRepaymentDate = this.originalFirstRepaymentDate
    this.endDate = this.originalEndDate
  }

  _proposeNewLoan(loan) {
    loan.status = this.loanStatus
    loan.rate = this.rate
    loan.repaymentType = this.repaymentType
    loan.repaymentFrequency = this.repaymentFrequency
    loan.repaymentAmount = this.repaymentAmount
    loan.endDate = this.endDate
    loan.firstRepaymentDate = this.firstRepaymentDate
    return loan
  }

  _showDialog($event, template, debit=null) {
    const scope = this.$rootScope.$new(false)
    scope.loan = this.loan
    scope.debit = debit
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.loadingPromise = this.loan.reload())
      .catch(() => this._handleRejection())
      .finally(() => this.$mdDialog.hide())

    return this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      template: template,
      scope
    })
  }

  _handleRejection() {}

  _validateFirstRepaymentDate() {
    if (moment(this.firstRepaymentDate).isBefore(moment(this.loan.startDate))) {
      this.loanRescheduleForm['firstRepaymentDate'].$setValidity('beforeStart', false)
    } else if (moment(this.firstRepaymentDate).isAfter(this.endDate)) {
      this.loanRescheduleForm['firstRepaymentDate'].$setValidity('afterEnd', false)
    } else {
      this.loanRescheduleForm['firstRepaymentDate'].$setValidity('beforeStart', true)
      this.loanRescheduleForm['firstRepaymentDate'].$setValidity('afterEnd', true)
    }
  }

  _validateEndDate() {
    if (moment(this.endDate).isBefore(moment(this.firstRepaymentDate))) {
      this.loanRescheduleForm['endDate'].$setValidity('date', false)
    } else {
      this.loanRescheduleForm['endDate'].$setValidity('date', true)
    }
  }

  _showMessage(message) {
    this.$mdToast.show(this._toastFor(message))
  }

  _toastFor(message) {
    return {
      hideDelay: 30000,
      position: 'top left',
      controllerAs: 'vm',
      controller: () => ({
        message: this.$sce.trustAsHtml(message),
        closeToast: () => {
          this.$mdToast.hide('ok')
        }
      }),
      template: toastTemplate({})
    }
  }
}

export default LoanDetailsController
