import template from './investorStatusDisplay.pug'
import controller from './investorStatusDisplay.controller'
import './investorStatusDisplay.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      status: '='
    }
  }
}
