import Auth0Lock from 'auth0-lock'

class AuthLockProviderService {

  getAuthLockConstructor() {
    return Auth0Lock
  }
}

export default AuthLockProviderService
