import Big from 'big.js'
import moment from 'moment'

class LoanStatsController {
  /*@ngInject*/
  constructor(loansRepository, fundingSourcesRepository, $filter) {
    this.name = 'loan-stats'
    this.loansRepository = loansRepository

    this.fundingSources = [{label: 'All', value: 'all'}]
    fundingSourcesRepository.all().then(data => {
      this.fundingSources.push(...data.fundingSources.map(source => ({label: $filter('asLeadingCaps')(source.name), value: source.name})))
    })

    this.originations = [
      {label: 'All', value: 'all'},
      {label: 'TruePillars', value: 'truepillars'},
      {label: 'Laddr', value: 'laddr'},
      {label: 'RedZed', value: 'redzed'},
      {label: 'Other', value: 'other'}
    ]

    this.dateMin = new Date('2016-01-01')
    this.dateMax = moment().startOf('day').toDate()

    this.fromDate = moment().startOf('day').startOf('month').toDate()
    this.toDate = moment().startOf('day').toDate()
    this.fundingSource = ['all']
    this.origination = ['all']
  }

  load() {
    if (!this.loading) {
      this.loading = true
      this.reportFromDate = this.fromDate
      this.reportToDate = this.toDate
      const id = 'stats?fromDate=' + this._formateDate(this.reportFromDate) + '&toDate=' + this._formateDate(this.reportToDate)
        + '&fundingSource=' + this.fundingSource + '&origination=' + this.origination
      this.loadingPromise = this.loansRepository.getById(id)
        .then((loanStats) => {
          this.loanStats = loanStats
          this.loading = false
        })
    }
  }

  get fundingSource() { return this._fundingSource }

  set fundingSource(fundingSource) {
    if (fundingSource) {
      if (fundingSource.length > 1) {
        const index = fundingSource.indexOf('all')
        if (index !== -1) {
          fundingSource.splice(index, 1)
        }
      }
      this._fundingSource = fundingSource
    } else {
      this._fundingSource = ['all']
    }
  }

  get origination() { return this._origination }

  set origination(origination) {
    if (origination) {
      if (origination.length > 1) {
        const index = origination.indexOf('all')
        if (index !== -1) {
          origination.splice(index, 1)
        }
      }
      this._origination = origination
    } else {
      this._origination = ['all']
    }
  }

  _formateDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  get loansIssued() {
    if (this.loanStats) {
      return new Big(this.loanStats.loansAtEnd).minus(new Big(this.loanStats.loansBeforeStart)).toString()
    }
  }

  get principalIssued() {
    if (this.loanStats) {
      return new Big(this.loanStats.principalIssuedAtEnd).minus(new Big(this.loanStats.principalIssuedBeforeStart)).toString()
    }
  }

  get loansLost() {
    if (this.loanStats) {
      return new Big(this.loanStats.loansLostAtEnd).minus(new Big(this.loanStats.loansLostBeforeStart)).toString()
    }
  }

  get principalLost() {
    if (this.loanStats) {
      return new Big(this.loanStats.principalLostAtEnd).minus(new Big(this.loanStats.principalLostBeforeStart)).toString()
    }
  }

  get principalRepaid() {
    if (this.loanStats) {
      return new Big(this.loanStats.principalOutstandingBeforeStart)
        .plus(new Big(this.principalIssued))
        .minus(new Big(this.loanStats.principalOutstandingAtEnd))
        .minus(new Big(this.principalLost))
        .toString()
    }
  }

}

export default LoanStatsController
