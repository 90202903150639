import {
  conjunction,
  fromModel,
  or
} from '../../dynamicForm/helpers/dynamicFormHelpers'


const calculateLoanAmount = or(conjunction('subtract')(fromModel('assetPrice'), fromModel('proposedDeposit')), fromModel('assetPrice'))

const cleanup = (object) => {
  return JSON.parse(angular.toJson(object))
}

const recursiveSearch = (obj, type, results) => {
  results ||= []
  if (Array.isArray(obj)) {
    obj.forEach(item => recursiveSearch(item, type, results))
  } else if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      if (key === type) {
        results.push(obj[key])
      }
      recursiveSearch(obj[key], type, results)
    })
  }
  return results
}


export {
  calculateLoanAmount, cleanup, recursiveSearch
}
