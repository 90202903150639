import template from './home.pug'
import controller from './home.controller'
import '../../../marketing.scss'
import './home.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
