import Big from 'big.js'
import moment from 'moment'

const ZERO = '0.0'
const BIG_ZERO = new Big('0.0')

class LoanReportService {
  /*@ngInject*/
  constructor($filter, pdfService) {
    this.$filter = $filter
    this.pdfService = pdfService
  }

  createAndDownloadLoanStatement(loan, loanRepayments, loanFees, loanLosses, borrower) {
    this._createAndDownloadReport('LoanStatement-' + loan.loanRequestSummary.reference + '-' + moment().locale('en').format('YYYYMMDD'), [
      this._reportTitleSection('Loan Statement'),
      this._loanDetailsSection(loan, borrower),
      this._repaymentsSection(loan, loanRepayments, loanFees, loanLosses),
      this._summarySection(loan, loanRepayments)
    ])
  }

  // private

  _reportTitleSection(title) {
    return {
      text: title,
      fontSize: 16,
      color: '#002855',
      style: ['bold', 'center']
    }
  }

  _loanDetailsSection(loan, borrower) {
    const borrowerDetails = [
      borrower.name,
      'ABN ' + borrower.abn
    ]
    if (borrower.contactName) {
      borrowerDetails.push(borrower.contactName.trim())
    }
    if (borrower.mailingAddress && borrower.mailingAddress.fullAddress) {
      borrower.mailingAddress.fullAddress.split(',').forEach(addressLine => {
        borrowerDetails.push(addressLine.trim())
      })
    }
    return {
      columns: [
        {
          width: '50%',
          stack: borrowerDetails
        },
        {
          width: '35%',
          stack: [
            'Statement date',
            'Loan reference',
            'Loan amount',
            'Loan term'
          ],
          style: ['right']
        },
        {
          width: '15%',
          stack: [
            moment().locale('en').format('DD MMM YYYY'),
            loan.loanRequestSummary.reference,
            this._formatAmount(loan.amount),
            loan.termInMonths + ' months',
          ],
          style: ['right']
        }
      ],
      fontSize: 9,
      style: 'margin1x'
    }
  }

  _repaymentsSection(loan, loanRepayments, loanFees, loanLosses) {
    const predicateDispatches = [
      {
        predicate: () => this._predicateInterestMethodLocked(loan),
        dispatch: () => this._repaymentsSectionInterestMethodLocked(loan, loanRepayments, loanFees, loanLosses)
      },
      {
        predicate: () => true,
        dispatch: () => this._repaymentsSectionDefault(loan, loanRepayments, loanFees)
      }
    ].values()
    for (let predicateDispatch of predicateDispatches) {
      if (predicateDispatch.predicate()) {
        return predicateDispatch.dispatch()
      }
    }
  }

  _summarySection(loan, loanRepayments) {
    const predicateDispatches = [
      {
        predicate: () => this._predicateInterestMethodLocked(loan),
        dispatch: () => this._summarySectionInterestMethodLocked(loan, loanRepayments)
      },
      {
        predicate: () => true,
        dispatch: () => this._summarySectionDefault(loan, loanRepayments)
      }
    ].values()
    for (const predicateDispatch of predicateDispatches) {
      if (predicateDispatch.predicate()) {
        return predicateDispatch.dispatch()
      }
    }
  }

  _repaymentsSectionInterestMethodLocked(loan, loanRepayments, loanFees, loanLosses) {
    const sumLoanRepayments = loanRepayments.reduce((sum, loanRepayment) => sum.add(new Big(loanRepayment.amount).minus(loanRepayment.additionalFee)), new Big(0))

    const drawDown = sumLoanRepayments.add(loan.principalOutstanding).add(loan.interestOutstanding)
      .add(loan.standardFeeOutstanding).add(loan.principalLost).add(loan.interestLost).add(loan.standardFeeLost)

    const entries = this._instrumentAndSort(loanRepayments, loanFees, loanLosses)
      .reduce((accum, entry) => {
        accum.push({
          when: entry.date,
          description: entry.comment,
          fees: entry.fees,
          amount: entry.amount,
          balance: entry.fees ? accum.at(-1).balance.plus(entry.fees) : accum.at(-1).balance.minus(entry.amount)
        })
        return accum
      }, [{when: loan.startDate, description: 'Loan drawdown', balance: drawDown}])

    const bodyHeader = [
      {text: 'Date', style: 'bold', color: '#a7d500'},
      {text: 'Description', style: 'bold', color: '#a7d500'},
      {text: 'Fees', style: ['bold', 'right'], color: '#a7d500'},
      {text: 'Repaid', style: ['bold', 'right'], color: '#a7d500'},
      {text: 'Payments remaining', style: ['bold', 'right'], color: '#a7d500'}
    ]

    const bodyEntries = entries.map(entry => {
      return [
        {text: this._formatDate(entry.when, 'DD MMM YYYY')},
        {text: entry.description},
        {text: this._isZero(entry.fees) ? '' : this._formatAmount(entry.fees), style: 'right'},
        {text: this._isZero(entry.fees) ? this._formatAmount(entry.amount) : '', style: 'right'},
        {text: this._formatAmount(entry.balance), style: 'right'}
      ]
    })

    return {
      table: {
        widths: [60, '*', 50, 50, 60],
        body: [bodyHeader].concat(bodyEntries)
      },
      layout: {
        hLineWidth: (i, node) => i === 1 ? 1 : 0,
        vLineWidth: (i, node) => 0,
        hLineColor: (i, node) => i === 1 ? '#a7d500' : null,
        vLineColor: (i, node) => null,
        paddingLeft: (i, node) => 10,
        paddingRight: (i, node) => 10,
        paddingTop: (i, node) => i === 1 ? 4 : 2,
        paddingBottom: (i, node) => 2,
        fillColor: (i, node) => null
      },
      fontSize: 9,
      style: 'margin2x'
    }
  }

  _repaymentsSectionDefault(loan, loanRepayments, loanFees) {
    const entries = this._instrumentAndSort(loanRepayments, loanFees)
      .reduce((accum, entry) => {
        accum.push({
          when: entry.date,
          description: entry.comment,
          principal: entry.principal,
          interest: entry.interest,
          gst: entry.gst,
          fees: entry.fees,
          amount: entry.fees ? ZERO : entry.amount,
          principalOutstanding: entry.principalOutstanding,
          balance: entry.fees ?
            accum.at(-1).balance.plus(entry.fees) : accum.at(-1).balance.minus(entry.principal).minus(entry.additionalFee)
        })
        return accum
      }, [{when: loan.startDate, description: 'Loan drawdown', balance: new Big(loan.amount)}])

    const bodyHeader = [
      {text: 'Date', style: 'bold', color: '#a7d500'},
      {text: 'Description', style: 'bold', color: '#a7d500'},
      {text: 'Principal', style: ['bold', 'right'], color: '#a7d500'},
      {text: 'Interest', style: ['bold', 'right'], color: '#a7d500'},
      {text: 'Fees', style: ['bold', 'right'], color: '#a7d500'},
      {text: 'Repaid', style: ['bold', 'right'], color: '#a7d500'},
      {text: 'Outstanding', style: ['bold', 'right'], color: '#a7d500'}
    ]
    if (this._loanTypeHasGST(loan)) {
      bodyHeader.splice(4, 0, {text: 'GST', style: ['bold', 'right'], color: '#a7d500'})
    }

    const bodyEntries = entries.map(entry => {
      const newEntry = [
        {text: this._formatDate(entry.when, 'DD MMM YYYY')},
        {text: entry.description},
        {text: this._formatAmount(entry.principal), style: 'right'},
        {text: this._formatAmount(entry.interest), style: 'right'},
        {text: this._isZero(entry.fees) ? '' : this._formatAmount(entry.fees), style: 'right'},
        {text: this._isZero(entry.fees) ? this._formatAmount(entry.amount) : '', style: 'right'},
        {text: this._formatAmount(entry.balance), style: 'right'}
      ]
      if (this._loanTypeHasGST(loan)) {
        newEntry.splice(4, 0, {text: this._formatAmount(entry.gst), style: 'right'})
      }
      return newEntry
    })

    return {
      table: {
        widths: (loan.type === 'FL') ? [60, '*', 50, 40, 40, 40, 50, 60] : [60, '*', 50, 40, 40, 50, 60],
        body: [bodyHeader].concat(bodyEntries)
      },
      layout: {
        hLineWidth: (i, node) => i === 1 ? 1 : 0,
        vLineWidth: (i, node) => 0,
        hLineColor: (i, node) => i === 1 ? '#a7d500' : null,
        vLineColor: (i, node) => null,
        paddingLeft: (i, node) => 10,
        paddingRight: (i, node) => 10,
        paddingTop: (i, node) => i === 1 ? 4 : 2,
        paddingBottom: (i, node) => 2,
        fillColor: (i, node) => null
      },
      fontSize: 9,
      style: 'margin2x'
    }
  }

  _summarySectionInterestMethodLocked(loan, loanRepayments) {
    const bodyHeader = [
      {text: ''},
      {text: 'Period', style: 'bold', color: 'black'},
      {text: 'Interest/fees', style: ['bold', 'right'], color: 'black'},
      {text: 'GST', style: ['bold', 'right'], color: 'black'},
      {text: ''}
    ]
    const financialYearResults = this._repaymentsByFinancialYear(new Map(), loanRepayments)
    const bodyEntries = [...financialYearResults.keys()].sort().map((orderedFinancialYear) => {
      const financialYearResult = financialYearResults.get(orderedFinancialYear)
      return [
        {text: ''},
        {text: 'Financial year ' + financialYearResult.financialYear},
        {text: this._formatAmount(new Big(financialYearResult.interest).add(financialYearResult.additionalFee).add(financialYearResult.standardFee)), style: 'right'},
        {text: this._formatAmount(financialYearResult.gst), style: 'right'},
        {text: ''}
      ]
    })
    return {
      table: {
        headerRows: 1,
        widths: ['*', 80, 80, 50, '*'],
        body: [bodyHeader].concat(bodyEntries)
      },
      layout: {
        hLineWidth: (i, node) => i === 1 ? 1 : 0,
        vLineWidth: (i, node) => 0,
        hLineColor: (i, node) => i === 1 ? 'black' : null,
        vLineColor: (i, node) => null,
        paddingLeft: (i, node) => 10,
        paddingRight: (i, node) => 10,
        paddingTop: (i, node) => i === 1 ? 4 : 2,
        paddingBottom: (i, node) => 2,
        fillColor: (i, node) => null
      },
      fontSize: 9,
      pageBreak: 'before',
      style: 'margin2x'
    }
  }

  _summarySectionDefault(loan, loanRepayments) {
    const bodyHeader = [
      {text: ''},
      {text: 'Period', style: 'bold', color: 'black'},
      {text: 'Principal', style: ['bold', 'right'], color: 'black'},
      {text: 'Interest', style: ['bold', 'right'], color: 'black'},
      {text: 'GST', style: ['bold', 'right'], color: 'black'},
      {text: 'Fees', style: ['bold', 'right'], color: 'black'},
      {text: 'Amount', style: ['bold', 'right'], color: 'black'},
      {text: ''}
    ]
    const financialYearResults = this._repaymentsByFinancialYear(new Map(), loanRepayments)
    const bodyEntries = [...financialYearResults.keys()].sort().map((orderedFinancialYear) => {
      const financialYearResult = financialYearResults.get(orderedFinancialYear)
      return [
        {text: ''},
        {text: 'Financial year ' + financialYearResult.financialYear},
        {text: this._formatAmount(financialYearResult.principal), style: 'right'},
        {text: this._formatAmount(financialYearResult.interest), style: 'right'},
        {text: this._formatAmount(financialYearResult.gst), style: 'right'},
        {text: this._formatAmount(new Big(financialYearResult.additionalFee).add(financialYearResult.standardFee)), style: 'right'},
        {text: this._formatAmount(financialYearResult.amount), style: 'right'},
        {text: ''}
      ]
    })
    return {
      table: {
        headerRows: 1,
        widths: ['*', 80, 50, 50, 50, 50, 50, '*'],
        body: [bodyHeader].concat(bodyEntries)
      },
      layout: {
        hLineWidth: (i, node) => i === 1 ? 1 : 0,
        vLineWidth: (i, node) => 0,
        hLineColor: (i, node) => i === 1 ? 'black' : null,
        vLineColor: (i, node) => null,
        paddingLeft: (i, node) => 10,
        paddingRight: (i, node) => 10,
        paddingTop: (i, node) => i === 1 ? 4 : 2,
        paddingBottom: (i, node) => 2,
        fillColor: (i, node) => null
      },
      fontSize: 9,
      pageBreak: 'before',
      style: 'margin2x'
    }
  }

  _instrumentAndSort(loanRepayments, loanFees, loanLosses=[]) {
    const mappedAdditionalFees = loanFees
      .filter(entry => entry.type === 'additional')
      .map(entry => {
        entry.fees = entry.amount
        entry.comment = this.$filter('asLoanFeeCategory')(entry.category) + ' fee'
        return entry
      })

    const splitRepayments = []

    const mappedRepayments = loanRepayments
      .map(entry => {
        const newEntry = {...entry}
        newEntry.date = entry.repaymentDate

        if (entry.additionalFee > 0) {
          newEntry.comment = 'Fee repayment'
          newEntry.amount = entry.additionalFee
          newEntry.principal = 0
          newEntry.interest = 0
          newEntry.gst = entry.gst > 0 ? new Big(entry.additionalFee).div('11').toString() : 0

          if (entry.interest > 0 || entry.principal > 0) {
            splitRepayments.push({
              comment: 'Repayment',
              amount: new Big(entry.principal).plus(entry.interest).plus(entry.standardFee),
              additionalFee: 0,
              standardFee: entry.standardFee,
              principal: entry.principal,
              interest: entry.interest,
              gst: new Big(entry.gst).minus(newEntry.gst),
              date: entry.repaymentDate
            })
          }
        } else {
          newEntry.comment = 'Repayment'
        }

        return newEntry
      })

    const mappedLosses = loanLosses
      .filter(entry => this._isZero(entry.loanPrincipal) && this._isZero(entry.loanPrincipalOutstanding) && !this._isZero(entry.loanInterest))
      .map(entry => ({
        type: 'additional',
        comment: 'Final repayment discount',
        fees: -entry.loanInterest,
        amount: -entry.loanInterest,
        additionalFee: -entry.loanInterest,
        standardFee: ZERO,
        principal: ZERO,
        interest: ZERO,
        gst: ZERO,
        date: entry.date
      }))

    return mappedAdditionalFees.concat(mappedRepayments).concat(splitRepayments).concat(mappedLosses)
      .sort((a, b) => (a.date > b.date) ? 1 : (b.date > a.date) ? -1 : 0)
  }

  _determineFinancialYear(when) {
    return moment(when).add(6, 'months').year()
  }

  _initialiseFinancialYear(financialYear) {
    return {financialYear: financialYear, count: 0, principal: ZERO, interest: ZERO, gst: ZERO, amount: ZERO, fees: ZERO, additionalFee: ZERO, standardFee: ZERO}
  }

  _matchResult(results, financialYear) {
    const match = results.get(financialYear)
    if (match) {
      return [results, match]
    } else {
      results.set(financialYear, this._initialiseFinancialYear(financialYear))
      return [results, results.get(financialYear)]
    }
  }

  _repaymentsByFinancialYear(results, loanRepayments) {
    return loanRepayments.reduce((results, loanRepayment) => {
      const [accumulatingResults, match] = this._matchResult(results, this._determineFinancialYear(loanRepayment.repaymentDate))
      match.count = match.count + 1
      match.principal = new Big(match.principal).add(loanRepayment.principal).toString()
      match.interest = new Big(match.interest).add(loanRepayment.interest).toString()
      match.additionalFee = new Big(match.additionalFee).add(loanRepayment.additionalFee).toString()
      match.standardFee = new Big(match.standardFee).add(loanRepayment.standardFee).toString()
      match.gst = new Big(match.gst).add(loanRepayment.gst).toString()
      match.amount = new Big(match.amount).add(loanRepayment.amount).toString()
      return accumulatingResults
    }, results)
  }

  _predicateInterestMethodLocked(loan) {
    return loan.interestMethod === 'locked'
  }

  _createAndDownloadReport(name, content) {
    const definition = {
      info: {
        title: name,
        author: 'TruePillars'
      },
      pageMargins: [30, 80, 30, 50],
      header: this._headerSection(),
      footer: {
        stack: [
          'TruePillars Pty Ltd ABN 36 607 063 785',
          'Level 1, 155 Queen Street, Melbourne VIC 3000',
          {
            text: [
              'Enquiries 1300 320 288',
              ' | ',
              {text: 'www.truepillars.com', link: 'https://www.truepillars.com'},
              ' | ',
              'contact@truepillars.com'
            ]
          }
        ],
        fontSize: 8,
        style: ['center', 'margin1x']
      },
      content: content,
      styles: {
        bold: {
          bold: 'true',
        },
        right: {
          alignment: 'right',
        },
        center: {
          alignment: 'center',
        },
        margin1x: {
          margin: [0, 10, 0, 0]
        },
        margin1_5x: {
          margin: [0, 15, 0, 0]
        },
        margin2x: {
          margin: [0, 20, 0, 0]
        },
        margin3x: {
          margin: [0, 30, 0, 0]
        }
      }
    }
    this.pdfService.createAndDownload(name + '.pdf', definition)
  }

  _headerSection() {
    return {
      columns: [
        {
          width: '50%',
          stack: [
            {
              image: 'TruePillars-logo.png',
              width: 200
            }
          ]
        },
        {
          width: '50%',
          stack: [
            'TruePillars Lending Pty Ltd',
            'Level 1, 155 Queen Street',
            'Melbourne VIC 3000',
            {
              text: '1300 320 288',
              color: '#a7d500',
            },
            'ABN 36 607 063 785',
          ],
          fontSize: 8,
          style: ['right']
        }
      ],
      margin: [30, 20, 30, 0]
    }
  }

  _formatAmount(amount) {
    return amount ? this.$filter('asCurrency')(amount) : ''
  }

  _formatDate(date, format) {
    return moment(date).locale('en').format(format || 'YYYY-MM-DD')
  }

  _loanTypeHasGST(loan) {
    return loan.type === 'FL'
  }

  _isZero(n=0) {
    return BIG_ZERO.cmp(n) === 0
  }

}

export default LoanReportService
