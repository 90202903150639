var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {pug_mixins["centered-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "centered-content","layout": "row"},attributes]), true)) + "\u003E\u003Cdiv class=\"content\" layout=\"column\" layout-align=\"start stretch\" flex flex-md=\"90\" flex-lg=\"80\" flex-gt-lg=\"70\" flex-offset-md=\"5\" flex-offset-lg=\"10\" flex-offset-gt-lg=\"15\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["contentful-component"] = pug_interp = function(component){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-if", "" + component + ".fields.type === 'wide'", true, true)) + "\u003E\u003Cicon-panel" + (" class=\"wide-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel" + (" class=\"standard-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E";
},
attributes: {"ng-if": pug.escape("" + component + ".fields.type !== 'wide'")}
});
};





























pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};










































































pug_mixins["rateDisplay"] = pug_interp = function(rate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + (pug.escape(null == (pug_interp = rate) ? "" : pug_interp)) + "\u003Cimg src=\"\u002Ficons\u002Ficon-percent-pa.svg\" alt=\"percentage pa\"\u003E";
};












pug_html = pug_html + "\u003Cdiv class=\"file-upload-control with-popup\"\u003E\u003Ch4\u003E{{vm.widget.prompt}}\u003C\u002Fh4\u003E\u003Cinput ng-model=\"vm.model\" name=\"{{vm.widget.modelFieldName}}\" ng-required=\"vm.widget.required\" ng-show=\"false\"\u003E\u003Cdiv class=\"file-uploads\" ng-repeat=\"fileUploads in vm.widget.fileUploadConfig.fileUploads\"\u003E\u003Cdiv class=\"heading\"\u003E{{fileUploads.prompt}}\u003C\u002Fdiv\u003E\u003Cdiv class=\"no-uploads\" ng-if=\"!vm.fileUploadFor(fileUploads.fileGroup) || vm.fileUploadFor(fileUploads.fileGroup).uploads.length === 0\"\u003E- no uploads -\u003C\u002Fdiv\u003E\u003Cdiv class=\"exisiting-uploads\" ng-if=\"vm.fileUploadFor(fileUploads.fileGroup).uploads.length !== 0\"\u003E\u003Cul\u003E\u003Cli ng-repeat=\"existingUpload in vm.fileUploadFor(fileUploads.fileGroup).uploads\"\u003E\u003Cp class=\"file-details\" layout=\"row\" layout-align=\"start center\"\u003E\u003Cspan class=\"file-name\"\u003E{{existingUpload.fileName}} \u003C\u002Fspan\u003E\u003Cspan class=\"file-size\"\u003E{{existingUpload.fileSize | asFileSize}}\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"comments\" ng-if=\"vm.widget.fileUploadConfig.comments\"\u003E\u003Cdiv class=\"heading\"\u003E{{vm.widget.fileUploadConfig.comments.prompt}}\u003C\u002Fdiv\u003E\u003Cp ng-bind-html=\"vm.model.comments | fromMarkdown\"\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cmd-button class=\"md-initiate-popup md-cornered\" ng-click=\"vm.uploadFiles($event)\"\u003EUpload…\u003C\u002Fmd-button\u003E\u003C\u002Fdiv\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;