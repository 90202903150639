const formSettings = {
  title: 'Investor settings',
  model: 'settings',
  actionLabel: 'Update',
  widgets: [
    {
      type: 'Container',
      definition: {
        prompt: 'Investor settings',
        classes: 'distinct border box-shadow-2',
        widgets: [
          {
            type: 'RadioButtons',
            definition: {
              model: 'smallWithdrawals',
              prompt: 'Allow small cash withdrawals?',
              required: true,
              radioButtons: [
                {
                  value: true,
                  label: 'Allow'
                },
                {
                  value: false,
                  label: 'Prevent'
                },
              ]
            }
          }
        ]
      }
    }
  ]
}

export default formSettings
