import template from './businessProfile.pug'
import controller from './businessProfile.controller'
import './businessProfile.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loanRequest: '=loanRequest',
    },
    bindToController: true
  }
}
