import template from './investorApplicationDetails.pug'
import controller from './investorApplicationDetails.controller'
import './investorApplicationDetails.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
