class CreateLoanRequestController {
  /*@ngInject*/
  constructor(loanRequestsRepository, fundingSourcesRepository, $filter) {
    this.name = 'create-loan-request'
    this.loanRequestsRepository = loanRequestsRepository

    this.fundingSources = []
    fundingSourcesRepository.all().then(data => {
      this.fundingSources = data.fundingSources.map(source => ({label: $filter('asLeadingCaps')(source.name), value: source.name}))
    })

    this.term = 12
  }

  confirmAction() {
    return this._create()
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  // private

  _create() {
    const payload = {
      abn: this.abn,
      name: this.businessName,
      tradingSince: this.tradingSince,
      fundingSource: this.fundingSource,
      amount: this.amount,
      term: this.term
    }
    return this.loanRequestsRepository.create(payload)
      .then((loanRequest) => this.completion.resolve(loanRequest))
  }
}

export default CreateLoanRequestController
