import template from './dynamicFieldDisplay.pug'
import controller from './dynamicFieldDisplay.controller'
import './dynamicFieldDisplay.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: true,
    bindToController: {
      type: '<',
      value: '<',
      format: '<',
      label: '=?'
    }
  }
}
