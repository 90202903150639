import moment from 'moment'

import {LoanNoteRegisterType} from './service/loanNoteRegister'
import {MemberRegisterType} from './service/memberRegister'
import {LoanSummaryRegisterType} from './service/loanSummaryRegister'

class RegistersController {
  /*@ngInject*/
  constructor($mdToast, registerService, investorsRepository, loansRepository) {
    this.name = 'registers'
    this.$mdToast = $mdToast
    this.registerService = registerService
    this.investorsRepository = investorsRepository
    this.loansRepository = loansRepository

    this.reportDate = moment().startOf('day').toDate()

    this.loanNoteRegisterReports = []
    this.memberRegisterReports = []
    this.loanSummaryRegisterReports = []

    this.generatingReportType = null
  }

  get reportDateMin() {
    new Date('2016-01-01')
  }

  get reportDateMax() {
    moment().startOf('day').toDate()
  }

  isReporting() {
    return this.generatingReportType !== null
  }

  isReportingLoanNoteRegister() {
    return this.generatingReportType === LoanNoteRegisterType
  }

  isReportingMemberRegister() {
    return this.generatingReportType === MemberRegisterType
  }

  isReportingLoanSummaryRegister() {
    return this.generatingReportType === LoanSummaryRegisterType
  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }

  /* ****** Members Register. ******* */

  _loadInvestors(investors, size, page, reportMethod) {
    this.investorsRepository
      .where({
        status: 'all',
        includeFunds: true,
        atDate: moment(this.reportDate).format('YYYY-MM-DD'),
        size: size,
        page: page
      })
      .then((data) => {
        investors = investors.concat(data.investors)
        if (data.investors.length >= size) {
          this._loadInvestors(investors, size, page + 1, reportMethod)
        } else {
          this.generatingReportType = null
          this._addReportInOrder(this.memberRegisterReports, this.registerService(MemberRegisterType)[reportMethod](this.reportDate, investors))
        }
      })
      .catch(() => {
        this._toast('Error: member register request failed, please contact TruePillars.')
      })
  }

  createMembersRegisterDownload(outputType) {
    if (this.generatingReportType !== MemberRegisterType) {
      this.generatingReportType = MemberRegisterType
      const reportMethod = outputType === 'CSV' ? 'processCsv' : 'processPdf'
      this._loadInvestors([], 5000, 1, reportMethod)
    }
  }


  /* ****** Loan Note Register **** */

  _loadLoanNotes(loanNotes, size, page, reportMethod) {
    this.loansRepository.getById(`loan-notes?scope=active&atDate=${moment(this.reportDate).format('YYYY-MM-DD')}&size=${size}&page=${page}`)
      .then((data) => {
        loanNotes = loanNotes.concat(data.loanNotes)
        if (data.loanNotes.length >= size) {
          this._loadLoanNotes(loanNotes, size, page + 1, reportMethod)
        } else {
          this.generatingReportType = null
          this._addReportInOrder(this.loanNoteRegisterReports, this.registerService(LoanNoteRegisterType)[reportMethod](this.reportDate, loanNotes))
        }
      })
      .catch(() => {
        this._toast('Error: loan notes request failed, please contact TruePillars.')
      })
  }

  createLoanNoteRegisterDownload(outputType) {
    if (this.generatingReportType !== LoanNoteRegisterType) {
      this.generatingReportType = LoanNoteRegisterType
      const reportMethod = outputType === 'CSV' ? 'processCsv' : 'processPdf'
      this._loadLoanNotes([], 5000, 1, reportMethod)
    }
  }


  /* ****** Loan Summary Register **** */

  _loadLoanSummaries(summaries, size, page, reportMethod) {
    this.loansRepository.getById(`summaries?atDate=${moment(this.reportDate).format('YYYY-MM-DD')}&size=${size}&page=${page}`)
      .then((data) => {
        summaries = summaries.concat(data.summaries)
        if (data.summaries.length >= size) {
          this._loadLoanSummaries(summaries, size, page + 1, reportMethod)
        } else {
          this.generatingReportType = null
          this._addReportInOrder(this.loanSummaryRegisterReports, this.registerService(LoanSummaryRegisterType)[reportMethod](this.reportDate, summaries))
        }
      })
      .catch(() => {
        this._toast('Error: loan summary request failed, please contact TruePillars.')
      })
  }

  createLoanSummaryRegisterDownload(outputType) {
    if (this.generatingReportType !== LoanSummaryRegisterType) {
      this.generatingReportType = LoanSummaryRegisterType
      const reportMethod = outputType === 'CSV' ? 'processCsv' : 'processPdf'
      this._loadLoanSummaries([], 5000, 1, reportMethod)
    }
  }

  /* -- private */

  _addReportInOrder(reports, report) {
    const reportIndex = reports.findIndex((candidateReport) => candidateReport.reportDate.isSame(report.reportDate))
    if (reportIndex !== -1) {
      reports[reportIndex] = report
    } else {
      reports.push(report)
      reports.sort((reportA, reportB) => {
        const reportDateA = reportA.reportDate
        const reportDateB = reportB.reportDate
        return reportDateA.isBefore(reportDateB) ? -1 : reportDateA.isAfter(reportDateB) ? 1 : 0
      })
    }
  }
}


export default RegistersController
