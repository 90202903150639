import angular from 'angular'

class AppController {
  /*@ngInject*/
  constructor($state, navigation, userService, entityContextService, loginRouter, contentful, applicationSettingsService, $scope, $document, $location, $timeout) {
    this.name = 'app'
    this.$state = $state
    this.navigation = navigation
    this.userService = userService
    this.entityContextService = entityContextService
    this.loginRouter = loginRouter
    this.applicationSettingsService = applicationSettingsService
    this.applicationClasses = ['default']

    this.messages = []

    $scope.$watch(() => userService.getBlockingMessages(), () => {
      this.messages = userService.getBlockingMessages()
    })

    contentful.entries(`content_type=page&fields.name=maintenance&include=10`).then((entry) => {
      this.page = entry.data && entry.data.items.length && entry.data.items[0].fields
    })

    $scope.$on('$stateChangeSuccess', (event, toState) => {
      const appClasses = (angular.isDefined(toState.data) && angular.isDefined(toState.data.appClasses)) ? toState.data.appClasses : ['default']
      const appElement = $document.find('#app')[0]
      if (typeof appElement !== 'undefined' && appElement) {
        this.applicationClasses.forEach((applicationClass) => {
          while (appElement.classList.contains(applicationClass)) {
            appElement.classList.remove(applicationClass)
          }
        })
        appClasses.forEach((appClass) => {
          appElement.classList.add(appClass)
        })
        this.applicationClasses = appClasses
      }

      if (angular.isDefined(toState.data) && angular.isDefined(toState.data.scrollToUrlHash) && toState.data.scrollToUrlHash) {
        const millisecondDelayToLetAngularSettle = 200
        $timeout(() => {
          const locator = `[reference="${$location.hash()}"]`
          const scrollTarget = $document.find(locator)
          if (angular.isDefined(scrollTarget) && angular.isDefined(scrollTarget[0])) {
            scrollTarget[0].scrollIntoView(true)
          }
        }, millisecondDelayToLetAngularSettle)
      }
    })
  }

  get title() {
    if (this.$state.$current.data && typeof this.$state.$current.data.title !== 'undefined') {
      this._title = this.$state.$current.data.title
    }
    return this._title
  }

  get entityName() {
    if (this.$state.$current.data && typeof this.$state.$current.data.entityName !== 'undefined') {
      this._entityName = this.$state.$current.data.entityName
    }
    return this._entityName
  }

  get landingPage() {
    if (this.$state.$current.data && this.$state.$current.data.landingPage) {
      return this.$state.$current.data.landingPage
    }
  }

  get userLoggedIn() {
    return this.userService.isLoggedIn()
  }

  get showMaintenanceMessage() {
    return this.applicationSettingsService.setting('enableMaintenanceMode') && (
      (this.userLoggedIn && !this.userService.isAdmin() && this.$state.$current.data && this.$state.$current.data.requireAuth)
        || (this.$state.$current.data && this.$state.$current.data.hideOnMaintenance)
    )
  }

  get showContentPanel() {
    return !this.showMaintenanceMessage && !this.showBlockingMessage
  }

  get showBlockingMessage() {
    return !this.showMaintenanceMessage && this.messages.length > 0
  }

  get isLoggedIn() {
    return this.userLoggedIn
  }

  reset() {
    this.userService.logout().then(() => {
      this.entityContextService.logout()
      this.loginRouter.gotoHome()
    })
  }

  openSidenav() {
    this.navigation.showSideNav()
  }
}

export default AppController
