import valueOrDefault from '../../../utils/valueOrDefault'
import resolveProperty from '../../../utils/resolveProperty'

class FaqsController {
  /*@ngInject*/
  constructor(contentful, $stateParams, $timeout, loggerService) {
    this.name = 'faqs'
    this.categories = []
    this.$stateParams = $stateParams
    const logger = loggerService.makeLogger()

    const trustItem = (parentPath, item, categoryExpanded) => ({
      expanded: categoryExpanded && item.id === $stateParams.category,
      path: item.id,
      question: item.name,
      answer: item.content
    })

    this.$onInit = () => {
      contentful.entries(`content_type=page&fields.name=${this.name}&include=10`).then((entry) => {
        this.page = entry.data && entry.data.items.length && entry.data.items[0].fields

        this.categories = this.page.components.map((faq) => {
          const categoryExpanded = faq.fields.id === $stateParams.category
          return {
            expanded: categoryExpanded,
            path: faq.fields.id,
            label: faq.fields.name,
            className: valueOrDefault(resolveProperty(faq, 'fields', 'name'), '').replace(/\s+/g, '_'),
            content: faq.fields.content,
            items: faq.fields.items && faq.fields.items.map((item) => trustItem(faq.fields.id, item.fields, categoryExpanded))
          }
        })

        this.categories.forEach(category => {
          category.expanded = category.path === $stateParams.category
          category.items && category.items.forEach((item) => {
            item.expanded = category.expanded && item.path === $stateParams.item
          })
        })

        $timeout(() => {
          this.scrollToItem()
        }, 0)
      }, (error) => {
        logger.serviceFault()
          .serviceId('marketingCMS')
          .notification(`We're unable to display that page just now. Please try again later.`)
          .logMessage(() => {
            const messages = ['Marketing pages contentful entries promise rejection.']
            messages.push(`this.name: ${JSON.stringify(this.name)}`)
            messages.push(`Reason: ${JSON.stringify(error)}`)
            return messages.join('\n')
          })
          .report()
      })
    }

    this.toggleExpanded = (category, item) => {
      const expandItem = item && !item.expanded
      const expandCategory = !category.expanded

      if (item) {
        item.expanded = expandItem
      } else {
        category.expanded = expandCategory
      }
    }

    this.itemLocation = (category, item) => {
      return '/faqs/' + category.path + (item ? '/' + item.path : '')
    }

    this.scrollToItem = () => {
      let content = angular.element(document.querySelector('#main-content'))
      let element = angular.element(document.querySelector('h3.expanded') || document.querySelector('h2.expanded'))
      if (element && element[0]) $timeout(() => content.scrollToElementAnimated(element, 0, 0), 0)
    }
  }
}

export default FaqsController
