class BusinessProfileController {
  /*@ngInject*/
  constructor(uploadService, $q, $scope) {
    this.name = 'business-profile'
    this.uploadService = uploadService
    this.$q = $q
    this.$scope = $scope
  }

  get profileForm() {
    return this._profileForm
  }

  set profileForm(newForm) {
    this.loadProfile()
    this._profileForm = newForm
    if (this._profileForm) {
      this._profileForm.$setPristine()
    }
  }

  loadProfile() {
    this.loanRequest.promise('businessProfile').then((response) => {
      this.file = null
      this.fileSrc = response.image
      this.description = response.descriptionRaw
      this.notes = response.notesRaw
      this.imagePosition = response.imagePosition
      this.loanRequest.promise('financialSummary').then((response) => {
        this.financialSummary = response.data
      })
    })
  }

  selectFile(file, invalidFiles) {
    if (file) {
      this.fileSrc = null
      this.file = file
      let reader = new FileReader()
      reader.onload = (loadEvent) => {
        this.fileSrc = loadEvent.target.result
        this.$scope.$digest()
      }
      reader.readAsDataURL(this.file)
    }
  }

  submit() {
    this.progressStatus = 'Submitting...'
    return this.loanRequest.promise('businessProfile')
      .then((resource) => {
        return this._updateUploadMetadata(resource)
      })
      .then((resource) => {
        return this._updateFields(resource)
      })
      .then((resource) => {
        return this._updateFinancials(resource)
      })
      .then((resource) => {
        this.progressStatus = 'Updating profile...'
        return resource.save()
      })
      .then((resource) => {
        this.progressStatus = 'Done'
        if (resource.uploadMetadata) {
          delete resource.uploadMetadata
        }
        return resource
      })
      .finally((resource) => {
        this.progressStatus = null
        this.loanRequest.reload()
      })
  }

  // private

  _uploadFile() {
    return this.uploadService.upload(this.file)
  }

  _updateFields(resource) {
    if (this.profileForm.description.$dirty) {
      resource.description = this.description
    }
    if (this.profileForm.notes.$dirty) {
      resource.notes = this.notes
    }
    if (this.profileForm.imagePosition.$dirty) {
      resource.imagePosition = this.imagePosition
    }
    return this.$q.resolve(resource)
  }

  _updateUploadMetadata(resource) {
    if (this.file) {
      this.progressStatus = 'Uploading file...'
      return this._uploadFile().then((metadata) => {
        resource.uploadMetadata = metadata
        this.file = false
        return resource
      })
    }
    return this.$q.resolve(resource)
  }

  _updateFinancials(resource) {
    if (this.profileForm.financialSummary.$dirty) {
      this.progressStatus = 'Uploading financials...'
      return this.loanRequest.promise('financialSummary').then((financialsResource) => {
        financialsResource.data = this.financialSummary
        return financialsResource.save().then(() => resource)
      })
    }
    return this.$q.resolve(resource)
  }
}

export default BusinessProfileController
