const completeAll = ($q, ...promises) => {
  const deferred = $q.defer()
  const outcomes = []

  const process = (promise, result, resolved) => {
    outcomes.push({promise, result, resolved})
    if (outcomes.length === promises.length) {
      deferred.resolve(outcomes)
    }
    return result
  }

  if (promises.length === 0) {
    deferred.resolve(outcomes)
  } else {
    promises.forEach(promise => {
      promise
        .then(result => process(promise, result, true))
        .catch(result => process(promise, result, false))
    })
  }
  return deferred.promise
}

export default completeAll
