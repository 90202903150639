class ContentfulFormController {
  /*@ngInject*/
  constructor(userService, contentful, $scope, $state, $mdToast, $timeout) {
    this.name = 'contentful-form'
    this.userService = userService
    this.contentful = contentful
    this.$scope = $scope
    this.$state = $state
    this.$mdToast = $mdToast
    this.$timeout = $timeout

    this.answers = {}
    this.requestFormStatus = 'pre-request'

    this.loading = true
    this.$onInit = () => {
      this.contentful.entries(`content_type=form&fields.name=${this.formName}&include=10`).then((entry) => {
        this.data = entry.data && entry.data.items.length && entry.data.items[0]
        this.questions = this._extractQuestions()

        this.questions.forEach((question) => {
          this.answers[question.name] = {name: question.name, question: question.title, type: (question.answers[0].fields.type || question.answers[0].sys.contentType.sys.id || '').toLowerCase()}
          this._handleOptionAnswers(question)
          this._handleNumberAnswers(question)
        })
        this.loading = false
      })
    }
  }

  set labels(externalLabels) {
    this._externalLabels = externalLabels ? externalLabels.split(/ *, */) : []
  }

  get labels() {
    return (this._externalLabels || []).concat(this._optionLabels).concat(this._numberLabels)
  }

  get pov() {
    return (this.isAdmin || this.isPartner) ? 3 : 2
  }

  get isAdmin() {
    return this.userService && this.userService.isAdmin()
  }

  get isPartner() {
    return this.labels.includes('isPartner')
  }

  get version() {
    return this.data.fields.version
  }

  get timeout() {
    return this.data && this.data.fields && this.data.fields.timeout
  }

  optionList(question) {
    return question.fields.answers.map((answer) => answer.fields.name)
  }

  isRequired(question) {
    return (question.fields.required && !this.isAdmin) ||
      (question.fields.answers[0] && question.fields.answers[0].fields && (question.fields.answers[0].fields.max || question.fields.answers[0].fields.min))
  }

  resolveDependencies(content) {
    if (!content || !content.fields || !content.fields.dependsOn) {
      return true
    }
    return content.fields.dependsOn.every((dependency) => {
      return dependency.startsWith('!') ? !this.labels.includes(dependency.substr(1)) : this.labels.includes(dependency)
    })
  }

  answerFor(name, question) {
    if (name && question && question.fields && question.fields.answers && question.fields.answers[0] && question.fields.answers[0].fields.attributes) {
      return question.fields.answers[0].fields.models[question.fields.answers[0].fields.attributes.indexOf(name)]
    }
  }

  submit() {
    this._optionLabels.push('formSubmitted')

    if (this.onSubmit) {
      let payload = Object.values(this.answers).filter((e) => e.name && e.answer)
      return this.onSubmit(payload)
        .then(() => this.requestFormStatus = 'successful')
        .catch((error) => this.$mdToast.show(this.$mdToast.simple().textContent('Error: ' + error.data.message).hideDelay(5000).position('top left right')))
        .then(() => this._resetAfterTimeout())
    } else {
      this.$mdToast.show(this.$mdToast.simple().textContent('Configuration error: missing save()').hideDelay(5000).position('top left right'))
    }
  }

  reset() {
    this.$state.reload()
  }

  requestPreRequest() {
    return this.requestFormStatus === 'pre-request'
  }

  requestInProgress() {
    return this.requestFormStatus === 'in-progress'
  }

  requestSuccessful() {
    return this.requestFormStatus === 'successful'
  }

  // private

  _resetAfterTimeout() {
    if (this.timeout) {
      this.$timeout(() => {
        this.reset()
      }, this.timeout * 1000)
    }
  }

  _handleOptionAnswers(question) {
    this._optionLabels = []

    this.$scope.$watch(() => this.answers[question.name].answer, (value) => {
      let selectedOption = question.answers.filter((answer) => answer.fields.name === value)[0]
      if (selectedOption) {
        this._populateAnswer(question.name, 'label', selectedOption.fields.label)
        this._populateAnswer(question.name, 'alert', selectedOption.fields.alert)
      }
      this._optionLabels = Object.keys(this.answers).map(key => this.answers[key].label).filter((value) => value)
    })
  }

  _handleNumberAnswers(question) {
    this._numberLabels = []

    if (question.answers && question.answers[0].fields.alert || question.answers[0].fields.label) {
      this.$scope.$watch(() => this.form[question.name] && this.form[question.name].$dirty && this.form[question.name].$invalid, (invalid) => {
        if (this.form[question.name] && this.form[question.name].$dirty) {
          this._updateArray(this._numberLabels, question.answers[0].fields.label, invalid)
          this._populateAnswer(question.name, 'alert', invalid ? question.answers[0].fields.alert : null)
        }
      })
    }
  }

  _populateAnswer(questionName, fieldName, value) {
    if (fieldName) {
      if (value) {
        this.answers[questionName][fieldName] = value
      } else {
        delete this.answers[questionName][fieldName]
      }
    }
  }

  _updateArray(array, value, add) {
    if (value) {
      if (add) {
        let index = array.indexOf(value)
        if (index === -1) array.push(value)
      } else {
        let index = array.indexOf(value)
        if (index !== -1) array.splice(index, 1)
      }
    }
  }

  _extractQuestions() {
    return this._flatten(this.data.fields.contents.map((content) => content.fields.questions)).map((element) => element.fields)
  }

  _flatten(arr) {
    return arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? this._flatten(toFlatten) : toFlatten), [])
  }
}

export default ContentfulFormController
