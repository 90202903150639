
import {riskRating2Display} from '../../utils/riskRatingsMapper'

class PairedRatingController {
  /*@ngInject*/
  constructor() {
    this.name = 'pairedRating'
  }

  get mappedRiskRating() {
    return riskRating2Display(this.riskRating)
  }
}

export default PairedRatingController
