import {
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  isLoanAmount,
  isReferrerNotes,
  isBusinessEntity,
  isReferrer,
  loanAmount,
  loanPurposeAdmin,
  referrerFirstName,
  referrerLastName,
  referrerPhone,
  referrerEmail,
  referrerNotes,
  isBorrowerDone,
  conjunction,
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  and, isModelDefined, fromModel
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const cashFlowAdminEnquiryForm = {
  title: 'Admin business loan',
  model: 'business-loan-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Business loan enquiry')
      .classes('border')
      .widgets(
        loanPurposeAdmin()
          .required(true)
          .display('none')
          .rules(ruleSet('"cashflow"'))
          .build(),
        contactType().admin().build(),
        helpContent().contentId('enquiry-form-business-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            loanAmount().prompt('Business loan amount?').min(20000).max(250000).build()
          ).build(),
        container()
          .boxed(5)
          .rules(ruleRender(isLoanAmount))
          .widgets(
            ...businessFullComposite(),
            referrerNotes().prompt('Describe the business background, and the reason for the loan').required(true).rules(ruleRender(isBusinessEntity)).build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Broker details')
          .rules(ruleRender(and(isBusinessEntity, isReferrerNotes)))
          .widgets(
            referrerFirstName().build(),
            referrerLastName().build(),
            referrerPhone().prompt('Your mobile number').build(),
            referrerEmail().prompt('Your email address').build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            ...borrowerFullComposite()
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Yes, another', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(isBorrowerDone))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(isBorrowerDone))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('atoICA', 'ATO portal report (Integrated Client Account)')
              .fileGroup('personalStatements', 'Personal Statement of Position for all Directors/Shareholders')
              .fileGroup('homeLoan', 'Most recent home loan statement')
              .fileGroup('councilRates', 'Copy of council rates notice')
              .fileGroup('accountantFinancials', 'Accountant prepared financials')
              .fileGroup('ytdFinancials', 'YTD management accounts')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .build())
          .build())
      .build()
  ]
}

export default cashFlowAdminEnquiryForm
