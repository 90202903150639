import template from './abnLookup.pug'
import controller from './abnLookup.controller'
import './abnLookup.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    require: ['^form'],
    controllerAs: 'vm',
    scope: {},
    link: function(scope, el, attr, ctrls) {
      let [form] = ctrls
      scope.vm.form = form
    },
    bindToController: {
      presentationStyle: '@',
      label: '@',
      type: '@',
      required: '=',
      disabled: '=',
      mappings: '=?',
      response: '=?',
      abnModel: '=?',
      acnModel: '=?',
      nameModel: '=?',
      stateModel: '=?',
      statusCodeModel: '=?',
      postcodeModel: '=?',
      tradingSinceModel: '=?',
      entityTypeModel: '=?',
      entityDescriptionModel: '=?',
      validEntityTypes: '=?',
      validStatusCodes: '=?'
    }
  }
}
