import template from './pairedRating.pug'
import controller from './pairedRating.controller'
import './pairedRating.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      riskRating: '=',
      securityRating: '=',
    }
  }
}
