import isDefined from '../../../../../utils/isDefined'


class EditInvestmentProductPartAutoReinvestController {
  /*@ngInject*/
  constructor(loggerService) {
    this.name = 'edit-investment-product-part-auto-reinvest'

    this.loggerService = loggerService

    this.logger = loggerService.makeLogger().enableNewRelic(true)
  }

  stopPropagation($event) {
    $event.stopPropagation()
    return false
  }

  // Note: The investment product part is mutated in other places too; beware of any side-effects from this save here action.
  toggleAutoReinvest(investmentProductPart) {
    investmentProductPart.save().catch(e => {
      return this.logger.error(`Error: Saving the autoReinvest change to the Investment Product Loan part (${investmentProductPart.id}) failed: '${JSON.stringify(e)}'.`)
    })
  }

  get hasActiveInvestmentProductPart() {
    return isDefined(this.investmentProductPart) && this.investmentProductPart.status === 'active'
  }
}

export default EditInvestmentProductPartAutoReinvestController
