import mediaItemTemplate from './mediaItem.pug'

class MediaCarouselController {
  /*@ngInject*/
  constructor(contentful, mediaService, $templateCache) {
    this.name = 'mediaItem'

    $templateCache.put('media-item-template', mediaItemTemplate({}))
    this.$onInit = () => {
      contentful.entries(`content_type=blurb&fields.featured=true&include=10`).then((entry) => {
        this.media = mediaService.mapToViewOrdered(entry.data.items.map((item) => item.fields))
      })
    }
  }
}

export default MediaCarouselController
