import valueOrDefault from '../../../../../utils/valueOrDefault'
import {loanEnquiryDetailLinkedBusinesses} from '../../../../../utils/loanEnquiryDetailHelper'

const sentenceCase = (sentence) => sentence ? sentence.slice(0, 1).toUpperCase() + sentence.slice(1) : sentence

class CreateLoanRequestFromLoanEnquiryController {
  /*@ngInject*/
  constructor($scope, loanRequestsRepository) {
    this.$scope = $scope
    this.loanRequestsRepository = loanRequestsRepository
  }

  $onInit() {
    // this.loanEnquiry is defined at this point.
    this.businesses = loanEnquiryDetailLinkedBusinesses(this.loanEnquiry)
    const businessesById = this.businesses.reduce((mapper, business) => {
      mapper.set(business.id, business)
      return mapper
    }, new Map())

    this.loanAmount = this.loanEnquiry.loanEnquirySummary.loan.loanAmount
    this.loanTerm = this.loanEnquiry.loanEnquirySummary.loan.loanTerm
    this.loanPurpose = sentenceCase(this.loanEnquiry.loanEnquirySummary.loan.loanPurpose)

    // console.log(`******** this.loanEnquiry:`, this.loanEnquiry)
    this.$scope.$watch(() => this.borrowerId, (borrowerId) => {
      if (borrowerId) {
        const selectedBorrower = valueOrDefault(businessesById.get(borrowerId), {name: '', tradingSince: ''})
        this.tradingSince = selectedBorrower['tradingSince']
        this.borrowerName = selectedBorrower['name']
        this.borrowerAbnAcn = selectedBorrower['abnAcn']
      }
    })
  }

  confirmAction() {
    return this._create()
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  // **** private

  _create() {
    const payload = {
      abn: this.borrowerAbnAcn,
      name: this.borrowerName,
      tradingSince: this.tradingSince,
      amount: this.loanAmount,
      term: this.loanTerm,
      loanEnquiryId: this.loanEnquiry.id,
      purpose: this.loanPurpose
    }
    return this.loanRequestsRepository.create(payload)
      .then((loanRequest) => this.completion.resolve(loanRequest))
  }
}

export default CreateLoanRequestFromLoanEnquiryController
