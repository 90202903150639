import angular from 'angular'
import 'angular-ui-router'

import checkConsentComponent from './checkConsent.component'

export default angular.module('app.pages.checkConsent', ['ui.router'])
  .directive('checkConsent', checkConsentComponent)
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('checkConsent', {
        url: '/consent-check/:token',
        template: '<check-consent />',
      })
      .state('createConsent', {
        url: '/consent-check',
        template: '<check-consent />',
      })
  }])
