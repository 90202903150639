export default ['applicationSettingsService', 'rulesService', '$compile', (applicationSettingsService, rulesService, $compile) => {
  return {
    restrict: 'A',
    priority: 600,
    scope: true,
    terminal: true,
    link: function link(scope, element, attrs) {
      const ngIfValue = attrs.ngIf ? `(${attrs.ngIf}) && applicationSettingsMatch` : 'applicationSettingsMatch'

      element.attr('ng-if', ngIfValue)

      const rule = attrs.ifApplicationSetting
      const options = {
        applicationSettingResolver: (settingName) => applicationSettingsService.setting(settingName)
      }

      const callback = () => {
        scope.applicationSettingsMatch = rulesService.parse(rule, options)
      }

      applicationSettingsService.addCallBack(element, callback)

      scope.$on('$destroy', () => {
        applicationSettingsService.removeCallBack(element)
      })

      callback()
      $compile(element)(scope)
    }
  }
}]
