import angular from 'angular'
import 'angular-ui-router'

import collectBiometricIdentityData from './collectBiometricIdentityData.component'

export default angular.module('app.pages.checkBiometricIdentity', ['ui.router'])
  .directive('collectBiometricIdentityData', collectBiometricIdentityData)
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('collectBiometricIdentityData', {
        url: '/collect-biometric-identity-data/:token',
        template: '<collect-biometric-identity-data />',
      })
  }])
