const formOwner = {
  title: 'Investor owner',
  model: 'owner',
  actionLabel: 'Update',
  widgets: [
    {
      type: 'Container',
      definition: {
        prompt: 'Investor owner',
        classes: 'distinct border box-shadow-2',
        widgets: [
          {
            type: 'Container',
            definition: {
              classes: 'border box-shadow-2',
              widgets: [
                {
                  type: 'RadioButtons',
                  definition: {
                    model: 'honorific',
                    prompt: 'Honorific',
                    required: false,
                    radioButtons: [
                      {
                        value: 'Miss',
                        label: 'Miss'
                      },
                      {
                        value: 'Mr.',
                        label: 'Mr.'
                      },
                      {
                        value: 'Mrs.',
                        label: 'Mrs.'
                      },
                      {
                        value: 'Ms.',
                        label: 'Ms.'
                      },
                      {
                        value: 'Mx.',
                        label: 'Mx.'
                      },
                      {
                        value: '',
                        label: '-blank-'
                      },
                    ]
                  }
                },
                {
                  type: 'String',
                  definition: {
                    model: 'firstName',
                    prompt: 'First name'
                  }
                },
                {
                  type: 'String',
                  definition: {
                    model: 'middleName',
                    prompt: 'Middle name'
                  }
                },
                {
                  type: 'String',
                  definition: {
                    model: 'lastName',
                    prompt: 'Last name',
                    required: true
                  }
                },
              ]
            }
          },
          {
            type: 'Address',
            definition: {
              model: 'address',
              prompt: 'Street address',
              required: true
            }
          },
          {
            type: 'PhoneAusMobile',
            definition: {
              model: 'phone',
              prompt: 'Australian mobile telephone',
              required: true
            }
          },
          {
            type: 'Date',
            definition: {
              model: 'dob',
              prompt: 'Date of birth',
              required: true
            }
          },
        ]
      }
    }
  ]
}

export default formOwner
