import {
  assetDescription,
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  assetPrice,
  isVendorSource,
  isBorrowerDone,
  isReferrer,
  loanAmount,
  preferredCommission,
  loanPurpose,
  referrerFirstName,
  referrerLastName,
  referrerPhone,
  referrerEmail,
  referrerNotes,
  conjunction,
  isReferrerEmail,
  proposedDeposit,
  vendorSource,
  originationFee,
  isOriginationFee,
  isReferrerNotes,
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  and,
  isModelDefined,
  fromModel, info
} from '../../../dynamicForm/helpers/dynamicFormHelpers'
import {calculateLoanAmount} from '../sharedUtilities'


const equipmentFinanceBrokerEnquiryForm = {
  title: 'Broker equipment finance',
  model: 'equipment-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      // .prompt('Equipment Finance application')
      // .classes('border')
      .widgets(
        loanPurpose()
          .required(true)
          .display('none')
          .rules(ruleSet('"asset"'))
          .build(),
        contactType().candidatePartner().build(),
        helpContent().contentId('enquiry-form-asset-application-main').build(),
        container()
          .boxed(5)
          .prompt('Accredited broker details')
          .widgets(
            referrerFirstName().build(),
            referrerLastName().build(),
            referrerPhone().build(),
            referrerEmail().build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Asset details')
          .rules(ruleRender(isReferrerEmail))
          .widgets(
            assetPrice()
              .prompt('Asset purchase price')
              .min(15_000)
              .build(),
            assetDescription()
              .required(true)
              .build(),
            vendorSource()
              .build(),
          ).build(),
        proposedDeposit()
          .prompt('Deposit amount (if applicable)')
          .rules(ruleRender(isVendorSource))
          .build(),
        loanAmount()
          .prompt('Financed amount (calculated)')
          .min(15_000)
          .max(300_000)
          .required(false)
          .rules(ruleRender(isVendorSource), ruleSet(calculateLoanAmount))
          .display('read-only')
          .build(),
        preferredCommission()
          .prompt('What is your preferred brokerage?')
          .min(0)
          .max(8)
          .step(0.5)
          .required(true)
          .rules(ruleRender(isVendorSource))
          .build(),
        originationFee()
          .rules(ruleRender(isVendorSource))
          .build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isOriginationFee))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background and reason for the loan')
              .required(true)
              .rules(ruleRender(isOriginationFee))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            info().content('By completing these details you are confirming that you hold the guarantor\'s authority to provide them, ' +
              'including (where applicable) copies of documents containing personal identifying information.').build(),
            ...borrowerFullComposite(),
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Yes, another', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(isBorrowerDone))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(isBorrowerDone))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('assetInvoices', 'Invoice for the asset (optional at this stage)')
              .fileGroup('councilRates', 'Rates notice if property owner (optional at this stage)')
              .fileGroup('creditReports', 'Equifax credit report for individuals (optional but preferred)')
              .fileGroup('bankStatements', 'Illion electronic bank statement link in HTML format (optional)')
              .fileGroup('identityDocuments', 'Copy of Drivers License (optional but preferred)')
              .fileGroup('otherDocuments', 'Any other relevant documents (optional)')
              .includeComments(false)
              .build())
          .build())
      .build()
  ]
}

export default equipmentFinanceBrokerEnquiryForm
