import {v4 as uuid} from 'uuid'

class UploadService {
  /*@ngInject*/
  constructor(Upload, environment, loggerService) {
    this.Upload = Upload
    this.environment = environment
    this.logger = loggerService.makeLogger()
  }

  upload(file, problemFunction, inProgressFunction) {
    return this.exposedUpload(file, problemFunction, inProgressFunction).uploadPromise
  }

  exposedUpload(file, problemFunction, inProgressFunction) {
    const id = uuid()
    const key = id + '/' + file.name
    const fileType = file.type && file.type !== '' ? file.type : 'application/octet-stream'

    const completed = () => ({
      'id': id,
      'key': key,
      'name': file.name,
      'size': file.size,
      'mime': fileType,
      'url': this.getFileUploadUrl() + key
    })

    const problem = problemFunction && typeof problemFunction === 'function' ? problemFunction : (response) => { this.logger.log('Something went wrong.', response) }

    const defaultInProgress = (event) => { /* Do nothing by default. */ }
    const inProgress = inProgressFunction && typeof inProgressFunction === 'function' ? inProgressFunction : defaultInProgress

    const upload = this.Upload.upload({
      url: this.getFileUploadUrl(),
      method: 'POST',
      skipAuthorization: true,
      data: {
        'key': key,
        'acl': 'public-read',
        'Content-Type': fileType,
        'filename': file.name,
        'file': file
      }
    })
    return {uploadPromise: upload.then(completed, problem, inProgress), upload}
  }

  getFileUploadUrl() {
    return this.environment.TP_FILE_UPLOAD_URL
  }
}

export default UploadService
