import template from './loanEnquiryCreateDynamic.pug'
import controller from './loanEnquiryCreateDynamic.controller.js'
import '../../../marketing.scss'
import './loanEnquiryCreateDynamic.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
