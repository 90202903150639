import template from './reportInteraction.pug'
import controller from './reportInteraction.controller'
import './reportInteraction.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      reportType: '=',
      label: '=?',
      payloadResolver: '=',
      response: '='
    }
  }
}
