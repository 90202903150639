import moment from 'moment'

class RescheduleLoanController {
  /*@ngInject*/
  constructor(loanSchedulesRepository, dateService) {
    this.name = 'reschedule-loan'
    this.loanSchedulesRepository = loanSchedulesRepository
    this.dateService = dateService

    this.$onInit = () => {
      this._loadSchedule(this.loan)
    }
  }

  get loanTermInMonths() {
    return this.loan && Math.round(this._monthsBetween(this.loan.startDate, this.loan.endDate, true))
  }

  isBeforeNow(date) {
    return this.dateService.isBeforeNow(date)
  }

  reschedule() {
    return this.loan.performAction('reschedule', {
      rate: this.loan.rate,
      repaymentType: this.loan.repaymentType,
      repaymentFrequency: this.loan.repaymentFrequency,
      firstRepaymentDate: this._formatDate(this.loan.firstRepaymentDate),
      endDate: this._formatDate(this.loan.endDate),
      repaymentAmount: this.loan.repaymentAmount
    }).then(() => this.completion.resolve())
  }

  cancel() {
    this.completion.reject()
  }

  // private

  _loadSchedule(loan) {
    if (loan && this.loanSchedulesRepository) {
      this.loanSchedulesRepository.where({
        loanId: loan.id,
        startDate: this._formatDate(loan.startDate),
        endDate: this._formatDate(loan.endDate),
        rate: loan.rate,
        repaymentType: this.loan.repaymentType,
        repaymentFrequency: this.loan.repaymentFrequency,
        firstRepaymentDate: this._formatDate(loan.firstRepaymentDate),
        repaymentAmount: loan.repaymentAmount
      }).then((schedule) => this.schedule = schedule)
    }
  }

  _monthsBetween(from, to, precise) {
    return moment(to).diff(moment(from), 'months', precise)
  }

  _formatDate(fromDate, format) {
    return moment(fromDate).locale('en').format(format || 'YYYY-MM-DD')
  }
}

export default RescheduleLoanController
