import angular from 'angular'
import scheduleAuctionModule from './scheduleAuction/scheduleAuction'
import updateAuctionModule from './updateAuction/updateAuction'
import cancelLoanRequestModule from './cancelLoanRequest/cancelLoanRequest'
import createLoanModule from './createLoan/createLoan'

import auctionDetailsComponent from './auctionDetails.component'

export default angular.module('app.pages.admin.loanRequestDetails.auctionDetails', [
  scheduleAuctionModule.name,
  updateAuctionModule.name,
  cancelLoanRequestModule.name,
  createLoanModule.name]
)
  .directive('adminAuctionDetails', auctionDetailsComponent)
