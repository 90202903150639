import angular from 'angular'
import 'angular-ui-router'
import investmentEntityFormModule from './investmentEntityForm/investmentEntityForm'
import consentModule from './consent/consent'

import investorApplicationComponent from './investorApplication.component'

export default angular.module('app.pages.investors.investorApplication', ['ui.router', investmentEntityFormModule.name, consentModule.name])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('investorApplication', {
        url: '/investor-applications/:id',
        template: '<investor-application />',
        replace: true,
        data: {
          requireAuth: true,
          title: 'Register as an investor'
        }
      })
      .state('investorApplication.myDetails', {
        url: '/my-details',
        template: '<registration-form user-identity-promise="vm.userIdentityPromise" completion="vm.registrationCompletionHandler"/>',
        tabName: 'Registration form'
      })
      .state('investorApplication.investmentEntity', {
        url: '/investment-entity',
        template: '<investment-entity-form form-tracker="vm.formTracker" validation-property="entity"/>',
        tabName: 'Registration form'
      })
      .state('investorApplication.consentForm', {
        url: '/consents',
        template: '<consent form-tracker="vm.formTracker" validation-property="consents"/>',
        tabName: 'Registration form'
      })
  }])
  .directive('investorApplication', investorApplicationComponent)
