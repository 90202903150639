class DebitsClearedController {
  /*@ngInject*/
  constructor($rootScope, $q, $mdDialog, directDebitsRepository, dateService, abaViewerService) {
    this.name = 'debits-cleared'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.directDebitsRepository = directDebitsRepository
    this.dateService = dateService
    this.abaViewerService = abaViewerService

    this.order = ['-clearedDate', '-dueDate']
    this.selectedDebitIds = []

    this.$onInit = () => {
      this.reload()
    }
  }

  reload() {
    const today = this.dateService.now().format('YYYY-MM-DD')
    this.selectedDebitIds = []

    this.loading = this.directDebitsRepository.where({scope: 'all', status: 'processing'}).then(data => {
      this.directDebits = data.directDebits.filter(directDebit => (directDebit.clearedDate <= today))
    })
  }

  viewABA($event, abaId, selectedDebitId) {
    this.abaViewerService.show($event, abaId, selectedDebitId)
  }

  createLoanRepayment($event, debit) {
    const scope = this.$rootScope.$new(false)
    const type = debit.type === 'fee' ? 'additional' : 'standard'

    scope.loan = debit.loan
    scope.debit = debit
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.reload())
      .finally(() => this.$mdDialog.hide())

    return this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      template: debit.type === 'final' ? this._finalLoanRepaymentTemplate() : this._createloanRepaymentTemplate(type),
      scope
    })
  }

  markAsRejected() {
    return this._confirmRejections().then(() => this._performActions('reject'))
  }

  markAsDisbursed() {
    return this._confirmDisbursements().then(() => this._performActions('disburse'))
  }

  // private

  _createloanRepaymentTemplate(type) {
    return `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-create-loan-repayment loan='loan' type='${type}' debit='debit' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
  }

  _finalLoanRepaymentTemplate() {
    return`
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-final-loan-repayment loan='loan' debit='debit' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
  }

  _confirmRejections() {
    const message = (this.selectedDebitIds.length === 1) ?
      'Are you sure you want to mark this debit as rejected?' :
      `Are you sure you want to mark these ${this.selectedDebitIds.length} debits as rejected?`
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(message)
        .clickOutsideToClose(true)
        .title(`Rejected debits`)
        .ariaLabel('Rejected debits')
        .ok('Do it')
        .cancel('No')
    )
  }

  _confirmDisbursements() {
    const message = (this.selectedDebitIds.length === 1) ?
      'Are you sure you want to mark this debit as disbursed?' :
      `Are you sure you want to mark these ${this.selectedDebitIds.length} debits as disbursed?`
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(message)
        .clickOutsideToClose(true)
        .title(`Disbursed debits`)
        .ariaLabel('Disbursed debits')
        .ok('Do it')
        .cancel('No')
    )
  }

  _performActions(action) {
    let results = this.selectedDebitIds.map(id => this.directDebitsRepository.getById(id)
      .then((debit) => debit.performAction(action)))

    return Promise.all(results).then((values) => this.reload())
  }
}

export default DebitsClearedController
