import template from './loanHistory.pug'
import controller from './loanHistory.controller'
import './loanHistory.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loanRequest: '=loanRequest'
    },
    bindToController: true
  }
}
