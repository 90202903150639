import resolveProperty from '../../../../utils/resolveProperty'
import concatNames from '../../../../utils/concatNames'
import {representativeAccountStatus, representativeConsentStatus} from '../../../../utils/representativeHelper'
import {isLoanEnquiryMutable, isLoanEnquiryForTrust} from '../../../../utils/loanEnquiryStatusHelper'
import makeAddRepresentative from './addRepresentative'

class RepresentativesController {
  /*@ngInject*/
  constructor($scope, $q, $mdDialog, $mdToast, representativeInvitationsRepository, greenidVerifyService) {
    this.name = 'enquiry-details-representatives'
    this.$scope = $scope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.representativeInvitationsRepository = representativeInvitationsRepository
    this.greenidVerifyService = greenidVerifyService
    this.loanEnquiryDetails = undefined
    this.copyToReferrer = false

    this._representativeActionStatus = new Map()

    const initRepresentatives = () => {
      this.addRepresentative = makeAddRepresentative($scope, $q, $mdDialog, this.loanEnquiry, () => ({}))

      $scope.$on('loanEnquiryEvent', (event, ...args) => {
        if (args[0] === 'reloadRepresentatives') {
          event.preventDefault()
          event.stopPropagation()
          this.reloadRepresentatives()
        }
      })

      this.loanEnquiry.promise('representatives').then(() => {
        $scope.$watch(() => this.loanEnquiry.representatives, representatives => this.representatives = representatives)
      })

      this.loanEnquiry.promise('loanEnquiryDetails').then(loanEnquiryDetails => {
        this.loanEnquiryDetails = loanEnquiryDetails
      })

      if (typeof this.loanEnquiry.loanEnquirySummary?.loan?.assetRideShareElectricVehicle === 'string') {
        this.copyToReferrer = this.loanEnquiry.loanEnquirySummary.loan.assetRideShareElectricVehicle.match(/yes/i) !== null
      }
    }

    this.$onInit = () => {
      initRepresentatives()
    }
  }

  get isMutable() {
    return isLoanEnquiryMutable(this.loanEnquiry)
  }

  get isTrust() {
    return isLoanEnquiryForTrust(this.loanEnquiry)
  }

  get hasRepresentatives() {
    return this.representatives && Array.isArray(this.representatives) && this.representatives.length > 0
  }

  representativeAccountStatus(representative) {
    return representativeAccountStatus(representative)
  }

  representativeConsentStatus(representative) {
    return representativeConsentStatus(representative)
  }

  // Representative actions - button appearance

  representativeCanSendIdEmail(representative) {
    return resolveProperty(representative, 'user', 'identityCheck', 'status') !== 'verified'
  }

  representativeSendIdEmailLabel(representative) {
    return resolveProperty(representative, 'identityEmailSent') ? 'Re-send ID email' : 'Send ID email'
  }

  representativeSendConsentEmailLabel(representative) {
    return resolveProperty(representative, 'consentRequestSent') ? 'Re-send consent email' : 'Send consent email'
  }

  representativeCanUpdateStatus(representative) {
    return representative.user && (!representative.user.identityCheck || representative.user.identityCheck.status !== 'verified')
  }

  representativeCanSendConsentEmail(representative) {
    return resolveProperty(representative, 'user', 'identityCheck', 'status') === 'verified' && !resolveProperty(representative, 'consent', 'hasAuthorisedCreditCheck')
  }

  userShortName(user) {
    return user ? concatNames(user.firstName, user.lastName) : ''
  }

  userFullName(user) {
    return user ? concatNames(user.firstName, user.middleName, user.lastName) : ''
  }

  reloadRepresentatives() {
    return this.loanEnquiry.reloadResource('representatives')
  }

// Representative actions - processing

  representativeSendIdEmail(event, discriminator, representative) {
    event.stopPropagation()
    this._representativeActionStarted(discriminator, representative)
    this.representativeInvitationsRepository.create({type: this.andReferrer('borrower'), id: representative.id})
      .then(() => this.loanEnquiry.reloadResource('representatives'))
      .catch((error) => this._showMessage(`There was an error emailing the identity check link. (Error message: ${error.data.message})`))
      .finally(() => this._representativeActionDone(representative))
  }

  representativeSendConsentEmail(event, discriminator, representative) {
    event.stopPropagation()
    this._representativeActionStarted(discriminator, representative)
    this.representativeInvitationsRepository.create({type: this.andReferrer('borrowerConsent'), id: representative.id})
      .then(() => this.loanEnquiry.reloadResource('representatives'))
      .catch((error) => this._showMessage(`There was an error emailing the consent check link. (Error message: ${error.data.message})`))
      .finally(() => this._representativeActionDone(representative))
  }

  representativeUpdateIdCheckStatus(event, discriminator, representative) {
    event.stopPropagation()
    if (resolveProperty(representative, 'user', 'identityCheck')) {
      this._representativeActionStarted(discriminator, representative)
      this.greenidVerifyService.updateStatus(representative.user.identityCheck.id)
        .then(() => this.loanEnquiry.reloadResource('representatives'))
        .catch((error) => this._showMessage(`There was an error updating the identity check status. (Error message: ${error.data.message})`))
        .finally(() => this._representativeActionDone(representative))
    } else {
      this.loanEnquiry.reloadResource('representatives')
    }
  }

  representativeActionInProgress(representative) {
    const actionStatus = this._representativeActionStatus.get(representative.id)
    return actionStatus === undefined ? false : actionStatus.status
  }

  representativeSpecificActionInProgress(discriminator, representative) {
    const actionStatus = this._representativeActionStatus.get(representative.id)
    return actionStatus === undefined || actionStatus.discriminator !== discriminator ? false : actionStatus.status
  }

  phoneNumber(user) {
    return (user && user.phone) || '- no phone provided -'
  }

  dob(user) {
    return (user && user.dob) || '- no date of birth provided -'
  }

  fullAddress(user) {
    return (user && user.address) || '- no address provided -'
  }

  andReferrer(type) {
    return this.copyToReferrer ? (type + 'AndReferrer') : type
  }

  cancelAction(representative, index) {
    if (this.representativeForm && this.representativeForm[index]) {
      this.representativeForm[index].$setPristine()
    }
    return representative.reload()
  }

  confirmAction(representative, index) {
    if (this.representativeForm && this.representativeForm[index]) {
      this.representativeForm[index].$setPristine()
    }
    return representative.save()
  }

  // ****** private

  _hasAddress(candidateAddress) {
    return candidateAddress && candidateAddress.fullAddress && candidateAddress.fullAddress.trim().length > 0
  }

  _createActionStatus(status, discriminator) {
    return ({status, discriminator})
  }

  _representativeActionStarted(discriminator, representative) {
    return this._representativeActionStatus.set(representative.id, this._createActionStatus(true, discriminator))
  }

  _representativeActionDone(representative) {
    return this._representativeActionStatus.set(representative.id, this._createActionStatus(false, undefined))
  }

  _showMessage(message, hideDelay=5000) {
    this.$mdToast.show(this.$mdToast.simple().position('top left').hideDelay(hideDelay).textContent(message))
  }
}

export default RepresentativesController
