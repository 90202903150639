import template from './scheduleAuction.pug'
import controller from './scheduleAuction.controller'
import './scheduleAuction.scss'

export default () => {
  return {
    template: template({}),
    controller: controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loanRequest: '=',
      completion: '=',
    },
    bindToController: true
  }
}
