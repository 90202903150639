import template from './embeddedEnquiryForm.pug'
import controller from './embeddedEnquiryForm.controller'
import './embeddedEnquiryForm.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    link: (scope, el, attr, ctrls) => {
      scope.vm.baseElement = el
    },
    transclude: true,
    scope: {},
    bindToController: {
      nameForm: '@',
      nameResponse: '@',
      clickTracking: '@?',
      completion: '='
    }
  }
}
