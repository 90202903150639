import ApiFormManager from './apiFormManager'

export default ($q, $mdToast, $window) => {
  return function link(scope, element, attrs, form) {
    const manager = new ApiFormManager(scope, form, attrs.apiForm, $q, $mdToast, $window)
    form.$clearApiError = (name) => manager.clearError(name)
    Object.defineProperty(form, '$submittable', {
      configurable: true, get: () => manager.submittable()
    })
    element.bind('submit', function(event) {
      manager.submit(event)
      event.preventDefault()
    })
  }
}
