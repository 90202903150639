const formInvestor = {
  title: 'Investor',
  model: 'investor',
  actionLabel: 'Update',
  widgets: [
    {
      type: 'Container',
      definition: {
        prompt: 'Investor details',
        classes: 'distinct border box-shadow-2',
        widgets: [
          {
            type: 'TFN',
            definition: {
              model: 'tfn',
              prompt: 'Tax file number',
              required: false
            }
          },
          {
            type: 'String',
            definition: {
              rules: [
                {type: 'render', rule: '(and (isDefined (fromModel "entityType")) (not (eq (fromModel "entityType") "individual")))'}
              ],
              model: 'name',
              prompt: 'Investor entity name',
              required: true
            }
          },
          {
            type: 'String',
            definition: {
              model: 'taxJurisdiction',
              prompt: 'Tax jurisdiction (Australia is \'domestic\')',
              required: true
            }
          },
        ]
      }
    }
  ]
}

export default formInvestor
