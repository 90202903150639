const MESSAGE_WIDTH = 320
const ARROW_WIDTH = 13

class HelpMateController {
  /*@ngInject*/
  constructor($element, hopscotch, helpMatesService, $window, $rootScope, $mdMedia) {
    this.name = 'helpMate'
    this.$element = $element
    this.calloutManager = hopscotch.getCalloutManager()
    this.helpMatesService = helpMatesService
    this.$window = $window
    this.$rootScope = $rootScope
    this.$mdMedia = $mdMedia

    const loadHelpMate = (topic) => {
      this.helpMatesService.getHelpByTopic(topic).then((helpMate) => {
        this.helpMate = helpMate
      })
    }

    this.$onInit = () => {
      if (this.computedTopic) {
        $rootScope.$watch(() => this.computedTopic, (current) => {
          loadHelpMate(current)
        })
      } else {
        loadHelpMate(this.topic)
      }
    }
  }

  click(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  mouseup() {
    this.calloutManager.removeAllCallouts()
    this.helpMate.target = this.$element[0]
    this._calculateArrowOffset(this.helpMate)
    if (this.$mdMedia('xs')) {
      this._centerHelpMate(this.helpMate)
    }
    this.calloutManager.createCallout(this.helpMate)
    this._addListeners(this.$window, this.$rootScope)
  }

  // private

  _calculateArrowOffset(helpMate) {
    if (helpMate.xOffset === 'center' || helpMate.yOffset === 'center') {
      helpMate.arrowOffset = 'center'
    } else if (helpMate.placement === 'top' || helpMate.placement === 'bottom') {
      helpMate.arrowOffset = -14 - (helpMate.xOffset || 0)
    }
  }

  _centerHelpMate(helpMate) {
    const clientWidth = this.$window.document.getElementsByTagName('content')[0].clientWidth
    const offsetLeft = this._offsetLeft(this.$element[0])
    helpMate.placement = 'top'
    helpMate.xOffset = (clientWidth - MESSAGE_WIDTH) / 2 - offsetLeft
    helpMate.arrowOffset = offsetLeft - (clientWidth - MESSAGE_WIDTH) / 2 - ARROW_WIDTH
    helpMate.yOffset = null
  }

  _offsetLeft(elem) {
    let left = 0
    while (elem) {
      left += elem.offsetLeft
      elem = elem.offsetParent
    }
    return left
  }

  _addListeners($window, $rootScope) {
    this.content = angular.element($window.document.getElementsByTagName('content')[0])

    this.content.on('scroll', () => {
      this.calloutManager.removeAllCallouts()
      this.content.off('scroll')
    })

    this.content.on('click', (event) => {
      const target = angular.element(event.target)[0]
      if (!target.className || !target.className.indexOf || target.className.indexOf('help-mate') < 0) {
        this.calloutManager.removeCallout(this.helpMate.id)
        this.content.off('click')
      }
    })

    this.removeListener = $rootScope.$on('$locationChangeSuccess', () => {
      this.calloutManager.removeAllCallouts()
      this.removeListener()
    })
  }
}

export default HelpMateController
