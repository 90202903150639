import template from './dynamicForm.pug'
import controller from './dynamicForm.controller'
import './dynamicForm.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: true,
    bindToController: {
      model: '=',
      linkToUri: '=',
      formDefinition: '<',
      showSubmit: '=',
      showAction: '=?',
      showCancel: '=?',
      saveFn: '=',
      submitFn: '=',
      cancelFn: '=?',
      submitTrigger: '=?',
      submitUntouched: '=?',
      actionLabel: '<',
      cancelLabel: '<',
      clickTracking: '@?',
      rulesContext: '=?',
      requiresConfirmation: '=?'
    }
  }
}
