class CombinedRatingController {
  /*@ngInject*/
  constructor($filter) {
    this.name = 'combinedRating'

    this.$onInit = () => {
      this.rating = `${$filter('asRiskRating')(this.riskRating)}${this.securityRating}`
    }
  }

  get styling() {
    return [
      this.selected ? 'include' : 'exclude',
      this.hasOwnProperty('disabled') && this.disabled ? 'disabled' : 'enabled'
    ]
  }
}

export default CombinedRatingController
