import template from './stepBall.pug'
import controller from './stepBall.controller'
import './stepBall.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      step: '=',
      ngModel: '=',
      min: '=',
      max: '=',
      direction: '@',
      disabled: '='
    }
  }
}
