import angular from 'angular'

import adminDashboardModule from './adminDashboard/adminDashboard'
import borrowerDetailsModule from './borrowerDetails/borrowerDetails'
import loanRequestDetailsModule from './loanRequestDetails/loanRequestDetails'
import investorApplicationDetailsModule from './investorApplicationDetails/investorApplicationDetails'
import loanDetailsModule from './loanDetails/loanDetails'
import loanEnquiryDetailsModule from './loanEnquiryDetails/loanEnquiryDetails'
import loanEnquiryCreateDynamicModule from './loanEnquiryCreateDynamic/loanEnquiryCreateDynamic'

export default angular.module('app.pages.admin', [
  adminDashboardModule.name,
  borrowerDetailsModule.name,
  loanRequestDetailsModule.name,
  investorApplicationDetailsModule.name,
  loanDetailsModule.name,
  loanEnquiryDetailsModule.name,
  loanEnquiryCreateDynamicModule.name,
])
