import template from './countdownTimer.pug'
import controller from './countdownTimer.controller'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      endDate: '='
    },
    bindToController: true
  }
}
