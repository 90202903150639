import moment from 'moment'
import Big from 'big.js'

class InvestorTotals {
  constructor(investor, dateService) {
    this.investor = investor
    this.dateService = dateService
  }

  availableFunds() {
    return this.investor && this.investor.availableFunds
  }

  totalBids(loanRequest) {
    let _totalBids = '0'
    if (loanRequest && loanRequest.auctionId && this.investor && this.investor.bids) {
      _totalBids = this.investor.bids
        .filter(bid => bid.auctionId === loanRequest.auctionId)
        .reduce((memo, bid) => memo.plus(bid.activeAmount), new Big(0))
        .toString()
    }
    return _totalBids
  }

  totalParts(loanRequest) {
    let _totalParts = '0'
    if (loanRequest && loanRequest.loanId && this.investor && this.investor.loanParts) {
      _totalParts = this.investor.loanParts
        .filter(loanPart => loanPart.loanId === loanRequest.loanId)
        .reduce((memo, loanPart) => memo.plus(loanPart.capitalOutstanding), new Big(0))
        .toString()
    }
    return _totalParts
  }

  totalListings(loanRequest) {
    let _totalListings = '0'
    if (loanRequest && loanRequest.loanId && this.investor && this.investor.listings) {
      _totalListings = this.investor.listings
        .filter(listing => listing.loanId === loanRequest.loanId)
        .reduce((memo, listing) => memo.plus(this._currentAmount(listing)), new Big(0))
        .toString()
    }
    return _totalListings
  }

  // private

  _currentAmount(listing) {
    return moment(listing.startDate).isBefore() && moment(listing.endDate).isAfter() ? listing.amount : 0
  }
}

export default InvestorTotals
