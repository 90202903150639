class PatchFormController {
  /*@ngInject*/
  constructor($scope, formErrors) {
    this.name = 'patchForm'
    this.formErrors = formErrors

    $scope.$watch('vm.form.$valid', (newValue, oldValue) => {
      this._setValidity(newValue)
    })
  }

  update() {
    if (this.formTracker) {
      this.formTracker.update()
    }
  }

  set validationProperty(value) {
    this._validationProperty = value
    this._showValidationMessages()
  }

  get validationProperty() {
    return this._validationProperty
  }

  set form(value) {
    this._form = value
    this._showValidationMessages()
  }

  get form() {
    return this._form
  }

  set formTracker(value) {
    this._formTracker = value
    this._showValidationMessages()
  }

  get formTracker() {
    return this._formTracker
  }

  _showValidationMessages() {
    if(this.form && this.formTracker && this.validationProperty) {
      if(this.formTracker.shouldValidate(this.validationProperty)) {
        this.formErrors.triggerMessages(this.form)
      }
      this.formTracker.setFormVisited(this.validationProperty)
    }
  }

  _setValidity(valid) {
    if(this.formTracker && this.form) {
      this.formTracker.setFormValid(this.validationProperty, valid)
    }
  }
}

export default PatchFormController
