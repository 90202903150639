import pdfMake from 'pdfmake/build/pdfmake'
import pdfMakeDefault from 'pdfmake/build/vfs_fonts'
import pdfVfsConfig from './pdf.vfs.config'

class PdfService {
  /*@ngInject*/
  constructor() {
    pdfMake.vfs = Object.assign(pdfMakeDefault.pdfMake.vfs, pdfVfsConfig)
  }

  createAndDownload(name, definition) {
    pdfMake.createPdf(definition).download(name)
  }

  // -- Document configuration helpers
  truncateContent(content, maxLength = 17) {
    return ''.concat(content.slice(0, maxLength), content.length > maxLength ? '…' : '')
  }

  // Formatting money.
  asMoney(rawMoney) {
    return rawMoney.round(2, 1).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // -- Format the table cells for presentation.
  thl(content, rowIndex = -1, options = {}) {
    return this._makeCell(content, rowIndex, Object.assign({bold: true, alignment: 'left', fontSize: 9}, options))
  }
  thr(content, rowIndex = -1, options = {}) {
    return this._makeCell(content, rowIndex, Object.assign({bold: true, alignment: 'right', fontSize: 9}, options))
  }
  tdl(content, rowIndex = -1, options = {}) {
    return this._makeCell( content, rowIndex, Object.assign({bold: false, alignment: 'left', fontSize: 9}, options))
  }
  tdr(content, rowIndex = -1, options = {}) {
    return this._makeCell( content, rowIndex, Object.assign({bold: false, alignment: 'right', fontSize: 9}, options))
  }

  createDocumentDefinition(reportDate, mainHeading, subHeading, ...contentParts) {
    const baseDocDefinition = {
      pageSize: 'A4',
      footer: (currentPage, pageCount) => {
        return {
          text: `${reportDate} : Page ${currentPage.toString()} of ${pageCount.toString()}`,
          alignment: 'center',
          fontSize: 7
        }
      },

      styles: {
        title: {
          fontSize: 24
        },
        titleSub: {
          fontSize: 18
        },
        titleDate: {
          fontSize: 14,
          alignment: 'right',
          bold: true
        }
      },

      content: [
        {
          columns: [
            {text: mainHeading, style: 'title', width: '*'},
            {text: reportDate, style: 'titleDate', width: '160'},
          ]
        },
        {text: `${subHeading}\n\n`, style: 'titleSub'},
      ],
    }
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition))
    docDefinition.footer = baseDocDefinition.footer
    docDefinition.content.push(...contentParts)
    return docDefinition
  }

  // -- private

  _makeCell(content, rowIndex = -1, options = {}) {
    return Object.assign({text: content, fillColor: rowIndex % 2 ? 'white' : '#e8e8e8'}, options)
  }

}
export default PdfService
