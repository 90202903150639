class JournalEntriesViewController {
  /*@ngInject*/
  constructor($scope, csvService, localStorageService, journalEntriesRepository) {
    this.name = 'journalEntriesView'
    this.csvService = csvService
    this.journalEntriesRepository = journalEntriesRepository
    this.limitOptions = [5, 10, 20, 50, 100]
    this.page = 1
    this.total = 0

    localStorageService.bind($scope, 'vm.limit', 50, 'journal-entries.limit')

    this.reloadData = () => {
      if (this.conditions) {
        this.conditions.size = this.limit
        this.conditions.page = this.page
        this.loadingPromise = this.journalEntriesRepository.where(this.conditions).then((data) => {
          this.journalEntries = data.journalEntries
          this.total = data.total
        })
      }
    }

    $scope.$watch('vm.conditions', () => {
      this.page = 1
      this.reloadData()
    })
  }

  startCsvDownload() {
    if (!this.prepareCsvDownload) {
      this.prepareCsvDownload = true
      delete this.journalEntriesCsv
      this._loadJournalEntries([], JSON.parse(JSON.stringify(this.conditions)), 500, 1)
    }
  }

  _loadJournalEntries(journalEntries, crystallizedConditions, size, page) {
    this.journalEntriesRepository.where(Object.assign(crystallizedConditions, {size: size, page: page})).then((data) => {
      journalEntries = journalEntries.concat(data.journalEntries)
      if (data.journalEntries.length >= size) {
        this._loadJournalEntries(journalEntries, crystallizedConditions, size, page + 1)
      } else {
        this.prepareCsvDownload = false
        this.csvService.createAndDownload('transactions.csv', this._createCsv(journalEntries))
      }
    })
  }

  _createCsv(journalEntries) {
    return journalEntries.map(entry => {
      let row = {}
      row['Ref'] = entry.id
      row['Date'] = entry.transactionDate
      row['Description'] = entry.description
      if (this.displayAccountType) row['Account'] = entry.accountType
      row['Debit'] = entry.credit ? '' : entry.amount
      row['Credit'] = entry.credit ? entry.amount : ''
      row['Balance'] = entry.balance
      return row
    })
  }
}

export default JournalEntriesViewController
