import moment from 'moment'

class MediaService {
  constructor() {

    const serviceToView = item => {
      return {
        description: item.description,
        heading: item.heading,
        icon: `https:${item.icon.fields.file.url}`,
        iconAlt: item.icon,
        link: item.link,
        published: moment(item.published)
      }
    }

    const reverseSort = (a, b) => (a.published < b.published) ? 1 : (a.published > b.published) ? -1 : 0

    this.mapToViewOrdered = media => media.map(serviceToView).sort(reverseSort)
  }
}

export default MediaService
