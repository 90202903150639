class LoanEnquiryDetailsController {
  /*@ngInject*/
  constructor($stateParams, $scope, loanEnquiriesRepository) {
    this.name = 'enquiry-details'
    this.uuid = $stateParams.uuid
    this.resourceCache = new Map()
    this.$scope = $scope
    this.loanEnquiriesRepository = loanEnquiriesRepository

    this.locateEnquiry = 'searching'
  }

  $onInit() {
    // ****** Communicate between summary directives.
    const targetEvent = 'summaryEvent'
    this.$scope.$on(targetEvent, (event, ...args) => {
      if (event.hasOwnProperty('stopPropagation')) {
        event.stopPropagation()
      }
      event.preventDefault()
      this.$scope.$broadcast(targetEvent, ...args)
    })

    // **** Get the Loan Enquiry from the URL parameter.
    this.loanEnquiriesRepository.getById(this.uuid).then((loanEnquiry) => {
      this.locateEnquiry = 'found'
      this.loanEnquiry = loanEnquiry
    }, () => {
      this.locateEnquiry = 'notFound'
    })
  }
}

export default LoanEnquiryDetailsController
