import Big from 'big.js'
import moment from 'moment'

const ONE_DAY_IN_SECONDS = 60 * 60 * 24

class LoanRequestNavigator {
  static create(dateService, loanRequests, auctions, listings) {
    return new LoanRequestNavigator(dateService, loanRequests, auctions, listings)
  }

  constructor(dateService, loanRequests, auctions, listings) {
    this.dateService = dateService

    const mappedAuctions = auctions.reduce((map, auction) => {
      map.set(auction.id, auction)
      return map
    }, new Map())

    loanRequests.forEach((loanRequest) => {
      if (loanRequest.auctionId) {
        const auction = mappedAuctions.get(loanRequest.auctionId)
        if (auction) {
          loanRequest.setResource('auction', auction)
        }
      }
    })

    const loanListings = this._aggregateListings(listings)
    loanRequests.forEach((loanRequest) => {
      const loanListing = loanListings.get(loanRequest.loanId)
      if (loanRequest.loanId && loanListing) {
        loanRequest.listingSummary = loanListing
      } else {
        delete loanRequest.listingSummary
      }
    })

    this._listings = listings
    this._auctions = auctions
    this._loanRequests = this._sort(loanRequests)
  }

  hasListings() {
    return this._listings.length > 0
  }

  hasAuctions() {
    return this._auctions.length > 0
  }

  hasLoanRequests() {
    return this._loanRequests.length > 0
  }

  loanRequests() {
    return this.hasLoanRequests() ? this._loanRequests : []
  }

  loanRequestsWithActiveAuction() {
    return this._loanRequests.filter(loanRequest => loanRequest.auction && this._isAfterNow(loanRequest.auction.endDate))
  }

  loanRequestsWithInProgressAuction() {
    return this._loanRequests.filter(loanRequest => loanRequest.auction && this._isBeforeNow(loanRequest.auction.startDate) && this._isAfterNow(loanRequest.auction.endDate))
  }

  loanRequestsWithListedLoan() {
    return this._loanRequests.filter(loanRequest => loanRequest.listingSummary)
  }

  loanRequestsWithListedActiveLoan() {
    return this._loanRequests.filter(loanRequest => loanRequest.listingSummary && loanRequest.loanId && loanRequest.loanSummary
      && (loanRequest.loanSummary.status === 'active' || loanRequest.loanSummary.status === 'deferred'))
  }

  loanRequestsWithCompletedAuctionAndUnlistedLoans() {
    return this._loanRequests.filter(loanRequest => !loanRequest.listingSummary &&
      ((loanRequest.loanId && loanRequest.loanSummary && loanRequest.loanSummary.status !== 'completed') ||
      (!loanRequest.loanId && loanRequest.auction && this._isBeforeNow(loanRequest.auction.endDate))))
  }

  loanRequestsWithUnlistedActiveLoans() {
    return this._loanRequests.filter(loanRequest => !loanRequest.listingSummary && loanRequest.loanId && loanRequest.loanSummary
      && (loanRequest.loanSummary.status === 'active' || loanRequest.loanSummary.status === 'deferred'))
  }

  hasListingsByLoanIdAndInvestorId(loanId, investorId) {
    return this._listings.some(listing => listing.loanId === loanId && listing.investorId === investorId)
  }

  listingsByLoanIdAndInvestorId(loanId, investorId) {
    return this._listings.filter(listing => listing.loanId === loanId && listing.investorId === investorId)
  }

  loanRequestById(loanRequestId) {
    return loanRequestId && this._loanRequests.find(loanRequest => loanRequest.id && loanRequest.id.toString() === loanRequestId.toString())
  }

  loanRequestType(loanRequest) {
    if (loanRequest) {
      if (loanRequest.status === 'fulfilled') {
        return loanRequest.listingSummary && loanRequest.listingSummary.totalAmount ? 'listed' : 'completed'
      } else if (loanRequest.status === 'scheduled') {
        let secondsUntilEnd = this.dateService.getSecondsTill(loanRequest.auction.endDate)
        if (secondsUntilEnd === 0) return 'completed'
        if (secondsUntilEnd < ONE_DAY_IN_SECONDS) return 'ending'
        if (this.dateService.getSecondsSince(loanRequest.auction.startDate) < ONE_DAY_IN_SECONDS) return 'new'
        return 'active'
      }
    }
    return 'unknown'
  }

  // private

  _isBeforeNow(date) {
    return this.dateService.getSecondsTill(date) <= 0
  }

  _isAfterNow(date) {
    return this.dateService.getSecondsTill(date) > 0
  }

  _isAuctionCompleted(auction) {
    return auction && this._isBeforeNow(auction.endDate)
  }

  _sort(loanRequests) {
    return loanRequests.sort((lhs, rhs) => this._comparator(lhs, rhs))
  }

  _comparator(lhs, rhs) {
    if (!this.hasAuctions()) {
      return -1
    }

    if (lhs.loanId && rhs.loanId) {
      // Both lhs and rhs with loans - order : loan with highest listing > loan with lowest listing > loan with no listing in creation order
      let lhsListingAmount = lhs.listingSummary ? lhs.listingSummary.totalAmount : 0
      let rhsListingAmount = rhs.listingSummary ? rhs.listingSummary.totalAmount : 0
      return lhsListingAmount !== rhsListingAmount ? rhsListingAmount - lhsListingAmount : rhs.loanId - lhs.loanId
    } else if (lhs.loanId && !rhs.loanId) {
      // Only lhs with loan - order : active auctions > loans > completed auctions
      return this._isAuctionCompleted(rhs.auction) ? -1 : 1
    } else if (!lhs.loanId && rhs.loanId) {
      // Only rhs with loan - order : active auctions > loans > completed auctions
      return this._isAuctionCompleted(lhs.auction) ? 1 : -1
    } else {
      // Both without loans - order : unfinished auctions > completed auctions
      let lhsCompleted = this._isAuctionCompleted(lhs.auction)
      let rhsCompleted = this._isAuctionCompleted(rhs.auction)
      if (lhsCompleted) {
        return rhsCompleted ? moment(lhs.auction.endDate) - moment(rhs.auction.endDate) : 1
      } else {
        // lhs with unfinished auction
        return rhsCompleted ? -1 : moment(lhs.auction.endDate) - moment(rhs.auction.endDate)
      }
    }
  }

  _aggregateListings(loanPartListings) {
    const makeEntry = (minRate, maxRate, marginRate, minAmount, maxAmount, totalAmount) => ({minRate, maxRate, marginRate, minAmount, maxAmount, totalAmount})
    return loanPartListings.reduce((memo, listing) => {
      const existingSummary = memo.get(listing.loanId)
      memo.set(listing.loanId, !existingSummary ?
        makeEntry(listing.rate, listing.rate, listing.marginRate, listing.amount, listing.amount, listing.amount) :
        makeEntry(
          this._min(existingSummary.minRate, listing.rate),
          this._max(existingSummary.maxRate, listing.rate),
          listing.marginRate,
          this._min(existingSummary.minAmount, listing.amount),
          this._max(existingSummary.maxAmount, listing.amount),
          this._add(existingSummary.totalAmount, listing.amount)))
      return memo
    }, new Map())
  }

  _min(a, b) {
    return new Big(a).lt(b) ? a : b
  }

  _max(a, b) {
    return new Big(a).gt(b) ? a : b
  }

  _add(a, b) {
    return (new Big(a).plus(b)).toString()
  }
}

export default LoanRequestNavigator
