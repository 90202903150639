import template from './loanRequestDetails.pug'
import controller from './loanRequestDetails.controller'
import './loanRequestDetails.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      showFinancials: '&'
    },
  }
}
