import {
  businessFullComposite,
  contactType,
  isLoanAmount,
  isBusinessEntity,
  isBorrowerDone,
  loanAmount,
  loanOriginationFixed,
  loanPurposeFixed,
  nCinoLeadId,
  reeceAccountNumber, assetPrice,
  vendorSourceFixed,
  isReeceAccountNumber,
  borrowerFullComposite
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  ruleRender,
  ruleValidation,
  conjunction,
  and,
  fromModel,
  isModelDefined
} from '../../../dynamicForm/helpers/dynamicFormHelpers'


const isValidAssetPrice = and(
  isModelDefined('assetPrice'),
  isModelDefined('loanAmount'),
  conjunction('fle')( fromModel('assetPrice'), fromModel('loanAmount')))


const toolsFinanceAdminEnquiryForm = {
  title: 'Admin tools finance scenario',
  model: 'tools-finance-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Tools finance application')
      .classes('border')
      .widgets(
        // Fixed values.
        loanPurposeFixed().toolsFinance().build(),
        contactType().admin().build(),
        loanOriginationFixed().laddr().build(),
        vendorSourceFixed().reece().build(),
        helpContent().contentId('enquiry-form-tools-finance-application-main').build(),
        // Variable values.
        container()
          .boxed(5)
          .prompt('Lead details')
          .widgets(
            nCinoLeadId().build(),
            reeceAccountNumber()
              .prompt('Reece trade account number')
              .required(true)
              .build(),
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isReeceAccountNumber))
          .widgets(
            ...businessFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isBusinessEntity))
          .widgets(
            helpContent().contentId('enquiry-form-tools-finance-borrower-contact-direct').build(),
            ...borrowerFullComposite()
          ).build(),
        container()
          .boxed(5)
          .prompt('Loan details')
          .rules(ruleRender(isBorrowerDone))
          .widgets(
            helpContent().contentId('enquiry-form-tools-finance-amounts').build(),
            loanAmount()
              .prompt('Loan facility (limit) amount')
              .min(1000)
              .max(15000)
              .required(true)
              .decimals(2)
              .build(),
            assetPrice()
              .prompt(`Tools purchase price`)
              .min(1000)
              .max(15000)
              .decimals(2)
              .required(true)
              .addRules(
                ruleRender(isLoanAmount),
                ruleValidation(isValidAssetPrice, 'Asset price must be less than or equal to the loan facility amount.')
              )
              .build()
          ).build(),
      ).build()
  ]
}

export default toolsFinanceAdminEnquiryForm
