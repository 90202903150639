class WithdrawalsDeclinedController {
  /*@ngInject*/
  constructor($scope, withdrawalService, localStorageService) {
    this.name = 'withdrawals-declined'
    this.withdrawalService = withdrawalService

    this.search = ''
    this.limitOptions = [5, 10, 20, 50, 100]
    this.order = '-UpdatedAt'
    this.page = 1
    localStorageService.bind($scope, 'vm.limit', 50, 'admin-withdrawal-requests.limit')

    this.mdReload = () => this.reload() // this is required for md-reorder-on to work

    this.total = 0
    this.withdrawals = []

    this.$onInit = () => {
      this.reload()
    }
  }

  get hasDeclined() {
    return this.withdrawals.length > 0
  }

  reload() {
    return this._loadWithdrawals()
  }

  performSearch() {
    this.page = 1
    return this.reload()
  }

  _loadWithdrawals() {
    const conditions = {
      search: this.search,
      order: this.order,
      page: this.page,
      size: this.limit
    }
    return this.withdrawalService.declinedWithdrawalsPromise(conditions).then((data) => {
      this.withdrawals = data.withdrawalRequests
      this.total = data.total
      return true
    })
  }
}

export default WithdrawalsDeclinedController
