import angular from 'angular'
import 'angular-ui-router'
import summaryModule from './summary/summary'
import representativesModule from './representatives/representatives'
import historyModule from './history/history'
import assessmentModule from './assessment/assessment'

import loanEnquiryDetailsComponent from './loanEnquiryDetails.component.js'

export default angular.module('app.pages.admin.loanEnquiryDetails', ['ui.router', summaryModule.name, representativesModule.name, historyModule.name, assessmentModule.name])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('adminLoanEnquiryDetails', {
        url: '/admin/enquiries/:uuid',
        template: '<admin-loan-enquiry-details/>',
        replace: true,
        data: {
          requireAuth: true,
          title: 'Loan enquiry details'
        }
      })
      .state('adminLoanEnquiryDetailsRedirect', {
        url: '/admin/enquiry-details/:uuid',
        template: '<admin-loan-enquiry-details/>',
        redirectTo: 'adminLoanEnquiryDetails'
      })
  }])
  .directive('adminLoanEnquiryDetails', loanEnquiryDetailsComponent)
