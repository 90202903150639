import isDefined from '../../utils/isDefined'

class FeatureTogglesService {
  /*@ngInject*/
  constructor(api, $firebaseObject, $document) {
    this.$document = $document
    const FIREBASE_URL_PATTERN = /^https:\/\/.*\.firebaseio\.com/i

    this.initialised = api.linkFor('featureToggles')
      .then((link) => {
        if (link && link.href && !link.href.match(FIREBASE_URL_PATTERN)) {
          throw new Error('Current Firebase libraries require: https://<YOUR FIREBASE>.firebaseio.com')
        }

        this._featureToggleFirebaseObject = $firebaseObject(new Firebase(link.href))

        if (this._featureToggleFirebaseObject) {
          return this._featureToggleFirebaseObject.$loaded()
        } else {
          return Promise.resolve()
        }
      })
  }

  can(feature) {
    return this._featureToggleFirebaseObject && this._featureToggleFirebaseObject[feature]
  }

  isResolved(feature) {
    return !!this._featureToggleFirebaseObject && isDefined(this._featureToggleFirebaseObject[feature])
  }
}

export default FeatureTogglesService

