class WithdrawalsController {
  /*@ngInject*/
  constructor($scope, router) {
    this.name = 'withdrawals'
    this.router = router

    this.tabs = [
      {label: 'Verified', state: 'adminDashboard.withdrawals.verified'},
      {label: 'Processing', state: 'adminDashboard.withdrawals.processing'},
      {label: 'Completed', state: 'adminDashboard.withdrawals.completed'},
      {label: 'Declined', state: 'adminDashboard.withdrawals.declined'},
      {label: 'Rejected', state: 'adminDashboard.withdrawals.rejected'},
    ]
    $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (toState.name.indexOf('adminDashboard.withdrawals') === 0) {
        let indexOfState = this.tabs.findIndex(tab => toState.name.startsWith(tab.state))
        if(indexOfState >= 0) {
          this.selectedIndex = indexOfState
        }
      }
    })
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }
}

export default WithdrawalsController
