import valueOrDefault from '../../utils/valueOrDefault'
import resolveProperty from '../../utils/resolveProperty'

const resolveInvestorBankDetails = (investor, details) => {
  return investor.promise('investorSetting').then((settings) => {
    const settingsOrDefault = (fieldName, defaultValue) => valueOrDefault(resolveProperty(settings, 'bankDetails', fieldName), defaultValue)
    details.accountName = investor.name,
    details.bsb = settingsOrDefault('bsb', ''),
    details.accountNumber = settingsOrDefault('accountNumber', ''),
    details.ownBankAccount = settingsOrDefault('ownBankAccount', false),
    details.authorised = false
  }).catch(e => {
    // TODO : Display error message?
  })
}

const hasInvestorBankDetails = (details) => {
  const present = (obj, property) => obj.hasOwnProperty(property) && obj[property] !== null && obj[property] !== undefined
  return details !== undefined && details !== null && typeof details === 'object' && !Array.isArray(details) &&
    present(details, 'accountName') && details.accountName.trim().length > 0 &&
    present(details, 'bsb') && details.bsb.trim().length > 0 &&
    present(details, 'accountNumber') && details.accountNumber.trim().length > 0 &&
    present(details, 'ownBankAccount') && details.ownBankAccount
}

export {resolveInvestorBankDetails, hasInvestorBankDetails}
