const formAddBusiness = {
  title: 'Add Business',
  model: 'add-business',
  actionLabel: 'Continue',
  widgets: [
    {
      type: 'RadioButtons',
      definition: {
        model: 'type',
        prompt: 'What is the type of the business?',
        required: true,
        radioButtons: [
          {
            value: 'vendor',
            label: 'Vendor'
          },
          {
            value: 'acquired business',
            label: 'Acquired business'
          },
          {
            value: 'cross directorship',
            label: 'Cross directorship'
          },
          {
            value: 'other business',
            label: 'Other business'
          },
        ]
      }
    },
    {
      type: 'ABN/ACN',
      definition: {
        prompt: 'What is the ABN/ACN of the business?',
        required: true,
        model: 'businessABNACN',
        optionalModels:
          {
            name: ['businessName', 'businessABNACNName'],
            tradingName: ['businessTradingName'],
            state: 'businessAddressState',
            postcode: 'businessAddressPostcode',
            gstRegisteredSince: 'businessGstRegisteredSince',
            tradingSince: 'businessTradingSince',
            entityType: 'businessStructure',
            entityDescription: 'businessEntityDescription',
            statusCode: 'businessStatusCode'
          }
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessName',
        prompt: 'By what name is the business known?',
        required: true
      }
    },
    {
      type: 'String',
      definition: {
        model: 'comment',
        prompt: 'Enter any comment.',
        required: false
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessABNName',
        prompt: 'Registered name of the business',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessTradingName',
        prompt: 'Trading name of the business',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessGstRegisteredSince',
        prompt: 'GST Registration date',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessTradingSince',
        prompt: 'Business registration date',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessStructure',
        prompt: 'Business entity type code',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessEntityDescription',
        prompt: 'Business entity description',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessStatusCode',
        prompt: 'Business status',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessAddressPostcode',
        prompt: 'Business address postcode',
        display: 'none'
      }
    },
    {
      type: 'String',
      definition: {
        model: 'businessAddressState',
        prompt: 'Business address state',
        display: 'none'
      }
    },
  ]
}

export default formAddBusiness
