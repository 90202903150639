import ClipboardJS from 'clipboard'

class ClipboardFactory {
  /*@ngInject*/
  constructor() {
  }

  createClipboard(data) {
    return new ClipboardJS(data)
  }
}

export default ClipboardFactory
