var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {pug_mixins["centered-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "centered-content","layout": "row"},attributes]), true)) + "\u003E\u003Cdiv class=\"content\" layout=\"column\" layout-align=\"start stretch\" flex flex-md=\"90\" flex-lg=\"80\" flex-gt-lg=\"70\" flex-offset-md=\"5\" flex-offset-lg=\"10\" flex-offset-gt-lg=\"15\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["contentful-component"] = pug_interp = function(component){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-if", "" + component + ".fields.type === 'wide'", true, true)) + "\u003E\u003Cicon-panel" + (" class=\"wide-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel" + (" class=\"standard-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E";
},
attributes: {"ng-if": pug.escape("" + component + ".fields.type !== 'wide'")}
});
};





























pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};










































































pug_mixins["rateDisplay"] = pug_interp = function(rate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + (pug.escape(null == (pug_interp = rate) ? "" : pug_interp)) + "\u003Cimg src=\"\u002Ficons\u002Ficon-percent-pa.svg\" alt=\"percentage pa\"\u003E";
};












pug_html = pug_html + "\u003Cpage-meta\u003EDashboard\u003C\u002Fpage-meta\u003E\u003Cdiv class=\"wrapper\"\u003E\u003Cdiv class=\"next-steps\" ng-if=\"vm.requiresNextSteps\"\u003E\u003Cdiv ng-switch=\"vm.investorRegistrationNextStep\"\u003E\u003Cdiv class=\"unfunded\" ng-switch-when=\"unfunded\"\u003E\u003Cicon-panel panel=\"vm.getPanel('investorDashboard-registrationNextSteps-unfunded')\"\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"review\" ng-switch-when=\"review\"\u003E\u003Cicon-panel panel=\"vm.getPanel('investorDashboard-registrationNextSteps-review')\"\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"biometric-id-incomplete\" ng-switch-when=\"biometricIdIncomplete\"\u003E\u003Cicon-panel panel=\"vm.getPanel('investorDashboard-registrationNextSteps-biometricIdIncomplete')\"\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"pending\" ng-if=\"!vm.nextStepResolved\"\u003E\u003Cmd-progress-circular md-mode=\"indeterminate\"\u003E\u003C\u002Fmd-progress-circular\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dashboard-detail\" ng-if=\"vm.nextStepResolved\"\u003E\u003Cmd-tabs class=\"md-primary\" md-no-pagination=\"false\" md-center-tabs=\"false\" md-swipe-content=\"true\" md-dynamic-height md-selected=\"vm.selectedIndex\"\u003E\u003Cmd-tab ng-repeat=\"tab in vm.tabs\" md-on-select=\"vm.onSelectTab(tab)\"\u003E{{tab.label}}\u003C\u002Fmd-tab\u003E\u003C\u002Fmd-tabs\u003E\u003Cui-view\u003E\u003C\u002Fui-view\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;