export default ['environment', '$compile', (environment, $compile) => {
  return {
    restrict: 'A',
    priority: 600,
    scope: true,
    terminal: true,
    link: function link(scope, element, attrs) {
      const ngIfValue = attrs.ngIf ? `${attrs.ngIf} && tenantMatch` : 'tenantMatch'

      element.attr('ng-if', ngIfValue)
      attrs.ngIf = ngIfValue

      const predicates = attrs.ifTenant.split(' ')
      const include = predicates.filter(tenant => !tenant.startsWith('!'))
      const exclude = predicates.filter(tenant => tenant.startsWith('!')).map(tenant => tenant.slice(1))
      const tenant = environment.TP_TENANT
      scope.tenantMatch = (include.length > 0 && include.includes(tenant)) || (exclude.length && !exclude.includes(tenant))

      $compile(element)(scope)
    }
  }
}]
