class UpdateService {
  /*@ngInject*/
  constructor($window, $rootScope, $state, versionService) {
    this.$window = $window
    this.$rootScope = $rootScope
    this.$state = $state
    this.versionService = versionService
  }

  init() {
    this.$rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) => {
      if (this.versionService.hasVersionChanged()) {
        event.preventDefault()
        this.$window.location.assign(this.$state.href(toState, toParams, options))
      }
    })
  }
}

export default UpdateService
