class BiometricIdentitiesService {
  /*@ngInject*/
  constructor(biometricIdentityChecksRepository, $q) {
    this.biometricIdentityChecksRepository = biometricIdentityChecksRepository
    this.$q = $q
  }

  createOrUpdateBiometricIdCheck(userIdentity, comment = '') {
    return this.$q((resolve, reject) => {
      if (userIdentity.biometricIdentityCheckAttempted()) {
        if (userIdentity.biometricIdentityCheckInProgress()) {
          this.biometricIdentityChecksRepository.getById(userIdentity.biometricIdentityCheck.verificationId).then((biometricIdentityCheckInProgress) => {
            userIdentity.biometricIdentityCheck = biometricIdentityCheckInProgress
            resolve(biometricIdentityCheckInProgress)
          }).catch((error) => {
            userIdentity.biometricIdentityCheck = 'failed'
            reject(error)
          })
        } else {
          resolve(`Biometric identity check already complete! [${userIdentity.biometricIdentityCheck.status}]`)
        }
      } else {
        this.biometricIdentityChecksRepository.create({userId: userIdentity.resource.id, comment: comment}).then((biometricIdentityCheck) => {
          userIdentity.biometricIdentityCheck = biometricIdentityCheck
          resolve(biometricIdentityCheck)
        }).catch((error) => {
          userIdentity.biometricIdentityCheck = 'failed'
          reject(error)
        })
      }
    })
  }
}

export default BiometricIdentitiesService
