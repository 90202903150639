export default class CognitoService {
  /*@ngInject*/
  constructor($rootScope, awsAmplifyService, loggerService) {
    this.$rootScope = $rootScope
    this.awsAmplifyService = awsAmplifyService
    this.awsAmplifyService.configure()
    this.logger = loggerService.makeLogger().enableNewRelic(true).enableConsole(false)
  }

  async isSignedIn() {
    try {
      return await this.awsAmplifyService.getCurrentUser()
    } catch (error) {
      return false
    }
  }

  async signIn(username, password) {
    let _isSignedIn = false

    try {
      const {isSignedIn} = await this.awsAmplifyService.signIn({
        username,
        password
      })
      _isSignedIn = isSignedIn
    } catch (error) {
      if (typeof error === 'string' && error.includes('UserAlreadyAuthenticatedException')) {
        _isSignedIn = true
      } else {
        this.logger.error(error)
      }
    }

    if (_isSignedIn) {
      await this.verifyAccreditationStatus()
    }
  }

  async verifyAccreditationStatus() {
    let session
    try {
      session = await this.awsAmplifyService.fetchAuthSession()
    } catch (error) {
      this.logger.error(error)
      await this.awsAmplifyService.signOut()
      return
    }

    const idToken = session.tokens.idToken || undefined

    // If the idToken is missing or the status is not set, sign out the user.
    if (!idToken || !idToken.payload || !idToken.payload['custom:status']) {
      this.logger.error('The idToken is missing or the status is not set. Signing out the user.')
      await this.awsAmplifyService.signOut()
      return
    }

    const isAccreditedBroker = idToken.payload['custom:status'].toLowerCase() === 'accredited'

    if (isAccreditedBroker) {
      this.$rootScope.$emit('accredited-broker', session)
    } else {
      await this.awsAmplifyService.signOut()
    }
  }
}
