import batchTemplate from './withdrawalsProcessingBatch.pug'
import moment from 'moment'
import Big from 'big.js'

class WithdrawalsProcessingController {
  /*@ngInject*/
  constructor($scope, $mdDialog, $mdToast, withdrawalService, localStorageService, csvService, tenantInformationService, loggerService) {
    this.name = 'withdrawals-processing'
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.withdrawalService = withdrawalService
    this.csvService = csvService
    this.tenantInformationService = tenantInformationService

    this.logger = loggerService.makeLogger().enableNewRelic(true)

    this.search = ''
    this.limitOptions = [5, 10, 20, 50, 100]
    this.order = '-UpdatedAt'
    this.page = 1
    localStorageService.bind($scope, 'vm.limit', 50, 'admin-withdrawal-requests.limit')

    this.mdReload = () => this.reload() // this is required for md-reorder-on to work

    this.total = 0
    this.withdrawals = []
    this.processing = false

    this.$onInit = () => {
      this.reload()
    }
  }

  get hasIdsToProcess() {
    return this.idsToProcess().length > 0
  }

  selectAllForProcessing() {
    this.withdrawals.forEach((withdrawal) => withdrawal.completed = true)
  }

  unselectAllForProcessing() {
    this.withdrawals.forEach((withdrawal) => withdrawal.completed = false)
  }

  idsToProcess() {
    return this.withdrawals.filter((request) => !!request.completed).map((request) => ({id: request.id, reason: 'completed'}))
  }

  performSearch() {
    this.page = 1
    return this.reload()
  }

  reload() {
    const conditions = {
      search: this.search,
      order: this.order,
      page: this.page,
      size: this.limit
    }
    return this.withdrawalService.processingWithdrawalsPromise(conditions).then((data) => {
      this.withdrawals = data.withdrawalRequests
      this.total = data.total
      return true
    })
  }

  sendForProcessing() {
    const ids = this.idsToProcess()
    if (ids.length > 0) {
      this._processChange('complete', ids)
    }
    return true
  }

  withdrawalRejected(id) {
    this._reasonForRejection((reason) => this._processChange('reject', [{id: id, reason: reason}]))
  }

  getBatch(batchId) {
    this._loadBatchAndProcess(batchId, 500, 1, [])
  }

  compare(a, b) {
    if (!a || !b) return 'initial'
    if (a !== b) return 'differ'
    return 'same'
  }

  // private

  _processChange(event, idsToProcess) {
    this.processing = true
    this.withdrawalService.update(event, idsToProcess)
      .catch((error) => {
        this._showToast(error)
      })
      .finally(() => {
        this.processing = false
        return this.reload()
      }, (error) => this._showToast(error)
    )
  }

  _showToast(error) {
    this.$mdToast.show(this.$mdToast.simple().textContent('Error: ' + error.data.message).hideDelay(5000).position('top left right'))
  }

  _reasonForRejection(onFulfilled) {
    const options = this.$mdDialog.prompt()
      .textContent('What is the reason for rejection?')
      .clickOutsideToClose(true)
      .title('Rejection Message for Requester')
      .ariaLabel('Confirm?')
      .ok('Reject')
      .cancel('Cancel')
    const onRejected = () => false
    return this.$mdDialog.show(options).then(onFulfilled, onRejected)
  }

  _loadBatchAndProcess(batchId, size, page, withdrawalRequests) {
    this.withdrawalService.batchWithdrawalsPromise(batchId).then((data) => {
      withdrawalRequests = withdrawalRequests.concat(data.withdrawalRequests)
      if (data.withdrawalRequests.length >= size) {
        this._loadBatchAndProcess(batchId, size, page + 1, withdrawalRequests)
      } else {
        this.$mdDialog.show({
          clickOutsideToClose: true,
          escapeToClose: true,
          template: batchTemplate({}),
          locals: {
            batchId,
            withdrawalRequests,
          },
          controller: ['$scope', '$mdDialog', 'batchId', 'withdrawalRequests', ($scope, $mdDialog, batchId, withdrawalRequests) => {
            $scope.batchId = batchId
            $scope.count = withdrawalRequests.length
            $scope.total = withdrawalRequests.reduce((sum, withdrawalRequest) => sum.plus(withdrawalRequest.amount), new Big('0.0')).toString()
            $scope.download = () => {
              this._getBatchCSV(batchId, withdrawalRequests)
            }
            $scope.closeDialog = () => $mdDialog.hide()
          }]
        })
        return true
      }
    })
    return true
  }

  _getBatchCSV(batchId, withdrawals) {
    this.tenantInformationService.getElement('accounts.custodian.bank').then((trusteeBankDetails) => {
      const csv = withdrawals.map((withdrawal) => {
        const row = {}
        row['Date'] = moment().startOf('day').locale('en').format('YYYY-MM-DD')
        row['Amount'] = new Big(withdrawal.amount).toFixed(2)
        row['From Account Name'] = trusteeBankDetails.name
        row['From Account BSB'] = trusteeBankDetails.bsb
        row['From Account Number'] = trusteeBankDetails.number
        row['To Account Name'] = withdrawal.accountName
        row['To Account BSB'] = withdrawal.bsb.length === 6 ? withdrawal.bsb.slice(0, 3) + '-' + withdrawal.bsb.slice(3) : withdrawal.bsb
        row['To Account Number'] = withdrawal.accountNumber
        row['Ref'] = 'wd CRN ' + withdrawal.investorSummary.bpayCrn
        return row
      })
      this.csvService.createAndDownload('tp-' + moment().startOf('day').locale('en').format('YYYY-MM-DD') + '-' + batchId + '.csv', csv)

    }, (error) => {
      this.logger.error(`Problem with getting the tenant information for the withdrawal trustee: ${error}.`)
    })
  }
}

export default WithdrawalsProcessingController
