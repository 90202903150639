import angular from 'angular'

import registersComponent from './registers.component'
import RegistersReports from './service/register.service'

export default angular.module('app.pages.admin.adminDashboard.registers', [])
  .service('registerService', ['pdfService', 'csvService', (pdfService, csvService) => {
    return (reportType) => {
      return new RegistersReports(pdfService, csvService).processor(reportType)
    }
  }])
  .directive('adminRegisters', registersComponent)
