const findPredicate = (attributeDefinition) => {
  const parts = attributeDefinition.split('=')
  const attr = parts[0].substring(1)
  const value = parts[1]
  return (obj) => obj[attr] === value
}


const resolveProperty = (thing, ...path) => {
  if (thing && path && path.length > 0) {
    const newThing = (Array.isArray(thing) && path[0].toString().startsWith('@')) ?
      thing.find(findPredicate(path[0])) :
      thing[path[0].toString()]
    return newThing === null || newThing === undefined ? newThing : resolveProperty(newThing, ...path.slice(1))
  }
  return thing
}

export default resolveProperty
