class DotPointController {
  /*@ngInject*/
  constructor(iconsService) {
    this.name = 'dot-point'

    this.$onInit = () => {
      if (this.assetId) {
        this.asset = iconsService.getByIdentifier(this.assetId)
      }
      if (this.assetName) {
        this.asset = iconsService.getByName(this.assetName)
      }
    }
  }

  set svg(shortName) {
    const urlPart = shortName ? shortName : 'brokenImage'
    this.svgSrc = `icons/icon_${urlPart}.svg`
    this._svg = urlPart
  }

  get svg() {
    return this._svg
  }

  get iconStatus() {
    return this.asset.status
  }

  get iconUrl() {
    return this.asset.url
  }

  get iconStyle() {
    return {
      'height': '56px',
      'background': `url(${this.asset.url}) no-repeat`
    }
  }
}

export default DotPointController
