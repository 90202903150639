import valueOrDefault from '../../../utils/valueOrDefault'

class TenantAnchorController {
  /*@ngInject*/
  constructor(tenantInformationService) {
    this.name = 'tenantAnchor'

    this.$onInit = () => {
      tenantInformationService.getElement(this.anchorId)
        .then((anchor) => {
          this.anchorHref = anchor.href
          this.anchorLabel = valueOrDefault(anchor.label, this.anchorHref)
          this.anchorAriaLabel = valueOrDefault(anchor.ariaLabel, valueOrDefault(this.anchorLabel, ''))
        })
    }
  }

  get label() {
    return this.anchorLabel
  }

  get href() {
    return this.anchorHref
  }

  get ariaLabel() {
    return this.anchorAriaLabel
  }
}

export default TenantAnchorController
