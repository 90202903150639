import template from './finalLoanRepayment.pug'
import controller from './finalLoanRepayment.controller'
import './finalLoanRepayment.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      loan: '=',
      debit: '=?',
      completion: '=',
    },
    bindToController: true
  }
}
