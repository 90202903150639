import angular from 'angular'

import abTestVariantService from './abTestVariant'

const variantPrefix = 'variant'
const createLocalStorageName = name => `ab-test.${name}`

export default angular.module('app.common.abTest', [abTestVariantService.name])
  .directive('abTestConfigure', ['$window', 'localStorageService', 'abTestVariantService', ($window, localStorageService, abTestVariantService) => ({
    restrict: 'E',
    link: (scope, elem, attr) => {
      const name = attr.name
      const localStorageName = createLocalStorageName(name)
      const variants = Object.keys(attr).filter(key => key.startsWith(variantPrefix)).reduce((map, key) => map.set(key, parseInt(attr[key], 10)), new Map())

      // remove unknown variant
      if (localStorageService.get(localStorageName) && !Array.from(variants.keys()).includes(variantPrefix + localStorageService.get(localStorageName))) {
        localStorageService.remove(localStorageName)
      }

      // set current variant
      if (!localStorageService.get(localStorageName)) {
        abTestVariantService.setVariant(variants, Math.random(), variantPrefix, localStorageName)
      }

      // send event to ga
      if ($window && $window.ga && typeof $window.ga === 'function') {
        $window.ga('send', 'event', 'ab-test', 'view', name + '.' + localStorageService.get(localStorageName), {
          nonInteraction: true
        })
      }
    }
  })])

  .directive('abTestShow', () => ({
    restrict: 'E',
    transclude: true,
    scope: {
      name: '@name',
      variant: '@variant'
    },
    controller: ['$scope', 'localStorageService', ($scope, localStorageService) => {
      $scope.canShow = (name, variant) => localStorageService.get(createLocalStorageName(name)) === variant
    }],
    template: '<div ng-if="canShow(name, variant)" ng-transclude></div>'
  }))
