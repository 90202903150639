class InvestmentProductsController {
  /*@ngInject*/
  constructor($rootScope, $scope, $q, $mdDialog, localStorageService, investmentProductsRepository, investmentProductPledgeRequestsRepository, investmentProductRedemptionRequestsRepository) {
    this.name = 'admin-investment-products'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.investmentProductsRepository = investmentProductsRepository
    this.investmentProductPledgeRequestsRepository = investmentProductPledgeRequestsRepository
    this.investmentProductRedemptionRequestsRepository = investmentProductRedemptionRequestsRepository

    localStorageService.bind($scope, 'vm.scope', 'all', 'admin-investment-products.scope')

    this.scopes = [
      {key: 'all', label: 'All'},
      {key: 'active', label: 'Active'}
    ]
    this.pledgeRequestsSearch = {
      categories: [
        {key: 'all', label: 'All'},
        {key: 'cash', label: 'Cash'},
        {key: 'rollover', label: 'Rollover'},
        {key: 'transfer', label: 'Transfer'},
      ],
      statuses: [
        {key: 'all', label: 'All'},
        {key: 'pending', label: 'Pending'},
        {key: 'approved', label: 'Approved'},
        {key: 'declined', label: 'Declined'},
        {key: 'cancelled', label: 'Cancelled'},
      ],
      limitOptions: [5, 10, 20, 50, 100]
    }
    this.redemptionRequestsSearch = {
      categories: [
        {key: 'all', label: 'All'},
        {key: 'ordinary', label: 'Ordinary'},
        {key: 'early', label: 'Early'},
        {key: 'maturity', label: 'Maturity'},
        {key: 'transfer', label: 'Transfer'},
        {key: 'involuntary', label: 'Involuntary'},
      ],
      statuses: [
        {key: 'all', label: 'All'},
        {key: 'pending', label: 'Pending'},
        {key: 'approved', label: 'Approved'},
        {key: 'declined', label: 'Declined'},
        {key: 'cancelled', label: 'Cancelled'},
      ],
      limitOptions: [5, 10, 20, 50, 100]
    }
    this.reload = () => {
      this.loading = true
      const conditions = {
        scope: this.scope,
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.investmentProductsRepository.where(conditions)
        .then(response => this.investmentProducts = response.investmentProducts)
        .finally(() => this.loading = false)
    }

    this.pledgeRequestsSearchPaginate = () => {
      this.pledgeRequestsSearch.loading = true
      const conditions = {
        scope: 'for_investment_product',
        id: this.pledgeRequestsSearch.investmentProductId,
        category: this.pledgeRequestsSearch.category,
        status: this.pledgeRequestsSearch.status,
        size: this.pledgeRequestsSearch.limit,
        page: this.pledgeRequestsSearch.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.pledgeRequestsSearch.loadingPromise = this.investmentProductPledgeRequestsRepository.where(conditions)
        .then(response => {
          this.pledgeRequestsSearch.total = response.total
          this.pledgeRequestsSearch.pledgeRequests = response.investmentProductPledgeRequests
        })
        .finally(() => this.pledgeRequestsSearch.loading = false)
    }

    this.redemptionRequestsSearchPaginate = () => {
      this.redemptionRequestsSearch.loading = true
      const conditions = {
        scope: 'for_investment_product',
        id: this.redemptionRequestsSearch.investmentProductId,
        category: this.redemptionRequestsSearch.category,
        status: this.redemptionRequestsSearch.status,
        size: this.redemptionRequestsSearch.limit,
        page: this.redemptionRequestsSearch.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.redemptionRequestsSearch.loadingPromise = this.investmentProductRedemptionRequestsRepository.where(conditions)
        .then(response => {
          this.redemptionRequestsSearch.total = response.total
          this.redemptionRequestsSearch.redemptionRequests = response.investmentProductRedemptionRequests
        })
        .finally(() => this.redemptionRequestsSearch.loading = false)
    }

    this.$onInit = () => {
      this.performInvestmentProductsSearch()
    }
  }

  performInvestmentProductsSearch() {
    this.pledgeRequestsSearch.limit = 50
    this.pledgeRequestsSearch.page = 1
    this.pledgeRequestsSearch.total = 0
    delete this.pledgeRequestsSearch.investmentProductId
    this.pledgeRequestsSearch.category = 'all'
    this.pledgeRequestsSearch.status = 'all'
    delete this.pledgeRequestsSearch.pledgeRequests
    delete this.pledgeRequestsSearch.loadingPromise

    this.redemptionRequestsSearch.limit = 50
    this.redemptionRequestsSearch.page = 1
    this.redemptionRequestsSearch.total = 0
    delete this.redemptionRequestsSearch.investmentProductId
    this.redemptionRequestsSearch.category = 'all'
    this.redemptionRequestsSearch.status = 'all'
    delete this.redemptionRequestsSearch.redemptionRequests
    delete this.redemptionRequestsSearch.loadingPromise

    this.reload()
  }

  performPledgeRequestsSearch() {
    this.pledgeRequestsSearch.page = 1
    this.pledgeRequestsSearchPaginate()
  }

  processPledgeRequest(investmentProductPledgeRequest, action) {
    return investmentProductPledgeRequest.performAction(action, {})
  }

  performRedemptionRequestsSearch() {
    this.redemptionRequestsSearch.page = 1
    this.redemptionRequestsSearchPaginate()
  }

  processRedemptionRequest(investmentProductRedemptionRequest, action) {
    return investmentProductRedemptionRequest.performAction(action, {})
  }

  amendRedemptionRequest($event, investmentProductRedemptionRequest) {
    let scope = this.$rootScope.$new()
    scope.completion = this.$q.defer()
    scope.investmentProductRedemptionRequest = investmentProductRedemptionRequest
    scope.completion.promise
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-amend-investment-product-redemption-request investment-product-redemption-request='investmentProductRedemptionRequest' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  showCreateInvestmentProductDialog($event) {
    let scope = this.$rootScope.$new()
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.reload())
      .catch(angular.noop)
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-create-investment-product completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  showUpdateInvestmentProductDialog($event, investmentProduct) {
    let scope = this.$rootScope.$new()
    scope.investmentProduct = investmentProduct
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.reload())
      .catch(angular.noop)
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-update-investment-product investment-product="investmentProduct" completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }
}

export default InvestmentProductsController
