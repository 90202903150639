const RISK_MAPPINGS = [
  ['A', '5'],
  ['B', '4'],
  ['C', '3'],
  ['D', '2'],
  ['E', '1'],
]

const display2Service = new Map(RISK_MAPPINGS.map(([a, b]) => [b, a]))
const riskRating2Service = (displayValue) => display2Service.get(displayValue)

const service2Display = new Map(RISK_MAPPINGS)
const riskRating2Display = (serviceValue) => service2Display.get(serviceValue)

export {riskRating2Service, riskRating2Display}
