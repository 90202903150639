import transitionStatusDialogTemplate from './transitionStatusDialog/transitionStatusDialog.pug'
import TransitionStatusDialogController from './transitionStatusDialog/transitionStatusDialog.controller'
import Big from 'big.js'

const MINIMUM_TOTAL = new Big('0.0001')

const effectivelyZero = (total) => new Big(total).lt(MINIMUM_TOTAL)

const permittedTransitions = new Map([
  ['reject', {label: 'Reject', class: 'md-warn', filter: (investor) => investor.status !== 'rejected'}],
  ['mark_deleted', {label: 'Delete', class: 'md-warn', filter: (investor) => investor.status !== 'deleted' && effectivelyZero(investor.totalFunds)}],
  ['accept', {label: 'Accept', filter: (investor) => investor.status !== 'accepted' && ['pending'].includes(investor.status)}],
  ['verify', {label: 'Verify', class: 'md-accent', filter: (investor) => investor.status !== 'verified'}],
])
const isTransitionPermitted = (transition, investor) => {
  const possibleTransitionFrom = permittedTransitions.get(transition)
  return !!possibleTransitionFrom && possibleTransitionFrom.filter(investor)
}

const allCaps = (s) => s.toString().toUpperCase()


class InvestorStatusChangeController {
  /*@ngInject*/
  constructor($mdDialog, $scope) {
    this.name = 'investorStatusChange'
    this.$mdDialog = $mdDialog

    this.permittedTransitions = []

    this.$onInit = () => {
      $scope.$watch(() => this.investor, () => {
        this._resolveTransitions(this.investor)
      })
    }
  }

  applyTransition(event, type) {
    return this.$mdDialog.show({
      targetEvent: event,
      template: transitionStatusDialogTemplate({}),
      controllerAs: 'vmd',
      locals: {
        investor: this.investor,
        model: {
          currentStatus: allCaps(this.investor.status),
          transition: type,
          category: '',
          comment: ''
        }
      },
      controller: TransitionStatusDialogController
    }).then((response) => {
      const resolveCategory = (response) => {
        if (response.category === undefined || response.category === null || response.category === '') {
          return ''
        } else if (response.category === 'other') {
          return response.otherCategory ? response.otherCategory : ''
        } else {
          return response.category
        }
      }
      const payload = {
        category: resolveCategory(response),
        comment: response.comment
      }
      return this.investor.performAction(`status/${response.transition}`, payload)
        .then((investor) => {
          this.submitting = false
          this._resolveTransitions(investor)
          return investor
        })
    }).catch((response) => {
      // Do something else with the response, possibly nothing (maybe report the problem).
    })
  }

  // ****** private

  _resolveTransitions(investor) {
    this.permittedTransitions = investor && Array.isArray(this.transitions) && this.transitions.length > 0
      ? this.transitions
        .filter(transition => isTransitionPermitted(transition, investor))
        .map(transition => ({type: transition, label: `${permittedTransitions.get(transition).label}`, class: permittedTransitions.get(transition).class || 'md-primary'}))
      : []
  }
}


export default InvestorStatusChangeController
