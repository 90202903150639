import template from './manageRedemptionRequests.pug'
import controller from './manageRedemptionRequests.controller'
import './manageRedemptionRequests.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      investmentProductPart: '='
    },
    bindToController: true
  }
}
