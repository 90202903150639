import template from './registrationForm.pug'
import controller from './registrationForm.controller'
import './registrationForm.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    transclude: true,
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      verificationStarted: '=',
      completion: '=',
      userIdentityPromise: '=?',
      validateOnEntry: '=?',
      forceEmailEntry: '<?',
      loanPurpose: '<?',
      loanOrigination: '<?'
    }
  }
}
