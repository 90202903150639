class RequestInterceptor {
  /*@ngInject*/
  constructor(volatileStore, environment) {
    this.name = 'requestInterceptor'
    this.volatileStore = volatileStore
    this.environment = environment
  }

  static addToHttp($httpProvider) {
    $httpProvider.interceptors.push(
      RequestInterceptor._createInterceptor()
    )
  }

  handleRequest(request) {
    if (this.volatileStore.tpAuthToken) {
      request.params = request.params || {}
      if (!request.url.includes(this.environment.CONTENTFUL_HOST)) {
        request.params.auth_token = this.volatileStore.tpAuthToken
      }
    }
    return request
  }

  // private

  static _createInterceptor() {
    return ['volatileStore', 'environment', (volatileStore, environment) => {
      let handler = new RequestInterceptor(volatileStore, environment)
      return {
        request: request => handler.handleRequest(request)
      }
    }]
  }
}

export default RequestInterceptor
