import 'firebase'

class FirebaseLoginManagerService {
  /*@ngInject*/
  constructor($mdToast) {
    this.$mdToast = $mdToast
  }

  set user(user) {
    if (this._user) {
      this._logout()
    }
    this._user = user
    if (this._user) {
      this._ref = this._createRef()
      this._login()
    }
  }

  get user() {
    return this._user
  }

  // private

  _logout() {
    this._ref.unauth()
  }

  _login() {
    this._ref.authWithCustomToken(this._user.firebaseToken, (error, authData) => {
      if (error) {
        this._handleLoginError(error)
      }
    }, {remember: 'sessionOnly'})
  }

  _createRef() {
    return new Firebase(this._user._links.notifications.href)
  }

  _handleLoginError(error) {
    this.$mdToast.show(
      this.$mdToast
      .simple()
      .textContent('Could not log into notifications. Try reloading the page.')
      .position('top left')
    )
  }
}

export default FirebaseLoginManagerService

