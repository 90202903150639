import angular from 'angular'

class HeroPanelController {
  /*@ngInject*/
  constructor($document, $timeout, $scope, userService, customPropertiesService, contentful) {
    this.$document = $document
    this.$timeout = $timeout
    this.$scope = $scope

    this.name = 'heroPanel'
    this.userService = userService

    this.$onInit = () => {
      if (this.heroId) {
        contentful.entries('sys.id=' + this.heroId + '&include=10').then((hero) => {
          this.hero = hero.data && hero.data.items[0]
        })
      }
      this.backgroundImageUrl = customPropertiesService.getProperty('--hero-panel-background-image-url').trim()
    }
  }

  get isLoggedIn() {
    return this.userService.isLoggedIn()
  }

  get backgroundStyle() {
    const styling = this._initialiseStyling()

    const backgroundImageUrl = this._backgroundImageUrl()
    if (backgroundImageUrl) {
      styling.get('background-image').push(backgroundImageUrl)
      styling.get('background-repeat').push('no-repeat')
      const backgroundLayout = this._backgroundLayout()
      if (backgroundLayout) {
        styling.get('background-position').push(backgroundLayout)
      }
      styling.get('background-size').push('cover')
    }
    return Array.from(styling.entries()).reduce((result, entry) => {
      if (entry[1].length > 0) {
        result[entry[0]] = entry[1].join(', ')
      }
      return result
    }, {})
  }

  get withIcon() {
    return this._getFieldOrDefault('icon', false, () => true)
  }

  get icon() {
    const iconImage = this._getFieldOrDefault('icon', '')
    return iconImage && iconImage.fields.file.url
  }

  get isClassic() {
    return this.heroType === 'classic'
  }

  get typeClasses() {
    const classes = [`hero-type-${this.heroType}`]
    if (!this.overlayIncluded) {
      classes.push('no-overlay')
    }
    return classes
  }

  get heroType() {
    return this._getFieldOrDefault('type', 'classic')
  }

  get overlayIncluded() {
    return this._getFieldOrDefault('includeOverlay', true)
  }

  scrollTo(element, delay) {
    if (typeof delay !== 'number') { delay = 1000 }
    const content = angular.element(this.$document.find('#main-content'))
    const target = angular.element(this.$document.find(element))
    this.$timeout(() => target && target[0] && content.scrollToElementAnimated(target, 0, delay), 0)
    return true
  }

  notifyEvent(direction, eventName, ...eventArgs) {
    if (['$emit', '$broadcast'].includes(direction)) {
      this.$scope.$root[direction](eventName, ...eventArgs)
    }
    return true
  }

  // private

  _backgroundLayout() {
    return this._getFieldOrDefault('backgroundLayout', '')
  }

  _backgroundImageUrl() {
    const backgroundImage = this._getFieldOrDefault('backgroundImage', '')
    const url = backgroundImage && backgroundImage.fields.file.url
    return url ? `url('${url}')` : ''
  }

  _getFieldOrDefault(field, defaultValue, transform) {
    if (!this._hasField(field)) {
      return defaultValue
    }
    return typeof transform === 'function' ? transform(this.hero.fields[field]) : this.hero.fields[field]
  }

  _hasField(property) {
    return this.hero && this.hero.fields && Object.getOwnPropertyNames(this.hero.fields).includes(property)
  }

  _initialiseStyling() {
    const styling = new Map([['background-image', []], ['background-repeat', []], ['background-position', []], ['background-size', []]])
    if (this.isClassic) {
      styling.get('background-image').push('linear-gradient(to right, rgba(0, 40, 85, 1) 0%, rgba(0, 40, 85, 0.7) 37%, rgba(0, 0, 0, 0) 75%)')
    } else if (this.overlayIncluded) {
      styling.get('background-image').push(this.backgroundImageUrl)
      styling.get('background-repeat').push('no-repeat')
      styling.get('background-position').push('top 0 left -50px')
      styling.get('background-size').push('800px')
    }
    return styling
  }
}

export default HeroPanelController
