import angular from 'angular'
import 'angular-ui-router'

import unfurlCardComponent from './unfurlCard.component'

export default angular.module('app.pages.marketing.unfurlCard', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('unfurlCard', {
        url: '/unfurl-card',
        template: '<unfurl-card />',
        data: {
          appClasses: ['unfurlCard', 'hero']
        }
      })
  }])
  .directive('unfurlCard', unfurlCardComponent)
