import moment from 'moment/moment'

class DebitsClearedController {
  /*@ngInject*/
  constructor($rootScope, $q, $mdDialog, $mdToast, directDebitsRepository, loanRepaymentsRepository, dateService, abaViewerService) {
    this.name = 'debits-cleared'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.$mdToast = $mdToast
    this.directDebitsRepository = directDebitsRepository
    this.loanRepaymentsRepository = loanRepaymentsRepository
    this.dateService = dateService
    this.abaViewerService = abaViewerService
    this.selectedDebitIds = []
    this.preselectDebits = true

    this.order = ['-clearedDate']


    this.$onInit = () => {
      this.reload()
    }
  }

  reload() {
    const today = this.dateService.now().format('YYYY-MM-DD')

    this.loading = this.directDebitsRepository.where({scope: 'all', status: 'processing'}).then(data => {
      this.directDebits = data.directDebits.filter(directDebit => (directDebit.clearedDate <= today))

      // preselect standard debits that are on time
      if (this.preselectDebits) {
        this.selectedDebitIds = []
        this.directDebits.forEach(debit => {
          debit.promise('loan').then(loan => {
            if (this.repaymentType(debit) === 'repayment') {
              this.selectedDebitIds.push(debit.id)
            }
          })
        })
        this.preselectDebits = false
      }
    })
  }

  viewABA($event, abaId, selectedDebitId) {
    this.abaViewerService.show($event, abaId, selectedDebitId)
  }

  createLoanRepayment($event, debit) {
    const scope = this.$rootScope.$new(false)
    scope.loan = debit.loan
    scope.debit = debit
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.reload())
      .finally(() => this.$mdDialog.hide())

    return this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      template: this._templateForType(debit.type),
      scope
    })
  }

  markAsRejected() {
    return this._confirmMarkRejectionsDialog().then(() => this._performActions('reject'))
  }

  markAsDisbursed() {
    return this._confirmMarkDisbursementsDialog().then(() => this._performActions('disburse'))
  }

  processRepayments() {
    return this._processRepaymentsDialog()
  }

  repaymentClass(debit) {
    if (debit.loan && debit.type === 'repayment') {
      if (debit.loan.repaymentType === 'principalOnly') {
        return 'principal-only'
      }
      if (debit.loan.status === 'defaulted') {
        return 'defaulted'
      }
      if (debit.loan.scheduledRepayments[0]?.amount !== debit.amount) {
        return 'amount'
      }
      if (debit.loan.paymentsRemaining < 2) {
        return 'last'
      }
      if (debit.loan.scheduledRepayments[0]?.expectedDate < debit.dueDate) {
        return 'late'
      }
    }
    return ''
  }

  repaymentType(debit) {
    if (debit?.type === 'repayment') {
      const cls = this.repaymentClass(debit)
      debit.orderType = cls ? `${debit.type} (${cls})` : debit.type
    } else {
      debit.orderType = debit.type
    }
    return debit.orderType
  }

  isSelected(directDebit) {
    return this.selectedDebitIds.includes(directDebit.id)
  }

  // private

  _templateForType(type) {
    switch (type) {
      case 'fee':
        return this._createloanRepaymentTemplate('additional')
      case 'final':
        return this._finalLoanRepaymentTemplate()
      default:
        return this._createloanRepaymentTemplate('standard')
    }
  }

  _createloanRepaymentTemplate(type) {
    return `
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-create-loan-repayment loan='loan' type='${type}' debit='debit' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
  }

  _finalLoanRepaymentTemplate() {
    return`
      <md-dialog flex="70">
        <md-dialog-content layout-padding>
          <admin-final-loan-repayment loan='loan' debit='debit' completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
  }

  _confirmMarkRejectionsDialog() {
    const message = (this.selectedDebitIds.length === 1) ?
      'Are you sure you want to mark this debit as rejected?' :
      `Are you sure you want to mark these ${this.selectedDebitIds.length} debits as rejected?`
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(message)
        .clickOutsideToClose(true)
        .title(`Rejected debits`)
        .ariaLabel('Rejected debits')
        .ok('Do it')
        .cancel('No')
    )
  }

  _confirmMarkDisbursementsDialog() {
    const message = (this.selectedDebitIds.length === 1) ?
      'Are you sure you want to mark this debit as disbursed?' :
      `Are you sure you want to mark these ${this.selectedDebitIds.length} debits as disbursed?`
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(message)
        .clickOutsideToClose(true)
        .title(`Disbursed debits`)
        .ariaLabel('Disbursed debits')
        .ok('Do it')
        .cancel('No')
    )
  }

  _processRepaymentsDialog() {
    const scope = this.$rootScope.$new(false)
    const completion = this.$q.defer()
    completion.promise.finally(() => {
      this.$mdDialog.hide()
      if (scope.dialog?.done > 0) {
        this.reload()
      }
    })

    return this.$mdDialog.show({
      parent: angular.element(document.body),
      // targetEvent: $event,
      escapeToClose: true,
      clickOutsideToClose: false,
      locals: {
        completion: completion,
        title: 'Process repayments',
        ariaLabel: 'Process repayments',
        paused: false,
        done: 0,
        todo: this.selectedDebitIds.length,
        message: (this.selectedDebitIds.length === 1) ?
          'Are you sure you want process this payment? (This cannot be undone)' :
          `Are you sure you want to process these ${this.selectedDebitIds.length} payments? (This cannot be undone)`,
        runProcess: () => {
          scope.dialog.running = true
          return this._processSelectedRepayments(completion, scope.dialog)
        },
        pauseProcess: () => {
          scope.dialog.paused = !scope.dialog.paused
          if (!scope.dialog.paused) {
            scope.dialog.runProcess()
          }
        },
        stopProcess: () => {
          scope.dialog.running = false
          completion.reject('cancel')
        }
      },
      controllerAs: 'dialog',
      bindToController: true,
      template: this._processRepaymentsTemplate(),
      controller: function() {},
      scope
    })
  }

  _processRepaymentsTemplate() {
    return `
      <md-dialog aria-label="{{ dialog.ariaLabel }}" ng-class="dialog.css">
        <md-dialog-content class="md-dialog-content" role="document">
          <h2 class="md-title">{{ dialog.title }}</h2>
          <p>{{ dialog.message }}</p>
        </md-dialog-content>
        <md-dialog-actions ng-if="!dialog.running">
          <md-button ng-click="dialog.stopProcess()" class="md-primary md-cancel-button">No</md-button>
          <md-button ng-click="dialog.runProcess()" class="md-primary md-confirm-button">Do it</md-button>
        </md-dialog-actions>
        <md-dialog-actions ng-if="dialog.running" layout="row" layout-align="start center">
          <div class="progress" flex="15">{{ dialog.done }} / {{ dialog.todo }}</div>
          <md-progress-linear flex="60" md-mode="determinate" value="{{ 100 * dialog.done / dialog.todo }}"></md-progress-linear>
          <md-button flex ng-click="dialog.stopProcess()" class="md-primary md-cancel-button">Quit</md-button> 
          <md-button flex ng-click="dialog.pauseProcess()" class="md-primary md-confirm-button">{{dialog.paused ? 'Resume' : 'Pause'}}</md-button> 
        </md-dialog-actions>
      </md-dialog>`
  }

  _performActions(action) {
    let results = this.selectedDebitIds.map(id => this.directDebitsRepository.getById(id)
      .then((debit) => debit.performAction(action)))

    return Promise.all(results).then((values) => this.reload())
  }

  async _processSelectedRepayments(completion, options) {
    const results = []
    for (const debitId of this.selectedDebitIds) {
      if (!options.paused) {
        const result = await this.directDebitsRepository.getById(debitId).then(async(debit) => {
          if (debit.status === 'disbursed' || !options.running) {
            options.done = options.done + 1
            return
          }
          if (options.paused) {
            return
          }
          await this._processRepayment(debit).then((repayment) => {
            options.done = options.done + 1
            return repayment
          }).catch((error) => {
            this._showToast(error)
            options.running = false // stop processing!
          }).finally((result) => {
            this.selectedDebitIds = this.selectedDebitIds.filter(id => id !== debitId)
            if (options.done >= options.todo) {
              completion.resolve(options.done)
            }
            return result
          })
        })
        if (result) {
          results.push(result)
        }
      }
    }
    return results
  }

  _processRepayment(debit) {
    return debit.promise('loan').then((loan) => {
      let paymentType = 'standard'
      let skipFees = loan.status === 'defaulted'
      let skipInterest = loan.repaymentType === 'principalOnly' || debit.loan.status === 'defaulted'

      if (debit.type === 'fee') {
        paymentType = 'additional'
        skipFees = false
        skipInterest = false
      }

      const payload = {
        loanId: debit.loanId,
        directDebitId: debit.id,
        type: paymentType,
        dueDate: moment(debit.dueDate).startOf('day').format('YYYY-MM-DD'),
        repaymentDate: moment(debit.processedDate).startOf('day').format('YYYY-MM-DD'),
        disbursementDate: this.dateService.now().startOf('day').format('YYYY-MM-DD'),
        amount: debit.amount,
        comment: 'batch',
        skipFees: skipFees,
        skipPrincipal: false,
        skipInterest: skipInterest
      }
      return this.loanRepaymentsRepository.create(payload)
    })
  }

  _showToast(error) {
    const msg = 'Error: ' + error.data.message + ' - ' + error.data.errors[0].source + ': ' + error.data.errors[0].message
    this.$mdToast.show(this.$mdToast.simple().textContent(msg).hideDelay(5000).position('top left'))
  }
}

export default DebitsClearedController
