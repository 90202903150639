class JournalEntriesController {
  /*@ngInject*/
  constructor() {
    this.name = 'journal-entries'
    this.searchTypes = [
      {key: 'all', label: 'ALL'},
      {key: 'for_system_account', label: 'System'}
    ]
    this.systemAccountTypes = [
      {key: 'trust', label: 'Trust'},
      {key: 'lending', label: 'Lending'},
      {key: 'unmatched', label: 'Unmatched'},
      {key: 'margin', label: 'Margin'},
      {key: 'tax_withholding', label: 'Tax Withholding'},
      {key: 'gst_collected', label: 'GST Collected'},
      {key: 'fees_collected', label: 'Fees Collected'},
    ]
    this.searchType = 'for_system_account'
    this.systemAccountType = 'trust'
    this.includeBetweenReservedAndAvailable = false
    this.changeQuery()
  }

  get systemAccountSearch() {
    return this.searchType === 'for_system_account'
  }

  changeQuery() {
    this.conditions = {scope: this.searchType, includeBetweenReservedAndAvailable: this.includeBetweenReservedAndAvailable}
    if (this.systemAccountSearch) {
      this.conditions.id = this.systemAccountType
    }
  }

}

export default JournalEntriesController
