import resolveProperty from '../../utils/resolveProperty'
import isDefined from '../../utils/isDefined'


class UserIdentity {
  /*@ngInject*/
  constructor(userResource) {
    this.resource = userResource
  }

  save() {
    return this.resource.save().then((user) => {
      this.resource = user
      return this
    })
  }

  // ****** GreenId identity check.

  identityCheckAttempted() {
    return isDefined(resolveProperty(this.resource, 'identityCheck'))
  }

  identityCheckStatus() {
    return this.resource.identityCheck.status
  }

  identityCheckInProgress() {
    return this.identityCheckAttempted() && this.identityCheckStatus() === 'pending'
  }

  identityCheckProgressed() {
    return this.identityCheckAttempted() && this.identityCheckStatus() !== 'pending'
  }

  identityCheckUnderReview() {
    return this.identityCheckAttempted() && this.identityCheckStatus() === 'review'
  }

  identityCheckRejected() {
    return this.identityCheckAttempted() && this.identityCheckStatus() === 'rejected'
  }

  identityCheckVerified() {
    return this.identityCheckAttempted() && this.identityCheckStatus() === 'verified'
  }

  get identityCheck() {
    return this.resource.identityCheck
  }

  set identityCheck(identityCheck) {
    this.resource.identityCheck = identityCheck
  }

  // ****** Biometric identity check.

  biometricIdentityCheckAttempted() {
    return isDefined(resolveProperty(this.resource, 'biometricIdentityCheck'))
  }

  biometricIdentityCheckStatus() {
    return this.resource.biometricIdentityCheck.status
  }

  biometricIdentityCheckPending() {
    return this.biometricIdentityCheckAttempted() && this.biometricIdentityCheckStatus() === 'pending'
  }

  biometricIdentityCheckInProgress() {
    return this.biometricIdentityCheckAttempted() && !['verified', 'rejected', 'legacy'].includes(this.biometricIdentityCheckStatus())
  }

  biometricIdentityCheckRequested() {
    return this.biometricIdentityCheckAttempted() && ['requested', 'retry'].includes(this.biometricIdentityCheckStatus())
  }

  biometricIdentityCheckLegacy() {
    return this.biometricIdentityCheckAttempted() && this.biometricIdentityCheckStatus() === 'legacy'
  }

  biometricIdentityCheckUnderReview() {
    return this.biometricIdentityCheckAttempted() && this.biometricIdentityCheckStatus() === 'review'
  }

  biometricIdentityCheckRejected() {
    return this.identityCheckAttempted() && this.biometricIdentityCheckStatus() === 'rejected'
  }

  biometricIdentityCheckVerified() {
    return this.biometricIdentityCheckAttempted() && this.biometricIdentityCheckStatus() === 'verified'
  }

  get biometricIdentityCheck() {
    return this.resource.biometricIdentityCheck
  }

  set biometricIdentityCheck(biometricIdentityCheck) {
    this.resource.biometricIdentityCheck = biometricIdentityCheck
  }
}

export default UserIdentity
