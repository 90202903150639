import angular from 'angular'
import 'angular-messages'
import 'angular-sanitize'
import 'angular-ui-router'
import 'angular-material'
import 'angular-material/angular-material.css'
import 'angular-jwt'
import 'angular-json-tree/build/angular-json-tree'
import 'angular-json-tree/build/angular-json-tree.css'
import 'angular-local-storage'
import 'angularfire'
import 'ng-currency'
import 'ng-csv'
import 'ng-fittext'
import 'ngtouch'
import 'angular-chartist.js'
import 'chartist/dist/chartist.min.css'
import 'angular-material-data-table'
import 'angular-material-data-table/dist/md-data-table.css'
import 'angular-jk-carousel'
import 'angular-jk-carousel/dist/jk-carousel.css'
import 'angular-contentful'
// tp modules
import commonModule from './common/common'
import filterModule from './common/filters'
import repositoriesModule from './services/repositories'
import servicesModule from './services/services'
import pagesModule from './pages/pages'
// helpers
import ResponseInterceptor from './helpers/responseInterceptor'
import RequestInterceptor from './helpers/requestInterceptor'
import addScrollHandler from './helpers/addScrollHandler'
import addJwtToHttp from './helpers/addJwtToHttp'
import exceptionHandlerDecorator from './helpers/exceptionHandlerDecorator'
// app component
import appComponent from './app.component'

export default angular.module('app', [
  'ngMessages',
  'ui.router',
  'ngMaterial',
  'angular-jwt',
  'angular-json-tree',
  'LocalStorageModule',
  'firebase',
  'ng-currency',
  'ngSanitize',
  'ngCsv',
  'ngFitText',
  'ngTouch',
  'angular-chartist',
  'md.data.table',
  'jkAngularCarousel',
  'contentful',
  commonModule.name,
  filterModule.name,
  repositoriesModule.name,
  servicesModule.name,
  pagesModule.name
])

// ****** CONFIGURE.

  .config(['$qProvider', 'environmentProvider', ($qProvider, environmentProvider) => {
    if (environmentProvider.env.TP_API_URL === 'https://api.truepillars.com') {
      $qProvider.errorOnUnhandledRejections(false)
    }
  }])
  .config(['$compileProvider', ($compileProvider) => {
    $compileProvider.aHrefSanitizationTrustedUrlList(/^\s*(https?|ftp|mailto|file|data|tel):/)
  }])
  .config(['jwtInterceptorProvider', '$httpProvider', (jwtInterceptorProvider, $httpProvider) => {
    addJwtToHttp(jwtInterceptorProvider, $httpProvider)
  }])
  .config(['jwtOptionsProvider', 'environmentProvider', (jwtOptionsProvider, environmentProvider) => {
    const env = environmentProvider.env
    jwtOptionsProvider.config({
      whiteListedDomains: [
        env.AUTH0_DOMAIN, // TODO: may not need this one!
        new URL(env.TP_API_URL).hostname,
      ]
    })
  }])
  .config(['contentfulProvider', 'environmentProvider', (contentfulProvider, environmentProvider) => {
    const env = environmentProvider.env
    contentfulProvider.setOptions({
      host: env.CONTENTFUL_HOST,
      space: env.CONTENTFUL_SPACE_ID,
      accessToken: env.CONTENTFUL_ACCESS_TOKEN
    })
  }])
  .config(['$mdThemingProvider', ($mdThemingProvider) => {
      const rootElement = window.getComputedStyle(document.documentElement)
      const mdPrimary1 = rootElement.getPropertyValue('--md-palette-colour-primary-1').trim().slice(1)
      const mdPrimary2 = rootElement.getPropertyValue('--md-palette-colour-primary-2').trim().slice(1)
      const mdPrimary3 = rootElement.getPropertyValue('--md-palette-colour-primary-3').trim().slice(1)
      const mdPrimary4 = rootElement.getPropertyValue('--md-palette-colour-primary-4').trim().slice(1)
      const mdAccent1 = rootElement.getPropertyValue('--md-palette-colour-accent-1').trim().slice(1)
      const mdAccent2 = rootElement.getPropertyValue('--md-palette-colour-accent-2').trim().slice(1)
      const mdWarn1 = rootElement.getPropertyValue('--md-palette-colour-warn-1').trim().slice(1)
      const mdWarn2 = rootElement.getPropertyValue('--md-palette-colour-warn-2').trim().slice(1)
      const mdWarn3 = rootElement.getPropertyValue('--md-palette-colour-warn-3').trim().slice(1)
      const mdWarn4 = rootElement.getPropertyValue('--md-palette-colour-warn-4').trim().slice(1)
      const mdBackground1 = rootElement.getPropertyValue('--md-palette-colour-background-1').trim().slice(1)
      const mdBackground2 = rootElement.getPropertyValue('--md-palette-colour-background-2').trim().slice(1)
      const mdForeground1 = rootElement.getPropertyValue('--md-palette-colour-foreground-1').trim()

      $mdThemingProvider.definePalette('tpPrimaryPalette', $mdThemingProvider.extendPalette('indigo', {'500': mdPrimary1, '300': mdPrimary2, '800': mdPrimary3, 'A100': mdPrimary4}))
      $mdThemingProvider.definePalette('tpAccentPalette', $mdThemingProvider.extendPalette('light-green', {'A200': mdAccent1, 'A700': mdAccent2}))
      $mdThemingProvider.definePalette('tpWarnPalette', $mdThemingProvider.extendPalette('deep-orange', {'300': mdWarn1, '500': mdWarn2, '800': mdWarn3, 'A100': mdWarn4}))
      $mdThemingProvider.definePalette('tpBackgroundPalette', $mdThemingProvider.extendPalette('grey', {'500': mdBackground1, 'A100': mdBackground2}))

      $mdThemingProvider.theme('default')
        .primaryPalette('tpPrimaryPalette')
        .accentPalette('tpAccentPalette')
        .warnPalette('tpWarnPalette')
        .backgroundPalette('tpBackgroundPalette')
      $mdThemingProvider.theme('default').foregroundPalette[1] = mdForeground1
    }]
  )
  .config(['$httpProvider', $httpProvider => {
    ResponseInterceptor.addToHttp($httpProvider)
    RequestInterceptor.addToHttp($httpProvider)
  }])
  .config(['$locationProvider', $locationProvider => {
      $locationProvider.html5Mode(true)
    }]
  )
  .config(['localStorageServiceProvider', localStorageServiceProvider => {
    localStorageServiceProvider.setPrefix('tp')
    localStorageServiceProvider.setStorageCookie(1, '/', true)
  }])
  // send errors to New Relic
  .config(['$provide', $provide => {
    exceptionHandlerDecorator($provide)
  }])

  // ****** RUN.

  // delete expired tokens
  .run(['userService', (userService) => {
    if (!userService.hasValidToken()) {
      userService.logout()
    }
  }])
  // reload page if release version has changed on next state change
  .run(['updateService', updateService => {
    updateService.init()
  }])
  // the policy init
  .run(['$rootScope', 'policyService', ($rootScope, policyService) => {
    policyService.init($rootScope)
  }])
  // third party services (GA, MyFace, etc.)
  .run(['$rootScope', 'thirdPartyService', ($rootScope, thirdPartyService) => {
    thirdPartyService.init($rootScope)
  }])
  .run(['browserDetectorService', browserDetectorService => {
    browserDetectorService.checkBrowser()
  }])
  .run(['applicationSettingsService', (applicationSettingsService) => {
    applicationSettingsService.init()
  }])
  .run(['navigation', (navigation) => {
    navigation.init()
  }])
  .run(['$rootScope', '$document', '$timeout', ($rootScope, $document, $timeout) => {
    addScrollHandler($rootScope, $document, $timeout)
  }])
  .run(['$rootScope', '$state', ($rootScope, $state) => {
    $rootScope.$on('$stateChangeStart', (evt, to, params) => {
      delete $state.current.parentTabName
      if (to.redirectTo) {
        evt.preventDefault()
        $state.go(to.redirectTo, params, {location: 'replace'})
      }
    })
  }])
  // preload the media assets.
  .run(['contentfulAssetsService', contentfulAssetsService => {
    contentfulAssetsService.init()
  }])
  // reload html on mobile orientation change
  .run(['$window', '$state', ($window, $state) => {
    $window.addEventListener('orientationchange', () => {
      $state.reload()
    })
  }])
  .directive('app', appComponent)
