import template from './formWidget.pug'
import controller from './formWidget.controller'
import './formWidget.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    require: ['^form'],
    controllerAs: 'vm',
    scope: {},
    link: (scope, el, attr, ctrls) => {
      let [form] = ctrls
      scope.vm.form = form
    },
    bindToController: {
      model: '=',
      linkToUri: '=',
      widget: '<'
    }
  }
}
