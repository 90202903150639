export default class ContentPanelToggleService {

  // Map component field name to feature toggle name.
  componentFeatureToggles = {
    'rideshare-finance-loan-calculator': 'enableRideshareCalculator'
  }

  // Map panel name to whether or not it should be rendered.
  renderablePanels = {
    'rideshare-finance-loan-calculator': false
  }

  /*@ngInject*/
  constructor($rootScope, featureTogglesService) {
    this.$rootScope = $rootScope
    this.featureTogglesService = featureTogglesService

    this.$rootScope.$on('accredited-broker', () => {
      this.$rootScope.$apply(() => {
        this.renderablePanels['rideshare-finance-loan-calculator'] = true
      })
    })
  }

  isEnabled(panel) {
    if (!panel || !panel.fields || !panel.fields.name) {
      return true
    }

    const panelName = panel.fields.name

    if (this.componentFeatureToggles[panelName]) {
      return this.featureTogglesService.can(this.componentFeatureToggles[panelName])
    }

    return true
  }

  shouldRender(panel) {
    if (!panel || !panel.fields || !panel.fields.name) {
      return true
    }

    const panelName = panel.fields.name

    if (this.renderablePanels.hasOwnProperty(panelName)) {
      return this.renderablePanels[panelName]
    }

    return true
  }
}
