export default () => {
  return {
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
      ngModel.$formatters.push((value) => {
        return new Date(value)
      })
    }
  }
}
