import template from './bankStatements.pug'
import controller from './bankStatements.controller.js'
import './bankStatements.scss'
import './sendBankStatementsRequestDialog/sendBankStatementsRequestDialog.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '='
    }
  }
}
