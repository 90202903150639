import isDefined from '../../utils/isDefined'
import resolveProperty from '../../utils/resolveProperty'

const reportRequestParameters = (request) => {
  if (!isDefined(request)) {
    throw new Error('Report request must be defined.')
  }
  const requestType = resolveProperty(request, 'type')
  if (!isDefined(requestType) || requestType.toString().trim().length < 1) {
    throw new Error('Report request type must be defined.')
  }
  const parameterPairs = resolveProperty(request, 'parameters')
  const parameters = isDefined(parameterPairs) && Object.getOwnPropertyNames(parameterPairs).length > 0 ?
    ((pairs) => {
      return `?${Object.getOwnPropertyNames(pairs)
        .map((propertyName) => `${propertyName}=${pairs[propertyName]}`)
        .join('&')}`
    })(parameterPairs) :
    ''
  return `${requestType}${parameters}`
}

export default reportRequestParameters
