import moment from 'moment'

class CreateInvestmentProductController {
  /*@ngInject*/
  constructor(investmentProductsRepository) {
    this.investmentProductsRepository = investmentProductsRepository
    this._startDate = moment().startOf('day').toDate()
    this.type = 'standard'
    this.brandingId = null
    this.estimatedStartDate = undefined
  }

  get startDate() {
    return this._startDate
  }

  set startDate(date) {
    this._startDate = date
    if (this.createForm) {
      this.createForm['startDate'].$setValidity('range', !(this.startDate && moment(this.startDate).isValid()) || !moment(this.startDate).isBefore(moment().startOf('day').toDate()))
    }
  }

  confirmAction() {
    return this._create()
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  _create() {
    const payload = {
      name: this.name,
      type: this.type,
      brandingId: this.brandingId,
      estimatedStartDate: this.estimatedStartDate,
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      targetRate: this.targetRate,
      unitPrice: this.unitPrice,
      commitmentPeriod: this.commitmentPeriod,
      redemptionNoticePeriod: this.redemptionNoticePeriod,
      earlyRedemptionFeeRate: this.earlyRedemptionFeeRate,
      maturityRedemptionFeeRate: this.maturityRedemptionFeeRate,
      minimumInitialInvestmentAmount: this.minimumInitialInvestmentAmount,
      minimumAdditionalInvestmentAmount: this.minimumAdditionalInvestmentAmount
    }
    return this.investmentProductsRepository.create(payload)
      .then((investmentProduct) => this.completion.resolve(investmentProduct))
  }
}

export default CreateInvestmentProductController
