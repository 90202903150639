import angular from 'angular'
import 'angular-ui-router'

import investorSignupComponent from './investorSignup.component'

export default angular.module('app.pages.investors.investorSignup', ['ui.router'])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('investorSignup', {
        url: '/investor-signup',
        template: '<investor-signup />',
        tabName: 'Investors',
        data: {
          appClasses: ['investor-signup']
        }
      })
  }])
  .directive('investorSignup', investorSignupComponent)
