class InvestorSignupController {
  /*@ngInject*/
  constructor(router, loginRouter, userService, entityContextService) {
    this.name = 'investor-signup'

    this.$onInit = () => {
      userService.signup().then(() => {
        entityContextService.createInvestorApplication().then(() => {
          loginRouter.gotoDashboard()
        })
      }).catch(() => {
        router.go('home')
      })
    }
  }
}

export default InvestorSignupController
