import template from './dotPoint.pug'
import controller from './dotPoint.controller'
import './dotPoint.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    transclude: true,
    scope: {},
    bindToController: {
      icon: '@',
      svg: '@?',
      label: '@',
      text: '@',
      value: '@',
      sref: '@',
      assetId: '@?',
      assetName: '@?'
    },
  }
}
