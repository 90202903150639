import Big from 'big.js'

class RepaymentsDueController {
  /*@ngInject*/
  constructor($q, $mdDialog, loansRepository, dateService, abaViewerService) {
    this.name = 'debits-due'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.loansRepository = loansRepository
    this.dateService = dateService
    this.abaViewerService = abaViewerService

    this.order = '-_repaymentDate'
    this.selectedLoanIds = []

    this.$onInit = () => {
      this.reload()
    }
  }

  reload() {
    const today = this.dateService.now().format('YYYY-MM-DD')
    this.selectedLoanIds = []
    this.loading = this.loansRepository.where({status: 'not_completed', includeAdditionalDetails: true, size: 5000}).then(data => {
      this.loans = data.loans.filter(loan =>
        (loan.status === 'active' && loan.nextDebitSummary?.status !== 'processing') ||
        (loan.nextDebitSummary?.status === 'scheduled' || loan.nextDebitSummary?.status === 'rejected')
      )

      this.loans.forEach(loan => {
        if (loan.nextDebitSummary?.status === 'scheduled') {
          loan._repaymentDate = loan.nextDebitSummary.dueDate
          loan._repaymentAmount = loan.nextDebitSummary.amount
        } else if (loan.nextDebitSummary?.status === 'rejected' && (!loan.nextRepaymentSummary || loan.nextRepaymentSummary?.dueDate < today)) {
          loan._repaymentDate = loan.nextDebitSummary.dueDate
          loan._repaymentAmount = loan.nextDebitSummary.amount
        } else {
          loan._repaymentDate = loan.nextRepaymentSummary?.dueDate
          loan._repaymentAmount = loan.nextRepaymentSummary?.amount
          if (loan.nextDebitSummary?.status) {
            loan.nextDebitSummary.status = ''
          }
        }
      })
      this.loans = this.loans.filter(loan => loan?._repaymentDate <= today)

      this.selectedLoanIds = this.loans
        .filter(loan => loan._repaymentDate === today && loan.hasBankDetail && !loan.disableDirectDebit)
        .map(loan => loan.id)
    })
  }

  viewABA($event, abaId, selectedDebitId) {
    this.abaViewerService.show($event, abaId, selectedDebitId)
  }

  createABA($event) {
    const completion = this.$q.defer()
    completion.promise.finally(() => {
      this.$mdDialog.hide()
      this.reload()
    })

    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      locals: {
        loanIds: this.selectedLoanIds,
        debitIds: [],
        total: this._sumRepaymentAmounts(this.selectedLoanIds),
        completion: completion
      },
      controllerAs: 'vm',
      bindToController: true,
      template:
        `<md-dialog>
          <md-dialog-content>
            <admin-create-aba loan-ids="vm.loanIds" total="vm.total" completion="vm.completion"/>
          </md-dialog-content>
        </md-dialog>`,
      controller: function() {},
    })
  }

  createLoanRepayment($event, loan) {
    const completion = this.$q.defer()
    completion.promise
      .finally(() => {
        this.$mdDialog.hide()
        this.reload()
      })

    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      locals: {
        loan: loan,
        completion: completion
      },
      controllerAs: 'vm',
      bindToController: true,
      template:
        `<md-dialog flex="70">
          <md-dialog-content layout-padding>
            <admin-create-loan-repayment loan="vm.loan" type="standard" completion="vm.completion"/>
          </md-dialog-content>
        </md-dialog>`,
      controller: function() {},
    })
  }

  // private

  _sumRepaymentAmounts(loanIds) {
    const loansMap = this.loans.reduce((map, loan) => map.set(loan.id, loan), new Map())
    return loanIds.reduce((total, id) => total.plus(loansMap.get(id)?._repaymentAmount || 0), new Big(0)).toNumber()
  }
}

export default RepaymentsDueController
