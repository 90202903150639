class CreditReportsController {
  /*@ngInject*/
  constructor(creditReportsRepository, usersRepository) {
    this.name = 'credit-reports'
    this.creditReportsRepository = creditReportsRepository
    this.usersRepository = usersRepository
    this.reportType = 'drs'
    this.hiddenSections = []
    this.sections = []

    this.$onInit = () => {
      if (this.loanRequest) {
        this.loanRequest.promise('borrower').then((borrower) => {
          this.borrower = borrower
          this.lookupKey = 'abn'
        })
          .then(() => this.usersRepository.where({userType: 'beneficial', borrowerId: this.borrower.id}))
          .then((beneficialOwners) => this._createSections(beneficialOwners.users))
      }
    }
  }

  get reportTypes() {
    return ['drs', 'drs_asic_refresh', 'sbrs']
  }

  get lookupKey() {
    return this._lookupKey
  }

  set lookupKey(key) {
    this._lookupKey = key
    this.lookupValue = (key === 'abn') && this.borrower ? this.borrower.abn : ''
  }

  shouldShow(section) {
    return this.hiddenSections.indexOf(section) === -1
  }

  toggleShow(section) {
    const index = this.hiddenSections.indexOf(section)
    if (index === -1) {
      this.hiddenSections.push(section)
    } else {
      this.hiddenSections.splice(index, 1)
    }
  }

  consumerReportDisabled(item) {
    return !item || !item.canonicalAddress || !item.canonicalAddress.fullAddress || (item.consumerReport && item.consumerReport.individual_detail)
  }

  requestCommercialReport(section) {
    const query = {borrowerId: this.borrower.id, reportType: this.reportType}
    query[this.lookupKey] = this.lookupValue
    this._loadReport(section, 'commercialReport', this.creditReportsRepository.create(query))
  }

  requestConsumerReport(representative) {
    this._loadReport(representative, 'consumerReport', this.creditReportsRepository.create({userId: representative._id, reportType: 'cbd3'}))
  }

  // private

  _createSections(users) {
    // this.sections = {borrower: this._borrowerDetails(this.borrower), representatives: []}
    this.sections = [
      {key: 'borrower', value: this._borrowerDetails(this.borrower)},
      {key: 'representatives', value: []}
    ]
    if (users && users.length > 0) {
      this.sections[1].value = Object.keys(users).reduce((memo, key, index) => {
        const user = users[key]
        const {firstName, lastName} = user
        memo[`${index + 1}. ${firstName} ${lastName}`] = this._representativeDetails(user)
        return memo
      }, {})
    }
  }

  _loadReport(item, property, reportPromise) {
    if (reportPromise) {
      item._loading = true
      return reportPromise.then((report) => {
        this._replaceAll(item[property], report.content)
      }).catch((error) => {
        this.errors = error.data && error.data.message && JSON.parse(error.data.message)
      }).finally(() => {
        delete item._loading
      })
    }
  }

  _replaceAll(base, contents) {
    Object.keys(base).forEach((key) => delete (base[key]))
    Object.assign(base, contents, {currentDate: new Date().toTimeString()})
  }

  _borrowerDetails(borrower) {
    const details = {
      name: borrower.name,
      abn: borrower.abn,
      tradingSince: borrower.tradingSince,
      commercialReport: {}
    }
    this._loadReport(details, 'commercialReport', this.borrower.promise('creditReport'))
    return details
  }

  _representativeDetails(user) {
    const details = {
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      email: user.email,
      dob: user.dob,
      canonicalAddress: user.canonicalAddress,
      consumerReport: {},
      _id: user.id
    }
    this._loadReport(details, 'consumerReport', user.promise('creditReport'))
    return details
  }
}

export default CreditReportsController
