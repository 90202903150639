import {container, text, radioButtons, ruleRender} from '../dynamicForm/helpers/dynamicFormHelpers'

const transitionStageFormDefinition = {
  title: 'Biometric Identity Check Stage',
  model: 'biometricIdentityCheckStage',
  actionLabel: 'Update',
  widgets: [
    container().prompt('You\'re updating the biometric identity check stage').classes('border box-shadow-2').widgets(
      text().prompt('Please add appropriate explanatory comments for the stage change').required().model('comment').build(),
      radioButtons().prompt('What is the reason for the retry?').model('retryReason').contentId('biometric-identity-check-retry-reasons')
        .rules(ruleRender('(eq (fromContext "transition") "request_again")')).required(true).block().build()
    ).build(),
  ]
}

export default transitionStageFormDefinition
