import {isVersion1LoanEnquiryDetailResponse, isVersion2LoanEnquiryDetailResponse, loanEnquiryDetailsByStatus, loanEnquiryDetailsSortByUpdatedAt} from '../../../../../utils/loanEnquiryDetailHelper'

// ****** Configuration for mapping the uploads.

const UPLOAD_GROUPS_IN_ORDER = [
  {label: 'Profit and Loss Statements', types: ['profitAndLossStatements']},
  {label: 'Balance Sheets', types: ['balanceSheets']},
  {label: 'Financial Statements', types: ['financialStatements', 'yearToDateFinancialStatements']},
  {label: 'Transaction Statements', types: ['electronicBankStatements.bankStatements', 'electronicBankStatements.smartStatements']},
  {label: 'Invoices / Orders', types: ['assetInvoices']},
  {label: 'Other', types: ['otherDocuments']},
  {label: 'Categorised', types: [], default: true}
]

const DEFAULT_TYPE_KEY = UPLOAD_GROUPS_IN_ORDER.findIndex(group => group.default)

const MAP_UPLOAD_GROUPS_BY_TYPE = UPLOAD_GROUPS_IN_ORDER.reduce((map, uploadGroup, index) => {
  uploadGroup.types.forEach(type => map[type] = index)
  return map
}, {})

const uploadGroupKey = (uploadType) => MAP_UPLOAD_GROUPS_BY_TYPE.hasOwnProperty(uploadType) ? MAP_UPLOAD_GROUPS_BY_TYPE[uploadType] : DEFAULT_TYPE_KEY

const setLinkExpiryMessage = (remainingTimeInMinutes) => {
  if (remainingTimeInMinutes < 1) {
    return 'have EXPIRED. Refresh the page to reactivate'
  } else {
    return `will expire in ${remainingTimeInMinutes} minute${remainingTimeInMinutes > 1 ? 's' : ''}`
  }
}

// ****** Sorting and grouping entities.

const groupedUploadsContainer = (label) => {
  const allUploads = []
  const allCategories = []
  const uploads = new Map()
  return {
    label: label,
    getCategories: () => {
      return allCategories
    },
    size: () => allUploads.length,
    getUploads: (key) => {
      return uploads.get(key).sort((a, b) => a.loanEnquiryDetail.createdAt < b.loanEnquiryDetail.createdAt ? 1 : (a.loanEnquiryDetail.createdAt > b.loanEnquiryDetail.createdAt ? -1 : 0))
    },
    addUpload: (upload, prompt) => {
      if (!uploads.has(prompt)) {
        allCategories.push(prompt)
        uploads.set(prompt, [])
      }
      const category = uploads.get(prompt)
      category.push(upload)
      allUploads.push(upload)
    }
  }
}

class UploadedFilesController {
  /*@ngInject*/
  constructor($scope, $interval, attachmentsRepository, loggerService) {
    this.name = 'enquiry-details-uploaded-files'
    const logger = loggerService.makeLogger()
    this.uploadGroups = UPLOAD_GROUPS_IN_ORDER.map(uploadType => groupedUploadsContainer(uploadType.label))

    let linkTimeOutInMinutes = 120
    this.remainingMinutes = setLinkExpiryMessage(linkTimeOutInMinutes)

    $interval(() => {
      linkTimeOutInMinutes = linkTimeOutInMinutes > 0 ? linkTimeOutInMinutes - 1 : linkTimeOutInMinutes
      this.remainingMinutes = setLinkExpiryMessage(linkTimeOutInMinutes)
    }, 60 * 1000)

    const addFileReference = (loanEnquiryDetail, name, prompt, uploads) => {
      const addToUploadGroup = (attachment) => {
        this.uploadGroups[uploadGroupKey(name)].addUpload({
          loanEnquiryDetail: loanEnquiryDetail,
          name: attachment.name,
          mime: attachment.mime,
          url: attachment.url,
          createdAt: attachment.createdAt
        }, prompt)
      }
      uploads.forEach((upload) => {
        const attachmentId = upload.attachmentId ? upload.attachmentId : upload.replace(/^.*[/](\d+)$/, '$1')
        attachmentsRepository.getById(attachmentId).then(attachment => {
          if (this.resourceCache.has('attachment-' + attachmentId)) {
            addToUploadGroup(attachment)
          } else {
            attachmentsRepository.getById(attachmentId).then(attachment => {
              this.resourceCache.set('attachment-' + attachmentId, attachment)
              addToUploadGroup(attachment)
            })
          }
        })
      })
    }

    this.$onInit = () => {
      this.loanEnquiry.promise('loanEnquiryDetails').then(() => {
        $scope.$watch(() => this.loanEnquiry.loanEnquiryDetails, (loanEnquiryDetails) => {
          this.uploadGroups = UPLOAD_GROUPS_IN_ORDER.map(uploadType => groupedUploadsContainer(uploadType.label))
          linkTimeOutInMinutes = 120

          loanEnquiryDetailsByStatus(loanEnquiryDetailsSortByUpdatedAt(loanEnquiryDetails), 'active', 'complete')
            .forEach((loanEnquiryDetail) => {
              if (isVersion1LoanEnquiryDetailResponse(loanEnquiryDetail.response)) {
                if (loanEnquiryDetail.response.hasOwnProperty('uploads')) {
                  Object.getOwnPropertyNames(loanEnquiryDetail.response.uploads).forEach((propertyName) => {
                    if (loanEnquiryDetail.response.uploads[propertyName].length > 0) {
                      addFileReference(loanEnquiryDetail, propertyName, '', loanEnquiryDetail.response.uploads[propertyName])
                    }
                  })
                }
              } else if (isVersion2LoanEnquiryDetailResponse(loanEnquiryDetail.response)) {
                loanEnquiryDetail.response.enquiryDetails.filter((qanda) => qanda.type === 'upload' || qanda.type === 'FileUpload').forEach((qanda) => {
                  if (Array.isArray(qanda.answer) && qanda.answer.length > 0) {
                    addFileReference(loanEnquiryDetail, qanda.name, '', qanda.answer)
                  } else if (qanda.answer.uploads && Array.isArray(qanda.answer.uploads) && qanda.answer.uploads.length > 0) {
                    addFileReference(loanEnquiryDetail, qanda.name, qanda.answer.prompt, qanda.answer.uploads)
                  }
                })
              } else {
                logger.log('UNKNOWN loan enquiry details response format')
              }
            })
        })
      })
    }
  }

  get hasFiles() {
    return this.uploadGroups.some((uploadGroup) => uploadGroup.size() > 0)
  }

  uploadGroupsWithFiles() {
    return this.uploadGroups.filter(uploadGroup => uploadGroup.size() > 0)
  }
}

export default UploadedFilesController
