import template from './viewABA.pug'
import controller from './viewABA.controller'
import './viewABA.scss'

export default () => {
  return {
    template: template({}),
    controller: controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      selectedDebitId: '<',
      abaId: '<',
      completion: '=',
    },
    bindToController: true
  }
}
