import angular from 'angular'
import 'angular-ui-router'

import documentsComponent from './documents.component'

export default angular.module('app.pages.documents', ['ui.router'])
    .config(['$stateProvider', $stateProvider => {
      $stateProvider
        .state('documents', {
          url: '/documents/:title',
          template: '<documents></documents>'
        })
    }])
  .directive('documents', documentsComponent)
