class FinancialsController {
  /*@ngInject*/
  constructor(bpayUploadsRepository) {
    this.name = 'financials'
    this.bpayUploadsRepository = bpayUploadsRepository
    this.toggles = {}
    this.progressStatus = {}
    this.createdAttachments = {}

    this.$onInit = () => {
      this.bpayUploadsRepository.all().then((allUploads) => {
        this.allUploads = allUploads.bpayUploads.sort((a, b) => {
          return b.id - a.id
        })
      })
    }
  }

  toggleShow(upload) {
    this.toggles[upload.id] = !this.toggles[upload.id]
  }

  shouldShow(upload) {
    return this.toggles[upload.id]
  }

  beginUpload() {
    this.bpayUploadsRepository.create().then((bpayUpload) => {
      this.allUploads.unshift(bpayUpload)
    })
  }

  uploadChanged(added, upload) {
    upload.attachmentId = added.id
    upload.save()
  }

  commit(upload) {
    upload.statusEvent = 'commit'
    this._updateUpload(upload)
  }

  reject(upload) {
    upload.statusEvent = 'reject'
    this._updateUpload(upload)
  }

  inProgress(upload) {
    return this.progressStatus[upload.id]
  }

  canProgressState(upload) {
    return upload.status === 'processed' && !this.inProgress(upload)
  }

  _updateUpload(upload) {
    this.progressStatus[upload.id] = true
    upload.save().then(() => {
      this.progressStatus[upload.id] = false
    })
  }
}

export default FinancialsController
