import angular from 'angular'

import environmentModule from './environment/environment'
import customPropertiesModule from './customProperties/customProperties'
import browserDetectorModule from './browserDetector/browserDetector'
import policyModule from './policy/policy'
import thirdPartyModule from './thirdParty/thirdParty'

import routerModule from './router/router'
import loginRouterModule from './loginRouter/loginRouter'
import authModule from './auth/auth'
import authLockModule from './auth/authLock'
import authLockProviderModule from './auth/authLockProvider'
import userModule from './user/user'
import navigationModule from './navigation/navigation'
import calculatorModule from './calculator/calculator'
import cognitoModule from './auth/cognito'
import awsAmplifyModule from './awsAmplify/awsAmplify'

import dateModule from './date/date'
import deepMergeModule from './deepMerge/deepMerge'
import formErrorsModule from './formErrors/formErrors'
import volatileStoreModule from './volatileStore/volatileStore'
import loggerModule from './logger/logger'

import investorApplicationRouterModule from './applicationRouter/investorApplicationRouter'

import hopscotchModule from './hopscotch/hopscotch'
import notificationsModule from './notifications/notifications'
import firebaseLoginManagerModule from './firebaseLoginManager/firebaseLoginManager'
import featureTogglesModule from './featureToggles/featureToggles'
import contentPanelToggleModule from './contentPanelToggle/contentPanelToggle'
import applicationSettingsModule from './applicationSettings/applicationSettings'
import versionModule from './version/version'
import updateModule from './update/update'
import csvModule from './csv/csv'
import pdfModule from './pdf/pdf'
import animeModule from './anime/anime'
import audioModule from './audio/audio'
import clipboardFactoryModule from './clipboardFactory/clipboardFactory'
import viewerFactoryModule from './viewerFactory/viewerFactory'

import ratingsModule from './ratings/ratings'
import mediaModule from './media/media'
import entityContextServiceModule from './entityContext/entityContext'
import loanPartListingsModule from './loanPartListings/loanPartListings'
import loanRequestModule from './loanRequest/loanRequest'
import uploadModule from './upload/upload'
import withdrawalModule from './withdrawal/withdrawal'
import emailVerificationModule from './emailVerification/emailVerification'

import greenidModule from './greenid/greenid'
import biometricIdentitiesService from './biometricIdentities/biometricIdentities'
import contentItemsModule from './contentItems/contentItems'
import confirmationDialogModule from './confirmationDialog/confirmationDialog'
import dynamicDialogModule from './dynamicDialog/dynamicDialog'
import helpMatesModule from './helpMates/helpMates'
import helpPanelsModule from './helpPanels/helpPanels'
import iconsModule from './icons/icons'

import brandingModule from './branding/branding'
import tenantInformationModule from './tenantInformation/tenantInformation'
import stylingModule from './styling/styling'

import sharedStateModule from './sharedState/sharedState'
import rulesModule from './rules/rules'
import reportsModule from './reports/reports'

import abaViewerModule from '../pages/admin/adminDashboard/directDebits/viewABA/abaViewer'

export default angular.module('app.services', [
  environmentModule.name,
  customPropertiesModule.name,
  browserDetectorModule.name,
  policyModule.name,
  thirdPartyModule.name,

  routerModule.name,
  loginRouterModule.name,
  authModule.name,
  authLockModule.name,
  authLockProviderModule.name,
  userModule.name,
  navigationModule.name,
  calculatorModule.name,
  cognitoModule.name,
  awsAmplifyModule.name,

  dateModule.name,
  deepMergeModule.name,
  formErrorsModule.name,
  volatileStoreModule.name,
  loggerModule.name,

  investorApplicationRouterModule.name,

  hopscotchModule.name,
  notificationsModule.name,
  firebaseLoginManagerModule.name,
  featureTogglesModule.name,
  contentPanelToggleModule.name,
  applicationSettingsModule.name,
  versionModule.name,
  updateModule.name,
  csvModule.name,
  pdfModule.name,
  animeModule.name,
  audioModule.name,
  viewerFactoryModule.name,
  clipboardFactoryModule.name,

  ratingsModule.name,
  mediaModule.name,
  entityContextServiceModule.name,
  loanRequestModule.name,
  loanPartListingsModule.name,
  withdrawalModule.name,
  uploadModule.name,
  emailVerificationModule.name,

  greenidModule.name,
  biometricIdentitiesService.name,
  contentItemsModule.name,
  confirmationDialogModule.name,
  dynamicDialogModule.name,
  helpMatesModule.name,
  helpPanelsModule.name,
  iconsModule.name,

  brandingModule.name,
  tenantInformationModule.name,
  stylingModule.name,

  sharedStateModule.name,
  rulesModule.name,

  reportsModule.name,

  abaViewerModule.name
])
