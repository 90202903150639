import moment from 'moment'

class UpdateAuctionController {
  /*@ngInject*/
  constructor() {
  }

  get auction() {
    return this._auction
  }

  set auction(auction) {
    this._auction = auction
    this.startDate = this._toDate(this.auction.startDate)
    this.endDate = this._toDate(this.auction.endDate)
  }

  update() {
    this.auction.startDate = this._toString(this.startDate)
    this.auction.endDate = this._toString(this.endDate)
    return this.auction.save().then(() => this.completion.resolve())
  }

  cancel() {
    this.auction.reload()
    this.completion.reject()
  }

  _toDate(dateString) {
    return moment(dateString).toDate()
  }

  _toString(date) {
    return moment(date).utc().format()
  }
}

export default UpdateAuctionController
