import resolveProperty from '../../../../utils/resolveProperty'

class KeyInfoController {
  /*@ngInject*/
  constructor(ratingsService) {
    this.name = 'key-info'
    this._ratingsService = ratingsService
  }

  get badDebtRate() {
    return this.loanRequest && this._ratingsService.rating(this.loanRequest.riskRating).risk
  }

  get isOriginatedFromTruePillars() {
    return resolveProperty(this, 'loanRequest', 'origination') === 'truepillars'
  }
}

export default KeyInfoController
