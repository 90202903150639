class InvestorStatusHelper {
  constructor(userService, entityContextService) {
    this.userService = userService
    this.entityContextService = entityContextService
  }

  bidStatusMessage(bidAmountMin) {
    if (!this.userService.isLoggedIn()) return 'Log in to bid'
    if (!this.entityContextService.isAccepted()) return 'Register to bid'
    if (!this.userService.isIdVerified()) return 'Register to bid'
    if (!this.entityContextService.hasFundsToBuy(bidAmountMin)) return 'Add funds to bid'
    if (!this.userService.isEmailVerified()) return 'Verify email to bid'
    return 'Enter bid'
  }

  selectStatusMessage(listingAmount) {
    if (!this.userService.isLoggedIn()) return 'Log in to select'
    if (!this.entityContextService.isAccepted()) return 'Register to proceed'
    if (!this.userService.isIdVerified()) return 'Register to proceed'
    if (!this.entityContextService.hasFundsToBuy(listingAmount)) return 'Add funds to proceed'
    if (!this.userService.isEmailVerified()) return 'Verify email to proceed'
    return 'Select listing'
  }
}

export default InvestorStatusHelper
