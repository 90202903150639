import angular from 'angular'
import 'angular-ui-router'

import investorP2pModule from './investorP2p/investorP2p'
import investorProductsSummaryModule from './investorProductsSummary/investorProductsSummary'
import manageFundsModule from './manageFunds/manageFunds'
import investorReportsModule from './investorReports/investorReports'
import investorSettingsModule from './investorSettings/investorSettings'
import investmentProductPartsModule from './investmentProductParts/investmentProductParts'

import investorDashboardComponent from './investorDashboard.component'

export default angular.module('app.pages.investors.investorDashboard', [
  'ui.router',
  investorProductsSummaryModule.name,
  investorP2pModule.name,
  manageFundsModule.name,
  investorReportsModule.name,
  investorSettingsModule.name,
  investmentProductPartsModule.name
])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('investorDashboard', {
        url: '/investor',
        template: '<investor-dashboard />',
        replace: true,
        data: {
          requireAuth: true,
          resumeOnLogin: true
        }
      })
      .state('investorDashboard.summary', {
        url: '/summary',
        template: '<investor-products-summary />',
        tabName: 'Dashboard'
      })
      .state('investorDashboard.p2p', {
        url: '/p2p',
        template: '<investor-p2p />',
        tabName: 'Dashboard'
      })
      .state('investorDashboard.investmentProductParts', {
        url: '/investments',
        template: '<investment-product-parts />',
        tabName: 'Dashboard'
      })
      .state('investorDashboard.manageFunds', {
        url: '/manage-funds',
        template: '<investor-manage-funds />',
        tabName: 'Dashboard'
      })
      .state('investorDashboard.investorSettings', {
        url: '/settings',
        template: '<investor-settings />',
        tabName: 'Dashboard'
      })
      .state('investorDashboard.reports', {
        url: '/reports',
        template: '<investor-reports />',
        tabName: 'Dashboard'
      })
  }])
  .directive('investorDashboard', investorDashboardComponent)
