import Resource from './resource'

class LiveResource extends Resource {
  static create($http, $q, resourceFactory, data, $firebaseObject, $rootScope) {
    return new LiveResource($http, $q, resourceFactory, data, $firebaseObject, $rootScope)
  }

  /*@ngInject*/
  constructor($http, $q, resourceFactory, data, $firebaseObject, $rootScope) {
    super($http, $q, resourceFactory, data)
    this._listenForChanges($firebaseObject, $rootScope)
  }

  _assignData(data) {
    super._assignData(data)
    if (this._links && this._links.live) {
      this.live = this._links.live
      delete this._links.live
    }
  }

  _listenForChanges($firebaseObject, $rootScope) {
    this._liveFirebaseObject = $firebaseObject(new Firebase(this.live.href))
    this._liveFirebaseObject.$loaded().then(() => this._liveFirebaseObject.$watch(() => this._syncData()))
    this._removeChangeListener = $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
      if (!(this._getParentStateName(toState) === this._getParentStateName(fromState) && toParams.id === fromParams.id)) {
        this._liveFirebaseObject.$destroy()
        this._removeChangeListener()
      }
    })
  }

  _hasParentState(state) {
    return state.name.indexOf('.') !== -1
  }

  _getParentStateName(state) {
    return this._hasParentState(state) ? state.name.substring(0, state.name.indexOf('.')) : state.name
  }

  _syncData() {
    for (let propertyName in this._liveFirebaseObject) {
      if (!this._isIgnoredForSynch(propertyName)) {
        if (propertyName in this && this[propertyName] !== this._liveFirebaseObject[propertyName]) {
          this[propertyName] = this._liveFirebaseObject[propertyName]
        }
      }
    }
  }

  _isIgnoredForSynch(propertyName) {
    return propertyName.indexOf('$') === 0 || propertyName === '_links' || propertyName === '_embedded'
  }

  _isIgnoredForJson(propertyName) {
    return super._isIgnoredForJson(propertyName) || propertyName === 'live'
  }
}

export default LiveResource
