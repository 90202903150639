
const makeAddRepresentative = ($scope, $q, $mdDialog, loanEnquiry, resolveDefaults = () => ({})) => {

  return (event) => {
    const template = `
      <md-dialog flex="50">
        <md-dialog-content layout-padding>
          <add-representative default-values="vm.defaultValues" entity='vm.loanEnquiry' completion='vm.completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    let hasEmitted = false
    const completion = $q.defer()
    completion.promise.then(() => $mdDialog.hide())
    completion.promise
      .then(() => {
        if (!hasEmitted) {
          $scope.$emit('loanEnquiryEvent', 'reloadRepresentatives')
          hasEmitted = true
        }
      })
      .finally(() => $mdDialog.hide())
    $mdDialog.show({
      escapeToClose: true,
      targetEvent: event,
      parent: angular.element(document.body),
      locals: {
        loanEnquiry: loanEnquiry,
        completion: completion,
        defaultValues: resolveDefaults()
      },
      template: template,
      controllerAs: 'vm',
      bindToController: true,
      controller: () => {
      }
    })
  }
}

export default makeAddRepresentative
