import moment from 'moment'

class CreateLoanLossController {
  /*@ngInject*/
  constructor($filter, $mdDialog, loanLossesRepository) {
    this.name = 'create-loan-loss'
    this.$filter = $filter
    this.$mdDialog = $mdDialog
    this.loanLossesRepository = loanLossesRepository
  }

  confirmAction() {
    const options = this.$mdDialog.confirm({
      onComplete: (scope, element, options) => {
        const actionsSection = element.find('md-dialog-actions')
        const cancelButton = actionsSection.children()[0]
        const confirmButton = actionsSection.children()[1]
        angular.element(confirmButton).removeClass('md-focused')
        angular.element(cancelButton).addClass('md-focused')
        cancelButton.focus()
      }
    })
      .htmlContent(`
        <div class="loss-confirmation">Are you sure that you want to create the loss?
          <table>
            <tr><th>Date:</th><td>${this._toDisplayDate(this.date)}</td></tr>
            <tr><th>Amount:</th><td>${this._toDisplayAmount(this.amount)}</td></tr>
            <tr><th>Comment:</th><td>${this.comment || ''}</td></tr>
          </table>
        </div>
      `)
      .clickOutsideToClose(true)
      .title('Confirm?')
      .ariaLabel('Confirm?')
      .multiple(true)
      .ok('Ok')
      .cancel('Cancel')
    return this.$mdDialog.show(options)
      .then(() => this._create(), () => this.cancelAction())
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  _create() {
    this.$mdDialog.show({
      template: `
        <md-dialog flex="50">
          <md-dialog-content class="loss-processing" layout-padding layout="column" layout-align="center center">
            <h2>Processing loan loss</h2> 
            <md-progress-circular md-mode="indeterminate" md-diameter="200"></md-progress-circular>
          </md-dialog-content>
        </md-dialog>
      `,
      escapeToClose: false,
      clickOutsideToClose: false
    })
    const payload = {
      loanId: this.loan.id,
      date: this._toBackendDate(this.date),
      amount: this.amount,
      comment: this.comment
    }
    return this.loanLossesRepository.create(payload)
      .then((loanLoss) => this.completion.resolve(loanLoss))
      .finally(() => this.$mdDialog.hide())
  }

  _toBackendDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  _toDisplayDate(date) {
    return this.$filter('asMoment')(moment(date), 'ddd D MMM YYYY')
  }

  _toDisplayAmount(amount) {
    return this.$filter('asCurrency')(amount)
  }
}

export default CreateLoanLossController
