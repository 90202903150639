import template from './history.pug'
import controller from './history.controller.js'
import './history.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '=',
      resourceCache: '='
    }
  }
}
