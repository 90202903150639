class AutoUpdateControl {
  constructor(options) {
    this.options = options
  }

  updateControl() {
    return {
      restrict: 'E',
      require: ['^?form', '?ngModel', '^?patchForm'],
      link: (scope, ele, attrs, controls) => {
        let [form, ngModel, patchForm] = controls
        if (form && patchForm && ngModel) {
          if (!attrs.ngModelOptions && this.options) {
            ngModel.$overrideModelOptions(this.options)
          }
          ngModel.$viewChangeListeners.push(function() {
            patchForm.update()
          })
        }
      }
    }
  }
}

export function updateControlWithDebounce() {
  return new AutoUpdateControl({
      updateOn: 'default blur',
      debounce: {
        'blur': 0,
        'default': 700
      }
    }).updateControl()
}

export function updateControl() {
  return new AutoUpdateControl().updateControl()
}
