import Viewer from 'viewerjs'

class ViewerFactory {
  /*@ngInject*/
  constructor() {
  }

  createViewer(htmlElement, options) {
    return new Viewer(htmlElement, options)
  }
}

export default ViewerFactory
