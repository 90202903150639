import {loanEnquiryStatus} from '../../../../utils/loanEnquiryStatusHelper'
import valueOrDefault from '../../../../utils/valueOrDefault'

class EnquiriesController {
  /*@ngInject*/
  constructor($rootScope, $q, $scope, $mdDialog, $filter, router, entityContextService, localStorageService, loanEnquiriesRepository) {
    this.name = 'enquiries'
    this.loanEnquiriesRepository = loanEnquiriesRepository
    this.entityContextService = entityContextService
    this.router = router
    this.$mdDialog = $mdDialog
    this.$rootScope = $rootScope
    this.$q = $q
    this.$filter = $filter

    this.order = '-id'
    this.limitOptions = [5, 10, 20, 50, 100]
    this.total = 0
    localStorageService.bind($scope, 'vm.limit', 50, 'admin-enquiries.limit')
    localStorageService.bind($scope, 'vm.status', 'all', 'admin-enquiries.status')
    localStorageService.bind($scope, 'vm.contactType', 'all', 'admin-enquiries.contact-type')

    this.selectedEnquiryIds = []

    this.enquiryStatuses = [
      {key: 'all', label: 'All'},
      {key: 'application:applied', label: 'Applied'},
      {key: 'application:closed', label: 'Closed'}
    ]

    this.enquiryContactTypes = [
      {key: 'all', label: 'All'},
      {key: 'admin', label: 'Admin'},
      {key: 'borrower', label: 'Borrower'},
      {key: 'partner', label: 'Partner'},
      {key: 'candidatePartner', label: 'Candidate Partner'}
    ]

    this.reload = () => {
      this.loading = true
      const conditions = {
        status: this.status,
        contactType: this.contactType,
        search: this.search,
        order: this.order,
        size: this.limit,
        page: this.page
      }
      this.loadingPromise = this.loanEnquiriesRepository.where(conditions)
        .then(data => {
          this.total = data.total
          this.loanEnquiries = data.loanEnquiries.map(loanEnquiry => {
            loanEnquiry._stage = this.$filter('asLoanEnquiryStage')(loanEnquiry)
            loanEnquiry._status = this.$filter('asLoanEnquiryStatus')(loanEnquiry)
            return loanEnquiry
          })
        })
        .finally(() => this.loading = false)
    }
    this.$onInit = () => {
      this.performSearch()
    }
  }

  statusIconLabel(loanEnquiry) {
    return valueOrDefault(this._getStatusMapping(loanEnquiry), {label: 'lock'}).label
  }

  statusIconClass(loanEnquiry) {
    return valueOrDefault(this._getStatusMapping(loanEnquiry), {classes: ''}).classes
  }

    performSearch() {
    this.page = 1
    this.selectedEnquiryIds = []
    this.reload()
  }

  selectedForClose() {
    return (this.loanEnquiries || []).findIndex(request => this.selectedEnquiryIds.includes(request.id) &&
      !['closed', 'applied'].includes(loanEnquiryStatus(request))) !== -1
  }

  closeSelected() {
    (this.loanEnquiries || [])
      .filter(enquiry => loanEnquiryStatus(enquiry) !== 'applied' && this.selectedEnquiryIds.includes(enquiry.id))
      .forEach(enquiry => enquiry.performAction('/status/close', {}).then(this._removeProcessedId(enquiry.id)))
  }

  selectedForOpen() {
    return (this.loanEnquiries || []).findIndex(request => this.selectedEnquiryIds.includes(request.id) && loanEnquiryStatus(request) === 'closed') !== -1
  }

  reopenSelected() {
    (this.loanEnquiries || [])
      .filter(enquiry => loanEnquiryStatus(enquiry) === 'closed' && this.selectedEnquiryIds.includes(enquiry.id))
      .forEach(enquiry => enquiry.performAction('/status/reopen', {}).then(this._removeProcessedId(enquiry.id)))
  }

  // private

  _removeProcessedId(id) {
    return () => this.selectedEnquiryIds.splice(this.selectedEnquiryIds.findIndex(element => element === id), 1)
  }

  _getStatusMapping(loanEnquiry) {
    return new Map([
      ['applied', {label: 'directions_walk', classes: 'icon-status-applied'}],
      ['completed', {label: 'check_circle', classes: 'icon-status-completed'}],
      ['closed', {label: 'highlight_off', classes: 'icon-status-closed'}],
      ['in_progress', {label: 'directions_run', classes: 'icon-status-in-progress'}],
    ]).get(loanEnquiryStatus(loanEnquiry))
  }
}

export default EnquiriesController
