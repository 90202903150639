import angular from 'angular'
import patchFormModule from '../../patchForm/patchForm'

import formRepeatSectionComponent from './formRepeatSection.component'

export default angular.module('app.common.forms.formRepeatSection', [patchFormModule.name])
  .directive('formRepeatSection', formRepeatSectionComponent)
  .directive('formRepeatSectionTransclusion', () => {
    return {
      require: ['^form', '^formRepeatSection'],
      controller: () => {
      },
      controllerAs: 'vm',
      scope: {},
      bindToController: {
        formObject: '=',
        index: '=',
      },
      link: (scope, el, attr, ctrls, transclude) => {
        let formRepeatSection = ctrls[1]
        transclude((transcludeEl, transScope) => {
          transScope.$formObject = scope.vm.formObject
          scope.vm.transcludedName = `${formRepeatSection.sectionName}-${scope.vm.index}`
          el.append(transcludeEl)
        })
      }
    }
  })
