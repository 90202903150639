import moment from 'moment'

class CreateLoanFeeController {
  /*@ngInject*/
  constructor(loanFeesRepository) {
    this.name = 'create-loan-fee'
    this.loanFeesRepository = loanFeesRepository
    this.feeTypes = [
      {label: 'Standard', value: 'standard'},
      {label: 'Additional', value: 'additional'},
    ]
    this.standardFeeCategories = [
      {label: 'Drawdown', value: '1001'},
      {label: 'Establishment', value: '1002'},
      {label: 'Valuation', value: '1003'},
      {label: 'Brokerage', value: '1004'},
      {label: 'Other', value: '1000'},
    ]
    this.additionalFeeCategories = [
      {label: 'Late', value: '2001'},
      {label: 'Dishonour', value: '2002'},
      {label: 'Past due notice', value: '2004'},
      {label: 'Mortgage/Caveat', value: '2005'},
      {label: 'Other', value: '2000'},
    ]
    this.today = moment().startOf('day').toDate()

    this.$onInit = () => {
      this.loan.promise('bankDetail').then((details) => {
        this.bankDetails = details
      })
      this.feeDate = this.today
      this.debitDate = this.today
      this.type = 'additional'
    }
  }

  get type() { return this._type }

  set type(type) {
    this._type = type
    delete this.category
  }

  confirmAction() {
    return this._createFee()
      .then((fee) => this.completion.resolve(fee))
      .catch((error) => this.completion.reject(error))
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  get minDebitDate() {
    return this._maxDate(this.today, this.feeDate)
  }

  get shouldCreateDebit() {
    return this?.loan.hasBankDetail && !this?.loan.disableDirectDebit && this.type === 'additional'
  }

  // private

  _maxDate(d1, d2) {
    if (!d1) { return d2 }
    if (!d2) { return d1 }
    return d1 > d2 ? d1 : d2
  }

  _createFee() {
    const newFee = {
      loanId: this.loan.id,
      date: this._toBackendDate(this.feeDate),
      type: this.type,
      category: this.category,
      amount: this.amount,
      comment: this.comment
    }
    if (this.shouldCreateDebit) {
      newFee.debitDate = this._toBackendDate(this.debitDate)
    }
    return this.loanFeesRepository.create(newFee)
  }

  _toBackendDate(date) {
    return moment(date).format('YYYY-MM-DD')
  }
}

export default CreateLoanFeeController
