class LoanEnquiryCreateDynamicController {
  /*@ngInject*/
  constructor($q, router) {
    this.name = 'create-loan-enquiry-dynamic'

    this.completion = $q.defer()
    this.completion.promise.then((loanEnquiry) => router.go('adminLoanEnquiryDetails', {uuid: loanEnquiry.uuid}, {}))
  }
}

export default LoanEnquiryCreateDynamicController
