class ResponseInterceptor {
  /*@ngInject*/
  constructor($injector) {
    this.name = 'responseInterceptor'
    this.$injector = $injector
  }

  static addToHttp($httpProvider) {
    $httpProvider.interceptors.push(
      ResponseInterceptor._createInterceptor()
    )
  }

  responseError(response) {
    const status = response.status === -1 ? 'Cancelled' : (response.status < 0 ? 0 : response.status)
    const handler = `_handleStatus${status}`
    if (this[handler]) {
      return this[handler](response)
    } else {
      // We're not going to handle this.
      throw response
    }
  }

  // private

  static _createInterceptor() {
    return ['$injector', ($injector) => {
      // Avoid circular dependency:
      // $templateRequest <- $$animateQueue <- $animate
      // <- $$interimElement <- $mdToast <- $http <- $templateRequest <- $compile
      const handler = new ResponseInterceptor($injector)
      return {
        responseError: response => {
          handler.responseError(response)
        }
      }
    }]
  }

  _handleStatusCancelled(response) {
    this._showMessage('Your request appears to have been cancelled.')
    return response
  }

  _handleStatus0(response) {
    this._showMessage('Unable to contact the TruePillars website. Check your internet connection.')
    return response
  }

  _handleStatus403(response) {
    this._extractMessages(response).forEach(message => this.userService.appendBlockingMessage(message))
    this.loginRouter.gotoHome()
  }

  _handleStatus418(response) {
    this._showMessage('Security permissions were not checked properly for this request')
    throw response
  }

  _handleStatus498(response) {
    if (!this._handlingExpiry) {
      this._handlingExpiry = true

      this.userService.logout()
        .then(() => {
          this.entityContextService.logout()
          return this.userService.login('Your session has expired')
            .finally(() => {
              this.loginRouter.resumeSession()
              this._handlingExpiry = false
            })
        })
        .finally(() => this._handlingExpiry = false)
    }
    throw response
  }

  _extractMessages(response) {
    return (response.data && response.data.errors && Array.isArray(response.data.errors)) ?
      response.data.errors.map(error => error.message) :
      []
  }

  _extractConcatenatedMessages(response) {
    return this._extractMessages(response).join('. ')
  }

  _handleStatus500(response) {
    const messages = this._extractConcatenatedMessages(response)
    if (messages.length > 0) {
      this._showMessage(messages)
    } else {
      this._showMessage('Something went wrong with your request. Try again later.')
    }
    throw response
  }

  _showMessage(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }

  get $mdToast() {
    return this.inject('$mdToast')
  }

  get $q() {
    return this.inject('$q')
  }

  get userService() {
    return this.inject('userService')
  }

  get entityContextService() {
    return this.inject('entityContextService')
  }

  get $state() {
    return this.inject('$state')
  }

  get loginRouter() {
    return this.inject('loginRouter')
  }

  inject(service) {
    const property = `_${service}_`
    if (!this[property]) {
      this[property] = this.$injector.get(service)
    }
    return this[property]
  }
}

export default ResponseInterceptor
