import {parse} from './processMoustacheExpression.pegjs'

const makeTokenProcessorUsingDSL = (valueMap, formatterProvider) => {
  const parseOptions = {
    model: valueMap,
    formatterProvider: formatterProvider
  }
  return (token, theResult) => {
    const trimmedToken = token.trim()
    try {
      return [true, theResult + parse(trimmedToken, parseOptions)]
    } catch (error) {
      return [false, theResult + '<<<error-token: ' + trimmedToken + '>>>']
    }
  }
}

export default makeTokenProcessorUsingDSL
