
const STAGES = new Map([
  ['application', {displayName: 'apply'}],
  ['assessment', {displayName: 'assess'}],
  ['funding', {displayName: 'fund'}],
])

// Process the stage/status ias a field on a loan enquiry.

const extractStatus = enquiry => (enquiry === undefined || enquiry === null || !enquiry.hasOwnProperty('status') || enquiry.status === undefined) ? null : enquiry.status

const loanEnquiryStage = enquiry => {
  const status = extractStatus(enquiry)
  return status === null || !status.toString().includes(':') ? null : status.replace(/(.*?):.*/, '$1')
}

const loanEnquiryStatus = enquiry => {
  const status = extractStatus(enquiry)
  return status === null ? null : status.replace(/.*?:(.*)/, '$1')
}

const loanEnquiryStageDisposition = (currentStageIndex, index, stage, status=null) => {
  if (currentStageIndex === -1) {
    return {disposition: 'unknown'}
  }
  if (currentStageIndex > index) {
    return {disposition: 'complete'}
  }
  if (currentStageIndex === index) {
    return {disposition: status === 'closed' ? 'closed' : 'stage', stage}
  }
  return {disposition: 'locked'}
}

const loanEnquiryStagesAll = Array.from(STAGES.keys())

const getCurrentStageIndex = (currentStage) => currentStage ? loanEnquiryStagesAll.findIndex(stage => stage === currentStage) : -1


// Process a simple loan enquiry stage/status string representation alone.

const capitalize = value => `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`

const loanEnquiryStageDisplay = (stage) => {
  if (stage === undefined || stage === null) {
    return '-UNKNOWN-'
  } else if (/^\s*$/.exec(stage)) {
    return ''
  } else if (STAGES.has(stage)) {
    return capitalize(STAGES.get(stage).displayName)
  }
  return '-UNKNOWN-'
}

const loanEnquiryStatusDisplay = (status, updated = '') => status !== null && (status === '' || status) ? `${capitalize(status.replace(/_/g, ' '))}${updated}` : '-UNKNOWN-'

const isLoanEnquiryMutable = (loanEnquiry) => loanEnquiry && ['in_progress', 'applied'].includes(loanEnquiryStatus(loanEnquiry)
)
const isLoanEnquiryForTrust = (loanEnquiry) => loanEnquiry && ['in_progress', 'applied'].includes(loanEnquiryStatus(loanEnquiry))

export {
  loanEnquiryStagesAll,
  loanEnquiryStage,
  loanEnquiryStatus,
  loanEnquiryStageDisplay,
  loanEnquiryStatusDisplay,
  loanEnquiryStageDisposition,
  getCurrentStageIndex,
  isLoanEnquiryMutable,
  isLoanEnquiryForTrust
}
