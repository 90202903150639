class CheckConsentController {
  /*@ngInject*/
  constructor(representativesRepository, volatileStore, userService, $scope, $stateParams) {
    this.name = 'check-consent'

    this.isLoading = true
    this.completed = false

    const clearToken = () => {
      delete volatileStore.tpAuthToken
    }

    this.saveConsent = () => {
      return this.owner.save()
        .then((owner) => owner.performAction('clear-token', {}))
        .then(() => {
          clearToken()
          this.completed = true
          this.isLoading = false
        })
    }

    $scope.$on('$destroy', () => {
      clearToken()
    })

    this.$onInit = () => {
      if ($stateParams.token) {
        volatileStore.tpAuthToken = $stateParams.token
        userService.logout()
          .then(() => representativesRepository.getById($stateParams.token))
          .then((representative) => this.owner = representative)
          .finally(() => this.isLoading = false)
      } else {
        this.owner = undefined
        this.isLoading = false
        this.completed = false
      }
    }
  }

  get invitationStatus() {
    return this.owner && this.owner.consentRequestSent && (this.owner.isBorrower || this.owner.isLoanEnquiry) ? 'incomplete' : 'invalid'
  }
}

export default CheckConsentController
