import angular from 'angular'
import 'angular-ui-router'

import borrowersModule from './borrowers/borrowers'
import loanRequestsModule from './loanRequests/loanRequests'
import loansModule from './loans/loans'
import directDebitsModule from './directDebits/directDebits'

import enquiriesModule from './enquiries/enquiries'

import investmentProductsModule from './investmentProducts/investmentProducts'
import investorsModule from './investors/investors'
import investorApplicationsModule from './investorApplications/investorApplications'
import financialsModule from './financials/financials'
import withdrawalsModule from './withdrawals/withdrawals'

import reportsModule from './reports/reports'

import adminDashboardComponent from './adminDashboard.component'

export default angular.module('app.pages.admin.adminDashboard', [
  'ui.router',
  borrowersModule.name,
  loanRequestsModule.name,
  loansModule.name,
  directDebitsModule.name,
  enquiriesModule.name,
  investmentProductsModule.name,
  investorsModule.name,
  investorApplicationsModule.name,
  financialsModule.name,
  withdrawalsModule.name,
  reportsModule.name
])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('adminDashboard', {
        url: '/admin/admin-dashboard',
        template: '<admin-dashboard/>',
        replace: true,
        data: {
          requireAuth: true,
          title: 'Dashboard'
        },
        tabName: 'Dashboard'
      })
      .state('adminDashboard.borrowers', {
        url: '/borrowers',
        template: '<admin-borrowers />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.loans', {
        url: '/loans',
        template: '<admin-loans />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.directDebits', {
        url: '/direct-debits',
        template: '<admin-direct-debits />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.loanRequests', {
        url: '/loan-requests',
        template: '<admin-loan-requests />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.investors', {
        url: '/investors',
        template: '<admin-investors />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.investmentProducts', {
        url: '/investment-products',
        template: '<admin-investment-products />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.investorApplications', {
        url: '/investor-applications',
        template: '<admin-investor-applications />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.financials', {
        url: '/financials',
        template: '<admin-financials />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.withdrawals', {
        url: '/withdrawals',
        template: '<admin-withdrawals />',
        tabName: 'Dashboard',
        redirectTo: 'adminDashboard.withdrawals.verified'
      })
      .state('adminDashboard.enquiries', {
        url: '/enquiries',
        template: '<admin-enquiries />',
        data: {
          requireAuth: true,
        },
        tabName: 'Dashboard'
      })
      .state('adminDashboard.directDebits.repayments', {
        url: '/repayments',
        template: '<repayments-due />',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.directDebits.fees', {
        url: '/fees',
        template: '<fees-due />',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.directDebits.processing', {
        url: '/processing',
        template: '<debits-processing/>',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.directDebits.cleared', {
        url: '/cleared',
        template: '<debits-cleared/>',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.withdrawals.verified', {
        url: '/verified',
        template: '<withdrawals-verified />',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.withdrawals.processing', {
        url: '/processing',
        template: '<withdrawals-processing/>',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.withdrawals.completed', {
        url: '/completed/:search',
        template: '<withdrawals-completed/>',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.withdrawals.declined', {
        url: '/declined',
        template: '<withdrawals-declined/>',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.withdrawals.rejected', {
        url: '/rejected',
        template: '<withdrawals-rejected/>',
        tabName: 'Dashboard',
      })
      .state('adminDashboard.reports', {
        url: '/reports',
        template: '<admin-reports />',
        tabName: 'Dashboard',
        redirectTo: 'adminDashboard.reports.loanStats'
      })
      .state('adminDashboard.reports.loanStats', {
        url: '/loan-stats',
        template: '<admin-loan-stats />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.reports.investorRegistry', {
        url: '/investor-registry',
        template: '<admin-investor-registry />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.reports.journalEntries', {
        url: '/journal-entries',
        template: '<admin-journal-entries />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.reports.registers', {
        url: '/registers',
        template: '<admin-registers />',
        tabName: 'Dashboard'
      })
      .state('adminDashboard.reports.reconciliation', {
        url: '/reconciliation',
        template: '<admin-reconciliation />',
        tabName: 'Dashboard'
      })
  }])
  .config(['$stateProvider', $stateProvider => {
    $stateProvider
      .state('adminLoanEnquiryCreateDynamic', {
        url: '/admin/loan-enquiry-create-dynamic',
        template: '<admin-loan-enquiry-create-dynamic/>',
        data: {
          requireAuth: true,
        },
        tabName: 'Dashboard'
      })
  }])
  .directive('adminDashboard', adminDashboardComponent)
