import {
  container,
  radioButtons,
  ruleRender,
  select,
} from '../../../../../common/dynamicForm/helpers/dynamicFormHelpers'


const editMaturityInstructionFormBuilder = () => {
  const investmentTypes = []
  const buildProductTypeSelect = () => {
    const selectBuilder = select()
      .model('reinvestProductId')
      .prompt('Investment type')
      .required(true)
      .rules(ruleRender('(and (isDefined (fromModel "instruction")) (eq (fromModel "instruction") "rollover"))'))
    investmentTypes.forEach(({label, value}) => {
      selectBuilder.choice(label, value)
    })
    return selectBuilder.build()
  }
  const builder = {
    addInvestmentType: (label, value) => {
      investmentTypes.push({label, value})
      return builder
    },
    build: () => {
      return {
        title: 'Maturity instruction',
        model: 'edit-maturity-instruction',
        actionLabel: 'Submit',
        widgets: [
          container()
            .prompt('Maturity instruction')
            .widgets(
              radioButtons()
                .model('instruction')
                .prompt('Select what should happen when your investment reaches maturity.')
                .button('Reinvest with TruePillars', 'rollover')
                .build(),
              buildProductTypeSelect(),
              radioButtons()
                .model('instruction')
                .button('Redeem', 'redeem')
                .build()
            )
            .build()
        ]
      }
    }
  }
  return builder
}

export default editMaturityInstructionFormBuilder
