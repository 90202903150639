import isDefined from '../../utils/isDefined'

class FeatureTogglesService {

  /*@ngInject*/
  constructor(api, $firebaseObject) {
    const FIREBASE_URL_PATTERN = /^https:\/\/.*\.firebaseio\.com/i

    api.linkFor('featureToggles').then((link) => {
      if (link && link.href && !link.href.match(FIREBASE_URL_PATTERN)) {
        throw new Error('Current Firebase libraries require: https://<YOUR FIREBASE>.firebaseio.com')
      }

      this._featureToggleFirebaseObject = $firebaseObject(new Firebase(link.href))
    })
  }

  can(feature) {
    return this._featureToggleFirebaseObject && this._featureToggleFirebaseObject[feature]
  }

  isResolved(feature) {
    return isDefined(this._featureToggleFirebaseObject) && isDefined(this._featureToggleFirebaseObject[feature])
  }
}

export default FeatureTogglesService

