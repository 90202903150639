import moment from 'moment'

class UpdateInvestmentProductController {
  /*@ngInject*/
  constructor(investmentProductsRepository) {
    this.investmentProductsRepository = investmentProductsRepository
    this.disbursement = false

    this.$onInit = () => {
      this.name = this.investmentProduct.name
      this.brandingId = this.investmentProduct.brandingId
      this.estimatedStartDate = this.investmentProduct.estimatedStartDate
      this.targetRate = this.investmentProduct.targetRate
      this.unitPrice = this.investmentProduct.unitPrice
      this.commitmentPeriod = this.investmentProduct.commitmentPeriod
      this.redemptionNoticePeriod = this.investmentProduct.redemptionNoticePeriod
      this.earlyRedemptionFeeRate = this.investmentProduct.earlyRedemptionFeeRate
      this.maturityRedemptionFeeRate = this.investmentProduct.maturityRedemptionFeeRate
      this.minimumInitialInvestmentAmount = this.investmentProduct.minimumInitialInvestmentAmount
      this.minimumAdditionalInvestmentAmount = this.investmentProduct.minimumAdditionalInvestmentAmount
      this.maturityInstruction = this.investmentProduct.maturityInstruction
      this.maturityRolloverInvestmentProductId = this.investmentProduct.maturityRolloverInvestmentProductId
      this.disbursementDate = moment().startOf('day').toDate()
      this.disbursementFrom = moment(this.investmentProduct.nextDisbursementSummary.from).toDate()
      this.disbursementTo = moment(this.investmentProduct.nextDisbursementSummary.to).toDate()
      this.disbursementAmount = this.investmentProduct.nextDisbursementSummary.amount
      this.investmentProductsRepository.where({scope: 'active'}).then(response => {
        this.activeInvestmentProducts = response.investmentProducts.map((investmentProduct) => ({label: investmentProduct.name, value: investmentProduct.id}))
      })
    }
  }

  confirmAction() {
    return this._update()
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  _update() {
    const payload = {
      name: this.name,
      brandingId: this.brandingId,
      estimatedStartDate: this.estimatedStartDate,
      targetRate: this.targetRate,
      unitPrice: this.unitPrice,
      commitmentPeriod: this.commitmentPeriod,
      redemptionNoticePeriod: this.redemptionNoticePeriod,
      earlyRedemptionFeeRate: this.earlyRedemptionFeeRate,
      maturityRedemptionFeeRate: this.maturityRedemptionFeeRate,
      minimumInitialInvestmentAmount: this.minimumInitialInvestmentAmount,
      minimumAdditionalInvestmentAmount: this.minimumAdditionalInvestmentAmount,
      maturityInstruction: this.maturityInstruction,
      maturityRolloverInvestmentProductId: this.maturityRolloverInvestmentProductId,
      disbursement: this.disbursement,
      disbursementDate: moment(this.disbursementDate).format('YYYY-MM-DD'),
      disbursementFrom: moment(this.disbursementFrom).format('YYYY-MM-DD'),
      disbursementTo: moment(this.disbursementTo).format('YYYY-MM-DD'),
      disbursementAmount: this.disbursementAmount,
    }
    if (this.maturityInstruction === 'redeem') {
      delete payload.maturityRolloverInvestmentProductId
    }
    return this.investmentProduct.performAction('', payload)
      .then((investmentProduct) => this.completion.resolve(investmentProduct))
  }
}

export default UpdateInvestmentProductController
