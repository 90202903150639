class CurrentBidsController {
  /*@ngInject*/
  constructor($scope, $q, dateService) {
    this.name = 'current-bids'
    this.order = ['-active', '-createdAt']

    $scope.$watch(this.investor, (newValue) => {
      if (this.investor) {
        this.loading = this.investor.promise('bids').then(bids => {
          bids ||= []
          this.bids = bids.map(bid => {
            Object.defineProperty(bid, 'type', {
              configurable: true,
              get: function() {
                return this.userId === 'system' ? 'auto' : 'manual'
              }
            })
            Object.defineProperty(bid, 'bidStatus', {
              configurable: true,
              get: function() {
                if (this.activeAmount === 0) {
                  return 'outbid'
                }
                if (this.auction && dateService.isBeforeNow(this.auction.endDate)) {
                  return 'pending'
                }
                if (this.activeAmount === this.amount) {
                  return 'active'
                }
                return 'partially outbid'
              }
            })
            Object.defineProperty(bid, 'active', {
              configurable: true,
              get: function() {
                return this.activeAmount > 0
              }
            })
            return bid
          })
        })
      } else {
        this.loading = $q.defer().promise
      }
    })
  }
}

export default CurrentBidsController
