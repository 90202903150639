import template from './investorTransactions.pug'
import controller from './investorTransactions.controller'
import './investorTransactions.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      investor: '='
    },
    bindToController: true
  }
}
