import template from './inputContainer.pug'
import controller from './inputContainer.controller.js'
import './inputContainer.scss'

export default () => {
  return {
    template: template({}),
    controller,
    controllerAs: 'vm',
    restrict: 'E',
    scope: {
      label: '@',
      alwaysShowErrors: '='
    },
    transclude: {
      extraMessages: '?ngMessage'
    }
  }
}
