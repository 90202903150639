import moment from 'moment'
import Big from 'big.js'
import makeDateRanger from '../../../../utils/dateRanger'

class InvestorReportsController {
  /*@ngInject*/
  constructor($scope,
              entityContextService,
              investmentPortfolioSummariesRepository,
              investorTransactionDetailsRepository,
              investorReportService,
              localStorageService,
              applicationSettingsService,
              loggerService) {
    this.name = 'investor-reports'
    this.investmentPortfolioSummariesRepository = investmentPortfolioSummariesRepository
    this.investorTransactionDetailsRepository = investorTransactionDetailsRepository
    this.investorReportService = investorReportService
    this.applicationSettingsService = applicationSettingsService

    this.logger = loggerService.makeLogger().enableNewRelic(true)

    const REPORT_TYPE_PORTFOLIO_SUMMARY = 'PORTFOLIO_SUMMARY'
    const REPORT_TYPE_TAX = 'TAX'
    const REPORT_TYPE_TRANSACTION_DETAILS = 'TRANSACTIONS_DETAILS'

    const REPORT_TYPE_DEFAULT = REPORT_TYPE_PORTFOLIO_SUMMARY

    this._reportTypes = [
      {key: REPORT_TYPE_TRANSACTION_DETAILS, label: 'Transactions details', order: 100},
      {key: REPORT_TYPE_PORTFOLIO_SUMMARY, label: 'Portfolio summary', order: 1000},
    ]
    this.reportPeriods = [
      {key: 'CURRENT_MONTH', label: 'Current month'},
      {key: 'LAST_MONTH', label: 'Last month'},
      {key: 'CURRENT_FINANCIAL_YEAR', label: 'Current financial year'},
      {key: 'LAST_FINANCIAL_YEAR', label: 'Last financial year'},
      {key: 'CUSTOM', label: 'Custom'}
    ]

    this.limitOptions = [5, 10, 20, 50, 100]
    this.page = 1
    this.total = 0
    localStorageService.bind($scope, 'vm.limit', 50, 'investor-reports.limit')

    this.reportDateRanger = makeDateRanger(this.logger)

    this.$onInit = () => {
      this.loading = true
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        this.transactionDetailsConditions = {scope: 'for_investor', id: investor.id}
        const today = moment()
        const lastFinancialYear = this._calculateLastFinancialYear(today)
        if (this._investorRegisteredBefore(lastFinancialYear)) {
          this._updateShowReportList('add', {key: REPORT_TYPE_TAX, label: 'Annual tax statements', order: 1})
        }
        this.reportPeriod = 'CURRENT_MONTH'
        localStorageService.bind($scope, 'vm.reportType', REPORT_TYPE_DEFAULT, 'investor-reports.report-type')
        if (!this._reportTypeExistsForKey(this.reportType)) {
          this.reportType = REPORT_TYPE_DEFAULT
        }
        this.loading = false
      })
    }
  }

  get reportTypes() {
    return this._reportTypes.sort((a, b) => a.order - b.order)
  }

  get reportType() {
    return this._reportType
  }

  set reportType(reportType) {
    if (this._reportTypeExistsForKey(reportType)) {
      if (reportType === 'PORTFOLIO_SUMMARY') {
        this.refreshPortfolioSummary()
      } else if (reportType === 'TRANSACTIONS_DETAILS') {
        this.refreshTransactionDetails()
      }
      this._reportType = reportType
    }
  }

  get reportPeriod() {
    return this._reportPeriod
  }

  set reportPeriod(reportPeriod) {
    this._reportPeriod = reportPeriod
    if (this.reportType === 'PORTFOLIO_SUMMARY') {
      this.refreshPortfolioSummary()
    } else if (this.reportType === 'TRANSACTIONS_DETAILS') {
      this.refreshTransactionDetails()
    }
  }

  enableTaxSummaryReportForFinancialYear(year) {
    this.reportDateRanger.defineDateRanges(this.applicationSettingsService.setting('enableAMITReportsFor'))
    const includeYear = this.reportDateRanger.includeYear(year)
    return includeYear && this._investorRegisteredBefore(`${year}-07-01`)
  }

  downloadTaxSummaryReport(year) {
    this.loading = true
    const fromDate = `${year - 1}-07-01`
    const toDate = `${year}-06-30`
    this.investmentPortfolioSummariesRepository.where({id: this.investor.id, fromDate: this._formatDate(fromDate), toDate: this._formatDate(toDate)}).then((ie) => {
      this.investorReportService.createAndDownloadTaxationSummaryReport(this.investor, fromDate, toDate, ie)
    }).finally(() => {
      this.loading = false
    })
  }

  refreshPortfolioSummary() {
    this.loading = true
    this._calculateReportFromAndToDate()
    delete this.investmentPortfolioSummary
    const query = {id: this.investor.id, fromDate: this._formatDate(this.reportPeriodFromDate), toDate: this._formatDate(this.reportPeriodToDate)}
    this.investmentPortfolioSummariesRepository.where(query).then((investmentPortfolioSummary) => {
      this.investmentPortfolioSummary = investmentPortfolioSummary
      this.investmentPortfolioSummaryFromDate = this.reportPeriodFromDate
      this.investmentPortfolioSummaryToDate = this.reportPeriodToDate
      this.investmentPortfolioSummary.total = {}
      this.investmentPortfolioSummary.total.openingFunds =
        new Big(this.investmentPortfolioSummary.cash.openingFunds).add(this.investmentPortfolioSummary.p2p.openingFunds).add(this.investmentPortfolioSummary.ip.openingFunds)
      this.investmentPortfolioSummary.total.deposit = new Big(this.investmentPortfolioSummary.cash.deposit)
      this.investmentPortfolioSummary.total.withdrawal = new Big(this.investmentPortfolioSummary.cash.withdrawal)
      this.investmentPortfolioSummary.total.capitalInvested = new Big(this.investmentPortfolioSummary.p2p.capitalInvested).add(this.investmentPortfolioSummary.ip.capitalInvested)
      this.investmentPortfolioSummary.total.capitalReturned = new Big(this.investmentPortfolioSummary.p2p.capitalReturned).add(this.investmentPortfolioSummary.ip.capitalReturned)
      this.investmentPortfolioSummary.total.capitalLost = new Big(this.investmentPortfolioSummary.p2p.capitalLost)
      this.investmentPortfolioSummary.total.interestReceived = new Big(this.investmentPortfolioSummary.p2p.interestReceived).add(this.investmentPortfolioSummary.ip.interestReceived)
      this.investmentPortfolioSummary.total.taxWithheld = new Big(this.investmentPortfolioSummary.p2p.taxWithheld).add(this.investmentPortfolioSummary.ip.taxWithheld)
      this.investmentPortfolioSummary.total.feesPaid = new Big(this.investmentPortfolioSummary.p2p.feesPaid).plus(this.investmentPortfolioSummary.ip.feesPaid)
      this.investmentPortfolioSummary.total.closingFunds =
        new Big(this.investmentPortfolioSummary.cash.closingFunds).add(this.investmentPortfolioSummary.p2p.closingFunds).add(this.investmentPortfolioSummary.ip.closingFunds)
    }).finally(() => {
      this.loading = false
    })
  }

  downloadPortfolioSummaryReport() {
    this.investorReportService.createAndDownloadPortfolioSummaryReport(this.investor, this.investmentPortfolioSummaryFromDate, this.investmentPortfolioSummaryToDate, this.investmentPortfolioSummary)
  }

  refreshTransactionDetails() {
    this.loading = true
    this.page = 1
    this._calculateReportFromAndToDate()
    delete this.investorTransactionDetails
    const query = {id: this.investor.id, fromDate: this._formatDate(this.reportPeriodFromDate), toDate: this._formatDate(this.reportPeriodToDate), size: this.limit, page: this.page}
    this.loadingPromise = this.investorTransactionDetailsRepository.where(query).then((data) => {
      this.total = data.total
      this.investorTransactionDetails = data.investorTransactionDetails
      this.investorTransactionDetailFromDate = this.reportPeriodFromDate
      this.investorTransactionDetailToDate = this.reportPeriodToDate
      this.paginateTransactionDetails = () => {
        const query = {
          id: this.investor.id,
          fromDate: this._formatDate(this.investorTransactionDetailFromDate),
          toDate: this._formatDate(this.investorTransactionDetailToDate),
          size: this.limit,
          page: this.page
        }
        this.loadingPromise = this.investorTransactionDetailsRepository.where(query).then((data) => {
          this.total = data.total
          this.investorTransactionDetails = data.investorTransactionDetails
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  downloadTransactionDetailsReport() {
    if (!this.prepareTransactionDetailsReport) {
      this.prepareTransactionDetailsReport = true
      const query = {id: this.investor.id, fromDate: this._formatDate(this.reportPeriodFromDate), toDate: this._formatDate(this.reportPeriodToDate)}
      this._loadTransactionDetailsAndCreatePdf([], query, 5000, 1)
    }
  }

  _loadTransactionDetailsAndCreatePdf(investorTransactionDetails, query, size, page) {
    this.investorTransactionDetailsRepository.where(Object.assign(JSON.parse(JSON.stringify(query)), {size: size, page: page})).then((data) => {
      investorTransactionDetails = investorTransactionDetails.concat(data.investorTransactionDetails)
      if (data.investorTransactionDetails.length > 0 && investorTransactionDetails.length !== data.total) {
        this._loadTransactionDetailsAndCreatePdf(investorTransactionDetails, query, size, page + 1)
      } else {
        this.prepareTransactionDetailsReport = false
        this.investorReportService.createAndDownloadTransactionDetailsReport(this.investor, query.fromDate, query.toDate, investorTransactionDetails)
      }
    })
  }

  downloadTransactionDetailsCsv() {
    if (!this.prepareTransactionDetailsCsv) {
      this.prepareTransactionDetailsCsv = true
      const query = {id: this.investor.id, fromDate: this._formatDate(this.reportPeriodFromDate), toDate: this._formatDate(this.reportPeriodToDate)}
      this._loadTransactionDetailsAndCreateCsv([], query, 5000, 1)
    }
  }

  _loadTransactionDetailsAndCreateCsv(investorTransactionDetails, query, size, page) {
    this.investorTransactionDetailsRepository.where(Object.assign(JSON.parse(JSON.stringify(query)), {size: size, page: page})).then((data) => {
      investorTransactionDetails = investorTransactionDetails.concat(data.investorTransactionDetails)
      if (data.investorTransactionDetails.length > 0 && investorTransactionDetails.length !== data.total) {
        this._loadTransactionDetailsAndCreateCsv(investorTransactionDetails, query, size, page + 1)
      } else {
        this.prepareTransactionDetailsCsv = false
        this.investorReportService.createAndDownloadTransactionDetailsCsv(query.fromDate, query.toDate, investorTransactionDetails)
      }
    })
  }

  // private

  _calculateLastFinancialYear(today) {
    const lastFinancialYear = today.quarter() > 2 ? today.month('June').endOf('month').format('YYYY-MM-DD') : today.year(today.year() - 1).month('June').endOf('month').format('YYYY-MM-DD')
    return lastFinancialYear
  }

  _investorRegisteredBefore(date) {
    return this.investor && moment(this.investor.createdAt).isBefore(moment(date))
  }

  _reportTypeExistsForKey(key) {
    return this._reportTypes.findIndex(t => t.key === key) >= 0
  }

  _updateShowReportList(addOrRemove, reportDetails) {
    const exists = this._reportTypeExistsForKey(reportDetails.key)
    if (addOrRemove === 'add' && !exists) {
      this.reportTypes.push(reportDetails)
    } else if (addOrRemove === 'remove' && exists) {
      this.reportTypes.splice(index, 1)
    }
  }

  _calculateReportFromAndToDate() {
    if (this.reportPeriod === 'CURRENT_MONTH') {
      this.reportPeriodFromDate = moment().startOf('day').startOf('month').toDate()
      this.reportPeriodToDate = moment().startOf('day').endOf('month').startOf('day').toDate()
    } else if (this.reportPeriod === 'LAST_MONTH') {
      this.reportPeriodFromDate = moment().startOf('day').startOf('month').subtract(1, 'months').toDate()
      this.reportPeriodToDate = moment().startOf('day').startOf('month').subtract(1, 'days').toDate()
    } else if (this.reportPeriod === 'CURRENT_FINANCIAL_YEAR') {
      if (moment().startOf('day').quarter() >= 3) {
        this.reportPeriodFromDate = moment().startOf('day').month(6).startOf('month').toDate()
        this.reportPeriodToDate = moment().startOf('day').month(6).startOf('month').add(1, 'year').subtract(1, 'days').toDate()
      } else {
        this.reportPeriodFromDate = moment().startOf('day').subtract(1, 'year').month(6).startOf('month').toDate()
        this.reportPeriodToDate = moment().startOf('day').month(6).startOf('month').subtract(1, 'days').toDate()
      }
    } else if (this.reportPeriod === 'LAST_FINANCIAL_YEAR') {
      if (moment().startOf('day').quarter() >= 3) {
        this.reportPeriodFromDate = moment().startOf('day').month(6).startOf('month').subtract(1, 'year').toDate()
        this.reportPeriodToDate = moment().startOf('day').month(6).startOf('month').subtract(1, 'days').toDate()
      } else {
        this.reportPeriodFromDate = moment().startOf('day').subtract(2, 'year').month(6).startOf('month').toDate()
        this.reportPeriodToDate = moment().startOf('day').subtract(1, 'year').month(6).startOf('month').subtract(1, 'days').toDate()
      }
    }
  }

  _formatDate(fromDate, format) {
    return moment(fromDate).locale('en').format(format || 'YYYY-MM-DD')
  }
}

export default InvestorReportsController
