import angular from 'angular'

import createInvestmentProductPledgeRequestModule from './createInvestmentProductPledgeRequest/createInvestmentProductPledgeRequest'
import displayInvestmentProductPartDetailsModule from './displayInvestmentProductPartDetails/displayInvestmentProductPartDetails'
import editInvestmentProductPartAutoReinvestModule from './editInvestmentProductPartAutoReinvest/editInvestmentProductPartAutoReinvest'
import editInvestmentProductPartMaturityInstructionModule from './editInvestmentProductPartMaturityInstruction/editInvestmentProductPartMaturityInstruction'
import managePledgeRequestsModule from './managePledgeRequests/managePledgeRequests'
import manageRedemptionRequestsModule from './manageRedemptionRequests/manageRedemptionRequests'
import investmentProductPartsComponent from './investmentProductParts.component'

export default angular.module('app.pages.investors.investorDashboard.investmentProductParts', [
  createInvestmentProductPledgeRequestModule.name,
  displayInvestmentProductPartDetailsModule.name,
  editInvestmentProductPartAutoReinvestModule.name,
  editInvestmentProductPartMaturityInstructionModule.name,
  managePledgeRequestsModule.name,
  manageRedemptionRequestsModule.name
]).directive('investmentProductParts', investmentProductPartsComponent)
