import cashFlowAdminEnquiryForm from './enquiryForms/cashFlow/admin'
import cashFlowBrokerEnquiryForm from './enquiryForms/cashFlow/broker'
import equipmentFinanceAdminEnquiryForm from './enquiryForms/equipmentFinance/admin'
import equipmentFinanceBrokerEnquiryForm from './enquiryForms/equipmentFinance/broker'
import lineOfCreditAdminEnquiryForm from './enquiryForms/lineOfCredit/admin'
import trailBookAdminEnquiryForm from './enquiryForms/trailBook/admin'
import trailBookBrokerEnquiryForm from './enquiryForms/trailBook/broker'
import toolsFinanceAdminEnquiryForm from './enquiryForms/toolsFinance/admin'
import rideshareAdminEnquiryForm from './enquiryForms/rideshare/admin'
import rideshareBrokerEnquiryForm from './enquiryForms/rideshare/broker'

import {createPayload, createPayloadReferrerDetails} from '../../utils/loanEnquiryDetailHelper'
import createEnum from '../../utils/createEnum'
import valueOrDefault from '../../utils/valueOrDefault'
import resolveProperty from '../../utils/resolveProperty'
import angular from 'angular'


const FORM_STATUS = createEnum('SHOW', 'SUBMITTED', 'PENDING', 'ERROR')
const FORM_DEFINITIONS = {
  'business-loan-admin': cashFlowAdminEnquiryForm,
  'business-loan-broker': cashFlowBrokerEnquiryForm,
  'equipment-finance-admin': equipmentFinanceAdminEnquiryForm,
  'equipment-finance-broker': equipmentFinanceBrokerEnquiryForm,
  'line-of-credit-admin': lineOfCreditAdminEnquiryForm,
  'trail-book-admin': trailBookAdminEnquiryForm,
  'trail-book-broker': trailBookBrokerEnquiryForm,
  'ride-share-admin': rideshareAdminEnquiryForm,
  'ride-share-broker': rideshareBrokerEnquiryForm,
  'tools-finance-admin': toolsFinanceAdminEnquiryForm
}

class EmbeddedEnquiryFormController {
  /*@ngInject*/
  constructor($q, $scope, $timeout, $document, $state, loanEnquiriesRepository, router, contentful, loggerService, userService) {
    this.name = 'embeddedEnquiryForm'
    const logger = loggerService.makeLogger()
    this.formStatus = FORM_STATUS.SHOW

    this.$onInit = () => {
      // set up defaults
      const model = {
        enquiryMode: 'scenario',
        preferredCommission: userService.isCreditAssessor() ? 0 : 3,
      }
      $scope.$on('form-model', function(event, ...args) {
        switch (valueOrDefault(resolveProperty(args, 0, 'actionIntention'), '-missing-')) {
          case 'scenario':
            model.enquiryMode = 'scenario'
            break
          case 'application':
            model.enquiryMode = 'application'
            break
          default:
            logger.error(`Unknown enquiryMode: ${JSON.stringify(args)}`)
            break
        }
      })
      this.clickTrackingEventName = valueOrDefault(this.clickTracking, '')

      contentful.entries(`content_type=container&fields.name=${this.nameResponse}&include=10`).then((entry) => {
        this.responseComponent = entry.data && entry.data.items.length && entry.data.items[0]
      }).catch((error) => {
        logger.log(`Didn't find response panel with id = '${this.nameResponse}'.`, error)
      })

      const embeddedEnquiryFormDefinition = FORM_DEFINITIONS[this.nameForm]
      this.theForm = {
        definition: embeddedEnquiryFormDefinition,
        model,
        showSubmit: true,
        saveAction: () => $q.defer().promise,
        cancelAction: () => $state.reload(),
        submitAction: (model, compiledWidgets) => {
          const payloadEnquiryDetails = createPayload(model, compiledWidgets)
          const payloadReferrerDetails = createPayloadReferrerDetails(model)
          const contactType = model['contactType']
          const [payload, email] = Object.getOwnPropertyNames(payloadReferrerDetails).length > 0 ?
            [{enquiryDetails: payloadEnquiryDetails, referrerDetails: payloadReferrerDetails}, model['referrerEmail']] :
            [{enquiryDetails: payloadEnquiryDetails}, model['borrowerEmail']]
          const body = {
            contactType: contactType,
            email: email,
            definition: embeddedEnquiryFormDefinition,
            payload: payload
          }
          return loanEnquiriesRepository.create(body)
            .then((loanEnquiry) => {
              const content = angular.element($document.find('#main-content'))
              const target = angular.element($document.find(this.baseElement))
              $timeout(() => target && target[0] && content.scrollToElementAnimated(target, 0, 1000), 0)
              if (this.completion) {
                this.formStatus = FORM_STATUS.PENDING
                this.completion.resolve(loanEnquiry)
              } else {
                this.formStatus = FORM_STATUS.SUBMITTED
              }
            })
            .catch((error) => {
              logger.error(error)
              if (this.completion) {
                this.completion.reject(error)
              }
            })
        },
        actionLabel: 'Submit enquiry'
      }
    }
  }
}

export default EmbeddedEnquiryFormController
