import valueOrDefault from './valueOrDefault'

const nameSplitter = (name) => {
  const names = {
    firstName: '',
    middleName: '',
    lastName: ''
  }
  const nameParts = valueOrDefault(name, '').trim().split(/\s+/)
  if (nameParts.length === 1) {
    names.lastName = nameParts[0]
  } else if (nameParts.length === 2) {
    names.firstName = nameParts[0]
    names.lastName = nameParts[1]
  } else if (nameParts.length > 2) {
    names.firstName = nameParts[0]
    names.middleName = nameParts.slice(1, nameParts.length - 1).join(' ')
    names.lastName = nameParts[nameParts.length - 1]
  }
  return names
}

export default nameSplitter
