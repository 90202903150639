import InvestorStatusHelper from '../../../../utils/investorStatusHelper'

class AuctionPaneController {
  /*@ngInject*/
  constructor(userService, dateService, entityContextService, emailVerificationService, $mdDialog) {
    this.name = 'auction-pane'
    this.userService = userService
    this.dateService = dateService
    this.entityContextService = entityContextService
    this.emailVerificationService = emailVerificationService
    this.$mdDialog = $mdDialog
    this.hideWidget = this.hideBidWidget.bind(this)
    this.user = userService.currentUser

    this._bidStatusMessage = ' '

    this.loading = true

    this.$onInit = () => {
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
      })
        .catch(angular.noop) /* Do nothing - prolly not logged in as an INVESTOR. */
        .finally(() => {
        this.loading = false
      })
    }
  }

  resendVerificationEmailDialog($event) {
    const confirm = this.$mdDialog.confirm()
      .title('You must verify your email address to be able to bid')
      .htmlContent(
        '<p>When you registered as an investor, an email verification request was sent to: <b>' + this.user.email + '</b></p>' +
        '<p>If you would like us to send you another verification email, please click below.</p>'
      )
      .clickOutsideToClose(true)
      .targetEvent($event)
      .ok('Re-send email')
      .cancel('Cancel')

    this.$mdDialog.show(confirm).then(() => {
      this.emailVerificationService.resendVerificationEmail()
    })
  }

  showBidWidget($event) {
    if (this.userService.isLoggedIn()) {
      this.displayBidWidget = true
    } else {
      this.userService.login().then(() => {
        this.displayBidWidget = true
      })
    }
  }

  hideBidWidget() {
    this.displayBidWidget = false
  }

  get addFundsRef() {
    return (this.investor && this.investor.moneyAdded) ? 'investorDashboard.manageFunds' : 'investorQualifying'
  }

  get bidStatusMessage() {
    if (!this.isStarted) {
      this._bidStatusMessage = 'Auction yet to start'
    } else if (this.isEnded || (this.auction.entryRate - this.auction.minRate) < 0) {
      this._bidStatusMessage = 'Bidding closed'
    } else if (this.loading) {
      this._bidStatusMessage = ' '
    } else {
      this._bidStatusMessage = new InvestorStatusHelper(this.userService, this.entityContextService).bidStatusMessage(this.auction.bidAmountMin)
    }
    return this._bidStatusMessage
  }

  get isRunning() {
    return this.auction && this.isStarted && !this.isEnded
  }

  get isStarted() {
    return this.auction && this.dateService.isBeforeNow(this.auction.startDate)
  }

  get isEnded() {
    return this.auction && this.dateService.isBeforeNow(this.auction.endDate)
  }

  get percentageFunded() {
    return this.auction ?
      (this.auction.totalBidAmount < this.auction.amount ? (Math.floor(this.auction.totalBidAmount * 1000 / this.auction.amount) / 10) : 100) :
      0
  }

  // private

  _helpmateOrWebkitFab(target) {
    return target.className && target.className.indexOf && target.className.indexOf('icon') >= 0
  }

  _firefoxOrIeFab(target) {
    return target.className && target.className.indexOf && target.className.indexOf('md-fab') >= 0
  }
}

export default AuctionPaneController
