import UserIdentity from './userIdentity.model'

class UserIdentityService {
  /*@ngInject*/
  constructor(userService) {
    this.userService = userService
  }

  augment(user) {
    return new UserIdentity(user)
  }

  createIdentity() {
    return this.userService.signupUser().then((user) => this.augment(user))
  }

  getCurrentIdentity() {
    return this.userService.currentUserPromise.then((currentUser) => this.augment(currentUser))
  }
}

export default UserIdentityService
