class AuctionDetailsController {
  /*@ngInject*/
  constructor($rootScope, $q, $mdDialog, dateService) {
    this.name = 'auction-details'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.dateService = dateService
  }

  get auction() {
    return this.loanRequest && this.loanRequest.auction
  }

  get uniqueInvestors() {
    return this.bids && this.bids.filter((bid) => bid.activeAmount > 0).map((bid) => bid.investorId).reduce((x, y) => x.includes(y) ? x : [...x, y], []).length
  }

  showScheduleAuctionDialog($event) {
    let scope = this.$rootScope.$new()
    scope.loanRequest = this.loanRequest
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.loanRequest.reload())
      .finally((result) => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-schedule-auction loan-request='loanRequest' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  reconcileAuction() {
    return this.auction.performAction('reconcile', {})
  }

  showOrReloadBids() {
    this.bidOrder = ['minRate * 1.0', 'id']
    this.loadingBids = true
    this.auction.clearResource('bids')
    this.auction.promise('bids').then(bids => {
      this.bids = bids
      this.loadingBids = false
    })
  }

  showUpdateAuctionDialog($event) {
    let scope = this.$rootScope.$new()
    scope.auction = this.auction
    scope.completion = this.$q.defer()
    scope.completion.promise
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-update-auction auction='auction' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  showCancelLoanRequestDialog($event) {
    let scope = this.$rootScope.$new()
    scope.loanRequest = this.loanRequest
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.loanRequest.reload())
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-cancel-loan-request loan-request='loanRequest' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  showCreateLoanDialog($event) {
    let scope = this.$rootScope.$new()
    scope.loanRequest = this.loanRequest
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.loanRequest.reload())
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      template: `
        <md-dialog flex="50">
          <md-dialog-content layout-padding>
            <admin-create-loan loan-request='loanRequest' completion='completion'/>
          </md-dialog-content>
        </md-dialog>`,
      scope
    })
  }

  businessProfileCompleted() {
    return this.loanRequest.businessProfile && this.loanRequest.businessProfile.completed === true
  }

  auctionFundingSource() {
    return this.loanRequest.fundingSource === 'auction'
  }

  loanRequestApproved() {
    return this.loanRequest.status === 'approved'
  }

  loanRequestScheduled() {
    return this.loanRequest.status === 'scheduled'
  }

  loanRequestComplete() {
    return this.loanRequest.status === 'fulfilled'
  }

  auctionComplete() {
    return this.auction && this.dateService.isBeforeNow(this.auction.endDate)
  }

  auctionFulfilled() {
    return this.auction.totalBidAmount >= this.auction.amount
  }

  canScheduleAuction() {
    return this.loanRequestApproved() && this.businessProfileCompleted()
  }

  canCancelAuction() {
    return this.loanRequestScheduled() && this.auctionComplete()
  }

  canCreateLoan() {
    return this.loanRequestScheduled() && this.auctionComplete() && this.auctionFulfilled()
  }
}

export default AuctionDetailsController
