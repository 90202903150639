import moment from 'moment'
import createAsyncCounter from '../../utils/asyncCounts'


class RegistrationFormController {
  /*@ngInject*/
  constructor(greenidService, representativesRepository, userIdentityService, biometricIdentitiesService,
              loggerService, formErrors, $mdToast, $scope, $state, $document, $timeout, $mdDialog,
              confirmationDialogService) {
    this.name = 'registrationForm'
    this.$mdToast = $mdToast
    this.greenidService = greenidService
    this.representativesRepository = representativesRepository
    this.userIdentityService = userIdentityService
    this.biometricIdentitiesService = biometricIdentitiesService
    this.formErrors = formErrors
    this.$document = $document
    this.$mdDialog = $mdDialog
    this.confirmationDialogService = confirmationDialogService

    this.logger = loggerService.makeLogger().enableConsole(true).enableNewRelic(false)
    this.asyncCounter = createAsyncCounter()
    this.honorifics = ['Miss', 'Mr.', 'Mrs.', 'Ms.', 'Mx.']

    $scope.$watch('vm.recommendAssistance', (newValue) => {
      if (newValue) {
        $timeout(() => {
          const element = angular.element(this.$document.find('#scroll-to-point'))
          const content = angular.element(this.$document.find('#main-content'))
          if (element && element[0]) content.scrollToElementAnimated(element, 0, 1000)
        }, 0)
      }
    })

    if (typeof $state.$current.data === 'undefined') {
      $state.$current.data = {}
    }
    $state.$current.data.entityName = ''
  }

  get logoClass() {
    return this.loanOrigination ? `logo-${this.loanOrigination}`: 'logo-truepillars'
  }

  get bioCheckRequired() {
    return this.loanPurpose !== 'toolsFinance'
  }

  set userIdentityPromise(userIdentityPromise) {
    this._userIdentityPromise = userIdentityPromise
    this._resolveUserIdentity()
  }

  get userIdentityPromise() {
    return this._userIdentityPromise
  }

  set registrationForm(value) {
    this._registrationForm = value
    this.triggerMessages()
  }

  get registrationForm() {
    return this._registrationForm
  }

  get validateOnEntry() {
    return this._validateOnEntry
  }

  set validateOnEntry(value) {
    this._validateOnEntry = value
    this.triggerMessages()
  }

  get recommendAssistance() {
    return this.greenidService && this.greenidService.recommendAssistance
  }

  triggerMessages() {
    if (this.registrationForm && this.validateOnEntry) {
      this.formErrors.triggerMessages(this.registrationForm)
    }
  }

  canBeEdited() {
    return this.userIdentity &&
      (!this.userIdentity.identityCheckProgressed || !this.userIdentity.identityCheckProgressed()) &&
      !this.asyncCounter.isLoading
  }

  get loading() {
    return this.asyncCounter.isWaiting
  }

  saveAndBeginIdCheck() {
    if (this.bioCheckRequired) {
      return this._confirmSelfieCheck().then(() => this._saveAndBeginIdCheck())
    } else {
      return this._saveAndBeginIdCheck()
    }
  }

  get formObject() {
    return this.userIdentity && this.userIdentity.resource
  }

  isIdentityCheckUnderReview() {
    return this.userIdentity && this.userIdentity.identityCheckUnderReview()
  }

  isBiometricIdentityCheckUnderReview() {
    return this.userIdentity && this.userIdentity.biometricIdentityCheckUnderReview()
  }

  isUnderReview() {
    return this.isIdentityCheckUnderReview() || this.isBiometricIdentityCheckUnderReview()
  }

  proceed() {
    if (this.isUnderReview() && this.completion) {
      this.completion.resolve(this.userIdentity)
    }
  }

  denied() {
    return this.userIdentity && this.userIdentity.identityCheckRejected()
  }

  get isPep() {
    return (this.formObject && this.formObject.consent && this.formObject.consent.isPep) || false
  }

  set isPep(value) {
    if (this.formObject) {
      if (!this.formObject.consent) {
        this.formObject.consent = {}
      }
      this.formObject.consent.isPep = value
    }
  }

  get eighteenYearsAgo() {
    if (!this._eighteenYearsAgo) {
      this._eighteenYearsAgo = moment(new Date()).subtract(18, 'years').toDate()
    }
    return this._eighteenYearsAgo
  }

  get address() {
    if (this.userIdentity && this.formObject.canonicalAddress && this.formObject.canonicalAddress.fullAddress) {
      return this.formObject.canonicalAddress
    }
    return null
  }

  set address(value) {
    if (this.userIdentity) {
      this.formObject.canonicalAddress = value
    }
  }

  // private

  _confirmSelfieCheck() {
    return this.confirmationDialogService.simpleConfig('investor-signup-selfie').then((config) => {
      return this.$mdDialog.show(config.ok('Got it'))
    })
  }

  _saveAndBeginIdCheck() {
    this.asyncCounter.start('save')

    if (this.userIdentity.resource._authToken) {
      return this.representativesRepository.create({authToken: this.userIdentity.resource._authToken})
        .then((representative) => representative.promise('user'))
        .then((user) => this.userIdentity = this.userIdentityService.augment(this._copyProperties(this.formObject, user)))
        .then(() => this.userIdentity.save())
        .then((userIdentity) => this._startIdCheck(userIdentity))
    } else {
      return this.userIdentity.save().then((userIdentity) => this._startIdCheck(userIdentity))
    }
  }

  _resolveUserIdentity() {
    if (this.userIdentityPromise) {
      const counterToken = 'userIdentity'
      this.asyncCounter.start(counterToken)
      this.userIdentityPromise.then((userIdentity) => {
        this.userIdentity = userIdentity
        this.promptForEmail = !this.userIdentity.resource || !this.userIdentity.resource.email || this.forceEmailEntry
        if (this.promptForEmail) {
          this.userIdentity.resource.email = undefined
        }
        this.promptForDetails = (this.formObject && this.formObject.email && !this.forceEmailEntry) || this.forceEmailEntry
        if (this.userIdentity.hasOwnProperty('identityCheckInProgress') && this.userIdentity.identityCheckInProgress()) {
          this._startIdCheck(userIdentity)
        }
        if (this.bioCheckRequired && !this.userIdentity.hasOwnProperty('biometricIdentityCheckAttempted')) {
          this._createBiometricIdCheck(userIdentity)
        }
      }).finally(() => this.asyncCounter.end(counterToken))
    }
  }

  _startIdCheck(userIdentity) {
    if (this.verificationStarted) {
      this.verificationStarted.resolve(userIdentity)
    }
    return this._createGreenIdCheck(userIdentity)
  }

  _createGreenIdCheck(userIdentity) {
    const getGreenidToken = 'getGreenIdToken'
    this.asyncCounter.start(getGreenidToken)
    return this.greenidService.tokenForUser(userIdentity).then((idCheck) => {
      const greenidShowToken = 'greenidShow'
      this.asyncCounter.start(greenidShowToken)
      this.checkingIdentity = true
      return this.greenidService.show('greenid', idCheck).then((idCheckResult) => {
        userIdentity.identityCheck = idCheckResult
        if (idCheckResult.status === 'verified' || idCheckResult.status === 'pending') {
          if (this.completion) {
            this.completion.resolve(userIdentity)
          }
        } else {
          this.identityNotVerified = true
        }
      }).finally(() => this.asyncCounter.end(greenidShowToken))
    }).catch((error) => {
      this.checkingIdentity = false
      this._greenIdError(error)
    }).finally(() => {
      this.checkingIdentity = false
      this.asyncCounter.end(getGreenidToken)
    })
  }

  _createBiometricIdCheck(userIdentity) {
    this.asyncCounter.start('biometrics')
    return this.biometricIdentitiesService.tokenForUser(userIdentity, 'Sign up, via registration form.').then((biometricIdCheck) => {
      // TODO : Do something with the token?
    }).catch((error) => {
      const message = 'Error checking biometric identity requirements. Please try again later.'
      this._toast(message)
      this.logger.error(message, error)
    }).finally(() => {
      this.asyncCounter.end('biometrics')
    })
  }

  _greenIdError(error) {
    this._toast('Error checking identity requirements. Please try again later.')
  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }

  _copyProperties(from, to) {
    for (let key in from) {
      if (!key.startsWith('_') && from.hasOwnProperty(key)) to[key] = from[key]
    }
    return to
  }
}

export default RegistrationFormController
