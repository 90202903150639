class FinancialSummaryDisplayController {
  /*@ngInject*/
  constructor(entityContextService) {
    this.name = 'financial-summary-display'
    this.entityContextService = entityContextService

    this.loading = false
    this.profitLoss = {}
    this.balanceSheet = {}
    this.debt = {}
  }

  get isFinancial() {
    return this.entityContextService.isFinancial()
  }

  get financialSummary() {
    if (this.loanRequest) {
      if (!(this._financialSummary || this.loading)) {
        this._load()
      }
    }
    return this._financialSummary
  }

  // private

  _load() {
    this.loading = this.loanRequest.promise('financialSummary').then((data) => {
      this._financialSummary = data
      this.profitLoss = {
        headings: this._headings('profitLoss').reverse(),
        fields: this._fields('profitLoss')
      }
      this.balanceSheet = {
        headings: this._headings('balanceSheet').reverse(),
        fields: this._fields('balanceSheet')
      }
      this.debt = {
        headings: this._headings('debt'),
        fields: this._fields('debt')
      }
    })
  }

  _fields(headingName) {
    let keyset = {}
    let heading = this._financialSummary[headingName]
    if (heading) {
      for (let key of Object.keys(heading)) {
        for (let subkey of Object.keys(heading[key])) {
          keyset[subkey] = true
        }
      }
    }
    return Object.keys(keyset)
  }

  _headings(headingName) {
    return this._financialSummary[headingName] ? Object.keys(this._financialSummary[headingName]) : []
  }
}

export default FinancialSummaryDisplayController
