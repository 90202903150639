class LoansController {
  /*@ngInject*/
  constructor($q, $mdDialog, $filter, loansRepository, localStorageService, fundingSourcesRepository, $scope) {
    this.name = 'loans'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.loansRepository = loansRepository
    this.localStorageService = localStorageService

    this.order = '-ID'
    this.limitOptions = [10, 50, 100, 250, 500]
    this.total = 0

    this.loanStatuses = [
      {label: 'All', value: 'all'},
      {label: 'Active', value: 'active'},
      {label: 'Deferral Agreed', value: 'deferred'},
      {label: 'Credit Monitoring', value: 'irregular'},
      {label: 'Defaulted', value: 'defaulted'},
      {label: 'Completed', value: 'completed'}
    ]
    this.loanTypes = [
      {label: 'All', value: 'all'},
      {label: 'Cash flow', value: 'CF'},
      {label: 'Chattel mortgage', value: 'CM'},
      {label: 'Finance lease', value: 'FL'},
      {label: 'Acquisition', value: 'AQ'},
      {label: 'Rent roll', value: 'RR'},
      {label: 'Trail book', value: 'TB'},
      {label: 'Line of Credit', value: 'LC'},
      {label: 'Tools finance', value: 'TF'},
      {label: 'Other', value: 'OT'}
    ]
    this.loanOriginations = [
      {label: 'All', value: 'all'},
      {label: 'TruePillars', value: 'truepillars'},
      {label: 'Laddr', value: 'laddr'},
      {label: 'RedZed', value: 'redzed'},
      {label: 'Other', value: 'other'}
    ]

    this.loanFundingSources = [{label: 'All', value: 'all'}]
    fundingSourcesRepository.all().then(data => {
      this.loanFundingSources.push(...data.fundingSources.map(source => ({label: $filter('asLeadingCaps')(source.name), value: source.name})))
    })

    localStorageService.bind($scope, 'vm.status', 'all', 'loans.status')
    localStorageService.bind($scope, 'vm.type', 'all', 'loans.type')
    localStorageService.bind($scope, 'vm.origination', 'all', 'loans.origination')
    localStorageService.bind($scope, 'vm.fundingSource', 'all', 'loans.fundingSource')
    localStorageService.bind($scope, 'vm.limit', 50, 'loans.limit')

    this.reload = () => {
      this.loading = true
      const conditions = {
        search: this.search,
        status: this.status,
        type: this.type,
        origination: this.origination,
        fundingSource: this.fundingSource,
        includeAdditionalDetails: this.includeAdditionalDetails,
        order: this.order,
        size: this.limit,
        page: this.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.loansRepository.where(conditions)
        .then(data => {
          this.loans = data.loans
          this.total = data.total
        })
        .finally(() => this.loading = false)
    }

    this.$onInit = () => {
      this.performSearch()
    }
  }

  performSearch() {
    this.page = 1
    this.reload()
  }
}

export default LoansController
