class NumberedStepController {
  /*@ngInject*/
  constructor($state) {
    this.name = 'numberedStep'
    this.$state = $state
  }

  get selected() {
    return this.state === this.$state.current.name
  }

  get status() {
    return this.selected ? 'current' : this.complete ? 'complete' : 'incomplete'
  }

  get showIncomplete() {
    return !this.complete && !this.selected && this.visited
  }
}

export default NumberedStepController
