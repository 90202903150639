import {container, radioButtons, ruleRender, string, text} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const formInvestorStatus = {
  title: 'Investor status',
  model: 'investorStatus',
  actionLabel: 'Update',
  widgets: [
    container().classes('border box-shadow-2').widgets(
      string().prompt('Current status').model('currentStatus').readOnly().build(),
      radioButtons().prompt('Category').model('category')
        .button('Aggregated', 'aggregated')
        .button('Deceased', 'deceased')
        .button('Fraud', 'fraud')
        .button('Inactive', 'inactive')
        .button('Mistake', 'mistake')
        .button('Unhappy', 'unhappy')
        .button('Other', 'other')
        .build(),
      string().prompt('Other category').model('otherCategory').rules(ruleRender('(eq (fromModel "category") "other")')).build(),
      text().prompt('Comment').model('comment').build(),
    ).build(),
  ]
}

export default formInvestorStatus
