import valueOrDefault from '../../utils/valueOrDefault'
import assert from '../../utils/assert'
import Big from 'big.js'

import transformRawRatingsMinMax from './ratingsUtils'
import rawRatingsData from './_rawRatings'

const RISK_RATINGS = Object.freeze(['A', 'B', 'C', 'D', 'E'])
const SECURITY_RATINGS = Object.freeze(['A', 'B', 'C', 'D', 'E'])

class RatingsService {
  /*@ngInject*/
  constructor() {

    // private

    const _ratings = [
      {stars: 1, label: '1 star', value: 'E', minRate: 18.0, maxRate: 24.0, risk: 8.0},
      {stars: 2, label: '2 star', value: 'D', minRate: 14.0, maxRate: 20.0, risk: 6.0},
      {stars: 3, label: '3 star', value: 'C', minRate: 11.0, maxRate: 16.0, risk: 3.5},
      {stars: 4, label: '4 star', value: 'B', minRate: 9.5, maxRate: 14.5, risk: 2.5},
      {stars: 5, label: '5 star', value: 'A', minRate: 8.0, maxRate: 13.0, risk: 1.5}
    ]

    const _rateSteps = [
      {value: 0.5, label: '0.5%'},
      {value: 0.1, label: '0.1%'}
    ]

    // exposed

    this.makeRatingsKey = (riskRating, securityRating) => {
      assert(RISK_RATINGS.includes(riskRating), `Unknown risk rating '${riskRating}'. Must be one of ${RISK_RATINGS}.`)
      assert(SECURITY_RATINGS.includes(securityRating), `Unknown security rating '${securityRating}'. Must be one of ${SECURITY_RATINGS}.`)
      return `r[${riskRating}]s[${securityRating}]`
    }

    this.indicativeBorrowerInterestRate = (rateKeys) => {
      return Array.isArray(rateKeys) && rateKeys.length > 0 ? rateKeys.reduce((sum, key) => {
        return sum.plus(this._allRatings[key].rateMin).plus(this._allRatings[key].rateMax)
      }, new Big(0.0)).div(new Big(rateKeys.length * 2)) : new Big('0.0')
    }
    this._allRatings = transformRawRatingsMinMax(rawRatingsData, this.makeRatingsKey)
    this.baseRate = this.indicativeBorrowerInterestRate(Object.keys(this._allRatings))

    this.rating = (value) => _ratings.find((element) => element.value === value)

    this.ratings = () => _ratings

    this.rateSteps = () => _rateSteps

    this.lowRate = (stars) => valueOrDefault(_ratings.find((element) => element.stars === stars), {minRate: 8.0}).minRate

    this.highRate = (stars) => valueOrDefault(_ratings.find((element) => element.stars === stars), {maxRate: 24.0}).maxRate

    this.allRiskRatings = () => RISK_RATINGS

    this.allSecurityRatings = () => SECURITY_RATINGS
  }
}

export default RatingsService
