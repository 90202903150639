import Big from 'big.js'
import moment from 'moment'
import resolveProperty from '../../../../utils/resolveProperty'

import makeTokenProcessorUsingDSL from './helpers/makeTokenProcessorUsingDSL'
import resolveAmitReportContent from './helpers/resolveAmitReportContent'
import jsonToPdfMakeTable from './helpers/jsonToPdfMakeTable'
import jsonToPdfMakeText from './helpers/jsonToPdfMakeText'

class InvestorReportService {
  /*@ngInject*/
  constructor($filter, pdfService, csvService, $mdToast, $q, tenantInformationService, contentfulAssetsService, contentful, stylingService, loggerService) {
    this.$filter = $filter
    this.pdfService = pdfService
    this.csvService = csvService
    this.$mdToast = $mdToast
    this.contentful = contentful

    this.logger = loggerService.makeLogger().enableNewRelic(true)

    this.globalAssetsPromise = $q.all([tenantInformationService.getElements(
      'name.long',
      'name.short',
      'trust.name.long',
      'abn',
      'contact.address.physical.inline',
      'contact.address.postal.inline',
      'contact.telephone.main.label',
      'contact.address.web.homepage',
      'contact.email.reportEnquiries.label',
      'products'
    ), contentfulAssetsService.findAsset('mast-logo-normal')])
      .then(([[nameLong, nameShort, trustName, abn, addressPhysical, addressPostal, enquiriesPhone, addressHomepage, enquiriesEmail, products], logoMetadata]) => {
        this.brandContent = {
          author: nameShort,
          trustName: trustName,
          logoUrl: `https:${resolveProperty(logoMetadata, 'asset', 'file', 'url')}`,
          styling: {
            colourPrimary: stylingService.getPropertyValue('--colour-primary'),
            colourAccent: stylingService.getPropertyValue('--colour-accent')
          },
          footer: {
            stack: [
              `${nameLong} ABN ${abn}`,
              [addressPhysical, addressPostal].filter((address) => address).join(' | '),
              {
                text: [
                  `Enquiries ${enquiriesPhone}`,
                  ' | ',
                  {text: addressHomepage.label, link: addressHomepage.href},
                  ' | ',
                  enquiriesEmail
                ]
              }
            ],
            fontSize: 8,
            style: ['center', 'margin1x']
          },
          products: products
        }
      })
  }


  // ****** Portfolio Summary Report

  createAndDownloadPortfolioSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    this.globalAssetsPromise.then(() => {
      this._createAndDownloadReport('PortfolioSummary-' + this._formatDate(fromDate, 'YYYYMMDD') + '-' + this._formatDate(toDate, 'YYYYMMDD'), [
        this._logoSection(),
        this._reportTitleSection(this.brandContent.trustName),
        this._ownerDetailsSection(investor, 'Portfolio summary \n' + this._formatDate(fromDate, 'D MMM YYYY') + ' to ' + this._formatDate(toDate, 'D MMM YYYY')),
        {
          text: '',
          fontSize: 12,
          style: ['margin3x']
        },
        this._investorDetailsSection(investor),
        {
          text: 'Portfolio summary',
          fontSize: 12,
          style: ['bold', 'margin1x']
        },
        {
          table: {
            widths: ['*', 80, 80, 80, 80],
            body: [
              [
                {text: 'Period', style: 'bold', colSpan: 2},
                {text: ''},
                {text: this._formatDate(fromDate, 'D MMM YYYY') + ' to ' + this._formatDate(toDate, 'D MMM YYYY'), style: 'right', colSpan: 3},
                {text: ''},
                {text: ''},
              ],
              [
                {text: 'P2P net investment return', style: 'bold', colSpan: 2},
                {text: ''},
                {text: investmentPortfolioSummary.p2p.returnRate + '% p.a.', style: 'right', colSpan: 3},
                {text: ''},
                {text: ''},
              ],
              [
                {text: 'Pooled net investment return', style: 'bold', colSpan: 2},
                {text: ''},
                {text: investmentPortfolioSummary.ip.returnRate + '% p.a.', style: 'right', colSpan: 3},
                {text: ''},
                {text: ''},
              ],
              [
                {text: 'Description', style: 'bold', color: this.brandContent.styling.colourAccent},
                {text: 'Cash', style: ['bold', 'right'], color: this.brandContent.styling.colourAccent},
                {text: 'P2P', style: ['bold', 'right'], color: this.brandContent.styling.colourAccent},
                {text: 'Pooled', style: ['bold', 'right'], color: this.brandContent.styling.colourAccent},
                {text: 'Total', style: ['bold', 'right'], color: this.brandContent.styling.colourAccent},
              ],
              [
                {text: 'Opening balance', style: 'bold'},
                {text: this._formatAmount(investmentPortfolioSummary.cash.openingFunds), style: ['bold', 'right']},
                {text: this._formatAmount(investmentPortfolioSummary.p2p.openingFunds), style: ['bold', 'right']},
                {text: this._formatAmount(investmentPortfolioSummary.ip.openingFunds), style: ['bold', 'right']},
                {text: this._formatAmount(investmentPortfolioSummary.total.openingFunds), style: ['bold', 'right']}
              ],
              [
                {text: 'Cash deposits'},
                {text: this._formatAmount(investmentPortfolioSummary.cash.deposit), style: 'right'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.total.deposit), style: 'right'}
              ],
              [
                {text: 'Cash withdrawals'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.cash.withdrawal)), style: 'right'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.total.withdrawal), style: 'right'}
              ],
              [
                {text: 'Capital invested'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.total.capitalInvested)), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalInvested), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.ip.capitalInvested), style: 'right'},
                {text: this._formatAmount(0), style: 'right'}
              ],
              [
                {text: 'Capital returned'},
                {text: this._formatAmount(investmentPortfolioSummary.total.capitalReturned), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.p2p.capitalReturned)), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.ip.capitalReturned)), style: 'right'},
                {text: this._formatAmount(0), style: 'right'}
              ],
              [
                {text: 'Capital losses'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.p2p.capitalLost)), style: 'right'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.total.capitalLost)), style: 'right'}
              ],
              [
                {text: 'Interest earned'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.p2p.interestReceived), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.ip.interestReceived), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.total.interestReceived), style: 'right'}
              ],
              [
                {text: 'Interest received'},
                {text: this._formatAmount(investmentPortfolioSummary.total.interestReceived), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.p2p.interestReceived)), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.ip.interestReceived)), style: 'right'},
                {text: this._formatAmount(0), style: 'right'}
              ],
              [
                {text: 'Tax withheld'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.p2p.taxWithheld)), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.ip.taxWithheld)), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.total.taxWithheld)), style: 'right'}
              ],
              [
                {text: 'Tax paid'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.total.taxWithheld)), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.p2p.taxWithheld), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.ip.taxWithheld), style: 'right'},
                {text: this._formatAmount(0), style: 'right'}
              ],
              [
                {text: 'Fees charged'},
                {text: this._formatAmount(0), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.p2p.feesPaid)), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.ip.feesPaid)), style: 'right'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.total.feesPaid)), style: 'right'}
              ],
              [
                {text: 'Fees paid'},
                {text: this._formatAmount(this._negative(investmentPortfolioSummary.total.feesPaid)), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.p2p.feesPaid), style: 'right'},
                {text: this._formatAmount(investmentPortfolioSummary.ip.feesPaid), style: 'right'},
                {text: this._formatAmount(0), style: 'right'}
              ],
              [
                {text: 'Closing balance', style: 'bold'},
                {text: this._formatAmount(investmentPortfolioSummary.cash.closingFunds), style: ['bold', 'right']},
                {text: this._formatAmount(investmentPortfolioSummary.p2p.closingFunds), style: ['bold', 'right']},
                {text: this._formatAmount(investmentPortfolioSummary.ip.closingFunds), style: ['bold', 'right']},
                {text: this._formatAmount(investmentPortfolioSummary.total.closingFunds), style: ['bold', 'right']}
              ],
            ]
          },
          layout: {
            hLineWidth: (i, node) => i === 4 ? 1 : 0,
            vLineWidth: (i, node) => 0,
            hLineColor: (i, node) => i === 4 ? 'black' : null,
            vLineColor: (i, node) => null,
            paddingLeft: (i, node) => 10,
            paddingRight: (i, node) => 10,
            paddingTop: (i, node) => i < 3 ? 0 : i === 3 ? 16 : i === 4 ? 6 : 4,
            paddingBottom: (i, node) => 4,
            fillColor: (i, node) => null
          },
          fontSize: 10,
          margin: [0, 5, 0, 0]
        },
        {
          text: [
            {text: 'DISCLAIMER: ', style: 'bold'},
            'This information refers to the marketplace lending platform operated by TruePillars Pty Ltd and the offer to investors of units in the TruePillars Investment Trust (the “Trust”). ' +
            'Units in the TruePillars Investment Trust provide investors with exposure to small and medium business loans. Any person seeking to make an investment should review the ' +
            'Product Disclosure Statement and seek legal, financial and taxation advice relative to their own circumstances. ' +
            'The manager of the Trust is TruePillars Pty Ltd, AFSL Authorised Representative Number 1237905 appointed by the trustee, ' +
            'Melbourne Securities Corporation Ltd ACN 160 326 545 AFSL 428289. ' +
            'This product is not a bank deposit. Returns are not guaranteed. ' +
            'An investment may achieve lower than expected returns and investors risk losing some or all of their principal investment. ' +
            'Past performance is no indication of future performance and actual returns will be dependent on the performance of specific investments selected by you. ' +
            'Diversification of your investment across several investments may reduce your overall risk.',
          ],
          fontSize: 6,
          margin: [0, 100, 0, 0]
        },
      ])
    })
  }


  // ****** Transaction Details Report

  createAndDownloadTransactionDetailsReport(investor, fromDate, toDate, investorTransactionDetails) {
    const bodyHeader = [
      {text: 'Date', style: 'bold', color: this.brandContent.styling.colourAccent},
      {text: 'Description', style: 'bold', color: this.brandContent.styling.colourAccent},
      {text: 'Amount', style: ['bold', 'right'], color: this.brandContent.styling.colourAccent},
    ]
    const bodyEntries = investorTransactionDetails.map(investorTransactionDetail => {
      return [
        {text: this._formatDate(investorTransactionDetail.transactionDate, 'DD MMM YYYY')},
        {text: investorTransactionDetail.description},
        {text: this._formatAmount(investorTransactionDetail.amount), style: 'right'},
      ]
    })
    this.globalAssetsPromise.then(() => {
      this._createAndDownloadReport('TransactionDetails-' + this._formatDate(fromDate, 'YYYYMMDD') + '-' + this._formatDate(toDate, 'YYYYMMDD'), [
        this._logoSection(),
        this._reportTitleSection(this.brandContent.trustName),
        this._ownerDetailsSection(investor, 'Transaction details\n' + this._formatDate(fromDate, 'D MMM YYYY') + ' to ' + this._formatDate(toDate, 'D MMM YYYY')),
        {
          text: '',
          fontSize: 12,
          style: ['margin3x']
        },
        this._investorDetailsSection(investor),
        {
          text: 'Transaction details',
          fontSize: 12,
          style: ['bold', 'margin1x']
        },
        {
          table: {
            widths: [60, '*', 60],
            headerRows: 1,
            body: [bodyHeader].concat(bodyEntries)
          },
          layout: {
            hLineWidth: (i, node) => i === 1 ? 1 : 0,
            vLineWidth: (i, node) => 0,
            hLineColor: (i, node) => i === 1 ? 'black' : null,
            vLineColor: (i, node) => null,
            paddingLeft: (i, node) => 10,
            paddingRight: (i, node) => 10,
            paddingTop: (i, node) => i === 1 ? 4 : 2,
            paddingBottom: (i, node) => 2,
            fillColor: (i, node) => null
          },
          fontSize: 9,
          style: 'margin1x'
        },
        {
          text: [
            {text: 'DISCLAIMER: ', style: 'bold'},
            'This information refers to the marketplace lending platform operated by TruePillars Pty Ltd and the offer to investors of units in the TruePillars Investment Trust (the “Trust”). ' +
            'Units in the TruePillars Investment Trust provide investors with exposure to small and medium business loans. Any person seeking to make an investment should review the ' +
            'Product Disclosure Statement and seek legal, financial and taxation advice relative to their own circumstances. ' +
            'The manager of the Trust is TruePillars Pty Ltd, AFSL Authorised Representative Number 1237905 appointed by the trustee, ' +
            'Melbourne Securities Corporation Ltd ACN 160 326 545 AFSL 428289. ' +
            'This product is not a bank deposit. Returns are not guaranteed. ' +
            'An investment may achieve lower than expected returns and investors risk losing some or all of their principal investment. ' +
            'Past performance is no indication of future performance and actual returns will be dependent on the performance of specific investments selected by you. ' +
            'Diversification of your investment across several investments may reduce your overall risk.',
          ],
          fontSize: 6,
          style: 'margin2x'
        },
      ])
    })
  }


  // ****** Transaction Details CSV

  createAndDownloadTransactionDetailsCsv(fromDate, toDate, investorTransactionDetails) {
    const rows = investorTransactionDetails.map(entry => {
      let row = {}
      row['Ref'] = entry.id
      row['Date'] = entry.transactionDate
      row['Description'] = entry.description
      row['Amount'] = entry.amount
      return row
    })
    this.csvService.createAndDownload('TransactionDetails-' + this._formatDate(fromDate, 'YYYYMMDD') + '-' + this._formatDate(toDate, 'YYYYMMDD') + '.csv', rows)
  }


  // ****** Taxation Summary Report

  createAndDownloadTaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    this.globalAssetsPromise.then(() => {
      const handler = `_createAndDownload${moment(toDate).year()}TaxationSummaryReport`
      if (this[handler]) {
        return this[handler](investor, fromDate, toDate, investmentPortfolioSummary)
      } else {
        const formatter = (when) => moment(when).format('YYYY')
        this._toast(`There is no AMIT report format for the years ${formatter(fromDate)} to ${formatter(toDate)}; sorry.`)
      }
    })
  }


  // ****** (2017) Taxation Summary Report

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2017TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    const totalDistributions = new Big(investmentPortfolioSummary.p2p.capitalReturned).plus(investmentPortfolioSummary.p2p.interestReceived).toString()
    const totalDeductions = investmentPortfolioSummary.p2p.feesPaid
    const netDistributions = new Big(totalDistributions).minus(totalDeductions).toString()
    const summaryTableLayout = {
      hLineWidth: (i, node) => i === 1 ? 1 : 0,
      vLineWidth: (i, node) => 0,
      hLineColor: (i, node) => i === 1 ? 'black' : null,
      vLineColor: (i, node) => null,
      paddingLeft: (i, node) => 10,
      paddingRight: (i, node) => 10,
      paddingTop: (i, node) => i === 1 ? 6 : 4,
      paddingBottom: (i, node) => 4,
      fillColor: (i, node) => null
    }
    this._createAndDownloadReport('AMIT-annual-statement-FY' + this._formatDate(toDate, 'YYYY'), [
      this._logoSection(),
      this._reportTitleSection('TruePillars Investment Trust'),
      this._ownerDetailsSection(investor, 'AMIT member annual (AMMA) statement for the income year ending ' + this._formatDate(toDate, 'D MMM YYYY')),
      {
        text: 'PLEASE RETAIN THIS STATEMENT FOR INCOME TAX PURPOSES',
        fontSize: 12,
        color: 'red',
        style: ['bold', 'center', 'margin1_5x']
      },
      this._investorDetailsSection(investor),
      {
        text: 'Taxation information summary',
        fontSize: 12,
        style: ['bold', 'margin1x']
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Member component on Loan Units', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, {text: 'Tax return label', style: ['bold', 'center']}],
            [{text: 'Interest income distributed (assessable)'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.interestReceived), style: 'right'}, {text: '13U', style: 'center'}],
            [{text: 'Loan principal repayments distributed (not assessable)'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalReturned), style: 'right'}, ''],
            [
              {text: 'Gross cash distribution on Loan Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDistributions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            ['', '', ''],
            ['', '', ''],
            [{text: 'TFN/ABN tax withheld'}, {text: this._formatAmount(0), style: 'right'}, {text: '13R', style: 'center'}],
            [{text: 'Foreign withholding tax on interest'}, {text: this._formatAmount(0), style: 'right'}, {text: '13A', style: 'center'}],
            [{text: 'Management fees*'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.feesPaid), style: 'right'}, {text: '13Y', style: 'center'}],
            [
              {text: 'Total deductions', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDeductions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            [
              {text: 'Net cash distribution on Loan Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(netDistributions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'AMIT cost base net amount (adjustments to the tax cost base of Units)', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Loan Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalReturned), style: 'right'}, ''],
            [{text: 'Cash Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(0), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Capital Gains', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Estimate of current year capital gains'}, {text: this._formatAmount(0), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: [12, '*'],
          body: [
            [{text: '*', style: 'bold'}, {text: 'Management fees include GST of 10%'}],
            [{text: '**', style: 'bold'}, {
              text: 'The reduction in the cost base of units has been calculated as the excess of cash distributions on units during the income year over ' +
                'the net assessable distributions on such units for that income year'
            }],
          ]
        },
        layout: {
          hLineWidth: (i, node) => i === node.table.body.length ? 0.5 : 0,
          vLineWidth: (i, node) => 0,
          hLineColor: (i, node) => i === node.table.body.length ? 'black' : null,
          vLineColor: (i, node) => null,
          paddingLeft: (i, node) => 10,
          paddingRight: (i, node) => 10,
          paddingTop: (i, node) => 0,
          paddingBottom: (i, node) => i === node.table.body.length - 1 ? 8 : 4,
          fillColor: (i, node) => null
        },
        fontSize: 8,
        style: 'margin2x'
      },
      {
        text: [
          {text: 'DISCLAIMER: ', style: 'bold'},
          'This statement is to assist you in preparing your ' + this._formatDate(toDate, 'D MMM YYYY') + ' tax return. Tax return label references are only applicable to individual tax returns. ' +
          'This document does not constitute tax advice. For further information refer to the PDS. To the extent permitted by law, no liability is accepted for any loss or damage as a result ' +
          'of reliance on this information. TruePillars does not guarantee the repayment of capital, payment of income or the Trust’s performance.'
        ],
        fontSize: 6,
        margin: [0, 70, 0, 0]
      },
      this._growTogetherTagLine()
    ])
  }


  // ****** (2018) Taxation Summary Report

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2018TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    const totalDistributions = new Big(investmentPortfolioSummary.p2p.capitalReturned).plus(investmentPortfolioSummary.p2p.interestReceived).toString()
    const totalDeductions = investmentPortfolioSummary.p2p.feesPaid
    const netDistributions = new Big(totalDistributions).minus(totalDeductions).toString()
    const summaryTableLayout = {
      hLineWidth: (i, node) => i === 1 ? 1 : 0,
      vLineWidth: (i, node) => 0,
      hLineColor: (i, node) => i === 1 ? 'black' : null,
      vLineColor: (i, node) => null,
      paddingLeft: (i, node) => 10,
      paddingRight: (i, node) => 10,
      paddingTop: (i, node) => i === 1 ? 6 : 4,
      paddingBottom: (i, node) => 4,
      fillColor: (i, node) => null
    }
    this._createAndDownloadReport('AMIT-annual-statement-FY' + this._formatDate(toDate, 'YYYY'), [
      this._logoSection(),
      this._reportTitleSection('TruePillars Investment Trust'),
      this._ownerDetailsSection(investor, 'AMIT member annual (AMMA) statement for the income year ending ' + this._formatDate(toDate, 'D MMM YYYY')),
      {
        text: 'PLEASE RETAIN THIS STATEMENT FOR INCOME TAX PURPOSES',
        fontSize: 12,
        color: 'red',
        style: ['bold', 'center', 'margin1_5x']
      },
      this._investorDetailsSection(investor),
      {
        text: 'Taxation information summary',
        fontSize: 12,
        style: ['bold', 'margin1x']
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Member component on Loan Units', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, {text: 'Tax return label', style: ['bold', 'center']}],
            [{text: 'Interest income distributed (assessable)'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.interestReceived), style: 'right'}, {text: '13U', style: 'center'}],
            [{text: 'Loan principal repayments distributed (not assessable)'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalReturned), style: 'right'}, ''],
            [
              {text: 'Gross cash distribution on Loan Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDistributions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            ['', '', ''],
            ['', '', ''],
            [{text: 'TFN/ABN tax withheld'}, {text: this._formatAmount(0), style: 'right'}, {text: '13R', style: 'center'}],
            [{text: 'Foreign withholding tax on interest'}, {text: this._formatAmount(0), style: 'right'}, {text: '13A', style: 'center'}],
            [{text: 'Management fees*'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.feesPaid), style: 'right'}, {text: '13Y', style: 'center'}],
            [
              {text: 'Total deductions', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDeductions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            [
              {text: 'Net cash distribution on Loan Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(netDistributions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'AMIT cost base net amount (adjustments to the tax cost base of Units)', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Loan Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalReturned), style: 'right'}, ''],
            [{text: 'Cash Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(0), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Capital Gains', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Estimate of current year capital gains'}, {text: this._formatAmount(0), style: 'right'}, ''],
            [{text: 'Estimate of current year capital losses'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalLost), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: [12, '*'],
          body: [
            [{text: '*', style: 'bold'}, {text: 'Management fees include GST of 10%'}],
            [{text: '**', style: 'bold'}, {
              text: 'The reduction in the cost base of units has been calculated as the excess of cash distributions on units during the income year over ' +
                'the net assessable distributions on such units for that income year'
            }],
          ]
        },
        layout: {
          hLineWidth: (i, node) => i === node.table.body.length ? 0.5 : 0,
          vLineWidth: (i, node) => 0,
          hLineColor: (i, node) => i === node.table.body.length ? 'black' : null,
          vLineColor: (i, node) => null,
          paddingLeft: (i, node) => 10,
          paddingRight: (i, node) => 10,
          paddingTop: (i, node) => 0,
          paddingBottom: (i, node) => i === node.table.body.length - 1 ? 8 : 4,
          fillColor: (i, node) => null
        },
        fontSize: 8,
        style: 'margin2x'
      },
      {
        text: [
          {text: 'DISCLAIMER: ', style: 'bold'},
          'This statement is to assist you in preparing your ' + this._formatDate(toDate, 'D MMM YYYY') + ' tax return. Tax return label references are only applicable to individual tax returns. ' +
          'This document does not constitute tax advice. For further information refer to the PDS. To the extent permitted by law, no liability is accepted for any loss or damage as a result ' +
          'of reliance on this information. TruePillars does not guarantee the repayment of capital, payment of income or the Trust’s performance.'
        ],
        fontSize: 6,
        margin: [0, 50, 0, 0]
      },
      this._growTogetherTagLine()
    ])
  }


  // ****** (2019) Taxation Summary Report

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2019TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    const totalDistributions = new Big(investmentPortfolioSummary.p2p.capitalReturned).plus(investmentPortfolioSummary.p2p.interestReceived).toString()
    const totalDeductions = new Big(investmentPortfolioSummary.p2p.feesPaid).plus(investmentPortfolioSummary.p2p.taxWithheld).toString()
    const netDistributions = new Big(totalDistributions).minus(totalDeductions).toString()
    const summaryTableLayout = {
      hLineWidth: (i, node) => i === 1 ? 1 : 0,
      vLineWidth: (i, node) => 0,
      hLineColor: (i, node) => i === 1 ? 'black' : null,
      vLineColor: (i, node) => null,
      paddingLeft: (i, node) => 10,
      paddingRight: (i, node) => 10,
      paddingTop: (i, node) => i === 1 ? 6 : 4,
      paddingBottom: (i, node) => 4,
      fillColor: (i, node) => null
    }
    this._createAndDownloadReport('AMIT-annual-statement-FY' + this._formatDate(toDate, 'YYYY'), [
      this._logoSection(),
      this._reportTitleSection('TruePillars Investment Trust'),
      this._ownerDetailsSection(investor, 'AMIT member annual (AMMA) statement for the income year ending ' + this._formatDate(toDate, 'D MMM YYYY')),
      {
        text: 'PLEASE RETAIN THIS STATEMENT FOR INCOME TAX PURPOSES',
        fontSize: 12,
        color: 'red',
        style: ['bold', 'center', 'margin1_5x']
      },
      this._investorDetailsSection(investor),
      {
        text: 'Taxation information summary',
        fontSize: 12,
        style: ['bold', 'margin1x']
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Member component on Loan Units', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, {text: 'Tax return label', style: ['bold', 'center']}],
            [{text: 'Interest income distributed (assessable)'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.interestReceived), style: 'right'}, {text: '13U', style: 'center'}],
            [{text: 'Loan principal repayments distributed (not assessable)'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalReturned), style: 'right'}, ''],
            [
              {text: 'Gross cash distribution on Loan Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDistributions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            ['', '', ''],
            ['', '', ''],
            [{text: 'TFN/ABN tax withheld'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.taxWithheld), style: 'right'}, {text: '13R', style: 'center'}],
            [{text: 'Foreign withholding tax on interest'}, {text: this._formatAmount(0), style: 'right'}, {text: '13A', style: 'center'}],
            [{text: 'Management fees*'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.feesPaid), style: 'right'}, {text: '13Y', style: 'center'}],
            [
              {text: 'Total deductions', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDeductions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            [
              {text: 'Net cash distribution on Loan Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(netDistributions), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'AMIT cost base net amount (adjustments to the tax cost base of Units)', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Loan Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalReturned), style: 'right'}, ''],
            [{text: 'Cash Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(0), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Capital Gains', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Estimate of current year capital gains'}, {text: this._formatAmount(0), style: 'right'}, ''],
            [{text: 'Estimate of current year capital losses'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalLost), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: [12, '*'],
          body: [
            [{text: '*', style: 'bold'}, {text: 'Management fees include GST of 10%'}],
            [{text: '**', style: 'bold'}, {
              text: 'The reduction in the cost base of units has been calculated as the excess of cash distributions on units during the income year over ' +
                'the net assessable distributions on such units for that income year'
            }],
          ]
        },
        layout: {
          hLineWidth: (i, node) => i === node.table.body.length ? 0.5 : 0,
          vLineWidth: (i, node) => 0,
          hLineColor: (i, node) => i === node.table.body.length ? 'black' : null,
          vLineColor: (i, node) => null,
          paddingLeft: (i, node) => 10,
          paddingRight: (i, node) => 10,
          paddingTop: (i, node) => 0,
          paddingBottom: (i, node) => i === node.table.body.length - 1 ? 8 : 4,
          fillColor: (i, node) => null
        },
        fontSize: 8,
        style: 'margin2x'
      },
      {
        text: [
          {text: 'DISCLAIMER: ', style: 'bold'},
          'This statement is to assist you in preparing your ' + this._formatDate(toDate, 'D MMM YYYY') + ' tax return. Tax return label references are only applicable to individual tax returns. ' +
          'This document does not constitute tax advice. For further information refer to the PDS. To the extent permitted by law, no liability is accepted for any loss or damage as a result ' +
          'of reliance on this information. TruePillars does not guarantee the repayment of capital, payment of income or the Trust’s performance.'
        ],
        fontSize: 6,
        margin: [0, 50, 0, 0]
      },
      this._growTogetherTagLine()
    ])
  }


  // ****** (2020) Taxation Summary Report

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2020TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    this._createAndDownload2019TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary)
  }


  // ****** (2021) Taxation Summary Report

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2021TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    const ipCapitalReturned = new Big(investmentPortfolioSummary.ip.capitalReturned).round(2, 0)
    const ipInterestReceived = new Big(investmentPortfolioSummary.ip.interestReceived).round(2, 0)
    const ipOpeningAccruedInterest = new Big(investmentPortfolioSummary.ip.openingAccruedInterest).round(2, 0)
    const ipClosingAccruedInterest = new Big(investmentPortfolioSummary.ip.closingAccruedInterest).round(2, 0)
    const ipFeesPaid = new Big(investmentPortfolioSummary.ip.feesPaid).round(2, 0)
    const ipTaxWithheld = new Big(investmentPortfolioSummary.ip.taxWithheld).round(2, 0)

    const p2pCapitalReturned = new Big(investmentPortfolioSummary.p2p.capitalReturned).round(2, 0)
    const p2pInterestReceived = new Big(investmentPortfolioSummary.p2p.interestReceived).round(2, 0)
    const p2pFeesPaid = new Big(investmentPortfolioSummary.p2p.feesPaid).round(2, 0)
    const p2pTaxWithheld = new Big(investmentPortfolioSummary.p2p.taxWithheld).round(2, 0)

    const capitalReturned = p2pCapitalReturned.plus(ipCapitalReturned)
    const interestReceived = p2pInterestReceived.plus(ipInterestReceived).minus(ipOpeningAccruedInterest).plus(ipClosingAccruedInterest)
    const totalDistributions = capitalReturned.plus(interestReceived)

    const feesPaid = p2pFeesPaid.plus(ipFeesPaid)
    const taxWithheld = p2pTaxWithheld.plus(ipTaxWithheld)
    const totalDeductions = feesPaid.plus(taxWithheld)

    const summaryTableLayout = {
      hLineWidth: (i, node) => i === 1 ? 1 : 0,
      vLineWidth: (i, node) => 0,
      hLineColor: (i, node) => i === 1 ? 'black' : null,
      vLineColor: (i, node) => null,
      paddingLeft: (i, node) => 10,
      paddingRight: (i, node) => 10,
      paddingTop: (i, node) => i === 1 ? 6 : 4,
      paddingBottom: (i, node) => 4,
      fillColor: (i, node) => null
    }
    this._createAndDownloadReport('AMIT-annual-statement-FY' + this._formatDate(toDate, 'YYYY'), [
      this._logoSection(),
      this._reportTitleSection('TruePillars Investment Trust'),
      this._ownerDetailsSection(investor, 'AMIT member annual (AMMA) statement for the income year ending ' + this._formatDate(toDate, 'D MMM YYYY')),
      {
        text: 'PLEASE RETAIN THIS STATEMENT FOR INCOME TAX PURPOSES',
        fontSize: 12,
        color: 'red',
        style: ['bold', 'center', 'margin1_5x']
      },
      this._investorDetailsSection(investor),
      {
        text: 'Taxation information summary',
        fontSize: 12,
        style: ['bold', 'margin1x']
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Member component on Units', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, {text: 'Tax return label', style: ['bold', 'center']}],
            [{text: 'Loan unit interest income distributed (assessable)'}, {text: this._formatAmount(p2pInterestReceived.toString()), style: 'right'}, {text: '13U', style: 'center'}],
            [
              {text: 'Pooled unit interest income distributed (assessable)'}, {text: this._formatAmount(ipInterestReceived.minus(ipOpeningAccruedInterest).toString()), style: 'right'},
              {text: '13U', style: 'center'}],
            [
              {text: 'Pooled unit interest income accrued (assessable)'}, {text: this._formatAmount(ipClosingAccruedInterest.toString()), style: 'right'},
              {text: '13U', style: 'center'}
            ],
            [{text: 'Loan principal repayments distributed (not assessable)'}, {text: this._formatAmount(p2pCapitalReturned.toString()), style: 'right'}, ''],
            [{text: 'Pooled unit redemptions (not assessable)'}, {text: this._formatAmount(ipCapitalReturned.toString()), style: 'right'}, ''],
            [
              {text: 'Gross distribution on Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDistributions.toString()), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            ['', '', ''],
            ['', '', ''],
            [{text: 'TFN/ABN tax withheld'}, {text: this._formatAmount(taxWithheld.toString()), style: 'right'}, {text: '13R', style: 'center'}],
            [{text: 'Foreign withholding tax on interest'}, {text: this._formatAmount(0), style: 'right'}, {text: '13A', style: 'center'}],
            [{text: 'Management fees*'}, {text: this._formatAmount(feesPaid.toString()), style: 'right'}, {text: '13Y', style: 'center'}],
            [
              {text: 'Total deductions', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDeductions.toString()), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
            [
              {text: 'Net cash distribution on Units', fillColor: '#dddddd', style: 'bold'},
              {text: this._formatAmount(totalDistributions.minus(totalDeductions).toString()), fillColor: '#dddddd', style: ['bold', 'right']},
              ''
            ],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'AMIT cost base net amount (adjustments to the tax cost base of Units)', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Loan Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(p2pCapitalReturned.toString()), style: 'right'}, ''],
            [{text: 'Pooled Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(ipCapitalReturned.toString()), style: 'right'}, ''],
            [{text: 'Cash Units Class – Reduction in cost base (excess amount) **'}, {text: this._formatAmount(0), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: ['*', 80, 70],
          headerRows: 1,
          body: [
            [{text: 'Capital Gains', style: 'bold'}, {text: 'Amount', style: ['bold', 'right']}, ''],
            [{text: 'Estimate of current year capital losses'}, {text: this._formatAmount(investmentPortfolioSummary.p2p.capitalLost), style: 'right'}, ''],
          ]
        },
        layout: summaryTableLayout,
        fontSize: 10,
        style: 'margin1_5x'
      },
      {
        table: {
          widths: [12, '*'],
          body: [
            [{text: '*', style: 'bold'}, {text: 'Management fees include GST of 10%'}],
            [{text: '**', style: 'bold'}, {
              text: 'The reduction in the cost base of units has been calculated as the excess of cash distributions on units during the income year over ' +
                'the net assessable distributions on such units for that income year'
            }],
          ]
        },
        layout: {
          hLineWidth: (i, node) => i === node.table.body.length ? 0.5 : 0,
          vLineWidth: (i, node) => 0,
          hLineColor: (i, node) => i === node.table.body.length ? 'black' : null,
          vLineColor: (i, node) => null,
          paddingLeft: (i, node) => 10,
          paddingRight: (i, node) => 10,
          paddingTop: (i, node) => 0,
          paddingBottom: (i, node) => i === node.table.body.length - 1 ? 8 : 4,
          fillColor: (i, node) => null
        },
        fontSize: 8,
        style: 'margin1_5x'
      },
      {
        text: [
          {text: 'DISCLAIMER: ', style: 'bold'},
          'This statement is to assist you in preparing your ' + this._formatDate(toDate, 'D MMM YYYY') + ' tax return. Tax return label references are only applicable to individual tax returns. ' +
          'This document does not constitute tax advice. For further information refer to the PDS. To the extent permitted by law, no liability is accepted for any loss or damage as a result ' +
          'of reliance on this information. TruePillars does not guarantee the repayment of capital, payment of income or the Trust’s performance.'
        ],
        fontSize: 6,
        style: 'margin1x'
      },
      this._growTogetherTagLine()
    ])
  }


  // ****** (2022) Taxation Summary Report

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2022TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    const mergeModel = this._createMergeModel(fromDate, toDate, investmentPortfolioSummary)
    const tokenProcessor = makeTokenProcessorUsingDSL(mergeModel, this.$filter, this.logger)
    const financialYear = this._formatDate(toDate, 'YYYY')

    resolveAmitReportContent(financialYear, this.contentful, this.logger, (reportParts) => {
      const partProcessor = (partDef, tokenProcessor, logger) => {
        const type = resolveProperty(partDef, 'type')
        if (type === 'text') {
          return jsonToPdfMakeText(partDef, tokenProcessor, logger)
        } else if (type === 'table') {
          return jsonToPdfMakeTable(partDef, tokenProcessor, logger)
        }
      }

      const reportContentBody = []
      reportParts.forEach((part) => {
        const partDefinition = resolveProperty(part, 'data')
        // FIXME : Pass in the name of the part to assist with reporting.
        const processedPart = partProcessor(partDefinition, tokenProcessor, this.logger)
        reportContentBody.push(processedPart)
      })

      const reportContentPreamble = [
        this._logoSection(),
        this._reportTitleSection(this.brandContent.trustName),
        this._ownerDetailsSection(investor, 'AMIT member annual (AMMA) statement for the income year ending ' + this._formatDate(toDate, 'D MMM YYYY')),
        {
          text: 'PLEASE RETAIN THIS STATEMENT FOR INCOME TAX PURPOSES',
          fontSize: 12,
          color: 'red',
          style: ['bold', 'center', 'margin1_5x']
        },
        this._investorDetailsSection(investor),
        {
          text: 'Taxation information summary',
          fontSize: 12,
          style: ['bold', 'margin1x']
        }
      ]
      const reportContent = reportContentPreamble.concat(reportContentBody)
      this._createAndDownloadReport(`AMIT-annual-statement-FY${financialYear}`, reportContent)
    })
  }


  // ****** (2023) Taxation Summary Report

  _createAndDownload2023TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    this._createAndDownload2022TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary)
  }

  // noinspection JSUnusedGlobalSymbols
  _createAndDownload2024TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary) {
    this._createAndDownload2023TaxationSummaryReport(investor, fromDate, toDate, investmentPortfolioSummary)
  }

  /* ****** Utility functions. */

  _logoSection() {
    return {
      image: 'brandLogo', height: 25, width: 150
    }
  }

  _growTogetherTagLine() {
    return {
      text: 'Grow together: ',
      fontSize: 8,
      style: ['right'],
      color: this.brandContent.styling.colourAccent
    }
  }

  _reportTitleSection(title) {
    return {
      text: title,
      fontSize: 16,
      color: this.brandContent.styling.colourPrimary,
      style: ['bold', 'center', 'margin2x']
    }
  }

  _ownerDetailsSection(investor, subTitle) {
    return {
      columns: [
        {
          width: '55%',
          stack: this._ownerNameAndAddress(investor)
        },
        {
          width: '*',
          text: subTitle,
          color: this.brandContent.styling.colourPrimary,
        }
      ],
      columnGap: 20,
      fontSize: 12,
      style: 'margin2x'
    }
  }

  _investorDetailsSection(investor) {
    return {
      table: {
        widths: [160, '*'],
        body: [
          [{text: 'Investor name', style: 'bold'}, {text: investor.name}],
          [{text: 'Customer reference number', style: 'bold'}, {text: investor.bpay.crn}]
        ]
      },
      layout: {
        hLineWidth: (i, node) => (i === 0 || i === node.table.body.length) ? 1 : 0,
        vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length) ? 1 : 0,
        hLineColor: (i, node) => (i === 0 || i === node.table.body.length) ? this.brandContent.styling.colourPrimary : null,
        vLineColor: (i, node) => (i === 0 || i === node.table.widths.length) ? this.brandContent.styling.colourPrimary : null,
        paddingLeft: (i, node) => 10,
        paddingRight: (i, node) => 10,
        paddingTop: (i, node) => i === 0 ? 12 : 4,
        paddingBottom: (i, node) => i === node.table.body.length - 1 ? 12 : 4,
        fillColor: (i, node) => null
      },
      fontSize: 12,
      style: 'margin1x'
    }
  }

  _createAndDownloadReport(name, content) {
    const definition = {
      info: {
        title: name,
        author: this.brandContent.author
      },
      pageMargins: [30, 20, 30, 50],
      footer: this.brandContent.footer,
      content: content,
      styles: {
        bold: {
          bold: 'true',
        },
        right: {
          alignment: 'right',
        },
        center: {
          alignment: 'center',
        },
        margin1x: {
          margin: [0, 10, 0, 0]
        },
        margin1_5x: {
          margin: [0, 15, 0, 0]
        },
        margin2x: {
          margin: [0, 20, 0, 0]
        },
        margin3x: {
          margin: [0, 30, 0, 0]
        }
      },
      images: {
        brandLogo: `${this.brandContent.logoUrl}?fm=jpg&h=250`
      }
    }
    this.pdfService.createAndDownload(name + '.pdf', definition)
  }

  _ownerNameAndAddress(investor) {
    const ownerSummary = investor.ownerSummary
    const lines = [
      {text: [ownerSummary.firstName, ownerSummary.lastName].join(' ').trim()},
    ]
    if (ownerSummary.canonicalAddress && ownerSummary.canonicalAddress.fullAddress) {
      ownerSummary.canonicalAddress.fullAddress.split(',').forEach(addressLine => {
        lines.push({text: addressLine.trim()})
      })
    }
    return lines
  }

  _negative(amount) {
    return new Big(amount).times(-1.0).toString()
  }

  _formatAmount(amount) {
    return this.$filter('asCurrency')(amount)
  }

  _asMoment(fromDate) {
    return moment(fromDate).locale('en')
  }

  _formatDate(fromDate, format = 'YYYY-MM-DD') {
    return this._asMoment(fromDate).format(format)
  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }
  
  _createMergeModel(fromDate, toDate, investmentPortfolioSummary) {
    const mergeModel = new Map()
    mergeModel.set('ipCapitalReturned', new Big(investmentPortfolioSummary.ip.capitalReturned).round(2, 0))
    mergeModel.set('ipInterestReceived', new Big(investmentPortfolioSummary.ip.interestReceived).round(2, 0))
    mergeModel.set('ipOpeningAccruedInterest', new Big(investmentPortfolioSummary.ip.openingAccruedInterest).round(2, 0))
    mergeModel.set('ipInterestDistributed', mergeModel.get('ipInterestReceived').minus(mergeModel.get('ipOpeningAccruedInterest')))
    mergeModel.set('ipClosingAccruedInterest', new Big(investmentPortfolioSummary.ip.closingAccruedInterest).round(2, 0))
    mergeModel.set('ipFeesPaid', new Big(investmentPortfolioSummary.ip.feesPaid).round(2, 0))
    mergeModel.set('ipTaxWithheld', new Big(investmentPortfolioSummary.ip.taxWithheld).round(2, 0))
    mergeModel.set('p2pCapitalReturned', new Big(investmentPortfolioSummary.p2p.capitalReturned).round(2, 0))
    mergeModel.set('p2pInterestReceived', new Big(investmentPortfolioSummary.p2p.interestReceived).round(2, 0))
    mergeModel.set('p2pFeesPaid', new Big(investmentPortfolioSummary.p2p.feesPaid).round(2, 0))
    mergeModel.set('p2pTaxWithheld', new Big(investmentPortfolioSummary.p2p.taxWithheld).round(2, 0))
    mergeModel.set('capitalReturned', mergeModel.get('p2pCapitalReturned').plus(mergeModel.get('ipCapitalReturned')))
    mergeModel.set('interestReceived', mergeModel.get('p2pInterestReceived')
      .plus(mergeModel.get('ipInterestReceived'))
      .minus(mergeModel.get('ipOpeningAccruedInterest'))
      .plus(mergeModel.get('ipClosingAccruedInterest')))
    mergeModel.set('totalDistributions', mergeModel.get('capitalReturned').plus(mergeModel.get('interestReceived')))
    mergeModel.set('feesPaid', mergeModel.get('p2pFeesPaid').plus(mergeModel.get('ipFeesPaid')))
    mergeModel.set('taxWithheld', mergeModel.get('p2pTaxWithheld').plus(mergeModel.get('ipTaxWithheld')))
    mergeModel.set('totalDeductions', mergeModel.get('feesPaid').plus(mergeModel.get('taxWithheld')))
    mergeModel.set('netCashDistribution', mergeModel.get('totalDistributions').minus(mergeModel.get('totalDeductions')))
    mergeModel.set('capitalLost', new Big(investmentPortfolioSummary.p2p.capitalLost).round(2, 0))

    mergeModel.set('dateTo', this._asMoment(toDate))
    mergeModel.set('dateFrom', this._asMoment(fromDate))
    
    return mergeModel
  }
}

export default InvestorReportService
