/* Injector wrapper to make testing easier - that's all. */

class AudioService {
  /*@ngInject*/
  constructor() {
  }

  makeAudio(source) {
    return new Audio(source)
  }
}

export default AudioService

