import toastTemplate from './toastTemplate.pug'

class AttachmentUploadController {
  /*@ngInject*/
  constructor(uploadService, attachmentsRepository, $mdToast, $mdDialog, $timeout, $window, $q, $sce) {
    this.name = 'attachmentUpload'
    this.loading = false

    this.uploadService = uploadService
    this.attachmentsRepository = attachmentsRepository
    this.$mdToast = $mdToast
    this.$mdDialog = $mdDialog
    this.$timeout = $timeout
    this.$window = $window
    this.$q = $q
    this.$sce = $sce

    this.attachments = []
  }

  get ariaRequiredLabel() {
    return this.isRequired() ? 'Upload required' : 'Upload not required'
  }

  $onInit() {
    this.maxAttachments = this.maxAttachments ? parseInt(this.maxAttachments, 10) : 1
    if (this._shouldLoad()) {
      this._loadAttachments()
    }
    if (!this.hasOwnProperty('attachmentLinks') || this.attachmentLinks === undefined) {
      this.attachmentLinks = []
    }
  }

  isRequired() {
    return this.ngRequired
  }

  selectFile(file) {
    if (file) {
      this._startLoading('Uploading')
      this.uploadService.upload(file)
        .then((metadata) => {
          this._startLoading('Saving')
          return this._createAttachment(metadata)
        })
        .then((attachment) => {
          this._addAttachment(attachment)
          this._notifyChange({added: attachment})
        })
        .catch((err) => {
          if (this.ngRequired) {
            this._showMessage('There was an error uploading your file. You can try again now or email it to <a href="mailto:contact@truepillars.com">contact@truepillars.com</a>')
            this.ngRequired = false
          } else {
            this._showMessage('There was an error uploading your file. Please try again.')
          }
          this._tellNewRelic('File upload failed')
        })
        .finally(() => this._stopLoading())
    }
  }

  delete(attachment) {
    this._confirmDeletion(attachment).then(() => this._performDeletion(attachment))
    return false
  }

  canUpload() {
    return !this.loading && this.numberOfUploads < this.maxAttachments
  }

  get numberOfUploads() {
    return this.attachmentLinks ? this.attachmentLinks.length : 0
  }

  // private

  _confirmDeletion({name}) {
    return this.$mdDialog.show(
      this.$mdDialog.confirm().textContent(`Are you sure you want to delete "${name}"?`)
        .clickOutsideToClose(true)
        .title(`Delete "${name}"?`)
        .ariaLabel('Delete file?')
        .ok('Delete')
        .cancel('Cancel')
    )
  }

  _performDeletion(attachment) {
    this._startLoading('Deleting')
    attachment.delete().then(() => {
      this._removeAttachment(attachment)
      this._notifyChange({removed: attachment})
      this._stopLoading()
    }).catch(() => {
      this._stopLoading()
      this._showMessage('There was an error deleting your file. Please try again.')
    })
  }

  _shouldLoad() {
    return this.attachmentsRepository && (this.numberOfUploads > 0)
  }

  _loadAttachments() {
    this._startLoading()
    this.$q.all(this.attachmentLinks.map((attachmentLink) => {
      return this.attachmentsRepository.getByHref(attachmentLink).then((attachment) => this._addAttachment(attachment))
    })).catch(() => {
      this._showMessage('There was an error loading your attachments. Please try again.')
    }).finally(() => {
      this._stopLoading()
    })
  }

  _createAttachment(metadata) {
    return this.attachmentsRepository.create({
      attachedTo: this.attachTo ? this.attachTo.self.href : 'none',
      metadata: metadata,
    })
  }

  _removeAttachment(attachment) {
    this.attachments.splice(this.attachments.indexOf(attachment), 1)
    this.attachmentLinks.splice(this.attachmentLinks.indexOf(attachment.self.href), 1)
  }

  _addAttachment(attachment) {
    this.attachments.push(attachment)
    const href = attachment.self.href
    if (this.attachmentLinks.indexOf(href) === -1) {
      this.attachmentLinks.push(href)
    }
  }

  _showMessage(message) {
    this.$mdToast.show(this._toastFor(message))
  }

  _startLoading(text = 'Loading') {
    this.loading = true
    this.loadingText = text
  }

  _stopLoading() {
    this.loading = false
  }

  _notifyChange({added, removed} = {}) {
    if (this.onChange) {
      this.$timeout(() => {
        this.onChange({added, removed, current: this.attachments})
      })
    }
  }

  _tellNewRelic(message) {
    if (this.$window.NREUM) {
      this.$window.NREUM.noticeError(new Error(message))
    }
  }

  _toastFor(message) {
    return {
      hideDelay: 30000,
      position: 'top left',
      controllerAs: 'vm',
      controller: () => ({
        message: this.$sce.trustAsHtml(message),
        closeToast: () => {
          this.$mdToast.hide('ok')
        }
      }),
      template: toastTemplate({})
    }
  }
}

export default AttachmentUploadController
