class UserService {
  /*@ngInject*/
  constructor($window, $q, $mdDialog, usersRepository, localStorageService, firebaseLoginManager, notifications, authService, emailVerificationsRepository, applicationSettingsService) {
    this.$window = $window
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.usersRepository = usersRepository
    this.localStorageService = localStorageService
    this.firebaseLoginManager = firebaseLoginManager
    this.notifications = notifications
    this.authService = authService
    this.emailVerificationsRepository = emailVerificationsRepository
    this.applicationSettingsService = applicationSettingsService

    this._blockingMessages = []
  }

  get currentUserPromise() {
    return this._loadUser()
  }

  getCurrentUser() {
    return this.currentUser
  }

  login(customTitle, username = undefined) {
    if (this._disabledAuthentication()) {
      return this.$q.reject('cannot log in!')
    }
    this.signupInProgress = false
    return this.authService.login(customTitle, username).then(() => this._loadUser())
  }

  signup(email) {
    if (this._disabledAuthentication()) {
      return this.$q.reject('can not signup!')
    }
    if(this.isLoggedIn()) {
      return this._loadUser()
    }
    this.signupInProgress = true
    return this.authService.signup(email).then((userId) => {
      return this.usersRepository.create({userId: userId}).then((user) => {
        this.signupInProgress = false
        return this._setCurrentUser(user)
      })
    })
  }

  signupUser() {
    if (this._disabledAuthentication()) {
      return this.$q.reject('can not sign up!')
    }
    if (!this.isAdmin()) {
      return this.$q.reject('can not signup another user!')
    }
    this.signupInProgress = true
    return this.authService.signup().then((userId) => {
      return this.usersRepository.create({userId: userId}).then((user) => {
        this.signupInProgress = false
        return user
      })
    })
  }

  renewToken() {
    return this.authService.renewToken()
  }

  logout() {
    this._preferredName = null
    this._loadingUser = null
    this._blockingMessages = []
    this.currentUser = null
    this.firebaseLoginManager.user = null
    this.notifications.user = null
    this.signupInProgress = false
    return this.authService.logout()
  }

  isLoggedIn() {
    return this.authService.isLoggedIn() && !this.signupInProgress
  }

  isAdmin() {
    return this.currentUser && this.hasRole('creditAssessor', 'bdm', 'supervisor')
  }

  isCreditAssessor() {
    return this.currentUser && this.hasRole('creditAssessor')
  }

  isBusinessDevelopmentManager() {
    return this.currentUser && this.hasRole('bdm')
  }

  isSupervisor() {
    return this.currentUser && this.hasRole('supervisor')
  }

  isIdVerified() {
    return this.currentUser && this.currentUser.identityCheck && this.currentUser.identityCheck.status === 'verified'
  }

  isBiometricIdVerified() {
    return this.currentUser && this.currentUser.biometricIdentityCheck && this.currentUser.biometricIdentityCheck.status === 'verified'
  }

  isBiometricIdIncomplete() {
    return this.currentUser && this.currentUser.biometricIdentityCheck && ['requested', 'retry'].includes(this.currentUser.biometricIdentityCheck.status)
  }

  isBiometricIdBeingReviewed() {
    return this.currentUser && this.currentUser.biometricIdentityCheck && this.currentUser.biometricIdentityCheck.status === 'review'
  }

  isBiometricIdRejected() {
    return this.currentUser && this.currentUser.biometricIdentityCheck && this.currentUser.biometricIdentityCheck.status === 'rejected'
  }

  isEmailVerified() {
    return this.currentUser && this.currentUser.emailVerified
  }

  setEmailVerified(value) {
    if (this.currentUser) {
      this.currentUser.emailVerified = value
    }
  }

  hasValidToken() {
    return this.authService.hasValidToken()
  }

  hasRole(...rolesRequired) {
    return rolesRequired.some(role => this.currentUser && this.currentUser.roles && this.currentUser.roles[role])
  }

  isRegistered(user) {
    user = user || this.currentUser
    return user && user.identityCheck && user.identityCheck.status !== 'pending'
  }

  name() {
    return this.currentUser && [this.currentUser.firstName, this.currentUser.lastName].join(' ').trim()
  }

  fullName() {
    return this.currentUser && [this.currentUser.firstName, this.currentUser.middleName, this.currentUser.lastName].join(' ').trim()
  }

  email() {
    return this.currentUser && this.currentUser.email
  }

  phone() {
    return this.currentUser && this.currentUser.phone
  }

  get preferredName() {
    if (!this.currentUser) {
      return null
    }
    if (this._preferredName) {
      return this._preferredName
    }
    if (this.currentUser.setting && this.currentUser.setting.preferredName) {
      this._preferredName = this.currentUser.setting.preferredName
    } else if (this.currentUser.firstName && this.currentUser.lastName) {
      this._preferredName = [this.currentUser.firstName, this.currentUser.lastName].join(' ').trim()
    } else {
      this._preferredName = this.currentUser.email
    }
    return this._preferredName
  }

  set preferredName(name) {
    this._preferredName = name

    if (!this.currentUser.setting) {
      this.currentUser.setting = {}
    }
    this.currentUser.setting.preferredName = name
    this.currentUser.save()
  }

  getBlockingMessages() {
    return this._blockingMessages
  }

  appendBlockingMessage(message) {
    this._blockingMessages.push(message)
  }

  // private

  _disabledAuthentication(authenticationType) {
    if (this.applicationSettingsService.setting('enableMaintenanceMode')) {
      this._showMaintenanceMessage()
      return true
    }
    if(!this._isLocalStorageOrCookiesAvailable()) {
      this._showCookiesRequiredMessage()
      return true
    }
    return false
  }

  _isLocalStorageOrCookiesAvailable() {
    return this.localStorageService.isSupported || this.localStorageService.cookie.isSupported
  }

  _showMaintenanceMessage() {
    this.$window.alert(`Sorry for the inconvenience, but we're performing some maintenance at the moment. We'll be back online shortly!`)
  }

  _showCookiesRequiredMessage() {
    this.$window.alert('The TruePillars site requires cookies.')
  }

  _loadUser() {
    const userId = this.authService.getLoggedInUserId()
    if (userId) {
      if (!(this._loadingUser && userId === this._loadingUser.id)) {
        this._loadingUser = this.usersRepository.getById(userId).then((user) => {
          let rv = this._setCurrentUser(user)
          delete(this._loadingUser)
          this._defeatBorrowers(user)
          return rv
        })
        this._loadingUser.id = userId
      }
      return this._loadingUser
    } else {
      return this.$q.reject('not logged in!')
    }
  }

  _defeatBorrowers(user) {
    user.promise('borrowers').then((borrowers) => {
      if (borrowers && borrowers.length > 0) {
        this.$mdDialog.show(this.$mdDialog.confirm()
          .title('TruePillars borrowers')
          .htmlContent(
            '<md-dialog>' +
            'This facility is no longer available for online access. If you would like to discuss your loan please ' +
            '<a ui-sref="contactUs" href="/contact-us" target="_blank">contact us</a>' +
            '</md-dialog>'
          )
          .clickOutsideToClose(true)
          .escapeToClose(true)
          .ok('OK'))
        this._tellNewRelic(user.email)
        this.logout()
      }
    })
  }

  _tellNewRelic(message) {
    if (this.$window.NREUM) {
      this.$window.NREUM.noticeError(new Error('Borrower login disallowed: ' + message))
    }
  }

  _setCurrentUser(user) {
    delete this._preferredName
    if(!this.currentUser || this.currentUser.id !== user.id) {
      this._changedUser(user)
    }
    this.currentUser = user
    return this.currentUser
  }

  _changedUser(user) {
    this._updateCurrentEmail(user, this.authService.getLoggedInUserEmail())
    this._verifyUserEmail(user)
    this.firebaseLoginManager.user = user
    this.notifications.user = user
    this._tellGoogleAnalytics(user)
  }

  _updateCurrentEmail(user, email) {
    if (user.email !== email) {
      user.email = email
      user.save()
    }
  }

  _tellGoogleAnalytics(user) {
    if (this.$window && this.$window.ga && user && user.id) {
      this.$window.ga('set', 'userId', user.id)
    }
  }

  _verifyUserEmail(user) {
    if (user && !user.emailVerified) {
      this.emailVerificationsRepository.create().then((result) => {
        user.emailVerified = result.user.emailVerified
      })
    }
  }
}

export default UserService
