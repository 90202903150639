import isDefined from './isDefined'
import Big from 'big.js'

const BIG_ZERO = new Big(0)


const isEmpty = (candidate) => isDefined(candidate) &&
  ((Array.isArray(candidate) && candidate.length === 0) ||
  (candidate.toString().trim().length === 0) ||
  (candidate instanceof Big && (new Big(candidate).eq(BIG_ZERO))))

export default isEmpty
