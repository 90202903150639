class EmailVerifiedController {
  /*@ngInject*/
  constructor(emailVerificationsRepository, userService, loginRouter) {
    this.name = 'email-verified'
    this.emailVerificationsRepository = emailVerificationsRepository
    this.loginRouter = loginRouter

    this.$onInit = () => {
      this.emailVerificationsRepository.create().then((result) => {
        this.status = 'success'
        userService.setEmailVerified(result.user.emailVerified)
      }).catch(() => {
        this.status = 'failed'
      })
    }
  }

  success() {
    return this.status === 'success'
  }

  error() {
    return this.status === 'failed'
  }

  gotoDashboard() {
    this.loginRouter.gotoDashboard({reload: true})
  }
}

export default EmailVerifiedController
