import createEnum from '../../../../../utils/createEnum'

const CONTENT_DISPOSITIONS = createEnum('PENDING', 'RAW', 'DOCUMENT', 'ASIC_EXTRACT', 'FAILED')

class ReportDialogController {
  /*@ngInject*/
  constructor($q, $mdDialog, $sce, report, formatter, creditReportsRepository) {
    this.name = 'report-dialog'
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.report = report
    this.contentDisposition = CONTENT_DISPOSITIONS.PENDING

    const INORDER_CONTENT_TYPE = [
      {type: CONTENT_DISPOSITIONS.ASIC_EXTRACT, predicate: () => this.reportContent && !this.reportDocument && formatter === 'asic-extract'},
      {type: CONTENT_DISPOSITIONS.DOCUMENT, predicate: () => this.reportDocument && !formatter},
      {type: CONTENT_DISPOSITIONS.RAW, predicate: () => true}
    ]

    this.$onInit = () => {
      creditReportsRepository.getById(report.id).then(result => {
        this.reportDocument = $sce.trustAsHtml(result.document)
        this.reportContent = result.content
        this.productName = this.reportContent && this.reportContent.header && this.reportContent.header.product_name
        this.contentDisposition = INORDER_CONTENT_TYPE.find(contentType => contentType.predicate()).type
      }).catch((error) => {
        this.contentDisposition = CONTENT_DISPOSITIONS.FAILED
      })
    }
  }

  get heading() {
    return this.productName ? `Report: ${this.productName}` : (this.report && this.report.type ? `Report: ${this.report.type}` : 'Report')
  }

  actionClose() {
    return this.$mdDialog.cancel()
  }
}

export default ReportDialogController
