import resolveProperty from '../../../../utils/resolveProperty'

class ReviewInvestorApplicationController {
  /*@ngInject*/
  constructor(greenidVerifyService) {
    this.name = 'review-investor-application'
    this.greenidVerifyService = greenidVerifyService

    this.investorTransitions = ['verify', 'reject']
  }

  get application() {
    return this.investorApplication && this.investorApplication.application
  }

  get applicant() {
    return this.investorApplication && this.investorApplication.applicant
  }

  get investor() {
    return this.investorApplication && this.investorApplication.investor
  }

  get uploads() {
    return this.investorApplication && this.investorApplication.uploads
  }

  get applicationJson() {
    if (!this._applicationJson) {
      if (this.application) {
        this._applicationJson = this._representApplication(this.application)
      }
    }
    return this._applicationJson
  }

  get applicantJson() {
    if (!this._applicantJson) {
      if (this.applicant) {
        this._applicantJson = this._representApplicant(this.applicant)
      }
    }
    return this._applicantJson
  }

  get investorJson() {
    if (!this._investorJson) {
      if (this.investor && this.investor.investorSetting && this.investor.investmentPortfolioSummary) {
        this._investorJson = this._representInvestor(this.investor)
      }
    }
    return this._investorJson
  }

  get investorBidsJson() {
    if (!this._investorBidsJson) {
      if (this.investor && this.investor.bids) {
        this._investorBidsJson = this.investor.bids
          .reduce((map, bid) => {
            map[bid.auctionId + '.' + bid.id] = bid.toJson()
            return map
          }, {})
      }
    }
    return this._investorBidsJson
  }

  get investorLoanPartsJson() {
    if (!this._investorLoanPartsJson) {
      if (this.investor && this.investor.loanParts) {
        this._investorLoanPartsJson = this.investor.loanParts
          .sort((a, b) => a.loanRequestSummary.reference.localeCompare(b.loanRequestSummary.reference))
          .reduce((map, loanPart) => {
            const json = loanPart.toJson()
            delete json.investorSummary
            map[loanPart.loanRequestSummary.reference + '.' + loanPart.id] = json
            return map
          }, {})
      }
    }
    return this._investorLoanPartsJson
  }

  updateApplicantIdentityCheckStatus() {
    this.updatingApplicantStatus = true
    this.greenidVerifyService.updateStatus(this.applicant.identityCheck.id).then(() => {
      this.applicant.reload().then(() => {
        delete this._applicantJson
      })
    }).finally(() => {
      this.updatingApplicantStatus = false
    })
  }

  verifyInvestor() {
    this._setInvestorStatusEvent('verify')
  }

  rejectInvestor() {
    this._setInvestorStatusEvent('reject')
  }

  get canWelcome() {
    return this.investor && this.investor.status && ['accepted', 'verified'].includes(this.investor.status) &&
      resolveProperty(this.investor, 'owner', 'identityCheck', 'status') === 'verified'
  }

  resendWelcome() {
    this.disableWhileSendWelcomeInProgress = true
    this.investor.performAction('welcome', {}).then(() => this.disableWhileSendWelcomeInProgress = false)
  }

  // ****** private.

  _setInvestorStatusEvent(event) {
    this.investor.statusEvent = event
    this.submitting = true
    return this.investor.save()
      .finally(() => {
        this.submitting = false
      })
  }

  _representApplicant(applicant) {
    let json = applicant.toJson()
    delete json.firebaseToken
    delete json.investors
    delete json.borrowers
    if (json.canonicalAddress) {
      json.address = json.canonicalAddress.fullAddress
    }
    delete json.canonicalAddress
    delete json.roles
    delete json.currentInvestorApplication
    return json
  }

  _representInvestor(investor) {
    const json = investor.toJson()
    delete json.loanParts
    return json
  }

  _representApplication(investorApplication) {
    let json = JSON.parse(JSON.stringify(investorApplication))
    if (this.uploads) {
      for (let upload of this.uploads) {
        this._setValueAtPath(json, upload.key, upload.attachments.map((attachment) => attachment.name + ' (SEE UPLOADS)'))
      }
    }
    return json
  }

  _setValueAtPath(obj, path, value) {
    let indexOfFirstDot = path.indexOf('.')
    if (indexOfFirstDot === -1) {
      obj[path] = value
    } else {
      this._setValueAtPath(obj[path.substring(0, indexOfFirstDot)], path.substring(indexOfFirstDot + 1), value)
    }
  }

}

export default ReviewInvestorApplicationController
