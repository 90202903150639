import angular from 'angular'

import formWidgetComponent from './formWidget.component'

export default angular.module('app.common.dynamicForm.widgets.formWidget', [])
  .directive('formWidget', formWidgetComponent)
  .directive('ruleValidate', function() {
    return {
      restrict: 'A',
      require: ['ngModel', '^^formWidget'],
      link: function(scope, elem, attr, ctrls) {
        ctrls[1].widget.formModel = ctrls[0]
      }
    }
  })
