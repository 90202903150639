import template from './assessment.pug'
import controller from './assessment.controller.js'
import './assessment.scss'
import './overrideDialog/overrideDialog.scss'
import './reportDialog/reportDialog.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      loanEnquiry: '=',
      resourceCache: '='
    }
  }
}
