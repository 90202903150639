import Bidder from './bidder'

class BidWidgetController {
  /*@ngInject*/
  constructor(bidsRepository) {
    this.bidsRepository = bidsRepository
    this.status = 'pre-confirmation'

    this.$onInit = () => {
      this.bidder = new Bidder(this.investor, this.auction)
      this.gotoEnterBidScreen()
    }
  }

  closeBidContainer() {
    this.complete()
  }

  gotoEnterBidScreen() {
    this.currentScreen = 'enter-bid'
  }

  gotoConfirmBidScreen() {
    this.currentScreen = 'confirm-bid'
  }

  gotoBidResultScreen() {
    this.currentScreen = 'bid-result'
  }

  confirm() {
    this.status = 'bid-in-progress'
    return this.bidsRepository.create({
      investorId: this.investor.id,
      auctionId: this.auction.id,
      minRate: this.bidder.borrowerMinRate.toFixed(1),
      maxRate: this.bidder.borrowerMaxRate.toFixed(1),
      amount: this.bidder.amount
    }).then((bid) => {
      this.createdBid = bid
      this.resultCode = bid.activeAmount === 0 ? 'error-outbid' : bid.amount !== bid.activeAmount ? 'success-partial' : 'success-total'
    }).catch((response) => {
      this.resultCode = this._getErrorCode(response)
    }).finally(() => {
      this.status = 'bid-complete'
      this.investor.reload()
      this.gotoBidResultScreen()
    })
  }

  _getErrorCode(response) {
    if (response && response.data && response.data.errors) {
      for (let check of [
        {code: 'AuctionEnded', result: 'error-auction-finished'},
        {code: 'AuctionNotStarted', result: 'error-auction-not-started'},
        {code: 'AmountInvalid', result: 'error-amount-range'},
        {code: 'RateTooLow', result: 'error-rate-range'},
        {code: 'RateTooHigh', result: 'error-rate-range'},
        {code: 'RateInvalid', result: 'error-rate-range'},
        {code: 'InsufficientFunds', result: 'error-insufficient-funds'},
      ]) {
        if (response.data.errors.find(error => error.code === check.code)) {
          return check.result
        }
      }
    }
    return 'error-unknown'
  }
}

export default BidWidgetController
