class InvestorsController {
  /*@ngInject*/
  constructor($scope, localStorageService, investorsRepository) {
    this.name = 'admin-investors'
    this.investorsRepository = investorsRepository
    this.order = 'ID'
    this.status = 'all'
    this.limitOptions = [5, 10, 20, 50, 100]
    this.total = 0

    localStorageService.bind($scope, 'vm.limit', 50, 'admin-investors.limit')

    this.investorStatuses = [
      {key: 'all', label: 'All'},
      {key: 'verified', label: 'Verified'},
      {key: 'accepted', label: 'Accepted'},
      {key: 'rejected', label: 'Rejected'}
    ]
    this.reload = () => {
      this.loading = true
      const conditions = {
        search: this.search,
        status: this.status,
        includeFunds: this.includeFunds,
        order: this.order,
        size: this.limit,
        page: this.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.investorsRepository.where(conditions)
        .then(data => {
          this.investors = data.investors
          this.total = data.total
        })
        .finally(() => this.loading = false)
    }
    this.$onInit = () => {
      this.performSearch()
    }
  }

  performSearch() {
    this.page = 1
    this.reload()
  }
}

export default InvestorsController
