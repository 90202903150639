import resolveProperty from '../../utils/resolveProperty'
import valueOrDefault from '../../utils/valueOrDefault'

class TenantInformationService {
  /*@ngInject*/
  constructor(contentful, loggerService) {
    this.contentful = contentful
    this.logger = loggerService.makeLogger()
    this._cachedData = undefined
  }

  getElement(elementId) {
    const elementPath = elementId.split('.')
    return this._dataStructure.then((data) => resolveProperty(data, ...elementPath))
  }

  getElements(...elementIds) {
    return this._dataStructure.then((data) => elementIds.map((elementId) => resolveProperty(data, ...elementId.split('.'))))
  }

  // private

  get _dataStructure() {
    return this._cachedData ? this._cachedData : (() => {
      this._cachedData = this.contentful.entries('content_type=genericJson&fields.name=tenant-information&limit=200&include=10')
        .then((entry) => valueOrDefault(resolveProperty(entry, 'data', 'items', 0, 'fields', 'data'), {}))
        .catch((error) => {
          this.logger.error('Failed to get tenant information.', error)
        })
      return this._cachedData
    })()
  }
}

export default TenantInformationService
