import template from './markdownEditor.pug'
import controller from './markdownEditor.controller'
import './markdownEditor.scss'

export default () => ({
  restrict: 'E',
  scope: {
    type: '@',
    inputLabel: '@',
    model: '=',
    saveHook: '='
  },
  controllerAs: 'vm',
  bindToController: true,
  controller,
  template: template({})
})
