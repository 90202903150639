import template from './editableDisplay.pug'
import controller from './editableDisplay.controller'
import './editableDisplay.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    transclude: true,
    scope: {},
    bindToController: {
      editable: '=',
      heading: '@',
      cancelAction: '=',
      saveAction: '=',
      saveLabel: '@',
      successfulSave: '='
    }
  }
}
