import filteredPanels from '../../../utils/filteredPanels'

class InvestorDashboardController {
  /*@ngInject*/
  constructor($scope, contentful, router, userService, entityContextService, tenantInformationService) {
    this.name = 'investor-dashboard'
    this.router = router

    this.nextStep = 'pending'

    let landingState
    $scope.$on('$stateChangeSuccess', (event, toState) => {
      if (toState.name.indexOf('investorDashboard') === 0) {
        landingState = toState
        if (this.tabs && this.tabs.length > 0) {
          this.navigateToTab(landingState.name)
        }
      }
    })

    this.tabs = []
    tenantInformationService.getElement('investorTabs').then((tabs) => {
      this.tabs = Object.entries(tabs).map((k) => ({'label': k[0], 'state': k[1]}))

      if (landingState) {
        this.navigateToTab(landingState.name)
        landingState = null
      }
    })

    this.panels = new Map()

    this.$onInit = () => {
      filteredPanels(contentful,
        'investorDashboard-registrationNextSteps-',
        (resultMap) => this.panels = resultMap)
      entityContextService.currentInvestor().then((investor) => {
        this.investorName = investor.name

        if (!userService.isIdVerified() ||
          userService.isBiometricIdBeingReviewed() ||
          userService.isBiometricIdRejected()) {
          this.nextStep = 'review'
        } else if (userService.isBiometricIdIncomplete()) {
          this.nextStep = 'biometricIdIncomplete'
        } else if (entityContextService.isAccepted()) {
          if (entityContextService.isFinancial()) {
            this.nextStep = 'none'
          } else {
            this.nextStep = 'unfunded'
          }
        } else {
          this.nextStep = 'review'
        }
      })
    }
  }

  get requiresNextSteps() {
    return this.nextStep !== 'none' && this.nextStep !== 'pending'
  }

  get nextStepResolved() {
    return this.nextStep !== 'pending'
  }

  get investorRegistrationNextStep() {
    return this.nextStep
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }

  getPanel(name) {
    const panelEntry = this.panels.get(name)
    return panelEntry ? panelEntry.panel : undefined
  }

  navigateToTab(state) {
    let indexOfState = this.tabs.findIndex(tab => tab.state === state)
    if (indexOfState >= 0) {
      this.selectedIndex = indexOfState
    }
  }
}

export default InvestorDashboardController
