class LoanRequestsController {
  /*@ngInject*/
  constructor($rootScope, $scope, $q, $mdDialog, localStorageService, loanRequestsRepository) {
    this.name = 'pending-applications'
    this.$rootScope = $rootScope
    this.$q = $q
    this.$mdDialog = $mdDialog
    this.loanRequestsRepository = loanRequestsRepository

    this.order = '-ID'
    this.limitOptions = [5, 10, 20, 50, 100]
    this.total = 0
    this.loanRequestStatuses = [
      {key: 'all', label: 'All'},
      {key: 'pending', label: 'Pending'},
      {key: 'scheduled', label: 'Scheduled'},
      {key: 'approved', label: 'Approved'},
      {key: 'fulfilled', label: 'Fulfilled'},
      {key: 'cancelled', label: 'Cancelled'},
    ]
    localStorageService.bind($scope, 'vm.limit', 50, 'loan-requests.limit')
    localStorageService.bind($scope, 'vm.status', 'all', 'loan-requests.status')

    this.reload = () => {
      this.loading = true
      const conditions = {
        status: this.status,
        search: this.search,
        order: this.order,
        size: this.limit,
        page: this.page
      }
      this.loadingPromise = this.loanRequestsRepository.where(conditions)
        .then(data => {
          this.total = data.total
          this.loanRequests = data.loanRequests
        })
        .finally(() => this.loading = false)
    }

    this.$onInit = () => {
      this.performSearch()
    }
  }

  performSearch() {
    this.page = 1
    this.reload()
  }

  filter() {
    return (value) => this.status === 'all' || value.status === this.status
  }

  createLoanRequest($event) {
    const template = `
      <md-dialog flex="50">
        <md-dialog-content layout-padding>
          <admin-create-loan-request completion='completion'/>
        </md-dialog-content>
      </md-dialog>
    `
    this._showDialog($event, template)
  }

  _showDialog($event, template) {
    const scope = this.$rootScope.$new(false)
    scope.completion = this.$q.defer()
    scope.completion.promise
      .then(() => this.performSearch())
      .finally(() => this.$mdDialog.hide())
    this.$mdDialog.show({
      parent: angular.element(document.body),
      targetEvent: $event,
      template: template,
      scope
    })
  }
}

export default LoanRequestsController
