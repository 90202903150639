import template from './representative.pug'
import controller from './representative.controller'
import './representative.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      representative: '=',
      showAppointer: '@',
      businessType: '@',
    }
  }
}
