import angular from 'angular'

import apiModule from './api/api'

export default angular.module('app.repositories', [apiModule.name])
  .service('attachmentsRepository', ['api', api => {
    return api.repository('attachments')
  }])
  .service('auctionsRepository', ['api', api => {
    return api.repository('auctions')
  }])
  .service('australianBusinessesRepository', ['api', api => {
    return api.repository('australianBusinesses')
  }])
  .service('australianCompaniesRepository', ['api', api => {
    return api.repository('australianCompanies')
  }])
  .service('authenticationsRepository', ['api', api => {
    return api.repository('authentications')
  }])
  .service('bankDetailsRepository', ['api', api => {
    return api.repository('bankDetails')
  }])
  .service('bidsRepository', ['api', api => {
    return api.repository('bids')
  }])
  .service('biometricIdentityChecksRepository', ['api', api => {
    return api.repository('biometricIdentityChecks')
  }])
  .service('biometricIdentityCheckStagesRepository', ['api', api => {
    return api.repository('biometricIdentityCheckStages')
  }])
  .service('borrowersRepository', ['api', api => {
    return api.repository('borrowers')
  }])
  .service('bpayUploadsRepository', ['api', api => {
    return api.repository('bpayUploads')
  }])
  .service('contactRequestsRepository', ['api', api => {
    return api.repository('contactRequests')
  }])
  .service('contactsRepository', ['api', api => {
    return api.repository('contacts')
  }])
  .service('creditReportsRepository', ['api', api => {
    return api.repository('creditReports')
  }])
  .service('emailVerificationsRepository', ['api', api => {
    return api.repository('emailVerifications')
  }])
  .service('identityChecksRepository', ['api', api => {
    return api.repository('identityChecks')
  }])
  .service('instructionsRepository', ['api', api => {
    return api.repository('instructions')
  }])
  .service('investmentProductsRepository', ['api', api => {
    return api.repository('investmentProducts')
  }])
  .service('investmentProductPartsRepository', ['api', api => {
    return api.repository('investmentProductParts')
  }])
  .service('investmentProductPledgeRequestsRepository', ['api', api => {
    return api.repository('investmentProductPledgeRequests')
  }])
  .service('investmentProductRedemptionRequestsRepository', ['api', api => {
    return api.repository('investmentProductRedemptionRequests')
  }])
  .service('investorApplicationsRepository', ['api', api => {
    return api.repository('investorApplications')
  }])
  .service('investorsRepository', ['api', api => {
    return api.repository('investors')
  }])
  .service('investmentEarningSummariesRepository', ['api', api => {
    return api.repository('investmentEarningSummaries')
  }])
  .service('investmentPortfolioSummariesRepository', ['api', api => {
    return api.repository('investmentPortfolioSummaries')
  }])
  .service('investorStatusChangesRepository', ['api', api => {
    return api.repository('investorStatusChanges')
  }])
  .service('investorTransactionDetailsRepository', ['api', api => {
    return api.repository('investorTransactionDetails')
  }])
  .service('journalEntriesRepository', ['api', api => {
    return api.repository('journalEntries')
  }])
  .service('listingsRepository', ['api', api => {
    return api.repository('listings')
  }])
  .service('listingPurchaseByCriteriaRepository', ['api', api => {
    return api.repository('listingPurchaseByCriteria')
  }])
  .service('loanEnquiriesRepository', ['api', api => {
    return api.repository('loanEnquiries')
  }])
  .service('loanEnquiryDetailsRepository', ['api', api => {
    return api.repository('loanEnquiryDetails')
  }])
  .service('loanRepaymentAbasRepository', ['api', api => {
    return api.repository('loanRepaymentAbas')
  }])
  .service('loanRequestsRepository', ['api', api => {
    return api.repository('loanRequests')
  }])
  .service('loanFeesRepository', ['api', api => {
    return api.repository('loanFees')
  }])
  .service('loanLossesRepository', ['api', api => {
    return api.repository('loanLosses')
  }])
  .service('loanRepaymentsRepository', ['api', api => {
    return api.repository('loanRepayments')
  }])
  .service('loanSchedulesRepository', ['api', api => {
    return api.repository('loanSchedules')
  }])
  .service('loansRepository', ['api', api => {
    return api.repository('loans')
  }])
  .service('partnersRepository', ['api', api => {
    return api.repository('partners')
  }])
  .service('partnerInvitationVerificationsRepository', ['api', api => {
    return api.repository('partnerInvitationVerifications')
  }])
  .service('partnerInvitationActivationsRepository', ['api', api => {
    return api.repository('partnerInvitationActivations')
  }])
  .service('reportsRepository', ['api', api => {
    return api.repository('reports')
  }])
  .service('representativesRepository', ['api', api => {
    return api.repository('representatives')
  }])
  .service('representativeInvitationsRepository', ['api', api => {
    return api.repository('representativeInvitations')
  }])
  .service('resendVerificationEmailsRepository', ['api', api => {
    return api.repository('resendVerificationEmails')
  }])
  .service('streetAddressesRepository', ['api', api => {
    return api.repository('streetAddresses')
  }])
  .service('usersRepository', ['api', api => {
    return api.repository('users')
  }])
  .service('withdrawalRequestsProcessingRepository', ['api', api => {
    return api.repository('withdrawalRequestsProcessing')
  }])
  .service('withdrawalRequestsRepository', ['api', api => {
    return api.repository('withdrawalRequests')
  }])
  .service('directDebitsRepository', ['api', api => {
    return api.repository('directDebits')
  }])
  .service('abasRepository', ['api', api => {
    return api.repository('abas')
  }])
  .service('fundingSourcesRepository', ['api', api => {
    return api.repository('fundingSources')
  }])
