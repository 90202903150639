class ManageFundsController {
  /*@ngInject*/
  constructor(userService, entityContextService) {
    this.name = 'manage-funds'
    this.user = userService.currentUser

    this.$onInit = () => {
      entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        this.investor.promise('users').then((users) => {
          this.isOwner = this.user && users.investorUsers.find((investorUser) => investorUser.role === 'owner' && investorUser.userId === this.user.id)
        })
      })
    }
  }

  get bpay() {
    return this.investor && this.investor.bpay
  }

  get email() {
    return this.user && this.user.email
  }
}

export default ManageFundsController
