class ApplicationRouter {
  constructor(router, $state, $stateParams, applicationConfig) {
    this.router = router
    this.$state = $state
    this.id = $stateParams.id
    this.steps = applicationConfig.steps
    this.mainRoute = applicationConfig.mainRoute
    this.applicationFinishedAddr = applicationConfig.applicationFinished

    this.registrationStep = applicationConfig.steps[applicationConfig.registrationIndex]
    this.consentStep = applicationConfig.steps[applicationConfig.consentIndex]
    this.applicationSteps = applicationConfig.steps.filter(step => step !== this.registrationStep && step !== this.consentStep)
  }

  goToDefaultRoute() {
    if (this.$state.current.name === this.mainRoute) {
      if (this.steps && this.steps[0]) {
        this._goToStep(this.steps[0], {id: this.id})
      }
    }
  }

  goToFirstApplicationForm() {
    this._goToStep(this.steps[1], {id: this.id})
  }

  applicationFinished() {
    this.router.go(this.applicationFinishedAddr)
  }

  set currentRoute(route) {
    if (route.indexOf(this.mainRoute) === 0) {
      this._selectedIndex = this.steps.findIndex(step => step.state === route)
    }
  }

  get currentRoute() {
    return this.steps[this._selectedIndex]
  }

  next() {
    if(this.hasNext) {
      this._goToStep(this.nextStep)
    }
  }

  previous() {
    if(this.hasPrevious) {
      this._goToStep(this.previousStep)
    }
  }

  get nextStep() {
    if(this.hasNext) {
      return this.steps[this._selectedIndex + 1]
    }
    return undefined
  }

  get previousStep() {
    if(this.hasPrevious) {
      return this.steps[this._selectedIndex - 1]
    }
    return undefined
  }

  get hasNext() {
    return this.steps && this._selectedIndex < this.steps.length - 1
  }

  get hasPrevious() {
    return this._selectedIndex !== 0
  }

  // private

  _goToStep(step, params = {}) {
    this.router.go(step.state, params)
  }
}

export default ApplicationRouter
