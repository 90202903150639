class StepBallController {
  /*@ngInject*/
  constructor($timeout, $interval, loggerService) {
    this.name = 'stepBall'
    this.$timeout = $timeout
    this.$interval = $interval
    this.logger = loggerService.makeLogger()
  }

  get ballSymbol() {
    return this.direction.toLowerCase() === 'up' ? 'add' : 'remove'
  }

  click() {
    if (!this._isNumeric(this.ngModel) || this.isDisabled) {
      return
    }
    switch (this.direction.toLowerCase()) {
      case 'up':
        this.ngModel = Math.round((this.ngModel + this.step) * 100) / 100
        break
      case 'down':
        this.ngModel = Math.round((this.ngModel - this.step) * 100) / 100
        break
      default:
        this.logger.log(`***** Unknown increment direction: '${this.direction}'.`)
        break
    }
  }

  startAutoIncrement(event) {
    event.preventDefault()
    this.click()
    if (!this.isDisabled) {
      this.timeoutPromise = this.$timeout(() => {
        this.intervalPromise = this.$interval(() => {
          this.click()
        }, 25)
      }, 500)
    }
  }

  stopAutoIncrement() {
    if (this.timeoutPromise) {
      this.$timeout.cancel(this.timeoutPromise)
      delete this.timeoutPromise
    }
    if (this.intervalPromise) {
      this.$interval.cancel(this.intervalPromise)
      delete this.intervalPromise
    }
  }

  get isDisabled() {
    if (this.disabled) {
      return true
    }
    const atLowestGoingDown = () => this._isNumeric(this.min) && this._isNumeric(this.step) && this.ngModel < (this.min + this.step) && this.direction.toLowerCase() === 'down'
    const atHighestGoingUp = () => this._isNumeric(this.max) && this._isNumeric(this.step) && this.ngModel > (this.max - this.step) && this.direction.toLowerCase() === 'up'
    if (atLowestGoingDown() || atHighestGoingUp()) {
      this.stopAutoIncrement()
      return true
    }
    return false
  }

  _isNumeric(value) {
    return !(value === null || value === undefined || isNaN(value))
  }

}

export default StepBallController
