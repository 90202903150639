import Big from 'big.js'

class EntityContextService {
  /*@ngInject*/
  constructor($q, $state, localStorageService, userService, investorApplicationsRepository) {
    this.$q = $q
    this.$state = $state
    this.localStorageService = localStorageService
    this.userService = userService
    this.investorApplicationsRepository = investorApplicationsRepository

    this._init()
  }

  entities() {
    if (this.userService.isLoggedIn() && !this.loadingPromise) {
      this._reload()
    }
    return this._entities
  }

  logout() {
    this.localStorageService.remove('selected-entity-index', 'sessionStorage')
    this._init()
    delete this.loadingPromise
    delete this.loadingComplete
    delete this.investorApplication
    delete this.investor
    delete this.admin
  }

  get selected() {
    return this.index
  }

  set selected(index) {
    if (index !== this.index) {
      // don't reload context, just switch entity
      this._selectEntityAt(index)
      this.$state.reload()
    }
  }

  selectedEntity() {
    return (this.loadingComplete && this.index >= 0 && this.index < this._entities.length) ? this._entities[this.index] : {}
  }

  current() {
    if (!this.loadingComplete) {
      return this.loadingPromise ? this.loadingPromise.then(() => this) : this._reload()
    }
    return this._reloadCurrentEntity()
  }

  forceReload() {
    const doReload = () => {
      delete this.loadingComplete
      delete this.loadingPromise
      return this.current()
    }
    if (this.loadingPromise) {
      return this.loadingPromise.then(doReload)
    } else {
      return doReload()
    }
  }

  currentInvestor() {
    if (this.investor) {
      return this.investor.reload()
    }
    return this._reload().then(() => this.investor || this.$q.reject('no investor'))
  }

  currentInvestorApplication() {
    if (this.investorApplication) {
      return this.investorApplication.reload()
    }
    return this._reload().then(() => this.investorApplication || this.$q.reject('no investor application'))
  }

  createInvestorApplication() {
    return (this.loadingPromise ? this.loadingPromise : this._reload()).then(() => {
      if (!this.admin && this.investorApplications.length > 0) {
        this._selectEntityAt(this._firstInvestorApplicationIndex())
        return this.currentInvestorApplication()
      }

      return this.investorApplicationsRepository.create().then(() => {
        this._setIndex(this._firstInvestorApplicationIndex())
        return this._reload().then(() => this.investorApplication || this.$q.reject('no investor application'))
      })
    })
  }

  isFinancial() {
    return this.admin || (this.investor && this.investor.moneyAdded)
  }

  isAccepted() {
    return this.admin || (this.investor && (this.investor.status === 'accepted' || this.investor.status === 'verified'))
  }

  hasFundsToBuy(amount) {
    return this.investor && this.investor.availableFunds && new Big(amount).lte(this.investor.availableFunds)
  }

  // private

  _init() {
    this._setIndex(this.localStorageService.get('selected-entity-index', 'sessionStorage') || 0)
    this.admins = []
    this.investorApplications = []
    this.investors = []
    this._entities = []
  }

  _setIndex(index) {
    this.index = index
    this.localStorageService.set('selected-entity-index', this.index, 'sessionStorage')
  }

  _selectEntityAt(index) {
    this._setIndex(index < this._entities.length ? index : 0)

    delete this.admin
    delete this.investorApplication
    delete this.investor

    const setEntity = (collection, entity, offset, index) => {
      if (collection && collection.length) {
        if (offset <= index && index < offset + collection.length) {
          entity = this._entities[index]
        }
        offset += collection.length
      }
      return [entity, offset]
    }

    let offset = 0;
    [this.admin, offset] = setEntity(this.admins, this.admin, offset, this.index);
    [this.investorApplication, offset] = setEntity(this.investorApplications, this.investorApplication, offset, this.index);
    [this.investor, offset] = setEntity(this.investors, this.investor, offset, this.index)
  }

  _capitalizeFirstLetter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  _reloadCurrentEntity() {
    if (this.admin) {
      return this.$q.resolve(this)
    }
    if (this.investorApplication) {
      return this.currentInvestorApplication().then(entity => {
        entity.name = this._findEntityName(entity)
        this.investorApplication = entity
        return this
      })
    }
    if (this.investor) {
      return this.currentInvestor().then(entity => {
        entity.name = this._findEntityName(entity)
        this.investor = entity
        return this
      })
    }
    return this.$q.resolve(this)
  }

  _reload() {
    const user = this.userService.currentUser
    if (!user) {
      return this.$q.resolve(this)
    }

    user.clearResource('investorApplications')
    user.clearResource('investors')

    return this.loadingPromise = this.$q.all([
      user.promise('investorApplications'),
      user.promise('investors'),
    ]).then((results) => {
      const [investorApplications, investors] = results

      this.admins = this.userService.isAdmin() ?
        [{name: Object.getOwnPropertyNames(
          this.userService.currentUser.roles || {}).map((s) => this._capitalizeFirstLetter(s)).join(', ')
        }] : []
      this.investorApplications = investorApplications.filter((application) => application.status === 'pending')
      this.investors = investors

      this._entities = [...this.admins, ...this.investorApplications, ...this.investors]
        .map((entity, index) => {
        entity._entitiesIndex = index
        return entity
      })
      for (let entity of this._entities) {
        entity.name = this._findEntityName(entity)
      }

      this._selectEntityAt(this.index)

      return this._reloadCurrentEntity().then(() => {
        this.loadingComplete = true
        return this
      })
    })
  }

  _findEntityName(entity) {
    if (entity.name) {
      return entity.name
    }
    if (entity.application && entity.application.investmentEntity) {
      if (entity.application.investmentEntity.type === 'individual' || entity.application.investmentEntity[entity.application.investmentEntity.type] === undefined) {
        const createFullName = (applicant) => Array.of(applicant.firstName, applicant.lastName).filter((e) => e).join(' ')
        return entity.applicantSummary ? createFullName(entity.applicantSummary) : createFullName(entity.applicant)
      }
      return entity.application.investmentEntity[entity.application.investmentEntity.type].name
    }
    if (entity.application && entity.application.business) {
      return entity.application.business.name
    }
    return null
  }

  _firstInvestorApplicationIndex() {
    return this.admins.length
  }
}

export default EntityContextService
