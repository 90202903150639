import template from './contentfulAsset.pug'
import controller from './contentfulAsset.controller'
import './contentfulAsset.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    transclude: true,
    scope: {},
    bindToController: {
      title: '@'
    },
  }
}
