/*

  The following RAW definition is a copy-paste from the rates spreadsheet.

  The remainder of the file is the code to process the raw data into a
  usable form for the rest of the application. There is some rudimentary
  validation in the processing code to assert that the raw data has the
  expected shape.

  To update the rates simple copy-paste the new raw data from the
  spreadsheet into the RAW value below.

 */

export default '\tA\tB\tC\tD\tE\t\t\tA\tB\tC\tD\tE\n' +
  '5\t8.3\t8.4\t8.9\t9.4\t10.0\t\t5\t10.7\t11.4\t12.2\t12.9\t16.2\n'+
  '4\t8.4\t8.5\t9.1\t9.9\t10.7\t\t4\t11.1\t11.9\t13.1\t14.0\t17.7\n'+
  '3\t8.7\t8.8\t9.4\t10.5\t11.6\t\t3\t11.7\t12.7\t14.2\t15.2\t19.5\n'+
  '2\t9.3\t9.4\t10.0\t11.5\t12.7\t\t2\t14.0\t15.2\t17.3\t18.6\t23.9\n'+
  '1\t11.1\t11.2\t11.9\t14.0\t15.7\t\t1\t16.8\t18.3\t21.0\t22.7\t28.9'
