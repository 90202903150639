class CsvService {
  /*@ngInject*/
  constructor(CSV) {
    this.CSV = CSV
  }

  createAndDownload(name, rows) {
    this.CSV.stringify(rows, {label: true, decimalSep: '.', txtDelim: '"'}).then((csv) => {
      // Don't use $window and $document, download stops working in safari if angular wrapper for document is used
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(new Blob([csv], {type: 'text/csv;charset=utf-8;'}), name)
      } else {
        const downloadLink = document.createElement('a')
        document.body.appendChild(downloadLink)
        downloadLink.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csv)
        downloadLink.download = name
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
    })
  }
}
export default CsvService
