class ContentfulAssetController {
  /*@ngInject*/
  constructor(contentfulDocumentsService) {
    this.name = 'contentful-asset'

    this.$onInit = () => {
      contentfulDocumentsService.findDocument(this.title).then((document) => {
        this.asset = document
      })
    }
  }
}

export default ContentfulAssetController
