import merge from 'lodash/merge'
import textParser from './textParser'

import resolveProperty from '../../../../../utils/resolveProperty'

const reportError = (logger, key, message) => {
  logger
    .notify(`Report generation was unsuccessful. (${key})`)
    .error(`jsonToPdfMakeText error (${key}): ${message}`)
}

const transformContent = (content, tokenProcessor, logger) => {
  const typeOfContent = typeof content
  switch (typeOfContent) {
    case 'number':
    case 'boolean':
    case 'bigint':
      return content
    case 'string':
      const [value, status] = textParser(content, tokenProcessor, logger)
      if (!status) {
        reportError(logger, 'string-parse', `Failed to parse '${content}'.`)
      }
      return value
    case 'object':
      const contentObject = merge({}, content)
      const contentObjectTextContent = resolveProperty(contentObject, 'text')
      if (contentObjectTextContent) {
        const [value, status] = textParser(contentObjectTextContent, tokenProcessor, logger)
        contentObject.text = value
        if (!status) {
          reportError(logger, 'object-string-parse', `Failed to parse '${contentObjectTextContent}'.`)
        }
      }
      return contentObject
    default:
      reportError(logger, 'unknown-content-type', `Unknown content type '${typeOfContent}' for content '${content}'.`)
      logger.error(`Unrecognised content type: '${typeof content}'.`)
      break
  }

}

const jsonToPdfMakeText = (textSpecification, tokenProcessor, logger) => {
  const template = resolveProperty(textSpecification, 'template')
  if (template === undefined) {
    reportError(logger, 'missing-template', 'Text specification is missing the template property.')
    return {}
  }
  const pmTextDefinition = merge({}, template)

  const textContentDefinition = resolveProperty(pmTextDefinition, 'text')
  if (textContentDefinition) {
    if (!Array.isArray(textContentDefinition)) {
      pmTextDefinition.text = transformContent(textContentDefinition, tokenProcessor, logger)
    } else {
      const textRows = []
      textContentDefinition.forEach((textDefinition) => {
        textRows.push(transformContent(textDefinition, tokenProcessor, logger))
      })
      pmTextDefinition.text = textRows
    }
  } else {
    reportError(logger, 'missing-text', `Missing text property on '${pmTextDefinition}'.`)
  }
  return pmTextDefinition
}

export default jsonToPdfMakeText
