import template from './contentfulForm.pug'
import controller from './contentfulForm.controller'
import './contentfulForm.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: true,
    bindToController: {
      formName: '@',
      labels: '@',
      onSubmit: '<'
    }
  }
}
