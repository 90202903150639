import Big from 'big.js'
import moment from 'moment'

const determineStatusDisplay = (status) => {
  switch (status) {
    case 'deferred':
      return 'deferral agreed'
    case 'irregular':
      return 'credit monitoring'
    default:
      return status
  }
}

const determineStatus = (loanPart, dateService) => {
  if (loanPart.loanStatus === 'active' && loanPart.nextRepaymentSummary) {
    if (loanPart.nextRepaymentSummary.expectedDate && dateService.isBeforeNow(moment(loanPart.nextRepaymentSummary.expectedDate).add(1, 'days').toDate())) {
      return 'overdue'
    } else if (loanPart.nextRepaymentSummary.dueDate && dateService.isBeforeNow(loanPart.nextRepaymentSummary.dueDate)) {
      return 'attempting collection'
    }
  }
  return loanPart.loanStatus
}

export default (dateService, loanPart) => {
  const ROUND_HALF_UP = 1

  const loanPartStatus = determineStatus(loanPart, dateService)

  const instance = {
    loanPart,
    loanPartStatus,
    loanPartDisableSecondaryMarket: loanPart.loanDisableSecondaryMarket,
    loanPartStatusDisplay: determineStatusDisplay(loanPartStatus),
    listingsContext: {
      listings: [],
      offeredLoanPartUnits: 0,
      offeredLoanPartAmount: new Big('0.0')
    }
  }

  instance.setListings = listings => {
    instance.listingsContext.offeredLoanPartUnits = 0
    instance.listingsContext.offeredLoanPartAmount = new Big('0.0')
    instance.listingsContext.listings = listings.map(listing => {
      const bigAmount = new Big(listing.amount)
      const instanceUnits = bigAmount.div(new Big(loanPart.capitalOutstanding).div(new Big(loanPart.units))).round(0, ROUND_HALF_UP)
      instance.listingsContext.offeredLoanPartUnits += Number.parseInt(instanceUnits.toString(), 10)
      instance.listingsContext.offeredLoanPartAmount = instance.listingsContext.offeredLoanPartAmount.plus(bigAmount)
      return listing
    })
    return instance.listingsContext
  }

  return instance
}
