import Big from 'big.js'

class ChartData {
  constructor(series) {
    this.series = series
  }

  get normalised() {
    const isNumeric = value => !(value === null || value === undefined || isNaN(value))
    const total = this.series.reduce((memo, value) => isNumeric(value) ? memo.plus(value) : memo, new Big(0))
    return this.series.map(value => {
      if (total.gt(0) && isNumeric(value)) {
        const portion = new Big(value).div(total)
        return portion.eq(0) ? 0 : portion.lt(0.001) ? '0.001' : portion.toFixed(3)
      } else {
        return 0
      }
    })
  }
}

export default ChartData
