class LoanRequestDetailsController {
  /*@ngInject*/
  constructor($state, $stateParams, $scope, $mdMedia, router, userService, entityContextService, loanRequestsRepository, loanRequestNavigatorService) {
    this.name = 'loan-request-details'
    this.$mdMedia = $mdMedia
    this.router = router
    this.userService = userService
    this.entityContextService = entityContextService
    this.loanRequestsRepository = loanRequestsRepository
    this.loanRequestNavigatorService = loanRequestNavigatorService

    this.auctionTab = {shortName: 'Auction', longName: 'Auction', state: 'loanRequestDetails.auction'}
    this.listingsTab = {shortName: 'Listings', longName: 'Listings', state: 'loanRequestDetails.listings'}
    this.historyTab = {shortName: 'Loan', longName: 'Loan history', state: 'loanRequestDetails.loanHistory', class: this._sampleClass() + ' highlight', icon: 'flag'}

    this.tabs = [
      {shortName: 'Profile', longName: 'Profile', state: 'loanRequestDetails.businessProfile'},
      {shortName: 'Summary', longName: 'Summary', state: 'loanRequestDetails.keyInfo'},
      {shortName: 'D&B', longName: 'D&B', state: 'loanRequestDetails.creditScore', class: this._sampleClass()},
      {shortName: 'Financials', longName: 'Financials', state: 'loanRequestDetails.financialSummary', class: this._sampleClass()}
    ]

    this.loading = true

    this.$onInit = () => {
      this.loanRequestsRepository.getById($stateParams.id).then((loanRequest) => {
        this.loanRequest = loanRequest
        this._renderLoanRequest($state, $scope)
        this.loading = false
      })

      this.loanRequestNavigatorService.loanRequestNavigator().then((loanRequestNavigator) => {
        this.loanRequestNavigator = loanRequestNavigator
        const loanRequest = this.loanRequestNavigator.loanRequestById($stateParams.id)
        if (loanRequest) {
          this.loanRequest = loanRequest

          let loanRequests
          if (this.userService.isAdmin()) {
            loanRequests = this.loanRequestNavigator.loanRequestsWithActiveAuction()
              .concat(this.loanRequestNavigator.loanRequestsWithListedLoan())
              .concat(this.loanRequestNavigator.loanRequestsWithCompletedAuctionAndUnlistedLoans())
          } else {
            loanRequests = this.loanRequestNavigator.loanRequestsWithInProgressAuction()
              .concat(this.loanRequestNavigator.loanRequestsWithListedActiveLoan())
              .concat(this.loanRequestNavigator.loanRequestsWithUnlistedActiveLoans())
          }
          const loanRequestIndex = loanRequests.indexOf(loanRequest)
          this.previousLoanRequest = loanRequestIndex > 0 ? loanRequests[loanRequestIndex - 1] : undefined
          this.nextLoanRequest = loanRequestIndex < loanRequests.length - 1 ? loanRequests[loanRequestIndex + 1] : undefined
        }
      })
    }
  }

  get auction() {
    return this.loanRequest && this.loanRequest.auction
  }

  get hasAuction() {
    return this.loanRequest && this.loanRequest.auctionId
  }

  get hasLoan() {
    return this.loanRequest && this.loanRequest.loanId
  }

  onSelectTab(tab) {
    this.router.go(tab.state)
  }

  navigateToLoanRequest(loanRequest) {
    this.router.go(this.tabs[this.selectedIndex || 0].state, {id: loanRequest.id})
  }

  // private

  _sampleClass() {
    return this.userService.isLoggedIn() ? '' : 'sample'
  }

  _renderLoanRequest($state, $scope) {
    this._updateStateTitle($state.current)

    // if logged in, make auction/listings the first tab on small devices, otherwise make it the last
    if (this.hasLoan) {
      if (!this.$mdMedia('gt-sm')) {
        if (this.userService.isLoggedIn()) {
          this._insertTab(this.listingsTab, true)
        }
      }
      $scope.$watch(() => (!this.$mdMedia('gt-sm')), (show) => this._insertTab(this.listingsTab, show))
      this._appendTab(this.historyTab, true)
      if (!this.$mdMedia('gt-sm')) {
        if (!this.userService.isLoggedIn()) {
          this._appendTab(this.listingsTab, true)
        }
      }
    } else if (this.hasAuction) {
      if (!this.$mdMedia('gt-sm')) {
        if (this.userService.isLoggedIn()) {
          this._insertTab(this.auctionTab, true)
        } else {
          this._appendTab(this.auctionTab, true)
        }
      }
      $scope.$watch(() => (!this.$mdMedia('gt-sm')), (show) => this._insertTab(this.auctionTab, show))
    }

    this._selectCurrentTabAndCreateTabWatcher($scope, $state)
  }

  _updateStateTitle(state) {
    if (this.loanRequest) {
      state.data.title = this.loanRequest.purpose
    }
  }

  _selectCurrentTabAndCreateTabWatcher($scope, $state) {
    let indexOfState = this.tabs.findIndex(tab => tab.state === $state.current.name)
    if (indexOfState >= 0) {
      this.selectedIndex = indexOfState
    }
    $scope.$on('$stateChangeSuccess', (event, toState) => {
      if (toState.name.indexOf('loanRequestDetails') === 0) {
        this._updateStateTitle(toState)
        let indexOfState = this.tabs.findIndex(tab => tab.state === toState.name)
        if (indexOfState >= 0) {
          this.selectedIndex = indexOfState
        }
      }
    })
  }

  _appendTab(thisTab, show) {
    let index = this.tabs.findIndex(tab => tab.state === thisTab.state)
    if (show) {
      if (index < 0) {
        this.tabs.push(thisTab)
      }
    } else {
      if (index >= 0) {
        this.tabs.splice(index, 1)
      }
    }
  }

  _insertTab(thisTab, show) {
    let index = this.tabs.findIndex(tab => tab.state === thisTab.state)
    if (show) {
      if (index < 0) {
        this.tabs.unshift(thisTab)
      }
    } else {
      if (index >= 0) {
        this.tabs.splice(index, 1)
      }
    }
  }

  _removeTab(thisTab) {
    let index = this.tabs.findIndex(tab => tab.state === thisTab.state)
    if (index >= 0) {
      this.tabs.splice(index, 1)
    }
  }
}

export default LoanRequestDetailsController
