const createAsyncCounter = () => {
  const asyncCounts = new Map()
  const mutateCount = (id, delta) => {
    const idCount = asyncCounts.has(id) ? asyncCounts.get(id) : 0
    asyncCounts.set(id, idCount + delta)
  }
  return {
    start: (id) => mutateCount(id, 1),
    end: (id) => [undefined, 0].includes(asyncCounts.get(id)) ? mutateCount(id, 0) : mutateCount(id, -1),
    get isWaiting() {
      return [...asyncCounts.keys()].reduce((sum, key) => {
        sum += asyncCounts.get(key)
        return sum
      }, 0) > 0
    },
    toString: () => {
      return 'counter: [' + [...asyncCounts.keys()].sort().reduce((rep, key) => {
        rep.push(`${key} => ${asyncCounts.get(key)}`)
        return rep
      }, []).join(', ') + ']'
    }
  }
}

export default createAsyncCounter
