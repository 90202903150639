const DEFAULT_BRAND_COLOUR = '#ffaabb'

class BrandingService {
  /*@ngInject*/
  constructor(tenantInformationService) {
    this.name = 'brandingService'

    this.definedBrands = []

    this.loadingBrands = tenantInformationService.getElement('brand.colours')
      .then(colours => {
        this.definedBrands = colours
      })
  }

  getAllProductBrandColours() {
    return this.definedBrands
  }

  getColourByBrandId(brandId, subBrandId) {
    const brandIndex = this.definedBrands.findIndex(definedBrand => definedBrand.brand === brandId)
    if (brandIndex === -1) {
      return DEFAULT_BRAND_COLOUR
    }
    const subBrandIndex = this.definedBrands[brandIndex].hues.findIndex(hue => hue.hue === subBrandId)
    return subBrandIndex === -1 ? DEFAULT_BRAND_COLOUR : this.definedBrands[brandIndex].hues[subBrandIndex].colour
  }

  makeBrandingIdentifier(brandId, subBrandId) {
    return `brand-${brandId}-${subBrandId}`
  }

  extractBrandIdSubBrandId(brandingIdentifier) {
    const result = brandingIdentifier.match(/brand-(\d+)-(\d+)/)
    return result ? {brandId: Number.parseInt(result[1], 10), subBrandId: Number.parseInt(result[2], 10)} : {brandId: -1, subBrandId: -1}
  }

  getColourForBrandingIdentifier(brandingIdentifier) {
    const indexes = this.extractBrandIdSubBrandId(brandingIdentifier)
    return this.getColourByBrandId(indexes.brandId, indexes.subBrandId)
  }
}

export default BrandingService
