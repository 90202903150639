import {container, text} from '../../common/dynamicForm/helpers/dynamicFormHelpers'

const collectBiometricIdentityDataForm = {
  title: `Photo Identification`,
  model: 'collectBiometricIdentityCheckData',
  actionLabel: 'Submit',
  widgets: [
    container().classes('border box-shadow-2 background-white').widgets(
      text().prompt('Comment').model('comment').build()
    ).build()
  ]
}

export default collectBiometricIdentityDataForm
