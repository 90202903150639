class VideoDialogController {
  /*@ngInject*/
  constructor($sce) {
    this.$sce = $sce
    this.videos = {
      'Animation Video': 161577061,
      'Trout Video': 161578191,
      'Brokers Video': 246190947
    }
  }

  get videoUrl() {
    return this.$sce.trustAsResourceUrl('//player.vimeo.com/video/' + this._getVideoId() + '?portrait=0&amp;color=a7d500')
  }

  _getVideoId() {
    return this.videos[this.videoName] || this.videoName
  }
}

export default VideoDialogController
