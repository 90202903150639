import template from './brandingColourSelection.pug'
import controller from './brandingColourSelection.controller'
import './brandingColourSelection.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: {
      brandingId: '=', // An identifier to hold the brand identity, example 'brand-1-4'
      required: '=?' // Whether a value is required for the form to be valid.
    }
  }
}
