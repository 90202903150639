import template from './displayInvestmentProductPartDetails.pug'
import controller from './displayInvestmentProductPartDetails.controller'
import './displayInvestmentProductPartDetails.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      investmentProductPart: '='
    },
    bindToController: true
  }
}
