/* eslint no-console: 0 */

class LoggerService {
  /*@ngInject*/
  constructor($window, $mdToast) {
    const log = (config, level, ...args) => {
      if (config.enableConsole) {
        switch (level) {
          case 'info':
            console.info(...args)
            break
          case 'warn':
            console.warn(...args)
            break
          case 'error':
            console.error(...args)
            break
          default:
            console.log(...args)
            break
        }
      }
      if (config.enableNewRelic && $window.NREUM) {
        $window.NREUM.noticeError(new Error(args.join(', ')))
      }
    }

    const doLogging = (config, level, ...args) => {
      if (args.length > 0) {
        if (args.length === 1 && typeof args[0] === 'function') {
          log(config, level, args[0]())
        } else {
          log(config, level, ...args)
        }
      }
    }

    const doNotification = (arg, hideDelay) => {
      const message = typeof arg === 'function' ? arg() : arg
      $mdToast.show($mdToast.simple().textContent(message).hideDelay(hideDelay).position('top left right'))
    }

    this.makeLogger = () => {
      const config = {
        enableConsole: true,
        enableNewRelic: false
      }
      const logger = {
        log: (...args) => doLogging(config, 'log', ...args),
        info: (...args) => doLogging(config, 'info', ...args),
        warn: (...args) => doLogging(config, 'warn', ...args),
        error: (...args) => doLogging(config, 'error', ...args),
        enableConsole: (enabled) => {
          config.enableConsole = enabled
          return logger
        },
        enableNewRelic: (enabled) => {
          config.enableNewRelic = enabled
          return logger
        },
        notify: (arg, hideDelay = 5000) => {
          doNotification(arg, hideDelay)
          return logger
        },
        serviceFault: () => {
          const metadata = {
            serviceId: undefined,
            notification: undefined,
            logMessage: undefined,
            hideDelay: undefined
          }
          const reporter = {
            serviceId: (serviceId) => {
              metadata.serviceId = serviceId
              return reporter
            },
            notification: (notification, hideDelay = 5000) => {
              metadata.notification = notification
              metadata.hideDelay = hideDelay
              return reporter
            },
            logMessage: (logMessage) => {
              metadata.logMessage = logMessage
              return reporter
            },
            report: () => {
              if (metadata.notification) {
                const message = typeof metadata.notification === 'string' && metadata.serviceId ? `${metadata.notification} (${metadata.serviceId})` : metadata.notification
                doNotification(message, metadata.hideDelay)
              }
              if (metadata.logMessage) {
                const message = typeof metadata.logMessage === 'string' && metadata.serviceId ? `${metadata.logMessage} (${metadata.serviceId})` : metadata.logMessage
                const localConfig = Object.create(config)
                localConfig.enableNewRelic = true
                doLogging(localConfig, 'error', message)
              }
            }
          }
          return reporter
        }
      }
      return logger
    }
  }
}

export default LoggerService
