export default {
  noHeaderId: true,
  headerLevelStart: 2,
  tasklists: true,
  tables: true,
  openLinksInNewWindow: true,
  emoji: true,
  parseImgDimensions: true,
  strikethrough: true
}
