import template from './withdrawalsProcessing.pug'
import controller from './withdrawalsProcessing.controller'
import '../withdrawals.scss'
import './withdrawalsProcessing.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
