class ManageLoanPartListingsController {

  /*@ngInject*/
  constructor(loanPartListingsService) {
    this.name = 'manage-loan-part-listings'

    this.$onInit = () => {
      loanPartListingsService.loadActiveListings(this.compositeLoanPart)
    }
  }
}

export default ManageLoanPartListingsController
