import formDefinitionBuilder from './formEditMaturityInstruction'
import noopPromiseBasedActionGenerator from '../../../../../common/dynamicForm/helpers/noopPromiseBasedActionGenerator'
import {and, or, not} from '../../../../../common/dynamicForm/helpers/dynamicFormHelpers'
import isDefined from '../../../../../utils/isDefined'
import valueOrDefault from '../../../../../utils/valueOrDefault'

class EditInvestmentProductPartMaturityInstructionController {
  /*@ngInject*/
  constructor(loggerService, $q) {
    this.name = 'edit-investment-product-part-maturity-instruction'
    this.$q = $q

    this.loggerService = loggerService

    this.logger = loggerService.makeLogger().enableNewRelic(true)
  }

  $onInit() {
    const formBuilder = formDefinitionBuilder()
    this.investmentProducts.forEach((investmentProduct) => {
      formBuilder.addInvestmentType(investmentProduct.name, investmentProduct.id)
    })

    const initForm = (model) => {
      model.instruction = this.investmentProductPart.maturityInstruction
      model.reinvestProductId = this.investmentProductPart.maturityRolloverInvestmentProductId
    }
    const rulesContext = {
      currentValues: {}
    }
    initForm(rulesContext.currentValues)
    const model = {}
    initForm(model)

    const isRollover = '(eq (fromModel "instruction") "rollover")'
    const isChangedInstruction = not('(eq (fromContext "currentValues" "instruction") (fromModel "instruction"))')
    const isChangedRolloverProductId = not('(eq (fromContext "currentValues" "reinvestProductId") (fromModel "reinvestProductId"))')
    const isSemanticallyDifferent = or(isChangedInstruction, and(isRollover, isChangedRolloverProductId))

    const confirmationDialogManager = {
      dialogName: 'investment-product-maturity-instruction-change-confirmation',
      dialogModelProvider: () => {
        const candidateProduct = () => this.investmentProducts.find((investmentProduct) => investmentProduct.id === model.reinvestProductId)
        return {
          instructionDescription: (model.instruction === 'redeem') ?
            'Redeem' :
            (() => valueOrDefault(candidateProduct(), {name: `UNKNOWN - investment product id: ${model.reinvestProductId}`}).name)()
        }
      }
    }

    const submitAction = (model, compiledWidgets) => {
      // TODO - Wrap this logic in a confirmation dialog box.
      return this.investmentProductPart.reload()
        .then((investmentProductPart) => {
          this.investmentProductPart = investmentProductPart
          this.investmentProductPart.maturityInstruction = model.instruction
          if (model.instruction === 'rollover') {
            this.investmentProductPart.maturityRolloverInvestmentProductId = model.reinvestProductId
          } else {
            this.investmentProductPart.maturityRolloverInvestmentProductId = null
            this.investmentProductPart.maturityRolloverInvestmentProductName = null
          }
          return this.investmentProductPart.save().then(() => {
            initForm(rulesContext.currentValues)
          })
        })
        .catch((e) => {
          this.loggerService.error(`Unable to save the changed maturity instruction: ${JSON.stringify(e)}`)
        })
    }

    this.theForm = {
      definition: formBuilder.build(),
      model,
      rulesContext,
      showSubmit: isSemanticallyDifferent,
      saveAction: noopPromiseBasedActionGenerator(this.$q),
      submitAction: submitAction,
      showCancel: true,
      cancelAction: (model) => {
        const result = this.$q.defer()
        result.resolve('- cancelled -')
        initForm(model)
        return result.promise
      },
      requiresConfirmation: confirmationDialogManager,
      actionLabel: 'SAVE'
    }
  }

  get hasActiveMaturityInstruction() {
    return isDefined(this.investmentProductPart) &&
      this.investmentProductPart.status === 'active' &&
      this.investmentProductPart.type === 'standard'
  }
}

export default EditInvestmentProductPartMaturityInstructionController
