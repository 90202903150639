import template from './loanCalculator.pug'
import controller from './loanCalculator.controller'
import './loanCalculator.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      calculator: '@',
      brand: '@',
      mode: '@',
      version: '@',
    },
    bindToController: true
  }
}
