import moment from 'moment'
const ISO_FORMAT='YYYY-MM-DD'

class DateSelectController {
  /*@ngInject*/
  constructor($timeout) {
    this.name = 'dateSelect'
    this.$timeout = $timeout
    this._updateFromModel()
  }

  get isDynamic() {
    return this.dynamic === undefined ? false : this.dynamic
  }

  get isRequired() {
    return this.required !== undefined && this.required !== null ? this.required : true
  }

  get model() {
    return this._model
  }

  set model(value) {
    this._model = value
    this._updateFromModel()
  }

  nameForControl(control) {
    if (this.formRepeatSectionTransclusion) {
      return `${this.formRepeatSectionTransclusion.transcludedName}-${this.name}-${control}`
    } else {
      return `${this.name}-${control}`
    }
  }

  dateInputChanged() {
    this._clearErrors()
    if (this.day && this.month && this.year) {
      const parsedDate = moment(`${this.year}-${this.month}-${this.day}`, ISO_FORMAT)
      if (this._isValidDate(parsedDate)) {
        this._updateFromInput(parsedDate)
      }
    }
  }
  
  // ***** private.

  _updateFromModel() {
    if (this.model) {
      const parsedDate = moment(this.model, ISO_FORMAT)
      if (parsedDate.isValid()) {
        this.day = parsedDate.date()
        this.month = parsedDate.month() + 1
        this.year = parsedDate.year()
        this._showFormattedDate(parsedDate)
      }
    }
  }

  _updateFromInput(date) {
    this._showFormattedDate(date)
    this._model = date.format(ISO_FORMAT)
    if (this.patchForm) {
      this.$timeout(() => {
        this.patchForm.update()
      })
    }
  }

  _showFormattedDate(date) {
    this.formattedDate = date.locale('en').format('ddd, MMM Do YYYY')
  }

  _clearModel() {
    this.formattedDate = null
    this.model = null
  }

  _isValidDate(date) {
    if (this._isValidFormat(date)) {
      this._validForKey('invalid-date')

      if (this._isAfterMax(date)) {
        this._invalidForKey('max-date')
        return false
      } else {
        this._validForKey('max-date')
      }

      if (this._isBeforeMin(date)) {
        this._invalidForKey('min-date')
        return false
      } else {
        this._validForKey('min-date')
      }
    } else {
      this._invalidForKey('invalid-date')
      return false
    }

    return true
  }

  _isValidFormat(date) {
    return date.isValid()
  }

  _isAfterMax(date) {
    return this.maxDate && date.isAfter(this.maxDate)
  }

  _isBeforeMin(date) {
    return this.minDate && date.isBefore(this.minDate)
  }

  _validForKey(key) {
    this._setValidity(key, true)
  }

  _invalidForKey(key) {
    this._setValidity(key, false)
    this._clearModel()
  }

  _setValidity(key, valid) {
    this.form[this.nameForControl('day')].$setValidity(key, valid)
    this.form[this.nameForControl('month')].$setValidity(key, valid)
    this.form[this.nameForControl('year')].$setValidity(key, valid)
  }

  _clearErrors() {
    this.form[this.nameForControl('day')].$error = {}
    this.form[this.nameForControl('month')].$error = {}
    this.form[this.nameForControl('year')].$error = {}
  }
}

export default DateSelectController
