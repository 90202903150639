import angular from 'angular'
import 'angular-ui-router'

import faqsComponent from './faqs.component'

export default angular.module('app.pages.marketing.faqs', ['ui.router'])
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider
      .state('faqs', {
        url: '/faqs',
        template: '<faqs/>',
        data: {
          appClasses: ['hero'],
        },
        tabName: 'More'
      })
      .state('faqsCategory', {
        url: '/faqs/:category',
        template: '<faqs/>',
        data: {
          appClasses: ['hero'],
        },
        tabName: 'More'
      })
      .state('faqsCategoryItem', {
        url: '/faqs/:category/:item',
        template: '<faqs/>',
        data: {
          appClasses: ['hero'],
        },
        tabName: 'More'
      })
  }])
  .directive('faqs', faqsComponent)
