import {
  borrowerFullComposite,
  guarantorFullComposite,
  businessFullComposite,
  contactType,
  fileUploadBuilder,
  loanAmount,
  isLoanAmount,
  isReferrerNotes,
  isReferrer,
  loanPurposeAdmin,
  reeceAccountNumber,
  referrerFirstName,
  referrerLastName,
  referrerPhone,
  referrerEmail,
  referrerNotes,
  conjunction,
  isBorrowerDone,
  isGuarantorDone,
} from '../dynamicEnquiryFormHelpers'
import {
  container,
  helpContent,
  radioButtons,
  ruleRender,
  ruleSet,
  or,
  and, isModelDefined, fromModel
} from '../../../dynamicForm/helpers/dynamicFormHelpers'

const isLaddrLoan = '(eq (fromModel "loanOrigination") "laddr")'
const isTruePillarsLoan = '(eq (fromModel "loanOrigination") "truepillars")'

const lineOfCreditAdminEnquiryForm = {
  title: 'Admin line of credit',
  model: 'line-of-credit-loan-enquiry-application',
  actionLabel: 'Submit enquiry',
  widgets: [
    container()
      .prompt('Line of credit application')
      .classes('border')
      .widgets(
        loanPurposeAdmin()
          .required(true)
          .display('none')
          .rules(ruleSet('"loc"'))
          .build(),
        contactType().admin().build(),
        helpContent().contentId('enquiry-form-line-of-credit-application-main').build(),
        container()
          .boxed(5)
          .widgets(
            radioButtons()
              .required(true)
              .model('loanOrigination')
              .prompt('Loan origination?')
              .button('Truepillars', 'truepillars')
              .button('Laddr', 'laddr')
              .build(),
            reeceAccountNumber().prompt('Reece trade account number')
              .addRules(ruleRender(isLaddrLoan))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Accredited referrer details')
          .rules(ruleRender(isTruePillarsLoan))
          .widgets(
            referrerFirstName().prompt('Referrer first name').build(),
            referrerLastName().prompt('Referrer last name').build(),
            referrerPhone().prompt('Referrer mobile number').build(),
            referrerEmail().prompt('Referrer email address').build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Loan details')
          .rules(ruleRender(or(and(isLaddrLoan, isModelDefined('reeceTradeAccount')), and(isTruePillarsLoan, isModelDefined('referrerEmail')))))
          .widgets(
            loanAmount()
              .prompt('Line of credit limit?')
              .min(5000)
              .max(100000)
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Borrower details')
          .rules(ruleRender(isLoanAmount))
          .widgets(
            ...businessFullComposite(),
            referrerNotes()
              .prompt('Describe the business background and reason for the loan')
              .required(true)
              .rules(ruleRender(isLoanAmount))
              .build()
          ).build(),
        container()
          .boxed(5)
          .prompt('Guarantor details')
          .rules(ruleRender(isReferrerNotes))
          .widgets(
            helpContent().contentId('enquiry-form-broker-representatives-info').build(),
            ...borrowerFullComposite()
          ).build(),
        radioButtons()
          .model('hasGuarantor')
          .prompt('Is there an additional guarantor for this loan?')
          .button('Another guarantor', 'yes')
          .button('Not required', 'no')
          .button('Don\'t know', 'maybe')
          .rules(ruleRender(isBorrowerDone))
          .required(true)
          .build(),
        helpContent()
          .contentId('enquiry-form-borrower-contact-direct')
          .rules(ruleRender(isBorrowerDone))
          .build(),
        container()
          .boxed(5)
          .prompt('Additional guarantor details')
          .rules(ruleRender(and(isModelDefined('hasGuarantor'), conjunction('eq')(fromModel('hasGuarantor'), '"yes"'))))
          .widgets(
            helpContent().contentId('enquiry-form-guarantor-details').build(),
            ...guarantorFullComposite()
          ).build(),
        radioButtons()
          .model('moreGuarantors')
          .rules(ruleRender(isGuarantorDone))
          .prompt('Are there any further guarantors for this loan?')
          .button('Additional guarantor(s)', 'yes')
          .button('Not required', 'no')
          .required(true)
          .build(),
        container()
          .boxed(5)
          .prompt('Documents')
          .rules(ruleRender(and(isReferrer, isBorrowerDone, isModelDefined('hasGuarantor'))))
          .widgets(
            fileUploadBuilder()
              .fileGroup('reeceAccountFinancials', 'Reece account financials')
              .fileGroup('laddrPrimaryApplication', 'Laddr primary application')
              .fileGroup('laddrSecondaryApplication', 'Laddr secondary application')
              .fileGroup('otherDocuments', 'Any other relevant documents')
              .build())
          .build())
      .build()
  ]
}

export default lineOfCreditAdminEnquiryForm
