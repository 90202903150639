export default () => {
  return {
    restrict: 'E',
    require: ['^?form', '?ngModel', '^?apiForm'],
    link: function(scope, ele, attrs, controls) {
      const [form, ngModel, apiForm] = controls
      if (form && apiForm && ngModel) {
        const name = ele.attr('name')
        scope.$watch(attrs.ngModel, () => form.$clearApiError(name))
      }
    }
  }
}
