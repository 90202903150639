import template from './contentItem.pug'
import controller from './contentItem.controller'
import './contentItem.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {
      contentItemName: '@'
    },
    bindToController: true
  }
}
