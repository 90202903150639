import moment from 'moment'

const noop = () => {}

const DUMMY_BASE_RATE = 2

const initProcessingResult = () => {
  const result = {
    processed: false,
    failed: false,
    heading: '',
    content: '',
    errors: []
  }
  return {
    get isFailed() {
      return result.processed && result.failed
    },
    get hasErrors() {
      return result.errors.length > 0
    },
    get errors() {
      return result.errors
    },
    get heading() {
      return result.heading
    },
    get content() {
      return result.content
    },
    failed(heading, content, errors) {
      result.processed = true
      result.failed = true
      result.heading = heading
      result.content = content
      result.errors = errors
    },
    succeeded() {
      result.processed = true
      result.failed = false
    },
  }
}

class ManageRedemptionRequestsController {
  /*@ngInject*/
  constructor(confirmationDialogService, dateService, investmentProductRedemptionRequestsRepository) {
    this.name = 'manage-redemption-requests'
    this.confirmationDialogService = confirmationDialogService
    this.dateService = dateService
    this.investmentProductRedemptionRequestsRepository = investmentProductRedemptionRequestsRepository

    this.page = 1
    this.limit = 5
    this.total = 0

    this.processingResult = initProcessingResult()

    this.reload = () => {
      this.loading = true
      const conditions = {
        scope: 'for_investment_product_part',
        id: this.investmentProductPart.id,
        size: this.limit,
        page: this.page
      }
      Object.keys(conditions).forEach((key) => !conditions[key] && delete conditions[key])
      this.loadingPromise = this.investmentProductRedemptionRequestsRepository.where(conditions)
        .then(data => {
          this.investmentProductRedemptionRequests = data.investmentProductRedemptionRequests
          this.total = data.total
        })
        .finally(() => this.loading = false)
    }

    const isCurrent = (investmentProductPart) => {
      const sameOrLater = (endDate) => moment(endDate).isSameOrAfter(dateService.now().startOf('day'))
        return (investmentProductPart.type === 'standard' && sameOrLater(investmentProductPart.endDate)) ||
          (investmentProductPart.type === 'rolling' && !investmentProductPart.endDate)
    }

    const redemptionRequestAllowed = (investmentProductPart) => {
      return !investmentProductPart.hasPendingRedemptionRequest &&
        investmentProductPart.status === 'active' &&
        isCurrent(investmentProductPart)
    }

    const redemptionRequestButtonLabel = (investmentProductPart) => {
      if (investmentProductPart.status !== 'active') {
        return 'Investment not Active'
      } else if (!isCurrent(investmentProductPart)) {
        return 'Investment is Expired'
      } else if (investmentProductPart.hasPendingRedemptionRequest) {
        return 'Redemption pending'
      } else {
        if (investmentProductPart.type === 'standard') {
          return 'Early Redemption Request'
        }
        return 'New Redemption Request'
      }
    }

    this.$onInit = () => {
      this.minRedemptionAmount = 50
      this.maxRedemptionAmount = this.investmentProductPart.currentValue
      this.redemptionRequestAllowed = redemptionRequestAllowed(this.investmentProductPart)
      this.redemptionRequestButtonLabel = redemptionRequestButtonLabel(this.investmentProductPart)
      this.performSearch()
      this.closePanel()
    }
  }

  performSearch() {
    this.page = 1
    this.reload()
  }

  closePanel() {
    this.panelState = 'closed'
    this.redemptionRequest = {}
  }

  createPanel($event) {
    const model = {
      baseFee: DUMMY_BASE_RATE,
      minRedemptionAmount: this.minRedemptionAmount
    }
    return this.confirmationDialogService.confirmAction($event, 'investment-product-redemption-fee-warning', model)
      .then(() => this.panelState = 'create')
      .catch(noop)
  }

  confirmPanel($event) {
    const model = {
      redemptionKind: this.redemptionRequest.kind,
      grossRedemptionAmount: this.redemptionRequest.kind === 'partial' ? this.redemptionRequest.amount : this.maxRedemptionAmount,
      nettRedemptionAmount: this.redemptionRequest.amount,
      feeAmount: 0
    }

    return this.confirmationDialogService.confirmAction($event, 'investment-product-redemption-amount-confirmation', model)
      .then(() => {
        return this._createRedemptionRequest()
          .then(() => {
            return this.confirmationDialogService.confirmAction($event, 'investment-product-redemption-acknowledgement')
              .then(() => this.panelState = 'closed')
              .catch(noop)
          })
          .catch(() => this.panelState = 'closed')
      })
      .catch(noop)
  }

  _createRedemptionRequest() {
    this.requestInProgress = true
    this.processingResult = initProcessingResult()
    this.processing = this.investmentProductRedemptionRequestsRepository.create({
      investorId: this.investmentProductPart.investorId,
      investmentProductPartId: this.investmentProductPart.id,
      kind: this.redemptionRequest.kind,
      amount: this.redemptionRequest.amount
    }).then((result) => {
      this.processingResult.succeeded()
      this.redemptionRequestAllowed = false
      this.investmentProductPart.reload()
      this.performSearch()
    }).catch((errorResult) => {
      this.processingResult.failed(
        'Redemption request failed',
        errorResult.data.message,
        errorResult.data.errors)
    }).finally(() => {
      this.requestInProgress = false
    })
    return this.processing
  }
}

export default ManageRedemptionRequestsController
