class FormErrors {
  /*@ngInject*/
  constructor($timeout) {
    this.$timeout = $timeout
  }

  triggerMessages(form) {
    // NB: there is some problem with md-select which causes validation messages to be cleared on a form
    // when they are bound in.
    // Wrapping it in a timeout for 100ms fixes the issue.
    this.$timeout(() => {
      form.$setSubmitted()
    }, 200)
  }
}

export default FormErrors
