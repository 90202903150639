import template from './formRepeatSection.pug'
import controller from './formRepeatSection.controller'
import './formRepeatSection.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    transclude: true,
    require: '?^patchForm',
    scope: {
      label: '@'
    },
    bindToController: {
      collection: '=',
      sectionName: '@',
      title: '@',
      required: '=',
      filter: '&?',
    },
    link: (scope, el, attr, ctrl) => {
      if (ctrl) {
        scope.vm.formTracker = ctrl.formTracker
      }
    }
  }
}
