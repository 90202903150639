import template from './repaymentsDue.pug'
import controller from './repaymentsDue.controller'
import '../directDebits.scss'
import './repaymentsDue.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    scope: {},
    bindToController: true
  }
}
