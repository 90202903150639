import angular from 'angular'

import adminModule from './admin/admin'
import marketingModule from './marketing/marketing'
import marketplaceModule from './marketplace/marketplace'
import investorsModule from './investors/investors'
import emailVerifiedModule from './emailVerified/emailVerified'
import checkIdentityModule from './checkIdentity/checkIdentity'
import collectBiometricIdentityDataModule from './collectBiometricIdentityData/collectBiometricIdentityData'
import checkConsentModule from './checkConsent/checkConsent'
import documentsModule from './documents/documents'

export default angular.module('app.pages', [
  adminModule.name,
  marketingModule.name,
  marketplaceModule.name,
  investorsModule.name,
  emailVerifiedModule.name,
  checkIdentityModule.name,
  collectBiometricIdentityDataModule.name,
  checkConsentModule.name,
  documentsModule.name
])
