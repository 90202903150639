var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (ngRequired, required) {pug_mixins["centered-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attrs(pug.merge([{"class": "centered-content","layout": "row"},attributes]), true)) + "\u003E\u003Cdiv class=\"content\" layout=\"column\" layout-align=\"start stretch\" flex flex-md=\"90\" flex-lg=\"80\" flex-gt-lg=\"70\" flex-offset-md=\"5\" flex-offset-lg=\"10\" flex-offset-gt-lg=\"15\"\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["contentful-component"] = pug_interp = function(component){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("ng-if", "" + component + ".fields.type === 'wide'", true, true)) + "\u003E\u003Cicon-panel" + (" class=\"wide-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E\u003C\u002Fdiv\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel" + (" class=\"standard-panel\""+pug.attr("panel", "" + component, true, true)) + "\u003E\u003C\u002Ficon-panel\u003E";
},
attributes: {"ng-if": pug.escape("" + component + ".fields.type !== 'wide'")}
});
};





























pug_mixins["required-radio-container"] = pug_interp = function(name, model, layout){
var block = (this && this.block), attributes = (this && this.attributes) || {};
ngRequired = attributes['ng-required']
required = !ngRequired
layout = layout || 'row'
pug_html = pug_html + "\u003Cmd-radio-group" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("ng-model", model, true, true)+pug.attr("layout", layout, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003Chelp-mate" + (pug.attr("topic", name, true, true)) + "\u003E\u003C\u002Fhelp-mate\u003E\u003C\u002Fmd-radio-group\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("ng-required", ngRequired, true, true)+pug.attr("required", required, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E";
};








pug_mixins["required-select"] = pug_interp = function(name, model, label){
var block = (this && this.block), attributes = (this && this.attributes) || {};
label = label || name
pug_html = pug_html + "\u003Cinput-container" + (pug.attr("label", label, true, true)) + "\u003E\u003Cmd-select" + (" class=\"required-select big-list\""+pug.attr("ng-model", model, true, true)+pug.attr("ng-class", "'" + name + "'", true, true)+pug.attr("placeholder", label, true, true)+pug.attr("aria-label", label, true, true)) + "\u003E";
block && block();
pug_html = pug_html + "\u003C\u002Fmd-select\u003E\u003Cinput-container class=\"hidden-input\"\u003E\u003Cinput" + (pug.attr("required", true, true, true)+pug.attr("name", name, true, true)+pug.attr("ng-model", model, true, true)+" ng-show=\"false\" aria-label=\"require\"") + "\u003E\u003C\u002Finput-container\u003E\u003C\u002Finput-container\u003E";
};










































































pug_mixins["rateDisplay"] = pug_interp = function(rate){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + (pug.escape(null == (pug_interp = rate) ? "" : pug_interp)) + "\u003Cimg src=\"\u002Ficons\u002Ficon-percent-pa.svg\" alt=\"percentage pa\"\u003E";
};












pug_html = pug_html + "\u003Cdiv class=\"content-wrapper\" ng-if=\"vm.showContentPanel &amp;&amp; !vm.landingPage\"\u003E\u003Cnavbar\u003E\u003C\u002Fnavbar\u003E\u003C\u002Fdiv\u003E\u003Ccontent id=\"main-content\" ng-if=\"vm.showContentPanel\" flex layout-fill layout=\"column\" layout-align=\"space-between\"\u003E\u003Cmd-toolbar class=\"secondary-navigation\"\u003E\u003Cdiv class=\"md-toolbar-tools\" layout=\"row\" layout-align=\"space-between center\"\u003E\u003Ch1\u003E{{ vm.title }}\u003C\u002Fh1\u003E\u003Ch2 class=\"entity-name\"\u003E{{ vm.entityName }}\u003C\u002Fh2\u003E\u003C\u002Fdiv\u003E\u003C\u002Fmd-toolbar\u003E\u003Csection class=\"main\"\u003E\u003Cui-view flex\u003E\u003C\u002Fui-view\u003E\u003C\u002Fsection\u003E\u003Cbigfoot ng-if=\"!vm.landingPage\"\u003E\u003C\u002Fbigfoot\u003E\u003C\u002Fcontent\u003E\u003Cdiv class=\"maintenance-wrapper\" ng-if=\"vm.showMaintenanceMessage\"\u003E\u003Cdiv ng-repeat=\"component in vm.page.components track by $index\"\u003E";
pug_mixins["centered-content"].call({
block: function(){
pug_html = pug_html + "\u003Cicon-panel panel=\"component\"\u003E\u003C\u002Ficon-panel\u003E";
}
});
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"blocking-notice\" ng-if=\"vm.showBlockingMessage\"\u003E\u003Ch2\u003EThis site is currently unavailable\u003C\u002Fh2\u003E\u003Cdiv class=\"messages\" ng-repeat=\"message in vm.messages\"\u003E\u003Cp class=\"error\"\u003E{{message}}\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cp\u003E \u003Cspan\u003EPlease contact \u003Ctenant-inline element-id=\"name.short\"\u003E\u003C\u002Ftenant-inline\u003E by calling us on \u003Ctenant-anchor anchor-id=\"contact.telephone.main\"\u003E\u003C\u002Ftenant-anchor\u003E\u003C\u002Fspan\u003E\u003C\u002Fp\u003E\u003Cp ng-if=\"vm.userLoggedIn\"\u003E\u003Cmd-button class=\"md-raised md-accent logout-button\" ng-click=\"vm.reset()\"\u003ELogout\u003C\u002Fmd-button\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";}.call(this,"ngRequired" in locals_for_with?locals_for_with.ngRequired:typeof ngRequired!=="undefined"?ngRequired:undefined,"required" in locals_for_with?locals_for_with.required:typeof required!=="undefined"?required:undefined));;return pug_html;};
module.exports = template;