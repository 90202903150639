class LoanPartDetailsController {
  /*@ngInject*/
  constructor() {
    this.name = 'loan-part-details'
  }

  convertToClass(className) {
    return className ? className.replace(/ /g, '-') : null
  }
}

export default LoanPartDetailsController
