class IconPanelController {
  /*@ngInject*/
  constructor(userService, contentful, $timeout, $document, contentPanelToggleService) {
    this.name = 'iconPanel'
    this.userService = userService
    this.$timeout = $timeout
    this.$document = $document
    this.contentPanelToggleService = contentPanelToggleService

    this.$onInit = () => {
      if (this.panelId) {
        contentful.entries('sys.id=' + this.panelId + '&include=10').then((panel) => {
          this.panel = panel.data && panel.data.items[0]
        })
      }
    }
  }

  scrollToTarget(targetSelector) {
    let content = angular.element(document.querySelector('#main-content'))
    let target = angular.element(document.querySelector(targetSelector))
    this.$timeout(() => target && target[0] && content.scrollToElementAnimated(target, 0, 1000), 0)
  }

  get isLoggedIn() {
    return this.userService.isLoggedIn()
  }

  get isWidePanel() {
    return this._hasField('type') && this.panel.fields.type === 'wide'
  }

  get panelName() {
    return this._getFieldOrDefault('name', '')
  }

  get panelStyle() {
    const styles = {}
    const backgroundColour = this._getFieldOrDefault('backgroundColor', '')
    if (backgroundColour !== '') {
      styles['background-color'] = backgroundColour
    }
    return styles
  }

  get inverted() {
    return this._hasField('invert') && this.panel.fields.invert
  }

  get withIcon() {
    return this._getFieldOrDefault('iconImage', false, () => true)
  }

  get iconStyle() {
    const styles = {}
    const icon = this._getFieldOrDefault('iconImage', '', (value) => `url('${value.fields.file.url}')`)
    if (icon !== '') {
      styles['background-image'] = icon
      const backgroundColour = this._getFieldOrDefault('backgroundColor', '')
      if (backgroundColour !== '') {
        styles['background-color'] = backgroundColour
      }
      styles['background-size'] = this._getFieldOrDefault('iconSize', '50% 50%', (value) => `${value} ${value}`)
    } else {
      styles['display'] = 'none'
    }
    return styles
  }

  get content() {
    return this._getFieldOrDefault('text', '')
  }

  get hasSubPanels() {
    return this._hasField('panels') && this.panel.fields.panels.length > 0
  }

  get subPanels() {
    return this.hasSubPanels ? this.panel.fields.panels : []
  }

  get subPanelLayout() {
    return this._getFieldOrDefault('panelLayout', 'column')
  }

  calculateFlex(subpanel) {
    if (this._isDivider(subpanel)) {
      return 'none'
    }
    let flex = this._subPanelsPerRow > 0 ? Math.floor(100 / this._subPanelsPerRow) : 100
    if (this.subPanels.some((el) => this._isDivider(el))) {
      flex = this._nearestValidFlexValue(flex - 1)
    }
    return flex === 100 ? '' : flex // firefox doesn't understand flex=100 within layout-wrap
  }

  scrollTo(element, delay) {
    if (typeof delay !== 'number') { delay = 1000 }
    let content = angular.element(this.$document.find('#main-content'))
    let target = angular.element(this.$document.find(element))
    this.$timeout(() => target && target[0] && content.scrollToElementAnimated(target, 0, delay), 0)
  }

  // private

  get _subPanelsPerRow() {
    return this._getFieldOrDefault('panelsPerRow', 0)
  }

  _nearestValidFlexValue(flex) {
    while (flex % 5) {
      if (flex === 33) break
      flex--
    }
    return flex
  }

  _isDivider(subpanel) {
    try { return subpanel.sys.contentType.sys.id === 'divider' } catch(error) { return false }
  }

  _getFieldOrDefault(field, defaultValue, transform) {
    if (!this._hasField(field)) {
      return defaultValue
    }
    return typeof transform === 'function' ? transform(this.panel.fields[field]) : this.panel.fields[field]
  }

  _hasField(property) {
    return this.panel && this.panel.fields && Object.getOwnPropertyNames(this.panel.fields).includes(property)
  }
}

export default IconPanelController
