class EmailVerificationService {
  /*@ngInject*/
  constructor(userService, emailVerificationsRepository, resendVerificationEmailsRepository, $mdToast, $q, $state) {
    this.name = 'emailVerificationService'
    this.userService = userService
    this.emailVerificationsRepository = emailVerificationsRepository
    this.resendVerificationEmailsRepository = resendVerificationEmailsRepository
    this.$mdToast = $mdToast
    this.$q = $q
    this.$state = $state
  }

  emailVerified() {
    return this.userService.isEmailVerified()
  }

  checkIfEmailVerified() {
    if (this.emailVerified()) {
      return this.$q.resolve(true)
    } else {
      return this._requestEmailVerificationStatus()
    }
  }

  resendVerificationEmail() {
    this.checkIfEmailVerified().then(() => {
      if (this.emailVerified()) {
        this._toast('Your email has been verified.')
      } else {
        this.resendVerificationEmailsRepository.create().then(() => {
          this._toast('A verification email has been sent to your registered email address. Please check your inbox.')
        }).catch(() => {
          this._toast('Error: request failed, please contact TruePillars.')
        })
      }
    })
  }

  // private

  _requestEmailVerificationStatus() {
    return this.emailVerificationsRepository.create().then((result) => {
      this.userService.setEmailVerified(result.user && result.user.emailVerified)
    })
  }

  _toast(message) {
    this.$mdToast.show(this.$mdToast.simple().textContent(message).hideDelay(5000).position('top left right'))
  }

}

export default EmailVerificationService
