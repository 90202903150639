class GreenidVerifyService {
  /*@ngInject*/
  constructor($http, api) {
    this.$http = $http
    this.greenidLink = api.linkFor('greenid')
  }

  updateStatus(verificationId) {
    return this.greenidLink.then((link) => {
      return this.$http.post(link.href + '/verify', {userId: verificationId}).then((response) => {
        return response.data
      })
    })
  }
}

export default GreenidVerifyService
