import Big from 'big.js'
import moment from 'moment'

class LoanHistoryController {
  /*@ngInject*/
  constructor($q, entityContextService, dateService) {
    this.name = 'loan-history'
    this.entityContextService = entityContextService
    this.dateService = dateService

    this.loading = true

    this.$onInit = () => {
      entityContextService.current().then((contextService) => {
        this.entityContextService = contextService
        this.isFinancial = this.entityContextService.isFinancial()
        if (this.isFinancial) {
          this.loadingPromises = $q.all([this.loanSummary.promise('loanRepayments'), this.loanSummary.promise('loanLosses')])
          this.loadingPromises.then((results) => {
            const [loanRepayments, loanLosses] = results
            this.entries = this._createEntries(loanRepayments, loanLosses)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }

  get loanSummary() {
    return this.loanRequest.loanSummary
  }

  get repaymentOverdue() {
    return this.repaymentStatus === 'overdue'
  }

  // private

  _createEntries(loanRepayments, loanLosses) {
    let entries = loanRepayments.map((repayment) => this._loanRepaymentToEntry(repayment))
      .concat(loanLosses.filter(loss => new Big(loss.loanPrincipal).gt('0')).map((loss) => this._loanLossToEntry(loss)))
    entries.sort((a, b) => (moment(a.dueDate).isBefore(b.dueDate) || (moment(a.dueDate).isSame(b.dueDate) && moment(a.repaymentDate).isBefore(b.repaymentDate))) ? -1 : 1)
    if (this.loanSummary.nextRepaymentSummary || this.loanSummary.repaymentComment) {
      entries.push(this._nextRepaymentToEntry())
    }
    return entries
  }

  _loanRepaymentToEntry(repayment) {
    let status
    let statusClass
    if (repayment.uiStatus) {
      status = repayment.uiStatus
      statusClass = 'paid-late'
    } else if (repayment.repaymentDate) {
      let daysLate = Math.round(moment.duration(moment(repayment.repaymentDate).diff(repayment.dueDate)).asDays())
      if (daysLate > 0) {
        status = `paid ${daysLate} days late`
        statusClass = 'paid-late'
      } else {
        status = 'paid on time'
        statusClass = 'paid-on-time'
      }
    }

    return this._createEntry(
      'repayment',
      status,
      statusClass,
      repayment.dueDate,
      repayment.repaymentDate,
      repayment.principal,
      repayment.interest,
      repayment.amount,
      repayment.principalOutstanding,
      repayment.comment
    )
  }

  _loanLossToEntry(loss) {
    return this._createEntry(
      'loss',
      'capital loss',
      'loss',
      loss.date,
      loss.date,
      loss.loanPrincipal,
      loss.loanInterest,
      loss.amount,
      loss.loanPrincipalOutstanding,
      loss.comment
    )
  }

  _nextRepaymentToEntry() {
    const nrs = this.loanSummary.nextRepaymentSummary || {}
    this.repaymentStatus = undefined
    if (this.loanSummary.status !== 'deferred') {
      if (nrs.expectedDate && this.dateService.isBeforeNow(moment(nrs.expectedDate).add(1, 'days').toDate())) {
        this.repaymentStatus = 'overdue'
      } else if (nrs.dueDate && this.dateService.isBeforeNow(nrs.dueDate)) {
        this.repaymentStatus = 'attempting collection'
      }
    }

    return this._createEntry(
      'scheduled',
      this.repaymentStatus,
      this.repaymentStatus ? this._convertToClass(this.repaymentStatus) : 'scheduled',
      nrs.dueDate,
      this.repaymentOverdue ? null : nrs.expectedDate,
      nrs.principal,
      nrs.interest,
      nrs.amount,
      nrs.principalOutstanding,
      this.loanSummary.repaymentComment
    )
  }

  _createEntry(type, status, statusClass, dueDate, repaymentDate, principal, interest, total, balance, comment) {
    return {
      type: type,
      status: status,
      statusClass: statusClass,
      dueDate: dueDate,
      repaymentDate: repaymentDate,
      principal: principal,
      interest: interest,
      total: total,
      balance: balance,
      comment: comment
    }
  }

  _convertToClass(className) {
    return className ? className.replace(/ /g, '-') : null
  }
}

export default LoanHistoryController
