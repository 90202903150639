import Big from '../../../../../../../../node_modules/big.js/big'
import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/x-pseudo'

const MemberRegisterType = Symbol.for('member register')

class MemberRegister {

  constructor(pdfService, csvService) {
    this.pdfService = pdfService
    this.csvService = csvService
  }

  processPdf(reportDate, investors) {
    const memberRegister = this._processInvestors(investors)

    let cumLoanUnits = new Big('0.0')
    let cumCashUnits = new Big('0.0')
    let cumTotalUnits = new Big('0.0')

    // -- Generate the body of the document table, with headings
    const tableBody = (dataRows) => {
      const body = [
        [
          this.pdfService.thl(' ', -1, {colSpan: 2}),
          this.pdfService.thl(' '),
          this.pdfService.thr('Loan Units'),
          this.pdfService.thr('Cash Units'),
          this.pdfService.thr('Total Units')
        ],
        []
      ]

      dataRows.forEach((row, index) => {
        const tableRow = []
        tableRow.push(this.pdfService.tdl(row['memberName'], index))
        tableRow.push(this.pdfService.tdl(this.pdfService.truncateContent(row['memberEmail']), index))
        tableRow.push(this.pdfService.tdr(this.pdfService.asMoney(row['loanUnits']), index))
        tableRow.push(this.pdfService.tdr(this.pdfService.asMoney(row['cashUnits']), index))
        tableRow.push(this.pdfService.tdr(this.pdfService.asMoney(row['totalUnits']), index))
        body.push(tableRow)

        cumLoanUnits = cumLoanUnits.plus(row['loanUnits'])
        cumCashUnits = cumCashUnits.plus(row['cashUnits'])
        cumTotalUnits = cumTotalUnits.plus(row['totalUnits'])
      })
      body[1] = [
        this.pdfService.tdl(`Total for ${dataRows.length} members`, -1, {
          colSpan: 2,
          fillColor: 'black',
          color: 'white'
        }),
        this.pdfService.tdl(' '),
        this.pdfService.tdr(this.pdfService.asMoney(cumLoanUnits), -1, {fillColor: 'black', color: 'white'}),
        this.pdfService.tdr(this.pdfService.asMoney(cumCashUnits), -1, {fillColor: 'black', color: 'white'}),
        this.pdfService.tdr(this.pdfService.asMoney(cumTotalUnits), -1, {fillColor: 'black', color: 'white'})
      ]
      return body
    }

    // -- The main report table, with the table body.
    const tableData = {
      table: {
        headerRows: 1,
        widths: ['*', 100, 70, 70, 70],

        body: tableBody(memberRegister),
      }
    }
    const reportDateMoment = moment(reportDate)
    const documentDefinition = this.pdfService.createDocumentDefinition(reportDateMoment.locale('en').format('D MMMM YYYY'),
      'TruePillars Investment Trust', 'Member Register', tableData)
    this.pdfService.createAndDownload(this._reportName(reportDateMoment, 'pdf'), documentDefinition)
    return {reportDate: reportDateMoment, count: memberRegister.length, totalLoanUnits: cumLoanUnits, totalCashUnits: cumCashUnits, totalUnits: cumTotalUnits}
  }

  processCsv(reportDate, investors) {
    const reportDateMoment = moment(reportDate)
    const memberRegister = this._processInvestors(investors)
    const processedInvestors = memberRegister.map(this._memberRegisterTransformCsv(reportDateMoment))

    const totals = memberRegister.reduce((totals, element) => {
      totals.loanUnits = totals.loanUnits.plus(element['loanUnits'])
      totals.cashUnits = totals.cashUnits.plus(element['cashUnits'])
      totals.totalUnits = totals.totalUnits.plus(element['totalUnits'])
      return totals
    }, {loanUnits: new Big('0.0'), cashUnits: new Big('0.0'), totalUnits: new Big('0.0')})

    this.csvService.createAndDownload(this._reportName(reportDateMoment, 'csv'), processedInvestors)

    return {reportDate: reportDateMoment, count: memberRegister.length, totalLoanUnits: totals.loanUnits, totalCashUnits: totals.cashUnits, totalUnits: totals.totalUnits}
  }

  // -- private methods.

  _reportName(reportDateMoment, suffix) {
    return `memberRegister-${reportDateMoment.format('YYYY-MM-DD')}.${suffix}`
  }

  _processInvestors(investors) {
    return investors.sort(this._memberRegisterSort).map(this._memberRegisterTransformPdf)
  }

  _memberRegisterSort(investorA, investorB) {
    const lastNameComparison = investorA.ownerSummary.lastName.toUpperCase().localeCompare(investorB.ownerSummary.lastName.toUpperCase())
    return lastNameComparison !== 0 ? lastNameComparison : investorA.ownerSummary.firstName.toUpperCase().localeCompare(investorB.ownerSummary.firstName.toUpperCase())
  }

  _memberRegisterTransformPdf(rawInvestor) {
    return {
      'memberName': rawInvestor.ownerSummary.firstName + ' ' + rawInvestor.ownerSummary.lastName,
      'memberEmail': rawInvestor.ownerSummary.email,
      'loanUnits': new Big(rawInvestor.p2pInvestedFunds),
      'cashUnits': new Big(rawInvestor.availableFunds).plus(new Big(rawInvestor.reservedFunds)),
      'totalUnits': new Big(rawInvestor.totalFunds)
    }
  }

  _memberRegisterTransformCsv(reportDateMoment) {
    const formattedReportDate = reportDateMoment.locale('en').format('YYYY-MM-DD')
    return (processedInvestor) => ({
      'Report Date': formattedReportDate,
      'Member Name': processedInvestor.memberName,
      'Member Email': processedInvestor.memberEmail,
      'Loan Units': processedInvestor.loanUnits.round(4, 1).toFixed(4),
      'Cash Units': processedInvestor.cashUnits.round(4, 1).toFixed(4),
      'Total Units': processedInvestor.totalUnits.round(4, 1).toFixed(4)
    })
  }

}

export {MemberRegisterType, MemberRegister}
