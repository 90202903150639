import template from './alertList.pug'
import controller from './alertList.controller'
import './alertList.scss'

export default () => {
  return {
    template: template({}),
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: true,
    scope: {
      type: '@'
    }
  }
}
