const DEFAULT_PREDICATE = () => false

const makeDateRanger = (logger) => {
  let predicates = [DEFAULT_PREDICATE]
  let currentDateRangeDefinitions = undefined

  const dateRanger = {
    defineDateRanges: (dateRangeDefinitions) => {
      if (dateRangeDefinitions && dateRangeDefinitions !== currentDateRangeDefinitions) {
        currentDateRangeDefinitions = dateRangeDefinitions
        predicates = [DEFAULT_PREDICATE]
        const reportRanges = dateRangeDefinitions.toString().trim().split(/[ ,]+/g)
        const definitionMatcher = /^(\d{4})(-(\d{4}))?$/
        predicates = reportRanges.map((reportRange) => {
          const rangeParts = reportRange.match(definitionMatcher)
          if (rangeParts !== null) {
            const lowerBoundOrOnly = parseInt(rangeParts[0], 10)
            if (rangeParts[3]) {
              const upperBound = parseInt(rangeParts[3], 10)
              const lower = Math.min(lowerBoundOrOnly, upperBound)
              const upper = Math.max(lowerBoundOrOnly, upperBound)
              return (targetYear) => targetYear >= lower && targetYear <= upper
            } else {
              return (targetYear) => targetYear === lowerBoundOrOnly
            }
          } else {
            if (logger) {
              logger.error(`ERROR: unrecognised date range definition: '${reportRange}'.`)
            }
            return () => false
          }
        })
      }
      return dateRanger
    },
    includeYear: (year) => predicates.some((predicate) => predicate(year))
  }
  return dateRanger
}

export default makeDateRanger
