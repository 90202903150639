import InvestorTotals from './../../../../services/investor/investorTotals.model'

class PositionPaneController {
  /*@ngInject*/
  constructor(entityContextService) {
    this.name = 'position-pane'
    this.entityContextService = entityContextService

    this.$onInit = () => {
      this.entityContextService.currentInvestor().then((investor) => {
        this.investor = investor
        this.investorTotals = new InvestorTotals(investor)
      })
        .catch(angular.noop) /* Do nothing - prolly not logged in as an INVESTOR. */
    }
  }

  get totalBids() {
    return this.investorTotals && this.investorTotals.totalBids(this.loanRequest)
  }

  get totalParts() {
    return this.investorTotals && Math.floor(this.investorTotals.totalParts(this.loanRequest))
  }

  get totalListings() {
    return this.investorTotals && Math.floor(this.investorTotals.totalListings(this.loanRequest))
  }

  get availableFunds() {
    return this.investor && Math.floor(this.investor.availableFunds)
  }
}

export default PositionPaneController
