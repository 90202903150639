import angular from 'angular'

import patchFormComponent from './patchForm.component'
import {updateControlWithDebounce, updateControl} from './autoUpdateControl.component'

export default angular.module('app.common.patchForm', [])
  .directive('patchForm', patchFormComponent)
  .directive('input', updateControlWithDebounce)
  .directive('textarea', updateControlWithDebounce)
  .directive('mdSelect', updateControl)
  .directive('mdCheckbox', updateControl)
  .directive('mdRadioGroup', updateControl)
