class DocumentsController {
  /*@ngInject*/
  constructor(contentfulDocumentsService, router, $stateParams, $window) {
    this.name = 'documents'

    this.$onInit = () => {
      let title = $stateParams.title && $stateParams.title.replace(/-+/g, ' ').toLowerCase()

      contentfulDocumentsService.findDocument(title).then((document) => {
        if (document && document.fields && document.fields.file && document.fields.file.url) {
          $window.open(document.fields.file.url, '_self')
        } else {
          router.go('home')
        }
      })
    }
  }
}

export default DocumentsController
