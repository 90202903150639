class AuthLockService {
  /*@ngInject*/
  constructor(environment, customPropertiesService, authLockProviderService, $window) {
    this.environment = environment
    this.authLockProviderService = authLockProviderService
    this.$window = $window
    this.brandedLogoUrl = customPropertiesService.getProperty('--lockWidget-logo-url').trim().slice(1, -1)
    this.brandedPrimaryColor = customPropertiesService.getProperty('--lockWidget-primary-color')
  }

  createLockWidget(email, title = undefined) {
    const config = {
      allowedConnections: ['Username-Password-Authentication'],
      autofocus: true,
      autoclose: true,
      avatar: null,
      rememberLastLogin: true,
      configurationBaseUrl: this.environment.AUTH0_CONFIGURATION_BASE_URL,
      auth: {
        audience: 'https://' + this.environment.AUTH0_DOMAIN + '/userinfo',
        responseType: 'token id_token',
        redirect: false,
        redirectUrl: this.$window.location.origin,
        sso: false,
        params: {
          scope: 'openid email'
        },
      },
      theme: {
        logo: this.brandedLogoUrl,
        primaryColor: this.brandedPrimaryColor
      },
      prefill: {
        email: email
      }
    }
    if (title) {
      config.title = title
      config.languageDictionary = {
        title: title
      }
    }
    return new (this.authLockProviderService.getAuthLockConstructor())(this.environment.AUTH0_USER_CLIENT_ID, this.environment.AUTH0_CUSTOM_DOMAIN, config)
  }
}

export default AuthLockService
