import createCompositeLoanPart from './compositeLoanPart'

class LoanPartListingsService {
  /*@ngInject*/
  constructor(dateService, listingsRepository) {

    const ROUND_DOWN = 0

    this.createCompositeLoanPart = loanPart => createCompositeLoanPart(dateService, loanPart)

    this.loadActiveListings = (compositeLoanPart) => {
      return listingsRepository.where({scope: 'loan_part', state: 'active', id: compositeLoanPart.loanPart.id}).then(data => {
        compositeLoanPart.setListings(data.listings)
      })
    }

    this.addListing = (id, listingAmount) => {
      return listingsRepository.create({loanPartId: id, amount: listingAmount.round(4, ROUND_DOWN)})
    }

    this.loadActiveListingsForInvestor = (investorId) => {
      return listingsRepository.where({scope: 'INVESTOR', state: 'ACTIVE', id: investorId})
    }
  }
}

export default LoanPartListingsService
