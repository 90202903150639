const overrideDialogForm = {
  title: 'Override',
  model: 'override',
  actionLabel: 'OVERRIDE',
  widgets: [
    {
      type: 'Text',
      definition: {
        model: 'overrideReason',
        prompt: 'Please include your reason for the override.',
        required: true
      }
    }
  ]
}

export default overrideDialogForm
