class BrowserDetectorService {
  /*@ngInject*/
  constructor(bowser, $window) {
    this.$window = $window
    // this.$mdToast = $mdToast
    this.bowser = bowser
    this.name = 'browserDetector'
    this.version = Number(bowser.version)
    this.isSupportedBrowser = this._isSupportedBrowser()
  }

  checkBrowser() {
    if (!this.isSupportedBrowser) {
      this._showWarning()
    }
  }

  // private

  _isSupportedBrowser() {
    if (isNaN(this.version)) {
      return !!this.bowser.webkit
    }
    if (this.bowser.chrome) {
      return this.version >= 40
    }
    if (this.bowser.ios) {
      return this.version >= 8
    }
    if (this.bowser.safari) {
      return this.version >= 8
    }
    if (this.bowser.facebook) {
      return this.version >= 50
    }
    if (this.bowser.msedge) {
      return this.version >= 13
    }
    if (this.bowser.msie) {
      return this.version >= 11
    }
    if (this.bowser.firefox) {
      return this.version >= 45
    }
    return !!this.bowser.webkit
  }

  _showWarning() {
    let message = 'You may be using a browser or operating system that is not supported by our website. ' +
      'For best results please consider upgrading to the latest software, or call us on 1300 320 288 for assistance.'
    this.$window.alert(message)
    this._tellNewRelic()
  }

  _tellNewRelic() {
    if (this.$window.NREUM) {
      this.$window.NREUM.noticeError(new Error('unsupported version: ' + JSON.stringify(this.bowser)))
    }
  }
}

export default BrowserDetectorService
