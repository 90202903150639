const evalMessage = (message) => typeof message === 'function' ? message().toString() : message.toString()

const assert = (condition, message) => {
  if (condition) {
    return
  }
  const failureMessage = message ? `Assertion failure: ${evalMessage(message)}` : 'Assertion failed!'
  throw typeof Error !== 'undefined' ? new Error(failureMessage) : failureMessage
}

export default assert
