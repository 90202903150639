class AddRepresentativeController {
  /*@ngInject*/
  constructor($scope) {
    this.name = 'addRepresentative'
    this.honorifics = [{value: 'Miss'}, {value: 'Mr.'}, {value: 'Mrs.'}, {value: 'Ms.'}, {value: 'Mx.'}, {value: '', label: '—'}]

    this.showRequiredInput = () => {
      $scope.$watch(() => this.createForm.$invalid, () => {
        if (this.createForm.$invalid) {
          angular.forEach(this.createForm.$error, (field) => {
            angular.forEach(field, (errorField) => {
              errorField.$setTouched()
            })
          })
        }
      })
    }

    this.$onInit = () => {
      this.model = {}
      if (this.defaultValues) {
        Object.getOwnPropertyNames(this.defaultValues).forEach((propertyName) => this.model[propertyName] = this.defaultValues[propertyName])
      }
      if (this._isInvestor()) {
        if (['rejected', 'deleted'].includes(this.entity.status)) {
          throw new Error(`Cannot add a representative to an inactive Investor (id: ${this.entity.id}).`)
        }
        if (this.entity.type === 'individual') {
          throw new Error('Cannot add representatives to individual investors.')
        }
      }

      this.showRequiredInput()
    }
  }

  get isCorporateInvestor() {
    return this._isInvestor() && this.entity.type === 'company'
  }

  get isBorrower() {
    return !this._isInvestor()
  }

  get canHavePercentage() {
    return this.model.isDirector || this.model.isBeneficialOwner
  }

  confirmAction() {
    const payload = {
      honorific: this.model.honorific,
      firstName: this.model.firstName,
      middleName: this.model.middleName,
      lastName: this.model.lastName,
      phone: this.model.phone,
      email: this.model.email,
      isDirector: this.model.hasOwnProperty('isDirector') ? this.model.isDirector : false,
      isBeneficialOwner: this.model.hasOwnProperty('isBeneficialOwner') ? this.model.isBeneficialOwner : false,
      ownershipPercent: this.model.hasOwnProperty('ownershipPercent') ? this.model.ownershipPercent : 0,
      hasActiveRole: this.model.hasOwnProperty('hasActiveRole') ? this.model.hasActiveRole : false,
      jobTitle: this.model.jobTitle ? this.model.jobTitle : ''
    }
    return this.entity.performAction('representative', payload)
      .then((entity) => this.completion.resolve(entity))
  }

  cancelAction() {
    this.completion.reject('cancel')
    return false
  }

  // ****** private

  _isInvestor() {
    return this.entityType && this.entityType === 'investor'
  }
}

export default AddRepresentativeController
