import {signIn, signOut, getCurrentUser, fetchAuthSession} from 'aws-amplify/auth'
import {Amplify} from 'aws-amplify'

export default class AwsAmplifyService {
  /*@ngInject*/
  constructor(environment) {
    this.environment = environment
  }

  configure() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: this.environment.AWS_BROKER_USER_POOL_ID,
          userPoolClientId: this.environment.AWS_BROKER_USER_POOL_WEB_CLIENT_ID,
        }
      }
    })
  }

  async signIn(credentials) {
    return await signIn(credentials)
  }

  async signOut() {
    await signOut()
  }

  async getCurrentUser() {
    return await getCurrentUser()
  }

  async fetchAuthSession() {
    return await fetchAuthSession()
  }
}
